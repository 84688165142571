//首页数据列表
const subjectList= [
  {pid: 0, name: "新闻传播大类", type: 1, id: 1, children: [
    {id: 21,name: "新闻出版类",pid: 1,type: 2},
    {id: 22,name: "广播影视类",pid: 1,type: 2}
  ]},
  {pid: 0, name: "教育与体育大类", type: 1, id: 7,children: [
    {id: 50,name: "教育类",pid: 7,type: 2},
    {id: 51,name: "语言类",pid: 7,type: 2},
    {id: 52,name: "体育类",pid: 7,type: 2},
  ]},
  {pid: 0, name: "装备制造大类", type: 1, id: 8,children: [
    {id: 53,name: "机械设计制造类",pid: 8,type: 2},
    {id: 54,name: "机电设备类",pid: 8,type: 2},
    {id: 55,name: "自动化类",pid: 8,type: 2},
  ]},
  {pid: 0, name: "电子与信息大类", type: 1, id: 2, children: [
    {id: 23,name: "电子信息类",pid: 2,type: 2},
    {id: 24,name: "计算机类",pid: 2,type: 2},
    {id: 25,name: "通信类",pid: 2,type: 2},
  ]},
]

const szysList= [
  {id: 113, pid: 0, name: "工匠精神"},
  {id: 104, pid: 4, name: "职业道德"},
  {id: 111, pid: 0, name: "文化自信"},
  {id: 102, pid: 0, name: "行业发展"},
  {id: 101, pid: 0, name: "专业发展"},
]

const areaList = [
  {name: "北京", id: 1},
  {name: "天津", id: 2},
  {name: "河北", id: 3},
  {name: "山西", id: 4},
  {name: "内蒙古", id: 5},
  {name: "辽宁", id: 6},
  {name: "上海", id: 9},
  {name: "青海", id: 29},
  {name: "新疆", id: 31},
]

const resTypeList =  [{type: 1, name: "图文"}, {type: 2, name: "视频"}]

const szztkDataList = [
  {level1Name:"国家概况",level2Name:"",level3List:[],current0:"1",openKeys0:[],category_id:1},
  {level1Name:"政策精神",level2Name:"国家政策",level3List:[],current0:"2",openKeys0:["sub1"],category_id:2},
  {level1Name:"领导讲话",level2Name:"领导人活动报道",level3List:[],current0:"5",openKeys0:["sub2"],category_id:5},
  {level1Name:"史料文献",level2Name:"经典著作",level3List:[{name:"马恩全集",id:8},{name:"列宁全集",id:9}],current0:"8",openKeys0:["sub3"],category_id:8},
  {level1Name:"重要人物",level2Name:"领袖人物",level3List:[{name:"毛泽东",id:27},{name:"刘少奇",id:28},{name:"周恩来",id:29},{name:"朱德",id:30},{name:"任弼时",id:31},{name:"邓小平",id:32}],current0:"14",openKeys0:["sub4"],category_id:27},
  {level1Name:"重大事件",level2Name:"党史大事",level3List:[],current0:"16",openKeys0:["sub5"],category_id:34},
]

const homeSearchList = [
  {id: 113, pid: 0, name: "工匠精神"},
  {id: 111, pid: 0, name: "文化自信"},
  {id: 112, pid: 0, name: "先进典型人物"},
]

export default{
  subjectList,
  szysList,
  areaList,
  resTypeList,
  szztkDataList,
  homeSearchList
}