<template>
  <div class="iframe-outer">

    <SzztkHeader />
    <div v-if="detailsData.address">
      <iframe frameborder="0" marginheight="0" class="detail-con" :src="detailsData.address"></iframe>
    </div>
    <div v-else class="noData-box">
      <img src="../../assets/image/jsckzyxy/empty.png" alt="">
      <div class="text">暂无相关内容</div>
    </div>
    <Loading :showLoading="loading" />

  </div>

</template>

<script>
import SzztkHeader from "../../components/szztkHeader.vue"
import services from "../../assets/js/services"
import Loading from "../../components/loading.vue";

export default {
  name: "detailIframe",
  components: {
    SzztkHeader,
    Loading
  },
  data () {
    return {
      special_id: '',
      detailsData: {},
      loading: false,
      school_code: localStorage.getItem("schoolCode"),
    }
  },
  created () {
    this.isLogin()
  },

  methods: {
    async isLogin () {
      this.loading = true
      this.special_id = this.$route.params.id;
      let data = await services.getSzztkDetail({ id: this.special_id })
      if (data.code == '0000' && data.data) {
        // if (data.data.is_open == 2) { //是否公开，1是2否
        //   this.$router.push({ path: `/${this.school_code}/zy/szztk`, query: { flag: 2 } })
        // } else {
        if (data.data.status == 1) { // 1正常  2下架
          if (data.data.address) {
            if (data.data.address.indexOf('http://') > -1) {
              window.open(data.data.address, '_self')
            }
          }
          this.detailsData = data.data
        } else {
          this.$message.error("专题不存在");
        }
        // }
      } else if (data.code == '2004') {
        this.$message.error("专题不存在");
        // this.$router.push({ path: "/szztk", query: { flag: 2 } })
      }
      this.loading = false
    }
  },

}
</script>

<style scoped>
.iframe-outer {
  width: 100vw;
  padding: 0;
  margin: 0;
}
.detail-con {
  width: 100vw;
  height: 100vh;
}

.noData-box {
  width: 100%;
  height: calc(100vh - 232px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.noData-box img {
  width: 240px;
  height: auto;
  margin: 0 auto 30px;
}
.noData-box .text {
  color: #76797e;
  font-size: 14px;
}
</style>