<template>
  <div ref="container" class="containers">
    <WhrjHeader />
    <div class="sdcm-container-box">
      <div class="sdcm-content-box w1280">
        <div :class="['side-bar',position?'position':'']" ref="sideBar">
          <div class="header-box"></div>
          <div class="body-box">
            <div :class="['item',currentIndex==index?'active':'']" v-for="(item,index) in zxjsData" :key="index" @click="currentSideBar(index,item)">
              <div class="star"></div>
              <div class="text">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="item" :ref="'point'+item.id" v-for="(item,index) in zxjsData" :key="index">
            <div class="header-box">
              <div class="inner-box">
                <div class="star"></div>
                <div class="text">{{item.name}}</div>
              </div>
            </div>
            <!-- <div class="content-box" v-html="item.text">

            </div> -->
            <div class="content-box"> 暂无介绍</div>
            <div class="footer-box"></div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <WhrjFooter :color="'#3E140B'" />
  </div>
</template>

<script>
import WhrjHeader from "../../components/WhrjHeader"
import WhrjFooter from "../../components/WhrjFooter"
import sdcmData from "./sdcmData"

export default {
  data () {
    return {
      zxjsData: sdcmData.zxjsData,
      currentIndex: 0,
      position: false,
    }
  },
  components: {
    WhrjHeader,
    WhrjFooter
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll)
    })
    this.handleScroll()
  },
  methods: {
    currentSideBar (index, item) {
      this.currentIndex = index
      let dom = this.$refs['point' + item.id];
      let offsetTop = dom[0].offsetTop;
      this.$nextTick(() => {
        if (document.body.scrollTop) {
          // chrome
          document.body.scrollTop = offsetTop - 25
        } else if (document.documentElement.scrollTop) {
          // firefox
          document.documentElement.scrollTop = offsetTop - 25
        } else if (window.pageYOffset) {
          // safari
          window.pageYOffset = offsetTop - 25
        } else if (document.body.clientTop) {
          document.body.clientTop = offsetTop - 25
        }
      })
    },
    handleScroll () {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset;
      if (scrollTop <= 1) {
        document.body.scrollTop = 1
        document.documentElement.scrollTop = 1
        window.pageYOffset = 1
      } else {
        if (scrollTop >= 218) {
          this.position = true
        } else {
          this.position = false
        }
      }
    },
    destroyed () {
      window.removeEventListener("scroll", this.handleScroll)
    },
  }
}
</script>

<style lang="less" scoped>
* {
  transition: all 0.3s;
}
.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
  overflow: hidden;
}

.clearfix {
  zoom: 1;
}

.w1280 {
  width: 1280px;
  margin: 0 auto;
}
.containers {
  margin-top: 1px;
}
.sdcm-container-box {
  background-image: url('../../assets/image/sdcmzyxy/zcwj-body.png');
  background-size: cover;
  padding: 22px 0 44px;
  .sdcm-content-box {
    background-color: #fff;
    padding: 0 52px 0 43px;
    min-height: calc(100vh - 330px);
    .side-bar {
      &.position {
        position: fixed;
      }
      width: 264px;
      float: left;
      top: 0px;
      .header-box {
        height: 123px;
        background-image: url('../../assets/image/sdcmzyxy/slidebar/ptjs.png');
        background-size: cover;
      }
      .body-box {
        padding-left: 38px;
        display: none;
        .item {
          cursor: pointer;
          width: 226px;
          height: 60px;
          line-height: 60px;
          background-color: #fff;
          border-bottom: 1px solid #f3f3f3;
          padding: 0 14px;
          display: flex;
          align-items: center;
          .star {
            width: 18px;
            height: 17px;
            background-image: url('../../assets/image/sdcmzyxy/slidebar/star.png');
            background-size: cover;
            margin-right: 8px;
          }
          .text {
            font-size: 17px;
            color: #262626;
          }
          &.active {
            background: #c00800;
            border: none;
            .star {
              background-image: url('../../assets/image/sdcmzyxy/slidebar/star-active.png');
            }
            .text {
              color: #fff;
            }
          }
        }
      }
    }
    .right-container {
      float: right;
      width: 840px;
      padding: 25px 0 0 0;
      .item {
        margin-bottom: 41px;
        .header-box {
          margin-bottom: 18px;
          position: relative;
          text-align: center;
          &::before {
            content: 'Platform  introduction';
            display: inline-block;
            position: absolute;
            bottom: -26px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #eeeeee;
          }
          // &::before {
          //   content: '';
          //   display: inline-block;
          //   position: absolute;
          //   bottom: 0;
          //   width: 100%;
          //   height: 1px;
          //   background-color: #f4f4f4;
          // }
          .inner-box {
            // position: relative;
            // display: inline-block;
            // padding-bottom: 18px;
            // border-bottom: 2px solid #960202;
            // border-radius: 1px;
            // .star {
            //   width: 17px;
            //   height: 18px;
            //   background-size: cover;
            //   background-image: url('../../assets/image/sdcmzyxy/slidebar/star-active.png');
            //   display: inline-block;
            //   vertical-align: middle;
            // }
            .text {
              font-size: 20px;
              font-weight: bold;
              color: #960202;
              margin-left: 8px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
        .content-box {
          font-size: 16px;
          color: #333333;
          line-height: 28px;
          width: 855px;
          height: 300px;
          background-size: cover;
          background-image: url('../../assets/image/sdcmzyxy/ptjs-body.png');
          padding: 40px 29px 0 40px;
          ::v-deep img,
          ::v-deep video,
          ::v-deep table {
            max-width: 100%;
          }
          ::v-deep p {
            font-size: 18px;
            color: #333333;
            line-height: 32px;
          }
        }
        .footer-box {
          width: 855px;
          height: 230px;
          background-size: cover;
          background-image: url('../../assets/image/whrjgczyxy/ptjs/ptjs-footer.jpg');
        }
      }
    }
  }
}
</style>