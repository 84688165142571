<template>
  <div class="nav-index">
    <div class="header">
      <div class="header-logo">
        <div class="left-logo" @click="$router.push(`/${school_code}/zy`)"></div>
      </div>
      <template>
        <div
          v-if="appsUserInfo && appsUserInfo.name"
          class="login-dropdown-box"
        >
          <div class="login-after">{{ appsUserInfo.name }}</div>
          <div class="login-sub-box">
            <template
              v-if="
                appsUserInfo.user_type == 2 ||
                (appsUserInfo.user_type == 1 && appsUserInfo.role_ids)
              "
            >
              <div class="hover-item">
                <a
                  :href="`${services.BEIKE_SERVER}/${school_code}/sz/school/resource`"
                  target="_blank"
                  >校内共建资源库</a
                >
              </div>
              <div class="hover-item">
                <a
                  :href="`${services.BEIKE_SERVER}/${school_code}/lesson/resource`"
                  target="_blank"
                  >备课资源库</a
                >
              </div>
              <div class="hover-item">
                <a
                  :href="`${services.BEIKE_SERVER}/${school_code}/lesson/works`"
                  target="_blank"
                  >协同备课</a
                >
              </div>
              <div class="hover-item">
                <a
                  :href="`${services.BEIKE_SERVER}/${school_code}/sz/favorite`"
                  target="_blank"
                  >资源收藏</a
                >
              </div>
            </template>
            <template
              v-if="
                appsUserInfo.user_type == 2 ||
                (appsUserInfo.user_type == 1 && appsUserInfo.role_ids == 2)
              "
            >
              <div class="hover-item">
                <a
                  :href="`${services.BEIKE_SERVER}/${school_code}/manage/user`"
                  target="_blank"
                  >用户管理</a
                >
              </div>
            </template>
            <div class="login-lines"></div>
            <div class="hover-item">
              <a
                :href="`${services.APPS_WEB}/${school_code}/worker`"
                target="_blank"
                >信息服务中心</a
              >
            </div>
            <div class="hover-item">
              <a
                :href="`${services.APPS_WEB}/${school_code}/userInfo`"
                target="_blank"
                >个人信息设置</a
              >
            </div>
            <div v-if="!isMobile" class="hover-item">
              <a
                :href="`${services.APPS_WEB}/${school_code}/editPassword`"
                target="_blank"
                >修改密码</a
              >
            </div>
            <div class="hover-item" @click="logoutEvent">
              <a href="javascript:;">退出</a>
            </div>
          </div>
        </div>
        <template v-else>
          <div class="btns">
            <div class="login-btn" @click="loginModal(true)">登录</div>
            <a
              class="free-btn"
              :href="services.YXT_GW + '/need'"
              target="_blank"
              >申请免费试用</a
            >
          </div>
        </template>
      </template>
    </div>
    <div class="resize-con">
      <div class="gjjs-title"></div>
      <div class="gjjs-tooltip">
        循迹溯源图新志&nbsp;&nbsp;&nbsp;凝心聚魂谱华章&nbsp;&nbsp;&nbsp;砥砺奋进迈新程
      </div>
      <div class="gjjs-info">
        <div>职业高度认同感</div>
        <div>强技立业自豪感</div>
        <div>敬业有德尊崇感</div>
        <div>革新图强使命感</div>
      </div>
      <div class="get-more" @click="toNextSlide">
        <div class="more-icon"></div>
        <div class="more-text">查 看 更 多</div>
      </div>
    </div>

    <Login></Login>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Login from "../../../components/Login.vue";
import services from "../../../assets/js/services";
import utils from "../../../assets/js/utils";
export default {
  components: {
    Login,
  },
  data() {
    return {
      services,
      isMobile: utils.isMobile(),
      school_code: localStorage.getItem("schoolCode"),
    };
  },
  computed: {
    ...mapState({
      loginVisible: (state) => state.appsLogin.loginVisible,
      appsUserInfo: (state) => state.appsLogin.appsUserInfo,
    }),
  },
  created() {
    this.getAppsLoginUserInfo();
  },
  methods: {
    ...mapMutations([
      "setSearchKeyWord",
      "setAppsLoginVisible",
      "setSearchSzzykData",
      "setSearchElementData",
      "resetSzzykTotal",
    ]),
    ...mapActions([
      "getAppsImageCaptcha",
      "getLoginSchoolInfo",
      "getAppsLoginUserInfo",
      "requestAppsLogout",
      "goAppsDetailsBeforeLogin",
      "schoolLoginRedirect",
    ]),
    loginModal(val) {
      let that = this;
      // 如果需要统一登录,跳转
      let param = {
        format: "json",
        school_code: that.school_code,
        from: window.location.href,
      };
      that.schoolLoginRedirect(param).then((res) => {
        if (
          res.code == "0000" &&
          res.data &&
          !(res.data.redirect.indexOf(services.APPS_WEB) > -1)
        ) {
          return window.open(res.data.redirect, "_self");
          // return window.open(`${services.APPS_WEB}/${this.school_code}/login?from=${window.location.href}`, "_self")
        } else {
          if (val) {
            that.getAppsImageCaptcha();
          }
          that.setAppsLoginVisible(val);
        }
      });
    },
    async logoutEvent() {
      let that = this;
      await that.requestAppsLogout().then(() => {
        this.$message.success("已退出登录");
      });
    },
    toNextSlide() {
      this.$emit("toNextSlide");
    },
  },
};
</script>

<style lang="less" scoped>
.nav-index {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/image/gjjs/index/nav-index/nav1-bg.png");
  background-size: cover;
  background-position: center;
  padding-top: 30px;
  background-repeat: no-repeat;

  .header {
    display: flex;
    margin-left: 45px;
    margin-right: 145px;
    justify-content: space-between;
    .login-dropdown-box {
      margin-left: 35px;
      height: 55px;
      line-height: 40px;
      position: relative;
      display: inline-block;
      .login-after {
        color: #ffffff;
        background: #d48432;
        font-size: 18px;
        display: inline-block;
        line-height: 34px;
        padding:  0 15px;
        &::after {
          display: inline-block;
          content: "";
          width: 7px;
          height: 5px;
          margin-left: 5px;
          background-image: url("../../../assets/image/common/up-icon.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% auto;
          vertical-align: 3px;
          transition: background-image 0.3s;
        }
      }
      .login-sub-box {
        display: none;
        position: absolute;
        padding: 10px 20px;
        box-sizing: border-box;
        top: 45px;
        left: 50%;
        transform: translateX(-50%);
        width: 140px;
        text-align: left;
        background-color: transparent;
        box-shadow: 0px 1px 6px 0px rgba(130, 130, 130, 0.2);
        border-radius: 2px;
        transition: background-color 0.3s;
        z-index: 100;
        .hover-item {
          display: block;
          line-height: 26px;
          &:hover {
            background-color: #fff;
          }
          a {
            color: #393939;
            font-size: 12px;
            display: block;
            &:hover {
              color: #e64757;
              background-color: #fff;
            }
          }
        }
        .login-lines {
          width: 100%;
          height: 1px;
          background: #f3f4f5;
          margin: 5px 0;
        }
      }
      &:hover .login-after::after {
        background-image: url("../../../assets/image/common/down-icon.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover .login-sub-box {
        display: block;
        background-color: #ffffff;
      }
    }
    .header-logo {
      display: flex;
      .left-logo {
        width: 418px;
        height: 60px;
        background-image: url("../../../assets/image/gjjs/index/nav-index/jyzx-logo.png");
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    .btns {
      display: flex;
      .login-btn {
        margin-top: 5px;
        width: 72px;
        height: 32px;
        background: #d48432;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 31px;
        text-align: center;
        margin-left: 53px;
        cursor: pointer;
      }
      .free-btn {
        width: 136px;
        height: 32px;
        background: #78171c;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 31px;
        text-align: center;
        margin-top: 5px;
        margin-left: 10px;
      }
    }
  }
  .resize-con {
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    .gjjs-title {
      margin: 0 auto;
      width: 1127px;
      height: 434px;
      background-image: url("../../../assets/image/gjjs/index/nav-index/nav-index-title.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      animation: titMove 2s;
    }
    .gjjs-tooltip {
      margin: 0 auto;
      width: 842px;
      height: 59px;
      background-image: url("../../../assets/image/gjjs/index/nav-index/nav-index-tooltip.png");
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      font-size: 22px;
      font-family: Milky Han Mono CN;
      font-weight: 600;
      color: #faf4b2;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: tooltipMove 1.5s;
    }
    .gjjs-info {
      margin: 2% auto 0;
      width: 415px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 57px;
      font-size: 22px;
      font-family: Milky Han Mono CN;
      font-weight: bold;
      color: #540c07;
      line-height: 34px;
      animation: tooltipMove 1.5s;
    }
    .get-more {
      display: flex;
      margin: 60px auto 0;
      cursor: pointer;
      justify-content: center;
      .more-icon {
        width: 42px;
        height: 24px;
        background-image: url("../../../assets/image/gjjs/index/nav-index/nav-more.png");
        background-size: cover;
        margin-right: 14px;
      }
      .more-text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ab030c;
      }
    }
  }
}
// @media screen and (max-height: 1080px ) {
//   .resize-con {
//     top: 100px;
//   }
// }
// @media screen and (max-height: 950px ) {
//   .resize-con {
//     top: 50px;
//   }
// }
// @media screen and (max-height: 600px)  {
//   .resize-con {
//     transform: scale(0.55) translate(-50%,-50%)  !important;
//     transform-origin: 0 0;
//     left: 50% !important;
//   }
// }
@media screen and (max-width: 1900px) and (min-width: 1700px) {
  .resize-con {
    transform: scale(0.9) translate(-50%, -50%) !important;
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1550px) {
  .resize-con {
    transform: scale(0.84) translate(-50%, -50%) !important;
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1550px) {
  .resize-con {
    transform: scale(0.74) translate(-50%, -50%) !important ;
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1400px) {
  .resize-con {
    transform: scale(0.6) translate(-50%, -50%) !important;
    transform-origin: 0 0;
    // left: 50% !important;
  }
}
// @media screen and (max-width: 1300px)  {
//   .resize-con {
//     transform: scale(0.55) translate(-50%,-50%) !important;
//     transform-origin: 0 0;
//     // left: 50% !important;

//   }
// }
@keyframes titMove {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes tooltipMove {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>