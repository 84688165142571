<template>
  <div class="sdcm-footer" :style="`background:${color?color:'#251d1b'}`">
    <div class="copyright">
      <p>
        <span>版权所有</span>
        <span>北京中教互联教育科技有限公司</span>
        <span>Eol corporation</span>
      </p>
      <p>
        <a href="http://www.eol.cn/html/cer.net/icp.htm" target="_blank">京ICP证140769号</a>
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">京ICP备2022007846号-1 </a>
        <a href="http://img.eol.cn/images/indexnew/www1024.jpg" target="_blank">京网文[2014]2106-306号</a>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236" target="_blank">京公网安备 11010802020236号</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped lang="less">
.sdcm-footer {
  width: 100%;
  height: 90px;
  background: #251d1b;
  color: #fff;
  font-size: 14px;
  padding: 24px 0;
  .copyright {
    a {
      color: #fff;
      font-size: 14px;
      margin: 0 3px;
      &:hover {
        cursor: pointer;
        color: #ae1101;
      }
    }
    & > p {
      text-align: center;
      span {
        margin: 0 5px;
      }
    }
  }
}
</style>