// import Vue from 'vue'
import services from '../assets/js/services.js'
import axios from "axios";
axios.defaults.withCredentials = true; 

export default {
  state: {
    relevantHomeList: [],
  },
  getters: {},
  actions: {
    loadHomeMediaData(context, payload){
      let timestamp = Date.parse(new Date());
      return new Promise((resolve, reject) => {
        axios.get(`${services.MEDIA_SERVER}/${payload.type}/NewsList_${payload.page}.json?${timestamp}`).then(resData => {
          // console.log("resData", resData);
          resolve(resData);
        }).catch(err => {
          reject(err);
        })
      });  
    },
    getSzzykData(context, payload){
      return new Promise((resolve,reject) =>{
        axios.get(`${services.rmtUrl}/api/people/article?category_id=${payload.id}&length=${payload.length}`).then(resData =>{
          resolve(resData);
        }).catch(err =>{
          reject(err);
        })
      });
    },
    getSzzykDetail(context, payload){
      return new Promise((resolve,reject) => {
        axios.get(`${services.rmtUrl}/api/people/article/${payload.id}`).then(resData =>{
          resolve(resData);
        }).catch(err =>{
          reject(err);
        })
      });
    },
    // 获取首页的推荐思政资源库列表数据
    getHomeRelevantData(context, payload) {
      return services.getSzzykListData(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setHomeRelevantData', {
            'data': res.data ? res.data : null
          })
        }
        return res;
      })
    },
  },
  mutations: {
    setHomeRelevantData(state, payload){
      if(payload.data){
        state.relevantHomeList = payload.data.items;
      } else {
        state.relevantHomeList = [];
      }
    },
  }
}