<template>
  <div class="kcszal-content">
    <template v-if="school_code == utils.whrj_code">
      <WhrjHeader :active="`/${this.school_code}/zy/szzyk`" />
    </template>
    <template v-else>
      <Header />
      <Navbar />
    </template>
    <div class="alzy-header"></div>
    <div class="category-box">
      <div class="category_list">
        <div class="category_top">
          <div class="top_label">专业大类</div>
          <div class="top_list">
            <div
              :class="['top_item', { active: category_idOne == '' }]"
              @click="getcategoryOne('')"
            >
              全部
            </div>
            <div
              :class="['top_item', { active: category_idOne == item.id }]"
              v-for="item in categoryOne"
              :key="item.id"
              @click="getcategoryOne(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="category_bottom" v-if="category_idOne != ''">
          <div class="bottom_label">专业小类</div>
          <div class="bottom_list line">
            <div
              :class="['bottom_item', { active: category_idTwo == '' }]"
              @click="getcategorytwo('')"
            >
              全部
            </div>
            <div
              :class="['bottom_item', { active: category_idTwo == item.id }]"
              v-for="item in categoryTwo"
              :key="item.id"
              @click="getcategorytwo(item.id,item.pid)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="category_bottom">
          <div class="bottom_label">资源类型</div>
          <div class="bottom_list">
            <div
              :class="['bottom_item', { active: categoryTag == '' }]"
              @click="setCategoryTag('')"
            >
              全部
            </div>
            <div
              :class="['bottom_item', { active: categoryTag == item.id }]"
              v-for="item in categoryTagList"
              :key="item.id"
              @click="setCategoryTag(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kcszal_bg">
      <div class="content_info">
        <div class="kcszal_list">
          <div
            class="kcszal_item"
            v-for="item in alzyList"
            :key="item.id"
            @click="getzyalInfo(item.id)"
          >
            <div class="item_img">
              <div :style="`background-image: url('${item.cover}');`"></div>
            </div>
            <div class="item_info">
              <div class="item_title">
                {{ item.name }}
              </div>
              <div class="item_keyword">
                {{ item.tags.join(" | ") }}
              </div>
            </div>
          </div>
        </div>
        <div class="Pagination-box">
          <APagination
            v-if="total > 10"
            v-model="page"
            :total="total"
            :page-size="pageSize"
            @change="onPaginationChange"
          ></APagination>
          <div v-else-if="alzyList.length <= 0" class="noData-box">
            <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
            <div class="text">暂无相关内容</div>
          </div>
        </div>
        <a-modal
          v-model="pdfVisible"
          title="资源预览"
          :afterClose="pdfCloseMadol"
          :footer="null"
          width="70vw"
          :centered="true"
        >
          <iframe
            :src="pdfUrl"
            frameborder="0"
            width="100%"
            height="600px"
          ></iframe>
          <div style="display: flex; justify-content: end; align-items: center">
            <template v-if="downloadList.some((item) => alzyInfoId == item)">
              <spin></spin>
              <div
                style="
                  font-size: 16px;
                  height: 40px;
                  padding: 0 20px;
                  line-height: 38px;
                  color: #1890ff;
                "
              >
                下载中...
              </div>
            </template>
            <Button type="link" size="large" @click="downloadpdf" v-else
              >下载</Button
            >
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { Pagination, Button, spin } from "ant-design-vue";
import services from "../../assets/js/services";
import { mapActions, mapState, mapMutations } from "vuex";
import Header from "../../components/Header.vue";
import Navbar from "../../components/Navbar.vue";
import axios from "axios";
import utils from "../../assets/js/utils";
import WhrjHeader from "../../components/WhrjHeader.vue";

export default {
  name: "xxzykcszal",
  components: {
    APagination: Pagination,
    Button,
    spin,
    Header,
    Navbar,
    WhrjHeader
  },
  data() {
    return {
      utils,
      school_code: localStorage.getItem("schoolCode"),
      categoryOne: [],
      categoryTwo: [],
      categoryTagList: [],
      categoryTag: "",
      category_idOne: "",
      category_idTwo: "",
      page: 1,
      pageSize: 10,
      alzyList: [],
      total: 0,
      // lastClickId: 103,
      // videoVisible: false,
      pdfVisible: false,
      // player: null,
      alzyInfoId: "",
      pdfUrl: "",
      fileName: "",
      videoFormat: "",
    };
  },
  created() {
    // console.log(window.scrollY);
    window.scrollTo(0, 0);
    this.category_idOne = this.$route.query.subjectBigId || ''
    this.category_idTwo = this.$route.query.subjectSmallId || ''
    this.categoryTag = this.$route.query.casesType || ''
    this.getalzyCategory(this.$route.query.flag);
    this.getalzyList();
    
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    ...mapMutations(["setDownloadIdList"]),
    pdfCloseMadol() {
      this.pdfVisible = false;
      this.pdfUrl = "";
          this.alzyInfoId = "";
      this.fileName = "";
    },

    async getalzyCategory(flag) {
      // this.categoryOne = [];
      let res = await services.getalzyCategory({
        school_code: this.school_code,
        subject_big_id: this.category_idOne,
        subject_small_id: this.category_idTwo,
        flag
      });
      if (res.code == "0000") {
        if (res.data.subject_big_list && res.data.subject_big_list.length) {
          this.categoryOne = res.data.subject_big_list;
        }
        if (res.data.subject_small_list && res.data.subject_small_list.length) {
          this.categoryTwo = res.data.subject_small_list;
        }
        if (res.data.type_list && res.data.type_list.length) {
          this.categoryTagList = res.data.type_list;
        }
      }
    },
    async getalzyList() {
      let res = await services.getalzyList({
        school_code: this.school_code,
        page: this.page,
        length: this.pageSize,
        subject_big_id: this.category_idOne,
        subject_small_id: this.category_idTwo,
        cases_type: this.categoryTag,
      });
      if (res.code == "0000") {
        if (res.data.items && res.data.items.length) {
          this.alzyList = res.data.items;
          this.total = res.data.total;
        }else {
          this.alzyList = [];
          this.total = 0;
        }
      }
    },
   
    getcategoryOne(id) {
      this.category_idOne = id;
      this.category_idTwo = "";
      this.categoryTag = "";
      this.page = 1;
      this.getalzyCategory();
      this.getalzyList();
    },
    getcategorytwo(id,pid) {
      this.category_idTwo = id;
      if(pid) {
      this.category_idOne = pid;
      }
      this.categoryTag = "";
      this.page = 1;
      this.getalzyCategory();
      this.getalzyList();
    },
    setCategoryTag(id) {
      this.categoryTag = id;
      this.page = 1;
      // this.getalzyCategory();
      this.getalzyList();
    },
    onPaginationChange(current) {
      this.page = current
      this.getalzyList();
    },
    getzyalInfo(id) {
      this.goAppsDetailsBeforeLogin().then(async () => {
        let res = await services.getalzyInfo(id);
        if (res.code == "0000") {
          this.pdfUrl = res.data.view_file;
          this.fileName = res.data.name;
          this.alzyInfoId = res.data.id;
          this.pdfVisible = true;
        }
      });
    },
     setKeywrodPadding() {
      let keyBoxs = document.querySelectorAll(".item_keyword");
      for (let i = 0; i < keyBoxs.length; i++) {
        if (keyBoxs[i].offsetHeight > 32) {
          keyBoxs[i].style.lineHeight = "22px";
          keyBoxs[i].style.padding = "10px";
        }
      }
    },
    createUrlBlob(url, fileName) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
    downloadpdf() {
        this.setDownloadIdList(this.alzyInfoId);
      axios({
        url: services.HOST + "/api/school/cases/download",
        params: { id: this.alzyInfoId },
        method: "get",
        responseType: "arraybuffer",
      }).then((res) => {
        let newUrl = window.URL.createObjectURL(new Blob([res.data]));
        this.setDownloadIdList(this.alzyInfoId);
        this.createUrlBlob(
          newUrl,
          this.fileName + '.pdf'
        );
      });
    },
  },
  computed: {
    ...mapState({
      downloadList: (state) => state.class.downloadIdList,
    }),
  },
};
</script>

<style lang="less" scoped>
.kcszal-content {
  .alzy-header {
    width: 100%;
    height: 300px;
    background-image: url("../../assets/image/xxzyk/xxzyal-banner.png");
    background-size: cover;
    background-position: center;
  }
  .category-box {
    width: 100%;
    background: #f8fafa;
    .category_list {
      width: 1200px;
      margin: 0 auto;
      padding-top: 30px;
      .category_top {
        display: flex;
        .top_label {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          margin-right: 28px;
          white-space: nowrap;
          line-height: 24px;
        }
        .top_list {
          flex: 1;
          padding-bottom: 16px;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background: #e9e9e9;
            opacity: 0.65;
            bottom: 0;
          }
          .top_item {
            padding: 0 14px;
            margin-bottom: 6px;
            line-height: 24px;
            font-size: 14px;
            font-weight: 400;
            color: #6d7074;
            margin-right: 8px;
            border-radius: 12px;
            white-space: nowrap;
            cursor: pointer;
            &.active {
              background: #e23f4b !important;
              color: #fff !important;
            }
            &:hover {
              background: #fdf3f3;
              color: #e23f4b;
            }
          }
        }
      }
      .category_bottom {
        display: flex;
        padding-top: 16px;
        .bottom_label {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          margin-right: 28px;
          white-space: nowrap;
          line-height: 24px;
        }
        .bottom_list {
          flex: 1;
          padding-bottom: 16px;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          &.line::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background: #e9e9e9;
            opacity: 0.65;
            bottom: 0;
          }
          .bottom_item {
            padding: 0 14px;
            line-height: 24px;
            font-size: 14px;
            margin-bottom: 6px;
            font-weight: 400;
            color: #6d7074;
            margin-right: 8px;
            border-radius: 12px;
            white-space: nowrap;
            cursor: pointer;
            &.active {
              background: #e23f4b !important;
              color: #fff !important;
            }
            &:hover {
              background: #fdf3f3;
              color: #e23f4b;
            }
          }
        }
      }
    }
  }
  .kcszal_bg {
    width: 100%;
    background-image: url("../../assets/image/xxzyk/index/list-bg.png");
    background-size: 100% auto;
    background-position: center bottom;
    padding-bottom: 28px;
    background-repeat: no-repeat;
    /deep/ .content_info {
      width: 1200px;
      margin: 0 auto;
      padding-top: 22px;
      position: relative;
      .kcszal_list {
        display: flex;
        justify-content: space-between;

        flex-wrap: wrap;
        .kcszal_item {
          display: flex;
          margin-bottom: 16px;
          width: 577px;
          padding-bottom: 16px;
          border-bottom: 1px solid #f2f2f2;
          cursor: pointer;
          &:hover .item_img div {
            transform: scale(1.1);
          }
          .item_img {
            width: 188px;
            height: 114px;
            overflow: hidden;
            position: relative;
            div {
              width: 188px;
              height: 114px;
              transition: all 0.5s;
              background-size: cover;
              background-position: center;
            }
            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 10;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
          .item_info {
            flex: 1;
            margin-left: 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .item_title {
              font-size: 16px;
              font-weight: bold;
              color: #3a3a3a;
              line-height: 24px;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              line-clamp: 2;
              -webkit-line-clamp: 2; //显示几行
              margin-top: 4px;
            }
            .item_keyword {
              width: 100%;
              min-height: 32px;
              max-height: 64px;
              background: #fff7f7;
              line-height: 32px;
              font-size: 14px;

              font-weight: 400;
              color: #be7f6e;
              padding-left: 10px;
            }
          }
        }
      }
      .Pagination-box {
        display: flex;
        margin-bottom: 28px;
        justify-content: center;
        .noData-box {
          width: 100%;
          text-align: center;
          height: 770px;
          padding-top: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 150px;
          img {
            width: 240px;
            height: auto;
            margin: 0 auto 30px;
          }
          .text {
            color: #76797e;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.modal-box {
  top: 50% !important;
  transform: translateY(-50%);
}
</style>