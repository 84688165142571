import { render, staticRenderFns } from "./mstd-detail.vue?vue&type=template&id=ca42f42c&scoped=true&"
import script from "./mstd-detail.vue?vue&type=script&lang=js&"
export * from "./mstd-detail.vue?vue&type=script&lang=js&"
import style0 from "./mstd-detail.vue?vue&type=style&index=0&id=ca42f42c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca42f42c",
  null
  
)

export default component.exports