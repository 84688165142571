<template>
  <div class="nav-jxsy">
    <div class="resize-con">
      <div class="jxsy-tit"></div>
      <div class="jxsy-content">
        <div
          class="sy-item"
          v-for="i in 4"
          :key="i"
          @click="toMoreList(i - 1)"
        ></div>
      </div>
      <div class="get-more" @click="toMoreList(-1)">
        <div class="more-icon"></div>
        <div class="more-text">查 看 更 多</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
    };
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    toMoreList(ind) {
      this.goAppsDetailsBeforeLogin().then(() => {
        let routeUrl = this.$router.resolve({
          path: `/${this.school_code}/gjjs/jxshany`,
          query: {
            ind,
          },
        });
        window.open(routeUrl.href, "_blank");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.nav-jxsy {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 4.2%;
  background-image: url("../../../assets/image/gjjs/index/nav-index/nav-xsgj-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  .resize-con {
    position: absolute;
    left: 308px;
top: 50%;
    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 468px;
      height: 548px;
      top: 54px;
      left: -246px;
      z-index: 0;
      background-image: url("../../../assets/image/gjjs/index/nav-xsgj/gjjs-sidber.png");
      background-size: cover;
      background-repeat: no-repeat;

      z-index: 0;
    }
    .jxsy-tit {
      width: 700px;
      height: 109px;
      margin: 0 auto;
      background-image: url("../../../assets/image/gjjs/index/nav-jxshanyao/jxshany-tit.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      // margin-left: 23.5%;
    }
    .jxsy-content {
      position: relative;
      z-index: 1;
      box-shadow: 0px 11px 26px 4px rgba(168, 141, 147, 0.68);
      width: 1100px;
      height: 630px;
      margin-top: 43px;

      // margin-left: 16%;
      background-color: #fcf4e9;
      background-image: url("../../../assets/image/gjjs/index/nav-jxshanyao/jxshanyao-bg.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: bottom;
      // margin-top: 2.1%;
      padding: 0 30px;
      display: flex;
      justify-content: space-evenly;
      .sy-item {
        width: 220px;
        height: 492px;
        margin-top: 104px;
        background-size: 100% auto;
        background-repeat: no-repeat;
        transition: all 0.5s;
        cursor: pointer;
        &:nth-child(even) {
          margin-top: 46px;
        }
        &:nth-child(1) {
          background-image: url("../../../assets/image/gjjs/index/nav-jxshanyao/shanyao1.png");
        }
        &:nth-child(2) {
          background-image: url("../../../assets/image/gjjs/index/nav-jxshanyao/shanyao2.png");
        }
        &:nth-child(3) {
          background-image: url("../../../assets/image/gjjs/index/nav-jxshanyao/shanyao3.png");
        }
        &:nth-child(4) {
          background-image: url("../../../assets/image/gjjs/index/nav-jxshanyao/shanyao4.png");
        }
        &:hover {
          transform: translateY(-10px);
          // box-shadow: 0px 6px 5px 4px rgba(168,141,147,0.68);
        }
      }
    }
    .get-more {
      display: flex;
      // margin: 2% auto 0;
      // margin-left: 38%;
      margin-top: 22px;
      justify-content: center;
      cursor: pointer;
      .more-icon {
        width: 42px;
        height: 24px;
        background-image: url("../../../assets/image/gjjs/index/nav-index/nav-more.png");
        background-size: cover;
        background-repeat: no-repeat;

        margin-right: 14px;
      }
      .more-text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ab030c;
      }
    }
  }
}@media screen and (max-width: 1900px) and (min-width: 1700px) {
  .resize-con {
    transform: scale(0.9) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-height: 600px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .resize-con {
    transform: scale(0.84) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1550px) {
  .resize-con {
    transform: scale(0.8) translateY(-50%);
    transform-origin: 0 0;
  }
}

@media screen and (max-width: 1550px) {
  .resize-con {
    transform: scale(0.74) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1500px) {
  .resize-con {
    transform: scale(0.7) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1400px) {
  .resize-con {
    transform: scale(0.6) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1300px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (min-width: 1900px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
@media screen and (min-height: 1080px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
</style>