<template>
  <div>
    <Header />
    <Navbar />
    <!-- 轮播 -->
    <div class="model-menu-box" @mouseenter="swiperEnter" @mouseleave="swiperLeave">
      <swiper ref="mySwiper" v-if="indexBannerArr.length > 0" class="eol-swiper-box" :options="swiperOption">
        <!-- 只针对扬职大首屏显示的banner -->
        <swiper-slide key="sz-yz-banner" v-if="school_code == yzSchoolCode">
          <a class="eol-banner-item" href="https://zhsz.jiaoyu.cn/100047/10/index.html" target="_blank" :style='`background: url(${require("../assets/image/home/sz-yz-banner.png")}) no-repeat center/cover;`'></a>
        </swiper-slide>

        <template v-for="(item,index) in indexBannerArr">
          <swiper-slide :key="index" v-if="!item.hidden">
            <template v-if="item.url">
              <div class="eol-banner-item" :style='`background-image: url(${item.thumb_image});background-size: cover;`' @click="goToNewLink(item)"></div>
            </template>
            <template v-else>
              <div class="eol-banner-item" :style='`background-image: url(${item.thumb_image});background-size: cover;`'></div>
            </template>
          </swiper-slide>
        </template>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="container model-first">
        <div class="link-right-box">
          <!-- <a href="javascript:void(0);" @click="goJiaowuZiyuanku" class="link-item res">我的<br />资源库</a> -->
          <!-- <a v-if="!isMobile" href="javascript:void(0);" @click="goJiaowuBeike" class="link-item zxbk">在线<br />备课</a> -->
        </div>

      </div>
    </div>

    <!-- 思政元素 -->
    <div class="model-sz-box">
      <div class="container">
        <div class="model-label-box">
          <div class="left-title-box">思政元素内涵理解</div>
          <router-link :to="{path: `/${school_code}/zy/szysk`}">
            <div class="right-more-box">更多</div>
          </router-link>
        </div>
        <div class="sizheng-list">
          <!-- <div v-for="(item,index) in szsyszysArr" :key="index" :style="`background: url('${item.thumb_image}') no-repeat center;background-size: 100% auto;`" class="sizheng-item" @click="toSzyskDetail(item)">{{item.name}}</div> -->
          <div v-for="(item,index) in szsyszysArr" :key="index" :class="['sizheng-item', item.classname]" @click="toSzyskDetail(item)">{{item.name}}</div>
          <div class="sizheng-visibility"></div>
          <div class="sizheng-visibility"></div>
          <div class="sizheng-visibility"></div>
        </div>
      </div>
    </div>

    <!-- 课程思政资源库 -->
    <div class="model-second-box">
      <div class="second-inner">
        <div class="container">
          <div class="model-label-box">
            <div class="left-title-box">热门课程思政元素资源</div>
            <!-- <div class="category-list">
              <div :class="subject_big_id == 'all' ? 'category-item active' : 'category-item'" @click="getHomeRelevantMethod">推荐</div>
              <div v-for="(item,index) in subjectList" :key="'p'+index" :class="subject_big_id == item.id ? 'category-item active' : 'category-item'" @click="getHomeRelevantMethod(item)">{{item.name}}</div>
            </div> -->
            <router-link :to="{path: `/${school_code}/zy/szzyk`}">
              <div class="right-more-box">更多</div>
            </router-link>
          </div>

          <div class="resource-list">
            <div v-for="(item,index) in relevantHomeList" :key="index" class="resource-opt" @click="goDetails(item)">
              <!-- <div :class="item.type == 1 ? 'res-title word ellipsis' : 'res-title video ellipsis'">
                {{item.title}} <span v-if='item.is_open == 2' class="resopen">公开</span>
              </div> -->
              <div :class="item.type == 1 ? 'res-title word' : 'res-title video'">
                <span class="title-span ellipsis">{{item.title}}</span>
                <!-- <span v-if='item.is_open == 2' class="resopen">公开</span> -->
              </div>
              <div class="res-all-box">
                <div class="left-tags">
                  <template v-for="(sub,sinx) in item.element_list">
                    <div v-if="sinx < 3" :key="sinx" class="tag-opt">{{sub.name}}</div>
                  </template>
                </div>
                <div class="right-author ellipsis">{{item.source}}</div>
                <div class="right-operat-box">
                  <div :class="item.favorite == 1 ? 'active operat-item star' : 'operat-item star'" @click.stop="requestCollect(item)">{{item.favorite == 1 ? '取消收藏': '收 藏'}}</div>
                  <!-- <div class="operat-item" @click="goDetails(item)">在线备课</div> -->
                </div>
              </div>
            </div>
            <div class="resource-visibility"></div>
            <div class="resource-visibility"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 思政培训 -->
    <div class="model-third-box">
      <div class="container">
        <!-- <img class="szpx-img" src="../assets/image/home/szpx.png" /> -->
        <div class="model-label-box" style="margin-top: 30px;">
          <div class="left-title-box">推荐思政专题</div>
          <router-link :to="{path: `/${school_code}/zy/szztk`}">
            <div class="right-more-box">更多</div>
          </router-link>
        </div>
        <div class="middle-category">
          <div class="item" @click="clickSzztkCate(item)" v-for="item,index in szztkCategory" :key="index">{{item.name}}</div>
        </div>
        <div class="bottom-box">
          <div class="list-main-box" v-if="szszztBannerArr && szszztBannerArr.length>0">
            <div class="item" v-for="item,index in szszztBannerArr" :key="index" @click="resDetails(item)">
              <div class="thumb_img">
                <div class="inner_img" :style='`background-image: url(${item.cover});background-size: 100% 100%;`'></div>
              </div>
              <div class="desc">
                <div class="title">
                  {{item.name}}
                </div>
              </div>
              <div class="tag">
                <div class="tag-item" v-for="tag,tag_index in item.keywords" :key="tag_index">
                  {{tag}}
                </div>
              </div>
              <!-- <div class="open-box" v-if="item.is_open == 1"> -->
            </div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
          </div>
          <div v-else class="noData-box">
            <img src="../assets/image/jsckzyxy/empty.png" alt="">
            <div class="text">暂无相关内容</div>
          </div>
        </div>
      </div>
    </div>
    <component :is="dialogConfig.name"></component>
    <!-- <Loading :showLoading="isShowLoading" /> -->
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
// import { Carousel } from "ant-design-vue";
import { mapState, mapActions } from "vuex";
import "../assets/css/home.less";
import Navbar from "../components/Navbar.vue";
// import Loading from "../components/loading.vue";
import services from "../assets/js/services";
import szztkData from "../assets/js/szztkData"
import homeData from "../assets/js/homeData";
import utils from '../assets/js/utils';
import Header from '../components/Header.vue';
// import { Modal } from "ant-design-vue";
import appleRes from "../components/appleRes.vue";

export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
    Navbar,
    Header,
    appleRes
    // Loading
  },
  data () {
    return {
      school_code: localStorage.getItem("schoolCode"),
      isMobile: utils.isMobile(),
      // isShowLoading: false,
      indexBannerArr: [
        {
          thumb_image: require("../assets/image/home/dggjbanner.jpg"),
          url: `${window.origin}/${localStorage.getItem("schoolCode")}/gjjs`
        },
        {
          thumb_image: require("../assets/image/home/szktz-url-banner.png"),
          url: `${window.origin}/${localStorage.getItem("schoolCode")}/zy/szktz`,
          isNeedLogin: true,
        },
        {
          thumb_image: require("../assets/image/home/szbanner.jpg"),
          url: ''
        },

      ],  //首页banner
      szszztBannerArr: [], // 首页推荐专题
      szztkCategory: [], // 思政专题分类
      // szszztBannerArr: [
      //   {
      //     thumb_image: require("../assets/image/new-szztk/index/szztk-ylzldxjp.jpg"),
      //     id: "633414e59a5e5e3c497ec5e2"
      //   },
      //   {
      //     thumb_image: require("../assets/image/new-szztk/index/szztk-yzzsjdzj.jpg"),
      //     id: "6334151ba36edd3a6b02f692"
      //   },
      //   {
      //     thumb_image: require("../assets/image/new-szztk/index/szztk-cwmkszyjd.jpg"),
      //     id: "6334170c7fbfd2534a1ecd02"
      //   },
      //   {
      //     thumb_image: require("../assets/image/new-szztk/index/szztk-xds.jpg"),
      //     id: "6334176d2b17895c6b793db2"
      //   },
      // ], // 思政培训下banner
      szsyszysArr: [], // 思政元素banner
      swiperOption: {
        // loop: true,
        autoplay: {
          pauseOnMouseEnter: true,
        },
        speed: 400, // 切换图片速度
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // // 修改swiper自己或子元素时，自动初始化swiper
        // observer: true,
        // // 修改swiper的父元素时，自动初始化swiper
        // observeParents: true,
      },
      services,
      openKeys: [],//展开的菜单
      level1Name: "",
      level2Name: "",
      current: "",
      szztkDataList: homeData.szztkDataList,
      subjectList: homeData.subjectList,
      szysList: homeData.szysList,
      areaList: homeData.areaList,
      resTypeList: homeData.resTypeList,
      subject_big_id: 'all', //推荐选择的大类id all 为推荐
      dialogConfig: {
        name: ""
      },
      yzSchoolCode: "100047"
    };
  },
  computed: {
    ...mapState({
      relevantHomeList: state => state.home.relevantHomeList, // 推荐思政资源库数据
      szysBgList: state => state.szysk.szysBgList,
      schoolInfo: state => state.appsLogin.schoolInfo
    }),
    mySwiper () {
      return this.$refs.mySwiper && this.$refs.mySwiper.swiper;
    },
  },
  mounted () {
    // this.getMethodMedia("sz_indexbanner");
    this.getSzysFiveData();
    this.getHomeRelevantMethod()
    this.getSzztkCategory()
    this.getSzztkData()

    if (this.school_code == this.yzSchoolCode) this.indexBannerArr[1].hidden = true // 对100047扬职大隐藏第二张轮播图
  },
  methods: {
    ...mapActions(['getHomeRelevantData', 'goAppsDetailsBeforeLogin', 'saveResourceFavorite']),
    // 获取课程思政元素类别
    getSzysFiveData () {
      services.getSzzykElement({ length: '10', page: 1, pid: 0, school_code: this.school_code }).then(res => {
        if (res.code == '0000') {
          if (res.data.items.length > 0) {
            res.data.items.forEach(element => {
              element.classname = `${this.szysBgList[Math.floor(Math.random() * 5)]}`
            });
          }
          this.szsyszysArr = res.data.items;
        }
      })
    },
    // 获取思政专题分类
    getSzztkCategory () {
      services.getSzztkCategory().then(res => {
        if (res.code == '0000') {
          this.szztkCategory = res.data
        }
      })
    },
    // 获取推荐思政专题资源
    getSzztkData () {
      services.getSzztkList({ page: 1, is_recommend: 2, school_code: this.school_code, length: 8 }).then(res => {
        if (res.code == '0000') {
          this.szszztBannerArr = res.data.items
        }
      })
    },
    // 点击去思政专题库分类
    clickSzztkCate (item) {
      let routeUrl = this.$router.resolve({
        path: `/${this.school_code}/zy/szztk`,
        query: {
          cateId: item.id
        }
      })
      window.open(routeUrl.href, utils.isMobile() ? '_self' : '_blank');
    },

    // 跳转到专题资源详情
    resDetails (item) {
      let that = this
      that.goAppsDetailsBeforeLogin().then(() => {
        // window.open(`${services.BEIKE_SERVER}/${that.school_code}/sz/special`)
        // if (item.is_open == 1) {
        if (item.address && item.address.indexOf('http://') > -1) {
          window.open(item.address)
        } else {
          window.open(`/${that.school_code}/zy/szztk/detail/${item.id}`)
        }
        // } else {
        //   that.dialogConfig.name = "appleRes"
        // }
      });
    },
    //点击收藏
    requestCollect (item) {
      let that = this;
      // that.isShowLoading = true
      that.goAppsDetailsBeforeLogin().then(() => {
        that.saveResourceFavorite({ id: item.id }).then((res) => {
          // that.isShowLoading = false
          if (res.code == '0000') {
            that.$message.success("操作成功")
            that.getHomeRelevantMethod()
          } else {
            if (res.code != '2001') {
              that.$message.error("操作失败")
            }
          }
        })
      })
    },
    // 获取热门课程思政元素资源 
    getHomeRelevantMethod () {
      this.getHomeRelevantData({
        order_name: 'new_quote_num',
        page: 1,
        length: 12,
        favorite_flag: 1,
        school_code: this.school_code,
      })
    },
    // 获取融媒体频道后台 banner数据
    getMethodMedia (type) {
      this.$store.dispatch("loadHomeMediaData", {
        type: type,
        page: 0,
      }).then((resData) => {
        if (resData.data && resData.data.info) {
          if (type == 'sz_indexbanner') {
            this.indexBannerArr = resData.data.info;
          } else if (type == 'szsyszys') {
            if (resData.data.info.length > 0) {
              resData.data.info.forEach(element => {
                element.classname = `${this.szysBgList[Math.floor(Math.random() * 7)]}`
              });
            }
            this.szsyszysArr = resData.data.info;
          } else {
            // this.szszztBannerArr = resData.data.info;
          }
        }
      })
    },
    // 思政资源库详情
    goDetails (item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        // window.open(`${services.BEIKE_SERVER}/${this.school_code}/lesson/distribution`)
        // if (item.is_open == 2) {
        let routeUrl = this.$router.resolve({
          path: `/${this.school_code}/zy/szzyk/details`,
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, utils.isMobile() ? '_self' : '_blank');
        // } else {
        //   // this.noAuthMessageModal()
        //   this.dialogConfig.name = "appleRes"
        // }
      })
    },
    // 无权限查看课程资源时弹层提示
    // noAuthMessageModal () {
    //   Modal.info({
    //     title: '温馨提示',
    //     okType: 'primary',
    //     okText: '申请开通更多资源',
    //     closable: true,
    //     content: (<div class="jioayu-chrome">
    //       请拨打服务咨询电话或申请开通更多资源，获得更多资源使用权限。<br />服务咨询： 宋老师  18701110987
    //     </div>),
    //     onOk () {
    //       window.open(`${services.YXT_GW}/need`)
    //     }
    //   })
    // },
    swiperEnter () {
      this.mySwiper && this.mySwiper.autoplay.stop();
    },
    swiperLeave () {
      this.mySwiper && this.mySwiper.autoplay.start();
    },
    // 跳转思政元素库详情
    toSzyskDetail (item) {
      let that = this;
      that.goAppsDetailsBeforeLogin().then(() => {
        let routeUrl = that.$router.resolve({
          path: `/${that.school_code}/zy/szysk/details`,
          query: {
            id: item.id,
            pid: item.pid,
            className: item.classname
          }
        });
        window.open(routeUrl.href, utils.isMobile() ? '_self' : '_blank');
      });
    },
    goJiaowuZiyuanku () {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(`${services.BEIKE_SERVER}`)
      })
    },
    goJiaowuBeike () {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(`${services.BEIKE_SERVER}/lesson/resource`)
      })
    },
    goToNewLink (item) {
      if (item.isNeedLogin) {
        this.goAppsDetailsBeforeLogin().then(() => {
          window.open(`${item.url}`)
        })
      } else {
        window.open(`${item.url}`)
      }

    }
  },
};
</script>

<style lang="less" scoped>
.szpx-item {
    cursor: pointer;
}
.model-third-box .bottom-box .right-imgs-box img {
    width: 285px;
}
</style>