<template>
  <div class="Szysk_details">
    <template v-if="school_code == utils.whrj_code">
      <WhrjHeader :active="'/'+school_code+'/zy/szysk'" />
    </template>
    <template v-else>
      <Header />
      <Navbar />
    </template>
    <div class="body" ref="body" id="body">
      <div class="center">
        <div class="header">
          <div class="route">当前位置：<a :href="'/'+school_code+'/zy/szysk'">思政元素内涵</a> > 思政元素查看</div>
        </div>
        <div class="detailsBox">
          <div class="left">
            <div class="title">思政元素内涵理解</div>
            <div class="line"></div>
            <div :class="`sizheng-item ${currentClassName}`">{{szyskDetails.name}}</div>
          </div>
          <div class="right" ref="right">
            <div class="intro-item" v-if="szyskDetails.abstract">
              <div class="eleintro">
                <span class="intro">元素说明：</span>
              </div>
              <div class="introBox">
                <div class="text">
                  {{szyskDetails.abstract}}
                </div>
                <div v-if="szyskDetails.second_element_list && szyskDetails.second_element_list.length > 0" class="elements">
                  <img src="../../assets/image/szysk/elements.png" alt="" />
                  <span class="name">下级元素:</span>
                  <span v-for="(item, index) in szyskDetails.second_element_list" @click="toSzyskDetail({...item, pid: currentId})" :key="index" class="item"># {{item.name}}</span>
                </div>
              </div>
            </div>

            <div class="intro-item" v-if="szyskDetails.purpose">
              <div class="eleintro">
                <span class="intro">设置目的：</span>
              </div>
              <div class="introBox">
                <div class="text">
                  {{szyskDetails.purpose}}
                </div>
              </div>
            </div>
            <div class="intro-item" v-if="szyskDetails.connotation">
              <div class="eleintro">
                <span class="intro">内涵理解：</span>
              </div>
              <div class="introBox">
                <div class="text">
                  {{szyskDetails.connotation}}
                </div>
              </div>
            </div>

            <div class="intro-item" v-if="szyskDetails.prominent">
              <div class="eleintro">
                <span class="intro">内核凸显：</span>
              </div>
              <div class="introBox">
                <div class="text">
                  {{szyskDetails.prominent}}
                </div>
              </div>
            </div>

            <div class="intro-item" v-if="szyskDetails.major">
              <div class="eleintro">
                <span class="intro">适用专业：</span>
              </div>
              <div class="introBox">
                <div class="text">
                  {{szyskDetails.major}}
                </div>
              </div>
            </div>

            <div class="intro-item" v-if="szyskDetails.suggest">
              <div class="eleintro">
                <span class="intro">应用建议：</span>
              </div>
              <div class="introBox">
                <div class="text">
                  {{szyskDetails.suggest}}
                </div>
              </div>
            </div>

            <div class="relativeRes">
              <span class="relativeResName">相关资源：</span>
              <div class="searchBox">
                <input type="text" v-model="searchVal" placeholder="请输入检索词" @keyup.enter="searchEvent" />
                <div v-show="searchVal && searchVal.length > 0" class="clear-input" @click="clearSearchEvent"></div>
                <div class="search" @click="searchEvent()">搜索</div>
              </div>
            </div>
            <div class="resList">
              <template v-if="relevantList.length > 0">
                <div v-for="(item,index) in relevantList" :key="index" class="resItem" @click="goDetails(item)">
                  <div class="title">
                    <img v-if="item.type == 1" src="../../assets/image/szysk/word.png" alt="" />
                    <img v-if="item.type == 2" src="../../assets/image/szysk/MP4.png" alt="" />
                    <!-- <div v-if='item.is_open == 2' class="text">
                      <span v-html="item.title" class="title-span"></span> <span class="resopen">公开</span>
                    </div> -->
                    <div class="textall" v-html="item.title"></div>
                  </div>
                  <div class="itemFooter">
                    <div class="itemLeft">
                      <div class="keys">
                        <span v-for="(sub,subinx) in item.element_list" :key="subinx">{{sub.name}}</span>
                      </div>
                    </div>
                    <div class="itemRight">
                      <img v-if="item.publish_time" class="dateImg" src="../../assets/image/szysk/date.png" />
                      <span v-if="item.publish_time" class="date">时间：{{item.publish_time}}</span>
                      <div :class="item.favorite == 1 ? 'active collect' : 'collect'" @click.stop="requestCollect(item)">
                        <div class="collectImg"></div>
                        <span class="text">{{item.favorite == 1 ? '取消收藏': '收 藏'}}</span>
                      </div>
                      <!-- <div class="download">
                        <div class="downloadImg"></div>
                        <span class="text">下载</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div style="text-align: center;padding: 20px 0 30px;">
                  <a-pagination v-model="currentPage" :show-total="total => `共${relevantTotal}条`" :total="relevantTotal" @change="onPagesChange" />
                </div>
              </template>
              <template v-else>
                <a-empty :image="simpleImage"><span slot="description"> 暂无数据</span></a-empty>
              </template>
            </div>
            <!-- <div class="otherEle">
              <span class="name">其他思政元素</span>
            </div>
            <div class="keyWords">
              <span v-for="(item, index) in szyskDetails.other_element_list" @click="toSzyskDetail(item)" :key="index">{{item.name}}</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <Loading :showLoading="isShowLoading" /> -->
    <component :is="dialogConfig.name"></component>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue';
import Navbar from "../../components/Navbar.vue";
// import Loading from "../../components/loading.vue";
import { mapState, mapActions, mapMutations } from "vuex"
import utils from '../../assets/js/utils'
import services from "../../assets/js/services";
import Header from "../../components/Header.vue"
import appleRes from "../../components/appleRes.vue"
import WhrjHeader from "../../components/WhrjHeader.vue";
export default {
  name: "Szysk_details",
  components: {
    Navbar,
    // Loading
    Header,
    appleRes,
    WhrjHeader
  },
  data () {
    return {
      utils,
      services,
      school_code: localStorage.getItem("schoolCode"),
      currentId: 0, // 当前id
      currentPid: 0, // 当前pid
      searchVal: '',
      currentPage: 1,
      currentClassName: 'one',
      pageNum: 10,
      // isShowLoading: false,
      dialogConfig: {
        name: ""
      }
    };
  },
  computed: {
    ...mapState({
      szyskDetails: state => state.szysk.szyskDetails,
      relevantList: state => state.szysk.relevantList, // 相关资源数据
      relevantListNums: state => state.szysk.relevantListNums, // 
      relevantTotal: state => state.szysk.relevantTotal,
    }),
  },
  // directives: {
  //   searchlight: {
  //     // 指令的定义
  //     bind: function (el, binding) {
  //       var { searchValue, message} = binding.value;
  //       if(message && message.length > 0){
  //         // 将字符串转数组
  //         var arr = message.split(searchValue)
  //         // 将searchValue标红
  //         var sp = `<span style="color: #DF4332;">${searchValue}</span>`;
  //         //使用红色的字对数组进行连接，连接后放回到 el中
  //         el.innerHTML = arr.join(sp);
  //       }
  //     },
  //     componentUpdated: function (el, binding) {
  //       var { searchValue, message} = binding.value;
  //       if(message && message.length > 0){
  //         // 将字符串转数组
  //         var arr = message.split(searchValue)
  //         // 将searchValue标红
  //         var sp = `<span style="color: #DF4332;">${searchValue}</span>`;
  //         //使用红色的字对数组进行连接，连接后放回到 el中
  //         el.innerHTML = arr.join(sp);
  //       }
  //     },
  //   }
  // },
  beforeCreate () {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  mounted () {
    let that = this
    if (this.$route.query.id) {
      this.currentId = this.$route.query.id
      this.currentPid = this.$route.query.pid
      this.getSzyskDetails({ id: this.$route.query.id }).then(res => {
        if (res.code == '2004') {
          that.$router.push({
            path: `/${that.school_code}/zy/szzyk/details`,
            query: that.$route.query
          })
        }
      })
      this.requestRelevantSzzykList(1, true)
      this.requestRelevantSzzykList(1)
    }
    if (this.$route.query.className) {
      this.currentClassName = this.$route.query.className
    }
  },
  methods: {
    ...mapMutations(['setAppsLoginVisible']),
    ...mapActions(['getAppsImageCaptcha', 'getSzyskDetails', 'getRelevantData', 'getAppsLoginUserInfo', 'goAppsDetailsBeforeLogin', 'saveResourceFavorite']),
    // 请求思政资源列表数据
    requestRelevantSzzykList (page, isCount) {
      let params = {
        page: page,
        length: this.pageNum,
        school_code: this.school_code,
        favorite_flag: 1, // 是否显示收藏
      };
      if (isCount) {
        params.count = 1
      }
      if (this.currentPid == 0) {
        params.element_first_id = this.currentId
      } else {
        params.element_first_id = this.currentPid
        params.element_second_id = this.currentId
      }
      if (this.searchVal) {
        params.q = this.searchVal
      }
      this.getRelevantData(params);
    },
    searchEvent () {
      this.requestRelevantSzzykList(1)
      this.currentPage = 1
    },
    // 清除搜索事件
    clearSearchEvent () {
      this.searchVal = ''
      this.searchEvent()
    },
    // 思政资源库详情
    goDetails (item) {
      let that = this;
      that.goAppsDetailsBeforeLogin().then(() => {

        // window.open(`${services.BEIKE_SERVER}/${that.school_code}/lesson/distribution`)

        // if (item.is_open == 2) {
        let routeUrl = that.$router.resolve({
          path: `/${that.school_code}/zy/szzyk/details`,
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, utils.isMobile() ? '_self' : '_blank');
        // } else {
        //   // that.noAuthMessageModal()
        //   this.dialogConfig.name = "appleRes"
        // }
      })
    },
    // 无权限查看课程资源时弹层提示
    // noAuthMessageModal () {
    //   Modal.info({
    //     title: '温馨提示',
    //     okType: 'primary',
    //     okText: '申请开通更多资源',
    //     closable: true,
    //     content: (<div class="jioayu-chrome">
    //       请拨打服务咨询电话或申请开通更多资源，获得更多资源使用权限。<br />服务咨询： 宋老师  18701110987
    //     </div>),
    //     onOk () {
    //       window.open(`${services.YXT_GW}/need`)
    //     }
    //   })
    // },
    onPagesChange (pageNumber) {
      let that = this;
      if (pageNumber > 1) {
        that.getAppsLoginUserInfo().then(res => {
          if (res.code == '2001') {
            that.currentPage = 1
            that.getAppsImageCaptcha()
            that.setAppsLoginVisible(true)
          } else if (res.code == '0000') {
            that.requestRelevantSzzykList(pageNumber)
          }
        })
      } else {
        that.requestRelevantSzzykList(pageNumber)
      }

    },
    // 去思政元素详情页
    toSzyskDetail (item) {
      let that = this;
      this.currentId = item.id
      // this.getSzyskDetails({id: item.id})
      // this.requestRelevantSzzykList(1)

      let routeUrl = that.$router.resolve({
        path: `/${that.school_code}/zy/szysk/details`,
        query: {
          id: item.id,
          pid: item.pid,
          className: item.classname
        }
      });
      window.open(routeUrl.href, utils.isMobile() ? '_self' : '_blank');
    },
    //点击收藏
    requestCollect (item) {
      let that = this;
      // that.goAppsDetailsBeforeLogin().then(() => {
      //   that.isShowLoading = true
      that.saveResourceFavorite({ id: item.id }).then((res) => {
        // that.isShowLoading = false
        if (res.code == '0000') {
          that.$message.success("操作成功")
          that.requestRelevantSzzykList(that.currentPage);
        } else {
          if (res.code != '2001') {
            that.$message.error("操作失败")
          }
        }
      })
      // })
    },
  },
};
</script>
<style lang="less" scoped>
.Szysk_details {
  background: #f5f7fa;
  .body {
    background: url('../../assets/image/szysk/bg_detail.png') no-repeat;
    width: 100%;
    padding-bottom: 30px;
    .center {
      width: 1200px;
      margin: 0 auto;
      .header {
        height: 50px;
        line-height: 50px;
        .route {
          font-size: 12px;
          color: #e64833;
          opacity: 0.82;
          * {
            font-size: 12px;
            color: #e64833;
            opacity: 0.82;
          }
        }
      }
      .detailsBox {
        position: relative;
        .left {
          width: 310px;
          background: #ffffff;
          border: 1px solid;
          border-image: linear-gradient(0deg, #ffffff, #f2f2f2) 10 10;
          border-radius: 6px;
          padding: 20px 15px;
          position: absolute;
          z-index: 100;
          display: inline-block;
          vertical-align: top;
          bottom: 0;
          top: 0;
          .title {
            font-size: 22px;
            font-weight: bold;
            color: #272727;
            text-align: center;
          }
          .line {
            width: 100%;
            height: 1px;
            background: linear-gradient(
              -90deg,
              #fef3f4 0%,
              #dd363a 50%,
              #ffffff 100%
            );
            margin: 20px 0;
          }
          .sizheng-item {
            width: 220px;
            height: 124px;
            line-height: 24px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            padding: 20px;
            color: #bd0e10;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            &.one {
              background: url('../../assets/image/home/sizheng1.jpg') no-repeat
                center;
              background-size: 100% auto;
            }
            &.two {
              background: url('../../assets/image/home/sizheng2.jpg') no-repeat
                center;
              background-size: 100% auto;
            }
            &.three {
              background: url('../../assets/image/home/sizheng3.jpg') no-repeat
                center;
              background-size: 100% auto;
            }
            &.four {
              background: url('../../assets/image/home/sizheng4.jpg') no-repeat
                center;
              background-size: 100% auto;
            }
            &.five {
              background: url('../../assets/image/home/sizheng5.jpg') no-repeat
                center;
              background-size: 100% auto;
            }
          }
        }
        .right {
          .intro-item {
            margin-bottom: 25px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          width: 890px;
          background: #ffffff;
          padding: 30px 45px 0 45px;
          display: inline-block;
          margin-top: 63px;
          margin-left: 310px;
          .intro,
          .relativeResName,
          .otherEle .name {
            font-size: 16px;
            font-weight: bold;
            color: #dd363a;
            display: inline-block;
            position: relative;
            line-height: 20px;
          }
          .eleintro {
            border-bottom: 1px solid #f3f3f3;
            padding-bottom: 10px;
            .intro::after {
              content: '';
              width: 100%;
              height: 4px;
              background: linear-gradient(162deg, #dd363a 0%, #df4531 100%);
              border-radius: 2px;
              left: 0px;
              display: inline-block;
              bottom: -13px;
              position: absolute;
            }
          }

          .introBox {
            padding: 20px;
            background: #f5f7fa;
            border-radius: 2px;
            margin-top: 20px;
            .text {
              font-size: 14px;
              color: #737373;
            }
            .elements {
              margin-top: 15px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              img {
                margin-right: 5px;
              }
              .name {
                font-size: 16px;
                color: #454545;
                margin-right: 20px;
              }
              .item {
                font-size: 14px;
                color: #b75031;
                margin-right: 20px;
                cursor: pointer;
              }
            }
          }
          .relativeRes {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 20px;
            border-bottom: 1px solid #f3f3f3;
            padding-bottom: 8px;
            .relativeResName::after {
              content: '';
              width: 100%;
              height: 4px;
              background: linear-gradient(162deg, #dd363a 0%, #df4531 100%);
              border-radius: 2px;
              left: 0px;
              display: inline-block;
              bottom: -16px;
              position: absolute;
            }
            .searchBox {
              position: relative;
              text-align: right;
              input {
                width: 260px;
                height: 34px;
                background: #ffffff;
                border: 1px solid #f8818d;
                border-radius: 16px;
                padding-left: 20px;
                outline-color: #f8818d;
                position: relative;
                padding-right: 65px;
              }
              input:focus-visible {
                border: 1px solid #f8818d;
                outline: none;
              }
              .clear-input {
                width: 30px;
                height: 30px;
                background: url('../../assets/image/common/clear.png') no-repeat
                  center center;
                background-size: 15px auto;
                cursor: pointer;
                display: inline-block;
                position: absolute;
                right: 60px;
                top: 1px;
              }
              .search {
                width: 59px;
                height: 34px;
                line-height: 34px;
                background: #e64757;
                border-radius: 0px 16px 16px 16px;
                text-align: center;
                font-size: 13px;
                color: #f5f7fa;
                position: absolute;
                bottom: 0px;
                right: 0px;
                cursor: pointer;
              }
            }
          }
          .resList {
            // margin-top: 10px;
            .resItem {
              padding: 20px 0px;
              border-bottom: 1px solid #f3f3f3;
              cursor: pointer;
              .title {
                display: flex;
                align-items: center;
                img {
                  margin-right: 9px;
                }
                .textall {
                  font-size: 16px;
                  color: #272727;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  display: inline-block;
                  width: 700px;
                }
                .text {
                  font-size: 16px;
                  color: #272727;
                  cursor: pointer;
                  .title-span {
                    max-width: 680px;
                    display: inline-block;
                    vertical-align: middle;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  .resopen {
                    font-size: 12px;
                    color: #ffffff;
                    background: #ee373d;
                    padding: 2px 6px;
                    border-radius: 6px;
                  }
                }
              }
              .itemFooter {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                margin-top: 20px;
                .itemLeft {
                  flex: 60%;
                  height: 26px;
                  overflow: hidden;
                  .keys {
                    span {
                      padding: 4px 16px;
                      background: #f9f9f9;
                      border-radius: 12px;
                      font-size: 12px;
                      color: #b39f8c;
                      margin-right: 8px;
                      display: inline-block;
                    }
                  }
                }
                .itemRight {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  .dateImg {
                    margin-right: 10px;
                  }
                  .date {
                    font-size: 13px;
                    color: #a3a5aa;
                    margin-right: 15px;
                  }
                  .collect {
                    width: 82px;
                    height: 24px;
                    background: #ffffff;
                    border: 1px solid #e4717d;
                    border-radius: 12px;
                    font-size: 12px;
                    color: #df4332;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 15px;
                    .collectImg {
                      background: url('../../assets/image/home/star.png')
                        no-repeat;
                      background-size: 100% 100%;
                      width: 18px;
                      height: 18px;
                      margin-right: 3px;
                    }
                    &.active .collectImg {
                      background: url('../../assets/image/home/star-active.png')
                        no-repeat !important;
                    }
                    &:hover {
                      background: linear-gradient(
                        162deg,
                        #d8292d 0%,
                        #d43d29 100%
                      ) !important;
                      .collectImg {
                        background: url('../../assets/image/home/star-active.png')
                          no-repeat !important;
                      }
                      span {
                        color: #ffffff !important;
                      }
                    }
                  }
                  .download {
                    width: 82px;
                    height: 24px;
                    background: #ffffff;
                    border: 1px solid #e4717d;
                    border-radius: 12px;
                    font-size: 12px;
                    color: #df4332;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 15px;
                    .downloadImg {
                      background: url('../../assets/image/home/download.png')
                        no-repeat;
                      background-size: 100% 100%;
                      width: 12px;
                      height: 12px;
                      margin-right: 3px;
                    }
                    &:hover {
                      background: linear-gradient(
                        162deg,
                        #d8292d 0%,
                        #d43d29 100%
                      ) !important;
                      .downloadImg {
                        background: url('../../assets/image/home/download-active.png')
                          no-repeat;
                        background-size: 100% 100%;
                      }
                      span {
                        color: #ffffff !important;
                      }
                    }
                  }
                }
              }
              &:hover {
                background: linear-gradient(0deg, #ffffff 0%, #f4f4f4 100%);
                border-radius: 2px;
                border-bottom: 1px solid #fff;
              }
            }
          }
          .otherEle {
            border-bottom: 1px solid #f3f3f3;
            padding-bottom: 10px;
            margin-top: 25px;
            .name {
              &::after {
                content: '';
                width: 100%;
                height: 4px;
                background: linear-gradient(162deg, #dd363a 0%, #df4531 100%);
                border-radius: 2px;
                left: 0px;
                display: inline-block;
                bottom: -12px;
                position: absolute;
              }
            }
          }
          .keyWords {
            margin-top: 24px;
            span {
              padding: 6px 16px;
              background: #f9f5e9;
              border-radius: 12px;
              text-align: center;
              font-size: 14px;
              color: #b75031;
              margin-right: 8px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>