<template>
  <div>
    <Header />
    <Navbar />
    <div class="eol-area-container" v-if="listData&&listData.length>0">
      <div class="eol-area-inner">
        <!-- <div class="block-content" v-for="item,index in listData" :key="index">
          <div class="block-inner" :style="`background-image: url('${item.cover}')`">
            <div class="block-desc">
              <div class="description">{{ item.abstract }}</div>
              <div class="jump-btn" @click="JumpBeike(item)">查看详情</div>
            </div>
          </div>
        </div> -->
        <div class="block-list-box">
          <div class="block-item" v-for="item,index in listData" :key="index">
            <div class="block-image">
              <div class="img-inner" :style="`background-image: url('${item.cover}')`"></div>
            </div>
            <div class="block-info">
              <div class="info-name">{{item.name}}</div>
              <div class="jump-btn" @click="JumpBeike(item)">查看详情</div>
            </div>
            <div class="description">{{ item.abstract }}</div>
          </div>
        </div>
        <div class="pagination-box">
          <APagination
            v-if="total > pageSize"
            v-model="page"
            :total="total"
            :page-size="pageSize"
            @change="onPaginationChange"
          ></APagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../../components/Header.vue"
import Navbar from "../../components/Navbar.vue"
import { mapActions, mapState } from "vuex"
import services from '../../assets/js/services';
import { Pagination,  } from "ant-design-vue";
export default {
  name: "areaList",
  components: {
    Header,
    Navbar,
    APagination: Pagination,
  },
  data () {
    return {
      school_code: localStorage.getItem("schoolCode"),
      loading: false,
      listData: [],
      page: 1,
      pageSize: 6,
      total: 0,
    }
  },
  computed: {
    ...mapState({
    })
  },
  async mounted () {
    await this.getListData()
  },
  methods: {
    ...mapActions(['goAppsDetailsBeforeLogin', 'getSchoolMenuStatus']),
    async getListData() {
      let res = await  services.getAreaList({ school_code: this.school_code, page: this.page, length: this.pageSize, });
      if (res.code == "0000") {
        if (res.data.items && res.data.items.length) {
          this.listData = res.data.items;
          this.total = res.data.total;
        } else {
          this.listData = [];
          this.total = 0;
        }
      }
    },
    onPaginationChange(current) {
      this.page = current
      this.getListData();
    },
    JumpBeike (item) {
      let that = this;
      that.goAppsDetailsBeforeLogin().then(() => {
        window.open(`/${that.school_code}/zy/region/list/detail?path=${item.url}`, "_blank")
      });
    },
  },

}
</script>

<style scoped lang="less">
.noData-box {
  width: 100%;
  height: calc(100vh - 232px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.noData-box img {
  width: 240px;
  height: auto;
  margin: 0 auto 30px;
}
.noData-box .text {
  color: #76797e;
  font-size: 14px;
}
.eol-area-container {
  min-height: calc(100vh - 151px - 42px - 180px);
  background-image: url('../../assets/image/area/main-bg.jpg');
  background-size: cover;
  background-position: top center;
  padding: 160px 0 0;
  .eol-area-inner {
    width: 1200px;
    margin: 0 auto;
    padding: 30px 0 50px;
    background-size: cover;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 3;

    // 列表页分页版本样式
    .block-list-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      &::after {
        content: '';
        display: inline-block;
        width: 376px;
        height: 0;
        visibility: hidden;
      }
      .block-item {
        width: 376px;
        border-radius: 8px 8px 0px 0px;
        background-color: #fff;
        display: inline-block;
        margin-bottom: 10px;
        &:hover .img-inner {
          transform: scale(1.1);
        }
        &:nth-child(2), &:nth-child(5) {
          margin-top: 30px;
        }
      }
      .block-image {
        margin: 4px 4px 0;
        width: 368px;
        height: 200px;
        border-radius: 8px 8px 0px 0px;
        overflow: hidden;
        .img-inner {
          transition: all 0.5s;
          width: 100%;
          height: 100%;
          background-size: auto 100%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .block-info {
        border-bottom: 2px solid #F8F8F8;
        padding: 20px 20px 20px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info-name {
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 20px;
          color: #333333;
        }
        .jump-btn {
          font-size: 14px;
          color: #E64757;
          position: relative;
          cursor: pointer;
          &::after {
            content: '';
            display: inline-block;
            background-image: url('../../assets/image/area/look2.png');
            background-size: cover;
            background-position: center;
            background-size: cover;
            width: 20px;
            height: 9px;
            margin-left: 4px;
          }
        }
      }
      .description {
        padding: 20px 22px 0 24px;
        margin-bottom: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #8E8C8C;
        line-height: 24px;
        height: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }
    }
    .pagination-box {
      width: 100%;
      text-align: center;
      padding: 40px 0 0;
    }
    // 未分页版本样式
    // &::after {
    //   content: '';
    //   display: inline-block;
    //   width: calc(100% - 12px);
    //   height: calc(100% - 80px);
    //   background: #fff;
    //   position: absolute;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   top: 0;
    //   z-index: -2;
    // }
    // &::before {
    //   content: '';
    //   display: inline-block;
    //   width: calc(100% + 12px);
    //   height: 540px;
    //   position: absolute;
    //   bottom: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   background-image: url('../../assets/image/area/block-bg.png');
    //   background-size: cover;
    //   background-position: center;
    //   background-size: cover;
    //   z-index: -1;
    // }
    // .block-content {
    //   background-image: url('../../assets/image/area/border.png');
    //   background-size: cover;
    //   background-position: center;
    //   background-size: cover;
    //   width: 100%;
    //   height: 281px;
    //   padding: 12px;
    //   &:not(:last-child) {
    //     margin-bottom: 30px;
    //   }
    //   .block-inner {
    //     background-size: cover;
    //     background-position: center;
    //     background-size: cover;
    //     position: relative;
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     align-items: flex-end;
    //     padding: 0 20px 20px 15px;
    //     .block-desc {
    //       display: flex;
    //       align-items: center;
    //       width: 100%;

    //       .description {
    //         overflow: hidden;
    //         text-overflow: ellipsis;
    //         display: -webkit-box;
    //         -webkit-box-orient: vertical;
    //         -webkit-line-clamp: 2;
    //         padding-right: 20px;
    //         position: relative;
    //         font-size: 14px;
    //         color: #ffffff;
    //         line-height: 22px;
    //         flex: 1;
    //       }
    //       .jump-btn {
    //         flex-shrink: 0;
    //         width: 108px;
    //         height: 34px;
    //         background: #e64757;
    //         text-align: center;
    //         line-height: 34px;
    //         font-size: 14px;
    //         font-weight: bold;
    //         color: #ffffff;
    //         position: relative;
    //         cursor: pointer;
    //         &::after {
    //           content: '';
    //           display: inline-block;
    //           background-image: url('../../assets/image/area/look.png');
    //           background-size: cover;
    //           background-position: center;
    //           background-size: cover;
    //           width: 20px;
    //           height: 8px;
    //           margin-left: 8px;
    //         }
    //       }
    //       &::before {
    //         content: '';
    //         display: inline-block;
    //         width: 100%;
    //         height: 100px;
    //         background: linear-gradient(
    //           0deg,
    //           #00070f 0%,
    //           rgba(0, 7, 10, 0) 100%
    //         );
    //         opacity: 0.82;
    //         position: absolute;
    //         bottom: 0;
    //         left: 0;
    //       }
    //     }
    //   }
    // }
  }
}
@media screen and (min-width:1900px) {
  .eol-area-container {
    padding: 190px 0 0;
  }
}
</style>