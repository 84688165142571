<template>
  <div>
    <div class="sdcm-header-box">
      <div class="btn-group">
        <div class="wrlogo"></div>
        <div class="school-home">
          <div class="eollogo">
            <img src="../assets/image/whrjgczyxy/index/eollogo.png" />
          </div>
          <a href="https://www.whvcse.edu.cn/index.htm" target="_blank" class="text" v-html="'学校主页'"></a>
          <div class="login-parent">
            <div v-if="appsUserInfo && appsUserInfo.name" :title="appsUserInfo.name" class="login-info-btn">
              <div class="username">{{appsUserInfo.name}}</div>
            </div>
            <div v-else @click="loginModal(true)" class="login-button">登录</div>
            <div v-if="appsUserInfo && appsUserInfo.name" class="login-sub-box">
              <template v-if="appsUserInfo.user_type== 2 || (appsUserInfo.user_type == 1 && appsUserInfo.role_ids)">
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/sz/school/resource`" target="_blank">校内共建资源库</a>
                </div>
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/lesson/resource`" target="_blank">备课资源库</a>
                </div>
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/lesson/works`" target="_blank">协同备课</a>
                </div>
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/sz/favorite`" target="_blank">资源收藏</a>
                </div>
              </template>
              <div class="hover-item">
                <a :href="`${services.APPS_WEB}/${school_code}/worker`" target="_blank">信息服务中心</a>
              </div>
              <div class="hover-item">
                <a :href="`${services.APPS_WEB}/${school_code}/userInfo`" target="_blank">个人信息设置</a>
              </div>
              <div class="hover-item">
                <a :href="`${services.APPS_WEB}/${school_code}/editPassword`" target="_blank">修改密码</a>
              </div>
              <div class="hover-item" @click="logoutEvent">
                <a href="javascript:;">退出</a>
              </div>
            </div>
          </div>
        </div>
        <div class="search-box">
          <div class="search-btn">
            <div class="selectVal" ref="val" @click="showMenu = !showMenu">{{currentData.name}}</div>
            <input type="text" ref="search" placeholder="请输入搜索关键词" v-model="keyword" @keyup.enter="handleSearch()">
            <div class="search" @click="handleSearch()"></div>
          </div>
          <div class="search-menu" v-show="showMenu">
            <ul>
              <li :class="[item.name == currentData.name ?'active':'']" v-for="item,index in searchMenu" :key="index" @click="currentCatid(item,index)">{{item.name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="sdcm-navbar-list">
      <div class="nav-bar-inner">
        <template v-for="(item, index) in navList">
          <div :class='[$route.path == item.path ? "nav-items active": "nav-items" ,active == item.path ? "nav-items active": "nav-items" ]' :key="index">
            <template>
              <router-link class="nav-href" :to="item.path" v-if="!item.outside&&!item.needLogin">{{item.title}}</router-link>
              <a v-else class="nav-href" href="javascript:void(0)" @click="toPath(item)">{{item.title}}</a>
            </template>
          </div>
        </template>
      </div>
    </div>
    <Login ref="loginchild"></Login>

  </div>

</template>

<script>
import Login from "../components/Login.vue"
import { mapState, mapActions, mapMutations } from "vuex"
import utils from "../assets/js/utils"
import { Modal } from 'ant-design-vue';
import services from '../assets/js/services'
export default {
  props: ['active', 'keywords', 'currentType'],
  data () {
    return {
      services,
      school_code: localStorage.getItem("schoolCode"),
      navList: [],
      // searchMenu: [
      //   { name: "全站", catid: '14643,14619,14652,14653' },
      //   { name: "建设动态", catid: '14643' },
      //   { name: "典型经验", catid: '14619' },
      //   { name: "政策文件", catid: '14652,14653' },
      // ],
      searchMenu: [
        { name: "资源", catid: '1' },
        { name: "资料", catid: '2' },
      ],
      keyword: "",
      showMenu: false,
      // currentData: { item: { name: "全站", catid: '14643,14619,14652,14653' }, index: 0 }
      currentData: { name: "资源", catid: '1' },
    }
  },
  components: {
    Login,
  },
  mounted () {
    this.setNavData()

    this.getAppsLoginUserInfo();
    // this.getLoginSchoolInfo({ school_code: this.school_code, label: 'kcsz' }).then(res => {
    //   if(res.code == '0000'){
    //     utils.setTitle(this.schoolInfo.school_name)
    //   }
    // })
  },
  methods: {
    ...mapMutations(['setSearchKeyWord', 'setAppsLoginVisible', 'setSearchSzzykData', 'setSearchElementData',]),
    ...mapActions(['getAppsImageCaptcha', 'getLoginSchoolInfo', 'getAppsLoginUserInfo', 'requestAppsLogout', 'goAppsDetailsBeforeLogin']),
    // 设置导航路由
    setNavData () {
      this.navList = [
        {
          title: '主页',
          path: `/${this.school_code}/whrjgczyxy`,
          outside: false,
        },
        // {
        //   title: '平台介绍',
        //   path: `/${this.school_code}/whrjgczyxy/ptjs`,
        //   outside: false,
        // },
        {
          title: '建设动态',
          path: `/${this.school_code}/whrjgczyxy/jsdt`,
          outside: false,
        },
        // {
        //   title: '典型经验',
        //   path: `/${this.school_code}/whrjgczyxy/dxjy`,
        //   outside: false,
        // },
        {
          title: '典型案例',
          path: `/${this.school_code}/whrjgczyxy/dxal`,
          outside: false,
        },
        {
          title: '政策文件',
          path: `/${this.school_code}/whrjgczyxy/zcwj`,
          outside: false,
        },
        {
          title: '示范课程',
          path: `/${this.school_code}/whrjgczyxy/sfkc`,
          outside: false,
        },
        // {
        //   title: '课程思政名家讲座',
        //   path: `/${this.school_code}/whrjgczyxy/mjjz`,
        //   outside: false,
        // },
        {
          title: '名家讲坛',
          path: `/${this.school_code}/whrjgczyxy/mjjt`,
          outside: false,
        },
        {
          title: '思政专题库',
          path: `/${this.school_code}/zy/szztk`,
          outside: false,
        },
        {
          title: '课程思政资源库',
          path: `/${this.school_code}/zy/szzyk`,
          outside: false,
        },
        {
          title: '备课空间',
          path: `${services.BEIKE_SERVER}/${this.school_code}/lesson/resource`,
          outside: true,
        },
        {
          title: '师资团队',
          path: `/${this.school_code}/whrjgczyxy/sztd`,
          outside: false,
        },
        // { title: "资源推荐", path: `${services.BEIKE_SERVER}/${this.school_code}/sz/school/me`, outside: true, needLogin: true, }
        {
          title: '使用指南',
          path: `/${this.school_code}/whrjgczyxy/syzn`,
          outside: false,
          needLogin: true,
        },
      ]
    },
    toPath (item) {
      let that = this
      if (item.needLogin) {
        that.goAppsDetailsBeforeLogin().then(() => {
          window.open(item.path, "_blank")
        })
      } else {
        window.open(item.path, '_blank')
      }
    },
    handleSearch () {
      let val = this.keyword
      if (val) {
        if (this.currentData.catid == 1) {
          this.setSearchKeyWord({ data: val })
          let routeUrl = this.$router.resolve({
            path: `/${this.school_code}/zy/search`,
            query: {
              keyWord: val,
            }
          });
          window.open(routeUrl.href, '_self');
        } else {
          let data = '14643,14652,14653,14681,14684,14671'
          if (this.appsUserInfo && this.appsUserInfo.id) {
            data = '14643,14652,14653,14681,14683,14684,14671,14672,14686'
          }
          this.$router.push({
            path: `/${this.school_code}/whrjgczyxy/search`,
            query: {
              keyword: this.keyword,
              time: Date.parse(new Date()),
              currentData: { item: { name: "全站", catid: data }, index: 0 },
            }
          })
          this.$emit("refresh", { item: { name: "全站", catid: data }, index: 0 })
        }

      } else {
        this.$message.error('请输入搜索关键字')
        return false
      }
    },
    currentCatid (item) {
      this.searchSelect = item.name
      this.showMenu = false
      this.currentData = item
    },
    loginModal (val) {
      if (val) {
        this.getAppsImageCaptcha()
      }
      this.setAppsLoginVisible(val);
    },
    // 退出登录
    async logoutEvent () {
      let that = this;
      await that.requestAppsLogout().then(() => {
        if (utils.whiteList.some( item =>  `/${this.school_code}${item}` == `${this.$route.path}`)) {
          that.$router.push({ path: `/${that.school_code}/` })
          that.loginModal(true);
        }
      })
      // Modal.info({
      //   // title: '温馨提示',
      //   okType: 'primary',
      //   okText: '确定',
      //   closable: true,
      //   content: '确定退出登录状态吗？',
      //   onOk () {

      //   }
      // })

    },
  },

  computed: {
    ...mapState({
      appsUserInfo: state => state.appsLogin.appsUserInfo,
      loginVisible: state => state.appsLogin.loginVisible,
      loading: state => state.search.loading,
      schoolInfo: state => state.appsLogin.schoolInfo
    }),
  },
  watch: {
    keywords (val) {
      if (val) {
        this.keyword = val
      }
    },
    currentType (val) {
      if (val) {
        this.currentData = val
      }
    }
  }


}
</script>

<style scoped lang="less">
.sdcm-header-box {
  width: 100%;
  height: 168px;
  background-image: url('../assets/image/whrjgczyxy/index/header.png');
  background-size: cover;
  background-position: center;
  .btn-group {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    .wrlogo {
      width: 700px;
      height: 84px;
      margin: 42px 0 0;
      float: left;
      background-image: url('../assets/image/whrjgczyxy/index/wrlogo.png');
      background-size: cover;
      background-position: center;
    }
    .school-home {
      .eollogo {
        width: 143px;
        height: 28px;
        position: absolute;
        right: 104px;
        bottom: 42px;
        img {
          height: 100%;
          width: auto;
        }
      }
      .text {
        display: block;
        text-align: center;
        position: absolute;
        right: 0;
        bottom: 42px;
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 15px;
          margin-right: 3px;
          vertical-align: -1px;
          background-image: url('../assets/image/whrjgczyxy/index/back-icon.png');
          background-size: cover;
          background-position: center;
        }
      }
      .login-button {
        color: #fed49e;
        display: block;
        position: absolute;
        right: 0;
        top: 10px;
        font-size: 14px;
        cursor: pointer;
        max-width: 92px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 0 0 10px;
        height: 28px;
        text-align: center;
        line-height: 28px;
      }
      .login-parent:hover .login-sub-box {
        display: block;
        background-color: #ffffff;
      }
      .login-info-btn {
        color: #fed49e;
        display: block;
        position: absolute;
        right: 0;
        top: 10px;
        font-size: 14px;
        cursor: pointer;
        padding: 0 0 0 10px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        .username {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #fed49e;
          display: inline-block;
          vertical-align: middle;
        }
        &::after {
          content: '';
          display: inline-block;
          width: 8px;
          height: 5px;
          margin: 10px 0 0 6px;
          background-image: url('../assets/image/whrjgczyxy/index/user-down.png');
          background-size: cover;
        }
      }
      .login-sub-box {
        display: none;
        position: absolute;
        padding: 10px 20px;
        box-sizing: border-box;
        top: 38px;
        right: 0;
        width: 140px;
        text-align: left;
        background-color: transparent;
        box-shadow: 0px 1px 6px 0px rgba(130, 130, 130, 0.2);
        border-radius: 2px;
        transition: background-color 0.3s;
        z-index: 100;
        .hover-item {
          display: block;
          line-height: 30px;
          &:hover {
            background-color: #fff;
          }
          a {
            color: #393939;
            font-size: 14px;
            display: block;
            &:hover {
              color: #ee373d;
              background-color: #fff;
            }
          }
        }
      }
    }
    .search-box {
      position: relative;
      text-align: right;
      top: 50px;
      right: 0;
      .search-btn {
        display: inline-block;
        position: relative;
        cursor: pointer;
        input {
          width: 140px;
          height: 36px;
          background: #fff;
          // border: 1px solid rgba(255,255,255,0.6);
          // border-right: none;
          // border-radius: 20px 0 0 20px;
          line-height: 36px;
          border: 1px solid #ed7455;
          border-right: none;
          border-left: none;
          padding: 0 15px;
          font-size: 14px;
          color: #454545;
          vertical-align: middle;
          &:focus-visible {
            outline: none;
          }
          &::placeholder {
            color: #999;
          }
        }
        .selectVal {
          font-size: 13px;
          color: #a7100d;
          cursor: pointer;
          width: 66px;
          padding-left: 15px;
          display: inline-block;
          background: #fff;
          border: 1px solid #ed7455;
          border-radius: 18px 0 0 18px;
          border-right: none;
          height: 36px;
          vertical-align: top;
          text-align: left;
          line-height: 36px;
          position: relative;
          &::before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 20px;
            background: #e0e0e0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          &::after {
            content: '';
            display: inline-block;
            width: 8px;
            height: 5px;
            background-image: url('../assets/image/whrjgczyxy/index/search-down.png');
            background-size: cover;
            position: absolute;
            top: 52%;
            right: 10px;
            transform: translateY(-50%);
          }
        }
      }
      .search-menu {
        position: absolute;
        width: 80px;
        top: 50px;
        right: 180px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(160, 160, 160, 0.58);
        border-radius: 2px;
        text-align: left;
        padding: 5px 14px 4px;
        z-index: 20;
      }
      ul {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          display: inline-block;
          border-top: 10px solid transparent;
          border-bottom: 10px solid #fff;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          top: -20px;
          left: 3px;
        }
        li {
          font-size: 13px;
          color: #6f6f6f;
          height: 36px;
          line-height: 36px;
          border-bottom: 1px solid #f5f5f5;
          position: relative;
          cursor: pointer;
          &:hover {
            color: #c00800;
          }
          &.active {
            &::before {
              content: '';
              display: inline-block;
              width: 2px;
              height: 14px;
              background: #c00800;
              position: absolute;
              left: -12px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      .search {
        display: inline-block;
        width: 50px;
        height: 36px;
        border-radius: 0 18px 18px 0;
        border: 1px solid #ed7455;
        border-left: none;
        border-left: 0;
        line-height: 36px;
        font-size: 15px;
        color: #ffffff;
        vertical-align: top;
        cursor: pointer;
        background: #fff url('../assets/image/whrjgczyxy/index/search-icon.png')
          no-repeat center;
        background-size: 16px auto;
      }
    }
  }
}
.sdcm-navbar-list {
  width: 100%;
  height: 50px;
  background: #880101;
  .nav-bar-inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-items {
      height: 50px;
      line-height: 50px;
      &.active {
        background: #a82b17;
        a {
          color: #ffd49e;
          font-weight: bold;
          &::after {
            content: '';
            display: inline-block;
            height: 2px;
            background: #ffe7c8;
            border-radius: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
          }
        }
      }
      &:hover {
        a {
          color: #ffd49e;
          font-weight: bold;
        }
      }
      a {
        margin: 0 18px;
        font-size: 17px;
        font-weight: bold;
        color: #ffd49e;
        position: relative;
        height: 50px;
        display: inline-block;
      }
    }
  }
}
</style>