<template>
  <div class="jsfz-content">
    <div class="header-box"></div>
    <div class="bg-box">
      <div class="bg-box1"></div>
      <div class="bg-box2"></div>
      <div class="bg-box3"></div>
    </div>
    <div class="content-info">
      <div class="ddsf-content">
        <div class="content-title"></div>
        <jsfzSwiperVue
          :list="sdsfList"
          :btnval="'sdsf'"
          v-if="isSdsfSwiper"
        ></jsfzSwiperVue>
        <div class="content-list" v-else-if="sdsfList && sdsfList.length > 0">
          <div
            class="content-item"
            v-for="item in sdsfList"
            :key="item.id"
            @click="goToClassDetail(item)"
          >
            <div class="item-img">
              <div
                :style="`background-image: url('${item.list_photo}');`"
              ></div>
            </div>
            <div class="item-name">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-else class="noData-box">
          <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
          <div class="text">暂无相关内容</div>
        </div>
      </div>
      <div class="jxyr-content">
        <div class="content-title"></div>
        <jsfzSwiperVue
          :list="jxyrList"
          :btnval="'jxyr'"
          v-if="isjxyrSwiper"
        ></jsfzSwiperVue>
        <div class="content-list" v-else-if="jxyrList && jxyrList.length > 0">
          <div
            class="content-item"
            v-for="item in jxyrList"
            :key="item.id"
            @click="goToClassDetail(item)"
          >
            <div class="item-img">
              <div
                :style="`background-image: url('${item.list_photo}');`"
              ></div>
            </div>
            <div class="item-name">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-else class="noData-box">
          <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
          <div class="text">暂无相关内容</div>
        </div>
      </div>
      <div class="zyjk-content">
        <div class="content-title"></div>
        <jsfzSwiperVue
          :list="zyjkList"
          :btnval="'zyjk'"
          v-if="iszyjkSwiper"
        ></jsfzSwiperVue>
        <div class="content-list" v-else-if="zyjkList && zyjkList.length > 0">
          <div
            class="content-item"
            v-for="item in zyjkList"
            :key="item.id"
            @click="goToClassDetail(item)"
          >
            <div class="item-img">
              <div
                :style="`background-image: url('${item.list_photo}');`"
              ></div>
            </div>
            <div class="item-name">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-else class="noData-box">
          <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
          <div class="text">暂无相关内容</div>
        </div>
      </div>
      <div class="zyly-content">
        <div class="content-title"></div>
        <jsfzSwiperVue
          :list="zylyList"
          :btnval="'zyly'"
          v-if="iszylySwiper"
        ></jsfzSwiperVue>
        <div class="content-list" v-else-if="zylyList && zylyList.length > 0">
          <div
            class="content-item"
            v-for="item in zylyList"
            :key="item.id"
            @click="goToClassDetail(item)"
          >
            <div class="item-img">
              <div
                :style="`background-image: url('${item.list_photo}');`"
              ></div>
            </div>
            <div class="item-name">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-else class="noData-box">
          <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
          <div class="text">暂无相关内容</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsfzSwiperVue from "../../components/jsfzSwiper.vue";
import services from "../../assets/js/services";
import { mapActions } from "vuex";
import utils from "../../assets/js/utils.js";

export default {
  name: "xxzykjsfz",
  components: {
    jsfzSwiperVue,
  },
  data() {
    return {
      isSdsfSwiper: false,
      isjxyrSwiper: false,
      iszyjkSwiper: false,
      iszylySwiper: false,
      school_code: localStorage.getItem("schoolCode"),
      category_id: {
        sdsf: 98,
        jxyr: 97,
        zyjk: 99,
        zyly: 100,
      },
      sdsfList: [],
      jxyrList: [],
      zyjkList: [],
      zylyList: [],
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getsdsfList();
    this.getjxyrList();
    this.getzyjkList();
    this.getzylyList();
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    goToClassDetail(item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(
          `${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.id}&category_name=${item.name}&cm_id=${item.id}`
        );
      });
    },
    async getsdsfList() {
      let res = await services.getkcszList({
        page: 1,
        size: 100,
        school_code: this.school_code,
        category_id: this.category_id.sdsf,
      });
      console.log(res);
      if (res.code == "0000" && res.data.items.length > 0) {
        if (res.data.items.length > 6) {
          this.isSdsfSwiper = true;
          this.sdsfList = utils.setSwiperList(res.data.items);
        } else {
          this.sdsfList = res.data.items;
        }
      }
    },
    async getjxyrList() {
      let res = await services.getkcszList({
        page: 1,
        size: 100,
        school_code: this.school_code,
        category_id: this.category_id.jxyr,
      });
      console.log(res);
      if (res.code == "0000" && res.data.items.length > 0) {
        if (res.data.items.length > 6) {
          this.isjxyrSwiper = true;
          this.jxyrList = utils.setSwiperList(res.data.items);
        } else {
          this.jxyrList = res.data.items;
        }
      }
    },
    async getzyjkList() {
      let res = await services.getkcszList({
        page: 1,
        size: 100,
        school_code: this.school_code,
        category_id: this.category_id.zyjk,
      });
      console.log(res);
      if (res.code == "0000" && res.data.items.length > 0) {
        if (res.data.items.length > 6) {
          this.iszyjkSwiper = true;
          this.zyjkist = utils.setSwiperList(res.data.items);
        } else {
          this.zyjkList = res.data.items;
        }
      }
    },
    async getzylyList() {
      let res = await services.getkcszList({
        page: 1,
        size: 100,
        school_code: this.school_code,
        category_id: this.category_id.zyly,
        // category_id: 2
      });
      console.log(res);
      if (res.code == "0000" && res.data.items.length > 0) {
        if (res.data.items.length > 6) {
          this.iszylySwiper = true;
          this.zylyList = utils.setSwiperList(res.data.items);
        } else {
          this.zylyList = res.data.items;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.jsfz-content {
  position: relative;
  width: 100%;
  overflow: hidden;
  .header-box {
    width: 100%;
    height: 400px;
    background-image: url("../../assets/image/xxzyk/jsfz/header-bg.png");
    background-size: cover;

    background-position: center;
  }
  .bg-box {
    position: absolute;
    width: 100%;
    .bg-box1,
    .bg-box2,
    .bg-box3 {
      width: 100%;
      height: 1020px;
      background-size: 100% auto;
    }
    .bg-box1 {
      background-image: url("../../assets/image/xxzyk/jsfz/bg1.png");
    }
    .bg-box2 {
      background-image: url("../../assets/image/xxzyk/jsfz/bg2.png");
    }
    .bg-box3 {
      background-image: url("../../assets/image/xxzyk/jsfz/bg3.png");
    }
  }
  .content-info {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    .ddsf-content {
      margin-top: 42px;
      .content-title {
        width: 100%;
        height: 53px;
        background-size: 100% auto;
        background-image: url("../../assets/image/xxzyk/jsfz/ddsf-tit.png");
      }
      .content-list {
        margin-top: 28px;
        display: flex;
        flex-wrap: wrap;
        .content-item {
          cursor: pointer;
          background: #fff;
          padding-top: 22px;
          height: 281px;
          width: 372px;
          border: 1px solid #e8d9c5;
          margin-left: 42px;
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          &:nth-child(n + 4) {
            margin-top: 28px;
          }
          &:hover .item-img div {
            transform: scale(1.1);
          }
          .item-img {
            height: 209px;
            width: 100%;
            background: #c3c3cc;
            overflow: hidden;
            div {
              transition: all 0.5s;
              width: 100%;
              height: 100%;
              background-size: 100% auto;
              background-position: center;
            }
          }
          .item-name {
            font-size: 18px;
            font-weight: 400;
            color: #3a3a3a;
            margin: 11px 20px 10px 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .jxyr-content {
      margin-top: 42px;
      .content-title {
        width: 100%;
        height: 53px;
        background-size: 100% auto;
        background-image: url("../../assets/image/xxzyk/jsfz/jxyr-bg.png");
      }
      .content-list {
        margin-top: 28px;
        display: flex;
        flex-wrap: wrap;
        .content-item {
          cursor: pointer;
          background: #fff;
          padding-top: 22px;
          height: 281px;
          width: 372px;
          border: 1px solid #e8d9c5;
          margin-left: 42px;
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          &:nth-child(n + 4) {
            margin-top: 28px;
          }
          &:hover .item-img div {
            transform: scale(1.1);
          }
          .item-img {
            height: 209px;
            width: 100%;
            background: #c3c3cc;
            overflow: hidden;
            div {
              transition: all 0.5s;
              width: 100%;
              height: 100%;
              background-size: 100% auto;
              background-position: center;
            }
          }
          .item-name {
            font-size: 18px;
            font-weight: 400;
            color: #3a3a3a;
            margin: 11px 20px 10px 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .zyjk-content {
      margin-top: 42px;
      .content-title {
        width: 100%;
        height: 53px;
        background-size: 100% auto;
        background-image: url("../../assets/image/xxzyk/jsfz/zyjk-bg.png");
      }
      .content-list {
        margin-top: 28px;
        display: flex;
        flex-wrap: wrap;
        .content-item {
          cursor: pointer;
          background: #fff;
          padding-top: 22px;
          height: 281px;
          width: 372px;
          border: 1px solid #e8d9c5;
          margin-left: 42px;
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          &:nth-child(n + 4) {
            margin-top: 28px;
          }
          &:hover .item-img div {
            transform: scale(1.1);
          }
          .item-img {
            height: 209px;
            width: 100%;
            background: #c3c3cc;
            overflow: hidden;
            div {
              transition: all 0.5s;
              width: 100%;
              height: 100%;
              background-size: 100% auto;
              background-position: center;
            }
          }
          .item-name {
            font-size: 18px;
            font-weight: 400;
            color: #3a3a3a;
            margin: 11px 20px 10px 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .zyly-content {
      margin-top: 42px;
      margin-bottom: 60px;
      .content-title {
        width: 100%;
        height: 53px;
        background-size: 100% auto;
        background-image: url("../../assets/image/xxzyk/jsfz/zyly-bg.png");
      }
      .content-list {
        margin-top: 28px;
        display: flex;
        flex-wrap: wrap;
        .content-item {
          cursor: pointer;
          background: #fff;
          padding-top: 22px;
          height: 281px;
          width: 372px;
          border: 1px solid #e8d9c5;
          margin-left: 42px;
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          &:nth-child(n + 4) {
            margin-top: 28px;
          }
          &:hover .item-img div {
            transform: scale(1.1);
          }
          .item-img {
            height: 209px;
            width: 100%;
            background: #c3c3cc;
            overflow: hidden;
            div {
              transition: all 0.5s;
              width: 100%;
              height: 100%;
              background-size: 100% auto;
              background-position: center;
            }
          }
          .item-name {
            font-size: 18px;
            font-weight: 400;
            color: #3a3a3a;
            margin: 11px 20px 10px 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>