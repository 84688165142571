<template>
  <div class="main-header">
    <div class="left-logo">
      <a href="https://www.jiaoyu.cn"><img src="../assets/image/newLogin/logo-eol-icon.png"/></a>
    </div>
    <div class="right-brief">中国教育在线旗下网站</div>
  </div>
</template>

<script>

export default {
  name: "loginHeader",
  components: {},
  data() {
    return {}
  },
  methods: {
    
  }
}
</script>

<style scoped>
.main-header {
  width: 100%;
  padding: 30px 0 0;
  background-color: #F1F2F7;
}
.main-header .left-logo {
  width: 134px;
  height: 40px;
  margin: 0 20px 0 40px;
  display: inline-block;
  vertical-align: top;
}
.main-header .left-logo img,
.main-header .right-brief img {
  width: 100%;
  height: auto;
}
.main-header .right-brief {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: top;
}
.user-agree-page .main-header {
  padding: 20px 0;
}
</style>