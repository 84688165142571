import services from '../assets/js/services'
import utils from '../assets/js/utils'
export default {
  state: {
    subjectAllData: [],
    subjectTreeData: [],
    subjectLevelData: [], // 专业类别menu keys 一级 二级
    suggestData: [], // 应用场景列表
    majorData: [], // 相关专业数据
    resAreaData: [], // 资源地域特色
    szElementData: [], // 思政元素
    resourceType: [], // 资源类型
    szzykListData: [], // 思政资源库列表页数据
    szzykListNums: 0, // 思政资源库列表总页数
    pageTotal: 0,
    pageNum: 10,
    szzykDetailsData: {}, // 详情页面数据
    recommendList: [], // 用于首页、详情页推荐的数据列表
    listCount: 0, // 总条数
    themeList: [],
    knowledgeData: [], // 知识点
    topicData: [], // 主题模块
    caseResources: []
  },
  getters: {
  },
  actions: {
    // 获取思政资源库学科专业列表
    getSubjectMenu (context, payload) {
      return services.getSubjectMenu(payload).then(res => {
        if (res.code == '0000') {
          if (payload.type == 3) {
            context.commit('setMajorData', {
              'majorData': res.data ? res.data : []
            })
          } else {
            context.commit('setSubjectAll', {
              'data': res.data ? res.data : []
            })
          }
        }
        return res;
      })
    },
    // // 获取思政资源库- 筛选 - 地域特色列表
    // getAreaData(context, payload) {
    //   return services.getSzzykArea(payload).then(res => {
    //     if (res.code == '0000') {
    //       context.commit('setResAreaData', {
    //         'data': res.data ? res.data : []
    //       })
    //     }
    //     return res;
    //   })
    // },
    // 获取思政资源库- 筛选 - 思政元素列表
    getSzzykCondition (context, payload) {
      return services.getSzzykCondition(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setScreenData', {
            'data': res.data ? res.data : null,
          })
        }
        return res;
      })
    },
    // 获取思政资源库- 筛选 - 应用场景列表
    getSzzykSuggestList (context, payload) {
      return services.getSzzykSuggest(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSuggestData', {
            'data': res.data ? res.data : [],
          })
        }
        return res;
      })
    },
    getTopicData(context, payload) {
      return services.getThemeList(payload).then(res => {
        if(res.code == "0000") {
          context.commit('setTopicData',res)
        }
      })
    },
    // 获取思政资源库 列表页和 推荐列表数据
    getSzzykListData (context, payload) {
      return services.getSzzykListData(payload).then(res => {
        if (res.code == '0000') {
          if (payload.is_recommend && payload.is_recommend == 2) {
            context.commit('setRecommendList', {
              'data': res.data && res.data.items ? res.data : null
            })
          } else {
            context.commit('setSzzykListData', {
              'data': res.data && res.data.items ? res.data : null
            })
          }
        }
        return res;
      })
    },

    getSzzykListCount (context, payload) {
      return services.getSzzykListData(payload).then(res => {
        if (res.data.total) {
          context.commit("setListCount", res.data)
        }
        return res;
      })
    },

    // 获取思政资源库详情页 推荐列表数据
    getSzzyRecommendData (context, payload) {
      return services.getSzzykListData(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setRecommendList', {
            'data': res.data && res.data.items ? res.data : null
          })
        }
        return res;
      })
    },
    // 获取思政资源库 详情页数据
    getSzzykDetails (context, payload) {
      return services.getSzzykDetails(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSzzykDetailsData', {
            'data': res.data ? res.data : null
          })
        }
        return res;
      })
    },
    // 获取备课平台url - wps 文本选取
    getBeikeSelectOffice (context, payload) {
      return services.selectBeikeOffice(payload).then(res => {
        if (res.code == '0000' && res.data.url) {
          window.open(`${res.data.url}&refer=${window.location.href}`)
        }
        return res;
      })
    },
    // 获取备课平台url - 图片选取
    getBeikeSelectImage (context, payload) {
      return services.selectBeikeImage(payload).then(res => {
        if (res.code == '0000' && res.data.url) {
          window.open(`${res.data.url}&refer=${window.location.href}`)

        }
        return res;
      })
    },
    // 获取备课平台url - 视频选取
    getBeikeSelectVideo (context, payload) {
      return services.selectBeikeVideo(payload).then(res => {
        if (res.code == '0000' && res.data.url) {
          window.open(`${res.data.url}&refer=${window.location.href}`)

        }
        return res;
      })
    },
    // 收藏资源
    saveResourceFavorite (context, payload) {
      return services.saveResourceFavorite(payload).then(res => {
        if (res.code == '2001') {
          let userInfo = localStorage.getItem("userInfo")
          if (!userInfo) {
            context.dispatch('getImageCaptha', {}, { root: true })
            context.commit('setLoginVisible', true, { root: true }) // 重点
          }
        }
        return res;
      })
    },
    async getResourceType(context, payload) {
      let res =  await services.getResourceType(payload)
      if (res.code == "0000") {
        if(res.data && res.data.type_list) {
          context.commit("setResourceType",res.data.type_list )
        }
      }
    }
  },
  mutations: {
    // 专业类别树结构数据
    setSubjectAll (state, payload) {
      if (payload.data.length > 0) {
        state.subjectAllData = payload.data
        let newarr = utils.transTreeData(payload.data, 'id', 'pid', 'children', 0);
        state.subjectTreeData = newarr;
        this.commit('setSubjectLevelData', payload)
      } else {
        state.subjectAllData = [];
        state.subjectTreeData = [];
      }
    },
    // 专业类别一级 keys
    setSubjectLevelData (state, payload) {
      if (payload.data.length > 0) {
        let keysArr = ['all'];
        payload.data.forEach(elem => {
          keysArr.push(`${elem.id}`)
        });
        state.subjectLevelData = keysArr;
      }
    },
    resetMajorData (state,) {
      state.majorData = [];
    },
    setMajorData (state, payload) {
      if (payload.majorData.length == 1) {
        state.majorData = payload.majorData
      } else {
        state.majorData = [{
          id: 'all',
          name: '全部',
          isActive: true,
        }, ...payload.majorData];
      }

    },
    async setTopicData(state, payload) {
      state.topicData = await payload.data && payload.data.length > 0 ? [{ name: "全部", id: "" }, ...payload.data] : [];
    },
    async setResourceType(state,payload) {
      state.caseResources = await payload && payload.length > 0 ? [{ name: "全部", id: "" }, ...payload] : [];
    },
    // setResAreaData(state, payload) {
    //   if(payload.data.length > 0){
    //     state.resAreaData = payload.data;
    //   } else {
    //     state.resAreaData = []
    //   }
    // },
    // setSzElementData(state, payload) {
    //   if(payload.data.length > 0){
    //     let newArr = utils.transTreeData(payload.data, 'id', 'pid', 'children', 0);
    //     state.szElementData = newArr;
    //   } else {
    //     state.szElementData = [];
    //   }
    // },
    async setScreenData (state, payload) {
      if (payload.data) {
        let param = {
          id: 'all',
          name: '全部',
          isActive: true,
        };
        state.resAreaData = payload.data.area_list && payload.data.area_list.length > 0 ? [param, ...payload.data.area_list] : [];
        // state.szElementData = [param, ...payload.data.element_list];
        if (payload.data.element_list && payload.data.element_list.length > 0) {
          let newArr = utils.transTreeData(payload.data.element_list, 'id', 'pid', 'children', 0);
          state.szElementData = [param, ...newArr];
        } else {
          state.szElementData = []
        }
        // state.multipleSzys = [param, ...newArr]
        state.resourceType = payload.data.type_list && payload.data.type_list.length > 0 ? payload.data.type_list : [];
        state.suggestData = payload.data.suggest_list && payload.data.suggest_list.length > 0 ? payload.data.suggest_list : [];
        // console.log(payload.data.knowledge_list);

        let newData = payload.data.knowledge_list
        let newArr = []
        for (let i = 0; i < newData.length; i++) {
          newArr.push({ name: newData[i], id: Math.random(0, 10000) + i })
        }
        state.knowledgeData = await newArr && newArr.length > 0 ? [{ name: "全部", id: "all", isActive: true }, ...newArr] : [];
        // state.topicData = await payload.data.topic_list && payload.data.topic_list.length > 0 ? [{ name: "全部", id: "" }, ...payload.data.topic_list] : [];

      } else {
        state.resAreaData = [];
        state.szElementData = [];
        state.resourceType = [];
        state.suggestData = []
      }
    },
    setSuggestData (state, payload) {
      state.suggestData = payload.data
    },
    setSzzykListData (state, payload) {
      if (payload.data) {
        state.szzykListData = payload.data.items;
        // state.listCount = payload.data.total
        // state.pageTotal = payload.data.total
        // state.szzykListNums = payload.data.page_num
      } else {
        state.szzykListData = [];
        // state.listCount = 0
        // state.pageTotal = 0
        // state.szzykListNums = 0
      }
    },
    setRecommendList (state, payload) {
      if (payload.data) {
        state.recommendList = payload.data.items;
      } else {
        state.recommendList = [];
      }
    },
    // 单选 和 多选时设置思政元素数据
    chooseSetELement (state, payload) {
      state.szElementData = JSON.parse(JSON.stringify(payload))
    },
    // 单选 和 多选时设置相关专业数据
    chooseSetMajor (state, payload) {
      state.majorData = JSON.parse(JSON.stringify(payload))
    },
    // 单选 和 多选时设置相关专业数据
    chooseSetZsd (state, payload) {
      state.knowledgeData = JSON.parse(JSON.stringify(payload))
    },
    setSzzykDetailsData (state, payload) {
      if (payload.data) {
        if (payload.data.subject_list && payload.data.subject_list.length > 0) {
          let newList = utils.transTreeData(payload.data.subject_list, 'id', 'pid', 'children', 0);
          payload.data.new_subject_list = newList
        }
        state.szzykDetailsData = payload.data;
      } else {
        state.szzykDetailsData = {};
      }
      // 请求分页器、列表总数
    },
    setListCount (state, payload) {
      state.listCount = payload.total
      state.pageTotal = payload.total
      state.szzykListNums = payload.page_num
    },
  }
}