<template>
  <div class="nav-jxsy">
    <div class="resize-con">
      <div class="jxsy-tit"></div>
      <div class="jxsy-content">
        <div
          class="sy-item"
          v-for="item in menuList"
          :key="item.categoryInd"
          @click="tojxlhList(item)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="get-more" @click="toMoreList">
        <div class="more-icon"></div>
        <div class="more-text">查 看 更 多</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      menuList: [
        {
          title: "装 备 制 造 大 类",
          categoryInd: 1,
        },
        {
          title: "交 通 运 输 大 类",
          categoryInd: 2,
        },
        {
          title: "农 林 牧 渔 大 类",
          categoryInd: 3,
        },
        {
          title: "能 源 动 力 与 材 料 大 类",
          categoryInd: 4,
        },
        {
          title: "土 木 建 筑 大 类",
          categoryInd: 5,
        },
        {
          title: "轻 工 纺 织 大 类",
          categoryInd: 6,
        },
      ],
    };
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    toMoreList() {
      this.goAppsDetailsBeforeLogin().then(() => {
        let routeUrl = this.$router.resolve({
          path: `/${this.school_code}/gjjs/jxlh`,
        });
        window.open(routeUrl.href, "_blank");
      });
    },
    tojxlhList(item) {
      if (item) {
        this.goAppsDetailsBeforeLogin().then(() => {
          let routeUrl = this.$router.resolve({
            path: `/${this.school_code}/gjjs/jxlh`,
            query: {
              ind: item.categoryInd,
            },
          });
          window.open(routeUrl.href, "_blank");
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-jxsy {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 139px;
  background-image: url("../../../assets/image/gjjs/index/nav-index/nav-xsgj-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  .resize-con {
    position: absolute;
    left: 308px;
    top: 50%;
    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 468px;
      height: 548px;
      top: 54px;
      left: -246px;
      z-index: 0;
      background-image: url("../../../assets/image/gjjs/index/nav-xsgj/gjjs-sidber.png");
      background-size: cover;
      background-repeat: no-repeat;

      z-index: 0;
    }
    .jxsy-tit {
      width: 700px;
      height: 109px;
      background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxlh.title.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin: 0 auto;
      // margin-left: 23.5%;
    }
    .jxsy-content {
      position: relative;
      z-index: 1;
      box-shadow: 0px 11px 26px 4px rgba(168, 141, 147, 0.68);
      width: 1100px;
      height: 630px;
      margin-top: 43px;
      // margin-left: 16%;
      background-color: #fcf4e9;
      background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxlh-bg.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: bottom;
      display: flex;
      justify-content: space-between;
      padding: 0 72px;
      .sy-item {
        width: 116px;
        height: 479px;
        margin-top: 59px;
        background-size: 100% auto;
        background-repeat: no-repeat;
        transition: all 0.5s;
        cursor: pointer;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #fff2e6;
        writing-mode: vertical-lr;
        display: flex;
        padding-top: 52px;
        align-items: center;
        background-repeat: no-repeat;

        &::before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: #fff;
          transform: rotate(45deg);
          margin-bottom: 4.5%;
        }
        &:nth-child(even) {
          margin-top: 105px;
        }
        &:nth-child(1) {
          background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzx1.png");
        }
        &:nth-child(2) {
          background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzx2.png");
        }
        &:nth-child(3) {
          background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzx3.png");
        }
        &:nth-child(4) {
          background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzx4.png");
        }
        &:nth-child(5) {
          background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzx5.png");
        }
        &:nth-child(6) {
          background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzx6.png");
        }
        &:hover {
          transform: translateY(-10px);
          // box-shadow: 0px 6px 5px 4px rgba(168,141,147,0.68);
        }
      }
    }
    .get-more {
      display: flex;
      justify-content: center;
      margin-top: 22px;
      // margin: 2% auto 0;
      // margin-left: 38%;
      cursor: pointer;
      .more-icon {
      width: 42px;
        height: 24px;
        background-image: url("../../../assets/image/gjjs/index/nav-index/nav-more.png");
        background-size: cover;
        background-repeat: no-repeat;

        margin-right: 14px;
      }
      .more-text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ab030c;
      }
    }
  }
}
@media screen and (max-width: 1900px) and (min-width: 1700px) {
  .resize-con {
    transform: scale(0.9) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-height: 600px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .resize-con {
    transform: scale(0.84) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1550px) {
  .resize-con {
    transform: scale(0.8) translateY(-50%);
    transform-origin: 0 0;
  }
}

@media screen and (max-width: 1550px) {
  .resize-con {
    transform: scale(0.74) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1500px) {
  .resize-con {
    transform: scale(0.7) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1400px) {
  .resize-con {
    transform: scale(0.6) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1300px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (min-width: 1900px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
@media screen and (min-height: 1080px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
</style>