<template>
  <div class="nav-jxsy">
    <div class="resize-con">
      <div class="jxsy-tit"></div>
      <div class="jxsy-content">
        <div class="jxsy-item" @click="toMoreList(1)">
          <div class="item-tit">
            <div class="item-index">1</div>
            尚巧求精 卓绝典范
          </div>
          <div class="img-content">
            <div class="img-border">
              <img
                src="../../../assets/image/gjjs/index/nav-jxsy/jxsy1.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="jxsy-item" @click="toMoreList(2)">
          <div class="item-tit">
            <div class="item-index">2</div>
            以民为本 兴利除害
          </div>
          <div class="img-content">
            <div class="img-border">
              <img
                src="../../../assets/image/gjjs/index/nav-jxsy/jxsy2.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="jxsy-item" @click="toMoreList(3)">
          <div class="item-tit">
            <div class="item-index">3</div>
            尊师重教 德艺兼求
          </div>
          <div class="img-content">
            <div class="img-border">
              <img
                src="../../../assets/image/gjjs/index/nav-jxsy/jxsy3.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="jxsy-item" @click="toMoreList(4)">
          <div class="item-tit">
            <div class="item-index">4</div>
            与古为新 实业救国
          </div>
          <div class="img-content">
            <div class="img-border">
              <img
                src="../../../assets/image/gjjs/index/nav-jxsy/jxsy4.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="get-more" @click="toMoreList(-1)">
        <div class="more-icon"></div>
        <div class="more-text">查 看 更 多</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      columnList: [
        {
          tit: "尚巧求精 卓绝典范",
          url: "../../../assets/image/gjjs/index/nav-jxsy/jxsy1.jpg",
        },
        {
          tit: "以民为本 兴利除害",
          url: "../../../assets/image/gjjs/index/nav-jxsy/jxsy2.jpg",
        },
        {
          tit: "尊师重教 德艺兼求",
          url: "../../../assets/image/gjjs/index/nav-jxsy/jxsy3.jpg",
        },
        {
          tit: "与古为新 实业救国",
          url: "../../../assets/image/gjjs/index/nav-jxsy/jxsy4.jpg",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    toMoreList(ind) {
      this.goAppsDetailsBeforeLogin().then(() => {
        let routeUrl = this.$router.resolve({
          path: `/${this.school_code}/gjjs/jxsy`,
          query: {
            ind,
          },
        });
        window.open(routeUrl.href, "_blank");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.nav-jxsy {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 139px;
  background-image: url("../../../assets/image/gjjs/index/nav-index/nav-xsgj-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  .resize-con {
    position: absolute;
    left: 308px;
    top: 50%;
    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 468px;
      height: 548px;
      top: 54px;
      left: -246px;
      z-index: 0;
      background-image: url("../../../assets/image/gjjs/index/nav-xsgj/gjjs-sidber.png");
      background-repeat: no-repeat;

      background-size: cover;
      z-index: 0;
    }
    .jxsy-tit {
      width: 700px;
      height: 109px;
      background-image: url("../../../assets/image/gjjs/index/nav-jxsy/jxsy-tit.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      // margin-left: 23.5%;
      margin: 0 auto;
    }
    .jxsy-content {
      position: relative;
      z-index: 1;
      box-shadow: 0px 11px 26px 4px rgba(168, 141, 147, 0.68);
      width: 1100px;
      height: 630px;
      // margin-left: 16%;
      background-image: url("../../../assets/image/gjjs/index/nav-jxsy/jxsy-bg.png");
      background-repeat: no-repeat;

      background-size: cover;
      margin-top: 43px;
      display: flex;
      justify-content: space-evenly;
      .jxsy-item {
        width: 21.8%;
        height: 68.3%;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .item-tit {
          background: linear-gradient(
            90deg,
            #7d6242 0%,
            rgba(184, 163, 138, 0.1) 100%
          );
          // opacity: 0.54;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          align-items: center;
          text-indent: 1.5em;
          position: relative;
          .item-index {
            height: 100%;
            position: absolute;
            left: 0;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 14.83%;
            text-indent: 0;
            background: #765a38;
            border-radius: 50%;
          }
        }
        .img-content {
          flex: 1;
          margin-top: 18px;
          border-left: 1px dashed #9d5f2a;
          padding-left: 4.1%;
          position: relative;
          &::before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #fcf4e9;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translate(-50%, 50%);
          }
          .img-border {
            border: 2px solid #f6d4a1;
            position: relative;
            border-radius: 0 8% 0 8%;
            overflow: hidden;
            transition: all 0.5s;
            &::before,
            &::after {
              content: "";
              display: block;
              position: absolute;
              width: 0;
              height: 0;
            }
            &::before {
              border-top: 13px solid #f6d4a1;
              border-left: 13px solid #f6d4a1;
              border-bottom: 13px solid transparent;
              border-right: 13px solid transparent;
              top: 0;
              left: 0;
            }
            &::after {
              border-top: 13px solid transparent;
              border-left: 13px solid transparent;
              border-bottom: 13px solid #f6d4a1;
              border-right: 13px solid #f6d4a1;
              right: 0;
              bottom: 0;
            }
            img {
              width: 100%;
            }
          }
        }

        &:nth-child(1) {
          height: 445px;
          margin-top: 45px;
          .item-tit {
            width: 100%;
            height: 32px;
            .item-index {
              width: 32px;
              height: 32px;
            }
          }
        }
        &:nth-child(2) {
          height: 353px;
          margin-top: 100px;
          .item-tit {
            width: 100%;
            height: 32px;
            .item-index {
              width: 32px;
              height: 32px;
            }
          }
        }
        &:nth-child(3) {
          height: 425px;
          margin-top: 53px;
          .item-tit {
            width: 100%;
            height: 32px;
            .item-index {
              width: 32px;
              height: 32px;
            }
          }
        }
        &:nth-child(4) {
          height: 400px;
          margin-top: 79px;
          .item-tit {
            width: 100%;
            height: 32px;
            .item-index {
              width: 32px;
              height: 32px;
            }
          }
        }
        &:hover {
          .img-content {
            .img-border {
              box-shadow: 0px 6px 5px 4px rgba(168, 141, 147, 0.68);
              transform: translateY(-10px);
            }
          }
        }
      }
    }
    .get-more {
      display: flex;
      justify-content: center;
      margin-top: 22px;
      cursor: pointer;
      .more-icon {
        width: 42px;
        height: 24px;
        background-image: url("../../../assets/image/gjjs/index/nav-index/nav-more.png");
        background-repeat: no-repeat;

        background-size: cover;
        margin-right: 14px;
      }
      .more-text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ab030c;
      }
    }
  }
}
@media screen and (max-width: 1900px) and (min-width: 1700px) {
  .resize-con {
    transform: scale(0.9) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-height: 600px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .resize-con {
    transform: scale(0.84) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1550px) {
  .resize-con {
    transform: scale(0.8) translateY(-50%);
    transform-origin: 0 0;
  }
}

@media screen and (max-width: 1550px) {
  .resize-con {
    transform: scale(0.74) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1500px) {
  .resize-con {
    transform: scale(0.7) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1400px) {
  .resize-con {
    transform: scale(0.6) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1300px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (min-width: 1900px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
@media screen and (min-height: 1080px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
</style>