<template>
  <div class="xsgj-content">
    <div class="resize-con">
      <div class="xjp-info">
        <div class="xsgj-title"></div>
        <div class="xsgj-con" v-if="xsgjList.length > 0">
          <swiper :options="xsgjSwiper" v-if="xsgjList.length > 0">
            <swiperSlide v-for="(item, ind) in xsgjList" :key="ind">
              <div
                class="slide-item"
                v-for="(ele, index) in item"
                :key="index"
                @click="toDetail(ele)"
              >
                <div
                  class="img-cover"
                  :style="{
                    backgroundImage: ele.cover ? `url(${ele.cover})` : '',
                  }"
                ></div>
                <div class="item-title">
                  <span></span>
                  <div>{{ ele.title }}</div>
                </div>
              </div>
            </swiperSlide>
          </swiper>
          <div class="swiper-pagination xsgj"></div>
          <div class="swiper-button-next xsgj"></div>
          <div class="swiper-button-prev xsgj"></div>
        </div>
      </div>
      <div class="get-more" @click="toMoreList()">
        <div class="more-icon"></div>
        <div class="more-text">查 看 更 多</div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import services from "../../../assets/js/services.js";
import { mapActions } from "vuex";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      services,
      xsgjSwiper: {
        slidesPerView: 1,
        spaceBetween: 18,
        // loop: true,
        autoplay: true,
        disableOnInteraction: false,
        navigation: {
          // 前进后退按钮 ,不同版本配置方式不同
          nextEl: `.swiper-button-next.xsgj`,
          prevEl: `.swiper-button-prev.xsgj`,
        },
        pagination: {
          el: `.swiper-pagination.xsgj`,
        },
      },
      xsgjList: [],
    };
  },
  created() {
    this.getXsgjList();
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    async getXsgjList() {
      let res = await services.getAreaSzztkLists({
        page: 1,
        length: 8,
        count: 0,
        school_code: this.school_code,
        special_id: this.specialId,
        kind: "1",
        column_name: "习说工匠",
      });
      if (res.code == "0000" && res.data.items.length > 0) {
        let num = Math.ceil(res.data.items.length / 2) - 1;
        for (let i = 0; i <= num; i++) {
          if (i == num) {
            this.xsgjList.push(res.data.items.slice(i * 2));
          } else {
            this.xsgjList.push(res.data.items.slice(i * 2, i * 2 + 2));
          }
        }
      }
    },
    toDetail(item) {
      if (item) {
        this.goAppsDetailsBeforeLogin().then(() => {
          let routeUrl = this.$router.resolve({
            path: `/${this.school_code}/gjjs/detail`,
            query: {
              id: item.id,
              column_id: item.column_id,
              columnPath: "jdgj",
            },
          });
          window.open(routeUrl.href, "_blank");
        });
      }
    },
    toMoreList() {
      this.goAppsDetailsBeforeLogin().then(() => {
        let routeUrl = this.$router.resolve({
          path: `/${this.school_code}/gjjs/jdgj?ind=0`,
        });
        window.open(routeUrl.href, "_blank");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.xsgj-content {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url("../../../assets/image/gjjs/index/nav-index/nav-xsgj-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 17px;
  overflow: hidden;
  .resize-con {
    position: absolute;
    top: 17px;
    left: 310px;
    top: 50%;
    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 468px;
      height: 548px;
      top: 188px;
      left: -214px;
      z-index: 0;
      background-image: url("../../../assets/image/gjjs/index/nav-xsgj/gjjs-sidber.png");
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
    }
    .xjp-info {
      // margin: 17px 0 ;
      // margin-left: 277px;
      width: 1200px;
      height: 980px;
      background-image: url("../../../assets/image/gjjs/index/nav-xsgj/xjp-red-con.png");
      background-size: cover;
      background-repeat: no-repeat;
      padding-top: 184px;
      position: relative;
      z-index: 1;
      .xsgj-title {
        width: 284px;
        height: 84px;
        background-image: url("../../../assets/image/gjjs/index/nav-xsgj/xsgj-tit.png");
        background-size: cover;
        background-repeat: no-repeat;

        margin-left: 470px;
      }
      ::v-deep.xsgj-con {
        width: 838px;
        margin: 37px auto 0;
        height: 570px;
        position: relative;
        .swiper-container {
          height: 100%;
          .swiper-wrapper {
            height: 100%;
            .swiper-slide {
              display: flex;
              justify-content: space-between;
              .slide-item {
                cursor: pointer;
                width: 410px;
                height: 540px;
                background: #fce7c8;
                border-radius: 20px 20px 0px 0px;
                display: flex;
                flex-direction: column;
                justify-content: end;
                align-items: center;
                .img-cover {
                  width: 318px;
                  height: 470px;
                  background-size: cover;
                  background-position: center;
                  background-image: url("../../../assets/image/gjjs/index/nav-index/default-bg.png");
                  background-repeat: no-repeat;
                }
                .item-title {
                  span {
                    background: #b6260a;
                    width: 4px;
                    height: 14px;
                    margin-right: 0.5em;
                  }
                  width: 100%;
                  height: 40px;
                  background: #ffb773;
                  font-size: 15px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #333333;
                  padding: 0 7.3% 0 5.3%;
                  display: flex;
                  align-items: center;
                  // line-height: 36px;
                  div {
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
        .swiper-button-next.xsgj {
          width: 30px;
          height: 72px;
          background: rgba(28, 28, 28, 0.64);
          border-radius: 4px;
          top: 209px;
          opacity: 1;
          right: 5px;
          transform: translateX(78%);
          background-image: url("../../../assets/image/gjjs/index/nav-xsgj/xsgj-swiper-next.png");
          background-size: 40% 30%;
          background-repeat: no-repeat;
          background-position: center;
        }
        .swiper-button-prev.xsgj {
          width: 30px;
          height: 72px;
          background: rgba(28, 28, 28, 0.64);
          border-radius: 4px;
          top: 209px;
          opacity: 1;
          left: 5px;
          transform: translateX(-78%);
          background-image: url("../../../assets/image/gjjs/index/nav-xsgj/xsgj-swiper-pre.png");
          background-size: 40% 30%;
          background-repeat: no-repeat;
          background-position: center;
        }
        .swiper-pagination.xsgj {
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          .swiper-pagination-bullet {
            margin: 0 5px;
            opacity: 0.6;
            height: 6px;
            width: 6px;
            &.swiper-pagination-bullet-active {
              width: 26px !important;
              background-color: #fbedd2;
              opacity: 1;
            }
          }
        }
      }
    }
    .get-more {
      display: flex;
      margin: -7px auto 0;
      margin-left: 518px;
      cursor: pointer;
      .more-icon {
         width: 42px;
        height: 24px;
        background-image: url("../../../assets/image/gjjs/index/nav-index/nav-more.png");
        background-size: cover;
        background-repeat: no-repeat;

        margin-right: 14px;
      }
      .more-text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ab030c;
      }
    }
  }
}

@media screen and (max-width: 1900px) and (min-width: 1800px ) {
  .resize-con {
    transform: scale(0.94) translateY(-50%) !important;
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1800px) and (min-width: 1700px ) {
  .resize-con {
    transform: scale(0.85) translateY(-50%) !important;
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .resize-con {
    transform: scale(0.75) translateY(-50%) !important;
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1500px) {
  .resize-con {
    transform: scale(0.65) translateY(-50%) !important;
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1500px)  {
  .resize-con {
    transform: scale(0.60)  translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50%;
  }
}
@media screen and (max-width: 1400px)  {
  .resize-con {
    transform: scale(0.60) translate(-50%,-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
    top: 50% !important;
  }
}
@media screen and (max-width: 1300px)  {
  .resize-con {
    transform: scale(0.55) translate(-50%,-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
    top: 50% !important;

  }
}
@media screen and (max-height: 1080px) and (min-height: 900px) {
  .resize-con {
    transform: scale(0.85) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-height: 900px) and (min-height: 800px ) {
  .resize-con {
    transform: scale(0.75) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-height: 800px) and (min-height: 700px ) {
  .resize-con {
    transform: scale(0.68) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-height: 600px)  {
  .resize-con {
    transform: scale(0.55) translate(-50%,-50%)  !important;
    transform-origin: 0 0;
    left: 50% !important;
    top: 50% !important;

  }
}
@media screen and (min-width: 1921px)  {
  .resize-con {
    transform: translateY(-50%);
  }
}
@media screen and (min-height: 1080px)  {
  .resize-con {
    transform: translateY(-50%);
  }
}
</style>