import { render, staticRenderFns } from "./jsdt.vue?vue&type=template&id=1ac31dfa&scoped=true&"
import script from "./jsdt.vue?vue&type=script&lang=js&"
export * from "./jsdt.vue?vue&type=script&lang=js&"
import style0 from "./jsdt.vue?vue&type=style&index=0&id=1ac31dfa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ac31dfa",
  null
  
)

export default component.exports