<template>
  <div class="zjsj-content">
    <div class="zjsj-header"></div>
    <div class="content-infobg">
      <div class="content-info">
        <div class="zcjd-content">
          <div class="content-title"></div>
          <jsfzSwiperVue :list="zcjdList" :btnval="'zcjd'" v-if="iszcjdSwiper"></jsfzSwiperVue>
          <div class="content-list" v-else-if="zcjdList && zcjdList.length > 0">
            <div class="content-item" v-for="item in zcjdList" :key="item.id" @click="goToClassDetail(item)">
              <div class="item-img">
                <div :style="`background-image: url('${item.list_photo}');`"></div>
              </div>
              <div class="item-name">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div v-else class="noData-box">
            <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
            <div class="text">暂无相关内容</div>
          </div>
        </div>
        <div class="zjzg-content">
          <div class="content-title"></div>
          <jsfzSwiperVue :list="zjzgList" :btnval="'zjzg'" v-if="isjzzgSwiper"></jsfzSwiperVue>
          <div class="content-list" v-else-if="zjzgList && zjzgList.length > 0">
            <div class="content-item" v-for="item in zjzgList" :key="item.id" @click="goToClassDetail(item)">
              <div class="item-img">
                <div :style="`background-image: url('${item.list_photo}');`"></div>
              </div>
              <div class="item-name">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div v-else class="noData-box">
            <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
            <div class="text">暂无相关内容</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsfzSwiperVue from "../../components/jsfzSwiper.vue";
import services from "../../assets/js/services";
import { mapActions } from "vuex";
import utils from "../../assets/js/utils";
export default {
  name: "xxzyzjsj",
  components: {
    jsfzSwiperVue,
  },
  data () {
    return {
      iszcjdSwiper: false,
      isjzzgSwiper: false,
      school_code: localStorage.getItem("schoolCode"),
      categoryId: {
        zcjd: 101,
        zjzg: 102,
      },
      zcjdList: [],
      zjzgList: [],
    };
  },
  created () {
    this.getzcjdList();
    this.getzjzgList();
    window.scrollTo(0, 0);

  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    goToClassDetail (item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(
          `${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.id}&category_name=${item.name}&cm_id=${item.id}`
        );
      });
    },
    async getzcjdList () {
      let res = await services.getkcszList({
        page: 1,
        size: 10,
        school_code: this.school_code,
        category_id: this.categoryId.zcjd,
      });
      if (res.code == "0000" && res.data.items.length > 0) {
        if (res.data.items.length > 6) {
          this.iszcjdSwiper = true;
          this.zcjdList = utils.setSwiperList(res.data.items);
        } else {
          this.zcjdList = res.data.items;
        }
      }
    },
    async getzjzgList () {
      let res = await services.getkcszList({
        page: 1,
        size: 10,
        school_code: this.school_code,
        category_id: this.categoryId.zjzg,
        // category_id: 2

      });
      if (res.code == "0000" && res.data.items.length > 0) {
        if (res.data.items.length > 6) {
          this.isjzzgSwiper = true;
          this.zjzgList = utils.setSwiperList(res.data.items);
        } else {
          this.zjzgList = res.data.items;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.zjsj-content {
  .zjsj-header {
    width: 100%;
    height: 400px;
    background-image: url('../../assets/image/xxzyk/zjsj-banner.png');
    background-size: cover;

    background-position: center;
  }
  .content-infobg {
    width: 100%;
    background-image: url('../../assets/image/xxzyk/zjsj/zjsj-bg.png');
    background-size: 100% auto;
    padding-bottom: 45px;
    .content-info {
      width: 1200px;
      margin: 0 auto;
      .zcjd-content {
        padding: 32px 0;
        .content-title {
          width: 100%;
          height: 50px;
          background-image: url('../../assets/image/xxzyk/zjsj/zcjd-bg.png');
          background-size: 100%;
        }
        .content-list {
          margin-top: 28px;
          display: flex;
          flex-wrap: wrap;
          .content-item {
            cursor: pointer;
            background: #fff;
            padding-top: 22px;
            height: 281px;
            width: 372px;
            border: 1px solid #e8d9c5;
            margin-left: 42px;
            &:nth-child(3n + 1) {
              margin-left: 0;
            }
            &:nth-child(n + 4) {
              margin-top: 28px;
            }
            &:hover .item-img div {
              transform: scale(1.1);
            }
            .item-img {
              height: 209px;
              width: 100%;
              background: #c3c3cc;
              overflow: hidden;
              div {
                transition: all 0.5s;
                width: 100%;
                height: 100%;
                background-size: 100% auto;
                background-position: center;
              }
            }
            .item-name {
              font-size: 18px;
              font-weight: 400;
              color: #3a3a3a;
              margin: 11px 20px 10px 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .zjzg-content {
        .content-title {
          margin-top: 40px;
          width: 100%;
          height: 51px;
          background-image: url('../../assets/image/xxzyk/zjsj/zjzg-bg.png');
          background-size: 100%;
          background-position: center;
          background-repeat: no-repeat;
        }
        .content-list {
          margin-top: 28px;
          display: flex;
          flex-wrap: wrap;
          .content-item {
            cursor: pointer;
            background: #fff;
            padding-top: 22px;
            height: 281px;
            width: 372px;
            border: 1px solid #e8d9c5;
            margin-left: 42px;
            &:nth-child(3n + 1) {
              margin-left: 0;
            }
            &:nth-child(n + 4) {
              margin-top: 28px;
            }
            &:hover .item-img div {
              transform: scale(1.1);
            }
            .item-img {
              height: 209px;
              width: 100%;
              background: #c3c3cc;
              overflow: hidden;
              div {
                transition: all 0.5s;
                width: 100%;
                height: 100%;
                background-size: 100% auto;
                background-position: center;
              }
            }
            .item-name {
              font-size: 18px;
              font-weight: 400;
              color: #3a3a3a;
              margin: 11px 20px 10px 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>