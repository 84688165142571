<template>
  <div class="thoughtParty">
    <div class="body">
      <!-- <Header :ztk="true" /> -->
      <template v-if="school_code == utils.whrj_code">
        <WhrjHeader />
      </template>
      <template v-else>
        <Header />
        <Navbar />
      </template>
      <div class="top-banner-box">
        <swiper ref="mySwiper" v-if="swiperData.length > 0" class="eol-swiper-box" :options="swiperOption" @mouseenter.native="swiperEnter" @mouseleave.native="swiperLeave">
          <template v-for="(item, index) in swiperData">
            <swiper-slide :key="index">
              <a href="javascript:void(0);" @click="szztkDetail(item)">
                <div class="eol-banner-item" :style="`background-image: url(${item.img_path});background-size: 100% 100%;`"></div>
              </a>
            </swiper-slide>
          </template>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="body-box">
        <div class="body-outer" ref="sideOterP">
          <div :class="['slide-bar',positionFlag?'active':'']" :style="[{left: positionFlag ? sideleftSize + 'px' : ''}]" id="jeol-sidebar" ref="sidebar">
            <div class="slide-bar-header">
              <div class="left-imgs">
                <img src="../../assets/image/new-szztk/index/icon-category.png">
              </div>
              <div class="right-txt">
                <div class="title">专题分类</div>
                <div class="desc">Thematic Classification</div>
              </div>
            </div>

            <div class="slide-bar-body">
              <div :class="['level1',level_1['active']==1?'active':'']" v-for="(level_1,level_idx1) in categoryData" :key="level_idx1">
                <div class="box" @click="openMenu(1, level_idx1,[],[],level_1)">
                  <div class="name">
                    {{level_1.name}}
                  </div>
                  <div :class="['show-icon',level_1.active?'active':'']" v-if="level_1.children"></div>
                </div>
                <template v-if="level_1.active">
                  <div :class="['level2',level_2['active']==1?'active':'']" v-for="level_2,level_idx2 in level_1.children" :key="level_idx2">
                    <div class="box" @click="openMenu(2, level_idx1, level_idx2,[],level_2)">
                      <div class="name">
                        {{level_2.name}}
                      </div>
                      <div :class="['show-icon',level_2.active?'active':'']" v-if="level_2.children"></div>
                    </div>
                    <template v-if="level_2.active">
                      <div :class="['level3',level_3['active']==1?'active':'']" v-for="level_3,level_idx3 in level_2.children" :key="level_idx3">
                        <div class="box" @click="openMenu(3, level_idx1, level_idx2, level_idx3,level_3)">
                          <div class="dot" v-show="level_3['active']"></div>
                          <div class="name">
                            {{level_3.name}}
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>

            </div>
          </div>
          <div class="list-body">
            <div :class="['list-header-menu',showFlag?'show':'']" ref="headerMenu" v-show="hotWords && hotWords.length>0 && category_id != 'recommend'">
              <div class="left-menu-title">
                专题热词：
              </div>
              <div class="right-menu-box">
                <div class="menu-body">
                  <div :class="['menu-item',currentIndex == index?'active':'']" v-for="item,index in hotWords" :key="index" @click="currentMenu(item,index)">
                    {{item}}
                  </div>
                </div>
                <div :class="['more',showFlag?'active':'']" @click="spread()" ref="moreButton">
                  {{showFlag?'收起':'更多'}}
                  <div class="icon"></div>
                </div>
              </div>
            </div>
            <template>
              <div class="list-main-box" v-if="listData && listData.length>0">
                <div class="item" v-for="item,index in listData" :key="index" @click="resDetails(item)">
                  <div class="thumb_img">
                    <div class="inner_img" :style='`background-image: url(${item.cover});background-size: 100% 100%;`'></div>
                  </div>
                  <div class="desc">
                    <div class="title">
                      {{item.name}}
                    </div>
                  </div>
                  <div class="tag">
                    <div class="tag-item" v-for="tag,tag_index in item.keywords" :key="tag_index">
                      {{tag}}
                    </div>
                  </div>
                  <!-- <div class="open-box" v-if="item.is_open == 1"> -->
                </div>
                <div class="sub-visibility"></div>
                <div class="sub-visibility"></div>
              </div>
              <div v-else class="noData-box">
                <img src="../../assets/image/jsckzyxy/empty.png" alt="">
                <div class="text">暂无相关内容</div>
              </div>
            </template>
            <div style="text-align: center;" v-if="total>0">
              <a-pagination v-model="currentPage" :show-total="total => `共${total}条`" :total="total" :pageSize="pageSize" @change="currentSzztkPage" />
            </div>
          </div>

          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <component :is="dialogConfig.name"></component>
    <Loading :showLoading="loading" />
  </div>
</template>
<script>
import Header from "../../components/Header.vue"
import WhrjHeader from "../../components/WhrjHeader.vue"
import Navbar from "../../components/Navbar.vue"
import { swiper, swiperSlide } from "vue-awesome-swiper";
import appleRes from "../../components/appleRes.vue"
import Loading from "../../components/loading.vue";
import utils from "../../assets/js/utils"
import { mapState, mapActions } from "vuex"
// import services from '../../assets/js/services';
export default {
  name: "HotProject",
  components: {
    Header,
    Navbar,
    swiper,
    swiperSlide,
    appleRes,
    Loading,
    WhrjHeader
  },
  data () {
    return {
      utils,
      selectedKeys: [],
      swiperData: [
        // { img_path: require('../../assets/image/new-szztk/background3.jpg'), path: 'http://news.haiwainet.cn/special/20cpcnc/' },
        { img_path: require('../../assets/image/new-szztk/background4.png'), path: 'http://news.haiwainet.cn/special/ztjy/' },
        { img_path: require('../../assets/image/new-szztk/background2.png'), path: 'http://app.people.cn/h5/topic/361' },
        // { img_path: require('../../assets/image/new-szztk/background1.png'), id: "6334151ba36edd3a6b02f692" },
        { img_path: require('../../assets/image/new-szztk/background1.png'), path: "http://cpc.people.com.cn/GB/67481/444826/index.html" },
        { img_path: require('../../assets/image/new-szztk/background.png'), path: "http://theory.people.com.cn/GB/40557/457725/index.html" },
      ],
      swiperOption: {
        loop: true,
        autoplay: {
          pauseOnMouseEnter: true,
        },
        speed: 400, // 切换图片速度
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
      },
      hotWords: [],
      listData: [],
      showFlag: false,
      // 当前选中热词的索引
      currentIndex: -1,
      positionFlag: 0,
      sideleftSize: 0,
      topMenuHeight: 0,
      rootSubmenuKeys: [],
      currentPage: 1,
      pageSize: 15,
      total: 0,
      dialogConfig: {
        name: ""
      },
      categoryData: this.szztkCategory,
      // 分类id
      category_id: "recommend", // 推荐专题 recommend 全部 “”
      // 选中的热词
      keywords: "",
      school_code: localStorage.getItem("schoolCode"),
    }
  },
  async mounted () {
    let that = this
    if (!that.schoolMenu) {
      await that.getSchoolMenuStatus(that.school_code);
    }
    if (!that.schoolMenu || !that.schoolMenu.open_szztk) {
      that.$router.push(`/${that.school_code}/zy`)
    }
    if (that.$route.query.flag == 1) {
      await that.goAppsDetailsBeforeLogin()
    } else if (that.$route.query.flag == 2) {
      that.dialogConfig.name = "appleRes"
      that.$set(that.$route.query, "flag", "")
      that.$router.push(`/${that.school_code}/zy/szztk`)
    }

    // 获取边栏父元素body-outer到窗口左侧的初始距离
    // js写法 document.getElementById("jeol-sidebar").getBoundingClientRect().left
    that.sideleftSize = that.$refs.sideOterP.getBoundingClientRect().left;
    that.$nextTick(() => {
      // 监听页面滚动事件
      window.addEventListener("scroll", that.handleScroll)
      // 监听窗口大小的变化 获取边栏父元素body-outer到窗口左侧的距离
      window.onresize = () => {
        return (() => {
          that.sideleftSize = that.$refs.sideOterP.getBoundingClientRect().left;
        })()
      }
    })

    if (that.$route.query.cateId) {
      that.category_id = that.$route.query.cateId
    }
    that.getSzztkTotal({ count: 1, school_code: that.school_code })
    that.getListData({ school_code: that.school_code })
    that.getCategory().then(() => {
      if (that.$route.query.cateId) {
        that.category_id = that.$route.query.cateId
        if (that.categoryData && that.categoryData.length > 0) {
          that.categoryData.forEach((element, index) => {
            if (element.id == that.category_id) {
              that.openMenu(1, index, [], [], element)
            }
          });
        }
      }
    })
    that.getHotWords()
  },
  computed: {
    mySwiper () {
      return this.$refs.mySwiper && this.$refs.mySwiper.swiper;
    },
    ...mapState({
      szztkList: state => state.szztk.szztkList,
      szztkTotal: state => state.szztk.szztkTotal,
      szztkCategory: state => state.szztk.szztkCategory,
      szztkKeyWords: state => state.szztk.szztkKeyWords,
      loading: state => state.szztk.loading,
      schoolMenu: (state) => state.appsLogin.schoolMenu,
    }),
  },
  methods: {
    ...mapActions(['getSzztkList', 'getSzztkTotal', 'getSzztkCategory', 'getSzztkKeyWords', 'goAppsDetailsBeforeLogin', 'getSchoolMenuStatus']),
    async getListData () {
      let params = {
        page: this.currentPage,
        length: this.pageSize,
        keywords: this.keywords,
        school_code: this.school_code,
      }
      if (this.category_id == 'recommend') {
        params.is_recommend = 2 // 1不推荐 2推荐
      } else {
        params.category_id = this.category_id
      }
      await this.getSzztkList(params)
      await this.getSzztkTotal({ count: 1, ...params })
      this.listData = this.szztkList
      this.total = this.szztkTotal
    },
    // 控制专题热词更多按钮 显示/隐藏
    controlMore () {
      this.$nextTick(() => {
        this.topMenuHeight = this.$refs.headerMenu.offsetHeight
        if (this.topMenuHeight > 85) {
          this.$refs.headerMenu.style.height = 85 + 'px'
          this.$refs.headerMenu.style.overflow = 'hidden'
          this.$refs.moreButton.style.display = 'flex'
        } else {
          this.$refs.headerMenu.style.height = 'unset'
          this.$refs.headerMenu.style.overflow = 'unset'
          this.$refs.moreButton.style.display = 'none'
        }
      })
    },

    // 左侧菜单固定定位
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // 0未超出 1超出可视窗口 
      if (scrollTop > 356) {
        this.positionFlag = 1
      } else {
        this.positionFlag = 0
      }
    },
    // 初始化树形结构菜单
    initTree () {
      if (this.categoryData && this.categoryData.length > 0) {
        for (let i = 0; i < this.categoryData.length; i++) {
          if (this.categoryData[i].children && this.categoryData[i].children.length > 0) {
            this.$set(this.categoryData[i], "active", 0)
            for (let j = 0; j < this.categoryData[i].children.length; j++) {
              if (this.categoryData[i].children[j].children && this.categoryData[i].children[j].children.length > 0) {
                this.$set(this.categoryData[i].children[j], "active", 0)
                for (let t = 0; t < this.categoryData[i].children[j].children.length; t++) {
                  this.$set(this.categoryData[i].children[j].children[t], "active", 0)
                }
              }
            }
          }
        }
        this.$set(this.categoryData[0], "active", 1)
      }
    },

    // 点击或者
    async openMenu (level, inx1, indx2, inx3, item) {
      this.currentPage = 1
      if (this.categoryData && this.categoryData.length > 0) {
        for (let i = 0; i < this.categoryData.length; i++) {
          // 一级进入
          if (level >= 1 && i == inx1) {
            this.categoryData[i].active = 1
            // this.categoryData[i].active = this.categoryData[i].active == 1 ? this.$set(this.categoryData[i], "active", 1) : this.$set(this.categoryData[i], "active", 1)
          } else {
            this.categoryData[i].active = this.$set(this.categoryData[i], "active", 0)
          }

          if (this.categoryData[i].children && this.categoryData[i].children.length > 0) {
            for (let j = 0; j < this.categoryData[i].children.length; j++) {
              // 2/3 级 进入
              if (level >= 2 && i == inx1 && j == indx2) {
                this.categoryData[i].active = 1
                // this.categoryData[i].active = this.categoryData[i].active == 1 ? this.$set(this.categoryData[i], "active", 1) : this.$set(this.categoryData[i], "active", 1)
                this.categoryData[i].children[j].active = this.categoryData[i].children[j].active == 1 ? this.$set(this.categoryData[i].children[j], "active", 1) : this.$set(this.categoryData[i].children[j], "active", 1)
              } else {
                this.$set(this.categoryData[i].children[j], "active", 0)
              }
              if (this.categoryData[i].children[j].children && this.categoryData[i].children[j].children.length > 0) {
                for (let t = 0; t < this.categoryData[i].children[j].children.length; t++) {
                  if (level == 3 && i == inx1 && j == indx2 && t == inx3) {
                    // this.categoryData[i].active = this.categoryData[i].active == 1 ? 0 : 1
                    // this.categoryData[i].children[j].active = this.categoryData[i].children[j].active == 1 ? 0 : 1
                    this.categoryData[i].children[j].children[t].active = this.categoryData[i].children[j].children[t].active == 1 ? 0 : 1
                  } else {
                    this.categoryData[i].children[j].children[t].active = 0
                  }
                }
              }
            }
          }
        }
      }

      if (item.active) {
        if (item.id == this.category_id) return
        this.currentIndex = -1
        this.keywords = ""
        this.category_id = item.id
        this.getListData()
        this.getHotWords()
      }
    },

    // 查看更多 / 收起
    spread () {
      this.showFlag = !this.showFlag
    },
    // 切换热词标签
    currentMenu (item, index) {
      this.currentPage = 1
      if (this.currentIndex == index) {
        this.currentIndex = -1
        this.keywords = ""
      } else {
        this.currentIndex = index
        this.keywords = item
      }

      this.getListData()
    },

    // 获取热词列表
    async getHotWords () {
      await this.getSzztkKeyWords({ category_id: this.category_id == 'recommend' ? '' : this.category_id })
      this.hotWords = this.szztkKeyWords
      this.controlMore()
    },

    // 跳转到专题资源详情
    resDetails (item) {
      let that = this
      that.goAppsDetailsBeforeLogin().then(() => {
        // window.open(`${services.BEIKE_SERVER}/${that.school_code}/sz/special`)
        // if (item.is_open == 1) {
        let userinfo = JSON.parse(localStorage.getItem("userInfo"))
        // 登录后判断用户无权限 跳转到无权限页面
        if (userinfo && userinfo.user_type == 1 && !userinfo.role_ids) {
          that.$router.push({
            path: `/${that.school_code}/zy/noauth`
          })
        } else {
          if (item.address && (item.address.indexOf('http://') > -1 || item.type == 2)) {
            window.open(item.address)
          } else {
            window.open(`/${that.school_code}/zy/szztk/detail/${item.id}`)
          }
        }
        // } else {
        //   that.dialogConfig.name = "appleRes"
        // }
      });
    },
    szztkDetail (item) {
      let that = this
      this.goAppsDetailsBeforeLogin().then(() => {
        if (item.path) {
          window.open(item.path)
        } else {
          window.open(`/${that.school_code}/zy/szztk/detail/${item.id}`)
        }
      });
    },
    // 切换分页
    async currentSzztkPage (page) {
      let that = this
      this.currentPage = 1
      await that.goAppsDetailsBeforeLogin().then(async () => {
        this.currentPage = page
        await this.getListData()
        document.documentElement.scrollTop = 0;
      });
    },
    // 获取分类
    async getCategory () {
      await this.getSzztkCategory()
      this.categoryData = this.szztkCategory
      this.initTree()
    },

    // 轮播图鼠标 进入/离开 暂停/播放
    swiperEnter () {
      this.mySwiper && this.mySwiper.autoplay.stop();
    },
    swiperLeave () {
      this.mySwiper && this.mySwiper.autoplay.start();
    },
  },

}

</script>

<style scoped>
.eol-banner-item {
  background-size: cover !important;
}
.eol-swiper-box,
.eol-banner-item {
  height: 140px !important;
}
.body-box {
  width: 1200px;
  margin: 0 auto;
  padding: 24px 0 55px;
}
.body-outer {
  position: relative;
  width: 100%;
  text-align: right;
}
.body-box .slide-bar {
  background-color: #fff;
  width: 192px;
  border-bottom: 1px solid #d9d9d9;
  vertical-align: top;
  display: inline-block;
  text-align: left;
  float: left;
  width: 192px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  position: unset;
}

.body-box .slide-bar.active {
  position: fixed;
  top: 25px;
}

.body-box .slide-bar .slide-bar-header {
  width: 100%;
  height: 56px;
  background: linear-gradient(162deg, #dd252a 0%, #e13d27 100%);
  display: flex;
  align-items: center;
}
.body-box .slide-bar .slide-bar-header .left-imgs {
  margin: 0 10px 0 14px;
}
.body-box .slide-bar .slide-bar-header .right-txt .title {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
.body-box .slide-bar .slide-bar-header .right-txt .desc {
  font-size: 12px;
  color: #e47f79;
}
.body-box .slide-bar .slide-bar-body {
  margin-top: 5px;
}
/* ZRR */
/* 一级样式 */
::v-deep.body-box
  .slide-bar
  .slide-bar-body
  .ant-menu-submenu
  .ant-menu-submenu-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
::v-deep
  .body-box
  .slide-bar
  .slide-bar-body
  .ant-menu-submenu
  .ant-menu-submenu-inline
  .ant-menu-submenu-open
  .ant-menu-submenu-title {
  background-color: #fff;
  color: red;
}
/* 二级样式 */
::v-deep
  .body-box
  .slide-bar
  .slide-bar-body
  .ant-menu-submenu-inline
  .ant-menu-inline
  .ant-menu-submenu-title {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
}

/* 三级样式 */
::v-deep
  .body-box
  .slide-bar
  .slide-bar-body
  .ant-menu-submenu-inline
  .ant-menu-item {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
/* ZRR */
.body-box .slide-bar .slide-bar-body .level1 {
  width: 100%;
  margin-top: 1px;
  font-size: 16px;
  font-weight: bold;
  box-sizing: border-box;
}
.body-box .slide-bar .slide-bar-body .level1 > .box {
  height: 56px;
  background: #f7f8fa;
  position: relative;
}

.body-box .slide-bar .slide-bar-body .level1.active {
  color: #dc3034;
}
.body-box .slide-bar .slide-bar-body .level1.active > .box {
  background: #fff0f0;
}
.body-box .slide-bar .slide-bar-body .level1.active > .box::before {
  content: '';
  display: inline-block;
  width: 2px;
  height: 100%;
  background-color: #dc3034;
  position: absolute;
  left: 0;
}
.body-box .slide-bar .slide-bar-body .level1:hover {
  color: #dc3034;
}

.body-box .slide-bar .slide-bar-body .level2 {
  width: 192px;
  font-size: 14px;
  line-height: 32px;
  color: #666666;
}
.body-box .slide-bar .slide-bar-body .level2.active > .box {
  background: #fff0f0;
  color: #d12327;
}

.body-box .slide-bar .slide-bar-body .level1 .box,
.body-box .slide-bar .slide-bar-body .level2 .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 0 20px;
  cursor: pointer;
}
.body-box .slide-bar .slide-bar-body .level2 .box {
  padding: 0 12px 0 20px;
  height: 34px;
  margin: 5px 0;
}

.body-box .slide-bar .slide-bar-body .level2 > .box:hover {
  background: #f9fafc;
}
.body-box .slide-bar .slide-bar-body .level2.active > .box:hover {
  background: #fff0f0;
}

.body-box .slide-bar .slide-bar-body .level3 .name {
  margin-left: 0;
}
.body-box .slide-bar .slide-bar-body .level3 {
  font-size: 12px;
  color: #666666;
  display: flex;
  align-items: center;
  padding: 20px 0;
  height: 14px;
}
.body-box .slide-bar .slide-bar-body .level3 .box {
  width: 192px;
  justify-content: unset;
  padding-left: 26px;
}
.body-box .slide-bar .slide-bar-body .level3:nth-child(1) {
  padding-top: 30px;
}
.body-box .slide-bar .slide-bar-body .level3.active {
  color: #dc3034;
}
.body-box .slide-bar .slide-bar-body .level3 .dot {
  width: 5px;
  height: 5px;
  background: #c41b1f;
  border-radius: 50%;
}
.body-box .slide-bar .slide-bar-body .level3 .name {
  margin-left: 9px;
}
/* 默认 */
.body-box .slide-bar .slide-bar-body .level1 .show-icon,
.body-box .slide-bar .slide-bar-body .level2 .show-icon {
  width: 8px;
  height: 8px;
  background-size: cover !important;
  background: url('../../assets/image/new-szztk/index/menu-more.png');
}
.body-box .slide-bar .slide-bar-body .level1 .show-icon:hover,
.body-box .slide-bar .slide-bar-body .level2 .show-icon:hover {
  background: url('../../assets/image/new-szztk/index/menu-more-hover.png');
}

/* 展开 */
.body-box .slide-bar .slide-bar-body .level1 .show-icon.active,
.body-box .slide-bar .slide-bar-body .level2 .show-icon.active {
  width: 8px;
  height: 2px;
  background-size: cover !important;
  background: url('../../assets/image/new-szztk/index/menu-up-hover.png');
}
.body-box .slide-bar .slide-bar-body .level1 .show-icon.active:hover,
.body-box .slide-bar .slide-bar-body .level2 .show-icon.active:hover {
  width: 8px;
  height: 2px;
  background-size: cover !important;
  background: url('../../assets/image/new-szztk/index/menu-up-hover.png');
}

.body-box .list-body {
  width: 955px;
  display: inline-block;
}
.body-box .list-body .list-header-menu {
  padding: 20px 20px 2px;
  background: #f7f8fa;
  display: flex;
  width: 929px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.body-box .list-body .list-header-menu .left-menu-title {
  width: 70px;
  font-size: 14px;
  font-weight: bold;
  color: #222222;
  height: 100%;
  margin-right: 18px;
}
.body-box .list-body .list-header-menu .right-menu-box {
  flex: 1;
  display: flex;
}
.body-box .list-body .list-header-menu .right-menu-box .menu-body {
  display: flex;
  flex-wrap: wrap;
  width: 92%;
}

.body-box .list-body .list-header-menu.show {
  height: unset !important;
}
.body-box .list-body .list-header-menu.show.icon {
  background: url('../../assets/image/new-szztk/index/icon-more-active.png');
}
.body-box .list-body .list-header-menu .right-menu-box .more {
  width: 56px;
  height: 22px;
  background: #f28484;
  border-radius: 2px;
  line-height: 22px;
  font-size: 12px;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}
.body-box .list-body .list-header-menu .right-menu-box .more .icon {
  width: 8px;
  height: 8px;
  background-size: cover !important;
  margin-left: 4px;
  cursor: pointer;
  background: url('../../assets/image/new-szztk/index/icon-more.png');
}
.body-box .list-body .list-header-menu .right-menu-box .more.active .icon {
  background: url('../../assets/image/new-szztk/index/icon-more-active.png');
}
.body-box .list-body .list-header-menu .menu-item {
  font-size: 14px;
  color: #222222;
  margin-right: 55px;
  cursor: pointer;
  margin-bottom: 10px;
}
.body-box .list-body .list-header-menu .menu-item.active {
  color: #dc3034;
}
.body-box .list-body .list-header-menu .menu-item:hover {
  color: #dc3034;
}
.body-box .list-body .list-main-box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 15px;
  min-height: 450px;
  align-items: flex-start;
}
.body-box .list-body .list-main-box .sub-visibility {
  flex: 0 0 294px;
  display: inline-block;
  overflow: visible;
}
.body-box .list-body .list-main-box .item {
  width: 310px;
  margin-bottom: 25px;
  transition: all 0.5s;
  z-index: 1;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(255, 255, 255, 0);
  cursor: pointer;
}
.body-box .list-body .list-main-box .item .open-box {
  position: absolute;
  right: 20px;
  top: 0;
  background: url('../../assets/image/new-szztk/index/status-open.png');
  width: 62px;
  height: 32px;
  background-size: cover;
}

.body-box .list-body .list-main-box .item:hover {
  background-color: #fff;
  width: 310px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(146, 151, 157, 0.3);
  z-index: 10;
}
.body-box .list-body .list-main-box .item:hover .tag {
  background-color: #fff;
}

.body-box .list-body .list-main-box .item .thumb_img {
  width: 294px;
  height: 165px;
  border-radius: 2px;
  margin: 0 auto 12px;
  overflow: hidden;
}
.body-box .list-body .list-main-box .item .inner_img {
  transition: all 0.8s;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
}
.body-box .list-body .list-main-box .item:hover .thumb_img .inner_img {
  transform: scale(1.1);
}
.body-box .list-body .list-main-box .item .desc {
  padding: 0 8px;
  text-align: left;
}
.body-box .list-body .list-main-box .item .desc .title {
  font-size: 16px;
  font-weight: bold;
  color: #2f2e30;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 10px;
}
.body-box .list-body .list-main-box .item .desc .title::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 14px;
  background: #dc3034;
  margin-right: 8px;
}
.body-box .list-body .list-main-box .item .tag {
  font-size: 12px;
  color: #a59287;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  padding: 0 8px;
  border-radius: 4px;
}
.body-box .list-body .list-main-box .item:hover .tag {
  white-space: pre-wrap;
  overflow: auto;
  position: absolute;
  left: 0;
  top: 211px;
  z-index: 100;
  box-shadow: 0px 6px 10px 0px rgba(146, 151, 157, 0.3);
  border-radius: 4px;
  min-height: 40px;
  padding: 0 8px 10px;
}
.body-box .list-body .list-main-box .item .tag-item {
  display: inline-block;
  padding: 0 12px;
  background: #f8f9fb;
  border-radius: 12px;
  margin-right: 4px;
  height: 24px;
  line-height: 24px;
  margin-bottom: 6px;
}

/* ant */
.ant-menu-inline {
  width: 100% !important;
}
::v-deep .ant-menu-submenu-arrow {
  display: none !important;
}

.slide-bar-body .icon {
  background: url('../../assets/image/new-szztk/index/menu-more.png');
  width: 8px;
  height: 8px;
  background-size: cover !important;
}
::v-deep .ant-menu.ant-menu-inline:hover .icon {
  background: url('../../assets/image/new-szztk/index/menu-more-hover.png');
}

.ant-menu-submenu-title .icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.ant-menu-submenu.ant-menu-submenu-selected .icon {
  background: url('../../assets/image/new-szztk/index/menu-up.png');
  width: 8px;
  height: 2px;
  background-size: cover !important;
}
::v-deep .ant-menu-submenu.ant-menu-submenu-selected:hover .icon {
  background: url('../../assets/image/new-szztk/index/menu-up-hover.png');
}
.slide-bar-body ul > li {
  width: 100%;
  /* height: 56px; */
  background: #f7f8fa;
}
::v-deep .slide-bar-body ul > li > .ant-menu-submenu-title {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 1px 0 !important;
}
.ant-menu-item {
  margin: 1px 0;
}
.ant-menu-item:nth-child(1) {
  margin: 1px 0;
}
::v-deep .slide-bar-body ul > li > div > span > span {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
::v-deep .ant-menu-submenu-selected > div {
  background: #fff0f0;
}
::v-deep .ant-menu-submenu-selected > div > span > span {
  color: #dc3034 !important;
}

.noData-box {
  width: 100%;
  text-align: center;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 150px;
}
.noData-box img {
  width: 240px;
  height: auto;
  margin: 0 auto 30px;
}
.noData-box .text {
  color: #76797e;
  font-size: 14px;
}
</style>