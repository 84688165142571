<template>
  <div>
    <div class="sdcm-header-box">
      <div class="btn-group">
        <div class="school-home">
          <a href="http://www.sdcmc.edu.cn/" target="_blank" class="text" v-html="'< 学校主页'"></a>
        </div>
        <div class="search-box">
          <div class="search-btn">
            <div class="selectVal" ref="val" @click="showMenu = !showMenu">{{searchSelect}}</div>
            <input type="text" ref="search" placeholder="请输入关键词" v-model="keyword" @keyup.enter="handleSearch()">
            <div class="search" @click="handleSearch()">搜索</div>
          </div>
          <div class="search-menu" v-show="showMenu">
            <ul>
              <li :class="[item.name == searchSelect ?'active':'']" v-for="item,index in searchMenu" :key="index" @click="currentCatid(item,index)">{{item.name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="sdcm-navbar-list">
      <div class="nav-bar-inner">
        <template v-for="(item, index) in navList">
          <div :class='[$route.path == item.path ? "nav-items active": "nav-items" ,active == item.path ? "nav-items active": "nav-items" ]' :key="index">
            <template>
              <router-link class="nav-href" :to="item.path" v-if="!item.outside&&!item.needLogin">{{item.title}}</router-link>
              <a v-else class="nav-href" href="javascript:void(0)" @click="toPath(item)">{{item.title}}</a>
            </template>
          </div>
        </template>
      </div>
    </div>
    <Login ref="loginchild"></Login>

  </div>

</template>

<script>
import Login from "../components/Login.vue"
import { mapState, mapActions, mapMutations } from "vuex"

import services from '../assets/js/services'
export default {
  props: ['active', 'keywords'],
  data () {
    return {
      school_code: localStorage.getItem("schoolCode"),
      navList: [],
      searchMenu: [
        { name: "全部", catid: '14618,14619,14622,14623,14624,14625' },
        { name: "建设动态", catid: '14618' },
        { name: "典型经验", catid: '14619' },
        { name: "政策文件", catid: '14622,14623,14624,14625' },
        { name: "示范课程", catid: '14632' },
      ],
      searchSelect: "全部",
      keyword: "",
      showMenu: false,
      currentData: { item: { name: "全部", catid: '14618,14619,14622,14623,14624,14625' }, index: 0 }
    }
  },
  components: {
    Login,
  },
  mounted () {
    this.navList = [
      {
        title: '主页',
        path: `/${this.school_code}/sdcmzyxy`,
        outside: false,
      },
      {
        title: '平台介绍',
        path: `/${this.school_code}/sdcmzyxy/ptjs`,
        outside: false,
      },
      {
        title: '建设动态',
        path: `/${this.school_code}/sdcmzyxy/jsdt`,
        outside: false,
      },
      {
        title: '典型经验',
        path: `/${this.school_code}/sdcmzyxy/dxjy`,
        outside: false,
      },
      {
        title: '政策文件',
        path: `/${this.school_code}/sdcmzyxy/zcwj`,
        outside: false,
      },
      {
        title: '示范课程',
        path: `/${this.school_code}/sdcmzyxy/sfkc`,
        outside: false,
      },
      {
        title: '资源库',
        path: `${services.KCSZ_SERVER}/${this.school_code}/zy/szzyk`,
        outside: true,
      },
      {
        title: '师资团队',
        path: `/${this.school_code}/sdcmzyxy/sztd`,
        outside: false,
      },

      // {
      //   title: '媒体报道',
      //   path: `/${this.school_code}/sdcmzyxy/mtbd`,
      //   outside: false,
      // },

      // {
      //   title: '学校主页',
      //   path: `http://www.sdcmc.edu.cn/`,
      //   outside: true,
      // },
      { title: "资源推荐", path: `${services.BEIKE_SERVER}/${this.school_code}/sz/school/me`, outside: true, needLogin: true, }
    ]
  },
  methods: {
    ...mapActions(['goAppsDetailsBeforeLogin']),
    ...mapMutations(['setAppsLoginVisible']),
    toPath (item) {
      let that = this
      if (item.needLogin) {
        that.goAppsDetailsBeforeLogin().then(() => {
          window.open(item.path, "_blank")
        })
      } else {
        window.open(item.path, '_blank')
      }
    },
    handleSearch () {
      let val = this.keyword
      if (val) {
        this.$router.push({
          path: `/${this.school_code}/sdcmzyxy/search`, query: {
            keyword: this.keyword,
            time: Date.parse(new Date()),
            currentData: this.currentData,
          }
        })

        this.$emit("refresh", this.currentData)
      } else {
        this.$message.error('请输入搜索关键字')
        return false
      }
    },
    currentCatid (item, index) {
      this.searchSelect = item.name
      this.$nextTick(() => {
        this.$refs.search.style['padding-left'] = this.$refs.val.offsetWidth + 50 + 'px'
      })
      this.showMenu = false
      this.currentData = { item, index }
    }
  },

  computed: {
    ...mapState({
      loginVisible: state => state.appsLogin.loginVisible,
    }),
  },
  watch: {
    keywords (val) {
      if (val) {
        this.keyword = val
      }
    }
  }


}
</script>

<style scoped lang="less">
.sdcm-header-box {
  width: 100%;
  height: 142px;
  background-image: url('../assets/image/sdcmzyxy/header-banner.jpg');
  background-size: cover;
  background-position: center;
  .btn-group {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    .school-home {
      .text {
        display: inline-block;
        width: 123px;
        height: 34px;
        background: rgba(253, 218, 172, 0.79);
        border-radius: 17px;
        text-align: center;
        line-height: 34px;
        position: absolute;
        right: 5px;
        top: 62%;
        transform: translate(0, -50%);
        font-size: 16px;
        color: #6e1318;
        cursor: pointer;
      }
    }
    .search-box {
      position: relative;
      text-align: right;
      top: 50px;
      right: 220px;
      .search-btn {
        display: inline-block;
        position: relative;
        input {
          width: 236px;
          height: 40px;
          background: rgba(255, 221, 179, 0.24);
          border: 1px solid #e3b18e;
          border-right: none;
          border-radius: 20px 0 0 20px;
          line-height: 40px;
          padding: 0 15px 0 78px;
          font-size: 14px;
          color: #e29479;
          opacity: 0.96;
          vertical-align: middle;
          &:focus-visible {
            outline: none;
          }
          &::placeholder {
            color: #e29479;
          }
        }
        .selectVal {
          font-size: 14px;
          color: #f9d7aa;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 15px;
          cursor: pointer;
          z-index: 10;
          &::after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 20px;
            background: #e3b18e;
            position: absolute;
            top: 50%;
            right: -8px;
            transform: translateY(-50%);
          }
          &::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 5px;
            background-image: url('../assets/image/sdcmzyxy/search-down.png');
            background-size: cover;
            position: absolute;
            right: -25px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .search-menu {
        position: absolute;
        width: 120px;
        top: 50px;
        right: 188px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(160, 160, 160, 0.58);
        border-radius: 2px;
        text-align: left;
        padding: 5px 14px 4px;
        z-index: 20;
      }
      ul {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          display: inline-block;
          border-top: 10px solid transparent;
          border-bottom: 10px solid #fff;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          top: -20px;
          left: 3px;
        }
        li {
          font-size: 13px;
          color: #6f6f6f;
          height: 36px;
          line-height: 36px;
          border-bottom: 1px solid #f5f5f5;
          position: relative;
          cursor: pointer;
          &:hover {
            color: #c00800;
          }
          &.active {
            &::before {
              content: '';
              display: inline-block;
              width: 2px;
              height: 14px;
              background: #c00800;
              position: absolute;
              left: -12px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      .search {
        display: inline-block;
        width: 70px;
        height: 40px;
        background: #950202;
        border: 1px solid #e3b18e;
        border-radius: 0px 20px 20px 0px;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        color: #ffffff;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
}
.sdcm-navbar-list {
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, #ae0801 0%, #ae1e01 100%);
  .nav-bar-inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-items {
      height: 50px;
      line-height: 50px;
      &.active {
        background: #ac3c14;
        a {
          color: #ffffff;
        }
      }
      &:hover {
        a {
          color: #ffffff;
        }
      }
      a {
        padding: 0 25px;
        font-size: 16px;
        font-weight: bold;
        color: #e3b189;
      }
    }
  }
}
</style>