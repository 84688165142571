<template>
  <div>
    <SdcmHeader />
    <div class="sdcm-container-box">
      <!-- 新闻动态 - 隐藏 -->
      <!-- <div class="sdcm-top-box">
        <div class="swiper-banner">
          <swiper ref="mySwiperBanner" v-if="indexSlideArr.length > 1" class="eol-swiper-box" :options="swiperBannerOption">
            <template v-for="item,index in indexSlideArr">
              <swiper-slide :key="index">
                <div class="banner-box" :style="`background-image:url(${item.thumb_image})`"></div>
              </swiper-slide>
            </template>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <template v-else>
            <template v-for="item,index in indexSlideArr">
              <div class="banner-box" :key="index" :style="`background-image:url(${item.thumb_image})`"></div>
            </template>
          </template>
        </div>
        <div class="swiper-top-container">
          <div class="swiper-box">
            <swiper ref="mySwiper" v-if="indexXwdt.length > 0" class="eol-swiper-box" :options="swiperOption">
              <template v-for="(item,index) in indexXwdt">
                <swiper-slide :key="index" v-if="index<3">
                  <div class="eol-banner-item" :style='`background-image: url(${item.thumb_image});background-size: 100% 100%;`' @click="goDetail(item,'jsdt')">
                    <div class="text">
                      <p>{{item.title}}</p>
                    </div>
                  </div>
                </swiper-slide>
              </template>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>

          </div>
          <div class="sidebar">
            <div class="top-main">
              <div class="icon"></div>
              <div class="text">新闻动态</div>
            </div>
            <a :href="'/'+school_code+'/sdcmzyxy/jsdt'" class="bottom-more">more></a>

          </div>
          <div class="right-article-box">
            <div class="article-container">
              <template v-for="(item,index) in indexXwdt">
                <div class="item" :key="index" v-if="index > 2">
                  <div class="top-main">
                    <div class="publish-time">
                      <div class="day">{{item.publish_date.substring(8,10)}}</div>
                      <div class="month">{{item.publish_date.substring(0,7)}}</div>
                    </div>
                    <div @click="goDetail(item,'jsdt')" class="publish-title">{{item.title}}</div>
                  </div>
                  <div class="description">{{item.description}}</div>
                </div>

              </template>
            </div>

          </div>
        </div>
      </div> -->

      <div class="sdcm-banner-box">
        <swiper ref="mySwiperBanner" v-if="bannerData&&bannerData.length>0" class="eol-swiper-box" :options="swiperBannerOption">
          <template v-for="item,index in bannerData">
            <swiper-slide :key="index">
              <div class="banner-item" :style="`background-image:url(${item.thumb_image})`" @click="goDetail(item,false)"></div>
            </swiper-slide>
          </template>
          <template v-if="bannerData&&bannerData.length>1">
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </template>
        </swiper>
      </div>

      <div class="body-bg-box">
        <div class="jsdt-container w1200" v-if="jsdtList">
          <div class="common-title jsdt">
            <div class="left-title">建设动态</div>
            <a :href="'/'+school_code+'/sdcmzyxy/jsdt'" target="_blank" class="right-more">更多></a>
          </div>
          <div class="list-box">
            <template v-for="item,index in jsdtList">
              <div class="item" :key="index" v-if="jsdtList && index <6">
                <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`"></div>
                <div class="right-content">
                  <div class="title" @click="goDetail(item,'jsdt')">{{item.title}}</div>
                  <div class="description">
                    <div class="time">{{item.issueTime.substring(0,10)}}</div>
                    <div class="intro" v-html="item.description"></div>
                  </div>
                </div>
              </div>
            </template>

          </div>
        </div>

        <div class="pxyt-container w1200" v-if="dxjyList">
          <div class="common-title pxyt">
            <div class="left-title">典型经验</div>
            <a :href="'/'+school_code+'/sdcmzyxy/dxjy'" target="_blank" class="right-more">更多></a>
          </div>
          <div class="list-box">
            <template v-for="(item,index) in dxjyList">
              <div class="item" :key="index" @click="goDetail(item,'dxjy')" v-if="index<4">
                <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`"></div>
                <div class="description">
                  <div class="title">{{item.title}}</div>
                  <div class="date">{{item.issueTime.substring(0,10)}}</div>
                  <div class="intro" v-html="item.description"></div>
                </div>
              </div>
            </template>

          </div>
        </div>

        <div class="zcwj-container w1200">
          <div class="common-title zcwj">
            <div class="left-title">政策文件</div>
            <a :href="'/'+school_code+'/sdcmzyxy/zcwj'" target="_blank" class="right-more">更多></a>
          </div>
          <div class="zcwj-list" v-if="zcwjList">
            <div class="left-article">
              <template v-for="(item,index) in zcwjList">
                <div class="item" :key="index" v-if="index<4">
                  <div class="month-day">{{item.issueTime.substring(5,10)}}</div>
                  <div class="title" @click="goDetail(item,'zcwj')">
                    <p>{{item.title}}</p>
                  </div>
                </div>
              </template>

            </div>
            <div class="right-group">
              <template v-for="item,index in zcwjList">
                <div class="item" :key="index" v-if="index > 3 && index <6" @click="goDetail(item,'zcwj')">
                  <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`"></div>
                  <div class="description">
                    <div class="article-info">
                      <div class="title">{{item.title}}</div>
                      <div class="date">{{item.issueTime.substring(0,10)}}</div>
                    </div>
                    <div class="intro" v-html='item.description'></div>
                  </div>
                </div>

              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="sfkc-container">
        <div class="sfkc-list zcwj w1200">
          <div class="common-title sfkc">
            <div class="left-title">示范课程</div>
            <a :href="'/'+school_code+'/sdcmzyxy/sfkc'" target="_blank" class="right-more">更多></a>
          </div>
          <div class="list-box" v-if="indexSfkc">
            <div class="item" v-for="(item,index) in indexSfkc" :key="index" @click="goCourse(item)">
              <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`"></div>
              <div class="description">
                <div class="title">
                  <div class="line"></div>
                  <div class="text">{{item.title}}</div>
                </div>
                <div v-if="item.ovtitle" class="author">{{item.ovtitle}}</div>
              </div>
            </div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
          </div>

        </div>
      </div>

      <div class="bottom-content">

        <div class="mstd-container w1200" v-if="teachGroup">
          <div class="common-title mstd">
            <div class="left-title">师资团队</div>
            <a :href="'/'+school_code+'/sdcmzyxy/sztd'" target="_blank" class="right-more">更多></a>
          </div>
          <div class="body-box">
            <div class="item" @click="goMstd(item)" v-for="item,index in teachGroup" :key="index">
              <div class="thumb-image" :style="item.thumb_image?`background-image:url(${item.thumb_image})`:'background-color:#f3f3f3'"></div>
              <div class="name">{{item.name}}</div>
            </div>

            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>

          </div>
        </div>
      </div>

    </div>
    <SdcmFooter :color="'#251D1B'" />

  </div>
</template>
<script>
import SdcmHeader from "../../components/SdcmHeader.vue"
import SdcmFooter from "../../components/SdcmFooter.vue"
import sdcmData from "./sdcmData"
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { mapActions, mapState, mapMutations } from "vuex"
import services from '../../assets/js/services'

export default {
  data () {
    return {
      // 顶部swiper数据
      // indexSlideArr: sdcmData.indexSlideArr.slice(0, 5),
      // 新闻动态数据
      // indexXwdt: sdcmData.indexXwdt.slice(0, 5),
      // 示范课程数据
      indexSfkc: sdcmData.indexSfkc['校本示范课-共建'],
      // 政策文件数据
      // indexZcwj: sdcmData.indexZcwj.slice(0, 6),
      // 培训研讨数据
      // indexPxyt: sdcmData.indexPxyt.slice(0, 4),
      // 媒体报道数据
      // jsdt: sdcmData.jsdt.slice(0, 8),
      // 名师团队数据
      teachGroup: sdcmData.teachGroup,


      // 建设动态
      jsdtList: [],
      // 政策文件
      zcwjList: [],
      // 典型经验
      dxjyList: [],
      // banner
      bannerData: [],



      swiperOption: {
        // loop: true,
        autoplay: {
          pauseOnMouseEnter: true,
        },
        speed: 400,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },

      },
      swiperBannerOption: {
        loop: true,
        autoplay: {
          pauseOnMouseEnter: true,
        },
        speed: 400,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      school_code: localStorage.getItem("schoolCode"),

    }
  },
  components: {
    swiper,
    swiperSlide,
    SdcmHeader,
    SdcmFooter,
  },
  computed: {
    mySwiper () {
      return this.$refs.mySwiper && this.$refs.mySwiper.swiper;
    },
    mySwiperBanner () {
      return this.$refs.mySwiperBanner && this.$refs.mySwiperBanner.swiper;
    },
    ...mapState({
      loginVisible: state => state.appsLogin.loginVisible,
    }),

  },
  mounted () {
    this.getList()
  },
  methods: {
    ...mapActions(['goAppsDetailsBeforeLogin']),
    ...mapMutations(['setAppsLoginVisible']),
    goDetail (item, flag) {
      if ((item.outside && item.href) || !flag) {
        window.open(item.href || item.url)
      } else {
        // this.$router.push({ path: `/${this.school_code}/sdcmzyxy/detail/${item.id}`, query: { flag, url: item.url } })
        window.open(`/${this.school_code}/sdcmzyxy/detail/${item.id}?flag=${flag}&url=${item.url}`)
      }
    },
    goCourse (item) {
      // 判断是否登录,进行跳转
      // this.goAppsDetailsBeforeLogin().then(() => {
      if (item.url == 'kcsz_sfkc') {
        return
      } else {
        window.open(item.url)
      }
      // })
    },
    goMstd (item) {
      // this.$router.push(`/${this.school_code}/sdcmzyxy/sztd/detail/${item.id}`)
      window.open(`/${this.school_code}/sdcmzyxy/sztd/detail/${item.id}`)

    },

    getList () {
      var time = Date.parse(new Date())
      services.getIndexZcWj({ page: 0, time }).then(res => {
        if (res && res.info) {
          this.zcwjList = res.info
        }
      })
      services.getIndexJsDt({ page: 0, time }).then(res => {
        if (res && res.info) {
          this.jsdtList = res.info
        }
      })

      services.getDxjyList({ page: 0, time }).then(res => {
        if (res && res.info) {
          this.dxjyList = res.info
        }
      })

      services.getIndexBanner({ page: 0, time }).then(res => {
        if (res && res.info) {
          this.bannerData = res.info
        }
      })
    },

  }
}
</script>

<style scoped lang="less">
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.common-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-title {
    font-size: 24px;
    font-weight: bold;
    color: #960202;
    line-height: 24px;
  }
  .right-more {
    font-size: 14px;
    color: #827d7d;
    cursor: pointer;
    &:hover {
      color: #ce0303;
    }
  }
}
.common-title.sfkc {
  margin-bottom: 29px;
}
.common-title.zcwj {
  margin: 7px 0 46px;
}
.common-title.pxyt {
  margin: 28px 0 32px;
}
.common-title.jsdt {
  margin: 0 0 32px 0;
}
.common-title.mstd {
  margin: 12px 0 55px;
}
.sdcm-container-box {
  // 新闻动态 - 隐藏
  // .sdcm-top-box {
  //   position: relative;
  //   min-height: 821px;
  //   .banner-box {
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 650px;
  //     background-image: url('../../assets/image/sdcmzyxy/index-banner1.jpg');
  //     background-size: cover;
  //     background-position: center;
  //     .eol-swiper-box {
  //       min-height: 821px;
  //     }
  //   }
  //   .swiper-banner {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     z-index: 1;
  //     .swiper-container {
  //       min-height: 825px;

  //       ::v-deep .swiper-pagination-bullets {
  //         display: inline-block;
  //         left: unset;
  //         width: unset;
  //         bottom: 495px;
  //         right: 342px;
  //         height: 20px;
  //         line-height: 20px;
  //       }
  //       ::v-deep .swiper-button-prev {
  //         width: 23px;
  //         height: 23px;
  //         background-size: cover;
  //         background-image: url('../../assets/image/sdcmzyxy/index-prev.png');
  //         top: unset;
  //         left: unset;
  //         bottom: 493px;
  //         right: 388px;
  //       }

  //       ::v-deep .swiper-button-next {
  //         width: 23px;
  //         height: 23px;
  //         background-size: cover;
  //         background-image: url('../../assets/image/sdcmzyxy/index-next.png');
  //         top: unset;
  //         left: unset;
  //         bottom: 493px;
  //         right: 305px;
  //       }
  //       ::v-deep .swiper-pagination .swiper-pagination-bullet {
  //         width: 9px;
  //         height: 9px;
  //         background: #ffffff;
  //         border: 1px solid #ffffff;
  //         border-radius: 50%;
  //       }
  //       ::v-deep .swiper-pagination .swiper-pagination-bullet-active {
  //         background: #931a14;
  //         border: 1px solid #931a14;
  //       }
  //     }
  //   }
  //   .swiper-top-container {
  //     width: 1300px;
  //     height: 402px;
  //     background: #ffffff;
  //     border-radius: 2px;
  //     padding: 46px 50px;
  //     position: absolute;
  //     top: 345px;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     display: flex;
  //     z-index: 10;
  //     .swiper-box {
  //       width: 692px;
  //       height: 391px;
  //       overflow: hidden;
  //       display: flex;
  //       .eol-swiper-box {
  //         width: 692px;
  //       }
  //       ::v-deep .swiper-pagination-fraction,
  //       .swiper-pagination-custom,
  //       .swiper-container-horizontal > .swiper-pagination-bullets {
  //         width: unset;
  //         left: unset;
  //         right: 30px;
  //         bottom: 20px;
  //       }
  //       ::v-deep .swiper-pagination .swiper-pagination-bullet {
  //         width: 8px;
  //         height: 8px;
  //         background: #ffd3a8;
  //         border-radius: 50%;
  //       }
  //       ::v-deep .swiper-pagination .swiper-pagination-bullet-active {
  //         background: #931a14;
  //         border: 1px solid #ffffff;
  //       }
  //       ::v-deep .eol-swiper-box .eol-banner-item {
  //         position: relative;
  //         height: 100%;
  //         &::before {
  //           content: '';
  //           display: inline-block;
  //           width: 100%;
  //           height: 100%;
  //           background-color: rgba(14, 14, 14, 8%);
  //         }
  //         .text {
  //           width: 100%;
  //           position: absolute;
  //           font-size: 16px;
  //           font-weight: bold;
  //           color: #ffffff;
  //           bottom: 0;
  //           left: 0;
  //           height: 60px;
  //           line-height: 60px;
  //           background: #000000;
  //           background-color: rgba(0, 0, 0, 0.5);
  //           padding: 0 12px;
  //           p {
  //             max-width: 550px;
  //             white-space: nowrap;
  //             text-overflow: ellipsis;
  //             overflow: hidden;
  //           }
  //         }
  //       }
  //       ::v-deep .eol-swiper-box {
  //         height: 100%;
  //       }
  //     }
  //     .sidebar {
  //       width: 96px;
  //       height: 391px;
  //       background: #931a14;
  //       padding: 38px 0 27px;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       .top-main {
  //         .icon {
  //           width: 30px;
  //           height: 23px;
  //           background-image: url('../../assets/image/sdcmzyxy/news-line.png');
  //           background-size: cover;
  //         }
  //         .text {
  //           width: 24px;
  //           font-size: 24px;
  //           font-weight: bold;
  //           color: #ffffff;
  //           line-height: 24px;
  //           margin: 10px 0 180px;
  //         }
  //       }
  //       .bottom-more {
  //         font-size: 12px;
  //         color: #ffffff;
  //       }
  //     }
  //     .right-article-box {
  //       flex: 1;
  //       padding: 25px 0 0 52px;
  //       .article-container {
  //         .item {
  //           padding: 27px 0 23px;
  //           border-bottom: 1px dashed #eceae7;
  //           &:first-child {
  //             padding-top: 0;
  //           }
  //           .top-main {
  //             display: flex;
  //             align-items: center;
  //             .publish-time {
  //               width: 58px;
  //               margin-right: 25px;
  //               .day {
  //                 font-size: 40px;
  //                 color: #931a14;
  //               }
  //               .month {
  //                 font-size: 14px;
  //                 color: #931a14;
  //               }
  //             }
  //             .publish-title {
  //               cursor: pointer;
  //               width: 272px;
  //               font-size: 20px;
  //               font-weight: bold;
  //               color: #262626;
  //               line-height: 28px;
  //               display: -webkit-box;
  //               text-overflow: ellipsis;
  //               overflow: hidden;
  //               -webkit-box-orient: vertical;
  //               -webkit-line-clamp: 2;
  //               width: 272px;
  //               height: 56px;
  //               &:hover {
  //                 color: #931a14;
  //               }
  //             }
  //           }
  //           .description {
  //             height: 44px;
  //             margin-top: 20px;
  //             font-size: 14px;
  //             color: #a2a2a2;
  //             line-height: 22px;
  //             display: -webkit-box;
  //             text-overflow: ellipsis;
  //             overflow: hidden;
  //             -webkit-box-orient: vertical;
  //             -webkit-line-clamp: 2;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .sdcm-banner-box {
    width: 100%;
    height: 460px;
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 18px;
    }
    ::v-deep .swiper-button-prev {
      width: 23px;
      height: 23px;
      background-size: cover;
      background-image: url('../../assets/image/sdcmzyxy/index-prev.png');
    }

    ::v-deep .swiper-button-next {
      width: 23px;
      height: 23px;
      background-size: cover;
      background-image: url('../../assets/image/sdcmzyxy/index-next.png');
    }
    ::v-deep .swiper-pagination .swiper-pagination-bullet {
      width: 9px;
      height: 9px;
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 50%;
    }
    ::v-deep .swiper-pagination .swiper-pagination-bullet-active {
      background: #931a14;
      border: 1px solid #931a14;
    }
    .eol-swiper-box {
      height: 460px;
    }
    .banner-item {
      width: 100%;
      height: 460px;
      background-size: cover;
      cursor: pointer;
      background-position: 50%;
    }
  }
  .sfkc-container {
    width: 100%;
    padding-top: 40px;
    .list-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .sub-visibility {
        flex: 0 0 285px;
        display: inline-block;
        overflow: visible;
      }

      .item {
        margin-bottom: 22px;
        overflow: hidden;
        &:first-child {
          cursor: pointer;
        }
        border-radius: 2px;
        transition: box-shadow 0.3s;
        &:hover {
          box-shadow: 0px 0px 8px 0px rgba(182, 182, 182, 0.44);
          .description {
            .title {
              .text {
                color: #931a14;
              }
            }
          }
        }
        .thumb-image {
          width: 285px;
          height: 160px;
          border-radius: 2px 2px 0px 0px;
          background-size: cover;
          background-position: center;

          &::before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: rgba(14, 14, 14, 8%);
          }
        }
        .description {
          padding: 12px 15px 14px 0;
          width: 285px;
          background: #fffeff;
          border: 1px solid #e8e8e8;
          border-radius: 0px 0px 4px 4px;
          height: 80px;
          .title {
            cursor: pointer;
            display: flex;
            .line {
              width: 4px;
              height: 15px;
              background: #931a14;
              border-radius: 0px 4px 4px 0px;
              margin-right: 11px;
              margin: 5px 11px 0 0;
            }
            .text {
              flex: 1;
              font-size: 16px;
              font-weight: bold;
              color: #262626;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .author {
            display: inline-block;
            padding: 0 35px 0 14px;
            height: 22px;
            line-height: 22px;
            background: linear-gradient(90deg, #fff5e7 0%, #fdf7f3 100%);
            border-radius: 0px 11px 11px 0px;
            font-size: 14px;
            color: #eb9d51;
            margin-top: 6px;
          }
        }
      }
    }
  }

  .zcwj-list {
    display: flex;
    .left-article {
      width: 476px;
      .item {
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #ebdbe3;
        padding: 21px 0 17px;
        height: 85px;
        &:first-child {
          padding-top: 0;
        }
        .month-day {
          width: 69px;
          height: 24px;
          background: #931a14;
          border-radius: 0px 12px 12px 0px;
          text-align: center;
          line-height: 24px;
          font-size: 13px;
          font-weight: bold;
          color: #ffffff;
          margin-right: 12px;
        }
        .title {
          transition: all 0s;
          cursor: pointer;
          flex: 1;
          line-height: 22px;
          font-size: 16px;
          color: #262626;
          display: flex;
          align-items: center;
          height: 44px;
          &:hover {
            color: #931a14;
            font-weight: bold;
          }
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .right-group {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-left: 32px;
      .item {
        border-radius: 2px;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          .description {
            .article-info {
              .title {
                color: #931a14;
              }
            }
          }
        }
        .thumb-image {
          width: 334px;
          height: 188px;
          background-size: cover;
          border-radius: 2px 2px 0 0;
          background-position: center;

          &::before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: rgba(14, 14, 14, 8%);
          }
        }
        .description {
          width: 334px;
          height: 148px;
          background: #ffffff;
          padding: 16px 12px 21px 15px;
          .article-info {
            height: 75px;
            .title {
              cursor: pointer;
              font-size: 16px;
              font-weight: bold;
              color: #262626;
              line-height: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
            .date {
              font-size: 12px;
              font-weight: bold;
              color: #960202;
              margin: 7px 0 8px;
            }
          }

          .intro {
            font-size: 14px;
            color: #a2a2a2;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
  .pxyt-container {
    padding-bottom: 33px;
    .list-box {
      display: flex;
      justify-content: space-between;
      .item {
        cursor: pointer;
        border-radius: 2px;
        &:hover {
          .description {
            .title {
              color: #931a14;
            }
          }
        }
        .thumb-image {
          border-radius: 2px 2px 0 0;
          width: 285px;
          height: 160px;
          background-size: cover;
          background-position: center;

          &::before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: rgba(14, 14, 14, 8%);
          }
        }
        .description {
          width: 285px;
          height: 134px;
          padding: 18px 7px 0 14px;
          background: #ffffff;
          border: 1px solid #e8e8e8;
          .title {
            height: 38px;
            font-size: 16px;
            font-weight: bold;
            color: #262626;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-height: 20px;
          }
          .date {
            font-size: 12px;
            color: #c3c3c3;
            padding: 14px 0 8px;
          }
          .intro {
            font-size: 14px;
            color: #acacac;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
      }
      .sub-visibility {
        flex: 0 0 285px;
        display: inline-block;
        overflow: visible;
      }
    }
  }
  .jsdt-container {
    .list-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        display: flex;
        // align-items: center;
        margin-bottom: 18px;
        position: relative;
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 400px;
          height: 1px;
          background: #f0ece7;
        }
        .thumb-image {
          width: 160px;
          height: 90px;
          border-radius: 2px;
          background-size: cover;
          margin-right: 18px;
          background-position: center;
        }
        .right-content {
          flex: 1;
          .title {
            color: #262626;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            width: 390px;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            margin: 12px 0 8px;
            cursor: pointer;
            &:hover {
              color: #931a14;
            }
          }
          .description {
            display: flex;
            align-items: center;
            .time {
              width: 82px;
              height: 18px;
              background: #f4c4a0;
              border-radius: 9px 9px 9px 0px;
              font-size: 12px;
              color: #fdf7ef;
              text-align: center;
              line-height: 18px;
              margin-right: 5px;
            }
            .intro {
              width: 299px;
              font-size: 14px;
              color: #acacac;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  .bottom-content {
    width: 100%;
    background-image: url('../../assets/image/sdcmzyxy/footer-bg.png');
    background-size: cover;
    .mstd-container {
      .body-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          border-radius: 0px 40px 0px 0px;
          margin-bottom: 66px;
          width: 133px;
          height: 200px;
          background: #ebedef;
          cursor: pointer;
          position: relative;
          .thumb-image {
            width: 100%;
            height: 183px;
            background-size: cover;
            position: absolute;
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
          }
          .name {
            width: 100%;
            height: 42px;
            background-color: #86261d;
            // background-image: url('../../assets/image/sdcmzyxy/index-teach.png');
            background-size: cover;
            text-align: center;
            line-height: 42px;
            font-size: 18px;
            color: #ffffff;
            margin: 0 auto;
            position: absolute;
            bottom: -0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            font-weight: bold;
            // &::before {
            //   content: '';
            //   display: inline-block;
            //   width: 100%;
            //   height: 5px;
            //   background: #fcead2;
            //   border-radius: 4px 4px 0px 0px;
            //   position: absolute;
            //   bottom: -5px;
            //   left: 50%;
            //   transform: translateX(-50%);
            //   z-index: 1;
            // }
          }
        }
        .sub-visibility {
          flex: 0 0 270px;
          display: inline-block;
          overflow: visible;
        }
      }
    }
  }
}
.body-bg-box {
  background-image: url('../../assets/image/sdcmzyxy/sfkc-bg.png');
  background-size: cover;
  padding: 35px 0 33px;
}
</style>