<template>
  <div ref="container" class="containers">
    <WhrjHeader />
    <div class="sdcm-container-box">
      <div class="sdcm-content-box w1280">
        <div class="side-bar">
          <div class="header-box"></div>
          <div class="body-box">
            <div :class="['item',course_id == item.id ?'active':'']" v-for="(item, index) of cateZyjnyc" :key="index" @click="currentSideBar(index,item)">
              <div class="star"></div>
              <div class="text">{{item.name == '全部' ? '全部课程' : item.name}}</div>
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="has-course" v-if="materialData && materialData.items  && materialData.items.length>0">
            <div class="tip-text">
              “增强法律意识，尊重知识产权，未经作者允许不得转载、摘录、引用等。”
            </div>
            <div class="body-box">
              <div class="class-lists">
                <div class="class-box" v-for="(item, index) in $store.state.class.materialData.items" :key="index">
                  <div class="link-box" @click="goToMaterialDetail(item)">
                    <div class="img">
                      <img :src="item.photo" alt />
                    </div>
                    <div class="txt">
                      <div class="title">{{ item.name }}</div>
                      <div class="num">
                        <span v-if="item.category_name">{{ item.category_name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sub-visibility"></div>
                <div class="sub-visibility"></div>
                <div class="sub-visibility"></div>
              </div>
            </div>
            <div style="text-align: center; padding-top:20px;width: 100%;">
              <a-pagination :defaultPageSize="pageSize" :default-current="1" :current="currentMaterialPage" :show-total="total => `共${materialData.total}条`" :total="materialData.total" @change="onMaterialChange" />
            </div>
          </div>
          <div v-else class="noData-box">
            <img src="../../assets/image/jsckzyxy/empty.png" alt="">
            <div class="text">暂无相关内容</div>
          </div>

        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <WhrjFooter :color="'#3E140B'" />

  </div>
</template>

<script>
import WhrjHeader from "../../components/WhrjHeader"
import WhrjFooter from "../../components/WhrjFooter"
import { mapActions, mapState, mapMutations } from "vuex"
import services from "../../assets/js/services"

export default {
  data () {
    return {
      school_code: localStorage.getItem("schoolCode"),
      currentMaterialPage: 1, //页码
      pageSize: 16,
      category_id: '', // 课程分类id
      course_id: 0, // 当前选择的课程id
    }
  },
  components: {
    WhrjHeader,
    WhrjFooter,
  },
  async mounted () {
    // 获取100024学校课程一级分类
    await this.loadCategoryData({ root_id: 0, level: 1, school_code: this.school_code });
    // 100024学校课程一级分类 仅一个 要求写死
    this.category_id = this.classCategory[0].id
    // 获取100024学校课程分类下的课程名称以做左侧筛选栏目
    await this.getZyjnycCateData({ school_code: this.school_code, page: 1, size: 100, category_id: this.category_id })
    let param = {
      category_id: this.category_id,
      // type: 2,
      page: 1,
      size: this.pageSize,
      school_code: this.school_code
    }
    // 请求100024学校课程分类下的所有素材
    await this.getMaterialLists(param)
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin", 'getAppsLoginUserInfo', "loadCategoryData", 'getCourseData', 'getMaterialLists', 'getZyjnycCateData']),
    ...mapMutations(['setAppsLoginVisible']),
    // 左侧课程名称筛选点击事件
    currentSideBar (index, item) {
      this.course_id = item.id
      this.currentMaterialPage = 1
      let param = {
        category_id: this.category_id,
        // type: 2,
        page: this.currentMaterialPage,
        size: this.pageSize,
        school_code: this.school_code,
        course_id: this.course_id
      }
      // 根据课程id筛选素材
      this.getMaterialLists(param)
    },

    // 素材分页点击事件
    onMaterialChange (pageNumber) {
      let that = this
      that.currentMaterialPage = pageNumber;
      let param = {
        category_id: that.category_id,
        // type: 2,
        page: pageNumber,
        size: that.size,
        school_code: that.school_code
      }
      that.getMaterialLists(param)
    },
    // 素材点击 跳转课程详情页
    goToMaterialDetail (item) {
      // window.open(`${services.YXT_GW}/school/class/${item.course_id}?cmid=${item.id}`, "_blank");
      let that = this
      that.goAppsDetailsBeforeLogin().then(() => {
        window.open(`${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.course_id}&cm_id=${item.id}`)
        // window.open(`${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue`)
      })
    }
  },
  computed: {
    ...mapState({
      materialData: state => state.class.materialData,
      classData: state => state.class.classData,
      classCategory: (state) => state.class.classCategory,
      cateZyjnyc: (state) => state.class.cateZyjnyc, // 专业技能养成的分类（课程名称）
    }),
  },
}
</script>

<style lang="less" scoped>
* {
  transition: all 0.3s;
}
.w1280 {
  width: 1280px;
  margin: 0 auto;
}
.sdcm-container-box {
  background-image: url('../../assets/image/sdcmzyxy/zcwj-body.png');
  background-size: cover;
  padding: 22px 0 0;
  .sdcm-content-box {
    background-color: #fff;
    padding: 0 40px 0 43px;
    min-height: 1000px;

    .side-bar {
      float: left;
      width: 264px;
      .header-box {
        height: 123px;
        background-image: url('../../assets/image/sdcmzyxy/slidebar/mjjt.png');
        background-size: cover;
      }
      .body-box {
        padding-left: 38px;
        padding: 0 41px 0 38px;
        .item {
          width: 226px;
          background-color: #fff;
          border-bottom: 1px solid #f3f3f3;
          padding: 10px 10px 10px 14px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .star {
            width: 18px;
            height: 17px;
            background-image: url('../../assets/image/sdcmzyxy/slidebar/star.png');
            background-size: cover;
            margin-right: 8px;
          }
          .text {
            flex: 1;
            font-size: 15px;
            color: #262626;
          }
          &.active {
            background: #c00800;
            border: none;
            .star {
              background-image: url('../../assets/image/sdcmzyxy/slidebar/star-active.png');
            }
            .text {
              color: #fff;
            }
          }
        }
      }
    }
    .right-container {
      width: 933px;
      float: right;
      padding: 23px 40px 0 38px;
      flex: 1;
      position: relative;
      .has-course {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 24px;
        .tip-text {
          width: 100%;
          height: 34px;
          background: #fcf1e0;
          text-align: center;
          line-height: 34px;
          font-size: 12px;
          color: #bd8249;
        }
        .body-box {
          width: 100%;
          overflow: hidden;
          .item {
            padding: 28px 0;
            border-bottom: 1px solid #f4f4f4;
            display: flex;
            transition: color 0s;
            cursor: pointer;
            &:first-child {
              cursor: pointer;
            }
            &:hover {
              .title .text {
                color: #840000 !important;
              }
            }
            &:first-child {
              padding-top: 25px;
            }
            .thumb-image {
              width: 200px;
              height: 113px;
              border-radius: 2px;
              background-size: cover;
              background-position: center;
            }
            .right-content {
              flex: 1;
              padding: 3px 0 0 15px;
              .title {
                padding-bottom: 17px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .text {
                  font-size: 18px;
                  color: #262626;
                  margin-right: 15px;
                  max-width: 80%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
                .label {
                  padding: 0 10px;
                  height: 24px;
                  background: linear-gradient(
                    -55deg,
                    #f8e7c6 0%,
                    #f8e5be 53%,
                    #fff0cf 99%
                  );
                  border-radius: 2px;
                  vertical-align: middle;
                  font-size: 14px;
                  color: #840000;
                  line-height: 24px;
                  text-align: center;
                  font-weight: bold;
                }
              }
              .sub-title {
                font-size: 12px;
                color: #840000;
                padding-bottom: 5px;
                &::before {
                  content: '';
                  display: inline-block;
                  background-image: url('../../assets/image/sdcmzyxy/sfkc-sub-label.png');
                  width: 6px;
                  height: 13px;
                  background-size: cover;
                  vertical-align: middle;
                  margin-right: 8px;
                }
              }
              .desc {
                font-size: 13px;
                color: #acacac;
                line-height: 22px;
              }
            }
          }
        }
      }
      .no-content {
        width: 100%;
        height: 100%;
        position: relative;
        min-height: 500px;
        font-size: 14px;
        color: #a2a2a2;
        transition: all 0s;
        div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: inline-block;
        }
      }
      .login-mask {
        position: absolute;
        width: 683px;
        height: 319px;
        z-index: 100;
        background-color: #fff;
        background-image: url('../../assets/image/sdcmzyxy/needLogin.png');
        background-size: cover;
        top: 106px;
        right: 134px;
        .login-btn {
          width: 158px;
          height: 38px;
          text-align: center;
          line-height: 38px;
          background: #ae0000;
          border-radius: 19px;
          cursor: pointer;
          font-size: 16px;
          color: #ffffff;
          margin: 36px auto 0;
          font-weight: 600;
        }
      }
    }
  }
}
::v-deep .login-outer-box .login-inner-box {
  top: 51%;
  left: 50%;
}
.class-lists {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0 0;
  text-align: left;
}
.class-box {
  width: 200px;
  // height: 216px;
  background: #fff;
  border: 1px solid #ececec;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 30px;
  border-radius: 8px;
  &:hover {
    box-shadow: 1px 2px 7px #ececec;
    transition: 0.3s box-shadow;
    .txt .title {
      color: #364c7e;
    }
  }
  .link-box {
    display: inline-block;
  }
  .img {
    height: 125px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    img {
      width: 100%;
      height: auto;
      margin: 0 auto;
      transition: all 0.8s;
    }
  }
  &:hover .img img {
    transform: scale(1.1);
  }
  .txt {
    padding: 15px 12px;
    width: 200px;
    box-sizing: border-box;
    .title {
      font-size: 14px;
      line-height: 20px;
      height: 40px;
      color: #3a3a3a;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .num {
      font-size: 12px;
      color: #959595;
      margin-top: 2px;
      .iconduoren {
        font-size: 17px;
        margin-top: 7px;
        vertical-align: -1px;
        margin-right: 5px;
        color: #d5d5d5;
      }
      .courseFont {
        width: 12px;
        height: 13px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: -1px;
        background-image: url('../../assets/image/jsckzyxy/times-icon.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
  }
}
.class-lists .sub-visibility {
  flex: 0 0 200px;
  display: inline-block;
  overflow: visible;
}
.noData-box {
  width: 100%;
  text-align: center;
  height: 770px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 150px;
  img {
    width: 240px;
    height: auto;
    margin: 0 auto 30px;
  }
  .text {
    color: #76797e;
    font-size: 14px;
  }
}
</style>