<template>
  <div class="szktz-content">
    <Header />
    <div class="szktz-banner">
      <div class="banner-content">
        <div class="banner-center">
          <div class="szktz-more" @click="beikeMessageModal">
            <div class="more-text">其他课程资源</div>
          </div>
        </div>
        <div class="banner-tit"></div>
      </div>
    </div>
    <div class="category-bg">
      <div class="category-content">
        <div
          class="category-item"
          @click="getClickCategoryThree({ id: 242, name: '全部章节' })"
          :class="{ active: categoryIdThree == 242 }"
        >
          全部章节
        </div>

        <div
          class="category-item"
          v-for="(item, ind) in szktzCategoryThree"
          :key="ind"
          @click="getClickCategoryThree(item)"
          :class="{ active: categoryIdThree == item.id }"
        >
          {{ item.name | setCategoryName }}
        </div>
      </div>
    </div>
    <div class="szktz-info-bg">
      <div class="szktz-info">
        <div class="szktz-title">
          <div class="title-text">{{ selectTitle }}</div>
          <dropdown placement="bottomCenter" v-if="selectTitle != '全部章节'">
            <a-menu slot="overlay">
              <a-menu-item
                v-for="item in szktzCategoryFour"
                :key="item.id"
                @click="getClickCategoryFour(item)"
              >
                {{ item.name }}
              </a-menu-item>
            </a-menu>
            <a-button style="margin-left: 8px">
              {{ selectTitleFour || "全部" }}
              <a-icon type="caret-down" :style="{ color: '#959595' }" />
            </a-button>
          </dropdown>
        </div>
        <div class="szktz-list" v-if="szktzList.length > 0">
          <div
            class="szktz-item"
            v-for="item in szktzList"
            :key="item.id"
            @click.stop="getszktzInfo(item.id)"
          >
            <div class="item-img" :class="{ file_id: item.file_id }">
              <div
                :style="item.cover && `background-image: url('${item.cover}');`"
              ></div>
            </div>
            <div class="item-title">
              {{ item.name }}
            </div>
            <div class="item-category">
              {{ item.category_name }}
            </div>
            <div
              class="item-info"
              :class="{ file_id: item.file_id }"
              v-if="item.file_id != ''"
            >
              资源时长 {{ item.duration | convertSecondsToHMS }}
            </div>
            <div class="item-info file-size" v-else>
              资源大小 {{ item.size | formatSizeUnits }}
            </div>
            <div class="item-btn">
              <div class="preview-botton" @click.stop="getszktzInfo(item.id)">
                预览
              </div>
              <div class="download-botton" @click.stop="beikeMessageModal">
                下载
              </div>
            </div>
          </div>
        </div>
        <div class="Pagination-box">
          <APagination
            v-if="total > 8"
            v-model="page"
            :total="total"
            :page-size="pageSize"
            :show-total="(total) => `共 ${total} 条`"
            @change="onPaginationChange"
          ></APagination>
          <div v-else-if="szktzList.length <= 0" class="noData-box">
            <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
            <div class="text">暂无相关内容</div>
          </div>
        </div>
        <a-modal
          v-model="videoVisible"
          title="资源预览"
          :afterClose="videoCloseMadol"
          :footer="null"
          width="800px"
        >
          <div class="video-box" ref="videoBox"></div>
          <div style="margin-top: 10px" class="mz-info">
            <p>免责声明：</p>
            <p>
              本平台仅提供空间服务，平台所有信息资源仅供学习、教学使用。凡从事与本平台相关的所有行为(包括但不限于访问浏览、利用、转载、下载资料)时，必须保留本平台注明的“资源来源”并标注“仅供教学使用”字样。不得故意或者过失损害本平台的各类合法权利与利益，不得利用本平台以任何方式直接或者间接的从事违反中国法律、国际公约以及社会公德的行为，且应当恪守下述承诺：
            </p>
            <p>
              1、传输和利用资料信息符合中国法律、国际公约的规定、符合公序良俗;
            </p>
            <p>
              2、不将本平台以及与之相关的更新资料用作非法用途以及非正当用途;
            </p>
            <p>3、遵守与本平台相关的服务协议、规定、程序和惯例等。;</p>
            <p>
              平台所涉及的部分互联网信息，如无意侵犯了机构或个人的知识产权，请邮件告之，我方将立即删除。
            </p>
            <p>联系邮箱：【管老师guanfm@eol.cn】、【汪老师wangyan@eol.cn】</p>
          </div>
        </a-modal>
        <a-modal
          v-model="pdfVisible"
          title="资源预览"
          :afterClose="pdfCloseMadol"
          :footer="null"
          width="800px"
          :centered="true"
        >
          <iframe
            :src="`${pdfUrl}#navpanes=0&toolbar=0`"
            frameborder="0"
            width="100%"
            height="400px"
          ></iframe>
          <div style="margin: 10px" class="mz-info">
            <p>免责声明：</p>
            <p>
              本平台仅提供空间服务，平台所有信息资源仅供学习、教学使用。凡从事与本平台相关的所有行为(包括但不限于访问浏览、利用、转载、下载资料)时，必须保留本平台注明的“资源来源”并标注“仅供教学使用”字样。不得故意或者过失损害本平台的各类合法权利与利益，不得利用本平台以任何方式直接或者间接的从事违反中国法律、国际公约以及社会公德的行为，且应当恪守下述承诺：
            </p>
            <p>
              1、传输和利用资料信息符合中国法律、国际公约的规定、符合公序良俗;
            </p>
            <p>
              2、不将本平台以及与之相关的更新资料用作非法用途以及非正当用途;
            </p>
            <p>3、遵守与本平台相关的服务协议、规定、程序和惯例等。;</p>
            <p>
              平台所涉及的部分互联网信息，如无意侵犯了机构或个人的知识产权，请邮件告之，我方将立即删除。
            </p>
            <p>联系邮箱：【管老师guanfm@eol.cn】、【汪老师wangyan@eol.cn】</p>
          </div>
        </a-modal>
      </div>
    </div>
    <component :is="dialogConfig.name"></component>
    <loading :showLoading="isLoading"></loading>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import { dropdown, Button } from "ant-design-vue";
import services from "../../assets/js/services";
import { mapActions, mapMutations } from "vuex";
import appleRes from "../../components/appleRes.vue";
import loading from "../../components/loading.vue";
export default {
  name: "szktzzy",
  components: {
    Header,
    dropdown,
    AButton: Button,
    appleRes,
    loading,
    // APagination: Pagination,
  },
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      szktzCategoryThree: [],
      szktzCategoryFour: [],
      szktzList: [],
      categoryIdThree: 242,
      categoryIdFour: "",
      lastClickId: "",
      page: 1,
      total: 0,
      pageSize: 8,
      selectTitle: "全部章节",
      selectTitleFour: "",
      videoVisible: false,
      pdfVisible: false,
      player: null,
      pdfUrl: "",
      videoUrl: "",
      fileName: "",
      videoFormat: "",
      dialogConfig: {
        name: "",
      },
      isLoading: false,
    };
  },

  async created() {
    let res = await this.getAppsLoginUserInfo();
    if (res.code != "0000") {
      this.$router.push({ path: `/${this.school_code}/zy` });
      this.setAppsLoginVisible(true);
    }
    this.getszktzCategoryThree();
    this.getClickCategoryThree({ id: 242, name: "全部章节" });
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin", "getAppsLoginUserInfo"]),
    ...mapMutations(["setAppsLoginVisible"]),
    getClickCategoryThree(item) {
      this.categoryIdThree = item.id;
      this.categoryIdFour = "";
      this.selectTitleFour = "";
      this.lastClickId = item.id;
      this.selectTitle = item.name;
      this.page = 1;
      this.getszktzCategoryFour();
      this.getszktzList(this.lastClickId);
    },
    getClickCategoryFour(item) {
      this.categoryIdFour = item.id;
      this.lastClickId = item.id;
      this.selectTitleFour = item.name;
      this.page = 1;
      this.getszktzList(this.lastClickId);
    },
    async getszktzCategoryThree() {
      let res = await services.getszktzCategory({
        school_code: this.school_code,
        level: 3,
        category_id: 242,
      });
      if (res.code == "0000" && res.data && res.data.length) {
        this.szktzCategoryThree = res.data;
      }
    },
    async getszktzCategoryFour() {
      let res = await services.getszktzCategory({
        school_code: this.school_code,
        level: 4,
        category_id: this.categoryIdThree,
      });
      if (res.code == "0000" && res.data && res.data.length) {
        this.szktzCategoryFour = [
          { id: this.categoryIdThree, name: "全部" },
          ...res.data,
        ];
      }
    },
    async getszktzList(categoryId) {
      this.isLoading = true;
      let res = await services.getszktzList({
        school_code: this.school_code,
        category_id: categoryId,
        page: this.page,
        size: this.pageSize,
        is_rand: 0,
        tag_id: "",
      });
      if (res.code == "0000" && res.data.items && res.data.items.length) {
        this.szktzList = res.data.items;
        this.total = res.data.total;
      }
      this.isLoading = false;
    },
    onPaginationChange(current) {
      this.page = current;
      this.getszktzList(this.lastClickId);
    },
    getszktzInfo(id) {
      this.isLoading = true;

      this.goAppsDetailsBeforeLogin()
        .then(async () => {
          let res = await services.getszktzInfo(id);
          if (res.code == "0000") {
            this.alzyInfoId = res.data.id;
            if (res.data.type == 1) {
              this.videoVisible = true;
              this.$nextTick(() => {
                this.$refs.videoBox.innerHTML =
                  '<video id="player-container-id" width="752px" height="400" preload="auto"></video>';
                this.player = TCPlayer("player-container-id", {});
                this.player.src(res.data.file_url);
                this.videoUrl = res.data.file_url;
                this.fileName = res.data.name;
                this.videoFormat = res.data.file_url.split(".");
              });
            } else {
              this.pdfVisible = true;
              this.$nextTick(() => {
                this.pdfUrl = services.XUEXI_SERVER + res.data.view_path;
                this.fileName = res.data.name;
                this.pdfSize = res.data.size;
                this.videoFormat = res.data.view_path.split(".");
              });
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    videoCloseMadol() {
      // console.log(e);
      this.videoVisible = false;
      this.player.dispose();
      this.player = null;
    },
    pdfCloseMadol() {
      this.pdfVisible = false;
    },
    beikeMessageModal() {
      this.dialogConfig.name = "appleRes";
    },
  },
  filters: {
    setCategoryName(name) {
      if (name.length <= 2) {
        return name;
      } else {
        if (name.indexOf("章") > 0) {
          return name.substring(0, name.indexOf("章") + 1);
        } else {
          return name.substring(0, 4);
        }
      }
    },
    convertSecondsToHMS(time) {
      let h = parseInt((time / 60 / 60) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((time / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(time % 60);
      s = s < 10 ? "0" + s : s;
      // 作为返回值返回
      return `${h}:${m}:${s}`;
    },
    formatSizeUnits(bytes) {
      if (bytes >= 1073741824) {
        bytes = (bytes / 1073741824).toFixed(2) + " GB";
      } else if (bytes >= 1048576) {
        bytes = (bytes / 1048576).toFixed(2) + " MB";
      } else {
        bytes = (bytes / 1024).toFixed(2) + " KB";
      }
      return bytes;
    },
  },
};
</script>

<style lang="less" scoped>
.szktz-content {
  .szktz-banner {
    width: 100%;
    // min-width: 1920px;
    background-position: center;
    height: 380px;
    margin: 0 auto;
    background-image: url("../../assets/image/szktz/szktz-banner.png");
    background-size: cover;
    position: relative;
    .banner-content {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      padding-top: 34px;
      display: flex;
      position: relative;
      .banner-center {
        margin-left: 135px;
        width: 877px;
        height: 311px;
        background-image: url("../../assets/image/szktz/szktx-book.png");
        background-size: cover;
        position: relative;
        .szktz-more {
          width: 146px;
          height: 38px;
          border: 1px solid #ffffff;
          position: absolute;
          margin-top: 55px;
          cursor: pointer;
          bottom: 24px;
          left: 332px;
          .more-text {
            width: 146px;
            height: 38px;
            display: block;
            position: absolute;
            top: -4px;
            left: -4px;
            background: linear-gradient(-90deg, #fd6204 0%, #dc3034 100%);
            border: 1px solid #ffffff;
            font-weight: bold;
            font-size: 16px;
            color: #ffffff;
            line-height: 30px;
            text-shadow: 2px 0px 8px rgba(155, 55, 10, 0.64);
            text-align: center;
            line-height: 36px;
            &::after {
              width: 0;
              height: 0;
              content: "";
              display: inline-block;
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;
              border-left: 12px solid #fff;
              border-right: 0 solid transparent;
              margin-left: 14px;
            }
          }
        }
      }
      .banner-tit {
        width: 42px;
        height: 197px;
        position: absolute;
        top: 0;
        right: 0;
        background-image: url("../../assets/image/szktz/szktz-titlt.png");
        background-size: cover;
      }
    }
  }
  .category-bg {
    background: #f5f7fa;
    width: 100%;
    .category-content {
      padding: 16px 0 22px;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      .category-item {
        width: 106px;
        height: 34px;
        background: #ffffff;
        border-radius: 17px;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        text-align: center;
        line-height: 33px;
        cursor: pointer;
        margin-top: 8px;
        margin-right: 15px;
        padding: 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:nth-child(10n) {
          margin-right: 0;
        }
        &:hover {
          background: #c90000;
          color: #fff;
        }
        &.active {
          background: #c90000;
          color: #fff;
        }
      }
    }
  }
  .szktz-info-bg {
    width: 100%;
    .szktz-info {
      width: 1200px;
      padding-top: 30px;
      padding-bottom: 50px;
      margin: 0 auto;
      /deep/.szktz-title {
        display: flex;
        justify-content: space-between;
        .title-text {
          font-weight: bold;
          font-size: 24px;
          color: #8e0f1c;
          &::before {
            content: "";
            display: inline-block;
            width: 23px;
            height: 27px;
            background-image: url("../../assets/image/szktz/title-icon.png");
            background-size: cover;
            vertical-align: -5px;
            margin-right: 11px;
          }
        }
        .ant-dropdown-trigger {
          min-width: 280px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #b4b3b2;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px 0 14px;
          font-size: 14px;
          color: #969696;
          border-radius: 0;
        }
      }
    }
    .szktz-list {
      flex-wrap: wrap;
      display: flex;
      height: 752px;
      .szktz-item {
        width: 280px;
        height: 346px;
        background: #ffffff;
        border: 1px solid #dddfe1;
        position: relative;
        cursor: pointer;
        margin-right: 26.6px;
        margin-top: 30px;
        overflow: hidden;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:hover {
          box-shadow: 0px 6px 6px 0px rgba(80, 77, 77, 0.12);
          .item-img.file_id {
            div {
              transform: scale(1.1);
            }
          }
          .item-btn {
            transform: translateY(-44px);
          }
          .item-title {
            color: #dc3034;
          }
        }
        .item-img {
          width: 100%;
          height: 158px;
          overflow: hidden;
          position: relative;
          &.file_id {
            &::before {
              content: "";
              width: 69px;
              height: 69px;
              background-image: url("../../assets/image/szktz/video-icon.png");
              background-size: cover;
              position: absolute;
              top: calc(50% - 34.5px);
              left: calc(50% - 34.5px);
              z-index: 1;
            }
            div {
              width: 100%;
              height: 100%;
              background-size: cover;
              background-position: center;
              transition: all 0.3s;
            }
          }

          div {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-image: url("../../assets/image/szktz/default-image.png");
          }
        }
        .item-title {
          width: 100%;
          height: 93px;
          font-weight: bold;
          font-size: 17px;
          color: #282828;
          line-height: 24px;
          padding: 20px 18px 0px 13px;
          overflow: hidden;
          display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
          -webkit-line-clamp: 3; /* 行数，值可以改，表示展示X行后多余的缩略展示 */
          -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
        }
        .item-category {
          width: 100%;
          font-weight: 400;
          font-size: 14px;
          color: #979695;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 18px 0 13px;
          margin-top: 16px;
        }
        .item-info {
          width: 100%;
          height: 44px;
          border-top: #dddfe1 1px solid;
          font-weight: bold;
          font-size: 12px;
          color: #ac815c;
          line-height: 44px;
          padding-left: 16px;
          // margin-top: 12px;
          position: absolute;
          bottom: 0;
          z-index: 0;
          &.file_id {
            &::before {
              content: "";
              width: 13px;
              height: 13px;
              margin-right: 10px;
              background-image: url("../../assets/image/szktz/video-time.png");
              background-size: cover;
              display: inline-block;
              vertical-align: -2px;
            }
          }
          &.file-size {
            &::before {
              content: "";
              width: 11px;
              height: 10px;
              margin-right: 10px;
              background-image: url("../../assets/image/szktz/file-size.png");
              background-size: cover;
              display: inline-block;
              vertical-align: -1px;
            }
          }
        }
        .item-btn {
          z-index: 1;
          width: 100%;
          height: 44px;
          border-top: #dddfe1 1px solid;
          display: flex;
          position: absolute;
          bottom: -44px;
          background: #fff;
          align-items: center;
          transition: 0.3s all;
          .preview-botton {
            flex: 1;
            font-weight: 400;
            font-size: 14px;
            color: #dc3034;
            text-align: center;
            border-right: 1px solid #d3d5d8;
          }
          .download-botton {
            flex: 1;
            font-weight: 400;
            font-size: 14px;
            color: #dc3034;
            text-align: center;
          }
        }
      }
    }
    .Pagination-box {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
}
.modal-box {
  top: 50% !important;
  transform: translateY(-50%);

}
  .mz-info {
    height: 65px;
    overflow-y: auto;
  }
</style>