import { message } from "ant-design-vue";
import axios from "axios";
// import store from '../../store'
// import VueCookies from 'vue-cookies';
axios.defaults.withCredentials = true;  //允许跨域携带cookies
var HOST = process.env.VUE_APP_HOST;
var LOGIN_SERVER = process.env.VUE_APP_LOGIN_SERVER;
var rmtUrl = process.env.VUE_APP_RMTURL;
var YXT_GW = process.env.VUE_APP_YXT_GW;
var BEIKE_SERVER = process.env.VUE_APP_BEIKE_SERVER;
var XUEXI_SERVER = process.env.VUE_APP_XUEXI_SERVER;
var XUEXI_JIAOYU = process.env.VUE_APP_XUEXI_JIAOYU;
var APPS_SERVIER = process.env.VUE_APP_APPS_SERVIER; //信息服务中心
var SZ_SERVER = process.env.VUE_APP_SZ_SERVER;
var APPS_WEB = process.env.VUE_APP_APPS_WEB;
var ZHIJIAO_SERVER = process.env.VUE_APP_ZHIJIAO_SERVER;
var KCSZ_SERVER = process.env.VUE_APP_KCSZ_SERVER
var ZHSZ_SERVER = process.env.VUE_APP_ZHSZ_SERVER;
// if (process.env.NODE_ENV == 'development') {
//   HOST = 'http://bk.66.dev.eol.com.cn';
//   // HOST = 'http://bk.dev.gohper.com'
//   BEIKE_SERVER = 'http://bk.66.dev.eol.com.cn';
//   LOGIN_SERVER = "http://passport.53.dev.eol.com.cn";
//   // rmtUrl = "https://rmtx.53.dev.eol.com.cn";
//   rmtUrl = "http://rmt.66.dev.eol.com.cn";
//   YXT_GW = 'http://www.66.dev.eol.com.cn';
//   XUEXI_SERVER = 'http://xuexi-api.66.dev.eol.com.cn';
//   XUEXI_JIAOYU = 'http://xuexi.66.dev.eol.com.cn';
//   APPS_SERVIER = 'http://apps-api.66.dev.eol.com.cn';
//   SZ_SERVER = 'http://sz.66.dev.eol.com.cn';
//   KCSZ_SERVER = '';
//   APPS_WEB = 'http://apps.66.dev.eol.com.cn'
//   ZHSZ_SERVER = 'http://zhsz.66.dev.eol.com.cn';
// } else {
//   // 第二层判断打包后是测试环境还是正式环境 
//   if (process.env.VUE_APP_FLAG === 'pro') {
//     // 正式环境 
//     HOST = 'https://bk.jiaoyu.cn';
//     BEIKE_SERVER = 'https://bk.jiaoyu.cn';
//     LOGIN_SERVER = "https://jw.jiaoyu.cn"; // eslint-disable-line no-unused-vars
//     rmtUrl = "https://rmt.jiaoyu.cn";
//     YXT_GW = 'https://www.jiaoyu.cn';
//     XUEXI_SERVER = 'https://xuexi-api.jiaoyu.cn';
//     XUEXI_JIAOYU = 'https://xuexi.jiaoyu.cn';
//     APPS_SERVIER = 'https://apps-api.jiaoyu.cn'; //信息服务中心
//     SZ_SERVER = 'https://sz.jiaoyu.cn';
//     KCSZ_SERVER = 'https://kcsz.jiaoyu.cn';
//     APPS_WEB = 'https://apps.jiaoyu.cn'
//   } else {
//     // 测试环境 
//     HOST = 'http://bk.66.dev.eol.com.cn';
//     BEIKE_SERVER = 'http://bk.66.dev.eol.com.cn';
//     LOGIN_SERVER = "http://passport.53.dev.eol.com.cn"; // eslint-disable-line no-unused-vars
//     // rmtUrl = "https://rmtx.53.dev.eol.com.cn";
//     rmtUrl = "http://rmt.66.dev.eol.com.cn";
//     YXT_GW = 'http://www.66.dev.eol.com.cn';
//     XUEXI_SERVER = 'http://xuexi-api.66.dev.eol.com.cn';
//     XUEXI_JIAOYU = 'http://xuexi.66.dev.eol.com.cn';
//     APPS_SERVIER = 'http://apps-api.66.dev.eol.com.cn';
//     SZ_SERVER = 'http://sz.66.dev.eol.com.cn';
//     KCSZ_SERVER = 'http://kcsz.66.dev.eol.com.cn';
//     APPS_WEB = 'http://apps.66.dev.eol.com.cn'
//     ZHSZ_SERVER = 'http://zhsz.66.dev.eol.com.cn';
//   }
// }

// console.log("host", HOST);
// console.log('当前环境', process.env);
// console.log("process.env.VUE_APP_FLAG", process.env.VUE_APP_FLAG);

//请求发送拦截
axios.interceptors.request.use((request) => {
  // if (process.env.NODE_ENV == 'production') {}
  return request;
}, function (error) {
  return Promise.reject(error);
});

function handleFunc (res, url) {
  if (url.indexOf('/api/user/info') < 0) {
    let codeStr = res.code + ''
    if (url.indexOf('zhijiao.eol.cn') > 0) return
    if (url.indexOf('/material/download') > 0) return
    if (codeStr != '2003' && codeStr != '0000' && codeStr != '2004' && codeStr != '2001' && codeStr.indexOf('22') == -1) {
      message.error(res.message)
    }
    if (codeStr == '2001') {
      if (url.indexOf('/api/school/favorite') == -1) {
        let userInfo = localStorage.getItem("userInfo")
        if (userInfo) {
          let path = window.location.href;
          if (path.indexOf('/zy/noauth') == -1) {
            let code = localStorage.getItem("schoolCode")
            if (code) {
              window.open(`${window.location.origin}/${code}/zy/noauth`, "_self")
            }
          }
        }
      } else {
        message.error(res.message)
      }
    }
  }
}

const request = (method, url, params) => {
  if (method == 'post') {
    return axios.post(url, params).then((res) => res.data).then((res) => {
      handleFunc(res, url);
      return res
    })
  } else if (method == 'get') {
    return axios.get(url, { params }).then((res) => res.data).then((res) => {
      handleFunc(res, url);
      return res
    })
  }
}

export default {
  HOST: HOST,
  LOGIN_SERVER: LOGIN_SERVER,
  MEDIA_SERVER: "https://rmt.eol.cn", // 融媒体请求地址
  rmtUrl: rmtUrl,//党建思政请求地址
  YXT_GW: YXT_GW,
  BEIKE_SERVER: BEIKE_SERVER,
  XUEXI_SERVER: XUEXI_SERVER,
  APPS_SERVIER: APPS_SERVIER,
  SZ_SERVER: SZ_SERVER,
  XUEXI_JIAOYU: XUEXI_JIAOYU,
  KCSZ_SERVER: KCSZ_SERVER,
  APPS_WEB: APPS_WEB,
  ZHSZ_SERVER: ZHSZ_SERVER,
  getSubjectMenu (params) {
    return request('get', HOST + '/api/school/subject', params)
  },
  getSzzykArea (params) {
    return request('get', HOST + '/api/school/area', params)
  },
  getSzzykElement (params) {
    return request('get', HOST + '/api/school/element', params)
  },
  // 思政元素分类查询
  getCategorySearch (params) {
    return request('get', HOST + '/api/school/element/search', params)
  },
  getSzyskDetails (params) {
    return request('get', HOST + '/api/school/element/' + params.id, params.data)
  },
  getSzzykCondition (params) {
    return request('get', HOST + '/api/school/resource/condition', params)
  },

  getSzzykSuggest (params) {
    return request('get', HOST + '/api/school/suggest', params)
  },
  getSzzykListData (params) {
    return request('get', HOST + '/api/school/resource', params)
  },
  getSzzykDetails (params) {
    return request('get', HOST + '/api/school/resource/' + params.id, params.data)
  },
  selectBeikeOffice (params) {
    return request('post', HOST + '/api/lesson/office/select', params)
  },
  selectBeikeImage (params) {
    return request('post', HOST + '/api/lesson/image/select', params)
  },
  selectBeikeVideo (params) {
    return request('post', HOST + '/api/lesson/video/select', params)
  },
  saveResourceFavorite (params) {
    return request('post', HOST + '/api/school/favorite', params)
  },


  // 信息服务中心 - 登录之前调用判断是否需要验证图片验证码
  getLoginBeforeInfo (params) {
    return request('get', APPS_SERVIER + '/api/login/info', params)
  },
  // 信息服务中心 - 获取图片验证码
  getAppsImageCaptcha (params) {
    return request('get', APPS_SERVIER + '/api/captcha', params)
  },
  // 信息服务中心 - 获取短信验证码
  getAppsSmsCaptcha (params) {
    return request('get', APPS_SERVIER + '/api/sms/send', params)
  },
  // 信息服务中心 - 官网注册
  postAppsRegister (params) {
    return request('post', APPS_SERVIER + '/api/register', params)
  },
  // 信息服务中心 - 登录
  postAppsLogin (params) {
    return request('post', APPS_SERVIER + '/api/login', params)
  },
  // 信息服务中心 - 退出登录
  postAppsLogout (params) {
    return request('post', APPS_SERVIER + '/api/logout', params)
  },
  // 信息服务中心 - 重置密码
  postAppsResetPassword (params) {
    return request('post', APPS_SERVIER + '/api/reset', params)
  },
  // 信息服务中心 - 获取用户信息
  getAppsUserInfo (params) {
    return request('get', HOST + '/api/user/info', params)
  },
  // 思政专题库 - 列表
  getSzztkList (params) {
    return request('get', HOST + '/api/school/special', params)
  },
  // 思政专题库工匠精神 - 详情
  getztgjjsDetail (params, id) {
    return request('get', HOST + `/api/school/special/resource/${id}`, params)
  },
  // 工匠精神-匠心领行筛选列表 
  getjxlhTag (params) {
    return request('get', HOST + `/api/school/special/gjjs/condition`, params)
  },
  // 工匠精神-匠心领行列表
  getjxlhList (params) {
    return request('get', HOST + `/api/school/special/gjjs`, params)
  },
  // 工匠精神匠心领行详情 
  getjxlhDetail (params, id) {
    return request('get', HOST + `/api/school/special/gjjs/${id}`, params)
  },
  getgjjsSearchList (params) {
    return request('get', HOST + `/api/school/special/gjjs/search`, params)
  },

  // 思政专题库 - 详情
  getSzztkDetail (params) {
    return request('get', HOST + `/api/school/special/${params.id}`)
  },
  // 思政专题库 - 分类
  getSzztkCategory (params) {
    return request('get', HOST + '/api/school/category', params)
  },

  // 思政专题库 - 热词
  getSzztkKeyWords (params) {
    return request('get', HOST + '/api/school/category/keywords', params)
  },
  // 学习资源库 -课程分类
  getCourseCategory (params) {
    return request('get', XUEXI_SERVER + '/api/sz/school/course/category', params)
  },
  //学习资源库 - 课程列表
  getCourseLists (params) {
    return request('get', XUEXI_SERVER + '/api/sz/school/course', params)
  },
  //学习资源库列表接口 - 获取课程素材列表
  courseMaterialLists (params) {
    return request('get', XUEXI_SERVER + '/api/sz/school/course/list', params)
  },
  // 以下是新改版学习资源库接口
  // x学习资源思政专题研修标签

  getOpenClass (params) {
    return request('get', XUEXI_SERVER + '/api/sz/school/category/open', params)
  },
  getszztyxLabelLIst (params) {
    return request('get', XUEXI_SERVER + '/api/sz/school/course/tag/ztyx', params)
  },
  // 学习资源思政专题研修列表
  getszztyxCourse (params) {
    return request('get', XUEXI_SERVER + '/api/sz/school/course/ztyx', params)
  },
  // 获取思政课拓展分类 
  getszktzCategory (params) {
    return request('get', XUEXI_SERVER + '/api/sz/school/material/category/alzy', params)
  },
  // 获取思政课拓展列表
  getszktzList (params) {
    return request('get', XUEXI_SERVER + '/api/sz/school/material/alzy', params)
  },
  // 获取思政课拓展详情
  getszktzInfo (params) {
    return request('get', XUEXI_SERVER + '/api/sz/school/material/' + params)
  },
  //学习资源库案例资源分类
  getalzyCategory (params) {
    return request('get', HOST + '/api/school/cases/condition', params)
  },
  //学习资源库案例资源列表
  getalzyList (params) {
    return request('get', HOST + '/api/school/cases', params)
  },
  //获取案例资源详情
  getalzyInfo (params) {
    return request('get', HOST + '/api/school/cases/' + params)
  },

  //获取通用课程列表
  getkcszList (params) {
    return request('get', XUEXI_SERVER + '/api/sz/school/course', params)
  },

  // 区域专题资源列表
  getAreaSzztkLists (params) {
    return request('get', HOST + '/api/school/special/resource', params)
  },
  // 区域专题开通信息  20230420-该接口已整合为菜单展示接口，陆续废弃
  getAreaSzztkRegion (params) {
    return request('get', HOST + '/api/school/special/region', params)
  },
  // 获取学校的菜单展示权限 - 区域资源、示范课程
  getSchoolRouterMenu (params) {
    return request('get', HOST + '/api/school/menu', params)
  },

  // 山东传媒职业学院 - 首页 - 政策文件
  getIndexZcWj (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/sdcmzyxy/shouye/zcwj1/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 山东传媒职业学院 - 首页 - Banner
  getIndexBanner (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/sdcmzyxy/shouye/sybanner/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 山东传媒职业学院  - 建设动态
  getIndexJsDt (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/sdcmzyxy/jsdt/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 山东传媒职业学院 - 政策文件
  getZcwjList (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/sdcmzyxy/${params.name}/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 山东传媒职业学院 - 典型经验
  getDxjyList (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/sdcmzyxy/dxjy/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 山东传媒职业学院 - 校外课程
  getXwkcList (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/sdcmzyxy/sfkc/xwsfk/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 山东传媒职业学院 - 搜索
  searchData (params) {
    return axios.post(`${ZHIJIAO_SERVER}/search/index.php?s=/index/Search/esSearch`, params, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },

  // 武汉软件工程职业学院 - 首页 - 政策文件
  getWhrjIndexZcWj (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/whrjgczyxy/wrsy/sywrzcwj/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 武汉软件工程职业学院 - 首页 - Banner
  getWhrjIndexBanner (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/whrjgczyxy/wrsy/wrsybanner/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 武汉软件工程职业学院 - 典型案例
  getWhrjIndexDxal (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/whrjgczyxy/wrsy/wr_sy_dxjy/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 武汉软件工程职业学院  - 建设动态
  getWhrjIndexJsDt (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/whrjgczyxy/wrjsdt/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 武汉软件工程职业学院 - 政策文件
  getWhrjZcwjList (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/whrjgczyxy/${params.name}/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 武汉软件工程职业学院 - 典型案例、 示范课程
  getWhrjDxalList (params) {
    return axios.get(`${ZHIJIAO_SERVER}/zyjy/whrjgczyxy/${params.name}/NewsList_${params.page}.json?${params.time}`, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },
  // 武汉软件工程职业学院 - 搜索
  searchWhrjData (params) {
    return axios.post(`${ZHIJIAO_SERVER}/search/index.php?s=/index/Search/esSearch`, params, {
      withCredentials: false
    }).then((res) => res.data).then((res) => {
      return res
    })
  },

  // 示范课程 - 列表筛选项
  sfkcScreen (params) {
    return request('get', HOST + '/api/school/lesson/course/filter', params)
  },
  sfkcList (params) {
    return request('get', HOST + '/api/school/lesson/course', params)
  },
  sfkcDetail (params) {
    return request('get', HOST + '/api/school/lesson/course/' + params.id, params)
  },
  sfkcResDetail (params) {
    return request('get', HOST + `/api/school/lesson/course/${params.id}/resource`, params)
  },

  // 获取主题模块列表
  getThemeList (params) {
    return request('get', HOST + '/api/school/topic', params)
  },
  // 获取相关专业案例资源类型
  getResourceType (params) {
    return request('get', HOST + '/api/school/cases/cases_type', params)
  },
  // 标注知识点
  setResPoint (params) {
    return request('post', HOST + '/api/school/resource/sign', params)
  },
  // 区域资源库列表
  getAreaList (params) {
    return request('get', HOST + '/api/school/special/region/list', params)
  },

  schoolLoginRedirect (params) {
    return request('get', APPS_SERVIER + '/api/redirect', params)
  },


}