<template>
  <div class="search">
    <template v-if="school_code == utils.whrj_code">
      <WhrjHeader :keywords="keyWord" :currentType='{name: "资源", catid: "1"}' />
    </template>
    <template v-else>
      <Header />
      <Navbar />
    </template>
    <div class="top">
      <span class="center">您搜索的关键字 <span class="word">“{{ keyWord }}”</span>，共有
        <template>
          <!-- <span v-if="totalResult"> {{ allCount}} </span> -->
          <span v-if="totalResult"> {{ searchTotalNum}} </span>
        </template>
        条结果</span>
    </div>
    <div class="body">
      <div class="tabs">
        <span :class="tabIndex == 0 ? 'tabActived tabItem' : 'tabItem'" @click="changeTab(0)">{{school_code == utils.sdcm_code ? '热点资源' : school_code == utils.whrj_code ? '课程思政资源库': '课程思政元素库'}}</span>
        <!-- <span :class="tabIndex == 1 ? 'tabActived tabItem' : 'tabItem'" @click="changeTab(1)">思政元素</span> -->
        <span :class="tabIndex == 2 ? 'tabActived tabItem' : 'tabItem'" @click="changeTab(2)">{{school_code == utils.sdcm_code ? '专题资源' : school_code == utils.whrj_code ? '思政专题' : '思政专题库'}}</span>
        <template v-if="schoolMenu && schoolMenu.hasOwnProperty('special') && schoolMenu.special.id">
          <span :class="tabIndex == 3 ? 'tabActived tabItem' : 'tabItem'" @click="changeTab(3)">{{schoolMenu.special.name}}</span>
        </template>
        <span v-if="school_code == utils.sdcm_code || school_code == utils.whrj_code" :class="tabIndex == 4 ? 'tabActived tabItem' : 'tabItem'" @click="changeTab(4)">{{school_code == utils.whrj_code ? '名家讲座': '培训资源'}}</span>
      </div>
      <div class="line"></div>
      <!-- 思政资源库 -->
      <div v-show="tabIndex == 0">
        <div class="list1" v-if="searchSzzykData && searchSzzykData.length > 0">
          <div v-for="(item, index) in searchSzzykData" :key="index" @click="goDetails(item)" class="item1">
            <div class="item1Top">
              <img v-if="item.type == 1" src="../../assets/image/home/word.png" alt="" class="word" />
              <img v-else src="../../assets/image/home/MP4.png" alt="" class="word" />
              <!-- <div v-if='item.is_open == 2' class="title">
                <span v-html="item.title" class="title-span"></span> <span class="resopen">公开</span>
              </div> -->
              <div class="titleall" v-html="item.title"></div>
            </div>
            <div v-html="item.abstract" class="intro"></div>
            <div class="footer">
              <div class="keyWords">
                <span v-for="(sub, subinx) in item.element_list" :key="subinx">{{ sub.name }}</span>
              </div>
              <div class="options">
                <div :class="item.favorite == 1 ? 'active collect' : 'collect'" @click.stop="requestCollect(item)">
                  <div class="collectImg"></div>
                  <span>{{ item.favorite == 1 ? "取消收藏" : "收 藏" }}</span>
                </div>
                <!-- 
                <div class="download" @click="download()">
                        <img src="../../assets/image/home/download.png" alt="">
                        <span>下载</span>
                </div> -->
              </div>
            </div>
          </div>
          <div v-if="szzykPageTotal > 0" style="text-align: center; padding: 30px 0 30px; width: 100%">
            <a-pagination v-model="currentPage" :show-total="(total) => `共${szzykPageTotal}条`" :total="szzykPageTotal" @change="onPagesChange" />
          </div>
        </div>
        <template v-else>
          <!-- <a-empty :image="simpleImage"><span slot="description"> 暂无数据</span></a-empty> -->
          <div class="loading-data" v-if="!isInitData || loading">加载中……</div>
          <nodata v-else />
        </template>
      </div>
      <!-- 思政元素 -->
      <div class="list2" v-if="tabIndex == 1">
        <template v-if="searchElementData && searchElementData.length > 0">
          <div v-for="(item, index) in searchElementData" :key="index" class="item2">
            <!-- <div  v-searchlight="{searchValue:keyWord,message:item.name}" class="title">
                        {{item.name }}
                    </div> -->
            <div v-html="item.name" class="title"></div>
            <div class="item2Box">
              <!-- <div v-searchlight="{searchValue:keyWord,message:item.abstract}" class="intro">
                            {{item.abstract }}
                        </div> -->
              <div v-html="item.abstract" class="intro"></div>
              <div class="line1"></div>
              <div class="seeDetail" @click="seeDetail(item)">查看详情</div>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- <a-empty style="flex:1" :image="simpleImage"><span slot="description"> 暂无数据</span></a-empty> -->
          <div class="loading-data" v-if="loading ">加载中……</div>
          <nodata v-else />
        </template>
      </div>
      <!-- 思政专题库 -->
      <div v-show="tabIndex == 2">
        <div v-if="szztkData && szztkData.length > 0">
          <div class="list-main-box">
            <div class="item" v-for="item,index in szztkData" :key="index" @click="resDetails(item)">
              <div class="thumb_img">
                <div class="inner_img" :style='`background-image: url(${item.cover});background-size: 100% 100%;`'></div>
              </div>
              <div class="desc">
                <div v-html="item.name" class="title"></div>
              </div>
              <div class="tag">
                <div class="tag-item" v-for="tag,tag_index in item.keywords" :key="tag_index">
                  {{tag}}
                </div>
              </div>
              <!-- <div class="open-box" v-if="item.is_open == 1"> -->
            </div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
          </div>
          <div style="text-align: center; padding: 30px 0px; width: 100%;" v-if="szztkTotal > 0">
            <a-pagination v-model="szztkPage" :show-total="szztkTotal => `共${szztkTotal}条`" :total="szztkTotal" :pageSize="szztkSize" @change="currentSzztkPage" />
          </div>
        </div>
        <template v-else>
          <!-- <div class="loading-data" v-if="!isInitData || loading">加载中……</div> -->
          <nodata />
        </template>
      </div>

      <!-- 区域资源列表 -->
      <div v-show="tabIndex == 3">
        <div v-if="areaZtData && areaZtData.length> 0">
          <div class="area-box">

            <div v-for="item,index in areaZtData" :key="index" @click="areaZtDetails(item)" class="item">
              <div v-if="item.cover" class="thumb-image" :style="`background-image: url(${item.cover})`"></div>
              <div v-else class="thumb-image" :style="`background-image: url(${services.BEIKE_SERVER}/special/qlwh/img/list/default-cover-100017.png)`"></div>

              <div class="title" v-html="item.title"></div>
              <div class="other">
                <div class="quote"><span>{{item.new_quote_num}}</span>次引用</div>
                <div class="browse"><span>{{item.new_visit_num}}</span>人浏览</div>
              </div>
            </div>
            <div class="flex-visibility"></div>
            <div class="flex-visibility"></div>
            <div class="flex-visibility"></div>
          </div>
          <div style="text-align: center; padding: 30px 0px; width: 100%;" v-if="areaZtTotal > 0">
            <a-pagination :defaultPageSize="areaZtSize" :show-total="(total) => `共${areaZtTotal}条`" :default-current="1" :current="classPage" :total="areaZtTotal" @change="onAreaZtChange" />
          </div>
        </div>
        <template v-else>
          <nodata />
        </template>
      </div>

      <!-- 培训资源列表 -->
      <div v-show="tabIndex == 4">
        <div v-if="classData && classData.length> 0">
          <div class="class-lists">
            <div class="class-box" v-for="(item, index) in classData" :key="index">
              <div class="link-box" @click="goToClassDetail(item)">
                <div class="img">
                  <img :src="item.photo" alt />
                </div>
                <div class="txt">
                  <div class="title" v-html="item.name"></div>
                  <div class="num">
                    <span v-if="item.category_name">{{ item.category_name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
          </div>
          <div style="text-align: center; padding: 30px 0px; width: 100%;" v-if="classTotal > 0">
            <a-pagination :defaultPageSize="classSize" :show-total="(total) => `共${classTotal}条`" :default-current="1" :current="classPage" :total="classTotal" @change="onClassChange" />
          </div>
        </div>
        <template v-else>
          <nodata />
        </template>
      </div>

      <component :is="dialogConfig.name"></component>
    </div>
    <!-- <Loading :showLoading="isShowLoading" /> -->

  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Nodata from "../../components/Nodata.vue";
// import Loading from "../../components/loading.vue";
import { Empty } from "ant-design-vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Header from "../../components/Header.vue"
import WhrjHeader from "../../components/WhrjHeader.vue"
import services from "../../assets/js/services";
import utils from "../../assets/js/utils";
import appleRes from "../../components/appleRes.vue"
export default {
  name: "search",
  components: {
    Navbar,
    Nodata,
    Header,
    appleRes,
    WhrjHeader
    // Loading
  },
  data () {
    return {
      utils,
      services,
      school_code: localStorage.getItem("schoolCode"),
      // keyWord: this.$route.query.keyWord,//输入的检索词
      tabIndex: 0, //当前tab索引
      currentPage: 1,
      isInitData: false,
      current: false,
      szztkPage: 1,  // 思政专题库页数
      classPage: 1,  // 培训资源页数
      areaZtPage: 1, //区域专题资源页数
      // isShowLoading: false,
      dialogConfig: {
        name: ""
      },
    };
  },
  computed: {
    ...mapState({
      searchSzzykData: (state) => state.search.searchSzzykData,
      searchSzzykNums: (state) => state.search.searchSzzykNums,
      szzykPageTotal: (state) => state.search.szzykPageTotal,
      elementPageTotal: (state) => state.search.elementPageTotal,
      pageNum: (state) => state.search.pageNum,
      searchElementData: (state) => state.search.searchElementData,
      keyWord: (state) => state.search.keyWord,
      loading: (state) => state.search.loading,
      totalResult: (state) => state.search.totalResult,
      allCount: (state) => state.search.allCount,
      szztkTotal: (state) => state.search.szztkTotal,
      szztkData: (state) => state.search.szztkData,
      szztkSize: (state) => state.search.szztkSize,
      classTotal: (state) => state.search.classTotal,
      classData: (state) => state.search.classData,
      classSize: (state) => state.search.classSize,
      areaZtTotal: (state) => state.search.areaZtTotal, // 区域专题资源总条数
      areaZtSize: (state) => state.search.areaZtSize, // 区域专题资源分页数量
      areaZtData: (state) => state.search.areaZtData,
      schoolMenu: (state) => state.appsLogin.schoolMenu,
    }),
    //搜索信息总数,
    searchTotalNum: function () {
      // this.elementPageTotal
      let nums = parseInt(this.szzykPageTotal + this.szztkTotal + this.classTotal + this.areaZtTotal);
      return nums ? nums : 0;
    },
  },
  watch: {
    keyWord: "searchEvery",
  },
  // directives: {
  //     searchlight: {
  //         // 指令的定义
  //         bind: function (el, binding) {
  //             var { searchValue, message} = binding.value;
  //             if(message && message.length > 0){
  //                 // 将字符串转数组
  //                 var arr = message.split(searchValue)
  //                 // 将searchValue标红
  //                 var sp = `<span style="color: #DF4332;">${searchValue}</span>`;
  //                 //使用红色的字对数组进行连接，连接后放回到 el中
  //                 el.innerHTML = arr.join(sp);
  //             }
  //         },
  //         componentUpdated: function (el, binding) {
  //             var { searchValue, message} = binding.value;
  //             if(message && message.length > 0){
  //                 // 将字符串转数组
  //                 var arr = message.split(searchValue)
  //                 // 将searchValue标红
  //                 var sp = `<span style="color: #DF4332;">${searchValue}</span>`;
  //                 //使用红色的字对数组进行连接，连接后放回到 el中
  //                 el.innerHTML = arr.join(sp);
  //             }
  //         }
  //     }
  // },
  beforeCreate () {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  async mounted () {
    let that = this
    await that.getRegionData()
    if (that.$route.query.keyWord) {
      that.setSearchKeyWord({ data: that.$route.query.keyWord });
    }
    that.searchEvery();
  },
  methods: {
    ...mapMutations(["setSearchKeyWord", "setAppsLoginVisible",]),
    ...mapActions([
      "getSearchSzzykData",
      "getSearchSzyskElement",
      "getAppsLoginUserInfo",
      "goAppsDetailsBeforeLogin",
      "saveResourceFavorite",
      "getSzzykTotal",
      "getAppsImageCaptcha",
      "getSearchSzztkList",
      "getSearchSzztkTotal",
      'getSearchClassLists',
      'getSearchAreaLists',
      'getSchoolMenuStatus'
    ]),
    // 获取区域开通信息
    async getRegionData () {
      if (!this.schoolMenu) {
        await this.getSchoolMenuStatus(this.school_code)
      }
    },
    // 搜索初始化 请求集合
    searchEvery () {
      if (this.keyWord && this.keyWord.length > 0) {
        this.getSzzykSearch(1);
        // this.getSearchSzyskElement({
        //   q: this.keyWord,
        //   length: -1,
        //   is_highlight: 1,
        // })
        // 专题库
        this.getSearchSzztkList({
          page: this.szztkPage,
          length: this.szztkSize,
          q: this.keyWord,
          school_code: this.school_code,
        })
        this.getSearchSzztkTotal({
          count: 1,
          page: this.szztkPage,
          length: this.szztkSize,
          q: this.keyWord,
          school_code: this.school_code,
        })
        if (this.school_code == utils.sdcm_code || this.school_code == utils.whrj_code) {
          // 培训资源
          this.getSearchClassLists({
            page: this.classPage,
            size: this.classSize,
            q: this.keyWord,
            school_code: this.school_code,
          })
        }
        if (this.schoolMenu && this.schoolMenu.hasOwnProperty('special') && this.schoolMenu.special.id) {
          // 区域专题资源
          this.getSearchAreaLists({
            page: this.areaZtPage,
            legnth: this.areaZtSize,
            q: this.keyWord,
            school_code: this.school_code,
          })
          this.getSearchAreaLists({
            page: this.areaZtPage,
            legnth: this.areaZtSize,
            q: this.keyWord,
            count: 1,
            school_code: this.school_code,
          })
        }

      }
    },
    // 思政资源库列表请求
    getSzzykSearch (page) {
      let that = this;
      that.getSearchSzzykData({
        q: that.keyWord,
        school_code: that.school_code,
        length: that.pageNum,
        page: page,
        favorite_flag: 1, // 是否显示收藏
      }).then(() => {
        that.getSzzykTotal({
          q: that.keyWord,
          count: 1,
          school_code: that.school_code,
        })
        that.isInitData = true;
      })
    },
    // 思政资源库分页事件
    onPagesChange (pageNumber) {
      let that = this;
      that.current = true
      if (pageNumber > 1) {
        that.getAppsLoginUserInfo().then((res) => {
          if (res.code == "2001") {
            that.currentPage = 1;
            that.getAppsImageCaptcha()
            that.setAppsLoginVisible(true);
          } else if (res.code == "0000") {
            that.getSzzykSearch(pageNumber);
          }
        });
      } else {
        that.getSzzykSearch(pageNumber);
      }
      that.current = false
    },
    // 思政专题库切换分页
    currentSzztkPage (pageNumber) {
      this.szztkPage = pageNumber
      let that = this
      if (pageNumber > 1) {
        that.getAppsLoginUserInfo().then((res) => {
          if (res.code == "2001") {
            that.szztkPage = 1;
            that.getAppsImageCaptcha()
            that.setAppsLoginVisible(true);
          } else if (res.code == "0000") {
            this.getSearchSzztkList({
              page: this.szztkPage,
              length: this.szztkSize,
              q: this.keyWord,
              school_code: this.school_code,
            })
            this.getSearchSzztkTotal({
              count: 1,
              page: this.szztkPage,
              length: this.szztkSize,
              q: this.keyWord,
              school_code: this.school_code,
            })
          }
        });
      } else {
        this.getSearchSzztkList({
          page: this.szztkPage,
          length: this.szztkSize,
          q: this.keyWord,
          school_code: this.school_code,
        })
        this.getSearchSzztkTotal({
          count: 1,
          page: this.szztkPage,
          length: this.szztkSize,
          q: this.keyWord,
          school_code: this.school_code,
        })
      }
    },
    onClassChange (pageNumber) {
      let that = this;
      this.classPage = pageNumber
      if (pageNumber > 1) {
        that.getAppsLoginUserInfo().then((res) => {
          if (res.code == "2001") {
            that.currentPage = 1;
            that.getAppsImageCaptcha()
            that.setAppsLoginVisible(true);
          } else if (res.code == "0000") {
            this.getSearchClassLists({
              page: this.classPage,
              size: this.classSize,
              q: this.keyWord,
              school_code: this.school_code,
            })
          }
        });
      } else {
        this.getSearchClassLists({
          page: this.classPage,
          size: this.classSize,
          q: this.keyWord,
          school_code: this.school_code,
        })
      }
    },
    // 区域专题资源分页点击事件
    onAreaZtChange (pageNumber) {
      let that = this;
      this.areaZtPage = pageNumber
      if (pageNumber > 1) {
        that.getAppsLoginUserInfo().then((res) => {
          if (res.code == "2001") {
            that.currentPage = 1;
            that.getAppsImageCaptcha()
            that.setAppsLoginVisible(true);
          } else if (res.code == "0000") {
            this.getSearchAreaLists({
              page: this.areaZtPage,
              legnth: this.areaZtSize,
              q: this.keyWord,
              school_code: this.school_code,
            })
            this.getSearchAreaLists({
              page: this.areaZtPage,
              legnth: this.areaZtSize,
              q: this.keyWord,
              count: 1,
              school_code: this.school_code,
            })
          }
        });
      } else {
        this.getSearchAreaLists({
          page: this.areaZtPage,
          legnth: this.areaZtSize,
          q: this.keyWord,
          school_code: this.school_code,
        })
        this.getSearchAreaLists({
          page: this.areaZtPage,
          legnth: this.areaZtSize,
          q: this.keyWord,
          count: 1,
          school_code: this.school_code,
        })
      }
    },
    // 跳转到专题资源详情
    resDetails (item) {
      let that = this
      that.goAppsDetailsBeforeLogin().then(() => {
        // window.open(`${services.BEIKE_SERVER}/${that.school_code}/sz/special`)
        // if (item.is_open == 1) {
        if (item.address && item.address.indexOf('http://') > -1) {
          window.open(item.address)
        } else {
          window.open(`/${that.school_code}/zy/szztk/detail/${item.id}`)
        }
        // } else {
        //   that.dialogConfig.name = "appleRes"
        // }
      });
    },
    // 区域资源详情
    areaZtDetails (item) {
      let that = this
      // <!-- href="/special/show?column_id=634fb8bf1fcc6f05961b4192&amp;resource_id=6345328614f3d758b930c212" -->
      that.goAppsDetailsBeforeLogin().then(() => {
        window.open(`${services.BEIKE_SERVER}/special/show?column_id=${item.column_id}&resource_id=${item.id}`)
      });
    },
    // 课程点击 跳转课程详情页
    goToClassDetail (item) {
      let that = this
      that.goAppsDetailsBeforeLogin().then(() => {
        // window.open(`${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue`)
        window.open(`${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.course_id}&category_name=${item.category_name}`)

      })
    },
    // 思政资源库详情
    goDetails (item) {
      let that = this;
      this.goAppsDetailsBeforeLogin().then(() => {
        // window.open(`${services.BEIKE_SERVER}/${that.school_code}/lesson/distribution`)
        // if (item.is_open == 2) {
        let params = {
          id: item.id,
        };
        that.$router.push({
          path: `/${that.school_code}/zy/szzyk/details`,
          query: params,
        });
        // } else {
        //   // that.noAuthMessageModal()
        //   this.dialogConfig.name = "appleRes"
        // }
      });
    },
    // 无权限查看课程资源时弹层提示
    // noAuthMessageModal () {
    //   Modal.info({
    //     title: '温馨提示',
    //     okType: 'primary',
    //     okText: '申请开通更多资源',
    //     closable: true,
    //     content: (<div class="jioayu-chrome">
    //       请拨打服务咨询电话或申请开通更多资源，获得更多资源使用权限。<br />服务咨询： 宋老师  18701110987
    //     </div>),
    //     onOk () {
    //       window.open(`${services.YXT_GW}/need`)
    //     }
    //   })
    // },
    //切换tab
    changeTab (index) {
      this.tabIndex = index;
    },
    //点击收藏
    requestCollect (item) {
      let that = this;
      // that.goAppsDetailsBeforeLogin().then(() => {
      //     that.isShowLoading = true
      that.saveResourceFavorite({ id: item.id }).then((res) => {
        // that.isShowLoading = false
        if (res.code == "0000") {
          that.$message.success("操作成功");
          that.getSzzykSearch(that.currentPage);
        } else {
          if (res.code != '2001') {
            that.$message.error("操作失败")
          }
        }
      });
      // });
    },
    //点击下载
    download () {
      // alert("下载成功");
    },
    //查看详情
    seeDetail (item) {
      let that = this;
      that.goAppsDetailsBeforeLogin().then(() => {
        that.$router.push({
          path: `/${that.school_code}/zy/szysk/details`,
          query: {
            id: item.id,
            pid: item.pid,
            className: item.classname,
          },
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  font-family: Microsoft YaHei;
  .top {
    height: 60px;
    line-height: 60px;
    background: #f5f7fa;
    .center {
      display: block;
      width: 1200px;
      margin: 0 auto;
      font-size: 14px;
      color: #666666;
      padding-left: 40px;
      .word {
        color: #dd363a;
      }
    }
  }
  .body {
    width: 1200px;
    margin: 0 auto;
    min-height: 600px;
    .tabs {
      height: 60px;
      line-height: 60px;
      padding-left: 40px;
      .tabItem {
        font-size: 16px;
        font-weight: bold;
        color: #5a6474;
        margin-right: 60px;
        cursor: pointer;
      }
      .tabActived {
        color: #dd363a !important;
        position: relative;
      }
      .tabActived::after {
        content: '';
        position: absolute;
        width: 32px;
        height: 4px;
        background: linear-gradient(162deg, #dd363a 0%, #df4531 100%);
        left: calc(50% - 16px);
        bottom: -20px;
      }
    }
    .line {
      width: 1200px;
      margin: 0 auto;
      height: 1px;
      background: #f5f7fa;
      border-radius: 1px;
    }
    .list1 {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item1 {
        width: 48%;
        padding: 20px;
        border-bottom: 1px solid #f3f3f3;
        cursor: pointer;
        .item1Top {
          display: flex;
          img {
            margin-right: 8px;
          }
          .titleall {
            font-size: 15px;
            color: #454545;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .title {
            font-size: 15px;
            color: #454545;
            cursor: pointer;
            .title-span {
              font-size: 15px;
              color: #454545;
              max-width: 430px;
              display: inline-block;
              vertical-align: middle;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .resopen {
              font-size: 12px;
              color: #ffffff;
              background: #ee373d;
              padding: 2px 6px;
              border-radius: 6px;
            }
          }
        }
        .intro {
          font-size: 13px;
          color: #a3a5aa;
          margin-top: 10px;
          display: -webkit-box; /**对象作为伸缩盒子模型展示**/
          -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
          -webkit-line-clamp: 2; /**显示的行数**/
          overflow: hidden; /**隐藏超出的内容**/
        }
        .footer {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          .keyWords {
            flex: 1;
            height: 24px;
            overflow: hidden;
            span {
              padding: 0 12px;
              height: 24px;
              line-height: 24px;
              text-align: center;
              display: inline-block;
              background: #f9f9f9;
              border-radius: 12px;
              margin-right: 15px;
              font-size: 12px;
              color: #b39f8c;
            }
          }
          .options {
            overflow: hidden;
            .collect,
            .download {
              width: 82px;
              height: 24px;
              line-height: 24px;
              background: #ffffff;
              border: 1px solid #e4717d;
              border-radius: 12px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              float: left;
              cursor: pointer;
              img {
                margin-right: 3px;
              }
              span {
                color: #df4332;
                font-size: 12px;
              }
            }
            .collect {
              // margin-right: 15px;
              .collectImg {
                background: url('../../assets/image/home/star.png') no-repeat;
                background-size: 100% 100%;
                width: 18px;
                height: 18px;
                margin-right: 3px;
              }
            }
            .collect.active .collectImg {
              background: url('../../assets/image/home/star-active.png')
                no-repeat !important;
            }
          }
        }
        .nobottom {
          border-bottom: none;
        }
      }
      .item1:hover {
        background: linear-gradient(0deg, #ffffff 0%, #f7f7f7 100%);
        border-radius: 2px;
        .keyWords {
          span {
            background: #f9f5e9;
            color: #b39f8c;
          }
        }
        .footer {
          border-bottom: none;
        }
        .collect {
          background: linear-gradient(
            162deg,
            #d8292d 0%,
            #d43d29 100%
          ) !important;
          .collectImg {
            background: url('../../assets/image/home/star-active.png') no-repeat !important;
          }
          span {
            color: #ffffff !important;
          }
        }
      }
    }
    .list2 {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item2 {
        width: 48%;
        padding: 20px;
        cursor: pointer;
        .title {
          font-size: 16px;
          color: #191919;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .item2Box {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #f3f3f3;
          padding-bottom: 25px;
          .intro {
            font-size: 14px;
            color: #a3a5aa;
            flex: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .line1 {
            width: 1px;
            height: 36px;
            background: #f4f5f7;
            border-radius: 1px;
            margin-left: 20px;
            margin-right: 20px;
          }
          .seeDetail {
            width: 82px;
            height: 24px;
            line-height: 22px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #e4717d;
            border-radius: 12px;
            color: #df4332;
            font-size: 12px;
            cursor: pointer;
          }
        }
        .nobottom {
          border-bottom: none;
        }
      }
      .item2:hover {
        background: linear-gradient(0deg, #ffffff 0%, #f7f7f7 100%);
        border-radius: 2px;
        .item2Box {
          border-bottom: none;
        }
        .seeDetail {
          background: #dd363a;
          color: #ffffff;
        }
      }
    }
  }
}
.loading-data {
  font-size: 13px;
  color: #a3a5aa;
  text-align: center;
  padding-top: 100px;
  margin: 0 auto;
}
// 思政专题库列表样式
.list-main-box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 15px 0;
  min-height: 450px;
  align-items: flex-start;
}
.list-main-box .sub-visibility {
  flex: 0 0 264px;
  display: inline-block;
  overflow: visible;
}
.list-main-box .item {
  width: 280px;
  margin-bottom: 25px;
  transition: all 0.5s;
  z-index: 1;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(255, 255, 255, 0);
  cursor: pointer;
}
.item .open-box {
  position: absolute;
  right: 20px;
  top: 0;
  background: url('../../assets/image/new-szztk/index/status-open.png');
  width: 62px;
  height: 32px;
  background-size: cover;
}

.list-main-box .item:hover {
  background-color: #fff;
  width: 280px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(146, 151, 157, 0.3);
  z-index: 10;
}
.list-main-box .item:hover .tag {
  background-color: #fff;
}

.list-main-box .item .thumb_img {
  width: 264px;
  height: 148px;
  border-radius: 2px;
  margin: 0 auto 12px;
  overflow: hidden;
}
.list-main-box .item .inner_img {
  transition: all 0.8s;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
}
.list-main-box .item:hover .thumb_img .inner_img {
  transform: scale(1.1);
}
.list-main-box .item .desc {
  padding: 0 8px;
  text-align: left;
}
.list-main-box .item .desc .title {
  font-size: 16px;
  font-weight: bold;
  color: #2f2e30;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 10px;
}
.list-main-box .item .desc .title::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 14px;
  background: #dc3034;
  margin-right: 8px;
}
.list-main-box .item .tag {
  font-size: 12px;
  color: #a59287;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  padding: 0 8px;
  border-radius: 4px;
}
.list-main-box .item:hover .tag {
  white-space: pre-wrap;
  overflow: auto;
  position: absolute;
  left: 0;
  top: 191px;
  z-index: 100;
  box-shadow: 0px 6px 10px 0px rgba(146, 151, 157, 0.3);
  border-radius: 4px;
  min-height: 40px;
  padding: 0 8px 10px;
}
.list-main-box .item .tag-item {
  display: inline-block;
  padding: 0 12px;
  background: #f8f9fb;
  border-radius: 12px;
  margin-right: 4px;
  height: 24px;
  line-height: 24px;
  margin-bottom: 6px;
}

.class-lists {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 0 0;
  text-align: left;
}
.class-box {
  width: 220px;
  // height: 216px;
  background: #fff;
  border: 1px solid #ececec;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 30px;
  border-radius: 8px;
  &:hover {
    box-shadow: 1px 2px 7px #ececec;
    transition: 0.3s box-shadow;
    .txt .title {
      color: #364c7e;
    }
  }
  .link-box {
    display: inline-block;
  }
  .img {
    height: 125px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    img {
      width: 100%;
      height: auto;
      margin: 0 auto;
      transition: all 0.8s;
    }
  }
  &:hover .img img {
    transform: scale(1.1);
  }
  .txt {
    padding: 15px 12px;
    width: 220px;
    box-sizing: border-box;
    .title {
      font-size: 14px;
      line-height: 20px;
      height: 40px;
      color: #3a3a3a;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .num {
      font-size: 12px;
      color: #959595;
      margin-top: 2px;
      .iconduoren {
        font-size: 17px;
        margin-top: 7px;
        vertical-align: -1px;
        margin-right: 5px;
        color: #d5d5d5;
      }
      .courseFont {
        width: 12px;
        height: 13px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: -1px;
        background-image: url('../../assets/image/jsckzyxy/times-icon.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
  }
}
.class-lists .sub-visibility {
  flex: 0 0 220px;
  display: inline-block;
  overflow: visible;
}

// 区域资源样式
.area-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 0 0;
}
.area-box .item {
  width: 220px;
  margin-bottom: 20px;
  overflow: hidden;
}

.area-box .item .thumb-image {
  width: 220px;
  height: 124px;
  background-size: 100% auto;
  cursor: pointer;
  position: relative;
  transition: all 0.8s;
  background-position: center;
}
.area-box .item:hover .thumb-image {
  transform: scale(1.1);
}
.area-box .item .title {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #1e1e1e;
  padding: 10px 0 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.area-box .item:hover .title {
  color: #552f06;
}
.area-box .item .other .quote {
  margin-right: 20px;
}
.area-box .item .other .quote,
.area-box .item .other .browse {
  display: inline;
  font-size: 12px;
  color: #ababab;
}
.area-box .flex-visibility {
  flex: 0 0 220px;
  display: inline-block;
  overflow: visible;
}
</style>