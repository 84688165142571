<template>
  <div class="footer-box" @contextmenu.prevent.capture>
    <div class="container flex-box">
      <div class="logo-left">
        <a :href="services.YXT_GW" target="_blank"><img src="../assets/image/common/yxt-bottom.png" /></a>
        <span class="split-line"></span>
        <div class="sz-name">思政资源库</div>
      </div>
      <div class="company-box">
        <p>北京中教互联教育科技有限公司</p>
        <p>地址：北京市海淀区海淀文教园F座5层</p>
      </div>
      <div class="contact-box">
        <div class="contact-title">服务咨询</div>
        <div class="contact-line">
          <p>【华东】吴老师 157 1579 3495</p>
          <p>【华南】王老师 131 6151 0966</p>
          <p>【华北】王老师 131 6151 0966</p>
          <p>【华中】罗老师 135 5412 3986</p>
        </div>
      </div>
      <div class="yxt-erweima">
        <img src="../assets/image/common/erweima.png" />
      </div>
    </div>
    <div class="copyright">
      <span>版权所有</span>
      <!-- <span><a href="https://www.eol.cn" target="_blank">中国教育在线</a></span> -->
      <span>北京中教互联教育科技有限公司</span>
      <a href="http://www.eol.cn/html/cer.net/icp.htm" target="_blank">京ICP证140769号</a> |
      <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">京ICP备2022007846号-1 </a> |
      <a href="http://img.eol.cn/images/indexnew/www1024.jpg" target="_blank">京网文[2014]2106-306号</a> |
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236" target="_blank">京公网安备 11010802020236号</a>
    </div>
  </div>
</template>

<script>
import services from "../assets/js/services"
export default {
  name: 'Header',
  components: {
  },
  data () {
    return {
      services,
    };
  },
}
</script>

<style lang="less" scoped>
.copyright {
  height: 40px;
  background: #e4e6e9;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  span,
  a {
    display: inline-block;
    vertical-align: top;
    margin: 0 5px;
    font-size: 12px;
    color: #696969;
    transition: color 0.3s;
  }
  a:hover {
    color: #ee373d;
  }
}
.footer-box {
  background: #f0f2f5;
  position: relative;
}
.flex-box {
  display: flex;
  height: 140px;
  justify-content: space-between;
  align-items: center;
  .logo-left {
    .split-line {
      display: inline-block;
      width: 1px;
      height: 17px;
      background: #cfcfcf;
      border-radius: 1px;
      margin: 13px 20px 0;
    }
    .sz-name {
      font-size: 18px;
      font-weight: bold;
      color: #2a1616;
      display: inline-block;
    }
  }
  .company-box {
    color: #828282;
    font-size: 14;
    line-height: 24px;
  }
  .contact-box {
    display: flex;
    white-space: nowrap;
    align-items: center;
    .contact-title {
      display: inline-block;
      -webkit-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      font-weight: bold;
      color: #252525;
      font-size: 12px;
      letter-spacing: 2px;
    }
    .contact-line {
      display: inline-block;
      font-size: 13px;
      color: #828282;
      margin-left: 15px;
      vertical-align: 2px !important;
      p {
        margin: 2px 0;
      }
    }
  }
}

@media (min-device-width: 320px) and (max-width: 689px),
  (max-device-width: 480px) {
  .footer-box {
    display: none;
  }
}
</style>
