<template>
  <div ref="container" class="containers">
    <SdcmHeader />
    <div class="sdcm-container-box">
      <div class="sdcm-content-box w1280">
        <div class="side-bar">
          <div class="header-box" v-if="indexSfkc"></div>
          <div class="body-box">
            <div :class="['item',currentIndex==index?'active':'']" v-for="(item, key, index) of indexSfkc" :key="index" @click="currentSideBar(index,key)">
              <div class="star"></div>
              <div class="text">{{key}}</div>
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="has-course" v-if="listData&&listData.length>0">
            <div class="login-mask" v-if="needLogin && currentIndex==1 ">
              <div class="login-btn" @click="loginCloseMask">
                登陆后查看 >>
              </div>
            </div>

            <template v-else>
              <div class="tip-text">
                “增强法律意识，尊重知识产权，未经作者允许不得转载、摘录、引用等。”
              </div>
              <div class="body-box">
                <div class="item" v-for="item,index in listData" :key="index" @click="goCourse(item)">
                  <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`" v-if="item.thumb_image"></div>
                  <div class="right-content">
                    <div class="title">
                      <div class="text">{{item.title}}</div>
                      <div class="label" v-if="item.ovtitle&&item.ovtitle.length>0">{{item.ovtitle}}</div>
                    </div>
                    <div class="sub-title" v-if="item.author||item.name" v-html="item.author || item.name"></div>
                    <div class="desc" v-html="item.description"></div>
                  </div>
                </div>
              </div>
              <div style="text-align: center; padding-top:20px;width: 100%;" v-if="currentIndex == 1">
                <a-pagination v-model="realyPage" :show-total="total => `共${allPages}页`" :total="total" @change="onPagesChange" />
              </div>
            </template>

          </div>
          <div class="no-content" v-else>
            <div>待提供内容</div>
          </div>

        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <SdcmFooter :color="'#3E140B'" />

  </div>
</template>

<script>
import SdcmHeader from "../../components/SdcmHeader.vue"
import SdcmFooter from "../../components/SdcmFooter.vue"
import sdcmData from "./sdcmData"
import { mapActions, mapState, mapMutations } from "vuex"
import services from "../../assets/js/services"

export default {
  data () {
    return {
      indexSfkc: sdcmData.indexSfkc,
      currentIndex: 0,
      listData: sdcmData.indexSfkc['校本示范课-共建'].slice(0, 3),
      school_code: localStorage.getItem("schoolCode"),
      needLogin: false,
      allPages: 0,
      page: 0,
      size: 10,
      total: 0,
      realyPage: 1,
    }
  },
  components: {
    SdcmHeader,
    SdcmFooter,
  },
  methods: {
    ...mapActions(['goAppsDetailsBeforeLogin', 'getAppsLoginUserInfo']),
    ...mapMutations(['setAppsLoginVisible']),
    currentSideBar (index, key) {
      this.currentIndex = index
      if (key == '校外示范课') {
        this.judgeLogin()
        this.getList()
        this.goAppsDetailsBeforeLogin()
      } else {
        this.listData = sdcmData.indexSfkc[key].slice(0, 3)
      }
    },
    goCourse (item) {
      // 判断是否登录,进行跳转
      if (this.currentIndex == 0) {
        if (item.url == 'kcsz_sfkc') {
          // window.open(`/${this.school_code}/sdcmzyxy/sfkc`)
          return
        } else {
          window.open(item.url)
        }
      } else {
        this.goAppsDetailsBeforeLogin().then(() => {
          window.open(item.url)
        })
      }

    },
    getList () {
      var time = Date.parse(new Date());
      services.getXwkcList({ page: this.page, time }).then(res => {
        this.listData = res.info
        this.allPages = res.pageNum
        this.total = res.pageNum * 10
      })
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    async judgeLogin () {
      await this.getAppsLoginUserInfo();
      this.needLogin = this.appsUserInfo.id ? false : true
    },
    loginCloseMask () {
      this.goAppsDetailsBeforeLogin()
      this.judgeLogin()
    },
    onPagesChange (val) {
      this.realyPage = val
      this.page = val - 1
      this.getList()
    },
  },
  computed: {
    ...mapState({
      loginVisible: state => state.appsLogin.loginVisible,
      appsUserInfo: state => state.appsLogin.appsUserInfo
    }),
  },
  watch: {
    appsUserInfo (newVal) {
      if (this.currentIndex)
        this.needLogin = newVal.id ? false : true
    }
  }
}
</script>

<style lang="less" scoped>
* {
  transition: all 0.3s;
}
.w1280 {
  width: 1280px;
  margin: 0 auto;
}
.sdcm-container-box {
  background-image: url('../../assets/image/sdcmzyxy/zcwj-body.png');
  background-size: cover;
  padding: 22px 0 0;
  .sdcm-content-box {
    background-color: #fff;
    padding: 0 40px 0 43px;
    min-height: 700px;

    .side-bar {
      float: left;
      width: 264px;
      .header-box {
        height: 123px;
        background-image: url('../../assets/image/sdcmzyxy/slidebar/sfkc.png');
        background-size: cover;
      }
      .body-box {
        padding-left: 38px;
        padding: 0 41px 0 38px;
        .item {
          width: 226px;
          height: 60px;
          line-height: 60px;
          background-color: #fff;
          border-bottom: 1px solid #f3f3f3;
          padding: 0 14px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .star {
            width: 18px;
            height: 17px;
            background-image: url('../../assets/image/sdcmzyxy/slidebar/star.png');
            background-size: cover;
            margin-right: 8px;
          }
          .text {
            font-size: 17px;
            color: #262626;
          }
          &.active {
            background: #c00800;
            border: none;
            .star {
              background-image: url('../../assets/image/sdcmzyxy/slidebar/star-active.png');
            }
            .text {
              color: #fff;
            }
          }
        }
      }
    }
    .right-container {
      width: 933px;
      float: right;
      padding: 23px 40px 0 38px;
      flex: 1;
      position: relative;
      .has-course {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 24px;
        .tip-text {
          width: 853px;
          height: 34px;
          background: #fcf1e0;
          text-align: center;
          line-height: 34px;
          font-size: 12px;
          color: #bd8249;
        }
        .body-box {
          width: 100%;
          overflow: hidden;
          .item {
            padding: 28px 0;
            border-bottom: 1px solid #f4f4f4;
            display: flex;
            transition: color 0s;
            &:first-child {
              cursor: pointer;
            }
            &:hover {
              .title .text {
                color: #840000 !important;
              }
            }
            &:first-child {
              padding-top: 25px;
            }
            .thumb-image {
              width: 200px;
              height: 113px;
              border-radius: 2px;
              background-size: cover;
              background-position: center;
            }
            .right-content {
              flex: 1;
              padding: 3px 0 0 15px;
              .title {
                padding-bottom: 17px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .text {
                  font-size: 18px;
                  color: #262626;
                  margin-right: 15px;
                  max-width: 80%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
                .label {
                  padding: 0 10px;
                  height: 24px;
                  background: linear-gradient(
                    -55deg,
                    #f8e7c6 0%,
                    #f8e5be 53%,
                    #fff0cf 99%
                  );
                  border-radius: 2px;
                  vertical-align: middle;
                  font-size: 14px;
                  color: #840000;
                  line-height: 24px;
                  text-align: center;
                  font-weight: bold;
                }
              }
              .sub-title {
                font-size: 12px;
                color: #840000;
                padding-bottom: 5px;
                &::before {
                  content: '';
                  display: inline-block;
                  background-image: url('../../assets/image/sdcmzyxy/sfkc-sub-label.png');
                  width: 6px;
                  height: 13px;
                  background-size: cover;
                  vertical-align: middle;
                  margin-right: 8px;
                }
              }
              .desc {
                font-size: 13px;
                color: #acacac;
                line-height: 22px;
              }
            }
          }
        }
      }
      .no-content {
        width: 100%;
        height: 100%;
        position: relative;
        min-height: 500px;
        font-size: 14px;
        color: #a2a2a2;
        transition: all 0s;
        div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: inline-block;
        }
      }
      .login-mask {
        position: absolute;
        width: 683px;
        height: 319px;
        z-index: 100;
        background-color: #fff;
        background-image: url('../../assets/image/sdcmzyxy/needLogin.png');
        background-size: cover;
        top: 106px;
        right: 134px;
        .login-btn {
          width: 158px;
          height: 38px;
          text-align: center;
          line-height: 38px;
          background: #ae0000;
          border-radius: 19px;
          cursor: pointer;
          font-size: 16px;
          color: #ffffff;
          margin: 36px auto 0;
          font-weight: 600;
        }
      }
    }
  }
}
::v-deep .login-outer-box .login-inner-box {
  top: 51%;
  left: 50%;
}
</style>