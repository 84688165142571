import Vue from 'vue'
import services from '../assets/js/services'

export default {
  state: {
    classCategory: [],
    classCategory2: [],
    classCategory3: [],
    classData: {},
    materialData: {},
    cateZyjnyc: [], // 专业技能养成的分类（课程名称）
    downloadIdList: [],
    xxzyRouterList: [
      {
        name: "思政专题研修",
        path: "szztyx",
        id: 60,
      },
      {
        name: "课程思政专题研修",
        path: "kcszzt",
        id: 6,
      },
      {
        name: "美育专题研修",
        path: "myztyx",
        id: 362,
      },
      {
        name: "课程思政案例资源",
        path: "kcszal",
        id: 103,
      },
      {
        name: "专业技能养成",
        path: "zyjnyc",
        id: 2,
      },
      {
        name: "素养提升",
        path: "rwsy",
        id: 55,
      },
      {
        name: "教师发展",
        path: "jsfz",
        id: 86,
      },
      {
        name: "职教世界",
        path: "zjsj",
        id: 87,
      },
      
    ],
  },
  getters: {
  },
  actions: {
    // 列表页获取课程分类
    loadCategoryData(context, payload){
      return services.getCourseCategory(payload).then((res) => {
        if(res.code === '0000') {
          if (payload.level == 2) {
            context.commit('setclassCategory2', {
              'data': res.data || []
            })
          }else if(payload.level == 3){
            context.commit('setclassCategory3', {
              'data': res.data || []
            })
          } else {
            context.commit('setclassCategory', {
              'data': res.data || []
            })
          }
        }
      })
    },
    // 获取课程列表数据
    getCourseData(context, payload) {
      return services.getCourseLists(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('loadClassData', {
            'classData': res.data || {}
          })
        }
      })
    },
    //  获取专题研修资源 素材
    getMaterialLists(context, payload) {
      return services.courseMaterialLists(payload).then((res) => {
        if (res.code === '0000') {
          context.commit('setMaterialData', {
            'data': res.data || {}
          })
        }
      })
    },
    // 获取专业技能养成的分类（课程名称）
    getZyjnycCateData(context, payload) {
      return services.getCourseLists(payload).then((res) => {
        if(res.code === '0000') {
          context.commit('setZyjnycCate', {
            'data': res.data && res.data.items || []
          })
        }
      })
    },
  },
  mutations: {
    setclassCategory (state, payload) {
      if(payload.data && payload.data.length > 0){
        // payload.data.unshift({
        //   name: "全部",
        //   id: "",
        // })
        state.classCategory = payload.data
      } else {
        state.classCategory = []
      }
    },
    setclassCategory2 (state, payload) {
      if(payload.data && payload.data.length > 0){
        // payload.data.unshift({
        //   name: "全部",
        //   id: "",
        // })
        state.classCategory2 = payload.data
      } else {
        state.classCategory2 = []
      }
    },
    setclassCategory3(state, payload) {
      if(payload.data && payload.data.length > 0){
        // payload.data.unshift({
        //   name: "全部",
        //   id: "",
        // })
        state.classCategory3 = payload.data
      } else {
        state.classCategory3 = []
      }
    },
    setZyjnycCate(state, payload){
      if(payload.data && payload.data.length > 0){
        payload.data.unshift({
          name: "全部",
          id: "",
        })
        state.cateZyjnyc = payload.data
      } else {
        state.cateZyjnyc = []
      }
    },
    loadClassData (state, payload) {
      Vue.set(state, "classData" ,payload.classData)
    },
    setDownloadIdList (state,payload){
      let isDownload = state.downloadIdList.findIndex(item => payload == item)
      if(isDownload > -1) {
        state.downloadIdList.splice(isDownload,1)
      }else {
        state.downloadIdList.push(payload)
      }

    },
    setxxzyRouterList (state,payload){
      state.xxzyRouterList = state.xxzyRouterList.filter(item => {
        return payload.some(elem => elem == item.id)
      })
    },
    // setClassCategory2 (state, payload) {
    //   Vue.set(state, "classCategory2", payload.classCategory2)
    // },
    // loadClassData (state, payload) {
    //   if (payload.classData.items && state.classCategory2) {
    //     payload.classData.items.forEach((pe) => {
    //       state.classCategory2.forEach((sub) => {
    //         if (pe.category_id == sub.category_id) {
    //           pe.category_name = sub.name
    //         }
    //       });
    //     });
    //   }
    //   Vue.set(state, "classData", payload.classData)
    // },
    setMaterialData(state, payload) {
      state.materialData = payload.data
    }
  }
}