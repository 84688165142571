<template>
  <div class="rwsy-content">
    <div class="header-box"></div>
    <div class="content-info">
      <div class="lssy-bg">
        <div class="lssy-content">
          <div class="content-title"></div>
          <div class="lssy-info">
            <div class="info-top">
              <div
                class="top-item"
                v-for="item in lssyLIstTop"
                :key="item.id"
                @click="goToClassDetail(item)"
              >
                <div
                  class="item_img"
                  :style="`background-image: url('${item.photo}');`"
                ></div>
                <div class="item-title">{{ item.name }}</div>
              </div>
            </div>
            <div class="info-bottom">
              <div
                class="bot-item"
                v-for="item in lssyLIstBottom"
                :key="item.id"
                @click="goToClassDetail(item)"
              >
                <div class="item-img">
                  <div
                    :style="`background-image: url('${item.list_photo}');`"
                  ></div>
                </div>
                <div class="item-info">
                  <div class="item-name">
                    {{ item.name }}
                  </div>
                  <div class="item-time">课程总时长：{{item.total_time}} | {{item.learn_num}}人已学</div>
                </div>
              </div>
            </div>
          </div>
          <div class="Pagination-boxxxx">
            <APagination
              v-if="total > 6"
              v-model="page"
              :total="total"
              :page-size="pageSize"
              @change="onPaginationChange"
            ></APagination>
            <div
              v-else-if="lssyLIstBottom.length + lssyLIstTop.length == 0"
              class="noData-box"
            >
              <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
              <div class="text">暂无相关内容</div>
            </div>
          </div>
        </div>
      </div>
      <div class="whpg-bg">
        <div class="whpg-content">
          <div class="whpg-title"></div>
          <div class="swiper-content" v-if="whpgList && whpgList.length > 0">
            <swiper :options="whpgSwiperOption">
              <swiper-slide
                :style="`background-image: url('${item.photo}');`"
                v-for="item in whpgList"
                :key="item.id"
                :data-kcid="item.id"
                :data-kcName="item.name"
              >
                <div
                  class="item-title"
                  :data-kcid="item.id"
                  :data-kcName="item.name"
                >
                  {{ item.name }}
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination whpg"></div>

            <div class="swiper-button-next whpg"></div>
            <div class="swiper-button-prev whpg"></div>
          </div>
          <div v-else class="noData-box">
            <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
            <div class="text">暂无相关内容</div>
          </div>
        </div>
        <div class="yssm-content">
          <div class="yssm-title"></div>
          <div class="yssm-swiper" v-if="yssmList && yssmList.length > 0">
            <swiper :options="yssmSwiperOption">
              <swiper-slide v-for="item in yssmList" :key="item.id">
                <div class="item-img">
                  <div
                    :style="`background-image: url('${item.list_photo}');`"
                  ></div>
                </div>
                <div class="item-info">
                  <div class="info-name">
                    {{ item.name }}
                  </div>
                  <div
                    class="info-more"
                    :data-kcid="item.id"
                    :data-kcName="item.name"
                  >
                    <span :data-kcid="item.id" :data-kcName="item.name"
                      >查看更多</span
                    >
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination yssm"></div>
            <div class="swiper-button-next yssm"></div>
            <div class="swiper-button-prev yssm"></div>
          </div>
          <div v-else class="noData-box">
            <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
            <div class="text">暂无相关内容</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import services from "../../assets/js/services";
import { Pagination } from "ant-design-vue";
import { mapActions } from "vuex";

export default {
  name: "xxzyrwsy",
  components: {
    APagination: Pagination,
    swiper,
    swiperSlide,
  },
  data() {
    let self = this;
    return {
      whpgSwiperOption: {
        effect: "coverflow",
        loop: true,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 0,
          stretch: 400,
          depth: 300,
          modifier: 1,
          slideShadows: false,
        },
        preventClicksPropagation: false,
        navigation: {
          // 前进后退按钮 ,不同版本配置方式不同
          nextEl: `.swiper-button-next.whpg`,
          prevEl: `.swiper-button-prev.whpg`,
        },
        pagination: {
          el: `.swiper-pagination.whpg`,
        },
        on: {
          click: function (v) {
            console.log(v.target.getAttribute("data-kcid"));
            let id = v.target.getAttribute("data-kcid");
            let kcName = v.target.getAttribute("data-kcName");
            if (id != null || kcName != null) {
              self.goToClassDetail({ id, name: kcName });
            }
          },
        },
      },
      yssmSwiperOption: {
        loop: true,
        spaceBetween: 23,
        slidesPerView: 4,
        preventClicksPropagation: false,
        navigation: {
          // 前进后退按钮 ,不同版本配置方式不同
          nextEl: `.swiper-button-next.yssm`,
          prevEl: `.swiper-button-prev.yssm`,
        },
        pagination: {
          el: `.swiper-pagination.yssm`,
        },
        on: {
          click: function (v) {
            console.log(v.target.getAttribute("data-kcid"));
            let id = v.target.getAttribute("data-kcid");
            let kcName = v.target.getAttribute("data-kcName");
            console.log(typeof id, typeof kcName);

            if (id != null || kcName != null) {
              self.goToClassDetail({ id, name: kcName });
            }
          },
        },
      },
      lssyLIstTop: [],
      lssyLIstBottom: [],
      whpgList: [],
      yssmList: [],
      categoryLIst: {
        lssy: 57,
        whpg: 96,
        yssm: 58,
      },
      school_code: localStorage.getItem("schoolCode"),
      total: 0,
      pageSize: 6,
      page: 1,
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getlssyList();
    this.getwhpgList();
    this.getyssmList();
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),

    async getlssyList() {
      try {
        let res = await services.getkcszList({
          school_code: this.school_code,
          size: this.pageSize,
          page: this.page,
          category_id: this.categoryLIst.lssy,
        });
        // console.log(res);

        if (res.code == "0000" && res.data.items.length > 0) {
          this.lssyLIstTop = res.data.items.slice(0, 2);
          this.lssyLIstBottom = res.data.items.slice(2);
          this.total = res.data.total;
        }
      } catch (error) {}
    },
    async getwhpgList() {
      try {
        let res = await services.getkcszList({
          school_code: this.school_code,
          size: 100,
          page: 1,
          category_id: this.categoryLIst.whpg,
          // category_id: 2,
        });
        if (res.code == "0000" && res.data.items.length > 0) {
          this.whpgList = res.data.items;
        }
      } catch (error) {}
    },
    async getyssmList() {
      try {
        let res = await services.getkcszList({
          school_code: this.school_code,
          size: 100,
          page: 1,
          category_id: this.categoryLIst.yssm,
          // category_id: 2,
        });
        if (res.code == "0000" && res.data.items.length > 0) {
          this.yssmList = res.data.items;
        }
      } catch (error) {}
    },
    onPaginationChange(current) {
      this.page = current;
      this.getlssyList();
    },
    goToClassDetail(item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(
          `${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.id}&category_name=${item.name}&cm_id=${item.id}`
        );
      });
    },
  },
};
</script>
<style lang="less" scoped>
.rwsy-content {
  .header-box {
    width: 100%;
    height: 400px;
    background-image: url("../../assets/image/xxzyk/syts-banner.png");
    background-size: cover;

    background-position: center;
  }
  .lssy-bg {
    background-image: url("../../assets/image/xxzyk/rwsy/lssy-bg.png");
    padding-bottom: 23px;
    background-position: center;
    background-size: cover;
    .lssy-content {
      width: 1200px;
      margin: 0 auto;
      .content-title {
        width: 592px;
        height: 113px;
        margin: 0 auto;
        background-image: url("../../assets/image/xxzyk/rwsy/lssy-title.png");
      }
      .lssy-info {
        margin-top: 10px;
        .info-top {
          display: flex;
          justify-content: space-between;
          .top-item {
            width: 588px;
            height: 331px;
            background: #fff;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            cursor: pointer;
            &:hover .item_img {
              transform: scale(1.1);
            }
            .item_img {
              transition: all 0.5s;
              background-size: 100% auto;
              background-position: center;
              width: 588px;
              height: 331px;
            }
            &::after {
              content: "";
              display: block;
              position: absolute;
              height: 60px;
              width: 100%;
              background: #191919;
              opacity: 0.5;
              bottom: 0;
            }
            .item-title {
              font-size: 18px;
              position: absolute;
              z-index: 2;
              bottom: 18px;
              width: 548px;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              left: 50%;
              transform: translateX(-50%);
              font-weight: bold;
              opacity: 1;
              color: #fff;
            }
          }
        }
        .info-bottom {
          margin-top: 3px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .bot-item {
            cursor: pointer;
            width: 590px;
            height: 160px;
            display: flex;
            align-items: flex-end;
            margin-top: 40px;
            &:hover .item-img div {
              transform: scale(1.1);
            }
            .item-img {
              width: 282px;
              cursor: pointer;
              height: 159px;
              overflow: hidden;
              div {
                width: 282px;
                height: 159px;
                transition: all 0.5s;
                background-size: 100% auto;
                background-position: center;
              }
            }
            .item-info {
              width: 308px;
              height: 148px;
              padding-top: 40px;
              padding-left: 40px;
              background-image: url("../../assets/image/xxzyk/rwsy/lssyinfo-bg.png");
              background-size: 100% auto;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              &::before {
                content: "";
                display: block;
                width: 14px;
                height: 16px;
                position: absolute;
                left: 18px;
                top: 46px;
                // background: #fff;
                background-image: url("../../assets/image/xxzyk/rwsy/lssytitle-icon.png");
                background-size: 100%;
              }
              .item-name {
                font-size: 18px;
                // height: 54px;
                font-weight: bold;
                color: #ffffff;
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                line-clamp: 2;
                -webkit-line-clamp: 2; //显示几行
              }
              .item-time {
                font-size: 14px;
                margin-bottom: 20px;
                font-weight: 400;
                color: #c19374;
              }
            }
          }
        }
      }
      /deep/ .Pagination-boxxxx {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .ant-pagination-item-active {
          font-weight: 500;
          background: #994001;
          border-color: #994001;
        }

        .ant-pagination-item-active:focus,
        .ant-pagination-item-active:hover,
        .ant-pagination-item:focus,
        .ant-pagination-item:hover {
          border-color: #994001;
          transition: all 0.3s;
          color: #fff;
        }

        .ant-pagination-item:focus a,
        .ant-pagination-item:hover a {
          color: #994001;
        }

        .ant-pagination-item-active:focus a,
        .ant-pagination-item-active:hover a {
          color: #fff;
        }

        .ant-pagination-item-active a {
          color: #fff;
          border-color: #994001;
        }

        .ant-pagination-prev:focus .ant-pagination-item-link,
        .ant-pagination-next:focus .ant-pagination-item-link,
        .ant-pagination-prev:hover .ant-pagination-item-link,
        .ant-pagination-next:hover .ant-pagination-item-link {
          color: #994001;
          border-color: #994001;
        }
      }
    }
  }
  .whpg-bg {
    padding-bottom: 120px;
    padding-top: 65px;

    background-image: url("../../assets/image/xxzyk/rwsy/whpg-bg.png");
    background-position: center;
    background-size: cover;
    .whpg-content {
      width: 1200px;
      margin: 0 auto;
      .whpg-title {
        margin: 0 auto 12px;
        width: 592px;
        height: 113px;
        background-image: url("../../assets/image/xxzyk/rwsy/whpg-title.png");
      }
      .swiper-content {
        position: relative;
        width: 100%;
        height: 500px;
        background: #faefe0;
        display: flex;
        align-items: center;
        .swiper-container {
          height: 403px;
          .swiper-wrapper {
            .swiper-slide {
              width: 716px;
              height: 403px;
              background: #fff;
              background-size: 100% auto;
              background-position: center;
              cursor: pointer;
              position: relative;
              &::before {
                content: "";
                display: block;
                position: absolute;
                height: 60px;
                width: 100%;
                background: #191919;
                opacity: 0.5;
                bottom: 0;
              }
              .item-title {
                font-size: 18px;
                position: absolute;
                z-index: 2;
                bottom: 18px;
                width: 548px;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                left: 50%;
                transform: translateX(-50%);
                font-weight: bold;
                opacity: 1;
                color: #fff;
              }
             
            }
          }
        }
        /deep/ .swiper-pagination {
          bottom: 10px;
          width: 100%;
          .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background: #cdb29f;
            border-radius: 50%;
            margin: 0 7px;
          }
          .swiper-pagination-bullet.swiper-pagination-bullet-active {
            width: 10px;
            height: 10px;
            background: #dd3639;
            border-radius: 50%;
          }
        }
        .swiper-button-next,
        .swiper-button-prev {
          width: 29px;
          height: 58px;
          background-size: cover;
        }
        .swiper-button-next {
          background-image: url("../../assets/image/xxzyk/jsfz/jsfz-next.png");
          right: -40px;
        }
        .swiper-button-prev {
          background-image: url("../../assets/image/xxzyk/jsfz/jsfz-prev.png");
          left: -40px;
        }
        .swiper-button-next:hover {
          background-image: url("../../assets/image/xxzyk/jsfz/jsfznext-hover.png");
        }
        .swiper-button-prev:hover {
          background-image: url("../../assets/image/xxzyk/jsfz/jsfzprev-hover.png");
        }
      }
    }
    .yssm-content {
      width: 1200px;
      margin: 0 auto;
      .yssm-title {
        margin: 10px auto;
        width: 592px;
        height: 113px;
        background-image: url("../../assets/image/xxzyk/rwsy/yssm-title.png");
      }
      .yssm-swiper {
        position: relative;
        height: 335px;
        .swiper-container {
          height: 285px;
          .swiper-wrapper {
            .swiper-slide {
              background: #fff;
              border: 1px solid #ececec;
              &:hover .item-img div {
                transform: scale(1.1);
              }
              .item-img {
                width: 282px;
                height: 159px;
                // background: #994001;
                overflow: hidden;
                div {
                  width: 100%;
                  height: 100%;
                  background-size: 100% auto;
                  background-position: center;
                  transition: all 0.5s;
                }
              }
              .item-info {
                height: 126px;
                padding: 16px 14px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .info-name {
                  font-size: 18px;
                  font-weight: bold;
                  color: #3a3a3a;
                  position: relative;
                  line-height: 20px;
                  padding-left: 20px;
                  display: -webkit-box;
                  overflow: hidden;
                  -webkit-box-orient: vertical;
                  width: 100%;
                  line-clamp: 2;
                  -webkit-line-clamp: 2; //显示几行
                  &::before {
                    content: "";
                    display: block;
                    width: 14px;
                    height: 16px;
                    position: absolute;
                    top: 3px;
                    left: 0;
                    background-size: 100%;
                    background-image: url("../../assets/image/xxzyk/rwsy/yssmtitle-icon.png");
                  }
                }
                .info-more {
                  width: 114px;
                  height: 34px;
                  background: #c79c62;
                  border-radius: 17px;
                  font-size: 15px;
                  font-weight: 400;
                  color: #ffffff;
                  cursor: pointer;
                  // text-align: center;
                  // line-height: 34px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &::before {
                    content: "";
                    display: block;
                    width: 13px;
                    height: 13px;
                    margin-right: 10px;
                    background-size: 100%;
                    background-image: url("../../assets/image/xxzyk/rwsy/yssm-more.png");
                  }
                }
              }
            }
          }
        }
        /deep/ .swiper-pagination {
          bottom: 10px;
          width: 100%;
          .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background: #cdb29f;
            border-radius: 50%;
            margin: 0 7px;
          }
          .swiper-pagination-bullet.swiper-pagination-bullet-active {
            width: 10px;
            height: 10px;
            background: #dd3639;
            border-radius: 50%;
          }
        }
        .swiper-button-next,
        .swiper-button-prev {
          width: 29px;
          height: 58px;
          top: 35%;
        }
        .swiper-button-next {
          background-image: url("../../assets/image/xxzyk/jsfz/jsfz-next.png");
          right: -40px;
        }
        .swiper-button-prev {
          background-image: url("../../assets/image/xxzyk/jsfz/jsfz-prev.png");
          left: -40px;
        }
        .swiper-button-next:hover {
          background-image: url("../../assets/image/xxzyk/jsfz/jsfznext-hover.png");
        }
        .swiper-button-prev:hover {
          background-image: url("../../assets/image/xxzyk/jsfz/jsfzprev-hover.png");
        }
      }
    }
  }
}
@media screen and (max-width: 1920px) {
  .lssy-bg {
    background-size: 1920px 943px !important;
  }
}
</style>

