<template>
  <div>
    <WhrjHeader />
    <div class="sdcm-container-box">
      <div class="sdcm-banner-box">
        <swiper ref="mySwiperBanner" v-if="bannerData&&bannerData.length>0" class="eol-swiper-box" :options="swiperBannerOption">
          <template v-for="item,index in bannerData">
            <swiper-slide :key="index">
              <div class="banner-item" :style="`background-image:url(${item.thumb_image})`" @click="goDetail(item,false)"></div>
            </swiper-slide>
          </template>
          <template v-if="bannerData&&bannerData.length>1">
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </template>
        </swiper>
      </div>

      <div class="first-bg-box">
        <div class="zcwj-container w1200">
          <div class="common-title zcwj">
            <div class="left-title">政策文件</div>
            <a :href="'/'+school_code+'/whrjgczyxy/zcwj'" target="_blank" class="right-more">更多></a>
          </div>
          <div class="zcwj-list" v-if="zcwjList">
            <div class="right-group">
              <template v-for="item,index in zcwjList">
                <div class="item" :key="index" v-if="index < 2" @click="goDetail(item,'zcwj')">
                  <div class="thumb-outer">
                    <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`"></div>
                  </div>
                  <div class="description">
                    <div class="article-info">
                      <div class="title">{{item.title}}</div>
                      <div class="date">{{item.issueTime.substring(0,10)}}</div>
                    </div>
                    <div class="intro" v-html='item.description'></div>
                  </div>
                </div>
              </template>
            </div>
            <div class="left-article" @mouseout="hoverZcwj = 2">
              <template v-for="(item,index) in zcwjList">
                <div :class="hoverZcwj == index ? 'item active' : 'item'" @mouseover="moseHover" :key="index" v-if="index > 1 && index < 6">
                  <div class="month-day">{{item.issueTime.substring(5,10)}}</div>
                  <div class="title" @click="goDetail(item,'zcwj')">
                    <p>{{item.title}}</p>
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

        <div class="pxyt-container w1200" v-if="dxjyList">
          <div class="common-title pxyt">
            <div class="left-title">典型案例</div>
            <a :href="'/'+school_code+'/whrjgczyxy/dxal'" target="_blank" class="right-more">更多></a>
          </div>
          <div class="list-box">
            <template v-for="(item,index) in dxjyList">
              <div class="item" :key="index" @click="goDetail(item,'dxjy')" v-if="index<4">
                <div class="thumb-outer">
                  <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`"></div>
                </div>
                <div class="description">
                  <div class="title">{{item.title}}</div>
                  <div class="date">{{item.issueTime.substring(0,10)}}</div>
                  <div class="intro" v-html="item.description"></div>
                </div>
              </div>
            </template>

          </div>
        </div>

      </div>

      <div class="body-bg-box">
        <div class="jsdt-container w1200" v-if="jsdtList">
          <div class="common-title jsdt">
            <div class="left-title">建设动态</div>
            <a :href="'/'+school_code+'/whrjgczyxy/jsdt'" target="_blank" class="right-more">更多></a>
          </div>
          <div class="list-box">
            <template v-for="item,index in jsdtList">
              <div class="item" :key="index" v-if="jsdtList && index <6">
                <div class="thumb-outer">
                  <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`"></div>
                </div>
                <div class="right-content">
                  <div class="title" @click="goDetail(item,'jsdt')">{{item.title}}</div>
                  <div class="description">
                    <div class="time">{{item.issueTime.substring(0,10)}}</div>
                    <div class="intro" v-html="item.description"></div>
                  </div>
                </div>
              </div>
            </template>

          </div>
        </div>
      </div>

      <div class="sfkc-container">
        <div class="sfkc-list zcwj w1200">
          <div class="common-title sfkc">
            <div class="left-title">名家讲坛</div>
            <a :href="'/'+school_code+'/whrjgczyxy/mjjt'" target="_blank" class="right-more">更多></a>
          </div>
          <div class="list-box" v-if="indexMsjz">
            <div class="item" v-for="(item,index) in indexMsjz" :key="index" @click="goToMaterialDetail(item)">
              <div class="thumb-outer">
                <div class="thumb-image" :style="`background-image:url(${item.photo})`"></div>
              </div>
              <div class="description">
                <div class="title">
                  <div class="line"></div>
                  <div class="text">{{item.name}}</div>
                </div>
                <!-- <div v-if="item.total_time" class="author">{{item.total_time}}</div> -->
              </div>
            </div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
          </div>
          <!-- <div class="class-lists">
            <div class="class-box" v-for="(item, index) in indexMsjz" :key="index">
              <div class="link-box" @click="goToClassDetail(item)">
                <div class="img">
                  <img :src="item.list_photo" alt />
                </div>
                <div class="txt">
                  <div class="title">{{ item.name }}</div>
                  <div class="num">
                    <span v-if="item.category_name">{{ item.category_name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
          </div> -->

        </div>

        <div class="mstd-container w1200" v-if="teachGroup && teachGroup.length > 0">
          <div class="common-title mstd">
            <div class="left-title">师资团队</div>
            <a :href="'/'+school_code+'/whrjgczyxy/sztd'" target="_blank" class="right-more">更多></a>
          </div>
          <!-- <div class="body-box">
            <div class="item" @click="goMstd(item)" v-for="item,index in teachGroup" :key="index">
              <div class="thumb-image" :style="item.thumb_image?`background-image:url(${item.thumb_image})`:'background-color:#f3f3f3'"></div>
              <div class="name">{{item.name}}</div>
            </div>

            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>

          </div> -->
          <div class="body-box">
            <template v-for="item,index in teachGroup">
              <div class="item" :key="index" @click="goMstd(item)" v-if="index<3">
                <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`"></div>
                <div :class="['description',index>=3?'right':'']">
                  <div class="name">【{{item.name}}】</div>
                  <div class="split"></div>
                  <!-- <div class="position">
                    <div class="position-item" v-for="p,i in item.position" :key="i">
                      {{p}}
                    </div>
                  </div> -->
                  <div class="intro">
                    <span v-for="item,index in item.details" :key="index">
                      {{item}}
                    </span>
                  </div>

                </div>
              </div>

              <div class="item" :key="index" @click="goMstd(item)" v-if="index>=3">
                <div class="description right">
                  <div class="name">【{{item.name}}】</div>
                  <div class="split"></div>
                  <!-- <div class="position">
                    <div class="position-item" v-for="p,i in item.position" :key="i">
                      {{p}}
                    </div>
                  </div> -->
                  <div class="intro">
                    <span v-for="item,index in item.details" :key="index">
                      {{item}}
                    </span>
                  </div>
                </div>
                <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`"></div>

              </div>
            </template>

          </div>
        </div>
      </div>

    </div>
    <WhrjFooter :color="'#251D1B'" />

  </div>
</template>
<script>
import WhrjHeader from "../../components/WhrjHeader"
import WhrjFooter from "../../components/WhrjFooter"
import sdcmData from "./sdcmData"
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { mapActions, mapState, mapMutations } from "vuex"
import services from '../../assets/js/services'

export default {
  data () {
    return {
      // 顶部swiper数据
      // indexSlideArr: sdcmData.indexSlideArr.slice(0, 5),
      // 新闻动态数据
      // indexXwdt: sdcmData.indexXwdt.slice(0, 5),
      // 示范课程数据
      // indexSfkc: sdcmData.indexSfkc['校本示范课-共建'],
      // 政策文件数据
      // indexZcwj: sdcmData.indexZcwj.slice(0, 6),
      // 培训研讨数据
      // indexPxyt: sdcmData.indexPxyt.slice(0, 4),
      // 媒体报道数据
      // jsdt: sdcmData.jsdt.slice(0, 8),
      // 名师团队数据 20230302暂时注释掉
      // teachGroup: sdcmData.teachGroup,
      teachGroup: [],
      // 建设动态
      jsdtList: [],
      // 政策文件
      zcwjList: [],
      // 典型经验
      dxjyList: [],
      // banner
      bannerData: [],
      // 名师讲座
      indexMsjz: [],
      swiperOption: {
        // loop: true,
        autoplay: {
          pauseOnMouseEnter: true,
        },
        speed: 400,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperBannerOption: {
        loop: true,
        autoplay: {
          pauseOnMouseEnter: true,
        },
        speed: 400,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      school_code: localStorage.getItem("schoolCode"),
      hoverZcwj: 2,
    }
  },
  components: {
    swiper,
    swiperSlide,
    WhrjHeader,
    WhrjFooter,
  },
  computed: {
    mySwiper () {
      return this.$refs.mySwiper && this.$refs.mySwiper.swiper;
    },
    mySwiperBanner () {
      return this.$refs.mySwiperBanner && this.$refs.mySwiperBanner.swiper;
    },
    ...mapState({
      loginVisible: state => state.appsLogin.loginVisible,
    }),

  },
  mounted () {
    this.getList()
  },
  methods: {
    ...mapActions(['goAppsDetailsBeforeLogin']),
    ...mapMutations(['setAppsLoginVisible']),
    goDetail (item, flag) {
      if ((item.outside && item.href) || !flag) {
        window.open(item.href || item.url)
      } else {
        // this.$router.push({ path: `/${this.school_code}/sdcmzyxy/detail/${item.id}`, query: { flag, url: item.url } })
        window.open(`/${this.school_code}/whrjgczyxy/detail/${item.id}?flag=${flag}&url=${item.url}`)
      }
    },
    goCourse (item) {
      // 判断是否登录,进行跳转
      // this.goAppsDetailsBeforeLogin().then(() => {
      if (item.url == 'kcsz_sfkc') {
        return
      } else {
        window.open(item.url)
      }
      // })
    },
    goMstd (item) {
      window.open(`/${this.school_code}/whrjgczyxy/sztd/detail/${item.id}`)

    },

    getList () {
      let that = this
      var time = Date.parse(new Date())
      services.getWhrjIndexZcWj({ page: 0, time }).then(res => {
        if (res && res.info) {
          this.zcwjList = res.info
        }
      })
      services.getWhrjIndexJsDt({ page: 0, time }).then(res => {
        if (res && res.info) {
          this.jsdtList = res.info
        }
      })

      services.getWhrjIndexDxal({ page: 0, time }).then(res => {
        if (res && res.info) {
          this.dxjyList = res.info
        }
      })

      services.getWhrjIndexBanner({ page: 0, time }).then(res => {
        if (res && res.info) {
          this.bannerData = res.info
        }
      })

      let params = { page: 1, size: 40, school_code: this.school_code }
      if (process.env.NODE_ENV == 'production') {
        // 第二层判断打包后是测试环境还是正式环境 
        if (process.env.VUE_APP_FLAG === 'pro') {
          params.category_id = 6
        }
      }
      // services.getCourseLists(params).then(res => {
      //   if(res && res.code == '0000'){
      //     this.indexMsjz = res.data.items
      //   }
      // });
      services.courseMaterialLists(params).then(res => {
        if (res && res.code == '0000') {
          that.indexMsjz = that.getRandomArray(res.data.items, 8)
        }
      });
    },
    getRandomArray (arr, num) {
      //新建一个数组,将传入的数组复制过来,用于运算,而不要直接操作传入的数组;
      var temp_array = new Array();
      for (var index in arr) {
        temp_array.push(arr[index]);
      }
      //取出的数值项,保存在此数组
      var return_array = new Array();
      for (var i = 0; i < num; i++) {
        //判断如果数组还有可以取出的元素,以防下标越界
        if (temp_array.length > 0) {
          //在数组中产生一个随机索引
          var arrIndex = Math.floor(Math.random() * temp_array.length);
          //将此随机索引的对应的数组元素值复制出来
          return_array[i] = temp_array[arrIndex];
          //然后删掉此索引的数组元素,这时候temp_array变为新的数组
          temp_array.splice(arrIndex, 1);
        } else {
          //数组中数据项取完后,退出循环,比如数组本来只有10项,但要求取出20项.
          break;
        }
      }
      return return_array;
    },
    // 课程点击 跳转课程详情页
    // goToClassDetail (item) {
    //   let that = this
    //   that.goAppsDetailsBeforeLogin().then(() => {
    //     window.open(`${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.id}&category_name=${item.category_name}&cm_id=${item.id}`)
    //   })
    // },
    // 素材点击 跳转课程详情页
    goToMaterialDetail (item) {
      // window.open(`${services.YXT_GW}/school/class/${item.course_id}?cmid=${item.id}`, "_blank");
      let that = this
      that.goAppsDetailsBeforeLogin().then(() => {
        window.open(`${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.course_id}&cm_id=${item.id}`)
        // window.open(`${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue`)
      })
    },
    moseHover (index) {
      this.hoverZcwj = index
    }

  }
}
</script>

<style scoped lang="less">
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
.first-bg-box {
  background: url('../../assets/image/whrjgczyxy/index/bg1.jpg') no-repeat
    center;
  background-size: cover;
  width: 100%;
}
.common-title {
  text-align: center;
  .left-title {
    font-size: 22px;
    font-weight: 600;
    color: #960202;
    line-height: 44px;
    height: 44px;
    width: 200px;
    height: 44px;
    display: inline-block;
    background: url('../../assets/image/whrjgczyxy/index/title-little.png')
      no-repeat center;
    background-size: 100% auto;
  }
  .right-more {
    font-size: 14px;
    color: #827d7d;
    margin-top: 11px;
    cursor: pointer;
    float: right;
    display: inline-block;
    &:hover {
      color: #ce0303;
    }
  }
}
.common-title.sfkc {
  margin-bottom: 30px;
  .left-title {
    width: 200px;
    height: 44px;
    background: url('../../assets/image/whrjgczyxy/index/title-little.png')
      no-repeat center;
    background-size: 100% auto;
  }
}
.common-title.zcwj {
  margin: 32px 0 34px;
}
.common-title.pxyt {
  margin: 32px 0 34px;
}
.common-title.jsdt {
  margin: 0 0 34px 0;
}
.common-title.mstd {
  margin: 30px 0 34px;
}
.class-lists {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0 0;
  text-align: left;
}
.class-box {
  width: 285px;
  // height: 216px;
  background: #fff;
  border: 1px solid #ececec;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 30px;
  border-radius: 8px;
  &:hover {
    box-shadow: 1px 2px 7px #ececec;
    transition: 0.3s box-shadow;
    .txt .title {
      color: #364c7e;
    }
  }
  .link-box {
    display: inline-block;
    width: 100%;
  }
  .img {
    height: 160px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    img {
      width: 100%;
      height: auto;
      margin: 0 auto;
      transition: all 0.8s;
    }
  }
  &:hover .img img {
    transform: scale(1.1);
  }
  .txt {
    padding: 15px 12px;
    width: 100%;
    box-sizing: border-box;
    .title {
      font-size: 14px;
      line-height: 20px;
      height: 40px;
      color: #3a3a3a;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .num {
      font-size: 12px;
      color: #959595;
      margin-top: 2px;
      .iconduoren {
        font-size: 17px;
        margin-top: 7px;
        vertical-align: -1px;
        margin-right: 5px;
        color: #d5d5d5;
      }
      .courseFont {
        width: 12px;
        height: 13px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: -1px;
        background-image: url('../../assets/image/jsckzyxy/times-icon.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
  }
}
.class-lists .sub-visibility {
  flex: 0 0 220px;
  display: inline-block;
  overflow: visible;
}
.sdcm-container-box {
  .sdcm-banner-box {
    width: 100%;
    height: 400px;
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 18px;
    }
    ::v-deep .swiper-button-prev {
      width: 23px;
      height: 23px;
      background-size: cover;
      background-image: url('../../assets/image/sdcmzyxy/index-prev.png');
    }

    ::v-deep .swiper-button-next {
      width: 23px;
      height: 23px;
      background-size: cover;
      background-image: url('../../assets/image/sdcmzyxy/index-next.png');
    }
    ::v-deep .swiper-pagination .swiper-pagination-bullet {
      width: 9px;
      height: 9px;
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 50%;
    }
    ::v-deep .swiper-pagination .swiper-pagination-bullet-active {
      background: #931a14;
      border: 1px solid #931a14;
    }
    .eol-swiper-box {
      height: 400px;
    }
    .banner-item {
      width: 100%;
      height: 400px;
      background-size: cover;
      cursor: pointer;
      background-position: 50%;
    }
  }
  .sfkc-container {
    width: 100%;
    // padding-top: 35px;
    padding: 35px 0 50px 0;
    background: url('../../assets/image/whrjgczyxy/index/bg2.jpg') no-repeat
      center;
    background-size: cover;
    .list-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .sub-visibility {
        flex: 0 0 285px;
        display: inline-block;
        overflow: visible;
      }

      .item {
        margin-bottom: 22px;
        overflow: hidden;
        cursor: pointer;
        &:first-child {
          cursor: pointer;
        }
        border-radius: 2px;
        transition: box-shadow 0.3s;
        &:hover {
          box-shadow: 0px 0px 8px 0px rgba(182, 182, 182, 0.44);
          .thumb-image {
            transform: scale(1.1);
          }
          .description {
            .title {
              .text {
                color: #931a14;
              }
            }
          }
        }
        .thumb-outer {
          width: 285px;
          height: 160px;
          border-radius: 2px 2px 0px 0px;
          overflow: hidden;
        }
        .thumb-image {
          width: 285px;
          height: 160px;
          border-radius: 2px 2px 0px 0px;
          background-size: cover;
          background-position: center;
          transition: all 0.8s;
          &::before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            // background-color: rgba(14, 14, 14, 8%);
          }
        }
        .description {
          padding: 12px 15px 14px 0;
          width: 285px;
          background: #fffeff;
          border: 1px solid #e8e8e8;
          border-radius: 0px 0px 4px 4px;
          height: 80px;
          .title {
            cursor: pointer;
            display: flex;
            .line {
              width: 4px;
              height: 15px;
              background: #931a14;
              border-radius: 0px 4px 4px 0px;
              margin-right: 11px;
              margin: 5px 11px 0 0;
            }
            .text {
              flex: 1;
              font-size: 16px;
              font-weight: bold;
              color: #262626;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .author {
            display: inline-block;
            padding: 0 35px 0 14px;
            height: 22px;
            line-height: 22px;
            background: linear-gradient(90deg, #fff5e7 0%, #fdf7f3 100%);
            border-radius: 0px 11px 11px 0px;
            font-size: 14px;
            color: #eb9d51;
            margin-top: 6px;
          }
        }
      }
    }
  }

  .zcwj-list {
    display: flex;
    .left-article {
      border: 1px solid #e8e8e8;
      width: 476px;
      .item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f5f3ee;
        padding: 20px 10px;
        height: 83px;
        &.active,
        &:hover {
          background-color: #a82b17;
          .title {
            color: #fff;
            &:hover {
              color: #fff;
              font-weight: bold;
            }
          }
          .month-day {
            background: #faf7f4;
            font-weight: 400;
            color: #c52922;
          }
        }
        &:last-child {
          border-bottom: none;
        }
        .month-day {
          width: 69px;
          height: 24px;
          background: #c52922;
          border-radius: 0px 12px 12px 0px;
          text-align: center;
          line-height: 24px;
          font-size: 13px;
          font-weight: bold;
          color: #ffffff;
          margin-right: 12px;
        }
        .title {
          transition: all 0s;
          cursor: pointer;
          flex: 1;
          line-height: 22px;
          font-size: 16px;
          color: #262626;
          display: flex;
          align-items: center;
          height: 44px;
          &:hover {
            color: #931a14;
            font-weight: bold;
          }
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .right-group {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 32px;
      .item {
        border-radius: 2px;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          .description {
            .article-info {
              .title {
                color: #931a14;
              }
            }
          }
          .thumb-image {
            transform: scale(1.1);
          }
        }
        .thumb-outer {
          width: 334px;
          height: 188px;
          overflow: hidden;
        }
        .thumb-image {
          width: 334px;
          height: 188px;
          background-size: cover;
          border-radius: 2px 2px 0 0;
          background-position: center;
          transition: all 0.8s;
          &::before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            // background-color: rgba(14, 14, 14, 8%);
          }
        }
        .description {
          width: 334px;
          height: 148px;
          background: #ffffff;
          padding: 16px 12px 21px 15px;
          border: 1px solid #f3ecea;
          border-top: 0;
          .article-info {
            height: 75px;
            .title {
              cursor: pointer;
              font-size: 16px;
              font-weight: bold;
              color: #262626;
              line-height: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
            .date {
              font-size: 12px;
              font-weight: bold;
              color: #960202;
              margin: 7px 0 8px;
            }
          }

          .intro {
            font-size: 14px;
            color: #a2a2a2;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
  .pxyt-container {
    padding-bottom: 33px;
    .list-box {
      display: flex;
      justify-content: space-between;
      .item {
        cursor: pointer;
        border-radius: 2px;
        &:hover {
          .description {
            .title {
              color: #931a14;
            }
          }
          .thumb-image {
            transform: scale(1.1);
          }
        }
        .thumb-outer {
          width: 285px;
          height: 160px;
          overflow: hidden;
        }
        .thumb-image {
          border-radius: 2px 2px 0 0;
          width: 285px;
          height: 160px;
          background-size: cover;
          background-position: center;
          transition: all 0.8s;
          &::before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: rgba(14, 14, 14, 8%);
          }
        }
        .description {
          width: 285px;
          height: 134px;
          padding: 18px 7px 0 14px;
          background: #ffffff;
          border: 1px solid #e8e8e8;
          .title {
            height: 38px;
            font-size: 16px;
            font-weight: bold;
            color: #262626;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-height: 20px;
          }
          .date {
            font-size: 12px;
            color: #c3c3c3;
            padding: 14px 0 8px;
          }
          .intro {
            font-size: 14px;
            color: #acacac;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
      }
      .sub-visibility {
        flex: 0 0 285px;
        display: inline-block;
        overflow: visible;
      }
    }
  }
  .jsdt-container {
    .list-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        display: flex;
        // align-items: center;
        margin-bottom: 18px;
        position: relative;
        cursor: pointer;
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 400px;
          height: 1px;
          background: #f0ece7;
        }
        .thumb-outer {
          width: 160px;
          height: 90px;
          overflow: hidden;
          margin-right: 18px;
          border-radius: 2px;
        }
        .thumb-image {
          width: 160px;
          height: 90px;
          background-size: cover;
          background-position: center;
          transition: all 0.8s;
          cursor: pointer;
        }
        .right-content {
          flex: 1;
          .title {
            color: #262626;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            width: 390px;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            margin: 12px 0 8px;
            cursor: pointer;
          }
          .description {
            display: flex;
            align-items: center;
            .time {
              width: 82px;
              height: 18px;
              background: #c52922;
              border-radius: 9px 9px 9px 0px;
              font-size: 12px;
              color: #fdf7ef;
              text-align: center;
              line-height: 18px;
              margin-right: 5px;
            }
            .intro {
              width: 299px;
              font-size: 14px;
              color: #acacac;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
        &:hover {
          .right-content {
            .title {
              color: #960202;
            }
          }
          .thumb-image {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  // .mstd-container {
  //   .body-box {
  //     display: flex;
  //     justify-content: space-between;
  //     flex-wrap: wrap;
  //     .item {
  //       border-radius: 0px 40px 0px 0px;
  //       margin-bottom: 66px;
  //       width: 133px;
  //       height: 200px;
  //       background: #ebedef;
  //       cursor: pointer;
  //       position: relative;
  //       .thumb-image {
  //         width: 100%;
  //         height: 183px;
  //         background-size: cover;
  //         position: absolute;
  //         top: -25px;
  //         left: 50%;
  //         transform: translateX(-50%);
  //       }
  //       .name {
  //         width: 100%;
  //         height: 42px;
  //         background-color: #86261d;
  //         // background-image: url('../../assets/image/sdcmzyxy/index-teach.png');
  //         background-size: cover;
  //         text-align: center;
  //         line-height: 42px;
  //         font-size: 18px;
  //         color: #ffffff;
  //         margin: 0 auto;
  //         position: absolute;
  //         bottom: -0;
  //         left: 50%;
  //         transform: translateX(-50%);
  //         z-index: 10;
  //         font-weight: bold;
  //         // &::before {
  //         //   content: '';
  //         //   display: inline-block;
  //         //   width: 100%;
  //         //   height: 5px;
  //         //   background: #fcead2;
  //         //   border-radius: 4px 4px 0px 0px;
  //         //   position: absolute;
  //         //   bottom: -5px;
  //         //   left: 50%;
  //         //   transform: translateX(-50%);
  //         //   z-index: 1;
  //         // }
  //       }
  //     }
  //     .sub-visibility {
  //       flex: 0 0 270px;
  //       display: inline-block;
  //       overflow: visible;
  //     }
  //   }
  // }
  .mstd-container {
    padding-bottom: 40px;
    .body-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        cursor: pointer;
        margin-bottom: 25px;
        .thumb-image {
          width: 172px;
          height: 229px;
          background-size: cover;
          display: inline-block;
        }
        .description {
          display: inline-block;
          width: 200px;
          height: 229px;
          background: #931a14;
          vertical-align: top;
          padding: 20px 15px 0;
          position: relative;
          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            left: -8px;
            top: 28px;
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-right: 8px solid #931a14;
            border-bottom: 8px solid transparent;
          }
          &.right {
            &::before {
              left: unset;
              right: -8px;
              top: 23px;
              transform: rotateY(180deg);
            }
          }
          .name {
            font-size: 17px;
            color: #ffffff;
            text-align: center;
          }
          .split {
            width: 159px;
            height: 7px;
            background-image: url('../../assets/image/sdcmzyxy/teach-split.png');
            background-size: cover;
            margin: 7px 0 10px;
          }
          .position {
            font-size: 14px;
            font-weight: bold;
            color: #fefefe;
            line-height: 16px;
            text-align: center;
            margin-bottom: 12px;
            .position-item {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .intro {
            font-size: 12px;
            color: #ffe4b6;
            line-height: 20px;
            opacity: 0.84;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
          }
        }
      }
    }
  }
}
.body-bg-box {
  background: #f9f7f3;
  padding: 32px 0 33px;
}
</style>