const redirect = (e, path, _blank) => {
  if (_blank) {
    let routeUrl = e.$router.resolve({
      path: path
    });
    window.open(routeUrl.href, '_blank');
  } else {
    e.$router.push({ path: path })
  }

}

// 动态加载js
const loadScript = (url, callback) => {
  if (typeof document !== "undefined") {
    var script = document.createElement("script");
  }
  script.type = "text/javascript";
  if (typeof callback !== "undefined") {
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = function () {
        callback();
      };
    }
  }
  script.src = url;
  if (typeof document !== "undefined") {
    document.head.appendChild(script);
  }
}

const loadStatistics = function () {
  if (typeof document !== "undefined") {
    var script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.src = "https://v1.cnzz.com/z_stat.php?id=1280977381&web_id=1280977381";
    var outer = document.createElement("div");
    outer.style.height = '1px';
    outer.style.width = '1px';
    outer.style.overflow = 'hidden';
    outer.appendChild(script1);

    var script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = "https://hm.baidu.com/hm.js?37c45bb62265fe426c091165b353b74b";

    document.body.append(outer);
    document.body.append(script2);
  }
}

// // 设置标题
// const setTitle = (title) => {
//   if (title && title.indexOf("江苏财会职业学院") > -1) {
//     title = '江苏财会职业学院-课程思政教学研究中心'
//   } else if (title && title.indexOf("山东传媒职业学院" > -1)) {
//     title = '山东传媒职业学院-课程思政教学研究中心'
//   } else {
//     title = '思政资源库 - 中国教育在线';
//   }
//   document.title = title
// }

// 设置标题
let school_code
const setTitle = (title) => {
  school_code = localStorage.getItem("schoolCode")
  if (school_code == sdcm_code || school_code == whrj_code) {
    document.title = title ? title + "-课程思政云平台" : "课程思政云平台"
  } else {
    document.title = title ? title + "-思政资源库" : "思政资源库"
  }
}


/**
 * 构造树型结构数据
 * @param {*} source 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
const transTreeData = (source, id, parentId, children, rootId) => {
  id = id || 'id'
  parentId = parentId || 'parentId'
  children = children || 'children'
  rootId = rootId || 0
  const cloneData = JSON.parse(JSON.stringify(source))// 对源数据深度克隆
  return cloneData.filter(father => {
    const branchArr = cloneData.filter(child => father[id] == child[parentId])// 返回每一项的子级数组
    branchArr.length > 0 ? father[children] = branchArr : delete father[children]// 如果存在子级，则给父级添加一个children属性，并赋值
    return father[parentId] == rootId // 返回第一层
  })
}

// 判断是否是手机端访问
const isMobile = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

// 判断是否是谷歌浏览器
const bowerUserAgent = () => {
  var userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
  let isChrome = false
  if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") == -1) {
    isChrome = true
  } else {
    isChrome = false
  }
  return isChrome
}

// 需要校验登录状态的路由  "/szztk/ylzl", "/szztk/yzzsjzj",
const whiteList = ["/szysk/details", "/szzyk/details", "/szztk/details", "/szztk/xfrw", "/szztk/ssjt", "/szztk/xxys", "/szztk/xfrw/sdkm", "/szztk/xfrw/sdkm/details/1", "/szztk/detail", "/whrjgczyxy/syzn","/zy/szktz"];
// 判断路由是否包含有whiteList
const pathRouteName = (path) => {
  var isName = false
  for (var i = 0; i < whiteList.length; i++) {
    if (path.indexOf(whiteList[i]) > -1) {
      isName = true
      break;
    }
  }
  return isName
}

// 获取浏览器地址栏中的学校code
const getUrlSchoolCode = (url) => {
  let code = ''
  if (url) {
    let arr = url.split("/");
    code = arr[1]
  }
  return code
}
const setSwiperList = (arr) => {
  let newArr = [];
  let num = Math.ceil(arr.length / 6) - 1;
  for (let i = 0; i <= num; i++) {
    if (i == num) {
      newArr.push(arr.slice(i * 6));
    } else {
      newArr.push(arr.slice(i * 6, i * 6 + 6));
    }
  }
  return newArr;
}
const sdcm_code = '100017'; // 山东传媒职业学院code
const zjgs_code = '100023'; // 浙江工商职业技术学院code
const whrj_code = '100024'; // 武汉软件工程职业学院code
const jyzx_code = '100004'; // 中国教育在线（思政）code
const zjjyzx_code = '100026'; // 中国教育在线（浙江）code
const fjjyzx_code = '100030'; //中国教育在线（福建）
const gdkm_code = '100033'; // 广东科贸职业学院
const tsgy_code = '100036'; // 唐山工业职业技术学院

export default {
  redirect,
  loadScript,
  setTitle,
  loadStatistics,
  transTreeData,
  isMobile,
  whiteList,
  getUrlSchoolCode,
  pathRouteName,
  bowerUserAgent,
  setSwiperList,
  sdcm_code,
  zjgs_code,
  whrj_code,
  jyzx_code,
  zjjyzx_code,
  fjjyzx_code,
  gdkm_code,
  tsgy_code
}
