<template>
  <div class="jxsy-list">
    <div class="jxsy-tit"></div>
    <div class="jxsy-content">
      <div class="jxsy-side">
        <div class="jxsy-nav" :class="{isFixed: isFixed}">
          <div
            class="nav-item"
            v-for="(item, ind) in navList"
            :key="ind"
            :class="{ active: item.column == activeNav }"
            @click="changeNav(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>

      <div class="jxsy-con">
        <div class="con-list">
          <div
            class="jxsy-item"
            v-for="(item, ind) in jxsyList"
            :key="ind"
            @click="toDetail(item)"
          >
            <div class="item-image">
              <div
                :style="{
                  backgroundImage: item.cover ? `url(${item.cover})` : '',
                }"
              ></div>
            </div>
            <div class="item-title" v-html="item.title"></div>
          </div>
        </div>
        <div v-if="pageNum > 1" class="pagination">
          <a-pagination
            v-model="page"
            :total="total"
            :page-size="18"
            @change="onPagesChange"
            size="small"
            :item-render="itemRender"
          />
        </div>
        <div v-if="jxsyList.length <= 0 && !loading" class="noData-box">
          <img src="../../../assets/image/jsckzyxy/empty.png" alt="" />
          <div class="text">暂无相关内容</div>
        </div>
      </div>
    </div>
    <div class="spin-con" v-if="loading">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import services from "../../../assets/js/services";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      navList: [
        {
          title: "全部",
          column: "中华智慧 匠心溯源",
        },
        {
          title: "尚巧求精 - 卓绝典范",
          column: "尚巧求精 卓绝典范",
        },
        {
          title: "以民为本 - 兴利除害",
          column: "以民为本 兴利除害",
        },
        {
          title: "尊师重教 - 德艺兼求",
          column: "尊师重教 德艺兼求",
        },
        {
          title: "与古为新 - 实业救国",
          column: "与古为新 实业救国",
        },
      ],
      activeNav: "中华智慧 匠心溯源",
      jxsyList: [],
      page: 1,
      total: 0,
      pageNum: 0,
      loading: true,
      isFixed: false,

    };
  },
  async created() {
    let res = await this.getAppsLoginUserInfo();
    if (res.code != "0000") {
      this.$message.info("请先登录！");
      this.$router.push({ path: `/${this.school_code}/gjjs/gjjsIndex` });
    }
    if (this.$route.query.ind > -1) {
      this.activeNav = this.navList[this.$route.query.ind].column;
    }
    this.getjxsyList();
  },
  async mounted() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 345) {
        this.isFixed = true;
      }else {
        this.isFixed = false;

      }
    });
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin", "getAppsLoginUserInfo"]),
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    async getjxsyList() {
      try {
        this.loading = true;
        let res = await services.getAreaSzztkLists({
          page: this.page,
          length: 18,
          count: 0,
          school_code: this.school_code,
          special_id: this.specialId,
          kind: "1",
          column_name: this.activeNav,
          is_sub_category: 1,
        });
        if (res.code == "0000" && res.data.items && res.data.items.length > 0) {
          this.jxsyList = res.data.items;
          this.pageNum = res.data.page_num;
          this.total = res.data.total;
        } else {
          this.jxsyList = [];
          this.pageNum = 0;
          this.total = 0;
        }
      } catch (error) {
      } finally {
        window.scrollTo(0, 0);
        this.loading = false;
      }
    },
    onPagesChange(val) {
      this.page = val;
      this.getjxsyList();
    },
    changeNav(item) {
      if (item) {
        this.activeNav = item.column;
        this.page = 1;
        this.getjxsyList();
      }
    },
    toDetail(item) {
      if (item) {
        this.goAppsDetailsBeforeLogin().then(() => {
          this.$router.push({
            path: `/${this.school_code}/gjjs/detail`,
            query: {
              id: item.id,
              column_id: item.column_id,
              columnPath: "jxsy",
            },
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.jxsy-list {
  background-image: url("../../../assets/image/gjjs/jxsy/jxsy-bottombg.png");
  padding-bottom: 160px;
  padding-top: 223px;
  position: relative;
  overflow: hidden;
  &::before {
    width: 100vw;
    height: 1100px;
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    background-size: cover;
    background-image: url("../../../assets/image/gjjs/jxsy/jxsy-topbg.png");
    z-index: 0;
  }
  .jxsy-tit {
    width: 1920px;
    height: 371px;
    background-image: url("../../../assets/image/gjjs/jxsy/jxsy-tit.png");
    background-size: cover;
    position: absolute;
    z-index: 1;

    top: 74px;
    left: 50%;
    transform: translateX(-50%);
  }
  .jxsy-content {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    // z-index: 1;
    justify-content: space-between;
    display: flex;
    .jxsy-side {
      width: 271px;
      .jxsy-nav {
        width: 271px;
        height: 623px;
        background-image: url("../../../assets/image/gjjs/jxsy/jxsy-nav.png");
        background-size: cover;
        margin-top: 96px;
        padding-top: 33px;
        position: relative;
        z-index: 11;
        .nav-item {
          margin: 0 auto;
          width: 243px;
          height: 74px;
          border-bottom: 1px dashed #f79c85;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #fff4e9;
          text-align: center;
          line-height: 73px;
          background-size: cover;
          cursor: pointer;
          &:last-child {
            border-bottom: 0;
          }
          &.active {
            background-image: url("../../../assets/image/gjjs/jxsy/jxsy-nav-active.png");
            color: #bb0007;
            border-bottom: 1px dashed transparent;
          }
        }
        &.isFixed {
          position: fixed;
          top: 46px;
          margin-top: 0;
        }
      }
    }

    .jxsy-con {
      width: 907px;
      padding: 106px 34px 24px;
      background-color: #fff;
      min-height: 1000px;
      .con-list {
        display: flex;
        flex-wrap: wrap;
        .jxsy-item {
          position: relative;
          z-index: 111;
          width: 266px;
          height: 192px;
          margin-left: 20px;
          margin-bottom: 22px;
          cursor: pointer;
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          .item-image {
            width: 100%;
            height: 150px;
            overflow: hidden;
            background: #b1000e;
            div {
              width: 100%;
              height: 100%;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              transition: all 0.3s;
              background-image: url("../../../assets/image/gjjs/index/nav-index/default-bg.png");
            }
            &:hover {
              div {
                transform: scale(1.1);
              }
            }
          }
          .item-title {
            width: 100%;
            height: 42px;
            background: #f9f7f4;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 42px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 14px;
          }
        }
      }
      .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        ::v-deep.ant-pagination {
          .ant-pagination-prev,
          .ant-pagination-next {
            margin: 0 15px;
            a {
              color: #cb4d2e;
            }
          }
          .ant-pagination-item {
            margin: 0 5px;
            border-radius: 0;
            a {
              color: #cb4d2e;
            }
            &.ant-pagination-item-active {
              background-color: #cb4e2e;
              border: 1px solid transparent;
              a {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .spin-con {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>