import services from '../assets/js/services'

export default {
  state: {
    keyWord: '',
    searchSzzykData: [], // 思政资源库列表页数据
    searchSzzykNums: 0, // 思政资源库列表总页数
    szzykPageTotal: 0,
    elementPageTotal: 0,
    pageNum: 10,
    searchElementData: [],
    loading: false, // 加载状态
    allCount: 0, // 总条数
    totalResult: false, // 是否总数有结果
    szztkTotal: 0, // 思政专题库总条数
    szztkData:[], // 思政专题库列表页数据
    szztkSize: 12, // 思政专题库分页数量
    classData: [], // 培训资源列表数据
    classTotal: 0, // 培训资源总条数
    classSize: 15, // 培训资源分页数量
    areaZtTotal: 0, // 区域专题资源总条数
    areaZtSize: 10, // 区域专题资源分页数量
    areaZtData: [],  // 区域专题资源列表数据 齐鲁
  },
  getters: {
  },
  actions: {
    // 获取搜索页面思政资源库 列表页
    getSearchSzzykData (context, payload) {
      context.state.loading = true
      context.commit('setSearchSzzykData', {
        'data': null
      })
      return services.getSzzykListData(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSearchSzzykData', {
            'data': res.data ? res.data : null
          })
        }
        context.state.loading = false
        return res;
      })
    },
    // 获取思政资源库- 筛选 - 思政元素列表
    getSearchSzyskElement (context, payload) {
      return services.getSzzykElement(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSearchElementData', {
            'data': res.data && res.data.items ? res.data : []
          })
        }
        return res;
      })
    },
    getSzzykTotal (context, payload) {
      return services.getSzzykListData(payload).then(res => {
        if (res.code == '0000') {
          context.commit("setSzzykTotal", res)
        }
        return res;
      })
    },

    // 获取思政专题库列表
    getSearchSzztkList (context, payload) {
      context.state.loading = true
      return services.getSzztkList(payload).then(res => {
        context.commit('setSearchSzztkList', res.data || [])
      })
    },

    // 获取思政专题库总数
    getSearchSzztkTotal (context, payload) {
      return services.getSzztkList(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSearchSzztkTotal', res.data || [])
        }
      })
    },
    //  获取培训资源课程列表
    getSearchClassLists(context, payload) {
      return services.getszztyxCourse(payload).then((res) => {
        if (res.code === '0000') {
          context.commit('setClassData', res.data || {})
        }
      })
    },
    //  获取培训资源课程列表
    getSearchAreaLists(context, payload) {
      return services.getAreaSzztkLists(payload).then((res) => {
        if (res.code === '0000') {
          if(payload.count == 1){
            context.commit('setAreaTotal', res.data || {})
          } else {
            context.commit('setAreaData', res.data || {})
          }
        }
      })
    },
  },
  mutations: {
    setSearchKeyWord (state, payload) {
      state.keyWord = payload.data;
    },
    setSearchSzzykData (state, payload) {
      state.searchSzzykData = payload.data ? payload.data.items : [];
    },
    setSearchElementData (state, payload) {
      state.searchElementData = payload.data.items;
      state.elementPageTotal = payload.data.total ? payload.data.total : 0
    },
    setSzzykTotal (state, payload) {
      state.searchSzzykNums = state.elementPageTotal + payload.data.page_num ? payload.data.page_num : 0;
      state.szzykPageTotal = state.elementPageTotal + payload.data.total ? payload.data.total : 0
      state.allCount = payload.data.total + state.elementPageTotal
      state.totalResult = true
    },
    resetSzzykTotal (state) {
      state.searchSzzykNums = 0
      state.szzykPageTotal = 0
      state.elementPageTotal = 0
      state.allCount = 0
      state.totalResult = false
    },
    // 设置思政专题库列表
    setSearchSzztkList (state, payload) {
      state.szztkData = payload.items
    },
    // 设置思政专题库总数
    setSearchSzztkTotal (state, payload) {
      state.szztkTotal = payload.total
    },
    setClassData(state, payload) {
      state.classData = payload.items
      state.classTotal = payload.total
    },
    setAreaData(state, payload) {
      state.areaZtData = payload.items
    },
    setAreaTotal(state, payload) {
      state.areaZtTotal = payload.total
    },
  }
}