import Vue from 'vue'
import Vuex from 'vuex'
// import modulesLogin from './login'
import modulesHome from './home'
import modulesSzzyk from './szzyk'
import modulesSzysk from './szysk'
import modulesSearch from './search'
import modulesClass from './class'
import modulesSzztk from './szztk'
import appsLogin from './appsLogin'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  modules: {
    // login: modulesLogin,
    home: modulesHome,
    szzyk: modulesSzzyk,
    szysk: modulesSzysk,
    search: modulesSearch,
    class: modulesClass,
    szztk: modulesSzztk,
    appsLogin: appsLogin,
  }
})

export default store