<template>
  <div class="szysk">
    <template v-if="school_code == utils.whrj_code">
      <WhrjHeader />
    </template>
    <template v-else>
      <Header />
      <Navbar />
    </template>
    <div class="header"></div>
    <div class="content-box">
      <div class="top-filter">
        <div class="major-box">
          <div class="major-label">专业大类：</div>
          <div class="major-type">
            <div :class="['major-item',item.id == subjectId?'active':'']" v-for="item,index in category.subject" :key="index" @click="majorClick(item,index)">{{ item.name }}</div>
          </div>
        </div>
        <div class="source-box">
          <div class="source-label">资源类型：</div>
          <div class="source-type">
            <div :class="['source-item',index == typeId?'active':'']" v-for="item,index in category.type" :key="index" @click="resTypeClick(item,index)">{{ item }}</div>
          </div>
        </div>
      </div>

      <template>
        <template v-if="classData&&classData.length>0">
          <div class="classic-list">
            <div v-for="item,index in classData" :key="index" class="classic-item" @click="courseDetail(item)">
              <div class="img-top">
                <div v-if="item.level" :class="['label', item.level == 0?'':item.level == 1?'guo':item.level==2?'sheng':'xiao']"></div>
                <div class="imgs-bg" :style="`background-image: url('${item.cover}');`"></div>
              </div>
              <div class="txt-bottom">
                <div class="title">{{item.name}}</div>
                <div :class="['author',!item.teacher?'hide':'']">{{ item.teacher ? item.teacher :' 暂无' }}</div>
                <div class="tabs-box">
                  <div class="tab-item" v-for="resItem,resIndex in item.resource" :key="resIndex">{{ resItem.type_name }}</div>
                </div>
              </div>
            </div>

            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
          </div>
          <div class="pages container" style="text-align: center;">
            <a-pagination v-model="page" :pageSize="page_num" :show-total="total => `共${totalPage}页`" :total="total" @change="changePage" />
          </div>
        </template>
        <div v-else class="noData-box">
          <img src="../../assets/image/jsckzyxy/empty.png" alt="">
          <div class="text">暂无相关内容</div>
        </div>

      </template>

    </div>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import "../../assets/css/home.less";
import utils from "../../assets/js/utils";
import { mapState, mapActions } from "vuex";
import Header from "../../components/Header.vue";
import WhrjHeader from "../../components/WhrjHeader.vue";
import services from "../../assets/js/services"

export default {
  name: "Szsfkc",
  components: {
    Navbar,
    Header,
    WhrjHeader,
  },
  data () {
    return {
      utils,
      school_code: localStorage.getItem("schoolCode"),
      classData: [],// 列表数据
      category: {}, // 资源类型|专业大类 分类数据
      subjectId: "", // 专业id
      typeId: "", // 资源类型id
      page: 1, // 当前页
      page_num: 12, // 每页显示12条
      total: 0, // 数据总条数
      totalPage: 0,//总页数
      listData: [],
    };
  },
  computed: {
    ...mapState({
      // szyskElementData: state => state.szysk.szyskElementData
    }),
  },
  async mounted () {
    this.initParams()

    await this.getSfkcScreen()
    await this.getList()
  },
  methods: {
    ...mapActions(["getSzyskElement", "goAppsDetailsBeforeLogin"]),
    // 跳转到详情页
    courseDetail (item) {
      window.open(`/${this.school_code}/zy/szsfkc/detail?id=${item.id}`)
    },
    // 获取筛选项
    getSfkcScreen () {
      services.sfkcScreen({ school_code: this.school_code }).then(res => {
        if (res && res.data) {
          this.category = res.data
          this.category.subject.unshift({ name: "全部", id: "" })
          this.category.type['0'] = '全部'
        }
      })
    },
    // 专业筛选
    majorClick (item, index) {
      this.subjectId = item.id
      window.open(`/${this.school_code}/zy/szsfkc?&big_id=${this.subjectId}&page=${this.page}&length=${this.page_num}&type=${this.typeId}`, "_self")
    },
    // 资源类型筛选
    resTypeClick (item, index) {
      this.typeId = index
      window.open(`/${this.school_code}/zy/szsfkc?&big_id=${this.subjectId}&page=${this.page}&length=${this.page_num}&type=${this.typeId}`, "_self")
    },
    // 获取列表数据
    getList () {
      services.sfkcList({ school_code: this.school_code, big_id: this.subjectId, page: this.page, length: this.page_num, type: this.typeId }).then(res => {
        this.total = res.data.total
        this.classData = res.data.items
        this.totalPage = res.data.page_num
      })
    },
    // 初始化url参数
    initParams () {
      let params = this.$route.query
      this.subjectId = params.big_id ? params.big_id : ''
      this.typeId = params.type ? params.type : 0
    },
    // 修改页码
    changePage () {
      this.getList()
    }
  },

};
</script>
<style lang="less" scoped>
.szysk {
  .header {
    width: 100%;
    height: 140px;
    background: url('../../assets/image/szsfkc/banner.png') no-repeat center
      center;
    background-size: cover;
    position: relative;
    margin-bottom: 20px;
  }
  .content-box {
    width: 1200px;
    margin: 0 auto 40px;
    .top-filter {
      background: #ffffff;
      border: 1px solid #f3f4f7;
      border-radius: 0px 1px 0px 0px;
      margin-bottom: 25px;
    }
    .major-box {
      padding: 14px 24px 9px;
      box-sizing: border-box;
      background: #fef7f7;
      display: flex;
      .major-label {
        width: 70px;
        line-height: 37px;
        font-size: 14px;
        color: #11233f;
      }
      .major-type {
        flex: 1;
        .major-item {
          color: #11233f;
          display: inline-block;
          padding: 8px 10px;
          border-radius: 2px;
          margin: 0 10px 5px;
          cursor: pointer;
          &.active,
          &:hover {
            background: #dc3034;
            color: #fff;
          }
        }
      }
    }
    .source-box {
      padding: 14px 24px 9px;
      box-sizing: border-box;
      display: flex;
      .source-label {
        font-size: 14px;
        line-height: 37px;
        color: #676f7f;
        width: 70px;
      }
      .source-type {
        flex: 1;
        .source-item {
          color: #676f7f;
          display: inline-block;
          padding: 8px 10px;
          border-radius: 2px;
          margin: 0 10px 5px;
          cursor: pointer;
          &.active,
          &:hover {
            color: #eb0012;
          }
        }
      }
    }
  }
}
.classic-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
}
.classic-item {
  width: 276px;
  background: #fff;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 1px 2px 7px #ececec;
    transition: 0.3s box-shadow;
    .txt-bottom .title {
      color: #dc3034;
    }
  }
  .img-top {
    height: 155px;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 10px 0 0 0;
    .label {
      box-shadow: 1px 2px 5px rgba(17, 3, 0, 0.3);
      position: absolute;
      top: 0;
      z-index: 4;
      left: 0;
      width: 88px;
      height: 23px;
      border-radius: 10px 20px 20px 0;
      background-repeat: no-repeat;
      &.guo {
        width: 88px;
        height: 23px;
        background-image: url('../../assets/image/szsfkc/label1.png');
        background-size: 100% auto;
      }
      &.sheng {
        width: 77px;
        height: 23px;
        background-image: url('../../assets/image/szsfkc/label2.png');
        background-size: 100% auto;
      }
      &.xiao {
        width: 77px;
        height: 23px;
        background-image: url('../../assets/image/szsfkc/label3.png');
        background-size: 100% auto;
      }
    }
    .imgs-bg {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      transition: all 0.8s;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  &:hover .img-top .imgs-bg {
    transform: scale(1.1);
  }

  .txt-bottom {
    padding: 12px 15px 12px;
    width: 276px;
    box-sizing: border-box;
    border: 1px solid #f1f2f7;
    border-top: none;
    .title {
      font-size: 14px;
      line-height: 22px;
      height: 40px;
      font-weight: bold;
      color: #38393b;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .author {
      &.hide {
        visibility: hidden;
      }
      font-size: 12px;
      color: #b1b6c7;
      margin: 10px 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &::before {
        content: '';
        width: 15px;
        height: 13px;
        margin-right: 4px;
        display: inline-block;
        vertical-align: -2px;
        background-image: url('../../assets/image/szsfkc/shouke.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
    .tabs-box {
      height: 32px;
      overflow: hidden;
      .tab-item {
        color: #d1802b;
        font-size: 12px;
        padding: 6px 10px 7px;
        margin-right: 4px;
        display: inline-block;
        background: #fefbf4;
      }
    }
  }
}
.classic-list .sub-visibility {
  flex: 0 0 276px;
  display: inline-block;
  overflow: visible;
}
</style>