<template>
  <div class="szysk">
    <template v-if="school_code == utils.whrj_code">
      <WhrjHeader />
    </template>
    <template v-else>
      <Header></Header>
      <Navbar />
    </template>
    <div class="header"></div>
    <div class="body">
      <div class="main-cover">
        <div class="thumb-image" :style="`background-image:url(${detailData.cover})`"></div>
        <div class="description">
          <div :class="['title',detailData.level == 1 ?'level-country':detailData.level ==2?'level-province':detailData.level ==3?'level-school':'']">
            {{detailData.name}}
          </div>
          <div class="category"><span>所属专业 </span> {{ detailData.subject_name || '暂无'}}</div>
          <div class="teacher"><span>授课教师 </span> {{ detailData.teacher ||'暂无' }}</div>
          <div class="browse">{{detailData.num_visit}}人浏览</div>
        </div>
      </div>
      <div class="tabs-panel">
        <div class="tabs-header">
          <div :class="['tabs-header-item',resType == item.type?'active':'']" @click="tabsCurrent(item)" v-for="(item,index) in detailData.resource" :key="index">{{ item.type_name }}</div>
        </div>
        <div class="tabs-body">
          <template>
            <!-- 简介 -->
            <div class="tabs-body-item intro" v-show="resType == 0">
              <div class="intro-box" v-html="detailData.intro || '暂无'">
              </div>
            </div>
            <!-- 教学资源 -->
            <div class="tabs-body-item" v-show="resType != 0">
              <a-spin tip="加载中..." :spinning="spinning">
                <div class="teach-resource">
                  <div class="resource-item" v-for="resItem,resIndex in resData" :key="resIndex">
                    <div class="resource-header">
                      <div class="name">{{ resItem.title | setResourceName }}</div>
                      <div :class="['toggle',resItem.active ?'show':'' ]" @click="toggleList(resItem)">{{ resItem.active ?'收起':'查看详情' }}</div>
                    </div>
                    <div :class="['resource-body',resItem.active?'show':'']">
                      <template v-if="resItem&&!resItem.file_id">
                        <template v-if="typeof resItem.image_url !== 'string'&&resItem.image_url&&resItem.image_url.length>0">
                          <div class="res-item" v-for="subItem,subIndex in resItem.image_url" :key="subIndex">
                            <img @contextmenu.prevent v-lazy="subItem">
                          </div>
                        </template>
                        <template v-else>
                          <div class="res-item">
                            <img @contextmenu.prevent v-lazy="resItem.image_url">
                          </div>

                        </template>
                      </template>
                      <div v-else>
                        <div class="res-item no-bg">
                          <video :id="'tcplayerVideo'+resItem.file_id+resIndex" :data-fileId="resItem.file_id"></video>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </a-spin>

            </div>
          </template>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import "../../assets/css/home.less";
import utils from "../../assets/js/utils";
import services from "../../assets/js/services";
import Header from '../../components/Header.vue';
import WhrjHeader from "../../components/WhrjHeader.vue";
export default {
  name: "SzsfkcDetail",
  components: {
    Navbar,
    Header,
    WhrjHeader
  },
  data () {
    return {
      utils,
      school_code: localStorage.getItem("schoolCode"),
      resType: 0, // 默认选中课程简介
      videoIndex: 0,// 默认展示第一个课程
      detailData: { // 详情数据
      },
      resData: [], // 详情资源数据
      courseId: this.$route.query.id, // 课程ID
      BEIKE_SERVER: services.BEIKE_SERVER,
      flag: false, // 是否有视频,有就初始化TCPlayer
      timeOut: null,// 定时器
      spinning: false
    };
  },
  mounted () {
    this.initDetail()
  },
  methods: {
    // 切换资源tab
    tabsCurrent (item) {
      this.resType = item.type
      // window.open(`/${this.school_code}/zy/szsfkc/detail?id=${this.courseId}&type=${this.resType}`, "_self")
      this.initResDetail()
    },
    // 初始化视频插件
    initVideoFile (elemId, qclude_id, appId) {
      if (elemId && qclude_id && appId) {
        if (document.getElementById(elemId).nodeName != 'VIDEO') return
        utils.loadScript(window.location.origin + "/vod/hls.min.0.12.4.js", () => {
          utils.loadScript(window.location.origin + "/vod/tcplayer.min.js", () => {
            if (typeof window.TCPlayer === "function") {
              window.TCPlayer(elemId, {
                fileID: qclude_id, // 请传入需要播放的视频filID 必须
                controls: true,
                appID: appId, // 请传入点播账号的appID 必须
                width: 800, //视频的显示宽度
                height: 450, //视频的显示高度
                definition: 30,
                autoplay: false,
              });
            }
          })
        })
      }

    },

    // 收起/查看详情下拉
    toggleList (vItem) {
      this.$set(vItem, "active", !vItem.active)
    },
    // 初始化详情数据
    initDetail () {
      // let params = this.$route.query
      // if (params) {
      //   this.resType = params.type ? params.type : 0
      // }
      let that = this
      services.sfkcDetail({ id: this.courseId, school_code: this.school_code }).then(res => {
        if (res.data) {
          this.detailData = res.data
          this.detailData.resource.unshift({
            type: "0",
            type_name: "课程简介"
          })
        } else {
          that.$message.error("课程不存在!")
          that.timeOut = setTimeout(function () {
            that.$router.push(`/${that.school_code}/zy/szsfkc`)
          }, 1000)
        }

      }).then(() => {
        this.initResDetail()
      })
    },
    // 初始化详情资源数据
    initResDetail () {
      let that = this
      if (this.resType != '0') {
        this.spinning = true
        services.sfkcResDetail({ id: this.courseId, school_code: this.school_code, type: this.resType, }).then(res => {
          if(res.data && res.data.length > 0) {
            this.resData = res.data
          console.log(this.resData);
          this.resData.forEach((item, index) => {
            this.$set(this.resData[index], "active", index == 0 ? true : false)
          })
          }else {
            this.resData = []
          }
        
        }).then(() => {
          this.$nextTick(() => {
            if (this.resData?.length > 0) {
              this.resData.forEach(item => {
                if (item.file_id) {
                  that.flag = true
                }
              })
            }
            if (that.flag) {
              let videoElement = document.getElementsByTagName("video"); // 获取页面上所有的video对象
              if (videoElement && videoElement.length > 0) {
                for (let i = 0; i < videoElement.length; i++) {
                  let appid = "1500004122"
                  let domid = videoElement[i].getAttribute("id");
                  let fileid = videoElement[i].getAttribute("data-fileid");
                  if (appid && domid && fileid) {
                    that.$nextTick(() => {
                      that.initVideoFile(domid, fileid, appid);
                    })
                  }
                }
                that.flag = false
              }
            }
            this.spinning = false
          })
        })
      }
    },
  },
  filters: {
    setResourceName (val) {
      let nameArr = val.split(".")
      
      if(val.length > 0) {
        if(nameArr.length > 1) {
          return nameArr.splice(0,nameArr.length - 1).join(".")
        } else {
          return nameArr[0]
        }
      } else {
        return "暂无"
      }
    }
  },
  // 组件销毁,清除定时器
  destroyed () {
    clearTimeout(this.timeOut)
  }
}
</script>
<style lang="less" scoped>
.szysk {
  .header {
    width: 100%;
    height: 140px;
    background: url('../../assets/image/szsfkc/banner.png') no-repeat center
      center;
    background-size: cover;
    position: relative;
  }
  .body {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0 0;
    .main-cover {
      display: flex;
      height: 293px;
      .thumb-image {
        width: 520px;
        height: 100%;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        flex-shrink: 0;
      }
      .description {
        flex: 1;
        background: linear-gradient(180deg, #f0f8ff 0%, #ffffff 100%);
        line-height: 1;
        padding: 27px 20px 0 30px;
        overflow: hidden;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          right: 12px;
          bottom: 0;
          background-image: url('../../assets/image/szsfkc/sfkc.png');
          width: 274px;
          height: 132px;
          background-size: cover;
        }
        .title {
          font-size: 22px;
          font-weight: bold;
          color: #38393b;
          line-height: 34px;
          display: inline-block;
          margin-bottom: 45px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          &.level-country::after {
            content: '';
            display: inline-block;
            width: 99px;
            height: 26px;
            background-image: url('../../assets/image/szsfkc/label1.png');
            border-radius: 13px 13px 13px 0px;
            background-size: cover;
            vertical-align: -4px;
          }
          &.level-province::after {
            content: '';
            display: inline-block;
            width: 86px;
            height: 26px;
            background-image: url('../../assets/image/szsfkc/label2.png');
            border-radius: 13px 13px 13px 0px;
            background-size: cover;
            vertical-align: -4px;
          }
          &.level-school::after {
            content: '';
            display: inline-block;
            width: 86px;
            height: 26px;
            background-image: url('../../assets/image/szsfkc/label3.png');
            border-radius: 13px 13px 13px 0px;
            background-size: cover;
            vertical-align: -4px;
          }
        }
        .category,
        .teacher {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          color: #676f7f;
          &::before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 12px;
            background-size: cover;
            background-image: url('../../assets/image/szsfkc/category.png');
            vertical-align: -1px;
            margin-right: 8px;
          }
          span {
            font-size: 14px;
            color: #11233f;
            &::after {
              content: '';
              display: inline-block;
              width: 5px;
              height: 7px;
              background-image: url('../../assets/image/szsfkc/arrow.png');
              background-size: cover;
              margin: 0 10px;
            }
          }
        }
        .category {
          margin-bottom: 24px;
        }
        .teacher {
          margin-bottom: 50px;
          &::before {
            background-image: url('../../assets/image/szsfkc/shouke.png');
          }
        }
        .browse {
          font-size: 12px;
          color: #bdc1cb;
          &::before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 8px;
            background-image: url('../../assets/image/szsfkc/browse.png');
            background-size: cover;
            margin-right: 8px;
          }
        }
      }
    }
    .tabs-panel {
      padding-top: 40px;
      .tabs-header {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #f1f2f7;
        margin-bottom: 11px;
        .tabs-header-item {
          font-size: 16px;
          font-weight: bold;
          color: #676f7f;
          padding-right: 24px;
          margin-right: 24px;
          border-right: 1px solid #edeff3;
          position: relative;
          cursor: pointer;
          &.active,
          &:hover {
            color: #eb0012;
            &::before {
              content: '';
              display: inline-block;
              width: 21px;
              height: 20px;
              background-image: url('../../assets/image/szsfkc/intro.png');
              position: absolute;
              right: 13px;
              top: -7px;
              z-index: -1;
              background-size: cover;
            }
            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              width: 70%;
              height: 4px;
              background: #eb0012;
              bottom: -20px;
              left: 2px;
            }
          }
        }
      }
      .tabs-body {
        padding-bottom: 30px;
        .tabs-body-item {
          &.intro {
            min-height: 200px;
          }
          text-align: center;
          ::v-deep audio,
          ::v-deep img,
          ::v-deep video {
            max-width: 70%;
            display: block;
            margin: 0 auto;
          }
          .tcplayer video {
            max-width: 100%;
            display: block;
            margin: 0 auto;
          }
          &.active {
            display: block;
          }
          .intro-box {
            padding: 18px;
            background: #f6f9fa;
            font-size: 14px;
            color: #333333;
            text-align: left;
            line-height: 24px;
            font-size: 14px;
            ::v-deep p {
              line-height: 30px !important;
              font-size: 16px;
              &:not(:last-child) {
                margin-bottom: 14px;
                padding-bottom: 18px;
              }
            }
          }

          .teach-resource {
            .resource-header {
              width: 100%;
              height: 40px;
              background: #f4f4f4;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 14px;
              margin-bottom: 4px;
              border-bottom: 1px solid #e4e4e4;
              .name {
                font-size: 14px;
                font-weight: bold;
                color: #333333;
                padding-left: 13px;
                border-left: 4px solid #eb0012;
                height: 13px;
                line-height: 13px;
              }
              .toggle {
                font-size: 14px;
                font-weight: bold;
                color: #125cfd;
                cursor: pointer;
                &::after {
                  content: '';
                  display: inline-block;
                  width: 6px;
                  height: 7px;
                  background-image: url('../../assets/image/szsfkc/menu-down.png');
                  margin-left: 8px;
                }
                &.show::after {
                  background-image: url('../../assets/image/szsfkc/menu-up.png');
                }
              }
            }
            .resource-body {
              text-align: center;
              overflow: hidden;
              transition: all 0.3s;
              padding-bottom: 0;
              height: 0;
              &.show {
                padding: 0 0 30px;
                height: auto;
              }
              video {
                width: 100%;
                height: 100%;
                padding: 10px 0 14px;
                display: block;
                margin: 0 auto;
              }
              .res-item {
                background: #f9fafc;
                padding: 12px 0;
                &:not(:last-child) {
                  margin-bottom: 14px;
                }
                &.no-bg {
                  background: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
.video-js {
  margin: 0 auto;
}
</style>