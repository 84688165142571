<template>
  <div>
    <div class="swiper" ref="xxzyswiper">
      <swiper :options="mySwiperOption">
        <swiper-slide
          v-for="item in list"
          :key="item.id"
          :style="`background-image: url('${item.list_photo}')`"
          :data-kcid="item.id"
          :data-kcName="item.name"
        >
        </swiper-slide>
      </swiper>
      <div :class="['swiper-button-next', btnval]"></div>
      <div :class="['swiper-button-prev', btnval]"></div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { mapActions } from "vuex";
import services from "../assets/js/services";
export default {
  name: "indexSwiper",
  props: ["list", "btnval"],
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    let self = this;
    return {
      mySwiperOption: {
        effect: "coverflow",
        loop: true,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 500,
          modifier: 1,
          slideShadows: false,
        },
        preventClicksPropagation: false,
        navigation: {
          // 前进后退按钮 ,不同版本配置方式不同
          nextEl: `.swiper-button-next.${this.btnval}`,
          prevEl: `.swiper-button-prev.${this.btnval}`,
        },
        on: {
          click: function (v) {
            console.log(v.target.getAttribute("data-kcid"));
            let id = v.target.getAttribute("data-kcid");
            let kcName = v.target.getAttribute("data-kcName");
            if (id != null || kcName != null) {
              self.goToClassDetail({ id, name: kcName });
            }
          },
        },
      },
      school_code: localStorage.getItem("schoolCode"),
    };
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    goToClassDetail(item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(
          `${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.id}&category_name=${item.name}&cm_id=${item.id}`
        );
      });
    },
  },
};
</script>

<style lang="less" scoped>
.swiper {
  width: 374px;
  height: 168px;
  position: relative;
  .swiper-slide {
    width: 293px;
    height: 168px;
    cursor: pointer;
    color: #fff;
    background: #3c3c3c;
    background-size: 100% auto;
    background-position: center;
    opacity: 0.5;
    // div{
    //   width: 100%;
    //   height: 100%;

    // }
    &.swiper-slide-active {
      opacity: 1 !important;
      border: 4px solid rgba(255, 255, 255, 0.84);
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 28px;
    height: 30px;
    background-size: 100%;
  }
  .swiper-button-next {
    background-image: url("../assets/image/xxzyk/index/next.png");
  }
  .swiper-button-prev {
    background-image: url("../assets/image/xxzyk/index/prev.png");
  }
  .swiper-button-next:hover {
    background-image: url("../assets/image/xxzyk/index/next-hover.png");
  }
  .swiper-button-prev:hover {
    background-image: url("../assets/image/xxzyk/index/prev-hover.png");
  }
}
</style>