<template>
  <div class="bg-box">
    <template v-if="school_code == utils.whrj_code">
      <WhrjHeader />
    </template>
    <template v-else>
      <Header />
      <Navbar />
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import utils from "../../assets/js/utils";
import Header from "../../components/Header.vue";
import Navbar from "../../components/Navbar.vue";

export default {
  name: "xxzykindex",
  components: {
    Header,
    Navbar,

  },
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      utils,
    };
  },
};
</script>

<style lang="less" scoped>
.bg-box {
  background-color: #fff;
  width: 100%;
}
</style>