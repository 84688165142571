<template>
  <div>
    <div class="gjjs-header" v-if="$route.path.indexOf('gjjsIndex') == -1 ">
      <div class="logo"></div>
      <div class="nav-list">
        <div class="nav-item " @click="$router.push(`/${school_code}/gjjs/gjjsIndex`)" >首页</div>
        <div class="nav-item"  @click="$router.push(`/${school_code}/gjjs/jdgj`)" :class="{active: currentPath.indexOf('jdgj') >= 0 || $route.query.columnPath == 'jdgj'  }" >习说工匠解读工匠</div>
        <div class="nav-item"  @click="$router.push(`/${school_code}/gjjs/jxsy`)" :class="{active: currentPath.indexOf('jxsy') >= 0 || $route.query.columnPath == 'jxsy'  }">中华制造匠心溯源</div>
        <div class="nav-item" @click="$router.push(`/${school_code}/gjjs/jxshany`)" :class="{active: currentPath.indexOf('jxshany') >= 0 || $route.query.columnPath == 'jxshany' }">继往开来匠心闪耀</div>
        <div class="nav-item" @click="$router.push(`/${school_code}/gjjs/gjjsIndex?ind=5`)">乘风破浪匠心争先</div>

        <div class="nav-item"  @click="$router.push(`/${school_code}/gjjs/jxlh`)" :class="{active: currentPath.indexOf('jxlh') >= 0 || $route.query.columnPath == 'jxlh' }">敬业济群匠心领航</div>
        <a class="nav-item" href="http://worldskillschina.mohrss.gov.cn/fczs/zgxs/" target="_blank">匠苗培育世赛青春</a>
      </div>
      <div class="setting-con">
        <div class="sreach">
          <div
            class="sreach-show"
            v-if="!isInputShow"
            @click="isInputShow = true"
          ></div>

          <div class="sreach-input" v-if="isInputShow">
            <input type="text" v-model="searchVal" @keyup.enter="ToSreach" />
            <div class="sreach-btn" @click="ToSreach" ></div>
          </div>
        </div>
         <div
          v-if="appsUserInfo && appsUserInfo.name"
          class="login-dropdown-box"
        >
          <div class="login-after">{{ appsUserInfo.name }}</div>
          <div class="login-sub-box">
            <template
              v-if="
                appsUserInfo.user_type == 2 ||
                (appsUserInfo.user_type == 1 && appsUserInfo.role_ids)
              "
            >
              <div class="hover-item">
                <a
                  :href="`${services.BEIKE_SERVER}/${school_code}/sz/school/resource`"
                  target="_blank"
                  >校内共建资源库</a
                >
              </div>
              <div class="hover-item">
                <a
                  :href="`${services.BEIKE_SERVER}/${school_code}/lesson/resource`"
                  target="_blank"
                  >备课资源库</a
                >
              </div>
              <div class="hover-item">
                <a
                  :href="`${services.BEIKE_SERVER}/${school_code}/lesson/works`"
                  target="_blank"
                  >协同备课</a
                >
              </div>
              <div class="hover-item">
                <a
                  :href="`${services.BEIKE_SERVER}/${school_code}/sz/favorite`"
                  target="_blank"
                  >资源收藏</a
                >
              </div>
            </template>
            <template
              v-if="
                appsUserInfo.user_type == 2 ||
                (appsUserInfo.user_type == 1 && appsUserInfo.role_ids == 2)
              "
            >
              <div class="hover-item">
                <a
                  :href="`${services.BEIKE_SERVER}/${school_code}/manage/user`"
                  target="_blank"
                  >用户管理</a
                >
              </div>
            </template>
            <div class="login-lines"></div>
            <div class="hover-item">
              <a
                :href="`${services.APPS_WEB}/${school_code}/worker`"
                target="_blank"
                >信息服务中心</a
              >
            </div>
            <div class="hover-item">
              <a
                :href="`${services.APPS_WEB}/${school_code}/userInfo`"
                target="_blank"
                >个人信息设置</a
              >
            </div>
            <div v-if="!isMobile" class="hover-item">
              <a
                :href="`${services.APPS_WEB}/${school_code}/editPassword`"
                target="_blank"
                >修改密码</a
              >
            </div>
            <div class="hover-item" @click="logoutEvent">
              <a href="javascript:;">退出</a>
            </div>
          </div>
        </div>
        <!-- <div class="login">登录</div>
        <div class="free-btn">申请免费试用</div> -->
      </div>
    </div>
    <router-view></router-view>
    <Login></Login>
  </div>
</template>

<script>
import Login from "../../components/Login.vue"
import { mapState,mapActions, mapMutations } from "vuex"
import services from "../../assets/js/services";
import utils from "../../assets/js/utils"
export default {
  data() {
    return {
       services,
      isMobile: utils.isMobile(),
      school_code: localStorage.getItem("schoolCode"),
      // currentPath: "",
      isInputShow: false,
      searchVal: ""
    };
  },
  components: {
    Login,
  },
  created() {
    // this.currentPath = this.$route.path;
  },
    computed: {
    ...mapState({
      appsUserInfo: state => state.appsLogin.appsUserInfo,
      loginVisible: state => state.appsLogin.loginVisible,
      keyWord: state => state.search.keyWord,
      loading: state => state.search.loading,
      schoolInfo: state => state.appsLogin.schoolInfo
    }),
    currentPath () {
      return this.$route.path
    }
  },
  methods: {
   ...mapMutations([
      "setSearchKeyWord",
      "setAppsLoginVisible",
      "setSearchSzzykData",
      "setSearchElementData",
      "resetSzzykTotal",
    ]),
    ...mapActions([
      "getAppsImageCaptcha",
      "getLoginSchoolInfo",
      "getAppsLoginUserInfo",
      "requestAppsLogout",
      "goAppsDetailsBeforeLogin",
      "schoolLoginRedirect",
    ]),
    async ToSreach() {
      if(this.searchVal) {
        this.$router.push({
          path: `/${this.school_code}/gjjs/gjjsSearch`,
          query: {
            val: this.searchVal
          }

        })
        // let res = await services.getgjjsSearchList()
      }else {
        this.$message.info("搜索内容不能为空！")
      }
    },
      async logoutEvent () {
      let that = this;
      await that.requestAppsLogout().then(() => {
        this.$message.success("已退出登录")
        this.$router.push(`/${this.school_code}/gjjs`)
      })
    },
  }
};
</script>

<style lang="less" scoped>
.gjjs-header {
  height: 76px;
  display: flex;
  align-items: center;
  padding: 0 36px 0 36px;
  justify-content: space-between;
  .logo {
    width: 103px;
    height: 48px;
    background-image: url("../../assets/image/gjjs/index/nav-index/logo.png");
  }
  .nav-list {
    display: flex;
    .nav-item {
      font-size: 16px;
      color: #333333;
      width: 64px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 69px;
      cursor: pointer;
      &:hover {
         font-weight: bold;
        color: #bb0007;
      }
      &.active {
        font-weight: bold;
        color: #bb0007;
      }
    }
  }
  .setting-con {
    display: flex;
    margin-right: 35px;
    // width: 469px;
    // justify-content: flex-end;
    .login-dropdown-box {
        margin-left: 35px;
        height: 32px;
        line-height: 32px;
        position: relative;
        display: inline-block;
        .login-after {
          color: #121212;
          font-size: 12px;
          display: inline-block;
          line-height: 30px;
          &::after {
            display: inline-block;
            content: '';
            width: 7px;
            height: 5px;
            margin-left: 5px;
            background-image: url('../../assets/image/common/up-icon.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
            vertical-align: 3px;
            transition: background-image 0.3s;
          }
        }
        .login-sub-box {
          display: none;
          position: absolute;
          padding: 10px 20px;
          box-sizing: border-box;
          top: 30px;
          left: -35px;
          width: 140px;
          text-align: left;
          background-color: transparent;
          box-shadow: 0px 1px 6px 0px rgba(130, 130, 130, 0.2);
          border-radius: 2px;
          transition: background-color 0.3s;
          z-index: 100;
          .hover-item {
            display: block;
            line-height: 26px;
            &:hover {
              background-color: #fff;
            }
            a {
              color: #393939;
              font-size: 12px;
              display: block;
              &:hover {
                color: #e64757;
                background-color: #fff;
              }
            }
          }
          .login-lines {
            width: 100%;
            height: 1px;
            background: #f3f4f5;
            margin: 5px 0;
          }
        }
        &:hover .login-after::after {
          background-image: url('../../assets/image/common/down-icon.png');
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-position: center;
        }
        &:hover .login-sub-box {
          display: block;
          background-color: #ffffff;
        }
      }
    .sreach {
      width: 40px;
      height: 32px;

      position: relative;
      .sreach-show {
        width: 40px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #bb0007;
        background-image: url("../../assets/image/gjjs/index/nav-index/sreach-icon.png");
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
      .sreach-input {
        position: absolute;
        width: 239px;
        height: 32px;
        border: 1px solid #c7c4c4;
        background-color: #fff;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        input {
          padding-left: 10px;
          height: 18px;
          width: 195px;
          border: 0;
          border-right: 1px #d3d3d3 solid;
          outline: none;
        }
        .sreach-btn {
          cursor: pointer;
          flex: 1;
          height: 30px;
          background-image: url("../../assets/image/gjjs/index/nav-index/sreach-icon.png");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    .login {
      width: 72px;
      height: 32px;
      background: #d48432;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 31px;
      margin-left: 12px;
    }
    .free-btn {
      width: 136px;
      height: 32px;
      background: #bb0007;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 31px;
      margin-left: 10px;
    }
  }
}
@media screen and (max-width: 1600px) {
  .nav-list {
    margin-right: 70px !important;
    .nav-item {
      margin-right: 40px !important;
    }
  }
}
@media screen and (max-width: 1400px) {
  .nav-list {
    .nav-item {
      margin-right: 40px !important;
    }
  }
}
@media screen and (max-width: 1300px) {
  .nav-list {
    margin-right: 174px !important;
    .nav-item {
      margin-right: 20px !important;
    }
  }
}
</style>