<template>
  <div class="bg-szzyk">
    <template v-if="school_code == utils.whrj_code">
      <WhrjHeader />
    </template>
    <template v-else>
      <Header />
      <Navbar />
    </template>
    <div class="top-banner-box">
      <div v-if="school_code == utils.whrj_code" class="container">
        <router-link class="szysk-button" :to="`/${school_code}/zy/szysk`">查看思政元素体系</router-link>
      </div>
    </div>
    <div class="container outer-list">
      <div class="inner-list-box">
        <div class="menu-subject-box">
          <div class="label-menu">
            <img src="../../assets/image/szzyk/subject-title.png" />
          </div>
          <!-- 学科门类菜单 -->

          <div class="menu-outer">
            <a-menu mode="inline" :open-keys="openKeys" :selected-keys="currentSub" @openChange="onOpenChange" @click="clickMenuItem">
              <a-sub-menu class="zyk-one-menu" key="all">
                <span class="zyk-one-line" slot="title"><span>全部专业</span></span>
              </a-sub-menu>
              <template v-for="item in subjectTreeData">
                <a-sub-menu class="zyk-one-menu" :key="`${item.id}`">
                  <span class="zyk-one-line" slot="title"><span>{{item.name}}</span><i v-if="item.children &&  item.children.length > 0" class="zyk-line-icon"></i></span>
                  <a-menu-item v-for="sub in item.children" :key="`${sub.id}`">
                    {{sub.name}}
                  </a-menu-item>
                </a-sub-menu>
              </template>
            </a-menu>
          </div>

        </div>
        <div class="data-all-box">
          <div v-show="currentLevel1" class="current-subject">
            <span v-if="currentLevel1" class="lable1">{{ currentLevel1.name }}</span>
            <span v-if="currentLevel2" class="lable2"> - {{ currentLevel2.name }}</span>
            <div class="add-res" @click="handleBeikeAdd">添加资源</div>
          </div>
          <!-- 筛选项 -->
          <div v-show="multipleType != 'major' && multipleType != 'szys' &&multipleType!='zsd'" class="screen-all-box">
            <div v-show="currentLevel2" :class="majorMore ? 'screen-lines': 'screen-lines overflow'">
              <div class="screen-label">相关专业：</div>
              <div ref="majorContent" class="screen-content">
                <!-- <div :class="majorChoose.id == 'all' ? 'items active' :'items'" @click="clickItemChoice('major', {id: 'all'})">全部</div> -->
                <div v-for="(item,index) in majorData" :key="index" @click="clickItemChoice('major', item)" :class="item.isActive ? 'items active' :'items'">{{item.name}}</div>
              </div>
              <div class="screen-operation">
                <div @click="screenMultiple('major')" class="select-btn">多选</div>
                <div v-show="majorClientHei > 30" @click="screenMore('major')" class="more-btn">{{majorMore ? '收起' : '更多'}}</div>
              </div>
            </div>

            <div class="screen-lines" v-show="ztmk&&ztmk.length>0 && openKeys[0]!='all'">
              <div class="screen-label">主题模块：</div>
              <div class="screen-content">
                <div :class="['items',item.id==ztmk_item.id?'active':'']" v-for="item,index in ztmk" :key="index" @click="clickItemChoice('ztmk',item)">{{ item.name }}</div>
              </div>
            </div>
            <div :class="!zsdMore ? 'screen-lines zsd': 'screen-lines zsd overflow'" v-show="zsd&&zsd.length>0">
              <div :class="['screen-label']">知识点：</div>
              <div ref="zsdContent" class="screen-content">
                <!-- <div @click="clickItemChoice('zsdOne', {name:'全部',id:'all'})" :class="[zsdOneChoose.indexOf('all') > -1 ? 'items active' :'items']">全部</div> -->
                <template v-for="(item,index) in zsd">
                  <div :key="index" v-if="item.name" @click="clickItemChoice('zsdOne', item)" :class="[(zsdOneChoose.indexOf(item.id) > -1||zsdOneChoose.indexOf(item.name) > -1) ? 'items active' :'items']">{{item.name}}</div>
                </template>

              </div>
              <div class="screen-operation">
                <div @click="screenMultiple('zsd')" class="select-btn">多选</div>
                <div v-show="zsdClientHei > 90" @click="screenMore('zsd')" class="more-btn">{{zsdMore ? '更多' : '收起'}}</div>
              </div>
            </div>
            <div v-show="caseResources.length > 0" :class="resourcesMore ? 'screen-lines':'screen-lines overflow'">
              <div class="screen-label">案例资源：</div>
              <div ref="resourcesContent" class="screen-content">
                <!-- <div :class="areaChoose.id == 'all' ? 'items active' :'items'" @click="clickItemChoice('area', {id: 'all'})">全部</div> -->
                <div v-for="(item,index) in caseResources" :key="index" @click="toalzyk(item)" :class="'' == item.id ? 'items active' :'items'">{{item.name}}</div>
              </div>
              <div class="screen-operation">
                <div v-show="resourcesClientHei > 30" @click="screenMore('alzy')" class="more-btn">{{resourcesMore ? '收起' : '更多'}}</div>
              </div>
            </div>
            <div v-show="isUserLogin && szElementData.length > 0" :class="szysMore ? 'screen-lines': 'screen-lines overflow'">
              <div class="screen-label">思政元素：</div>
              <div ref="szysContent" class="screen-content">
                <!-- <div :class="szysOneChoose.id == 'all' ? 'items active' :'items'" @click="clickItemChoice('szysOne', {id: 'all'})">全部</div> -->
                <div v-for="(item,index) in szElementData" :key="index" @click="clickItemChoice('szysOne', item)" :class="szysOneChoose.indexOf(item.id) > -1 ? 'items active' :'items'">{{item.name}}</div>

                <a class="understand" :href="`/${school_code}/zy/szysk`" target="_blank">了解思政元素体系</a>
              </div>
              <div class="screen-operation">
                <div @click="screenMultiple('szys')" class="select-btn">多选</div>
                <div v-show="szysClientHei > 30" @click="screenMore('szys')" class="more-btn">{{szysMore ? '收起' : '更多'}}</div>
              </div>
            </div>

            <div v-show="isUserLogin && szysSecondArr.length > 0" class="screen-lines">
              <div class="screen-label">元素要点：</div>
              <div class="screen-content">
                <div :class="szysTwoChoose.id == 'all' ? 'items active' :'items'" @click="clickItemChoice('szysTwo', {id: 'all'})">全部</div>
                <div v-for="(item,index) in szysSecondArr" :key="index" @click="clickItemChoice('szysTwo', item)" :class="szysTwoChoose.id == item.id ? 'items active' :'items'">{{item.name}}</div>
              </div>
              <div class="screen-operation"></div>
            </div>

            <div v-show="resAreaData.length > 0" :class="areaMore ? 'screen-lines':'screen-lines overflow'">
              <div class="screen-label">地域特色：</div>
              <div ref="areaContent" class="screen-content">
                <!-- <div :class="areaChoose.id == 'all' ? 'items active' :'items'" @click="clickItemChoice('area', {id: 'all'})">全部</div> -->
                <div v-for="(item,index) in resAreaData" :key="index" @click="clickItemChoice('area', item)" :class="areaChoose.id == item.id ? 'items active' :'items'">{{item.name}}</div>
              </div>
              <div class="screen-operation">
                <div v-show="areaClientHei > 30" @click="screenMore('area')" class="more-btn">{{areaMore ? '收起' : '更多'}}</div>
              </div>
            </div>

            <div v-show="isUserLogin && suggestData.length > 0" class="screen-lines">
              <div class="screen-label">应用建议：</div>
              <div ref="szysEle" class="screen-content">
                <div :class="suggestChoose.id == 'all' ? 'items active' :'items'" @click="clickItemChoice('suggest', {id: 'all'})">全部</div>
                <div v-for="(item,index) in suggestData" :key="index" @click="clickItemChoice('suggest', item)" :class="suggestChoose.id == item.id ? 'items active' :'items'">{{item.name}}</div>
              </div>
              <div class="screen-operation"></div>
            </div>

            <div v-show="resourceType.length > 0" class="screen-lines">
              <div class="screen-label">资源类型：</div>
              <div ref="szysEle" class="screen-content">
                <div :class="resTypeChoose.type == 'all'?'items active':'items'" @click="clickItemChoice('resType', {type: 'all'})">全部</div>
                <div v-for="(item,index) in resourceType" :key="index" @click="clickItemChoice('resType', item)" :class="resTypeChoose.type == item.type?'items active':'items'">{{item.name}}</div>
              </div>
              <div class="screen-operation"></div>
            </div>

          </div>
          <!-- 多选和更多的展开 相关专业 -->
          <div v-show="multipleType == 'major'" class="select-all-box">
            <div class="first-lines">
              <div class="line-label">相关专业：</div>
              <!-- <div class="line-words">
                <div class="word-item active">A ~ G</div>
                <div class="word-item">H ~ N</div>
                <div class="word-item">O ~ T</div>
                <div class="word-item">U ~ Z</div>
              </div> -->
              <div class="line-button">
                <div class="btn" @click="saveChoose('major')">保存</div>
                <div class="btn" @click="cancelChoose('major')">取消</div>
              </div>
            </div>
            <div class="select-groups">
              <div v-for="(item,index) in multipleMajor" :key="index" @click="clickItemCheckbox('major', item)" :class="item.isActive ? 'select-items active':'select-items'">{{item.name}}</div>
            </div>
          </div>

          <!-- 思政元素 多选和更多的展开 -->
          <div v-show="multipleType == 'szys'" class="select-all-box">
            <div class="first-lines">
              <div class="line-label">思政元素：</div>
              <!-- <div class="line-words">
                <div class="word-item active">A ~ G</div>
                <div class="word-item">H ~ N</div>
                <div class="word-item">O ~ T</div>
                <div class="word-item">U ~ Z</div>
              </div> -->
              <div class="line-button">
                <div class="btn" @click="saveChoose('szys')">保存</div>
                <div class="btn" @click="cancelChoose('szys')">取消</div>
              </div>
            </div>
            <div class="select-groups">
              <div v-for="(item,index) in multipleSzys" :key="index" @click="clickItemCheckbox('szysOne', item)" :class="item.isActive ? 'select-items active':'select-items'">{{item.name}}</div>
            </div>
          </div>

          <div v-show="multipleType == 'zsd'" class="select-all-box">
            <div class="first-lines">
              <div class="line-label">知识点：</div>
              <!-- <div class="line-words">
                <div class="word-item active">A ~ G</div>
                <div class="word-item">H ~ N</div>
                <div class="word-item">O ~ T</div>
                <div class="word-item">U ~ Z</div>
              </div> -->
              <div class="line-button">
                <div class="btn" @click="saveChoose('zsd')">保存</div>
                <div class="btn" @click="cancelChoose('zsd')">取消</div>
              </div>
            </div>
            <div class="select-groups">
              <div v-for="(item,index) in multipleZsd" :key="index" @click="clickItemCheckbox('zsdOne', item)" :class="item.isActive ? 'select-items active':'select-items'">{{item.name}}</div>
            </div>
          </div>

          <!-- 当前筛选条件 -->
          <!-- openKeys[0] != 'all' -->
          <div v-show="(majorShowText.length > 0 || szysShowText.length > 0 || szysTwoChoose.id != 'all' || areaChoose.id != 'all' || resTypeChoose.type != 'all' || suggestChoose.id !='all') || zsdShowText.length>0 || ztmkShowText!='全部'"
            class="screen-current-box">
            <div class="left-label">当前筛选条件</div>
            <div class="current-list">
              <div v-show="majorShowText.length > 0" class="current-opt">学科专业：
                <span class="current-text">{{majorShowText | filterArrJoin}}</span>
                <div class="clear-btn" @click="clearScreen('major')"></div>
              </div>
              <div v-show="szysShowText.length > 0" class="current-opt">思政元素：
                <span class="current-text">{{szysShowText | filterArrJoin}}</span>
                <span v-show="szysTwoChoose.id != 'all'">、{{szysTwoChoose.name}}</span>
                <div class="clear-btn" @click="clearScreen('szys')"></div>
              </div>

              <div v-show="zsdShowText.length > 0" class="current-opt">知识点：
                <span class="current-text">{{zsdShowText | filterArrJoin}}</span>
                <!-- <span v-show="szysTwoChoose.id != 'all'">、{{szysTwoChoose.name}}</span> -->
                <div class="clear-btn" @click="clearScreen('zsd')"></div>
              </div>
              <div v-show="ztmkShowText !='全部'" class="current-opt">主题模块：
                <span class="current-text">{{ztmkShowText}}</span>
                <!-- <span v-show="szysTwoChoose.id != 'all'">、{{szysTwoChoose.name}}</span> -->
                <div class="clear-btn" @click="clearScreen('ztmk')"></div>
              </div>
              <div v-show="areaChoose.id != 'all'" class="current-opt">
                地域特色：{{areaChoose.name}}
                <div class="clear-btn" @click="clearScreen('area')"></div>
              </div>
              <div v-show="suggestChoose.id != 'all'" class="current-opt">
                应用建议：{{suggestChoose.name}}
                <div class="clear-btn" @click="clearScreen('suggest')"></div>
              </div>
              <div v-show="resTypeChoose.type != 'all'" class="current-opt">
                资源类型：{{resTypeChoose.name}}
                <div class="clear-btn" @click="clearScreen('resType')"></div>
              </div>
            </div>
          </div>
          <!-- 搜索结果列表 -->
          <div class="model-szzyk-box">
            <div class="left-title-box">搜索结果</div>
            <div class="right-search-box">
              <div class="frame-input">
                <input v-model="searchVal" class="text" @keyup.enter="searchEvent" placeholder="请输入检索词" />
              </div>
              <div v-show="searchVal && searchVal.length > 0 &&!loading" class="clear-input" @click="clearSearchEvent"></div>
              <div :class="['search-button',loading?'disabled':'']" @click="searchEvent">{{loading?'搜索中':'搜索'}}</div>
            </div>
            <div @click="changeOrderName('new_quote_num')" :class="order_name == 'new_quote_num' ? 'sort-middle-box active' : 'sort-middle-box'">使用热度</div>
            <div @click="changeOrderName('new_visit_num')" :class="order_name == 'new_visit_num' ? 'sort-middle-box active' : 'sort-middle-box'">浏览次数</div>
            <div @click="changeOrderName('new_favorite_num')" :class="order_name == 'new_favorite_num' ? 'sort-middle-box active' : 'sort-middle-box'">收藏次数</div>
            <div @click="changeOrderName('publish_time')" :class="order_name == 'publish_time' ? 'sort-middle-box active' : 'sort-middle-box'">发布时间</div>
            <!-- <div :class="`sort-middle-box ${order_by}`" @click="changeOrderBy()">资源发布时间</div> -->
          </div>

          <template v-if="szzykListData.length > 0">
            <div class="resource-list">
              <div v-for="(item, index) in szzykListData" :key="index" class="resource-opt" @click="goDetails(item)">
                <div :class="[item.type == 1? 'res-title word' : 'res-title video',item.is_school_original == 1?'ov87':'']">
                  <span class="title-span ellipsis" v-html="item.title"></span>
                  <!-- <span v-if='item.is_open == 2' class="resopen">公开</span> -->
                  <div class="badge" v-if="item.is_school_original == 1"></div>
                </div>
                <div class="res-all-box">
                  <!-- <div class="res-left-img">
                  <img src="../../assets/image/szzyk/subject-title.png" />
                </div> -->
                  <div class="res-right-con">
                    <div class="brief" v-html="item.abstract"></div>
                    <div v-if="isUserLogin" class="advise-box">
                      <div v-if="item.element_list_second" class="advise-item">元素要点：
                        <template v-for="(sub,sinx) in item.element_list_second">
                          <template v-if="sinx < 2">
                            {{sub.name}}{{((item.element_list_second.length  - 1) > sinx) ? '、' : ''}}
                          </template>
                        </template>
                      </div>
                      <div v-if="item.suggest_list" class="advise-item">应用建议：
                        <template v-for="(sub,sinx) in item.suggest_list">
                          <template v-if="sinx < 2">
                            {{sub.name}}{{((item.suggest_list.length  - 1) > sinx) ? '、' : ''}}
                          </template>
                        </template>
                      </div>

                      <div v-if="item.knowledge&&item.knowledge.length>0" class="advise-item flex">知识点：
                        <span class="pointContent">{{ (item.knowledge.length>=2&&item.knowledge[2]!=undefined&&item.knowledge[2])?(item.knowledge.join("、")):(item.knowledge[0])}}</span>
                        <div v-if="item.knowledge&&item.knowledge.length>1" class="more">
                          <div class="more-text"></div>
                          <div class="more-group">
                            <template v-for="pointItm,pointIdx in item.knowledge">
                              <div class="more-item" :key="pointIdx" v-if="pointItm">
                                {{ pointItm }}</div>
                            </template>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.publish_time || item.source" class="little-box">
                      <div class="left-tags">
                        <div v-if="item.publish_time" class="tag-opt timer">时间：{{item.publish_time}}</div>
                        <div v-if="item.source" class="tag-opt author">来自：{{item.source}}</div>
                        <div v-if="isUserLogin" class="tag-opt computed">
                          <span v-if="item.new_visit_num" class="nums">{{item.new_visit_num}}人浏览</span>
                          <span v-if="item.new_favorite_num" class="nums">{{item.new_favorite_num}}次收藏</span>
                          <span v-if="item.new_quote_num" class="nums">{{item.new_quote_num}}次使用</span>
                        </div>
                      </div>
                      <div class="right-operat-box">
                        <div :class="item.favorite == 1 ? 'operat-item star active': 'operat-item star'" @click.stop="requestCollect(item)">
                          {{item.favorite == 1 ? '取消收藏': '收 藏'}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="text-align: center;">
              <a-pagination v-model="currentPage" :show-total="total => `共${$store.state.szzyk.listCount}条`" :total="$store.state.szzyk.listCount" @change="onPagesChange" />
            </div>
          </template>
          <template v-else>
            <nodata v-if="isInitData" />
            <div class="loading-data" v-else>加载中……</div>
          </template>
        </div>
      </div>
    </div>
    <component :is="dialogConfig.name"></component>

  </div>
</template>

<script>
import Navbar from "../../components/Navbar.vue"
import Nodata from "../../components/Nodata.vue"
import { Empty } from 'ant-design-vue';
// import { Empty, Modal } from 'ant-design-vue';
import { mapState, mapActions, mapMutations } from "vuex"
import utils from "../../assets/js/utils"
import services from "../../assets/js/services";
import Header from '../../components/Header.vue';
import WhrjHeader from "../../components/WhrjHeader.vue"
import appleRes from "../../components/appleRes.vue"

export default {
  name: "szzykList",
  components: {
    Navbar,
    Nodata,
    Header,
    appleRes,
    WhrjHeader
  },
  data () {
    return {
      services,
      utils,
      school_code: localStorage.getItem("schoolCode"),
      openKeys: ['all'],
      currentSub: [],
      currentLevel1: { name: '全部专业' },
      currentLevel2: null,
      majorMore: false,  // 相关专业-更多按钮
      szysMore: false, // 思政元素-更多按钮
      areaMore: false, // 地域特色-更多按钮
      zsdMore: false, // 知识点-更多按钮
      resourcesMore: false, // 案例资源-更多按钮
      majorClientHei: 0, // 相关专业-内容高度
      szysClientHei: 0,// 思政元素-内容高度
      areaClientHei: 0,// 地域特色-内容高度
      zsdClientHei: 0, // 知识点-内容高度
      resourcesClientHei: 0 ,// 案例资源-内容高度
      currentPage: 1,
      szysOneChoose: ['all'], // 选中的思政一级元素
      zsdOneChoose: ['all'],
      szysTwoChoose: { id: 'all' },
      areaChoose: { id: 'all' },
      suggestChoose: { id: 'all' },
      resTypeChoose: { type: 'all' },
      multipleMajor: [], // 多选操作 - 相关专业数据
      multipleSzys: [], // 多选操作 - 思政元素数据
      multipleZsd: [], // 多选操作 - 知识点
      multipleType: 'null',
      szysSecondArr: [], // 当前选中的一级思政元素的二级列表
      searchVal: '', // 搜索关键字
      majorShowText: [],
      szysShowText: [],
      zsdShowText: [],
      ztmkShowText: "全部",
      isInitData: false,
      order_by: 'desc', // desc 降序 asc 升序
      order_name: 'new_quote_num', // 默认热度排序（new_quote_num） 发布时间publish_time|热度new_quote_num|游览次数new_visit_num
      loading: false,// 关键字搜索状态
      dialogConfig: {
        name: ""
      },
      isUserLogin: false, //用户是否登录
      ztmk_item: { id: "", name: "全部" },
    }
  },
  computed: {
    ...mapState({
      appsUserInfo: state => state.appsLogin.appsUserInfo,
      subjectAllData: state => state.szzyk.subjectAllData,
      subjectTreeData: state => state.szzyk.subjectTreeData,
      subjectLevelData: state => state.szzyk.subjectLevelData,
      suggestData: state => state.szzyk.suggestData, // 应用场景列表
      majorData: state => state.szzyk.majorData,
      resAreaData: state => state.szzyk.resAreaData,
      szElementData: state => state.szzyk.szElementData,
      resourceType: state => state.szzyk.resourceType,
      szzykListData: state => state.szzyk.szzykListData,
      seg_list: state => state.szzyk.seg_list,
      szzykListNums: state => state.szzyk.szzykListNums,
      pageTotal: state => state.szzyk.pageTotal,
      pageNum: state => state.szzyk.pageNum,
      zsd: state => state.szzyk.knowledgeData,
      ztmk: state => state.szzyk.topicData,
      caseResources: state => state.szzyk.caseResources,
    }),
  },
  filters: {
    filterArrJoin: (val) => {
      if (typeof val == "string") return
      return val.join("、");
    },
  },
  watch: {
    appsUserInfo: function (newVal) {
      if (newVal && newVal.id) {
        this.isUserLogin = true
      } else {
        this.isUserLogin = false
      }
      // this.requestSzzykList(1)
    },
    szysMore: function () {
      this.$nextTick(function () {
        let szysRef = this.$refs['szysContent']
        if (szysRef) {
          this.szysClientHei = szysRef.clientHeight;
        }
      });
    },
    areaMore: function () {
      this.$nextTick(function () {
        let areaRef = this.$refs['areaContent']
        if (areaRef) {
          // console.log(areaRef.clientHeight);
          this.areaClientHei = areaRef.clientHeight;
        }
      });
    },
    resourcesMore: function () {
      this.$nextTick(function () {
        let resourcesRef = this.$refs['resourcesContent']
        if (resourcesRef) {
          // console.log(resourcesRef.clientHeight);
          this.resourcesClientHei = resourcesRef.clientHeight;
        }
      });
    },
    majorMore: function () {
      this.$nextTick(function () {
        let majorRef = this.$refs['majorContent']
        if (majorRef) {
          this.majorClientHei = majorRef.clientHeight;
        }
      });
    },
    zsdMore: function () {
      this.$nextTick(function () {
        let zsdRef = this.$refs['zsdContent']
        if (zsdRef) {
          this.zsdClientHei = zsdRef.clientHeight;
        }
      });
    },

  },
  beforeCreate () {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  async mounted () {
    let that = this
    await this.getSubjectMenu({ type: '1,2', school_code: that.school_code })
    if (this.$route.query.subjectbigid) {
      this.openKeys = [this.$route.query.subjectbigid + '']
      this.onOpenChange(this.openKeys)
      if (this.$route.query.subjectsmallid) {
        this.currentSub = [this.$route.query.subjectsmallid + '']
        this.clickMenuItem({ key: this.$route.query.subjectsmallid + '' })
      }

    } else {
      await this.getResourceType({school_code: this.school_code})
      await that.getSzzykCondition({ school_code: that.school_code }).then(res => {
        if (res.code == '0000') {
          if (this.$route.query.areaid) {
            that.resAreaData.forEach(element => {
              if (element.id == that.$route.query.areaid) {
                that.areaChoose = element
              }
            });
          }
          if (this.$route.query.type) {
            that.resourceType.forEach(element => {
              if (element.type == that.$route.query.type) {
                that.resTypeChoose = element
              }
            });
          }
          that.requestSzzykList(1)
        }
      })
    }

    this.refContentHeight()
    this.getSzzykListTotal()
    this.judgeLogin()
    // this.getAreaData()
    // this.getSzzykElement({length: '-1'})
  },
  methods: {
    ...mapMutations(['chooseSetELement', 'chooseSetZsd', 'chooseSetMajor', 'setAppsLoginVisible', 'resetMajorData', 'setSzElementData']),
    ...mapActions(["getAppsImageCaptcha", "getAppsLoginUserInfo", 'getSubjectMenu', 'getAreaData', 'getSzzykElement', 'getSzzykCondition', 'getSzzykSuggestList', 'getSzzykListData', 'goAppsDetailsBeforeLogin', 'getSzzykListCount', 'saveResourceFavorite',"getTopicData","getResourceType"]),
    // 无权限查看课程资源时弹层提示
    // noAuthMessageModal () {
    //   Modal.info({
    //     title: '温馨提示',
    //     okType: 'primary',
    //     okText: '申请开通更多资源',
    //     closable: true,
    //     content: (<div class="jioayu-chrome">
    //       请拨打服务咨询电话或申请开通更多资源，获得更多资源使用权限。<br />服务咨询： 宋老师  18701110987
    //     </div>),
    //     onOk () {
    //       window.open(`${services.YXT_GW}/need`)
    //     }
    //   })
    // },
    // 添加资源
    handleBeikeAdd () {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(`${services.BEIKE_SERVER}/${this.school_code}/sz/school/me/create`, "_blank")
      })

    },
    //点击收藏
    requestCollect (item) {
      let that = this;
      that.goAppsDetailsBeforeLogin().then(() => {
        //   that.isShowLoading = true
        that.saveResourceFavorite({ id: item.id }).then((res) => {
          // that.isShowLoading = false
          if (res.code == '0000') {
            that.$message.success("操作成功")
            this.requestSzzykList(that.currentPage)
          } else {
            if (res.code != '2001') {
              that.$message.error("操作失败")
            }
          }
        })
      })
    },
    // 获取内容改变后高度 
    refContentHeight () {
      this.$nextTick(function () {
        let majorRef = this.$refs['majorContent']
        if (majorRef) {
          this.majorClientHei = majorRef.clientHeight;
        }
        let szysRef = this.$refs['szysContent']
        let zsdRef = this.$refs['zsdContent']
        if (szysRef) {
          this.szysClientHei = szysRef.clientHeight;
          if (this.szysClientHei > 30) {
            this.szysMore = true
          }
        }
        let areaRef = this.$refs['areaContent']
        let resourcesRef = this.$refs['resourcesContent']
        if (resourcesRef) {
          this.resourcesClientHei = resourcesRef.clientHeight;
        }
        if (areaRef) {
          this.areaClientHei = areaRef.clientHeight;
        }
        if (zsdRef) {
          this.zsdClientHei = zsdRef.clientHeight;
          this.zsdMore = this.zsdClientHei > 90
        }
      });
    },
    //  左侧菜单点击事件
    async onOpenChange (openKeys) {
      this.areaChoose = { id: 'all' };
      this.resTypeChoose = { type: 'all' };
      this.szysTwoChoose = { id: 'all' };
      this.szysOneChoose = ['all'];
      this.zsdOneChoose = ['all']
      this.szysShowText = [];
      this.szysSecondArr = []
      this.suggestChoose = { id: 'all' }
      this.ztmk_item = { id: "", name: "全部" }
      this.zsdOneChoose = ['all']
      this.zsdShowText = []
      this.multipleZsd = []
      this.ztmkShowText = '全部'
      await this.resetMajorData([])
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.subjectLevelData && this.subjectLevelData.indexOf(latestOpenKey) === -1) {
        // this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
      this.currentLevel2 = null;
      if (this.openKeys[0] !== 'all') {
        await this.filterSubject(this.openKeys[0], 1)
       this.getResourceType({school_code: this.school_code,subject_big_id: this.currentLevel1.id})

        this.getTopicData({school_code: this.school_code, subject_big_id: this.openKeys[0]})
      } else {
        this.currentLevel1 = {
          name: '全部专业'
        }
        // this.openKeys = ['all']
      this.getResourceType({school_code: this.school_code})
        this.currentSub = []
        this.currentLevel1 = { name: '全部专业' };
        this.currentLevel2 = null;
        this.ztmk_item = { name: "全部", id: "" }
        this.ztmkShowText = "全部"
        await this.requestSzzykCondition()

      }
      await this.resetMajorData([])
      await this.requestSzzykList(1, 1)
      this.currentPage = 1

    },
    // 获取当前点击的专业类别
    filterSubject (id, type) {
      this.currentSubject = {};
      this.subjectAllData.forEach(element => {
        if (element.id == id) {
          if (type == 1) {
            this.currentSub = []
            this.currentLevel2 = null
            this.currentLevel1 = element
            this.majorShowText = [];
            this.szysShowText = [];
            this.szysSecondArr = []
            this.szysOneChoose = ['all']
            this.zsdOneChoose = ['all']
            this.szysTwoChoose = { id: 'all' }
            this.zsdShowText = "全部"
            this.requestSzzykCondition({
              subject_big_id: element.id
            })
          } else {
            this.currentLevel2 = element
            this.currentSub = [element.id + '']
            this.requestSzzykCondition({
              subject_small_id: element.id,
              subject_big_id: element.pid
            })
          }
        }
      });

    },
    // 请求筛选条件
    async requestSzzykCondition (params) {
      let that = this
      await this.getSzzykCondition({ ...params, school_code: that.school_code }).then(res => {
        if (res.code == '0000') {
          this.$nextTick(function () {
            that.suggestChoose = { id: 'all' }
            that.szysShowText = []
            that.szysSecondArr = []
            that.szysOneChoose = ['all'];
            that.zsdOneChoose = ['all']
            that.szysTwoChoose = { id: 'all' };
            that.areaChoose = { id: 'all' };
            that.resTypeChoose = { type: 'all' };
            // 切换专业时 如果当前筛选项有对应的项目 则不清除选中项目
            // if(that.szysShowText && that.szElementData && that.szElementData.length > 0){
            //   let isHave = false
            //   that.szElementData.forEach(element => {
            //     if(that.szysShowText.indexOf(element.name) > -1) {
            //       isHave = true
            //     }
            //   });
            //   if(!isHave){
            //     that.szysShowText = []
            //     that.szysSecondArr = []
            //     that.szysOneChoose = ['all'];
            //   }
            // }
            // if(that.szysShowText && that.szysTwoChoose && that.szysTwoChoose.id != 'all' && that.szysSecondArr && that.szysSecondArr.length > 0){
            //   let isHave = false
            //   that.szysSecondArr.forEach(element => {
            //     if(that.szysTwoChoose.id == element.id) {
            //       isHave = true  
            //     }
            //   });
            //   if(!isHave){
            //     that.szysTwoChoose = { id: 'all' };
            //   }
            // }
            // if(that.areaChoose && that.areaChoose.id != 'all' && that.resAreaData && that.resAreaData.length > 0){
            //   let isHave = false
            //   that.resAreaData.forEach(element => {
            //     if(that.areaChoose.id == element.id) {
            //       isHave = true  
            //     }
            //   });
            //   if(!isHave){
            //     that.areaChoose = { id: 'all' };
            //   }
            // }
            // if(that.resTypeChoose && that.resTypeChoose.type != 'all' && that.resourceType && that.resourceType.length > 0){
            //   let isHave = false
            //   that.resourceType.forEach(element => {
            //     if(that.resTypeChoose.type == element.type) {
            //       isHave = true  
            //     }
            //   });
            //   if(!isHave){
            //     that.resTypeChoose = { type: 'all' };
            //   }
            // }
          })
        }
      })
      this.refContentHeight();
    },
    onPagesChange (pageNumber) {
      let that = this;
      if (pageNumber > 1) {
        that.getAppsLoginUserInfo().then(res => {
          if (res.code == '2001') {
            that.currentPage = 1
            that.getAppsImageCaptcha()
            that.setAppsLoginVisible(true)
          } else if (res.code == '0000') {
            that.currentPage = pageNumber
            that.pageScrollTop()
            that.requestSzzykList(pageNumber)
          }
        })
      } else {
        that.pageScrollTop()
        this.requestSzzykList(pageNumber)
      }

    },
    pageScrollTop () {
      if (document.body.scrollTop) {
        // chrome
        document.body.scrollTop = 260
      } else if (document.documentElement.scrollTop) {
        // firefox
        document.documentElement.scrollTop = 260
      } else if (window.pageYOffset) {
        // safari
        window.pageYOffset = 260
      }
    },
    // 左侧菜单二级点击事件
    async clickMenuItem ({ key }) {
      await this.getSubjectMenu({ type: '3', pid: key, school_code: this.school_code });
      await this.filterSubject(key, 2);
      await this.refContentHeight();
      // 如果一个专业小类下只开放了一个专业，筛选资源时，选中专业小类后，右侧的默认选中专业，不显示全部
      if (this.majorData && this.majorData.length == 1) {
        this.clickItemChoice('major', this.majorData[0], "hidden")
      }
      await this.getResourceType({school_code: this.school_code,subject_big_id: this.currentLevel1.id ,subject_small_id: this.currentLevel2.id})
      await this.requestSzzykList(1, 2);

      this.currentPage = 1
    },
    // 思政资源库详情
    goDetails (item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        // if (item.is_open == 2) {
        let params = {
          id: item.id,
        };
        if (this.currentLevel1 && this.currentLevel1.id) {
          params.subject_big_id = this.currentLevel1.id
        }
        if (this.currentLevel2 && this.currentLevel2.id) {
          params.subject_small_id = this.currentLevel2.id
        }
        let routeUrl = this.$router.resolve({
          path: `/${this.school_code}/zy/szzyk/details`,
          query: params
        })
        window.open(routeUrl.href, utils.isMobile() ? '_self' : '_blank');
        // } else {
        //   // that.noAuthMessageModal()
        //   this.dialogConfig.name = "appleRes"
        // }
      })
    },
    // 展开更多
    screenMore (type) {
      if (type == 'major') {
        this.majorMore = !this.majorMore
      } else if (type == 'szys') {
        this.szysMore = !this.szysMore
      } else if (type == 'zsd') {
        this.zsdMore = !this.zsdMore
      } else if (type == 'alzy') {
        this.resourcesMore = !this.resourcesMore
      } else {
        this.areaMore = !this.areaMore
      }
    },
    // 多选 - 显示
    screenMultiple (type) {
      this.multipleType = type
      if (type == 'szys') {
        this.multipleSzys = JSON.parse(JSON.stringify(this.szElementData))
      } else if (type == 'zsd') {
        this.multipleZsd = JSON.parse(JSON.stringify(this.zsd))
      } else {
        this.multipleMajor = JSON.parse(JSON.stringify(this.majorData))
      }
    },
    // 多选- 取消
    cancelChoose (type) {
      this.multipleType = null;
      if (type == 'szys') {
        this.multipleSzys = []
      } else if (type == 'zsd') {
        this.multipleZsd = []
      } else {
        this.multipleMajor = []
      }
    },
    // 多选- 保存事件
    async saveChoose (type) {
      let that = this
      if (type == 'szys') {
        this.chooseSetELement(this.multipleSzys)
        // 保存一级思政元素选中的id szysOneChoose
        this.setOneSzysForeach(this.multipleSzys)
      } else if (type == 'zsd') {
        this.chooseSetZsd(this.multipleZsd)
        this.setOneZsdForeach(this.multipleZsd)
      } else {
        this.chooseSetMajor(this.multipleMajor)
      }
      this[`${type}ShowText`] = []
      this.multipleType = null;
      // 相关专业选中的id
      let majorCurrentId = [];
      let zsdCurrentId = []
      if (type == 'zsd') {
        this[`multipleZsd`].forEach((element,) => {
          if (element.id != 'all' && element.isActive) {
            this[`${type}ShowText`].push(element.name)
            zsdCurrentId.push(element.id)
          }
        })

      } else {
        this[`multiple${type == 'szys' ? 'Szys' : 'Major'}`].forEach((element,) => {
          if (element.id != 'all' && element.isActive) {
            this[`${type}ShowText`].push(element.name)
            majorCurrentId.push(element.id)
          }
        })
      }

      if (type == 'major') {
        await this.requestSzzykCondition({
          subject_small_id: that.currentLevel2 ? that.currentLevel2.id : '',
          subject_big_id: that.currentLevel1 ? that.currentLevel1.id : '',
          subject_major_id: majorCurrentId.length > 0 ? majorCurrentId.join(",") : ''
        })
      }
      this.requestSzzykList(1)
      that.currentPage = 1
    },
    // 保存一级思政元素选中的id szysOneChoose
    setOneSzysForeach (arr) {
      this.szysOneChoose = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].isActive) {
          this.szysOneChoose.push(arr[i].id)
        }
      }
    },
    setOneZsdForeach (arr) {
      this.zsdOneChoose = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].isActive) {
          this.zsdOneChoose.push(arr[i].name)
        }
      }
    },
    // 筛选项 - 多选点击事件
    clickItemCheckbox (type, item) {
      let that = this;
      if (type.indexOf("zsd") > -1) {
        that[`multipleZsd`].forEach((element, index) => {
          // chioce true 为多选
          let itemName = 'multipleZsd'
          if (item.id == 'all') {
            if (item.id == element.id) {
              that.$set(that[itemName][index], 'isActive', true)
            } else {
              that.$set(that[itemName][index], 'isActive', false)
            }
          } else {
            if (element.id == 'all') {
              that.$set(that[itemName][index], 'isActive', false)
            }
            if (item.id == element.id) {
              that.$set(that[itemName][index], 'isActive', !element.isActive)
            }
          }
        });
      } else {
        that[`multiple${type == 'szysOne' ? 'Szys' : 'Major'}`].forEach((element, index) => {
          // chioce true 为多选
          let itemName = `multiple${type == 'szysOne' ? 'Szys' : 'Major'}`
          if (item.id == 'all') {
            if (item.id == element.id) {
              that.$set(that[itemName][index], 'isActive', true)
            } else {
              that.$set(that[itemName][index], 'isActive', false)
            }
          } else {
            if (element.id == 'all') {
              that.$set(that[itemName][index], 'isActive', false)
            }
            if (item.id == element.id) {
              that.$set(that[itemName][index], 'isActive', !element.isActive)
            }
          }
        });
      }



    },
    // 筛选项 - 单选选点击事件
    async clickItemChoice (type, item, hidden) {
      let that = this;
      if (type == 'zsdOne') {
        if (item.id == 'all') {
          this.zsdOneChoose = []
          this.multipleZsd = []
        } else {
          this.zsdOneChoose = []
          this.zsdOneChoose[0] = item.name
        }
      }
      if (type == 'szysOne' || type == 'major' || type == 'zsdOne' || type == 'ztmk') {
        if (type == 'major') {
          await this.requestSzzykCondition({
            subject_small_id: that.currentLevel2 ? that.currentLevel2.id : '',
            subject_big_id: that.currentLevel1 ? that.currentLevel1.id : '',
            subject_major_id: item.id == 'all' ? '' : item.id
          })
        }
        let newArr = [];
        if (type == 'szysOne') {
          if (item.id == 'all') {
            this.szysOneChoose = []
          } else {
            this.szysOneChoose[0] = item.id
          }
          newArr = [...this.szElementData]
          if (item.id != 'all' && item.children && item.children.length > 0) {
            this.szysSecondArr = item.children;
            this.szysTwoChoose = { id: 'all' }
          } else {
            this.szysSecondArr = []
            this.szysTwoChoose = { id: 'all' }
          }
        } else if (type == 'zsdOne') {
          if (item.id == 'all') {
            this.zsdOneChoose = ['all']
            this.zsdShowText = []
          } else {
            this.zsdOneChoose[0] = item.name
            this.zsdShowText[0] = item.name
          }
          newArr = [...this.zsd]
        } else if (type == 'ztmk') {
          this.ztmk_item = item
          this.ztmkShowText = item.name
        } else {
          newArr = [...this.majorData];
        }
        if (type != "zsdOne") {
          let itemName = `${type == 'szysOne' ? 'szys' : 'major'}ShowText`
          this[itemName] = []
          newArr.forEach((element) => {
            if (item.id == element.id) {
              element.isActive = true
              this[itemName].push(element.name)
            } else {
              element.isActive = false
            }
          });
          if (item.id == 'all') {
            this[itemName] = []
          }
        } else {
          let itemName = `zsdOneShowText`
          this[itemName] = []
          newArr.forEach((element) => {
            if (item.id == element.id) {
              element.isActive = true
              this[itemName].push(element.name)
            } else {
              element.isActive = false
            }
          });
          if (item.id == 'all') {
            this[itemName] = []
          }
        }

      } else {
        this[type + 'Choose'] = item
      }
      this.requestSzzykList(1)
      that.currentPage = 1
      if (hidden) {
        this.majorShowText = [];
      }
    },
    // 清除数据
    async clearScreen (type) {
      let that = this;
      if (type == 'szys' || type == 'major' || type == 'zsd') {
        let itemName = `${type == 'szys' ? 'szElement' : 'major'}Data`
        that[itemName].forEach((element, index) => {
          // chioce true 为多选
          if (element.id == 'all') {
            that.$set(that[itemName][index], 'isActive', true)
          } else {
            that.$set(that[itemName][index], 'isActive', false)
          }
        });
        this[`${type}ShowText`] = []
        if (type == 'szys') {
          this.szysOneChoose = ['all']
          this.szysSecondArr = []
          this.szysTwoChoose = { id: 'all' }
        } else if (type == 'zsd') {
          this.zsdOneChoose = ['all']
          this.zsdShowText = []
          this.multipleZsd = []
        } else if (type == 'ztmk') {
          this.ztmkShowText = "全部"
          this.ztmk_item = { name: "全部", id: "" }
        } else {
          await this.requestSzzykCondition({
            subject_small_id: that.currentLevel2 ? that.currentLevel2.id : '',
            subject_big_id: that.currentLevel1 ? that.currentLevel1.id : '',
          })
        }
      } else {
        this[type + 'Choose'] = { [type == 'resType' ? 'type' : 'id']: 'all' }

      }
      await this.requestSzzykList(1)
      that.currentPage = 1
    },
    // 搜索点击事件
    searchEvent () {
      this.loading = true
      this.requestSzzykList(1)
      this.currentPage = 1
    },
    // 清除搜索事件
    clearSearchEvent () {
      this.searchVal = ''
      this.searchEvent()
    },
    changeOrderName (name) {
      this.order_name = name
      this.searchEvent()
    },
    changeOrderBy () {
      this.order_by = this.order_by == 'desc' ? 'asc' : 'desc'
      this.searchEvent()
    },
    // 请求思政资源列表数据
    async requestSzzykList (page, clear) {

      let that = this
      let params = {
        page: page,
        length: this.pageNum,
        school_code: that.school_code,
        favorite_flag: 1
      };
      if (this.searchVal) {
        params.q = this.searchVal
      }
      // if (this.order_by) {
      //   params.order_by = this.order_by
      // }
      if (this.order_name) {
        params.order_name = this.order_name
      }
      if (this.resTypeChoose && this.resTypeChoose.type != 'all') {
        params.type = this.resTypeChoose.type
      }
      if (this.currentLevel1 && this.currentLevel1.id) {
        params.subject_big_id = this.currentLevel1.id
      }
      if (this.currentLevel2 && this.currentLevel2.id) {
        params.subject_small_id = this.currentLevel2.id
      }
      if (this.szysTwoChoose && this.szysTwoChoose.id != 'all') {
        params.element_second_id = this.szysTwoChoose.id
      }
      if (this.areaChoose && this.areaChoose.id != 'all') {
        params.area_id = this.areaChoose.id
      }
      if (this.suggestChoose && this.suggestChoose.id != 'all') {
        params.suggest = this.suggestChoose.id
      }
      if (params.subject_small_id && params.subject_big_id) {
        let majorarr = [];
        this.majorData.forEach(element => {
          if (element.id != 'all' && element.isActive) {
            majorarr.push(element.id)
          }
        });
        if (majorarr.length > 0) {
          params.subject_major_id = majorarr.join(",")
        }
      }
      if (this.zsdOneChoose) {
        params.knowledge = this.zsdOneChoose.indexOf("all") > -1 ? '' : this.zsdOneChoose.join(",")
      }

      params.topic_id = this.ztmk_item.id
      let szysarr = [];
      this.szysOneChoose.forEach(element => {
        if (element != 'all') {
          szysarr.push(element)
        }
      });
      if (szysarr.length > 0) {
        params.element_first_id = szysarr.join(",")
      }
      if (clear) {
        // this.resetMajorData([]);
        this.majorShowText = [];
        this.areaChoose = { id: 'all' };
        if (clear == 1) {
          this.szysShowText = [];
          this.szysSecondArr = [];
          this.szysOneChoose = ['all']
          this.szysTwoChoose = { id: 'all' };
          this.zsdOneChoose = ['all']
          this.zsdShowText = []
          this.ztmk_item = { name: "全部", id: "" }
          this.ztmkShowText = "全部"
          params.element_first_id = null;
        } else if (clear == 2) {
          this.zsdOneChoose = ['all']
          this.zsdShowText = []
          this.ztmk_item = { name: "全部", id: "" }
          this.ztmkShowText = "全部"
          params.knowledge = ""
          params.topic_id = ""
        }
      }
      await this.getSzzykListData(params).then(() => {
        that.isInitData = true
        that.loading = false
        this.$nextTick(() => {
          let node = document.querySelectorAll(".pointContent")
          for (let i = 0; i < node.length; i++) {
            if (node[i].scrollWidth <= 180) {
              if (node[i].nextElementSibling) {
                node[i].nextElementSibling.style.opacity = 0
              }
            }
          }
        })
      })
      let countParams1 = {
        ...params,
        count: 1,
        school_code: that.school_code
      }
      await this.getSzzykListCount(countParams1)

    },

    // 请求列表总数
    async getSzzykListTotal () {
      let that = this
      let countParams = {
        count: 1,
        school_code: that.school_code
      }
      await this.getSzzykListCount(countParams).then(() => {
        that.isInitData = true
        that.loading = false
      })
    },
    // 判断是否登录显示登录弹窗
    async judgeLogin () {
      let query = this.$route.query.needLogin
      if (query && !this.isUserLogin) {
        this.goAppsDetailsBeforeLogin()
      }
    },
    toalzyk(item) {
      console.log(item);
      if(item) {
        this.$router.push({
          path: `/${this.school_code}/zy/alzyk`,
          query: {
            subjectBigId:  this.currentLevel1?.id,
            subjectSmallId:  this.currentLevel2?.id,
            casesType: item.id,
            flag: 1
          }
        })
      }
    }
  },
}
</script>

<style lang="less">
.bg-szzyk {
  .zyk-one-menu {
    .ant-menu-submenu-arrow {
      visibility: hidden;
    }
    .zyk-one-line {
      font-size: 15px;
      color: #272727;
      &::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 13px;
        margin-right: 10px;
        vertical-align: -1px;
        background: url('../../assets/image/szzyk/subject.png') no-repeat center
          center;
        background-size: 100% auto;
      }
      .zyk-line-icon {
        display: inline-block;
        width: 5px;
        height: 8px;
        position: absolute;
        top: 50%;
        right: 16px;
        background: url('../../assets/image/szzyk/off.png') no-repeat center
          center;
        background-size: 100% auto;
        transform: translateY(-4px);
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    .ant-menu-submenu-title {
      font-size: 15px;
      color: #272727;
      height: 32px;
      line-height: 32px;
    }
    .ant-menu-item {
      color: #676767;
      font-size: 15px;
      height: 32px;
      line-height: 32px;
    }
    .ant-menu-item.ant-menu-item-selected,
    .ant-menu-item-active {
      color: #ee373d !important;
      background-color: #fff;
    }
    .ant-menu-inline .ant-menu-item-selected::after {
      border-color: #ee373d;
    }
  }
  .zyk-one-menu.ant-menu-submenu-open {
    .zyk-one-line {
      color: #cf0d04;
      font-weight: bold;
      .zyk-line-icon {
        display: inline-block;
        width: 9px;
        height: 5px;
        position: absolute;
        top: 50%;
        right: 16px;
        background: url('../../assets/image/szzyk/open-active.png') no-repeat
          center center;
        background-size: 100% auto;
        transform: translateY(-3px);
      }
    }
    .zyk-one-line::before {
      background: url('../../assets/image/szzyk/subject-active.png') no-repeat
        center center;
      background-size: 100% auto;
    }
  }
}
</style>

<style lang="less" scoped>
.bg-szzyk {
  background-color: #f5f7fa;
}
.top-banner-box {
  width: 100%;
  height: 140px;
  background: url('../../assets/image/szzyk/banner.png') no-repeat center center;
  background-size: cover;
  margin-bottom: 24px;
  position: relative;
  .szysk-button {
    height: 34px;
    display: inline-block;
    line-height: 34px;
    background: #a10a08;
    border-radius: 17px;
    padding: 0 17px;
    margin: 43px 0 0;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    float: right;
    cursor: pointer;
    &::before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 12px;
      margin-right: 8px;
      background: url('../../assets/image/szzyk/yskbtn.png') no-repeat center
        center;
      background-size: auto 100%;
    }
  }
}
.outer-list {
  padding-bottom: 60px;
}
.inner-list-box {
  width: 100%;
}
.menu-subject-box {
  vertical-align: top;
  display: inline-block;
  width: 228px;
  min-height: 500px;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(130, 130, 130, 0.2);
  border-radius: 2px;
  .label-menu {
    width: 100%;
    text-align: center;
    padding: 20px 0 15px;
    border-bottom: 2px solid #f5f7fa;
  }
  .menu-outer {
    padding: 10px 0;
    .ant-menu-inline {
      border-right: none;
    }
  }
}
.data-all-box {
  width: 942px;
  min-height: 1000px;
  display: inline-block;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 0 0 35px;
  position: relative;
  // overflow: hidden;

  .current-subject {
    padding: 0 45px;
    height: 66px;
    line-height: 66px;
    border-bottom: 2px solid #f5f7fa;
    position: relative;
    .add-res {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      background: #e64757;
      width: 85px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      cursor: pointer;
      color: #fff;
      border-radius: 14px;
      font-size: 14px;
    }
    &::before {
      content: '';
      margin-right: 16px;
      display: inline-block;
      width: 5px;
      height: 18px;
      background: #dd363a;
      border-radius: 0px 3px 3px 0px;
      vertical-align: -2px;
    }
    .lable1 {
      font-size: 18px;
      font-weight: bold;
      color: #2a2a2a;
    }
    .lable2 {
      font-size: 18px;
      color: #2a2a2a;
    }
  }
  .screen-all-box {
    padding: 15px 30px 0px 45px;
    .screen-lines {
      // display: flex;
      padding: 0 0 10px;
      // justify-content: space-between;
      height: auto;

      .understand {
        display: inline-block;
        font-size: 13px;
        padding: 5px 0 0 15px;
        color: #004ce2;
        text-decoration: underline;
      }
      &.overflow {
        height: 36px;
        overflow: hidden;
        &.zsd {
          height: 86px;
        }
      }
      .screen-label {
        display: inline-block;
        vertical-align: top;
        // font-weight: bold;
        // color: #11233F;
        color: #676f7f;
        font-size: 14px;
        padding: 4px 0 0;
        width: 70px;
        text-align: right;
      }
      .screen-content {
        // flex: 1;
        display: inline-block;
        width: 690px;
        margin-right: 10px;
        .items {
          cursor: pointer;
          display: inline-block;
          font-weight: 400;
          color: #333;
          font-size: 13px;
          padding: 5px 15px;
          &.active,
          &:hover {
            color: #ee373d;
            font-weight: bold;
          }
        }
      }
      .screen-operation {
        float: right;
        text-align: right;
        padding: 4px 0 0;
        cursor: pointer;
        .select-btn {
          display: inline-block;
          font-size: 12px;
          color: #474747;
          vertical-align: 2px;
          &:hover {
            color: #ee373d;
          }
          &::after {
            content: '';
            display: inline-block;
            width: 12px;
            height: 11px;
            background: url('../../assets/image/szzyk/plus.png') no-repeat
              center;
            background-size: 100% auto;
            margin-left: 6px;
          }
        }
        .more-btn {
          display: inline-block;
          font-size: 12px;
          color: #474747;
          float: right;
          margin-left: 12px;
          &:hover {
            color: #ee373d;
          }
          &::after {
            content: '';
            display: inline-block;
            width: 7px;
            height: 11px;
            background: url('../../assets/image/szzyk/more-icon.png') no-repeat
              center;
            background-size: 100% auto;
            margin-left: 6px;
          }
        }
      }
    }
  }
  .select-all-box {
    padding: 10px 45px 20px;
    .first-lines {
      .line-label {
        color: #11233f;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
      }
      .line-words {
        display: inline-block;
        .word-item {
          display: inline-block;
          font-size: 13px;
          color: #676f7f;
          margin: 0 20px;
          cursor: pointer;
          &.active,
          &:hover {
            color: #ee373d;
          }
        }
      }
      .line-button {
        float: right;
        display: inline-block;
        .btn {
          width: 48px;
          height: 26px;
          border-radius: 15px;
          text-align: center;
          font-size: 12px;
          background: #eff1f4;
          line-height: 26px;
          display: inline-block;
          margin-left: 10px;
          color: #dd363a;
          cursor: pointer;
          &:hover {
            background: linear-gradient(162deg, #d8292d 0%, #d43d29 100%);
            color: #fff;
          }
        }
      }
    }
    .select-groups {
      padding: 20px 0;
      .select-items {
        cursor: pointer;
        display: inline-block;
        font-weight: 400;
        color: #676f7f;
        font-size: 13px;
        padding: 5px 30px 5px 0;
        &.active,
        &:hover {
          color: #ee373d;
          font-weight: bold;
        }
      }
    }
  }
  .screen-current-box {
    padding: 15px 45px 10px;
    background: linear-gradient(93deg, #fffbf0 0%, #fefaf0 100%);
    display: flex;
    .left-label {
      font-size: 14px;
      font-weight: bold;
      color: #da5b2f;
      margin-right: 15px;
      padding-top: 6px;
    }
    .current-list {
      flex: 1;
      .current-opt {
        height: 30px;
        line-height: 30px;
        background: #ffffff;
        padding: 0 15px;
        border: 1px solid #eaeaea;
        box-shadow: 0px 0px 6px 0px rgba(130, 130, 130, 0.14);
        border-radius: 15px;
        color: #676f7f;
        font-size: 13px;
        display: inline-block;
        margin-right: 12px;
        margin-bottom: 10px;
        .current-text {
          display: inline-block;
          max-width: 600px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: top;
        }
        .clear-btn {
          cursor: pointer;
          display: inline-block;
          width: 8px;
          height: 8px;
          background: url('../../assets/image/szzyk/close.png') no-repeat center;
          background-size: 8px auto;
          margin-left: 10px;
          vertical-align: 1px;
        }
      }
    }
  }
  .model-szzyk-box {
    padding: 15px 30px 14px 45px;
    .left-title-box {
      font-size: 18px;
      font-weight: bold;
      color: #cf0d04;
      display: inline-block;
      &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../../assets/image/home/label-star.png') no-repeat
          center;
        background-size: 100% auto;
        margin-right: 15px;
        vertical-align: -3px;
      }
    }
    .sort-middle-box {
      margin-left: 35px;
      color: #acafb6;
      font-size: 14px;
      font-weight: bold;
      line-height: 34px;
      height: 34px;
      display: inline-block;
      cursor: pointer;
      &::after {
        content: '';
        display: inline-block;
        width: 8px;
        height: 10px;
        margin-left: 5px;
      }
      &::after {
        content: '';
        background: url('../../assets/image/szzyk/sort.png') no-repeat center;
        background-size: 100% auto;
      }
      &.active {
        color: #f47874;
      }
      &.active::after {
        content: '';
        background: url('../../assets/image/szzyk/sort-hover.png') no-repeat
          center;
        background-size: 100% auto;
      }
    }
    .right-search-box {
      float: right;
      width: 260px;
      height: 34px;
      background: #ffffff;
      border: 1px solid #f8818d;
      border-radius: 16px;
      display: flex;
      align-items: center;
      .frame-input {
        flex: 1;
        input {
          font-size: 14px;
          margin-left: 15px;
          border: none;
          outline: none;
          height: 28px;
          line-height: 28px;
          &:focus-visible {
            border: none;
            outline: none;
          }
        }
      }
      .clear-input {
        width: 30px;
        height: 30px;
        background: url('../../assets/image/common/clear.png') no-repeat center
          center;
        background-size: 15px auto;
        cursor: pointer;
      }
      .search-button {
        width: 60px;
        height: 34px;
        line-height: 34px;
        background: #e64757;
        border-radius: 0px 16px 16px 16px;
        text-align: center;
        cursor: pointer;
        font-size: 13px;
        color: #f5f7fa;
      }
      .search-button.disabled {
        pointer-events: none;
      }
    }
  }
  .resource-list {
    width: 100%;
    padding: 0 30px 20px 45px;
    min-height: 400px;
    display: block;
    position: relative;
    .resource-opt {
      cursor: pointer;
      width: 100%;
      border-bottom: 1px solid #f3f3f3;
      padding: 18px 14px 18px 9px;
      position: relative;
      .res-title {
        font-size: 15px;
        color: #454545;
        font-weight: bold;
        width: 100%;
        margin-bottom: 8px;
        .title-span {
          max-width: 90%;
          display: inline-block;
          vertical-align: middle;
        }
        &.ov87 {
          .title-span {
            max-width: 87%;
          }
        }
        .badge {
          width: 65px;
          height: 20px;
          background-image: url('../../assets/image/szzyk/school-badge.png');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          display: inline-block;
          vertical-align: middle;
          margin-left: 12px;
        }
        .resopen {
          font-size: 12px;
          color: #ffffff;
          background: #ee373d;
          padding: 2px 6px;
          border-radius: 6px;
        }
        &::before {
          content: '';
          display: inline-block;
          margin-right: 6px;
          width: 25px;
          height: 23px;
          vertical-align: -4px;
        }
        &.word::before {
          background: url('../../assets/image/home/word.png') no-repeat center;
          background-size: 100% auto;
        }
        &.video::before {
          background: url('../../assets/image/home/MP4.png') no-repeat center;
          background-size: 100% auto;
        }
      }
      .res-all-box {
        width: 100%;
        display: flex;
        .res-left-img {
          width: 122px;
          height: 69px;
          border-radius: 2px;
          background-size: 100% auto;
          background-position: center center;
          background-repeat: no-repeat;
          margin-right: 20px;
        }
        .res-right-con {
        }
        .brief {
          width: 100%;
          max-height: 40px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-size: 13px;
          color: #a3a5aa;
          line-height: 20px;
          margin-bottom: 10px;
        }
        .advise-box {
          display: flex;
          margin-bottom: 5px;
          .advise-item {
            color: #b75031;
            font-size: 12px;
            display: flex;
            min-width: 200px;
            margin-right: 20px;
            .pointContent {
              font-size: 12px;
              color: #b75031;
              max-width: 180px;
              overflow: hidden;
              // text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .more {
            display: inline-block;
            position: relative;

            .more-text {
              display: inline-block;
              margin-left: 10px;
              width: 10px;
              height: 10px;
              background: url('../../assets/image/szzyk/more.png');
              background-size: cover;
              position: relative;
              span {
                position: absolute;
                letter-spacing: -1px;
                top: 38%;
                left: 48%;
                transform: translate(-50%, -50%);
              }
            }
            .more-group {
              opacity: 0;
              padding: 8px 3px 3px;
              position: absolute;
              bottom: 25px;
              left: 50%;
              transform: translateX(-50%);
              background: #fff;
              transition: all 0.5s;
              // width: 300px;
              max-width: 400px;
              width: max-content;
              box-shadow: 0px 0px 8px 0px rgba(103, 106, 110, 0.3);
              border-radius: 4px;
              z-index: -1;
              &::before {
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                border-top: 8px solid #ffffff;
                border-right: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 8px solid transparent;
                position: absolute;
                left: 50%;
                bottom: -16px;
                transform: translate(-50%, 0);
              }
              .more-item {
                margin: 0 5px 5px;
                padding: 4px 8px;
                background: #f8f9fb;
                color: #666666;
                font-size: 12px;
                display: inline-block;
              }
            }
            &:hover .more-group {
              opacity: 1;
              z-index: 1;
            }
          }
        }
        .little-box {
          flex: 1;
          display: flex;
          justify-content: space-between;
          height: 24px;
          align-items: center;
          width: 827px;
        }
        .left-tags {
          flex: 1;
          .tag-opt {
            color: #a3a5aa;
            font-size: 12px;
            display: inline-block;
            min-width: 200px;
            margin-right: 20px;
            // &.timer, &.author {
            //   min-width: 140px;
            // }
            &.timer::before {
              content: '';
              display: inline-block;
              margin-right: 10px;
              width: 10px;
              height: 10px;
              background: url('../../assets/image/szzyk/timer.png') no-repeat
                center;
              background-size: 100% auto;
            }
            &.author::before {
              content: '';
              display: inline-block;
              margin-right: 10px;
              width: 10px;
              height: 10px;
              background: url('../../assets/image/szzyk/resource.png') no-repeat
                center;
              background-size: 100% auto;
            }
            &.computed {
              font-size: 12px;
              .nums {
                display: inline-block;
                &::after {
                  content: '';
                  display: inline-block;
                  width: 1px;
                  height: 10px;
                  background: #e8e8e8;
                  border: 0px solid #151431;
                  margin: 0 10px;
                }
                &:last-child::after {
                  display: none;
                }
              }
            }
          }
        }
        .right-operat-box {
          display: none;
          width: 120px;
          text-align: right;
          .operat-item {
            width: 82px;
            text-align: center;
            height: 24px;
            line-height: 24px;
            background: #ffffff;
            border: 1px solid #dd363a;
            border-radius: 12px;
            color: #df4332;
            display: inline-block;
            cursor: pointer;
            &:hover {
              border: 1px solid transparent;
              background: linear-gradient(162deg, #dd363a 0%, #df4531 100%);
              color: #ffffff;
            }
            &.star {
              &::before {
                content: '';
                display: inline-block;
                width: 18px;
                height: 18px;
                margin-right: 4px;
                background: url('../../assets/image/home/star.png') no-repeat
                  center;
                background-size: 100% auto;
                vertical-align: -3px;
              }
              &:hover::before {
                background: url('../../assets/image/home/star-active.png')
                  no-repeat center;
                background-size: 100% auto;
              }
            }
            &.active.star {
              width: 112px;
              &::before {
                background: url('../../assets/image/home/star-active.png')
                  no-repeat center;
                background-size: 100% auto;
              }
            }
          }
        }
      }
      &:hover {
        background: linear-gradient(0deg, #ffffff 0%, #f4f4f4 100%);
        border-radius: 2px;
        border-bottom: 1px solid #fff;
      }
      &:hover .right-operat-box {
        display: block;
      }
    }
  }
  .loading-data {
    width: 100%;
    height: 100%;
    .loading-mask {
      position: absolute;
      top: 250px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f3f3f3;
      opacity: 0.5;
    }
    .loading-text {
      position: absolute;
      top: 350px;
      color: #000;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.red-color {
  color: #df4332;
}
.loading-data {
  font-size: 13px;
  color: #a3a5aa;
  text-align: center;
  padding-top: 100px;
}
.empty {
  width: 14px;
  opacity: 0;
  display: inline-block;
}
</style>