<template>
  <div>
    <SdcmHeader :active="'/'+school_code+'/sdcmzyxy/'+flag" />
    <div class="sdcm-container-box">
      <div class="content-box w1280">
        <template>
          <div class="left-sidebar" :style="{ backgroundImage: `url(${sideBarBg})` }" v-if="flag=='zcwj'">
            <div :class="['side-bar',position?'position':'']" ref="sideBar">
              <div class="header-box"></div>
              <div class="body-box">
                <div :class="['item',currentIndex==index?'active':'']" v-for="(item,index) in menuData" :key="index" @click="currentSideBar(index,item)">
                  <div class="star"></div>
                  <div class="text">{{item.name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="left-sidebar common" :style="{ backgroundImage: `url(${sideBarBg})` }">

          </div>
        </template>

        <div class="right-content">

          <router-link to="/sdcmzyxy/zcwj">
            <router-link class="btn-return" :to="flag=='xwdt'?`/${school_code}`:`/${school_code}/sdcmzyxy/${flag}`" v-text="'< 返回' + returnText + '列表'"></router-link>
          </router-link>
          <div class="clearfix"></div>
          <template>
            <!-- <div class="article-box" v-if="detailData && detailData.publish_date">
              <div class="header-box">
                <div class="left-date">
                  <div class="date-year-month">{{detailData.publish_date.substring(5,10)}}</div>
                  <div class="date-day">{{detailData.publish_date.substring(0,4)}}</div>
                </div>
                <div class="right-title">
                  <div class="title-text">
                    {{detailData.title}}
                  </div>
                  <div class="source">来源来源来源</div>
                </div>
              </div>
              <div class="body-box" v-html="detailData.detail"></div>
            </div> -->
            <iframe :src="detailData.url || detailData.iframeUrl" frameborder="0" id="cwin" :style="`height:${height + 50}px`"></iframe>
          </template>
        </div>
      </div>
    </div>
    <SdcmFooter :color="'#3e140b'" />
  </div>
</template>

<script>
import SdcmHeader from "../../components/SdcmHeader.vue"
import SdcmFooter from "../../components/SdcmFooter.vue"
import sdcmData from "./sdcmData"
export default {
  data () {
    return {
      allData: sdcmData.allData,
      detailData: {},
      flag: "",
      sideBarBg: "",
      returnText: "",
      school_code: localStorage.getItem("schoolCode"),
      height: 400,
      menuData: [
        { name: "全部", path: "zcwj" },
        { name: "职业教育", path: "zcwj/zyjyl" },
        { name: "思政教育", path: "zcwj/szjyl" },
        { name: "课程思政", path: "zcwj/kcszl" },
        { name: "传媒行业", path: "zcwj/cmxyl" },
      ],
      currentIndex: -1,
      position: false,

    }
  },
  components: {
    SdcmHeader,
    SdcmFooter
  },
  mounted () {
    this.searchData()
    this.getSource()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    this.$nextTick(() => {
      this.SetCwinHeight()
    })

    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll)
    })
    this.handleScroll()


  },
  methods: {
    handleScroll () {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset;
      if (scrollTop <= 1) {
        document.body.scrollTop = 1
        document.documentElement.scrollTop = 1
        window.pageYOffset = 1
      } else {
        if (scrollTop >= 218) {
          this.position = true
        } else {
          this.position = false
        }
      }
    },
    getSource () {
      this.flag = this.$route.query.flag
      this.sideBarBg = require(`../../assets/image/sdcmzyxy/slidebar/${this.flag}.png`)
      this.returnText = this.flag == 'jsdt' ? '建设动态' : this.flag == 'zcwj' ? '政策文件' : this.flag == 'mtbd' ? '媒体报道' : this.flag == 'dxjy' ? '典型经验' : ''
    },
    searchData () {
      let id = this.$route.params.id
      this.allData.forEach(item => {
        if (item.id == id) {
          this.detailData = item
        }
      })
      if (!this.detailData.title) {
        this.detailData = this.$route.query
      }
    },

    SetCwinHeight () {
      let that = this
      window.addEventListener('message', function (event) {
        const data = event;
        that.height = data.data.height
      });
    },


    currentSideBar (index, item) {
      window.open(`/${this.school_code}/sdcmzyxy/zcwj?select=${index}&paramsName=${item.path}`)
    },
  }
}
</script>


<style lang="less" scoped>
* {
  transition: all 0.3s;
}
.w1280 {
  width: 1280px;
  margin: 0 auto;
}
.sdcm-container-box {
  background-image: url('../../assets/image/sdcmzyxy/zcwj-body.png');
  background-size: cover;
  padding: 22px 0 44px;

  .content-box {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 0 39px 0 43px;
    position: relative;
    min-height: 700px;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 203px;
      left: 0;
      background-size: cover;
      width: 637px;
      height: 563px;
      background-image: url('../../assets/image/sdcmzyxy/zcwj-bg.png');
      z-index: 1;
    }
  }
  .left-sidebar.common {
    width: 264px;
    height: 125px;
    background-image: url('../../assets/image/sdcmzyxy/slidebar/zcwj.png');
    background-size: cover;
  }
  .right-content {
    z-index: 10;
    padding-top: 22px;
    flex: 1;
    margin-left: 80px;

    .btn-return {
      width: 179px;
      height: 28px;
      background: linear-gradient(90deg, #fdeac6 0%, #fffaf1 100%);
      border-radius: 14px 0px 0px 14px;
      text-align: center;
      line-height: 28px;
      cursor: pointer;
      color: #9b2020;
      float: right;
    }
    .header-box {
      display: flex;
      padding: 20px 0 30px;
      border-bottom: 1px solid #f4f4f4;

      .left-date {
        margin-right: 22px;
        .date-year-month {
          width: 70px;
          height: 24px;
          background: #9b2020;
          line-height: 24px;
          text-align: center;
          font-size: 16px;
          color: #ffffff;
        }
        .date-day {
          width: 70px;
          height: 30px;
          background: #fff6f6;
          line-height: 30px;
          text-align: center;
          font-size: 16px;
          color: #840000;
        }
      }
      .right-title {
        width: 748px;
        .title-text {
          font-size: 22px;
          font-weight: bold;
          color: #262626;
          line-height: 28px;
        }
        .source {
          font-size: 12px;
        }
      }
    }
    ::v-deep .body-box {
      padding-top: 30px;
      p {
        font-size: 16px;
        color: #333333;
        line-height: 28px;
        text-indent: 2em;
      }
      img,
      video {
        max-width: 100%;
      }
      .vsbcontent_img {
        text-align: center;
      }
    }
  }
}

::v-deep #xxgk_content_redheadbg h1 {
  font-size: 20px;
  font-weight: bold;
  padding: 30px 0;
  font-size: 20px;
  text-align: center;
  font-family: '微软雅黑';
  color: #4b4b4b;
}
iframe {
  width: 100%;
  padding-top: 30px;
}

.left-sidebar {
  width: 264px;
  height: 125px;
  background-size: cover;
  z-index: 10;
  .side-bar {
    z-index: 10;
    &.position {
      position: fixed;
    }
    width: 264px;
    float: left;
    top: 0px;
    .header-box {
      height: 123px;
      background-image: url('../../assets/image/sdcmzyxy/slidebar/zcwj.png');

      background-size: cover;
    }
    .body-box {
      padding-left: 38px;
      .item {
        cursor: pointer;
        width: 226px;
        height: 60px;
        line-height: 60px;
        background-color: #fff;
        border-bottom: 1px solid #f3f3f3;
        padding: 0 14px;
        display: flex;
        align-items: center;
        .star {
          width: 18px;
          height: 17px;
          background-image: url('../../assets/image/sdcmzyxy/slidebar/star.png');
          background-size: cover;
          margin-right: 8px;
        }
        .text {
          font-size: 17px;
          color: #262626;
        }
        &.active {
          background: #c00800;
          border: none;
          .star {
            background-image: url('../../assets/image/sdcmzyxy/slidebar/star-active.png');
          }
          .text {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>