import services from '../assets/js/services'
export default {
  state: {
    szztkList: [],
    szztkTotal: 0,
    szztkCategory: [],
    loading: false,
    szztkKeyWords: [],
    areaRegionInfo: null, //区域专题开通信息
  },
  getters: {
  },
  actions: {
    // 获取思政专题库列表
    getSzztkList (context, payload) {
      context.state.loading = true
      return services.getSzztkList(payload).then(res => {
        context.commit('setSzztkList', res.data || [])
      })
    },

    // 获取思政专题库总数
    getSzztkTotal (context, payload) {
      return services.getSzztkList(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSzztkTotal', res.data || [])
        }
      })
    },

    // 获取思政专题库分类
    getSzztkCategory (context, payload) {
      return services.getSzztkCategory(payload).then(res => {
        // if (res.code == '0000') {
        context.commit('setSzztkCategory', res.data || [])
        // }
      })
    },

    // 获取思政专题库热词
    getSzztkKeyWords (context, payload) {
      return services.getSzztkKeyWords(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSzztkKeyWords', res.data || [])
        }
      })
    },
    // 区域专题开通信息
    getAreaRegionInfo (context, payload) {
      return services.getAreaSzztkRegion(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setAreaRegionInfo', res.data || null)
        } else {
          context.commit('setAreaRegionInfo', null)
        }
        return res;
      })
    }
  },
  mutations: {
    // 设置思政专题库列表
    setSzztkList (state, payload) {
      state.szztkList = payload.items
      state.loading = false
    },
    // 设置思政专题库总数
    setSzztkTotal (state, payload) {
      state.szztkTotal = payload.total
    },
    // 设置思政专题库分类
    setSzztkCategory (state, payload) {
      state.szztkCategory = [{ name: "推荐专题", id: "recommend", pid: "" },{ name: "全部专题", id: "", pid: "" }, ...payload]
    },
    // 设置思政专题库热词
    setSzztkKeyWords (state, payload) {
      state.szztkKeyWords = payload
    },
    setAreaRegionInfo (state, payload) {
      state.areaRegionInfo = payload
    }

  }
}