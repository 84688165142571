<template>
  <div class="xsgj-list">
    <div class="headerber"></div>
    <div class="content">
      <div class="xsgj-title"></div>
      <div class="xsgj-content">
        <div class="xsgj-item" v-for="item,ind in xsgjList" :key="ind" @click="toDetail(item)">
          <div class="img-content"  :style="{backgroundImage: item.cover? `url(${item.cover})` : ''}"></div>
          <div class="item-title">{{item.title}}</div>
        </div>
      </div>
      <div class="jdgj-title"></div>
      <div class="jdgj-content">
        <div class="jdgj-top">
          <div class="top-item" v-for="item,ind in jdgjList.slice(0,3)" :key="ind" :style="{backgroundImage: item.cover? `url(${item.cover})` : ''}" @click="toDetail(item)"></div>
        </div>
        <div class="jdgj-bottom">
          <div class="bottom-item" v-for="item,ind in jdgjList.slice(3,jdgjList.length)" :key="ind" :style="{backgroundImage: item.cover? `url(${item.cover})` : ''}" @click="toDetail(item)"></div>
       
        </div>

      </div>
       <div v-if="pageNum > 1" class="pagination">
          <a-pagination
            v-model="page"
            :total="total"
            :page-size="9"
            @change="onPagesChange"
            size="small"
            :item-render="itemRender"
          />
        </div>
    </div>
  </div>
</template>

<script>
import services from "../../../assets/js/services.js";
import { mapActions } from "vuex";
import utils from "../../../assets/js/utils.js";

export default {
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      xsgjList: [],
      jdgjList: [],
      page: 1,
      total: 0,
      pageNum:0
    };
  },
 async created() {
    let res = await this.getAppsLoginUserInfo();
    this.isChrome = utils.bowerUserAgent();
    if (res.code != "0000") {
      this.$message.info("请先登录！");
      this.$router.push({ path: `/${this.school_code}/gjjs/gjjsIndex` });
    }
    await this.getXsgjList()
    await this.getjdgjList()
      if(this.$route.query.ind) {
      if(this.$route.query.ind == 0) {
        window.scrollTo(0,500)
      }
      if(this.$route.query.ind == 1) {
        window.scrollTo(0,1624)
      }
    }
  },
  mounted() {
    if(this.$route.query.ind) {
      if(this.$route.query.ind == 0) {
        window.scrollTo(0,500)
      }
      if(this.$route.query.ind == 1) {
        window.scrollTo(0,1624)
      }
    }
 
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin","getAppsLoginUserInfo"]),
      async getXsgjList() {
      let res = await services.getAreaSzztkLists({
        page: 1,
        length: 8,
        count: 0,
        school_code: this.school_code,
        special_id: this.specialId,
        kind: "1",
        column_name: "习说工匠",
      });
      if (res.code == "0000" && res.data.items.length > 0) {
        this.xsgjList = res.data.items
     
      }
    },
      async getjdgjList() {
      // let that = this
      let res = await services.getAreaSzztkLists({
        page: this.page,
        length: 9,
        count: 0,
        school_code: this.school_code,
        special_id: this.specialId,
        kind: "1",
        column_name: "解读工匠",
        
      });
      if (res.code == "0000" && res.data.items.length > 0) {
        this.jdgjList = res.data.items;
        this.pageNum = res.data.page_num;
          this.total = res.data.total;
      } else {
        this.jdgjList = [];
        this.pageNum = [];
          this.total = [];
      }
    },
     toDetail(item) {
      if(item) {
        this.goAppsDetailsBeforeLogin().then(() => {
          this.$router.push({
            path: `/${this.school_code}/gjjs/detail`,
            query: {
              id: item.id,
              column_id: item.column_id,
              columnPath: "jdgj"
            },
          })
        })
      }
    },
    onPagesChange(val) {
      this.page = val;
      this.getjdgjList();
    },
      itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
  }
};
</script>

<style lang="less" scoped>
.xsgj-list {
  
  .headerber {
    width: 100%;
    height: 400px;
    // background: #000;
    background-image: url("../../../assets/image/gjjs/index/nav-xsgj/xsgj-banner.png");
    background-size: cover;
    background-position: center;
  }
  .content {
    padding-top: 47px;
    background-image: url("../../../assets/image/gjjs/xsgj/xsgj-bg.png");
    padding-bottom: 40px;
    background-color: #FFF8EB;
    background-repeat: no-repeat;
    background-position: center;
    .xsgj-title {
      width: 1200px;
      height: 92px;
      background-size: cover;
      margin: 0 auto 32px;
      background-image: url("../../../assets/image/gjjs/xsgj/xsgj-list-tit.png");
    }
     .jdgj-title {
      width: 1200px;
      height: 75px;
      background-size: cover;
      margin: 26px auto 30px;
      background-image: url("../../../assets/image/gjjs/xsgj/jdgj-list-tit.png");
    }
    .xsgj-content {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: space-between;
      .xsgj-item {
        margin-bottom: 30px;
        width: 278px;
        height: 450px;
        cursor: pointer;
        .img-content {
          width: 100%;
          height: 410px;
          // background-color: #d1c4c5;
          background-image: url("../../../assets/image/gjjs/index/nav-index/default-bg.png");
          background-size: cover;
          background-position: center;
        }
        .item-title {
          width: 278px;
          height: 40px;
          background: #ffb773;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 40px;
          padding: 0 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &::before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 14px;
            background: #b6260a;
            vertical-align: -1px;
            margin-right: 8px;
          }
        }
      }
    }
    .jdgj-content {
      width: 1200px;
      margin: 0 auto;
      .jdgj-top {
        width: 100%;
        height: 454px;
        display: grid;
        cursor: pointer;
        grid-template-columns: repeat(3, 386px);
        grid-template-rows: repeat(2, 217px);
        grid-template-areas:
          "a a b"
          "a a c";
        grid-gap: 22px 21px;
        .top-item {
          // background: #000;
          background-image: url("../../../assets/image/gjjs/index/nav-index/default-bg.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          &:nth-child(1) {
            grid-area: a;
          }
          &:nth-child(2) {
            grid-area: b;
          }
          &:nth-child(3) {
            grid-area: c;
          }
        }
      }
      .jdgj-bottom {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .bottom-item {
          // background-color: #ff6060;
          background-image: url("../../../assets/image/gjjs/index/nav-index/default-bg.png");
          margin-top: 22px;
          width: 386px;
          cursor: pointer;
          height: 217px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
     .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 25px;
        z-index: 2;
        ::v-deep.ant-pagination {
          .ant-pagination-prev,
          .ant-pagination-next {
            margin: 0 15px;
            a {
              color: #cb4d2e;
            }
          }
          .ant-pagination-item {
            margin: 0 5px;
            border-radius: 0;
            a {
              color: #cb4d2e;
            }
            &.ant-pagination-item-active {
              background-color: #cb4e2e;
              border: 1px solid transparent;
              a {
                color: #fff;
              }
            }
          }
        }
      }
  }
}
</style>