<template>
  <div class="jsfz-swiper">
    <div class="swiper">
      <swiper :options="jsfzSwiperOption">
        <swiper-slide v-for="(item, ind) in list" :key="ind">
          <div
            class="content-item"
            v-for="elem in item"
            :key="elem.id"
            :data-kcid="elem.id"
            :data-kcName="elem.name"
          >
            <div class="item-img">
              <div
                :style="`background-image: url('${elem.list_photo}');`"
                :data-kcid="elem.id"
                :data-kcName="elem.name"
              ></div>
            </div>
            <div
              class="item-name"
              :data-kcid="elem.id"
              :data-kcName="elem.name"
            >
              {{ elem.name }}
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div :class="['swiper-pagination', btnval]"></div>

      <div :class="['swiper-button-next', btnval]"></div>
      <div :class="['swiper-button-prev', btnval]"></div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { mapActions } from "vuex";
import services from "../assets/js/services";

export default {
  name: "jsfzSwiper",
  props: ["list", "btnval"],
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    let self = this;
    return {
      school_code: localStorage.getItem("schoolCode"),

      jsfzSwiperOption: {
        loop: true,
        // preventClicksPropagation: false,
        navigation: {
          nextEl: `.swiper-button-next.${this.btnval}`,
          prevEl: `.swiper-button-prev.${this.btnval}`,
        },
        pagination: {
          el: `.swiper-pagination.${this.btnval}`,
        },
        on: {
          click: function (v) {
            console.log(v.target.getAttribute("data-kcid"));
            let id = v.target.getAttribute("data-kcid");
            let kcName = v.target.getAttribute("data-kcName");
            if (id != null || kcName != null) {
              self.goToClassDetail({ id, name: kcName });
            }
          },
        },
      },
    };
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),

    goToClassDetail(item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(
          `${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.id}&category_name=${item.name}&cm_id=${item.id}`
        );
      });
    },
  },
};
</script>

<style lang="less" scoped>
.jsfz-swiper {
  margin-top: 28px;
  position: relative;
  .swiper {
    height: 630px;
    .swiper-container {
      height: 590px;
      .swiper-slide {
        display: flex;
        flex-wrap: wrap;

        .content-item {
          cursor: pointer;
          background: #fff;
          padding-top: 22px;

          width: 372px;
          height: 281px;
          border: 1px solid #e8d9c5;
          margin-left: 42px;

          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          &:nth-child(n + 4) {
            margin-top: 28px;
          }
          &:hover .item-img div {
            transform: scale(1.1);
          }
          .item-img {
            height: 209px;
            width: 100%;
            background: #c3c3cc;
            overflow: hidden;
            div {
              transition: all 0.5s;
              width: 100%;
              height: 100%;
              background-size: 100% auto;
              background-position: center;
            }
          }
          .item-name {
            font-size: 18px;
            font-weight: 400;
            color: #3a3a3a;
            margin: 14px 20px 18px 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 29px;
      height: 58px;
      top: 45%;
    }
    .swiper-button-prev {
      background-image: url("../assets/image/xxzyk/jsfz/jsfz-prev.png");
      left: -40px;
    }
    .swiper-button-next {
      background-image: url("../assets/image/xxzyk/jsfz/jsfz-next.png");
      right: -40px;
    }
    .swiper-button-prev:hover {
      background-image: url("../assets/image/xxzyk/jsfz/jsfzprev-hover.png");
    }
    .swiper-button-next:hover {
      background-image: url("../assets/image/xxzyk/jsfz/jsfznext-hover.png");
    }
    /deep/ .swiper-pagination {
      width: 100%;
      bottom: 0;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background: #cdb29f;
        border-radius: 50%;
        margin: 0 7px;
      }
      .swiper-pagination-bullet.swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
        background: #dd3639;
        border-radius: 50%;
      }
    }
  }
}
</style>