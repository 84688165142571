<template>
  <div id="app">
    <!-- <Header /> -->
    <!-- <Aside v-if="!isMobile" /> -->
    <router-view></router-view>
    <Footer
      v-if="!isMobile && $route.path.indexOf('register') == -1 && $route.path.indexOf('resetPassword') == -1 && $route.path.indexOf('szztk/ylzl') == -1 && $route.path.indexOf('szztk/yzzsjzj') == -1 && $route.path.indexOf('sdcmzyxy') == -1 && $route.path.indexOf('whrjgczyxy') == -1 && $route.path !==`/${school_code}`  && $route.path.indexOf('/gjjsIndex') == -1  " />
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import '../assets/css/common.less'
import '../assets/css/mobile.less'
import utils from '../assets/js/utils.js';
import services from '../assets/js/services.js';

// import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
// import Aside from "../components/Aside.vue"
export default {
  name: 'App',
  components: {
    // Header,
    Footer,
    // Aside
  },
  data () {
    return {
      zh_CN,
      services,
      isMobile: utils.isMobile(),
      school_code: localStorage.getItem("schoolCode"),

    }
  },
  mounted () {
    let code = utils.getUrlSchoolCode(window.location.pathname)
    if (code) {
      localStorage.setItem("schoolCode", code);
    } else {
      localStorage.setItem("schoolCode", '');
      window.open(services.SZ_SERVER, "_self")
    }
    if (process.env.NODE_ENV == 'production') {
      // 第二层判断打包后是测试环境还是正式环境 
      if (process.env.VUE_APP_FLAG === 'pro') {
        utils.loadStatistics();
      }
    }
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
