import services from '../assets/js/services'
import utils from '../assets/js/utils'
export default {
  state: {
    imageCode: {
      key: '',
      img: '',
    },
    appsUserInfo: {}, // 登录后用户信息
    loginVisible: false,
    schoolInfo: null, // 学校相关信息 code logo
    schoolMenu: null, // 学校菜单展示 区域资源、示范课程
  },
  getters: {
  },
  actions: {
    // 获取图片验证码
    getAppsImageCaptcha (context, payload) {
      return services.getAppsImageCaptcha(payload).then(res => {
        // console.log(res);
        if (res.code == '0000') {
          context.commit('setAppsImageCode', {
            'imageCode': res.data
          })
        }
        return res;
      })
    },
    // 获取手机短信验证码
    getAppsSmsCaptcha (context, payload) {
      return services.getAppsSmsCaptcha(payload).then(res => {
        return res;
      })
    },
    // 获取学校信息 code logo
    getLoginSchoolInfo (context, payload) {
      return services.getLoginBeforeInfo(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSchoolInfo', {
            'data': res.data ? res.data : null
          })
        }
        return res;
      })
    },
    // 登录
    requestAppsLoginApps (context, payload) {
      return services.postAppsLogin(payload).then(res => {
        if (res.code == '0000') {
          localStorage.setItem("appsToken", res.data.token)
        }
        return res;
      })
    },
    // 获取用户信息
    getAppsLoginUserInfo (context, payload) {
      let school_code = localStorage.getItem('schoolCode')
      return services.getAppsUserInfo({ ...payload, school_code, }).then(res => {
        if (res.code == '0000') {
          if (res.data) {
            localStorage.setItem("userInfo", JSON.stringify(res.data))
            context.commit('setAppsLoginUserInfo', {
              'userInfo': res.data ? res.data : {}
            })
          }
        } else if (res.code == '2001') {
          localStorage.setItem("userInfo", '')
          context.commit('setAppsLoginUserInfo', {
            'userInfo': {}
          })
        }
        return res;
      })
    },
    // 重置密码
    requestAppsResetPassword (context, payload) {
      return services.postAppsResetPassword(payload).then(res => {
        return res;
      })
    },
    // 官网注册
    requestAppsRegister (context, payload) {
      return services.postAppsRegister(payload).then(res => {
        if (res.code == '0000') {
          localStorage.setItem("appsToken", res.data.token)
        }
        return res;
      })
    },
    // 退出登录
    requestAppsLogout (context, payload) {
      let school_code = localStorage.getItem('schoolCode')
      let params = { ...payload }
      if (school_code == utils.zjgs_code) {
        params.from = `${services.KCSZ_SERVER}/${school_code}/zy`
      }
      return services.postAppsLogout(params).then(res => {
        if (res.code == '0000') {
          localStorage.removeItem("userInfo")
          localStorage.removeItem("appsToken")
          context.commit('setAppsLoginUserInfo', {
            'userInfo': {}
          })

          // if (school_code == utils.zjgs_code && res.data && res.data.redirect) {
          //   window.open(`${res.data.redirect}`, '_self')
          // }
          if (res.data && !(res.data.redirect.indexOf(services.APPS_WEB) > -1)) {
            return window.open(res.data.redirect, "_self")
            // return window.open(`${services.APPS_WEB}/${this.school_code}/login?from=${window.location.href}`, "_self")
          }
        }
        return res;
      })
    },
    // 所有点击详情页按钮 先判断是否登录 未登录显示登录弹层 
    goAppsDetailsBeforeLogin (context) {
      return new Promise(function (resolve,rejuct) {
        context.dispatch('getAppsLoginUserInfo', {}).then(res => {
          if (res.code == '2001') {
            let school_code = localStorage.getItem('schoolCode')
            if (school_code == utils.zjgs_code) {
              window.open(`${services.APPS_SERVIER}/api/redirect?from=${window.location.href}&school_code=${utils.zjgs_code}`, '_self')
            } else {
              context.dispatch("getAppsImageCaptcha", {})
              context.commit('setAppsLoginVisible', true);
            }
            // resolve(res)
            rejuct(res)
          } else if (res.code == '0000') {
            resolve(res)
          }
        })
      })
    },
    // 请求获取学校的菜单展示权限 - 区域资源、示范课程
    getSchoolMenuMethod (context, payload) {
      return services.getSchoolRouterMenu(payload).then(res => {
        if (res.code == '0000') {
          if (res.data) {
            let schoolMenu = {
              ...res.data,
              expiryTime: new Date().getTime(),
              school_code: payload.school_code
            }
            localStorage.setItem("schoolMenu", JSON.stringify(schoolMenu))
            context.commit('setSchoolMenu', res.data)
          }
        }
        return res;
      })
    },
    // 判断菜单展示权限 - 先取缓存数据 相隔5分钟再次发起请求，避免重复过多请求
    async getSchoolMenuStatus (context, payload) {
      let schoolMenu = localStorage.getItem('schoolMenu')
      schoolMenu = schoolMenu ? JSON.parse(schoolMenu) : schoolMenu
      // 判断是否是同一个学校的请求
      if (schoolMenu && schoolMenu.school_code && (schoolMenu.school_code == payload)) {
        let expiryTime = schoolMenu.expiryTime
        let currentTime = new Date().getTime()
        // 如果当前时间大于过期时间5分钟 重新请求接口并存储新的值； 否则，直接使用缓存数据
        if ((currentTime - expiryTime) > 5 * 60 * 1000) {
          await context.dispatch("getSchoolMenuMethod", { school_code: payload })
        } else {
          context.commit('setSchoolMenu', schoolMenu)
        }
      } else {
        await context.dispatch("getSchoolMenuMethod", { school_code: payload })
      }
    },
    // 学校使用自己的统一登录时 - 因和第三方学校登录集成，此接口做判断是否跳转
    schoolLoginRedirect (context, payload) {
      return services.schoolLoginRedirect(payload).then(res => {
        return res;
      })
    }
  },
  mutations: {
    setAppsImageCode (state, payload) {
      state.imageCode = payload.imageCode;
    },
    setAppsLoginUserInfo (state, payload) {
      state.appsUserInfo = payload.userInfo;
    },
    setAppsLoginVisible (state, payload) {
      state.loginVisible = payload;
    },
    setSchoolInfo (state, payload) {
      state.schoolInfo = payload.data;
      if (payload.data && payload.data.school_name) {
        utils.setTitle(payload.data.school_name)
      }
      if (!payload.data.is_open_app) {
        window.open(services.SZ_SERVER, "_self")
      }
    },
    setSchoolMenu (state, payload) {
      state.schoolMenu = payload;
    },
  }
}