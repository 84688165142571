<template>
  <div class="gjjs-container" :class="`nav${navActiveIndex}`">
    <!-- 首页导航 -->
    <div class="index-nav" :class="`nav${navActiveIndex}`">
      <div
        class="nav-item"
        v-for="(item, ind) in gjjsNavList"
        :key="ind"
        :class="{ active: navActiveIndex == ind }"
        @click="changeSlide(ind)"
      >
        <div class="nav-button">
          <div class="x"></div>
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="content">
      <swiper ref="navSwiper" :options="navSwiper" class="swiper-no-swiping">
        <swiperSlide class="nav1">
          <navgjjs @toNextSlide="toNextSlide"></navgjjs>
        </swiperSlide>
        <swiperSlide>
          <navxsgj></navxsgj>
        </swiperSlide>
        <swiperSlide>
          <navjdgj></navjdgj>
        </swiperSlide>
        <swiperSlide>
          <navjxsy></navjxsy>
        </swiperSlide>
        <swiperSlide>
          <navjxshany></navjxshany>
        </swiperSlide>
        <swiperSlide>
          <navjxzx></navjxzx>
        </swiperSlide>
        <swiperSlide>
          <navjxlh></navjxlh>
        </swiperSlide>
        <swiperSlide>
          <navssqc></navssqc>
        </swiperSlide>
      </swiper>
    </div>
  </div>
</template>

<script>
import navgjjs from "../components/navgjjs.vue";
import navxsgj from "../components/navxsgj.vue";
import navjdgj from "../components/navjdgj.vue";
import navjxsy from "../components/navjxsy.vue";
import navjxzx from "../components/navjxzx.vue";
import navjxlh from "../components/navjxlh.vue";
import navssqc from "../components/navssqc.vue";
import navjxshany from "../components/navjxshany.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    navgjjs,
    navxsgj,
    navjdgj,
    navjxsy,
    navjxshany,
    navjxzx,
    navjxlh,
    navssqc,
    swiper,
    swiperSlide,
  },
  mounted() {
    if (this.$route.query.ind) {
      this.$refs.navSwiper.swiper.slideTo(this.$route.query.ind);
    }
  },
  data() {
    let self = this;
    return {
      navActiveIndex: 0,
      gjjsNavList: [
        {
          title: "工匠精神",
        },
        {
          title: "说工匠 · 解读工匠",
        },
        {
          title: "中华智慧 · 匠心溯源",
        },
        {
          title: "继往开来 · 匠心闪耀",
        },
        {
          title: "乘风破浪 · 匠心争先",
        },
        {
          title: "精业济群 · 匠心领行",
        },
        {
          title: "匠苗培育 · 世赛青春",
        },
      ],
      navSwiper: {
        direction: "vertical",
        followFinger: false,
        mousewheel: true,
        on: {
          slideChange: function (e) {
            self.navActiveIndex = this.activeIndex;
            if (this.activeIndex == 1 || this.activeIndex == 2) {
              self.navActiveIndex = 1;
            }
            if (this.activeIndex > 2) {
              self.navActiveIndex = this.activeIndex - 1;
            }
          },
        },
      },
    };
  },
  methods: {
    changeSlide(ind) {
      if (!(ind < 2)) {
        this.$refs.navSwiper.swiper.slideTo(ind + 1);
      } else {
        this.$refs.navSwiper.swiper.slideTo(ind);
      }
    },
    toNextSlide() {
      this.$refs.navSwiper.swiper.slideTo(1);
    },
  },
};
</script>

<style lang="less" scoped>
.gjjs-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 404px;
    height: 100%;
    right: 0;
    top: 0;
    background-image: url("../../../assets/image/gjjs/index/nav-xsgj/navber-bg.png");
    background-size: cover;
    z-index: 11;
    transition: all 0.5s;
  }
  &.nav0 {
    &::before {
      right: -404px;
    }
  }
  overflow-x: hidden;
  .index-nav {
    position: absolute;
    width: 14px;
    height: 655px;
    right: 29px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s;
    z-index: 11;

    .nav-item {
      cursor: pointer;
      position: relative;
      .nav-button {
        position: absolute;
        width: 290px;
        height: 60px;
        background: #b31118;
        border-radius: 30px 0px 0px 30px;
        top: calc(50% - 30px);
        right: -23px;
        z-index: -1;
        padding-right: 60px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #e97e69;
        line-height: 58px;
        text-align: end;
        opacity: 1;
        transition: all 0.5s;
      }
      &::before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        background: #e76d55;
        border-radius: 50%;
        z-index: 20;
      }
      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 92px;
        position: absolute;
        left: calc(50% - 1px);
        // transform: translateX(-50%);
        z-index: 12;
        transition: all 0.5s;
        z-index: 50;
        border-left: 2px solid #e76d55;
      }
      &.active {
        &::before {
          background: #ffffff;
        }
        .nav-button {
          color: #ffffff;
        }
        &:nth-child(2) {
          .nav-button {
            .x {
              background-image: url("../../../assets/image/gjjs/index/nav-index/x-icon-hover.png");
            }
          }
        }
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      &:nth-child(2) {
        .nav-button {
          .x {
            transition: all 0.5s;
            width: 24px;
            height: 32px;
            display: inline-block;
            background-image: url("../../../assets/image/gjjs/index/nav-index/x-icon.png");
            background-size: cover;
            vertical-align: -8px;
          }
        }
      }
    }
    &.nav0 {
      right: 58px;
      height: 486px;

      .nav-item {
        &::before {
          background: #d7c5a4;
        }
        .nav-button {
          right: -295px;
          opacity: 0;
        }
        &::after {
          height: 63px;
          border: 1px dashed #d7c5a4;
        }
        &.active {
          &::before {
            background: #c0000a;
          }
        }
      }
    }
    &.nav1 {
      .nav-item {
        &:nth-child(n + 2) {
          &::after {
            // height: 63px;
            border: 1px dashed #e76d55;
          }
        }
      }
    }
    &.nav2 {
      .nav-item {
        &:nth-child(n + 3) {
          &::after {
            // height: 63px;
            border: 1px dashed #e76d55;
          }
        }
      }
    }
    &.nav3 {
      .nav-item {
        &:nth-child(n + 4) {
          &::after {
            // height: 63px;
            border: 1px dashed #e76d55;
          }
        }
      }
    }
    &.nav4 {
      .nav-item {
        &:nth-child(n + 5) {
          &::after {
            // height: 63px;
            border: 1px dashed #e76d55;
          }
        }
      }
    }
    &.nav5 {
      .nav-item {
        &:nth-child(n + 6) {
          &::after {
            // height: 63px;
            border: 1px dashed #e76d55;
          }
        }
      }
    }
    &.nav6 {
      .nav-item {
        &:nth-child(n + 7) {
          &::after {
            // height: 63px;
            border: 1px solid #e76d55;
          }
        }
      }
    }
  }
  .content {
    width: 100%;
    height: 100%;
    .swiper-container {
      width: 100%;
      height: 100%;
      .swiper-wrapper {
        width: 100%;
        height: 100%;
        .swiper-slide {
          width: 100%;
          height: 100% !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1400px)  {
  .gjjs-container {
    &::before {
      right: -404px;
    }
    .index-nav {
      right: 58px;
      height: 486px;
      .nav-item {
        .nav-button {
          right: -295px;
          opacity: 0;
        }
    &::before {
            background: #d7c5a4;
          }
        &::after {
          height: 63px;
          border: 1px dashed #d7c5a4;
        }
        &.active {
          &::before {
            background: #c0000a;
          }
        }
      }
    }
  }
}
@media screen and (max-height: 700px)  {
  .gjjs-container {
    &::before {
      right: -404px;
    }
    .index-nav {
      right: 58px;
      height: 486px;
      .nav-item {
        .nav-button {
          right: -295px;
          opacity: 0;
        }
         &::before {
            background: #d7c5a4;
          }
        &::after {
          height: 63px;
          border: 1px dashed #d7c5a4 !important;
        }
        &.active {
          &::before {
            background: #c0000a;
          }
        }
      }
    }
  }
}
</style>