<template>
  <div class="outer-nores">
    <Header />
    <Navbar />
    <div class="noResouce">
      <img class="imgs404" src="../assets/image/common/res404.png" />
      <p>您查看的资源不存在</p>

      <div class="tabs">
        <span class="tabActived tabItem">相关推荐</span>
      </div>
      <div class="line"></div>
      <div class="list1" v-if="datalist && datalist.length > 0">
        <div v-for="(item, index) in datalist" :key="index" @click="goDetails(item)" class="item1">
          <div class="item1Top">
            <img v-if="item.type == 1" src="../assets/image/home/word.png" alt="" class="word" />
            <img v-else src="../assets/image/home/MP4.png" alt="" class="word" />
            <div class="title">
              <span v-html="item.title" class="title-span"></span> 
              <!-- <span v-if='item.is_open == 2' class="resopen">公开</span> -->
            </div>
          </div>
          <div v-html="item.abstract" class="intro"></div>
          <div class="footer">
            <div class="keyWords">
              <span v-for="(sub, subinx) in item.element_list" :key="subinx">{{
                sub.name
              }}</span>
            </div>
            <div class="options">
              <div :class="item.favorite == 1 ?'active collect' : 'collect'" @click.stop="requestCollect(item)">
                <div class="collectImg"></div>
                <span>{{item.favorite == 1 ? '取消收藏': '收 藏'}}</span>
              </div>
              <!-- <div class="download" @click="download()">
                                <img src="../../assets/image/home/download.png" alt="">
                                <span>下载</span>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <component :is="dialogConfig.name"></component>
    <!-- <Loading :showLoading="isShowLoading" /> -->
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapActions } from "vuex";
import services from "../assets/js/services";
// import Loading from '../components/loading.vue';
import Header from '../components/Header.vue'
// import { Modal } from 'ant-design-vue';
import appleRes from "../components/appleRes.vue"
export default {
  name: "page404",
  components: {
    Navbar,
    Header,
    // Loading,
    appleRes,
  },
  data () {
    return {
      school_code: localStorage.getItem("schoolCode"),
      currentId: 0,
      services,
      datalist: [],
      // isShowLoading: false,
      dialogConfig: {
        name: ""
      }
    };
  },
  mounted () {
    this.getSzzykData();
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin", "saveResourceFavorite"]),
    getSzzykData () {
      let that = this;
      // if (that.$route.query.id) {
      //   that.currentId = that.$route.query.id;

      let param = {
        length: 6,
        page: 1,
        // is_recommend: 2, // 1为不推荐 2为推荐
        favorite_flag: 1, // 是否显示收藏 1显示
      };
      if (that.$route.query.subject_big_id) {
        param.subject_big_id = that.$route.query.subject_big_id;
      }
      if (that.$route.query.subject_small_id) {
        param.subject_small_id = that.$route.query.subject_small_id;
      }

      that.services.getSzzykListData({...param, school_code: that.school_code}).then((res) => {
        if (res.code == "0000") {
          that.datalist = res.data && res.data.items ? res.data.items : [];
        }
      });
      // }
    },
    //点击收藏
    requestCollect (item) {
      let that = this;
      // that.goAppsDetailsBeforeLogin().then(() => {
      // that.isShowLoading = true
      that.saveResourceFavorite({ id: item.id }).then((res) => {
        // that.isShowLoading = false
        if (res.code == '0000') {
          that.$message.success("操作成功")
          that.getSzzykData();
        } else {
          if (res.code != '2001') {
            that.$message.error("操作失败")
          }
        }
      })

      // })
    },
    // 思政资源库详情
    goDetails (item) {
      let that = this;
      this.goAppsDetailsBeforeLogin().then(() => {
        // window.open(`${services.BEIKE_SERVER}/${that.school_code}/lesson/distribution`)
        // if (item.is_open == 2) {
          let params = {
            id: item.id,
          };
          that.$router.push({
            path: `/${that.school_code}/zy/szzyk/details`,
            query: params,
          });
        // } else {
        //   // that.noAuthMessageModal();
        //   that.dialogConfig.name = "appleRes"
        // }

      });
    },
    // 无权限查看课程资源时弹层提示
    // noAuthMessageModal () {
    //   Modal.info({
    //     title: '温馨提示',
    //     okType: 'primary',
    //     okText: '申请开通更多资源',
    //     closable: true,
    //     content: (<div class="jioayu-chrome">
    //       请拨打服务咨询电话或申请开通更多资源，获得更多资源使用权限。<br />服务咨询： 宋老师  18701110987
    //     </div>),
    //     onOk () {
    //       window.open(`${services.YXT_GW}/need`)
    //     }
    //   })
    // },
  },
};
</script>

<style lang="less" scoped>
.outer-nores {
  background-color: #f5f7fa;
  padding: 0 0 30px;
}
.noResouce {
  width: 1200px;
  margin: 20px auto 0;
  text-align: center;
  background-color: #ffffff;
  img.imgs404 {
    margin-top: 70px;
  }
  p {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2f2f2f;
    padding-top: 30px;
    margin-bottom: 60px;
  }
  .tabs {
    height: 60px;
    line-height: 60px;
    padding-left: 40px;
    text-align: left;
    .tabItem {
      font-size: 16px;
      font-weight: bold;
      color: #5a6474;
      margin-right: 60px;
      cursor: pointer;
    }
    .tabActived {
      color: #dd363a !important;
      position: relative;
    }
    .tabActived::after {
      content: '';
      position: absolute;
      width: 64px;
      height: 4px;
      background: linear-gradient(162deg, #dd363a 0%, #df4531 100%);
      left: 0;
      bottom: -20px;
    }
  }
  .line {
    width: 1200px;
    margin: 0 auto;
    height: 1px;
    background: #f5f7fa;
    border-radius: 1px;
  }
  .list1 {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item1 {
      width: 48%;
      padding: 20px;
      border-bottom: 1px solid #f3f3f3;
      cursor: pointer;
      .item1Top {
        display: flex;
        img {
          margin-right: 8px;
        }
        .title {
          font-size: 15px;
          color: #454545;
          font-weight: bold;
          text-align: left;
          .title-span {
            max-width: 430px;
            display: inline-block;
            vertical-align: middle;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .resopen {
            font-size: 12px;
            color: #ffffff;
            background: #ee373d;
            padding: 2px 6px;
            border-radius: 6px;
          }
        }
      }
      .intro {
        font-size: 13px;
        color: #a3a5aa;
        margin-top: 10px;
        display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp: 2; /**显示的行数**/
        overflow: hidden; /**隐藏超出的内容**/
      }
      .footer {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .keyWords {
          flex: 1;
          height: 24px;
          overflow: hidden;
          text-align: left;
          span {
            padding: 0 12px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            display: inline-block;
            background: #f9f9f9;
            border-radius: 12px;
            margin-right: 15px;
            font-size: 12px;
            color: #b39f8c;
          }
        }
        .options {
          overflow: hidden;
          .collect,
          .download {
            width: 82px;
            height: 24px;
            line-height: 24px;
            background: #ffffff;
            border: 1px solid #e4717d;
            border-radius: 12px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            float: left;
            cursor: pointer;
            img {
              margin-right: 3px;
            }
            span {
              color: #df4332;
              font-size: 12px;
            }
          }
          .collect {
            // margin-right: 15px;
            .collectImg {
              background: url('../assets/image/home/star.png') no-repeat;
              background-size: 100% 100%;
              width: 18px;
              height: 18px;
              margin-right: 3px;
            }
          }
          .collect.active .collectImg {
            background: url('../assets/image/home/star-active.png') no-repeat !important;
          }
        }
      }
      .nobottom {
        border-bottom: none;
      }
    }
    .item1:hover {
      background: linear-gradient(0deg, #ffffff 0%, #f7f7f7 100%);
      border-radius: 2px;
      .keyWords {
        span {
          background: #f9f5e9;
          color: #b39f8c;
        }
      }
      .footer {
        border-bottom: none;
      }
      .collect {
        background: linear-gradient(
          162deg,
          #d8292d 0%,
          #d43d29 100%
        ) !important;
        .collectImg {
          background: url('../assets/image/home/star-active.png') no-repeat !important;
        }
        span {
          color: #ffffff !important;
        }
      }
    }
  }
}
.jioayu-chrome {
  font-size: 14px;
  color: #333;
  display: inline;
  line-height: 30px;
  a,
  a:hover {
    color: #df4531;
  }
  a:hover {
    text-decoration: underline;
  }
}
</style>