<template>
  <div class="nav-ssqc">
    <div class="resize-con">
      <div class="jxsy-tit"></div>
      <div class="ssqc-content">
        <div class="item-info">
          <div
            class="item-img"
            @click="toDetail(hoverObj)"
            :style="`background-image: url(${hoverObj.cover})`"
          >
            <div class="topr-border"></div>
            <div class="topl-border"></div>
            <!-- <img class="" :src="hoverObj.cover" alt="" /> -->
            <div class="bottomr-border"></div>
            <div class="bottoml-border"></div>
          </div>
          <div class="info-content">
            <div class="info-from">
              <div class="info-name" @click="toDetail(hoverObj)">
                {{ hoverObj.title }}
              </div>
              <div class="info-subject" v-if="hoverObj.keywords" >
                <img
                  src="../../../assets/image/gjjs/index/nav-ssqc/ssqc-subjcet.png"
                  alt=""
                />{{ hoverObj.keywords && hoverObj.keywords.join("") }}
              </div>
            </div>
            <div class="info-text">
              {{ hoverObj.abstract }}
            </div>
          </div>
        </div>
        <div class="student-list">
          <div
            class="student-item"
            v-for="(item, ind) in sspcList"
            :key="ind"
            :class="{ hover: hoverIndex == ind }"
            @mouseover="changHover(ind)"
            @click="toDetail(item)"
          >
            <div
              class="item-img"
              :style="`background-image: url(${item.cover})`"
            ></div>
          </div>
        </div>
      </div>
      <a
        class="get-more"
        href="http://worldskillschina.mohrss.gov.cn/fczs/zgxs/"
        target="_blank"
      >
        <div class="more-icon"></div>
        <div class="more-text">查 看 更 多</div>
      </a>
    </div>
  </div>
</template>

<script>
import services from "../../../assets/js/services.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      sspcList: [],
      hoverIndex: 0,
      hoverObj: {},
    };
  },
  created() {
    this.getsspcList();
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),

    async getsspcList() {
      let res = await services.getAreaSzztkLists({
        page: 1,
        length: 5,
        count: 0,
        school_code: this.school_code,
        special_id: this.specialId,
        kind: "1",
        column_name: "匠苗培育 世赛青春",
      });
      if (res.code == "0000" && res.data.items.length > 0) {
        this.sspcList = res.data.items;
        this.hoverObj = this.sspcList[0];
      }
    },
    changHover(ind) {
      this.hoverIndex = ind;
      this.hoverObj = this.sspcList[ind];
    },
    toDetail(item) {
      if (item) {
        this.goAppsDetailsBeforeLogin().then(() => {
          if (item.is_open_url == 2) {
            window.open(`${item.source_url}`, "_blank");
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-ssqc {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 139px;
  background-image: url("../../../assets/image/gjjs/index/nav-index/nav-xsgj-bg.png");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  .resize-con {
    position: absolute;
    left: 308px;
    top: 50%;
    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 468px;
      height: 548px;
      top: 54px;
      left: -246px;
      z-index: 0;
      background-image: url("../../../assets/image/gjjs/index/nav-xsgj/gjjs-sidber.png");
      background-size: cover;
      background-repeat: no-repeat;

      z-index: 0;
    }
    .jxsy-tit {
      width: 700px;
      height: 109px;
      background-image: url("../../../assets/image/gjjs/index/nav-jxzx/ssqc-tit.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin: 0 auto;
    }
    .ssqc-content {
      position: relative;
      z-index: 1;
      box-shadow: 0px 11px 26px 4px rgba(168, 141, 147, 0.68);
      width: 1100px;
      height: 630px;
      // margin-left: 16%;
      background: #fcf4e9;
      border-radius: 6px;
      background-repeat: no-repeat;
      margin-top: 43px;
      background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzx-bg.png");
      background-size: 1081px 599px;
      background-position: center;
      padding: 58px 40px 42px;
      .item-info {
        width: 100%;
        height: 287px;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          display: block;
          height: 100%;
          width: 44.4%;
          background-image: url("../../../assets/image/gjjs/index/nav-jxzx/ssqc-img-border.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;

          z-index: 1;
        }
        &::after {
          content: "";
          display: block;
          height: 85.3%;
          position: absolute;
          right: 0;
          top: 4.8%;
          width: 58.7%;
          background: linear-gradient(0deg, #fccf94 0%, #ffefd8 100%);
          z-index: 0;
        }
        .item-img {
          width: 40.3%;
          height: 94.7%;
          background: #ccc;
          margin-left: 1%;
          position: relative;
          background-size: cover;
          cursor: pointer;
          z-index: 1;
          background-position: center;
          .topr-border {
            position: absolute;
            border-top: 8px #fee1ba solid;
            border-right: 8px #fee1ba solid;
            border-bottom: 8px transparent solid;
            border-left: 8px transparent solid;
            top: 0;
            right: 0;
          }
          .topl-border {
            position: absolute;
            border-top: 8px #fee1ba solid;
            border-right: 8px transparent solid;
            border-bottom: 8px transparent solid;
            border-left: 8px #fee1ba solid;
            top: 0;
            left: 0;
          }
          .bottomr-border {
            position: absolute;
            border-right: 8px #fee1ba solid;
            border-left: 8px transparent solid;
            border-top: 8px transparent solid;
            border-bottom: 8px #fee1ba solid;
            bottom: 0;
            right: 0;
          }
          .bottoml-border {
            position: absolute;
            border-left: 8px #fee1ba solid;
            border-right: 8px transparent solid;
            border-top: 8px transparent solid;
            border-bottom: 8px #fee1ba solid;
            bottom: 0;
            left: 0;
          }
        }
        .info-content {
          position: relative;
          z-index: 3;
          flex: 1;
          height: 100%;
          .info-from {
            margin-left: 10%;
            margin-top: 12.1%;
            height: 12.8%;
            display: flex;
            position: relative;
            align-items: flex-end;
            .info-name {
              font-size: 36px;
              line-height: 36px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #161616;
              cursor: pointer;
            }
            .info-subject {
              height: 81%;
              background: linear-gradient(0deg, #ffd28b 0%, #fff6ea 100%);
              border-radius: 20px 6px 0px 0px;
              position: relative;
              font-size: 13px;
              display: flex;
              align-items: center;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #c0000a;
              padding-left: 7.5%;
              padding-right: 2.4%;
              margin-left: 8.8%;
              img {
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
            &::after {
              content: "";
              display: block;
              position: absolute;
              width: 66%;
              height: 27%;
              background-image: url("../../../assets/image/gjjs/index/nav-ssqc/ssqc-name-split.png");
              background-size: cover;
              background-repeat: no-repeat;

              bottom: 0;
              transform: translateY(240%);
            }
          }
          .info-text {
            margin-top: 6.3%;
            font-size: 17px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #70675e;
            line-height: 24px;
            margin-left: 9.6%;
            margin-right: 7.6%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 94.4%;
            height: 4%;
            bottom: 2.7%;
            right: 0;
            background-image: url("../../../assets/image/gjjs/index/nav-ssqc/sspc-bottom.png");

            background-size: 100%;
            background-repeat: no-repeat;
          }
        }
      }
      .student-list {
        width: 100%;
        height: 41.5%;
        margin-top: 2.2%;
        display: flex;
        justify-content: space-between;
        .student-item {
          width: calc(20% - 2px);
          height: 100%;
          padding: 2.2% 0.7% 0.9%;
          cursor: pointer;
          &.hover {
            background-image: url("../../../assets/image/gjjs/index/nav-ssqc/ssqc-hover.png");
            background-size: cover;
            background-repeat: no-repeat;
            box-shadow: 0px 11px 8px 0px rgba(117, 10, 14, 1);
          }
          .item-img {
            width: 100%;
            height: 100%;
            background-color: #70675e;
            background-size: cover;
            background-position: center;
          }
        }
      }
    }
    .get-more {
      display: flex;
      justify-content: center;
      margin-top: 22px;
      cursor: pointer;
      .more-icon {
        width: 42px;
        height: 24px;
        background-image: url("../../../assets/image/gjjs/index/nav-index/nav-more.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 14px;
      }
      .more-text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ab030c;
      }
    }
  }
}
@media screen and (max-width: 1900px) and (min-width: 1700px) {
  .resize-con {
    transform: scale(0.9) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-height: 600px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .resize-con {
    transform: scale(0.84) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1550px) {
  .resize-con {
    transform: scale(0.8) translateY(-50%);
    transform-origin: 0 0;
  }
}

@media screen and (max-width: 1550px) {
  .resize-con {
    transform: scale(0.74) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1500px) {
  .resize-con {
    transform: scale(0.7) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1400px) {
  .resize-con {
    transform: scale(0.6) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1300px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (min-width: 1900px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
@media screen and (min-height: 1080px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
</style>