<template>
  <div class="kcszzt-content">
    <div class="header-box"></div>
    <div class="category-list">
      <div class="category-box">
        <div class="category-label">资源分类</div>
        <div class="category-items">
          <div
            :class="['category-item', { active: labelId == '' }]"
            @click="getCourselist('')"
          >
            全部
          </div>
          <div
            :class="['category-item', { active: labelId == item.id }]"
            v-for="item in labelList"
            :key="item.id"
            @click="getCourselist(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="content-infobg">
      <div class="content-info">
        <div class="content-list">
          <div
            class="content-item"
            v-for="(item, ind) in courselist"
            :key="ind"
            @click="goToMaterialDetail(item)"
          >
            <div class="item-img">
              <div :style="`background-image: url('${item.photo}')`"></div>
            </div>
            <div class="item-name">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="Pagination-box">
          <APagination
            v-if="total > 12"
            v-model="page"
            :total="total"
            :page-size="pageSize"
            @change="onPaginationChange"
          ></APagination>
          <div v-else-if="courselist.length <= 0" class="noData-box">
            <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
            <div class="text">暂无相关内容</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from "ant-design-vue";
import services from "../../assets/js/services";
import { mapActions } from "vuex";
export default {
  name: "xxzyzyjnyc",
  components: {
    APagination: Pagination,
  },
  data() {
    return {
      labelList: [], //标签列表
      courselist: [],
      labelId: "",
      total: 0,
      page: 1,
      pageSize: 12,
      category_id: 60,
      school_code: localStorage.getItem("schoolCode"),
    };
  },
  created() {
    window.scrollTo(0, 0);

    this.getLabelLIst();
    this.getCourselist("");
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    async getLabelLIst() {
      try {
        let res = await services.getszztyxLabelLIst({
          school_code: this.school_code,
          category_id: this.category_id,
        });
        if (res.code == "0000" && res.data.length > 0) {
          this.labelList = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCourselist(tag_id, page = 1) {
      this.labelId = tag_id;
      this.page = page;
      try {
        let res = await services.getszztyxCourse({
          school_code: this.school_code,
          category_id: this.category_id,
          page: this.page,
          size: this.pageSize,
          tag_id,
        });
        if (res.code == "0000" && res.data.items.length > 0) {
          this.courselist = res.data.items;
          this.total = res.data.total;
        }
      } catch (error) {
        console.log(error);
      }
    },
    onPaginationChange(current) {
      this.getCourselist(this.labelId, current);
    },
    goToMaterialDetail(item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(
          `${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.course_id}&cm_id=${item.id}`
        );
      });
    },
  },
};
</script>

<style lang="less" scoped>
.kcszzt-content {
  .header-box {
    width: 100%;
    height: 300px;
    background-image: url("../../assets/image/xxzyk/szztyx-banner.png");
    background-size: cover;

    background-position: center;
  }
  .category-list {
    height: 84px;
    background: #f8fafa;
    .category-box {
      width: 1200px;
      height: 84px;
      margin: 0 auto;
      //   background: #e4e3e3;
      display: flex;
      align-items: center;
      .category-label {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin-right: 28px;
        white-space: nowrap;
      }
      .category-items {
        display: flex;
        .category-item {
          padding: 0 14px;
          line-height: 26px;
          border-radius: 12px;
          color: #6d7074;
          font-size: 14px;
          cursor: pointer;
          margin-right: 8px;
          &.active {
            background: #e23f4b !important;
            color: #fff !important;
          }
          &:hover {
            background: #fdf3f3;
            color: #e23f4b;
          }
        }
      }
    }
  }
  .content-infobg {
    width: 100%;
    background-image: url("../../assets/image/xxzyk/index/list-bg.png");
    background-size: 100% auto;
    background-position: center bottom;
    padding-bottom: 28px;
    background-repeat: no-repeat;

    .content-info {
      width: 1200px;
      margin: 0 auto;
      padding-top: 28px;

      .content-list {
        display: flex;
        flex-wrap: wrap;
        .content-item {
          border: 1px solid #e4e3e3;
          cursor: pointer;
          width: 270px;
          background: #fff;
          margin-left: 40px;
          margin-bottom: 24px;
          &:nth-child(4n + 1) {
            margin-left: 0;
          }
          &:hover .item-img div {
            transform: scale(1.1);
          }
          .item-img {
            width: 100%;
            height: 152px;
            overflow: hidden;

            div {
              width: 100%;
              height: 152px;
              background-size: cover;
              background-position: center;
              transition: all 0.5s;
            }
          }
          .item-name {
            margin: 14px 15px;
            height: 40px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            -webkit-line-clamp: 2; //显示几行
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #3a3a3a;
          }
        }
      }
      .Pagination-box {
        display: flex;
        justify-content: center;
        .noData-box {
          width: 100%;
          text-align: center;
          height: 770px;
          padding-top: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 150px;
          img {
            width: 240px;
            height: auto;
            margin: 0 auto 30px;
          }
          .text {
            color: #76797e;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>