<template>
  <div v-show="showLoading" class="pages-loading">
    <a-spin class="spin-pages">
      <a-icon slot="indicator" type="loading" style="font-size: 24px;color:#df4332;" spin />
    </a-spin>
  </div>
</template>
<script>

export default {
  name: "Loading",
  props: {
    showLoading: {
      type: Boolean,
      default: false,
    }
  },
  components: {
  },
  data () {
    return {
    }

  },
}
</script>
<style lang="less" scoped>
.pages-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  text-align: center;
  // background: rgba(0, 0, 0, 0.2);
  .spin-pages {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

    