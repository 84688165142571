<template>
  <div class="header-box" @contextmenu.prevent.capture>
    <div class="head-two">
      <div class="container flex-center">
        <div class="left-imgs">
          <!-- <a href="https://www.eol.cn" target="_blank" class="zgjyzxIcon"><img src="../assets/image/new-szztk/zgjyzx.png" /></a> -->
          <img v-if="schoolInfo" class="schoollogo" :src="schoolInfo.school_logo" />
          <!-- <a :href="services.YXT_GW" target="_blank"><img src="../assets/image/new-szztk/zjyk.png" /></a> -->
          <div class="split-line"></div>
          <div class="szzyk-img">
            <a :href="`/${school_code}/zy/szzyk`" target="_blank">思政资源库</a>
          </div>
        </div>
        <div class="search-middle-box">

          <div class="item">
            <a :href="`/${school_code}/zy/szzyk`" target="_blank">
              {{school_code==utils.sdcm_code?'热点资源':'思政资源库'}}</a>
          </div>
          <div class="split-line"></div>

          <div class="item active">
            <a :href="`/${school_code}/zy/szztk`" target="_blank">
              {{school_code==utils.sdcm_code?'专题资源':'思政专题库'}}</a>
          </div>

          <div v-if="schoolMenu && schoolMenu.hasOwnProperty('special') && schoolMenu.special.id" class="split-line"></div>
          <div v-if="schoolMenu && schoolMenu.hasOwnProperty('special') && schoolMenu.special.id" class="item">
            <a :href="`/${school_code}/zy/region`" target="_blank">
              {{schoolMenu.special.name}}</a>
          </div>
        </div>
        <!-- <div class="renminkeji-box">
          <div class="rmkj-text">数据支持 / 战略合作伙伴：</div>
          <img src="../assets/image/new-szztk/rmkj.png" />
        </div> -->

        <div class="login-box">
          <div v-if="appsUserInfo && appsUserInfo.name" class="login-dropdown-box">
            <div class="login-after">{{appsUserInfo.name}}</div>
            <div class="login-sub-box">
              <template v-if="appsUserInfo.user_type== 2 || (appsUserInfo.user_type == 1 && appsUserInfo.role_ids)">
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/sz/school/resource`" target="_blank">校内共建资源库</a>
                </div>
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/lesson/resource`" target="_blank">备课资源库</a>
                </div>
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/lesson/works`" target="_blank">协同备课</a>
                </div>
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/sz/favorite`" target="_blank">资源收藏</a>
                </div>
                <div class="login-lines"></div>
              </template>
              <div class="hover-item">
                <a :href="`${services.APPS_WEB}/${school_code}/worker`" target="_blank">信息服务中心</a>
              </div>
              <div class="hover-item">
                <a :href="`${services.APPS_WEB}/${school_code}/userInfo`" target="_blank">个人信息设置</a>
              </div>
              <div v-if="!isMobile" class="hover-item">
                <a :href="`${services.APPS_WEB}/${school_code}/editPassword`" target="_blank">修改密码</a>
              </div>
              <div class="hover-item" @click="logoutEvent">
                <a href="javascript:;">退出</a>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="login" @click="loginModal(true)">登录</div>
            <!-- <router-link class="register" to="/register">个人注册</router-link> -->
            <!-- <a class="free-trial" :href='services.YXT_GW + "/need"' target="_blank">学校免费试用</a> -->
          </template>
        </div>
      </div>
    </div>

    <!-- 登录弹层 -->
    <Login />
  </div>
</template>

<script>
import services from "../assets/js/services"
import utils from "../assets/js/utils"
import { mapState, mapActions, mapMutations } from "vuex"
import homeData from "../assets/js/homeData"
import Login from "./Login.vue"

export default {
  name: 'Header',
  components: {
    Login,
  },
  data () {
    return {
      utils,
      services,
      isChrome: true,
      homeSearchList: homeData.homeSearchList,
      isMobile: utils.isMobile(),
      school_code: localStorage.getItem("schoolCode"),
    };
  },
  computed: {
    ...mapState({
      appsUserInfo: state => state.appsLogin.appsUserInfo,
      loginVisible: state => state.appsLogin.loginVisible,
      keyWord: state => state.search.keyWord,
      schoolInfo: state => state.appsLogin.schoolInfo,
      schoolMenu: (state) => state.appsLogin.schoolMenu,
    }),
  },
  mounted () {
    this.isChrome = utils.bowerUserAgent();
    this.getAppsLoginUserInfo();
    // this.getLoginSchoolInfo({ school_code: this.school_code, label: 'kcsz' })
    // this.getRegionData()
  },
  methods: {
    ...mapMutations(['setSearchKeyWord', 'setAppsLoginVisible']),
    ...mapActions(['getAppsImageCaptcha', 'getAppsLoginUserInfo', 'requestAppsLogout', 'goAppsDetailsBeforeLogin', 'getLoginSchoolInfo', 'getSchoolMenuStatus']),
    // 获取区域开通信息
    // getRegionData () {
    //   let that = this
    //   this.getSchoolMenuStatus(this.school_code)
    // },
    changeInputVal (e) {
      this.setSearchKeyWord({ data: e.target.value })
    },
    clearInputVal () {
      this.setSearchKeyWord({ data: '' })
    },
    searchMethod () {
      if (this.keyWord && this.keyWord.length > 0) {
        this.$router.push({
          path: `/${this.school_code}/zy/search`,
          query: {
            keyWord: this.keyWord
          }
        });
      } else {
        this.$message.error("检索词不能为空")
      }
    },
    loginModal (val) {
      let that = this
      // 如果需要统一登录,跳转
      let param = { format: 'json', school_code: that.school_code, from: window.location.href }
      that.schoolLoginRedirect(param).then(res => {
        if (res.code == '0000' && res.data && !(res.data.redirect.indexOf(services.APPS_WEB) > -1)) {
          return window.open(res.data.redirect, "_self")
          // return window.open(`${services.APPS_WEB}/${this.school_code}/login?from=${window.location.href}`, "_self")
        } else {
          if (val) {
            that.getAppsImageCaptcha()
          }
          that.setAppsLoginVisible(val);
        }
      })
    },
    // 退出登录
    async logoutEvent () {
      let that = this
      await that.requestAppsLogout().then(() => {
        let path = this.$route.path.split(this.$route.params.id)[0]
        if (utils.whiteList.includes(path.slice(0, path.length - 1))) {
          that.$router.push({ path: `/${that.school_code}/szztk` })
          that.loginModal(true);
        }
      })
    },
    toSzyskDetail (item) {
      let that = this;
      that.goAppsDetailsBeforeLogin().then(() => {
        let routeUrl = that.$router.resolve({
          path: `/${that.school_code}/szysk/details`,
          query: {
            id: item.id,
            pid: item.pid,
            className: item.classname
          }
        });
        window.open(routeUrl.href, '_blank');
      });
    }
  }
}
</script>

<style lang="less" scoped>
.login-outer-box {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  .login-mask {
    background: #000000;
    opacity: 0.52;
    width: 100%;
    height: 100%;
    z-index: 1001;
  }
  .login-inner-box {
    width: 384px;
    background-color: #ffffff;
    border-radius: 16px;
    z-index: 1002;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    padding: 30px 34px;
    transition: width 0.3s;
    .student-box {
      font-size: 13px;
      color: #df4531;
      margin-bottom: 10px;
    }
    .line-top {
      margin-bottom: 30px;
      position: relative;
      span {
        font-size: 22px;
        color: #101010;
      }
      .close {
        position: absolute;
        top: 12px;
        right: 0;
        cursor: pointer;
      }
    }
    .login-form-button {
      width: 316px;
      height: 40px;
      line-height: 40px;
      background: #df4531;
      border-radius: 21px;
      color: #fff;
      font-size: 19px;
      border: none;
      box-shadow: none;
      margin: 10px 0 20px;
    }
    .bottom-ways-box {
      width: 100%;
      .wechat-box {
        display: inline-block;
        span {
          font-size: 13px;
          color: #a4a4a4;
        }
        .weixin-icon {
          width: 40px;
          height: auto;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
      .link-right {
        float: right;
        display: inline-block;
        font-size: 16px;
        color: #df4531;
        line-height: 40px;
        &.try {
          margin-left: 15px;
        }
      }
    }
  }
}
.login-form-item {
  margin-bottom: 20px;
  .form-input {
    color: #191919;
    font-size: 14px;
    border: none;
    box-shadow: none;
    height: 40px;
    background: #f8f5f5;
    border-radius: 6px;
  }
  .form-input.input-code {
    width: 200px;
  }
  .img-code {
    width: 86px;
    height: 40px;
    float: right;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: inline-block;
      width: 100%;
      height: auto;
    }
  }
}
.header-box {
  width: 100%;
  .head-top {
    width: 100%;
    height: 30px;
    background: #f5f5f5;
    .jioayu-online {
      color: #333333;
      font-size: 12px;
      line-height: 30px;
      float: left;
    }
    .jioayu-chrome {
      font-size: 11px;
      color: #df4531;
      margin-left: 20px;
      display: inline-block;
      line-height: 30px;
      a,
      a:hover {
        color: #df4531;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    .login-box {
      float: right;
      text-align: right;
      .free-trial {
        color: #e54656;
        font-size: 12px;
        line-height: 30px;
        display: inline-block;
        &::after {
          content: '';
          width: 2px;
          height: 9px;
          background: #eeeff0;
          display: inline-block;
          margin: 0 12px;
        }
      }
      .login {
        color: #e54656;
        font-size: 12px;
        display: inline-block;
        cursor: pointer;
      }
      .login-dropdown-box {
        height: 30px;
        position: relative;
        display: inline-block;
        .login-after {
          color: #121212;
          font-size: 12px;
          display: inline-block;
          line-height: 30px;
          &::after {
            display: inline-block;
            content: '';
            width: 7px;
            height: 5px;
            margin-left: 5px;
            background-image: url('../assets/image/common/up-icon.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
            vertical-align: 3px;
            transition: background-image 0.3s;
          }
        }
        .login-sub-box {
          display: none;
          position: absolute;
          padding: 10px 20px;
          box-sizing: border-box;
          top: 30px;
          left: -35px;
          width: 140px;
          text-align: left;
          background-color: transparent;
          box-shadow: 0px 1px 6px 0px rgba(130, 130, 130, 0.2);
          border-radius: 2px;
          transition: background-color 0.3s;
          z-index: 100;
          .hover-item {
            display: block;
            line-height: 30px;
            &:hover {
              background-color: #fff;
            }
            a {
              color: #393939;
              font-size: 14px;
              display: block;
              &:hover {
                color: #e64757;
                background-color: #fff;
              }
            }
          }
        }

        &:hover .login-after::after {
          background-image: url('../assets/image/common/down-icon.png');
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-position: center;
        }
        &:hover .login-sub-box {
          display: block;
          background-color: #ffffff;
        }
      }
    }
  }
}
.head-two {
  width: 100%;
  background: #ffffff;
  border: 1px solid #ebebeb;
  height: 52px;
  .flex-center {
    display: flex;
    align-items: center;
    width: 1200px;
    height: 50px;
    background: #ffffff;
    justify-content: space-between;
    margin: 0 auto;

    .split-line {
      width: 1px;
      height: 20px;
      background: #e6e3e3;
      margin: 0 20px;
      display: inline-block;
    }
    .left-imgs {
      display: flex;
      align-items: center;
      .zgjyzxIcon {
        margin-right: 12px;
      }
      .schoollogo {
        margin-right: 12px;
        max-height: 32px;
        height: auto;
      }
    }
    .szzyk-img {
      vertical-align: -7px;
      font-size: 14px;
      font-weight: bold;
      font-style: italic;
      color: #4b4b4b;
    }
    .search-middle-box {
      display: flex;
      align-items: center;
      margin-right: 140px;
      .item {
        font-size: 14px;
        color: #4b4b4b;
        cursor: pointer;
      }
      .item.active {
        font-weight: bold;
        color: #de3938;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 20px;
          height: 3px;
          background: #de3937;
          border-radius: 2px;
          bottom: -15px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .renminkeji-box {
      display: flex;
      text-align: center;
      align-items: center;
      .rmkj-text {
        font-size: 12px;
        color: #4b4b4b;
        margin: 5px 10px 0 0;
      }
      img {
        margin-top: 5px;
      }
    }
    .login-box {
      float: right;
      text-align: right;
      .free-trial {
        color: #e54656;
        font-size: 12px;
        line-height: 30px;
        display: inline-block;
        &::after {
          content: '';
          width: 2px;
          height: 9px;
          background: #eeeff0;
          display: inline-block;
          margin: 0 12px;
        }
      }
      .login {
        color: #e54656;
        font-size: 12px;
        display: inline-block;
        cursor: pointer;
      }
      .login-dropdown-box {
        height: 30px;
        position: relative;
        display: inline-block;
        .login-after {
          color: #121212;
          font-size: 12px;
          display: inline-block;
          line-height: 30px;
          &::after {
            display: inline-block;
            content: '';
            width: 7px;
            height: 5px;
            margin-left: 5px;
            background-image: url('../assets/image/common/up-icon.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
            vertical-align: 3px;
            transition: background-image 0.3s;
          }
        }
        .login-sub-box {
          display: none;
          position: absolute;
          padding: 10px 20px;
          box-sizing: border-box;
          top: 30px;
          left: -35px;
          width: 140px;
          text-align: left;
          background-color: transparent;
          box-shadow: 0px 1px 6px 0px rgba(130, 130, 130, 0.2);
          border-radius: 2px;
          transition: background-color 0.3s;
          z-index: 100;
          .hover-item {
            display: block;
            line-height: 30px;
            &:hover {
              background-color: #fff;
            }
            a {
              color: #393939;
              font-size: 14px;
              display: block;
              &:hover {
                color: #e64757;
                background-color: #fff;
              }
            }
          }
          .login-lines {
            width: 100%;
            height: 1px;
            background: #f3f4f5;
            margin: 5px 0;
          }
        }
        &:hover .login-after::after {
          background-image: url('../assets/image/common/down-icon.png');
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-position: center;
        }
        &:hover .login-sub-box {
          display: block;
          background-color: #ffffff;
        }
      }
    }
  }
}
@media (min-device-width: 320px) and (max-width: 689px),
  (max-device-width: 480px) {
  .flex-center {
    width: 100% !important;
  }
}

@media (min-device-width: 320px) and (max-width: 689px),
  (max-device-width: 480px) {
  body {
    width: 100%;
    .header-box {
      display: none;
    }
  }

  .p1 {
    text-align: center;
  }

  video {
    width: 90%;
    height: auto;
    margin: 0 auto;
  }

  .w1000,
  .p4_1 {
    width: 100%;
  }

  .p4_1 .img1 li {
    margin: 0;
  }

  .img1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
  }

  #copyright {
    width: 100% !important;
  }

  .zttop {
    width: 100% !important;
    height: auto !important;
  }

  .img1 li {
    font-size: 12px;
    line-height: 200%;
    margin-bottom: 20px;
    height: 190px;
    min-height: 180px;
    width: 47%;
  }

  .img1 li p {
    text-align: left;
  }

  .p4 {
    margin-top: 20px;
  }

  .mobileImg {
    display: block;
    width: 100%;
  }

  .rm_logo {
    width: 100px;
    margin-top: 15px;
    margin-left: 15px;
  }

  .text_img {
    width: 100%;
  }

  .img1 li .play {
    width: 20px;
    height: auto;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pcImg {
    display: none;
  }

  .bza.w1000 {
    width: 90% !important;
    margin: 20px auto;
    padding: 10px;
  }

  .bza h3 {
    font-size: 18px;
    line-height: 200%;
  }

  .bza p {
    font-size: 12px;
    line-height: 180%;
  }

  .link {
    margin-top: -20px;
  }
}
.copyright {
  line-height: 0 !important;
}
</style>
