<template>
  <a-spin tip="加载中..." :spinning="spinning" class="details">
    <div class="szzykdetails" @contextmenu.prevent.capture ref="szzykdetails">

      <!-- <Loading :showLoading="isShowLoading" /> -->
      <template v-if="school_code == utils.whrj_code">
        <WhrjHeader :active="'/'+school_code+'/zy/szzyk'" />
      </template>
      <template v-else>
        <Header />
        <Navbar />
      </template>
      <div class="topBg">
        <div class="body">
          <div class="route">当前位置：课程思政元素库 > 资源查看</div>
          <div class="leftBox">
            <div class="intro">
              <!-- 3 -->
              <div :class="['title',szzykDetailsData.is_school_original == 1  ? 'badge':'']">
                {{szzykDetailsData.title}}
                <!-- <span v-if='szzykDetailsData.is_open == 2' class="resopen">公开</span> -->
              </div>
              <div class="jumpFooter" @click="jumpFooter">免责声明</div>
              <div class="keyword-box">
                <div v-if="szzykDetailsData && szzykDetailsData.keywords && szzykDetailsData.keywords.length>0" class="key-item">【关键词】
                  <template v-for="(sub,sinx) in szzykDetailsData.keywords">
                    {{sub}}{{((szzykDetailsData.keywords.length  - 1) > sinx) ? '、' : ''}}
                  </template>
                </div>
                <div class="key-nums"> <span>{{szzykDetailsData.new_quote_num}}次使用</span><span>{{szzykDetailsData.new_visit_num}}人浏览</span><span>{{szzykDetailsData.new_favorite_num}}人收藏</span></div>
              </div>
              <div class="introFoot">
                <div class="info">
                  <span v-if="szzykDetailsData.publish_time">时间 <span class="date">{{ szzykDetailsData.publish_time }}</span></span>
                  <span v-if="szzykDetailsData.source">来源 <span class="origin">{{ szzykDetailsData.source }}</span></span>
                  <span v-if="szzykDetailsData.school_name">共享院校 <span class="school">{{ szzykDetailsData.school_name }}</span></span>
                  <span v-if="szzykDetailsData.created_name">共享教师 <span class="teacher">{{ szzykDetailsData.created_name}}【原创】</span></span>

                </div>
                <div class="options">
                  <div class="collect" @click.stop="requestCollect">
                    <img class="collectimg" v-if="isFavorite == 2" src="../../assets/image/szzyk/collect.png" alt="" />
                    <img class="collectimg" v-if="isFavorite == 1" src="../../assets/image/szzyk/collect-active.jpg" alt="" />
                    <span>{{isFavorite == 1 ? '取消收藏': isFavorite == 2 ? '收藏':''}}</span>
                  </div>
                  <!-- <div class="download">
                  <img src="../../assets/image/szzyk/download.png" alt="" />
                  <span>下载</span>
                </div> -->
                  <div class="onlineClass" @click="clickBeikeSelect">
                    <img src="../../assets/image/szzyk/onlineClass.png" alt="" />
                    <span>在线备课</span>
                  </div>
                  <div class="labelPoint" @click="labelPoint">
                    <img src="../../assets/image/common/labelPoint.png">
                  </div>
                </div>
              </div>
            </div>
            <div class="detailsBox">
              <div class="tabs">
                <span :class="tabIndex == 0 ? 'tabActived tabItem' : 'tabItem'" @click="changeTab(0)">资源介绍</span>
                <div class="line"></div>
                <span :class="tabIndex == 1 ? 'tabActived tabItem' : 'tabItem'" @click="changeTab(1)">资源详情</span>
              </div>
              <div class="row"></div>
              <div class="resourcesIntro" v-show="tabIndex == 0">
                <div v-show="szzykDetailsData.abstract" class="resIntro-line">
                  <img src="../../assets/image/szzyk/relationSz.png" alt="">
                  <span class="text">资源简介</span>
                </div>
                <p v-show="szzykDetailsData.abstract" class="introtext">
                  {{szzykDetailsData.abstract}}
                </p>
                <!-- <div v-show="szzykDetailsData.element_list && szzykDetailsData.element_list.length > 0" class="relationSz">
                <div class="title">
                  <img src="../../assets/image/szzyk/relationSz.png" alt="">
                  <span class="text">关联思政元素</span>
                </div>
                <div class="keywords">
                  <span v-for="(item, index) in szzykDetailsData.element_list" :key="index" class="key">{{item}}</span>
                </div>
              </div>
              <div v-show="szzykDetailsData.area_list && szzykDetailsData.area_list.length > 0" class="relationDy">
                <div class="title">
                  <img src="../../assets/image/szzyk/relationDy.png" alt="">
                  <span class="text">关联地域元素</span>
                </div>
                <div class="keywords">
                  <span v-for="(item, index) in szzykDetailsData.area_list" :key="index" class="key">{{item}}</span>
                </div>
              </div> -->
                <div v-show="(szzykDetailsData.element_list && szzykDetailsData.element_list.length > 0) || (szzykDetailsData.element_list_second && szzykDetailsData.element_list_second.length > 0)" class="relationSz">
                  <div v-if="szzykDetailsData.element_list && szzykDetailsData.element_list.length > 0" class="oneElem-box">
                    <div class="title">
                      <img src="../../assets/image/szzyk/relationSz.png" alt="">
                      <span class="text">思政元素</span>
                    </div>
                    <div class="keywords fl">
                      <span v-for="(item, index) in szzykDetailsData.element_list" :key="index" @click="categoryDetail(item)" class="key">{{item}}</span>
                    </div>
                  </div>
                  <div v-if="szzykDetailsData.element_list_second && szzykDetailsData.element_list_second.length > 0" class="oneElem-box">
                    <div class="title">
                      <img src="../../assets/image/szzyk/relationYs.png" alt="">
                      <span class="text">元素要点</span>
                    </div>
                    <div class="keywords">
                      <span v-for="(item, index) in szzykDetailsData.element_list_second" :key="index" class="key">{{item}}</span>
                    </div>
                  </div>
                </div>
                <div v-show="(szzykDetailsData.area_list && szzykDetailsData.area_list.length > 0) || (szzykDetailsData.suggest_list && szzykDetailsData.suggest_list.length > 0)" class="relationDy">
                  <div v-if="szzykDetailsData.suggest_list && szzykDetailsData.suggest_list.length > 0" class="oneElem-box">
                    <div class="title">
                      <img src="../../assets/image/szzyk/advise.png" alt="">
                      <span class="text">应用建议</span>
                    </div>
                    <div class="advise">
                      <template v-for="(sub,sinx) in szzykDetailsData.suggest_list">
                        {{sub}}{{((szzykDetailsData.suggest_list.length  - 1) > sinx) ? '、' : ''}}
                      </template>
                    </div>
                  </div>
                  <div v-if="szzykDetailsData.area_list && szzykDetailsData.area_list.length > 0" class="oneElem-box">
                    <div class="title">
                      <img src="../../assets/image/szzyk/relationDy.png" alt="">
                      <span class="text">关联地域元素</span>
                    </div>
                    <div class="advise">
                      <template v-for="(sub,sinx) in szzykDetailsData.area_list">
                        {{sub}}{{((szzykDetailsData.area_list.length  - 1) > sinx) ? '、' : ''}}
                      </template>
                    </div>
                  </div>
                </div>
                <div class="majors">
                  <div class="title">
                    <img src="../../assets/image/szzyk/majors.png" alt="">
                    <span class="text">适合高职院校学科专业</span>
                  </div>
                  <table class="detail-table">
                    <thead class="detail-head">
                      <th class="detail-th">专业大类</th>
                      <th class="detail-th">专业小类</th>
                      <th class="detail-th">专业</th>
                    </thead>
                    <tbody v-if="szzykDetailsData && szzykDetailsData.subject_list && szzykDetailsData.subject_list.length > 0" class="detail-body">
                      <template v-for="(item,index) in szzykDetailsData.new_subject_list">
                        <template v-for="(two, tinx) in item.children">
                          <tr class="detail-tr" :key="`${index}-${tinx}`">
                            <td v-if="tinx == 0" class="detail-td" :rowspan="item.children.length">{{item.name}}</td>
                            <td :key="index" class="detail-td">{{two.name}}</td>
                            <td class="detail-td">
                              <ul class="td3List">
                                <template v-for="(sub, sinx) in two.children">
                                  <li v-if="sub.type == 3" :key="sinx">
                                    <span class="type">{{sub.name}}</span>
                                  </li>
                                </template>
                              </ul>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="3" class="table-nodata">暂无相关专业</td>
                      </tr>
                    </tbody>
                  </table>

                </div>

                <div class="relationSz border0" v-if="szzykDetailsData.topic_list&&szzykDetailsData.topic_list.length>0">
                  <div class="oneElem-box">
                    <div class="title">
                      <img src="../../assets/image/szzyk/theme.png" alt="">
                      <span class="text">适用主题模块</span>
                    </div>
                    <div class="keywords">
                      <span v-for="(item, index) in szzykDetailsData.topic_list" :key="index" class="key">{{item.name}}</span>
                    </div>
                  </div>
                </div>

                <div class="relationSz border0" v-if="szzykDetailsData.knowledge&&szzykDetailsData.knowledge.length>0">
                  <div class="oneElem-box">
                    <div class="title">
                      <img src="../../assets/image/szzyk/knowledge.png" alt="">
                      <span class="text">适用知识点</span>
                    </div>
                    <div class="keywords">
                      <template v-for="(item, index) in szzykDetailsData.knowledge">
                        <span :key="index" v-if="item" class="key">
                          {{item}}
                        </span>
                      </template>

                    </div>
                  </div>
                </div>
              </div>
              <div class="resourcesDetails" v-show="tabIndex == 1">
                <div id="jeol-article-box" @mouseup="handleMouseSelect($event)" @mouseout="mouseoutEvent" class="article">
                  <div v-html="szzykDetailsData.content"></div>
                  <a href="javascript:void(0)" v-show="isShowBeike" @mouseenter="hoverBeike" @click="goOnlineBeike" class="element-beike-box" :style="`top: ${clientY}px; left:${clientX}px`">在线备课</a>
                </div>
                <!-- 免责声明 from_type 1 是人民科技 2 自采 3校内共建 === 已改为都显示-->
                <div class="mzsm">
                  <div class="title">免责声明：</div>
                  <div>
                    <p>
                      本平台仅提供空间服务，平台所有信息资源仅供学习、教学使用。凡从事与本平台相关的所有行为(包括但不限于访问浏览、利用、转载、下载资料)时，必须保留本平台注明的“资源来源”并标注“仅供教学使用”字样。不得故意或者过失损害本平台的各类合法权利与利益，不得利用本平台以任何方式直接或者间接的从事违反中国法律、国际公约以及社会公德的行为，且应当恪守下述承诺：
                    </p>
                  </div>
                  <div>
                    <p>1、传输和利用资料信息符合中国法律、国际公约的规定、符合公序良俗;</p>
                    <p>2、不将本平台以及与之相关的更新资料用作非法用途以及非正当用途;</p>
                    <p>3、遵守与本平台相关的服务协议、规定、程序和惯例等。</p>
                  </div>
                  <div>
                    <p>平台所涉及的部分互联网信息，如无意侵犯了机构或个人的知识产权，请邮件告之，我方将立即删除。</p>
                    <p>联系邮箱：【管老师guanfm@eol.cn】、【汪老师wangyan@eol.cn】</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightBox">
            <div class="name">推荐思政资源</div>
            <div class="line1"></div>
            <template v-if="recommendList.length > 0">
              <ul class="recommend">
                <template v-for="(item, index) in recommendList">
                  <li v-if="item.id != currentId" :key="index" @click="goDetails(item)">
                    <div class="title">
                      <img v-if="item.type == 1" src="../../assets/image/szzyk/w.png" alt="" />
                      <img v-else src="../../assets/image/szzyk/p.png" alt="" />
                      <!-- <div v-if='item.is_open == 2' class="text">
                      <span class="title-span">{{item.title}}</span> <span class="resopen">公开</span>
                    </div> -->
                      <div class="textall">{{item.title}}</div>
                    </div>
                    <div class="keywords">
                      <span v-for="(sub, subinx) in item.element_list" :key="subinx" class="key">{{sub.name}}</span>
                    </div>
                  </li>
                </template>
              </ul>
            </template>
            <template v-else>
              <a-empty :image="simpleImage"><span slot="description"> 暂无相关推荐</span></a-empty>
            </template>
          </div>
        </div>
      </div>

      <component :is="dialogConfig.name"></component>

      <a-modal title="标注资源属性" class="labelPointModal" :visible="pointVisible" @ok="handlePointOk" @cancel="handlePointCancel" cancelText="取消" okText="提交">
        <div>
          <div class="theme panel normal">
            <div class="panel-title">
              适用主题模块：
            </div>
            <template v-if="topicList&&topicList.length>0">
              <div class="panel-body">
                <div :class="['item',topic_id.includes(item.id) ?'active':'']" v-for="item,index in topicList" :key="index" @click="selectTopic(item)">{{ item.name }}</div>
              </div>
            </template>
            <template v-else>
              <div class="panel-body theme-empty">
                <p>本校尚未设置适合这个资源所在专业大类的主题模块。</p>
                <!-- 管理员显示跳转按钮 -->
                <a :href="`${services.BEIKE_SERVER}/${school_code}/sz/topic`" target="_blank" v-if="appsUserInfo&&(appsUserInfo.user_type==2||appsUserInfo.role_ids == 2)">去添加主题模块</a>
              </div>
            </template>
          </div>

          <div class="theme panel has-set">
            <div class="panel-title mb10">
              已设置主题模块：
            </div>
            <template v-if="szzykDetailsData.topic_list&&szzykDetailsData.topic_list.length>0">
              <div class="panel-body">
                <div class="item" v-for="item,index in szzykDetailsData.topic_list" :key="index">{{ item.name }}</div>
              </div>
            </template>
            <template v-else>
              <div class="panel-body">
                <div class="empty">
                  <img src="../../assets/image/common/nodata.png" alt="">
                  <p>该资源尚未标注适用主题模块~</p>
                </div>
              </div>
            </template>

          </div>
        </div>

        <div>
          <div class="theme panel normal point">
            <div class="panel-title">
              <span class="required">*</span>适用知识点：
              <span class="tip">请用2~30个字描述知识点，多个知识点通过回车分割</span>
            </div>
            <div class="panel-body" @click="focusInput()">
              <vue-tags-input :preventAddingDuplicates="false" v-model="tag" :tags="tags" :add-on-key="[13]" @before-adding-tag="checkTag" @tags-changed="newTags => tags = newTags" :placeholder="
                ''" />
            </div>
          </div>

          <div class="theme panel has-set">
            <div class="panel-title mb10">
              已设置知识点：
            </div>
            <template v-if="szzykDetailsData.knowledge&&szzykDetailsData.knowledge.length>0">
              <div class="panel-body">
                <template v-for="item,index in szzykDetailsData.knowledge">
                  <div class="item" :key="index" v-if="item">{{ item }}</div>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="panel-body">
                <div class="empty">
                  <img src="../../assets/image/common/nodata.png" alt="">
                  <p>该资源尚未标注适用的知识点
                    <br>
                    请您来标注第一个知识点吧~
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </a-modal>

    </div>
  </a-spin>

</template>

<script>
import { Empty, Modal } from 'ant-design-vue';
import Nodata from "../../components/Nodata.vue"

import Navbar from "../../components/Navbar.vue"
// import Loading from "../../components/loading.vue"
import { mapState, mapActions } from "vuex"
import utils from '../../assets/js/utils'
import Header from "../../components/Header.vue"
import services from "../../assets/js/services";
import appleRes from "../../components/appleRes.vue"
import WhrjHeader from "../../components/WhrjHeader.vue";
import VueTagsInput from '@johmun/vue-tags-input';
export default {
  name: "Szzykdetails",
  components: {
    Navbar,
    Header,
    appleRes,
    WhrjHeader,
    VueTagsInput,
    Nodata
    // Loading
  },
  data () {
    return {
      // isShowLoading: false,
      utils,
      services,
      school_code: localStorage.getItem("schoolCode"),
      tabIndex: 0,
      currentId: 0,
      subject_big_id: '',
      subject_small_id: '',
      clientX: 0,
      clientY: 0,
      isShowBeike: false,
      currentElem: null,
      selectionText: '',
      isChrome: false,
      isFavorite: '', // 收藏状态|int|1是2否| 
      TcpatchaObj: null, // 显示验证码
      dialogConfig: {
        name: ""
      },
      spinning: false,
      textTag: ['<p', "<span", "<strong", "<em"],
      html: "",
      pointVisible: false, // 弹框是否显示
      topicList: [], // 所有主题模块列表
      topic_id: [], // 选中的主题模块
      tag: '', // v-modal tag
      tags: [], // tags数组

    };
  },
  computed: {
    ...mapState({
      szzykDetailsData: state => state.szzyk.szzykDetailsData,
      recommendList: state => state.szzyk.recommendList,
      szysBgList: state => state.szysk.szysBgList,
      appsUserInfo: state => state.appsLogin.appsUserInfo
    }),
  },
  beforeCreate () {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created () {
    let that = this
    this.isChrome = utils.bowerUserAgent();
    this.getAllTopic()

    if (this.$route.query.id) {
      this.currentId = this.$route.query.id
      that.requestSzzykDetails(this.$route.query.id)
      // 获取右侧推荐数据
      that.getRecommendList();
    }
  },
  methods: {
    ...mapActions(['getSzzykDetails', 'getSzzyRecommendData', 'goAppsDetailsBeforeLogin', 'getBeikeSelectOffice', 'getBeikeSelectImage', 'getBeikeSelectVideo', 'saveResourceFavorite', 'allTopic']),
    // tags-input限制30个字
    checkTag (obj) {
      if (obj.tag.text.length > 30) {
        return this.$message.error("知识点描述内容过长，建议精简后填写")
      } else if (obj.tag.text.trim().length < 2 && this.tag) {
        return this.$message.error("每个知识点最少输入2个字")
      } else {
        obj.addTag()
      }
    },
    handlePointCancel () {
      this.pointVisible = false
      this.topic_id = []
      this.tags = []
      this.tag = ""
    },
    async handlePointOk () {
      let that = this
      let params = {}
      let tagsArr = []
      that.tags.forEach(item => {
        tagsArr.push(item.text)
      })
      tagsArr.push(this.tag.trim())
      params.id = that.currentId
      params.topic_id = that.topic_id.join(",")
      params.knowledge = tagsArr.join(",")
      params.school_code = that.school_code
      if ((!params.knowledge && !that.tag) && (!this.tag.trim())) {
        that.$message.error("请输入适用知识点")
        this.tag = ""
        return
      } else if (that.tag && that.tag.trim().length < 2) {
        return that.$message.error("每个知识点最少输入2个字")
      } else if (that.tag && that.tag.length > 30) {
        return that.$message.error("知识点描述内容过长，建议精简后填写")
      }
      await services.setResPoint(params).then(async (res) => {
        await that.handlePointCancel()
        await that.requestSzzykDetails(that.$route.query.id)
      })
    },
    // 跳转到分类详情
    categoryDetail (name) {
      services.getCategorySearch({ name: name }).then((res) => {
        if (res.code == '0000') {
          let items = res.data.items
          if (items && items.length > 0) {
            window.open(`/${this.school_code}/zy/szysk/details?id=${items[0].id}&pid=0&className=${this.szysBgList[Math.floor(Math.random() * 5)]}`, "_blank")
          } else {
            this.$message.error(`查询失败：${res.message}`)
          }
        } else {
          this.$message.error(`查询失败：${res.message}`)
        }
      })
    },
    // 点击外部框让input自动focus
    focusInput () {
      document.getElementsByClassName("ti-new-tag-input")[0].focus();
    },
    // 获取主题模块
    getAllTopic () {
      services.getThemeList({ school_code: this.school_code, id: this.$route.query.id }).then((res => {
        this.topicList = res.data
      }))
    },
    // 选择主题模块
    selectTopic (item) {
      if (this.topic_id.includes(item.id)) {
        this.topic_id.splice(this.topic_id.indexOf(item.id), 1);
      } else {
        this.topic_id.push(item.id);
      }
    },
    requestSzzykDetails (id, vailte) {
      let that = this;
      let params = { 'favorite_flag': 1 }
      if (vailte) {
        params.ticket = vailte.ticket
        params.randstr = vailte.randstr
      }
      // 获取资源详情 后加载视频
      that.getSzzykDetails({ id: id, data: params }).then(async res => {
        if (res.code == '0000') {
          if (res.data.favorite) {
            this.isFavorite = res.data.favorite
          }
          if (res.data.content) {
            // 播放视频初始化方法
            await that.addEventMethod()
            // 添加在线备课的事件
            await that.addBeikeMethod()
          }
        } else if (res.code == '2004') {
          that.$router.push({
            path: `/${that.school_code}/zy/page404`,
            query: that.$route.query
          })
        } else if (res.code == '2201') {
          // 触发腾讯验证
          that.initTencentCpatcha()
        } else if (res.code == '2202') {
          // 验证失败
          // 触发腾讯验证
          if (window.TencentCaptcha) {
            that.TcpatchaObj.show(); // 显示验证码
          } else {
            that.initTencentCpatcha()
          }
        } else if (res.code == '2203') {
          // 大于150篇 提示
          Modal.info({
            okType: 'primary',
            okText: '确定',
            closable: true,
            content: res.message ? res.message : '今天已经浏览了足够多的资源了，休息一下明天再看吧',
          })
        }
      })
    },
    getRecommendList () {
      let that = this;
      let param = {
        length: 5,
        page: 1,
        school_code: that.school_code,
        // is_recommend: 2, // 1为不推荐 2为推荐
      }
      if (this.$route.query.subject_big_id) {
        this.subject_big_id = this.$route.query.subject_big_id
        param.subject_big_id = this.$route.query.subject_big_id
      }
      if (this.$route.query.subject_small_id) {
        this.subject_small_id = this.$route.query.subject_small_id
        param.subject_small_id = this.$route.query.subject_small_id
      }

      that.getSzzyRecommendData(param)
    },
    //点击收藏
    requestCollect () {
      let that = this;
      // that.goAppsDetailsBeforeLogin().then(() => {
      //   that.isShowLoading = true
      that.saveResourceFavorite({ id: that.currentId }).then((res) => {
        // that.isShowLoading = false
        if (res.code == '0000') {
          that.$message.success("操作成功")
          that.isFavorite = that.isFavorite == 1 ? 2 : 1
        } else {
          if (res.code != '2001') {
            that.$message.error("操作失败")
          }
        }
      })
      // })
    },
    //切换tab
    changeTab (index) {
      this.tabIndex = index;
      if (index == 1) {
        this.addBeikeMethod()
      }
    },
    // 监听鼠标按下
    handleMouseDown ($event) {
      if ($event.target.tagName == 'A') return
      if (($event.target.tagName != 'IMG' && $event.target.tagName != 'VIDEO') && window.getSelection().toString() == '') this.isShowBeike = false
    },
    // 鼠标选取文本事件
    handleMouseSelect ($event) {
      // 如果没有选取内容 return
      if ($event.target.tagName == 'A') return
      this.currentElem = $event.target
      // if (!this.html) return this.isShowBeike = false
      if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText("")
      }
      let text = window.getSelection().toString()
      this.selectionText = text;
      if (this.currentElem.tagName == 'IMG' || this.currentElem.tagName == 'VIDEO' || this.currentElem.className == 'vjs-poster') {
        var myElement = $event.target; // 获取子元素的引用
        var top = myElement.offsetTop + (this.currentElem.tagName != 'IMG' ? 15 : 0); // 获取子元素相对于父元素顶部的位置
        var left = myElement.offsetLeft + (this.currentElem.tagName != 'IMG' ? 55 : 0); // 获取子元素相对于父元素左侧的位置
        this.clientY = parseInt(top);
        this.clientX = parseInt(left + myElement.offsetWidth - 100);
      } else {
        if (!text) return this.isShowBeike = false // 如果没有选取任何内容，不显示“在线备课”
        this.clientX = parseInt($event.offsetX - 28);
        this.clientY = parseInt($event.target.offsetTop + $event.offsetY + 13);
      }
      this.isShowBeike = true
    },
    // 鼠标进入富文本 在线备课按钮
    hoverBeike () {
      this.isShowBeike = true
    },
    // 点击选取内容去 在线备课平台
    async goOnlineBeike () {
      let that = this
      if (that.isChrome) {
        let type = "" // 1图片 2视频 3组合
        if (that.currentElem) {
          try {
            let clipboardItems
            await navigator.clipboard.writeText("")
            var clipboardData = await navigator.clipboard;
            if (!clipboardData) {
              return this.$message.error("不支持复制粘贴");
            } else {
              if (!document.execCommand('copy')) {
                return this.$message.error("不支持复制");
              }
              clipboardItems = await navigator.clipboard.read();
              for (let clipboardItem of clipboardItems) {
                let data = await clipboardItem.getType('text/html');
                that.html = await data.text()
                that.html = await that.html.replace(/&quot;/g, "").replace(/'\'/g, "")
              }
              navigator.clipboard.writeText("")
              for (let i = 0; i < that.textTag.length; i++) {
                // 找到文字&&图片/视频 的组合
                if (that.html.indexOf(that.textTag[i]) > -1 && (that.html.indexOf("<img") > -1) || that.html.indexOf("<video") > -1) {
                  type = 3
                  break;
                  // 未找到文字，找到图片了
                } else if ((that.html.indexOf(that.textTag[i]) == -1 && (that.html.indexOf("<img") > -1))) {
                  type = 1
                  break;
                  // 未找到文字，找到视频了
                } else if ((that.html.indexOf(that.textTag[i]) == -1 && (that.html.indexOf("<video") > -1))) {
                  type = 2
                  break;
                  // 纯文字
                } else {
                  type = 3
                }
              }
            }
          } catch {
            that.spinning = false
            navigator.clipboard.writeText("")
          }
          that.spinning = true
          try {
            let copyText = window.getSelection().toString()
             
            if ((that.currentElem.tagName == 'VIDEO' || this.currentElem.className == 'vjs-poster') && type != 3 && !copyText) {
              let fileId = that.currentElem.getAttribute("data-id") || that.currentElem.previousElementSibling.previousElementSibling.previousElementSibling.getAttribute("data-id")
              if (fileId) {
                // 2022-8-12 思政2.0去掉从前台去备课功能 展示弹窗提示
                that.getBeikeSelectVideo({
                  id: fileId,
                  resource_id: that.szzykDetailsData.id,
                })
                // that.clickBeikeSelect()
              }
            } else if (that.currentElem.tagName == 'IMG' && type != 3 && !copyText) {
              let srcTxt = that.currentElem.getAttribute("src")
              if (srcTxt) {
                that.getBeikeSelectImage({
                  url: srcTxt,
                  resource_id: that.szzykDetailsData.id,
                })
                // that.clickBeikeSelect()
              }
            } else {
              if (that.html) {
                await that.getBeikeSelectOffice({
                  content: `${that.html}`
                  // resource_id: that.szzykDetailsData.id,
                })
              } else {
                this.$message.error("不支持复制粘贴")
                that.spinning = false
                return
              }

            }
          } catch (err) {
            that.spinning = false
            navigator.clipboard.writeText("")
          }
          that.spinning = false
        }
      } else {
        that.messageModal()
      }


      // } else {
      //   that.messageModal()
      // }
    },
    // 鼠标再文章内容时 判断是否在img dom
    mouseoverEvent ($event) {
      if ($event.target.tagName == 'A') return
      if (window.getSelection().toString()) {
        return
      }
      this.currentElem = $event.target
      this.isShowBeike = true
      // 如果是非文本类型。需要将在线备课按钮定位到素材的右上角
      if (this.currentElem.tagName == 'IMG' || this.currentElem.tagName == 'VIDEO' || this.currentElem.className == 'vjs-poster') {
        var myElement = $event.target; // 获取子元素的引用
        var top = myElement.offsetTop + (this.currentElem.tagName != 'IMG' ? 15 : 0); // 获取子元素相对于父元素顶部的位置
        var left = myElement.offsetLeft + (this.currentElem.tagName != 'IMG' ? 55 : 0); // 获取子元素相对于父元素左侧的位置
        this.clientY = parseInt(top);
        this.clientX = parseInt(left + myElement.offsetWidth - 100);
      } else {
        this.clientX = parseInt($event.offsetX - 28);
        this.clientY = parseInt($event.target.offsetTop + $event.offsetY + 13);
      }
    },
    // 鼠标离开文章内容时
    mouseoutEvent () {
      // 如果选中了文字，fixed 备课按钮
      if (window.getSelection().toString()) return
      this.isShowBeike = false
    },
    // 添加在线备课的事件
    addBeikeMethod () {
      let that = this;
      that.$nextTick(() => {
        let articleEle = document.getElementById('jeol-article-box')
        let allEleArr = [], subImgArr = [], subVideoArr = []
        subImgArr = articleEle.getElementsByTagName('img');
        subVideoArr = [...articleEle.getElementsByTagName('video'), ...articleEle.getElementsByClassName('vjs-poster')];
        allEleArr = [...subImgArr, ...subVideoArr]
        if (allEleArr && allEleArr.length > 0) {
          for (let i = 0; i < allEleArr.length; i++) {
            if (allEleArr[i].addEventListener) {                    // 所有主流浏览器，除了 IE 8 及更早版本
              allEleArr[i].addEventListener("mouseenter", that.mouseoverEvent);
              allEleArr[i].addEventListener("mouseleave", that.mouseoutEvent);
            } else if (allEleArr[i].attachEvent) {                  // IE 8 及更早版本
              allEleArr[i].attachEvent("mouseenter", that.mouseoverEvent);
              allEleArr[i].attachEvent("mouseleave", that.mouseoutEvent);
            }
          }
        }
      })

    },
    // 在线备课弹出提示事件 - 新版本
    // clickBeikeSelect () {
    //   // Modal.info({
    //   //   title: '温馨提示',
    //   //   okType: 'primary',
    //   //   okText: '申请开通更多资源',
    //   //   closable: true,
    //   //   content: (<div class="jioayu-chrome">
    //   //     请拨打服务咨询电话或申请开通更多资源，获得在线备课权限<br />服务咨询： 宋老师  18701110987
    //   //   </div>),
    //   //   onOk () {
    //   //     window.open(`${services.YXT_GW}/need`)
    //   //   }
    //   // })
    //   this.isShowBeike = false
    //   this.dialogConfig.name = "appleRes"
    // },
    // 点击在线备课按钮事件 - 旧版本逻辑
    clickBeikeSelect () {
      let that = this;
      if (that.isChrome) {
        that.spinning = true
        let articleBox = document.getElementById("jeol-article-box");
        let subImg = articleBox.getElementsByClassName("img")
        let subVideo = articleBox.getElementsByClassName("video")
        if ((that.szzykDetailsData && that.szzykDetailsData.type != 2) && (subImg.length < 1 && subVideo.length < 1)) {
          if (articleBox.innerText) {
            let newHTML = articleBox.innerHTML.replace(/在线备课/g, "")
            that.getBeikeSelectOffice({
              content: newHTML,
              // resource_id: that.szzykDetailsData.id,
            })
              .then(() => {
                that.spinning = false

                //   if (res.code == '0000' && res.data.url) {
                //     window.open(res.data.url + `&refer=${window.location.href}`)
                //   }
              })
          }
        } else {
          that.spinning = false
          that.$message.error("该资源不支持直接备课，请在资源详细内容中选择需要的部分选用备课")
        }
      } else {
        that.messageModal()
      }
    },
    // chrome浏览器弹出提示
    messageModal () {
      Modal.info({
        okType: 'primary',
        okText: '确定',
        closable: true,
        content: (<div class="jioayu-chrome">
          资源在线备课使用Chrome 80 以上版本浏览器体验效果更佳，点击
          <a href="https://www.google.cn/chrome/index.html" target="_blank">下载浏览器</a>。
        </div>),
      })
    },
    // 获取详情的video 加载视频播放插件
    addEventMethod () {
      let that = this;
      that.$nextTick(() => {
        let videoElement = document.getElementsByTagName('video'); // 获取页面上所有的video对象
        if (videoElement && videoElement.length > 0) {
          for (let i = 0; i < videoElement.length; i++) {
            let appid = videoElement[i].getAttribute("data-appid")
            let domid = videoElement[i].getAttribute("id")
            let fileid = videoElement[i].getAttribute("data-fileid")
            if (appid && domid && fileid) {
              that.initVideoFile(domid, fileid, appid)
            }
          }
        }
      })
    },
    // 思政资源库详情
    goDetails (item) {
      let that = this;
      that.goAppsDetailsBeforeLogin().then(() => {
        // if (item.is_open == 2) {
        let params = {
          length: 5,
          page: 1,
          is_recommend: 2, // 1为不推荐 2为推荐
        };
        if (that.subject_big_id) {
          params.subject_big_id = that.subject_big_id
        }
        if (that.subject_small_id) {
          params.subject_small_id = that.subject_small_id
        }
        let routeUrl = that.$router.resolve({
          path: `/${that.school_code}/zy/szzyk/details`,
          query: {
            id: item.id,
            subject_big_id: params.subject_big_id,
            subject_small_id: params.subject_small_id,
          }
        })
        window.open(routeUrl.href, utils.isMobile() ? '_self' : '_blank');
        // that.getSzzykListData(params)
        // } else {
        //   // that.noAuthMessageModal()
        //   this.dialogConfig.name = "appleRes"
        // }
      })
    },
    // 无权限查看课程资源时弹层提示
    // noAuthMessageModal () {
    //   Modal.info({
    //     title: '温馨提示',
    //     okType: 'primary',
    //     okText: '申请开通更多资源',
    //     closable: true,
    //     content: (<div class="jioayu-chrome">
    //       请拨打服务咨询电话或申请开通更多资源，获得更多资源使用权限。<br />服务咨询： 宋老师  18701110987
    //     </div>),
    //     onOk () {
    //       window.open(`${services.YXT_GW}/need`)
    //     }
    //   })
    // },
    // 初始化视频插件
    initVideoFile (elemId, qclude_id, appId) {
      utils.loadScript(window.location.origin + "/vod/hls.min.0.12.4.js", () => {
        utils.loadScript(window.location.origin + "/vod/tcplayer.min.js", () => {
          if (typeof window.TCPlayer === "function") {
            window.TCPlayer(elemId, {
              // player-container-id 为播放器容器ID，必须与html中一致
              fileID: qclude_id, // 请传入需要播放的视频filID 必须
              // fileID: "5285890807937512700",
              controls: true,
              // playbackRates: [0.5, 1, 1.25, 1.5, 2],
              appID: appId, // 请传入点播账号的appID 必须
              width: utils.isMobile() ? 355 : 720, //视频的显示宽度
              height: utils.isMobile() ? 300 : 403, //视频的显示高度
              definition: 30,
              autoplay: false,
              plugins: {
                // ContinuePlay: {
                // 	// 开启续播功能
                // 	auto: true, //[可选] 是否在视频播放后自动续播
                // 	text: "上次播放至 ", //[可选] 提示文案
                // 	btnText: "恢复播放", //[可选] 按钮文案
                // },
              },
              // controlBar: {
              //   playbackRateMenuButton: false
              // }
            });
          }
        })
      })
    },
    // 初始化腾讯验证码
    initTencentCpatcha () {
      let that = this
      utils.loadScript(window.location.origin + "/tencent/TCaptcha.js", () => {
        //方法1: 直接生成一个验证码对象。
        try {
          that.TcpatchaObj = new window.TencentCaptcha('2013226553', that.tencentCallback);
          that.TcpatchaObj.show(); // 显示验证码
        } catch (error) {
          that.loadErrorCallback();
        }
      })
    },
    tencentCallback (res) {
      /* res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
        res（客户端出现异常错误 仍返回可用票据） = {ret: 0, ticket: "String", randstr: "String", errorCode: Number, errorMessage: "String"}
        res（用户主动关闭验证码）= {ret: 2}
      */
      let that = this;
      if (res.ticket && res.randstr) {
        // 上传票据 可根据errorCode和errorMessage做特殊处理或统计
        that.requestSzzykDetails(that.currentId, res)
      }
    },
    // 验证码js加载错误处理
    loadErrorCallback () {
      let that = this
      var CaptchaAppId = ''
      /* 生成票据或自行做其它处理 */
      var ticket = 'terror_1001_' + CaptchaAppId + Math.floor(new Date().getTime() / 1000);
      that.tencentCallback({
        ret: 0,
        randstr: '@' + Math.random().toString(36).substr(2),
        ticket: ticket,
        errorCode: 1001,
        errorMessage: 'jsload_error',
      });
    },
    labelPoint () {
      this.pointVisible = true
    },
    // 跳转到页面底部
    jumpFooter () {
      this.changeTab(1)
      this.$nextTick(() => {
        window.scrollTo({
          top: this.$refs.szzykdetails.clientHeight
        })
      })
    }

  },
};
</script>

<style lang="less" scoped>
.szzykdetails {
  background: #f5f7fa;
  overflow: hidden;
  padding-bottom: 20px;
  position: relative;
  min-height: calc(100vh - 180px);
  .topBg {
    background: url('../../assets/image/szzyk/bg.png') no-repeat;
    background-size: 100% 100%;
    height: 156px;
    .body {
      width: 1200px;
      margin: 0 auto;
      .route {
        height: 50px;
        line-height: 50px;
        color: #a47148;
        font-size: 12px;
      }
      .leftBox {
        width: 890px;
        float: left;
        // overflow: hidden;
        .intro {
          background: #fff;
          padding: 25px 40px 15px;
          position: relative;
          .jumpFooter {
            position: absolute;
            top: 30px;
            right: 40px;
            font-size: 13px;
            color: #e22323;
            cursor: pointer;
          }
          .title {
            color: #272727;
            font-size: 22px;
            position: relative;
            // padding-left: 43px;
            padding-right: 72px;
            &.badge::after {
              content: '';
              display: inline-block;
              width: 71px;
              height: 22px;
              // position: absolute;
              // left: -4px;
              // transform: translateX(-50%);
              background-image: url('../../assets/image/szzyk/school-badge.png');
              background-size: cover;
              vertical-align: -3px;
              margin-left: 5px;
              // top: 7px;
            }
            .resopen {
              font-size: 13px;
              color: #ffffff;
              background: #ee373d;
              padding: 2px 6px;
              border-radius: 6px;
              vertical-align: 3px;
              margin-left: 10px;
            }
          }
          .keyword-box {
            width: 100%;
            margin: 25px 0 10px 0;
            .key-item {
              display: inline-block;
              color: #894407;
              font-size: 14px;
              margin-right: 40px;
            }
            .key-nums {
              display: inline;
              color: #999999;
              margin-right: 10px;
              span {
                display: inline;
                font-size: 12px !important;
                &::after {
                  content: '';
                  width: 1px;
                  height: 10px;
                  background: #dddddd;
                  display: inline-block;
                  margin: 0 10px;
                }
                &:last-child::after {
                  display: none;
                }
              }
            }
          }
          .introFoot {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 25px;
            flex-wrap: wrap;
            .info {
              color: #676f7f;
              font-size: 12px;
              margin-bottom: 10px;
              .date {
                color: #9f9f9f;
                font-size: 12px;
                margin-right: 40px;
                margin-left: 10px;
              }
              .origin {
                color: #9f9f9f;
                font-size: 12px;
                margin-left: 10px;
                margin-right: 40px;
              }
              .school {
                color: #9f9f9f;
                font-size: 12px;
                margin-left: 10px;
                margin-right: 40px;
              }
              .teacher {
                color: #9f9f9f;
                font-size: 12px;
                margin-left: 10px;
              }
            }
            .options {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              div {
                &:not(:last-child) {
                  margin-right: 30px;
                }
                display: flex;
                align-items: center;
                cursor: pointer;
              }
              img {
                margin-right: 5px;
              }
              .collectimg {
                width: 22px;
              }
              span {
                color: #df4332;
                font-size: 14px;
              }
            }
          }
        }
        .detailsBox {
          background: #fff;
          padding: 20px 40px;
          margin-top: 10px;
          .tabs {
            display: flex;
            align-items: center;
            .tabItem {
              font-size: 16px;
              font-weight: bold;
              color: #3d3d3d;
              cursor: pointer;
            }
            .tabActived {
              color: #dd363a;
              position: relative;
            }
            .tabActived::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: linear-gradient(162deg, #dd363a 0%, #df4531 100%);
              border-radius: 1px;
              left: 0px;
              bottom: -15px;
            }
            .line {
              width: 1px;
              height: 16px;
              background: #efeded;
              border-radius: 1px;
              margin: 0 20px;
            }
          }
          .row {
            height: 1px;
            background: #f3f3f3;
            width: 890px;
            margin-top: 15px;
            margin-left: -30px;
          }
          .resourcesIntro {
            margin-top: 20px;
            .resIntro-line {
              img {
                margin-right: 10px;
                vertical-align: -2px;
              }
              .text {
                color: #1a1a1a;
                font-size: 16px;
              }
            }
            .introtext {
              margin-top: 10px;
              background: #f5f7fa;
              border-radius: 2px;
              padding: 20px;
              color: #737373;
              font-size: 14px;
            }
            .relationSz,
            .relationDy {
              margin-top: 20px;
              border-bottom: 1px solid #f5f7fa;
              padding-bottom: 20px;
              display: flex;
              .oneElem-box {
                flex: 1;
              }
              .title {
                display: flex;
                align-items: center;
                img {
                  margin-right: 8px;
                  width: 16px;
                  height: auto;
                }
                .text {
                  color: #999999;
                  font-size: 16px;
                }
              }
              .keywords {
                margin: 10px 0 0 10px;
                .key {
                  padding: 0 12px;
                  margin-bottom: 10px;
                  height: 24px;
                  line-height: 24px;
                  background: #f9f5e9;
                  border-radius: 12px;
                  text-align: center;
                  display: inline-block;
                  color: #b75031;
                  font-size: 12px;
                  margin-right: 10px;
                }
                &.fl .key {
                  cursor: pointer;
                  text-decoration: underline;
                }
              }
              .advise {
                color: #222222;
                font-size: 12px;
                line-height: 20px;
                margin: 10px 0 0 22px;
              }
            }
            .majors {
              margin-top: 20px;
              padding-bottom: 20px;
              .title {
                display: flex;
                align-items: center;
                img {
                  margin-right: 10px;
                }
                .text {
                  color: #1a1a1a;
                  font-size: 16px;
                }
              }
            }
          }
          .resourcesDetails {
            margin-top: 20px;
            .article {
              color: #121212;
              font-size: 14px;
              padding-bottom: 20px;
              width: 100%;
              overflow: hidden;
              position: relative;
              /deep/ p {
                text-indent: 2em !important;
                margin: 15px 0 !important;
                line-height: 26px !important;
              }
              /deep/ img {
                max-width: 100% !important;
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }
      .rightBox {
        width: 290px;
        float: right;
        overflow: hidden;
        background: #fff;
        padding: 15px 20px 30px;
        .name {
          color: #272727;
          font-size: 16px;
          font-weight: bold;
        }
        .line1 {
          height: 1px;
          background: #f3f3f3;
          opacity: 0.83;
          border-radius: 1px;
          width: 290px;
          margin-left: -20px;
          margin-top: 15px;
        }
        .recommend {
          margin-top: 20px;
          li {
            margin-top: 20px;
            .title {
              display: flex;
              align-items: flex-start;
              img {
                margin-right: 10px;
                width: 20px;
              }
              .textall {
                font-size: 14px;
                color: #333;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                cursor: pointer;
              }
              .text {
                font-size: 14px;
                color: #333;
                cursor: pointer;
                .title-span {
                  max-width: 170px;
                  display: inline-block;
                  vertical-align: middle;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .resopen {
                  font-size: 12px;
                  color: #ffffff;
                  background: #ee373d;
                  padding: 2px 6px;
                  border-radius: 6px;
                }
              }
              .text:hover {
                color: #121212;
              }
            }
            .keywords {
              margin-top: 10px;
              height: 24px;
              overflow: hidden;
              .key {
                padding: 0 12px;
                margin-bottom: 10px;
                height: 24px;
                line-height: 24px;
                background: #f9f5e9;
                border-radius: 12px;
                font-size: 12px;
                color: #b75031;
                text-align: center;
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
          li:hover {
            .text {
              color: #121212;
            }
          }
        }
      }
    }
  }
}
.detail-table {
  width: 100%;
  margin-top: 20px;
  border: 1px solid #e3e3e3;
  overflow: hidden;
  .detail-head {
    width: 100%;
    .detail-th {
      border-right: 1px solid #e3e3e3;
      background: #f5f5f5;
      width: 25%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-weight: bold;
      color: #2e2e2e;
      padding-left: 30px;
      &:last-child {
        width: 50%;
      }
    }
  }
  .detail-body {
    width: 100%;
    color: #492600;
    .detail-tr {
      width: 100%;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      .detail-td {
        width: 25%;
        border-right: 1px solid #e3e3e3;
        // padding-left: 30px;
        padding: 10px 0 10px 30px;
        &:last-child {
          width: 50%;
        }
      }
      .td3List {
        li {
          margin: 2px 0;
          // color: #2E2E2E;
          color: #492600;
          font-size: 14px;
        }
      }
    }
  }
  .table-nodata {
    padding: 30px 0 20px;
    text-align: center;
    width: 100%;
  }
}
.element-beike-box {
  // position: fixed;
  position: absolute;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.15);
  z-index: 200;
  width: 100px;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  color: #333;
  text-align: center;
  cursor: pointer;
  display: block;
  &:hover {
    color: #121212;
  }
}
.mzsm p {
  font-size: 13px;
  color: #666;
  line-height: 1.5;
}

.mzsm .title {
  font-size: 13px;
  color: #e22323;
  font-weight: bold;
  padding-bottom: 10px;
}

.mzsm div {
  padding-bottom: 15px;
}

.jioayu-chrome {
  font-size: 14px;
  color: #333;
  display: inline;
  line-height: 30px;
  a,
  a:hover {
    color: #df4531;
  }
  a:hover {
    text-decoration: underline;
  }
}
.labelPoint {
  width: 87px;
  height: 24px;
  background: #dd363a;
  justify-content: center;
  border: 1px solid #dd363a;
  img {
    width: 68px;
    margin: 0 !important;
  }
}
.mb10 {
  margin-bottom: 6px !important;
}
</style>
<style>
.ant-message .ant-message-notice:not(:first-child) {
  display: none;
}
.resourcesDetails .article img {
  max-width: 720px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: inherit;
}
.resourcesDetails .article .tcplayer {
  margin: 10px auto;
}
.details.ant-spin-nested-loading > div > .ant-spin {
  position: fixed;
  top: 20%;
}
.labelPointModal .ant-modal-content {
  border-radius: 8px;
  padding: 0 30px;
}
.labelPointModal .ant-modal-header {
  padding: 20px 0 12px;
}

.labelPointModal .ant-modal-header .ant-modal-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  display: inline-block;
  position: relative;
}
.labelPointModal .ant-modal-header .ant-modal-title::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -14px;
  width: 94px;
  height: 2px;
  background: #df4531;
}
.labelPointModal .ant-modal-body {
  padding: 0 12px 0;
}
.labelPointModal .ant-modal {
  width: 950px !important;
}

.labelPointModal .ant-modal-body {
  border: 1px solid #e5e6e9;
  margin-top: 12px;
}
.labelPointModal .ant-modal-footer {
  border: 0;
  padding: 12px 0 15px;
}
.labelPointModal .ant-modal-body {
  display: flex;
}
.labelPointModal .ant-modal-body > div {
  width: 50%;
  padding-top: 15px !important;
}
.labelPointModal .ant-modal-body > div:first-child {
  border-right: 1px solid #e5e6e9;
  padding-right: 12px;
}
.labelPointModal .ant-modal-body > div:last-child {
  padding: 0 0 0 14px;
}
.labelPointModal .ant-modal-body .panel {
  width: 100%;
  height: 28vh;
  overflow-y: auto;
}
.labelPointModal .ant-modal-body .panel-title {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 8px;
}
.labelPointModal .ant-modal-body .panel-title .tip {
  font-size: 12px;
  font-weight: normal;
  color: #b1b5c6;
}
.labelPointModal .ant-modal-body .panel.normal {
  border-bottom: 1px solid #f4f5f8;
}

.labelPointModal .ant-modal-body .panel.normal .panel-body {
  display: flex;
  flex-wrap: wrap;
}
.labelPointModal .ant-modal-body .panel.normal .panel-body .item {
  border-radius: 13px;
  padding: 1.5px 12px;
  font-size: 14px;
  margin: 0 4px 4px 0;
  color: #df4531;
  background: #fff8f7;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid #e78980;
}
/* .labelPointModal .ant-modal-body .panel.normal .panel-body .item:hover */
.labelPointModal .ant-modal-body .panel.normal .panel-body .item.active {
  background: #df4531;
  border: 1px solid #df4531;
  color: #ffffff;
}
.labelPointModal .ant-modal-body .panel.has-set {
  margin: 12px 0 0;
  position: relative;
}
.labelPointModal .ant-modal-body .panel.has-set .panel-body {
  display: flex;
  flex-wrap: wrap;
}
.labelPointModal .ant-modal-body .panel.has-set .panel-body .item {
  background: #f9f5e9;
  border-radius: 13px;
  font-size: 14px;
  color: #b75031;
  padding: 2.5px 12px;
  margin: 0 4px 4px 0;
}
.labelPointModal .ant-modal-footer div button:first-child {
  width: 52px;
  height: 28px;
  background: #cbccd0;
  text-align: center;
  line-height: 28px;
  padding: 0;
  margin-right: 16px;
  border-radius: 0;
  color: #fff;
  font-size: 14px;
  border: none;
}
.labelPointModal .ant-modal-footer div button:last-child {
  width: 52px;
  height: 28px;
  background: #df4531;
  text-align: center;
  line-height: 28px;
  padding: 0;
  border-radius: 0;
  color: #fff;
  font-size: 14px;
  border: none;
  margin: 0;
}
.labelPointModal .ant-modal-body .panel .panel-body .ti-input {
  width: 100%;
  height: 100%;
  background: #fafafa;
  border: none;
  padding: 8px 6px;
}
.labelPointModal
  .ant-modal-body
  .panel
  .panel-body
  .ti-new-tag-input-wrapper
  input {
  background: #fafafa;
  width: 100%;
  height: 25px;
}
.labelPointModal .ant-modal-body .panel .panel-body .ti-tags {
  background: #fafafa;
}
.labelPointModal .ant-modal-body .panel .panel-body .ti-tag {
  background: #df4531;
  border-radius: 13px;
  padding: 5px 30px 5px 10px;
  position: relative;
}
.labelPointModal .ant-modal-body .panel .panel-body .ti-tag span {
  line-height: 1.2;
}
.labelPointModal .ant-modal-body .panel .panel-body .vue-tags-input {
  width: 100%;
  height: 100%;
  cursor: text;
}
.labelPointModal .ant-modal-body .panel .panel-body .vue-tags-input * {
  display: inline-block;
}
.labelPointModal .ant-modal-body .panel .panel-body .vue-tags-input input {
  display: initial;
  width: 390px;
}
.labelPointModal .ant-modal-body .panel.normal.point {
  border-bottom: none;
}
.labelPointModal .ant-modal-body .panel.normal.point .panel-body {
  width: 100%;
  height: calc(100% - 30px);
}
.labelPointModal .ant-modal-body .panel.normal.point .panel-title {
  margin-bottom: 7px;
}
.labelPointModal
  .ant-modal-body
  .panel.normal.point
  .panel-body
  .ti-tag
  .ti-actions
  i {
  transform: translateY(2px);
  margin-left: 5px;
}
.labelPointModal
  .ant-modal-body
  .panel.normal.point
  .panel-body
  .ti-new-tag-input-wrapper {
  margin: 0;
  padding: 0 0 0 5px;
}
.labelPointModal .border0 {
  border: 0 !important;
}
.labelPointModal .relationSz.border0 {
  margin-top: 0 !important;
  padding-bottom: 10px !important;
}
.labelPointModal .required {
  color: #df4531;
  margin-right: 3px;
}
/* .labelPointModal .ant-modal-body .panel.normal.point .panel-body .ti-tag {
  display: flex !important;
} */
.labelPointModal .theme-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 30px);
  font-size: 14px;
}
.labelPointModal .theme-empty a {
  color: #1a9bff;
  padding-top: 5px;
}
.labelPointModal .empty {
  font-size: 14px;
  color: #999;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.labelPointModal .empty img {
  width: 120px;
}
.labelPointModal .empty p {
  padding-top: 15px;
}
.ti-actions {
  position: absolute;
  right: 10px;
}
</style>
