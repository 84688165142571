<template>
  <div class="szysk">
    <template v-if="school_code == utils.whrj_code">
      <WhrjHeader />
    </template>
    <template v-else>
      <Header />
      <Navbar />
    </template>
    <div class="header">
      <!-- <img src="../../assets/image/szysk/yskbg.png" alt="" /> -->
      <div v-if="school_code == utils.whrj_code" class="container">
        <router-link class="szysk-button" :to="`/${school_code}/zy/szzyk`">通过专业方查找资源</router-link>
      </div>
    </div>
    <div class="body">
      <div class="bodyTop">
        <div class="leftBox">
          <span class="title">思政元素体系</span>
          <div class="type">
            <span :class="typeIndex==0?'actived':''" @click="changeType(0)">全部</span>
            <span :class="typeIndex==1?'actived':''" @click="changeType(1)">A ~ F</span>
            <span :class="typeIndex==2?'actived':''" @click="changeType(2)">G ~ L</span>
            <span :class="typeIndex==3?'actived':''" @click="changeType(3)">M ~ S</span>
            <span :class="typeIndex==4?'actived':''" @click="changeType(4)">T ~ Z</span>
          </div>
        </div>
        <div class="rightBox">
          <input type="text" placeholder="请输入检索词" v-model="searchText" @keyup.enter="searchEvent" />
          <div v-show="searchText && searchText.length > 0" class="clear-input" @click="clearSearchEvent"></div>
          <div class="search" @click="searchEvent">搜索</div>
        </div>
      </div>
      <div class="model-sz-box">
        <template v-if="szyskElementData.length > 0">
          <div class="sizheng-list">
            <div :class="`sizheng-item ${item.classname}`" v-for="(item,i) in szyskElementData" :key="i" @click="toDetail(item)" v-html="item.name"></div>
            <div class="sizheng-item"></div>
            <div class="sizheng-item"></div>
            <div class="sizheng-item"></div>
          </div>
        </template>
        <template v-else>
          <a-empty :image="simpleImage"><span slot="description"> 暂无数据</span></a-empty>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue';
import Navbar from "../../components/Navbar.vue";
import "../../assets/css/home.less";
import utils from "../../assets/js/utils";
import { mapState, mapActions } from "vuex"
import Header from '../../components/Header.vue';
import WhrjHeader from "../../components/WhrjHeader.vue";
export default {
  name: "Szysk",
  components: {
    Navbar,
    Header,
    WhrjHeader
  },
  data () {
    return {
      utils,
      typeIndex: 0,//类型索引
      searchText: "",// 搜索关键字
      school_code: localStorage.getItem("schoolCode"),
    };
  },
  beforeCreate () {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  computed: {
    ...mapState({
      szyskElementData: state => state.szysk.szyskElementData
    }),
  },
  mounted () {
    this.getSzyskElement({ length: '-1', pid: 0, school_code: this.school_code });
  },
  methods: {
    ...mapActions(['getSzyskElement', 'goAppsDetailsBeforeLogin']),
    changeType (index) {
      this.typeIndex = index;
      this.getListData()
    },
    searchEvent () {
      this.getListData()
    },
    clearSearchEvent () {
      this.searchText = ''
      this.getListData()
    },
    getListData () {
      let param = {
        length: '-1',
        is_highlight: 2,
        pid: 0,
        school_code: this.school_code
      }
      if (this.searchText) {
        param.q = this.searchText
      }
      if (this.typeIndex > 0) {
        param.fword_type = this.typeIndex
      }
      this.getSzyskElement(param);
    },
    toDetail (item) {
      let that = this;
      that.goAppsDetailsBeforeLogin().then(() => {
        let routeUrl = that.$router.resolve({
          path: `/${that.school_code}/zy/szysk/details`,
          query: {
            id: item.id,
            pid: item.pid,
            className: item.classname
          }
        });
        window.open(routeUrl.href, utils.isMobile() ? '_self' : '_blank');
      });
    }
  }
};
</script>
<style lang="less" scoped>
.szysk {
  .header {
    width: 100%;
    height: 140px;
    background: url('../../assets/image/szysk/yskbg-tx.png') no-repeat center
      center;
    background-size: cover;
    position: relative;
    .szysk-button {
      height: 34px;
      display: inline-block;
      line-height: 34px;
      background: #a10a08;
      border-radius: 17px;
      padding: 0 17px;
      font-size: 14px;
      margin: 39px 0 0;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
      float: right;
      &::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 12px;
        margin-right: 8px;
        background: url('../../assets/image/szzyk/yskbtn.png') no-repeat center
          center;
        background-size: auto 100%;
      }
    }
  }
  .body {
    width: 1200px;
    margin: 0 auto;
    .bodyTop {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f3f3f3;
      padding-bottom: 15px;
      .leftBox {
        .title {
          font-size: 20px;
          font-weight: bold;
          color: #dd363a;
          position: relative;
        }
        .title::after {
          content: '';
          position: absolute;
          width: 100%;
          left: 0px;
          bottom: -21px;
          height: 2px;
          background: linear-gradient(162deg, #dd363a 0%, #df4531 100%);
          border-radius: 1px;
        }
        .type {
          display: inline-block;
          margin-left: 40px;
          font-size: 13px;
          color: #676f7f;
          span {
            margin-right: 30px;
            cursor: pointer;
          }
          span.actived {
            color: #ee373d;
          }
        }
      }
      .rightBox {
        position: relative;
        text-align: right;
        input {
          width: 260px;
          height: 34px;
          background: #ffffff;
          border: 1px solid #f8818d;
          border-radius: 16px;
          padding-left: 20px;
          outline-color: #f8818d;
          position: relative;
          padding-right: 65px;
        }
        input:focus-visible {
          border: 1px solid #f8818d;
          outline: none;
        }
        .clear-input {
          width: 30px;
          height: 30px;
          background: url('../../assets/image/common/clear.png') no-repeat
            center center;
          background-size: 15px auto;
          cursor: pointer;
          display: inline-block;
          position: absolute;
          right: 60px;
          top: 1px;
        }
        .search {
          width: 59px;
          height: 34px;
          line-height: 34px;
          background: #e64757;
          border-radius: 0px 16px 16px 16px;
          text-align: center;
          font-size: 13px;
          color: #f5f7fa;
          position: absolute;
          bottom: 0px;
          right: 0px;
          cursor: pointer;
        }
      }
    }
    .model-sz-box {
      background: #ffffff;
      padding-top: 30px;
      min-height: 500px;
      .sizheng-list {
        flex-wrap: wrap;
        .sizheng-item {
          margin-bottom: 25px;
          color: #bd0e10;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>