<template>
 <div class="resSearch-nodata-box">
    <div class="resSearch-inner">
      <div class="left-no-img">
        <img src="../assets/image/common/nodata.png" />
      </div>
      <div class="right-content">
        <div class="title">抱歉，没有找到与筛选条件相关的资源</div>
        <div class="brief">
          <span>建议您：</span><br/>
          1、适当减少筛选条件<br/>
          2、尝试其他关键字<br/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nodata",
  components: {
	},
  data() {
    return {
      }
  },
  created() {
   
  },
  mounted() {
    
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
// 搜索无数据样式
.resSearch-nodata-box {
  width: 100%;
  padding: 100px 0;
  .resSearch-inner {
    margin: 0 auto;
    display: flex;
    align-items: center;
    .left-no-img {
      flex: 40%;
      text-align: right;
      img {
        width: 170px;
        height: auto;
      }
    }
    .right-content {
      flex: 60%;
      margin-left: 30px;
      .title {
        font-weight: bold;
        color: #2F2F2F;
        font-size: 16px;
        margin: 20px 0 12px;
      }
      .brief {
        font-weight: 400;
        color: #7D7D7D;
        font-size: 12px;
        line-height: 22px;
        span {
          color: #2F2F2F;
          font-size: 14px;
        }
      }
    }
  }
}
</style>