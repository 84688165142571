<template>
  <div class="gjjs-search">
    <div class="search-info">
      按照 “<span>{{ searchVal }}</span
      >”的搜索到<span>{{ total }}</span
      >条结果
    </div>
    <div class="search-content">
      <div class="search-list">
        <div
          class="search-item"
          v-for="(item, ind) in searchList"
          :key="ind"
          @click="toDetail(item)"
        >
          <div class="item-image">
            <div :style="{backgroundImage: item.cover? `url(${item.cover})` : ''}"></div>
          </div>
          <div class="item-title" v-html="item.title"></div>
        </div>
      </div>
      <div v-if="pageNum > 1" class="pagination">
        <a-pagination
          v-model="page"
          :total="total"
          :page-size="16"
          @change="onPagesChange"
          size="small"
          :item-render="itemRender"
        />
      </div>
    </div>
    <div class="spin-con" v-if="loading">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import services from "../../../assets/js/services";
import utils from "../../../assets/js/utils";

export default {
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      searchList: [],
      searchVal: "",
      page: 1,
      pageNum: 0,
      total: 0,
      columnPath: [
        {
          title: "习说工匠 解读工匠",
          path: "jdgj",
        },
        {
          title: "中华智慧 匠心溯源",
          path: "jxsy",
        },
        {
          title: "继往开来 匠心闪耀",
          path: "jxshany",
        },
        {
          title: "匠苗培育 世赛青春",
          path: "ssqc",
        },
      ],
      loading: false,
    };
  },
  created() {
    if (this.$route.query.val) {
      this.searchVal = this.$route.query.val;
    }
    this.getSearchList();
  },
  methods: {
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    async getSearchList() {
      try {
        this.loading = true;
        let res = await services.getgjjsSearchList({
          page: this.page,
          length: 16,
          school_code: this.school_code,
          special_id: this.specialId,
          q: this.searchVal,
        });
        if (res.code == "0000" && res.data.items && res.data.items.length > 0) {
          this.searchList = res.data.items;
          this.pageNum = res.data.page_num;
          this.total = res.data.total;
        } else {
          this.searchList = [];
          this.pageNum = 0;
          this.total = 0;
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    onPagesChange(val) {
      this.page = val;
      this.getSearchList();
    },
    toDetail(item) {
      if (item.mark.type == 2) {
        let routeUrl = this.$router.resolve({
          path: `/${this.school_code}/gjjs/jxlhDetail`,
          query: {
            id: item.resource_id,
            columnId: item.column_id,
          },
        });
        window.open(routeUrl.href, utils.isMobile() ? "_self" : "_blank");
      } else {
        if (item.is_open_url == 2) {
          window.open(`${item.source_url}`, "_blank");
        } else {
          let ptahFrom = this.columnPath.find(
            (ele) => ele.title == item.mark.column_name
          );
          if (ptahFrom) {
          }
          let routeUrl = this.$router.resolve({
            path: `/${this.school_code}/gjjs/detail`,
            query: {
              id: item.resource_id,
              column_id: item.column_id,
              columnPath: ptahFrom.path,
            },
          });
          window.open(routeUrl.href, utils.isMobile() ? "_self" : "_blank");
        }
      }
    },
  },
  watch: {
    $route: {
      handler(newVal) {
        this.searchVal = newVal.query.val;
        this.getSearchList();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.gjjs-search {
  padding-top: 21px;
  background: linear-gradient(0deg, #ffffff 0%, #ffefd8 100%);
  .search-info {
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #b1000e;
  }
  .search-content {
    margin: 22px auto;
    width: 1200px;
    height: 951px;
    background: #ffffff;
    padding: 36px 38px 0;
    .search-list {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      height: 856px;
      .search-item {
        width: 266px;
        height: 192px;
        margin-left: 20px;
        margin-bottom: 22px;
        cursor: pointer;
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
        .item-image {
          width: 100%;
          height: 150px;
          overflow: hidden;
          // background: #b1000e;
          div {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            transition: all 0.3s;
            background-image: url("../../../assets/image/gjjs/index/nav-index/default-bg.png");
          }
          &:hover {
            div {
              transform: scale(1.1);
            }
          }
        }
        .item-title {
          width: 100%;
          height: 42px;
          background: #f9f7f4;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 42px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 14px;
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      ::v-deep.ant-pagination {
        .ant-pagination-prev,
        .ant-pagination-next {
          margin: 0 15px;
          a {
            color: #cb4d2e;
          }
        }
        .ant-pagination-item {
          margin: 0 5px;
          border-radius: 0;

          a {
            color: #cb4d2e;
          }
          &.ant-pagination-item-active {
            background-color: #cb4e2e;
            border: 1px solid transparent;
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .spin-con {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>