<template>
  <div class="outer-nores">
    <Header />
    <Navbar />
    <div class="inner-auth">
      <img class="images" src="../assets/image/common/noauth.png" />
      <p class="no-text">无权限访问，请联系管理员开通</p>
      <div class="bottom-box">
        <div class="back-btn home" @click="backHome">返回首页</div>
        <!-- <div class="back-btn prev" @click="backPrev">返回上级</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import { mapActions } from "vuex";
import services from "../assets/js/services";
import Header from '../components/Header.vue'
export default {
  name: "page404",
  components: {
    Navbar,
    Header,
  },
  data () {
    return {
      school_code: localStorage.getItem("schoolCode"),
      services,
    };
  },
  mounted () {
    
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    backHome(){
      this.$router.push({
        path: `/${this.school_code}/zy`,
      })
    },
    backPrev(){
      this.$router.go(-1)
    },
  },
};
</script>

<style lang="less" scoped>
.outer-nores {
  background-color: #fff;
  padding: 0 0;
}
.inner-auth {
  min-height: 700px;
  text-align: center;
  .images {
    width: 288px;
    height: auto;
    margin:  110px auto 40px;
  }
  .no-text {
    color: #434343;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .bottom-box {
    .back-btn {
      display: inline-block;
      width: 116px;
      height: 38px;
      line-height: 38px;
      background: #EAEAEA;
      border-radius: 20px;
      cursor: pointer;
      color: #535353;
      text-align: center;
      font-size: 16px;
      &.home {
        // margin-right: 60px;
      }
      &:hover {
        background-color: #EB452E;
        color: #fff;
      }
    }
  }
}
</style>