<template>
  <div class="bg-szzyk">
    <div class="headerber"></div>
    <div class="outer-list">
      <div class="inner-list-box">
        <div class="sider-bar">
          <div class="menu-subject-box  " :class="{isFixed: isFixed}">
            <div class="label-menu"></div>
            <div class="menu-outer">
              <div
                class="sub-menu"
                :class="{ active: categoryName == '' }"
                @click="selectMenu({ title: '' })"
              >
                全部
              </div>
              <div
                class="sub-menu"
                v-for="(item, ind) in menuList"
                :class="{ active: categoryName == item.title }"
                :key="ind"
                @click="selectMenu(item)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          
        </div>

        <div class="data-all-box">
          <div class="current-subject">
            <span class="lable1">{{ categoryName || "全部" }}</span>
            <!-- <span v-if="currentLevel2" class="lable2">
              - {{ currentLevel2.name }}</span
            > -->
          </div>
          <!-- 筛选项 -->
          <div class="screen-all-box">
            <div
              v-show="areaList.length > 0"
              :class="areaMore ? 'screen-lines' : 'screen-lines overflow'"
            >
              <div class="screen-label">地域特色：</div>
              <div ref="areaContent" class="screen-content">
                <div
                  class="items"
                  :class="{ active: areaId == '' }"
                  @click="selectTag('areaId', { id: '' })"
                >
                  全部
                </div>
                <div
                  class="items"
                  v-for="(item, ind) in areaList"
                  :class="{ active: areaId == item.id }"
                  :key="ind"
                  @click="selectTag('areaId', item)"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="screen-operation">
                <div
                  v-show="areaClientHei > 30"
                  @click="areaMore = !areaMore"
                  class="more-btn"
                >
                  更多
                </div>
              </div>
            </div>

            <div class="screen-lines" v-show="suggestList.length > 0">
              <div class="screen-label">应用建议：</div>
              <div ref="szysEle" class="screen-content">
                <div
                  class="items"
                  :class="{ active: suggestId == '' }"
                  @click="selectTag('suggestId', { id: '' })"
                >
                  全部
                </div>
                <div
                  class="items"
                  v-for="(item, ind) in suggestList"
                  :key="ind"
                  :class="{ active: suggestId == item.id }"
                  @click="selectTag('suggestId', item)"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="screen-operation"></div>
            </div>

            <div class="screen-lines" v-show="typeList.length > 0">
              <div class="screen-label">资源类型：</div>
              <div ref="szysEle" class="screen-content">
                <div
                  class="items"
                  :class="{ active: typeId == '' }"
                  @click="selectTag('typeId', { type: '' })"
                >
                  全部
                </div>
                <div
                  class="items"
                  v-for="(item, ind) in typeList"
                  :class="{ active: typeId == item.type }"
                  :key="ind"
                  @click="selectTag('typeId', item)"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="screen-operation"></div>
            </div>
          </div>

          <!-- 当前筛选条件 -->
          <!-- openKeys[0] != 'all' -->
          <div
            class="screen-current-box"
            v-show="typeId != '' || suggestId != '' || areaId != ''"
          >
            <div class="left-label">当前筛选条件</div>
            <div class="current-list">
              <div class="current-opt" v-if="areaId != ''">
                地域特色：{{ areaList.find((item) => item.id == areaId).name }}
                <div
                  class="clear-btn"
                  @click="selectTag('areaId', { id: '' })"
                ></div>
              </div>
              <div class="current-opt" v-if="suggestId != ''">
                应用建议：{{
                  suggestList.find((item) => item.id == suggestId).name
                }}
                <div
                  class="clear-btn"
                  @click="selectTag('suggestId', { id: '' })"
                ></div>
              </div>
              <div class="current-opt" v-if="typeId != ''">
                资源类型：{{
                  typeList.find((item) => item.type == typeId).name
                }}
                <div
                  class="clear-btn"
                  @click="selectTag('typeId', { type: '' })"
                ></div>
              </div>
            </div>
          </div>
          <!-- 搜索结果列表 -->
          <div class="model-szzyk-box">
            <div class="left-title-box">搜索结果</div>
            <div class="right-search-box">
              <div class="frame-input">
                <input
                  v-model="searchVal"
                  class="text"
                  placeholder="请输入检索词"
                  @keyup.enter="searchEvent"
                />
              </div>
              <div
                v-show="searchVal && searchVal.length > 0 && !isLoading"
                class="clear-input"
                @click="clearSearchVal"
              ></div>
              <div
                :class="['search-button', loading ? 'disabled' : '']"
                @click="searchEvent"
              >
                搜索
              </div>
            </div>
            <div
              @click="changeOrderName('new_quote_num')"
              :class="
                order_name == 'new_quote_num'
                  ? 'sort-middle-box active'
                  : 'sort-middle-box'
              "
            >
              使用热度
            </div>
            <div
              @click="changeOrderName('new_visit_num')"
              :class="
                order_name == 'new_visit_num'
                  ? 'sort-middle-box active'
                  : 'sort-middle-box'
              "
            >
              浏览次数
            </div>
            <div
              @click="changeOrderName('publish_time')"
              :class="
                order_name == 'publish_time'
                  ? 'sort-middle-box active'
                  : 'sort-middle-box'
              "
            >
              发布时间
            </div>
          </div>

          <template v-if="jxlhList.length > 0">
            <div class="resource-list">
              <div
                v-for="(item, index) in jxlhList"
                :key="index"
                class="resource-opt"
                @click="goDetails(item)"
              >
                <div
                  :class="[
                    item.type == 1 ? 'res-title word' : 'res-title video',
                    item.is_school_original == 1 ? 'ov87' : '',
                  ]"
                >
                  <span class="title-span ellipsis" v-html="item.title"></span>
                  <div class="badge" v-if="item.is_school_original == 1"></div>
                </div>
                <div class="res-all-box">
                  <div class="res-right-con">
                    <div class="brief" v-html="item.abstract"></div>
                    <div class="advise-box">
                      <div
                        v-if="
                          item.element_list_second &&
                          item.element_list_second.length > 0
                        "
                        class="advise-item"
                      >
                        元素要点：
                        <div
                          class="advise-item-span"
                          v-for="(sub, sinx) in item.element_list_second"
                          :key="sinx"
                        >
                          {{ sub.name }}
                        </div>
                      </div>
                      <div
                        v-if="item.suggest_list && item.suggest_list.length > 0"
                        class="advise-item"
                      >
                        应用建议：
                        <div
                          class="advise-item-span"
                          v-for="(sub, sinx) in item.suggest_list"
                          :key="sinx"
                        >
                          {{ sub.name }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="item.publish_time || item.source"
                      class="little-box"
                    >
                      <div class="left-tags">
                        <div v-if="item.publish_time" class="tag-opt timer">
                          时间：{{ item.publish_time }}
                        </div>
                        <div v-if="item.source" class="tag-opt author">
                          来自：{{ item.source }}
                        </div>
                        <div class="tag-opt computed">
                          <span v-if="item.new_visit_num" class="nums"
                            >{{ item.new_visit_num }}人浏览</span
                          >
                          <span v-if="item.new_favorite_num" class="nums"
                            >{{ item.new_favorite_num }}次收藏</span
                          >
                          <span v-if="item.new_quote_num" class="nums"
                            >{{ item.new_quote_num }}次使用</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="text-align: center">
              <a-pagination
                v-model="currentPage"
                :show-total="(total) => `共${total}条`"
                :total="total"
                @change="onPagesChange"
              />
            </div>
          </template>
          <template v-else>
            <nodata v-if="isInitData" />
            <div class="loading-data" v-else>加载中……</div>
          </template>
        </div>
      </div>
    </div>
    <component :is="dialogConfig.name"></component>
    <div class="spin-con" v-if="isLoading">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/Navbar.vue";
import Nodata from "../../../components/Nodata.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import utils from "../../../assets/js/utils";
import services from "../../../assets/js/services";
import Header from "../../../components/Header.vue";
import WhrjHeader from "../../../components/WhrjHeader.vue";
import appleRes from "../../../components/appleRes.vue";

export default {
  name: "jxlhList",
  components: {
    Navbar,
    Nodata,
    Header,
    appleRes,
    WhrjHeader,
  },
  data() {
    return {
      services,
      utils,
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      currentLevel1: null,
      areaMore: false, // 地域特色-更多按钮
      areaClientHei: 0, // 地域特色-内容高度
      currentPage: 1,
      total: 0,
      multipleType: "null",
      searchVal: "", // 搜索关键字
      isInitData: false,
      order_by: "desc", // desc 降序 asc 升序
      order_name: "new_quote_num", // 默认热度排序（new_quote_num） 发布时间publish_time|热度new_quote_num|游览次数new_visit_num
      loading: false, // 关键字搜索状态
      dialogConfig: {
        name: "",
      },
      menuList: [
        {
          title: "装备制造大类",
          categoryInd: 1,
        },
        {
          title: "交通运输大类",
          categoryInd: 2,
        },
        {
          title: "农林牧渔大类",
          categoryInd: 3,
        },
        {
          title: "能源动力与材料大类",
          categoryInd: 4,
        },
        {
          title: "土木建筑大类",
          categoryInd: 5,
        },
        {
          title: "轻工纺织大类",
          categoryInd: 6,
        },
      ],
      categoryName: "",
      areaList: [], //地域特色
      suggestList: [], // 应用建议
      typeList: [], // 资源类型
      areaId: "", //已选中地域特色
      suggestId: "", //已选中应用建议
      typeId: "", //已选中资源类型
      jxlhList: [],
      isLoading: false,
      isFixed: false,
      //   isUserLogin: false, //用户是否登录
    };
  },
  computed: {
    ...mapState({
      appsUserInfo: (state) => state.appsLogin.appsUserInfo,
    }),
  },
  watch: {
    appsUserInfo: function (newVal) {
      if (newVal && newVal.id) {
        this.isUserLogin = true;
      } else {
        this.isUserLogin = false;
      }
      // this.requestSzzykList(1)
    },
    areaMore: function () {
      this.$nextTick(function () {
        let areaRef = this.$refs["areaContent"];
        if (areaRef) {
          this.areaClientHei = areaRef.clientHeight;
        }
      });
    },
  },
  async created() {
    let res = await this.getAppsLoginUserInfo();
    if (res.code != "0000") {
      this.$message.info("请先登录！");
      this.$router.push({ path: `/${this.school_code}/gjjs/gjjsIndex` });
    }
    if (this.$route.query.ind) {
      this.categoryName = this.menuList.find(
        (item) => item.categoryInd == this.$route.query.ind
      ).title;
      // this.categoryName = obj.title
    }
    this.getjxlhTag();
    this.getjxlhList();
  },
  async mounted() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 476) {
        this.isFixed = true;
      }else {
        this.isFixed = false;

      }
    });
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin", "getAppsLoginUserInfo"]),
    // 获取筛选标签
    async getjxlhTag() {
      try {
        this.isLoading = true;
        let res = await services.getjxlhTag({
          school_code: this.school_code,
          special_id: this.specialId,
          column_name: this.categoryName,
        });
        if (res.code == "0000") {
          this.areaList = res.data.area_list;
          this.suggestList = res.data.suggest_list;
          this.typeList = res.data.type_list;
          this.refContentHeight();
        }
      } catch (err) {
      } finally {
        this.isLoading = false;
      }
    },
    //获取 列表
    async getjxlhList() {
      try {
        this.isLoading = true;
        window.scrollTo(0,0)
        let pageinfo = await services.getjxlhList({
          school_code: this.school_code,
          special_id: this.specialId,
          area_id: this.areaId,
          suggest: this.suggestId,
          type: this.typeId,
          page: this.currentPage,
          length: 10,
          count: 1,
          order_by: this.order_by,
          q: this.searchVal,
          order_name: this.order_name,
          column_name: this.categoryName,
        });
        if (pageinfo.code == "0000") {
          this.total = pageinfo.data.total;
        } else {
          this.total = 0;
        }
        let res = await services.getjxlhList({
          school_code: this.school_code,
          special_id: this.specialId,
          area_id: this.areaId,
          suggest: this.suggestId,
          type: this.typeId,
          page: this.currentPage,
          length: 10,
          order_by: this.order_by,
          q: this.searchVal,
          order_name: this.order_name,
          column_name: this.categoryName,
        });
        if (res.code == "0000" && res.data.items && res.data.items.length > 0) {
          this.jxlhList = res.data.items;
        } else {
          this.jxlhList = [];
          this.isInitData = true;
        }
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    //切换筛选标签
    selectTag(key, item) {
      if (item) {
        if (key == "typeId") {
          this[key] = item.type;
        } else {
          this[key] = item.id;
        }
        this.currentPage = 1;
        this.getjxlhList();
      }
    },
    // 切换筛选维度
    changeOrderName(name) {
      if (this.order_name == name) {
        return;
      } else {
        this.order_name = name;
        this.currentPage = 1;
      }
      this.getjxlhList();
    },
    // 思政资源库详情
    goDetails(item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        // if (item.is_open == 2) {
        let params = {
          id: item.id,
          columnId: item.column_id,
        };
        // if (this.currentLevel1 && this.currentLevel1.id) {
        //   params.subject_big_id = this.currentLevel1.id;
        // }
        // if (this.currentLevel2 && this.currentLevel2.id) {
        //   params.subject_small_id = this.currentLevel2.id;
        // }
        let routeUrl = this.$router.resolve({
          path: `/${this.school_code}/gjjs/jxlhDetail`,
          query: params,
        });
        window.open(routeUrl.href, utils.isMobile() ? "_self" : "_blank");
        // } else {
        //   // that.noAuthMessageModal()
        //   this.dialogConfig.name = "appleRes"
        // }
      });
    },
    // 切换菜单
    selectMenu(item) {
      if (item) {
        (this.areaId = ""), //已选中地域特色
          (this.suggestId = ""), //已选中应用建议
          (this.typeId = ""), //已选中资源类型
          // this.order_name= "new_quote_num"
          (this.categoryName = item.title);
        this.currentPage = 1;
        this.areaMore = false;
        this.getjxlhTag();
        this.getjxlhList();
      }
    },
    searchEvent() {
      if (this.searchVal.length > 0) {
        this.getjxlhList();
      }
    },
    clearSearchVal() {
      this.searchVal = "";
      this.getjxlhList();
    },
    // 获取内容改变后高度
    refContentHeight() {
      this.$nextTick(function () {
        let areaRef = this.$refs["areaContent"];
        if (areaRef) {
          this.areaClientHei = areaRef.clientHeight;
        }
      });
    },
    // 分页切换
    onPagesChange(pageNumber) {
      this.currentPage = pageNumber;
      this.getjxlhList();
    },
  },
};
</script>

<style lang="less" scoped>
.headerber {
  width: 100%;
  height: 400px;
  background-image: url("../../../assets/image/gjjs/jxlh/jxlh-banner.png");
  background-size: cover;
  background-position: center;
  // margin-bottom: 20px;
}
.spin-con {
  position: fixed;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-banner-box {
  width: 100%;
  height: 140px;
  background: url("../../../assets/image/szzyk/banner.png") no-repeat center
    center;
  background-size: cover;
  margin-bottom: 24px;
  position: relative;
  .szysk-button {
    height: 34px;
    display: inline-block;
    line-height: 34px;
    background: #a10a08;
    border-radius: 17px;
    padding: 0 17px;
    margin: 43px 0 0;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    float: right;
    cursor: pointer;
    &::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 12px;
      margin-right: 8px;
      background: url("../../../assets/image/szzyk/yskbtn.png") no-repeat center
        center;
      background-size: auto 100%;
    }
  }
}
.outer-list {
  padding-bottom: 60px;
  width: 1240px;
  margin: 0 auto;
}
.inner-list-box {
  width: 100%;
  display: flex;
}
.sider-bar {
  width: 240px;
  position: relative;
  margin-right: 58px;
.menu-subject-box {
  vertical-align: top;
  display: inline-block;
  margin-top: 34px;
  width: 240px;
  min-height: 500px;
  
  border-radius: 2px;
  &.isFixed {
    position: fixed;
    top: 34px;
  }
  .label-menu {
    width: 100%;
    text-align: center;
    height: 50px;
    background-image: url("../../../assets/image/gjjs/jxlh/subject-tit.png");
    // padding: 20px 0 15px;
  }
  .menu-outer {
    padding: 10px 0;
    background: #f9f9f9;

    .sub-menu {
      width: 100%;
      position: relative;
      background: #f9f9f9;
      font-size: 17px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #272727;
      line-height: 20px;
      padding-left: 13px;
      // padding-right: 16px;
      line-height: 45px;
      width: 240px;
      height: 46px;
      cursor: pointer;
      border-bottom: 1px solid #efefef;
      &:last-child {
        border: 0;
      }
      &.active {
        background: #efefef;
        color: #bb0007;
        &::after {
          width: 8px;
          height: 5px;
          background-image: url("../../../assets/image/gjjs/jxlh/menu-icon-active.png");
          // transform: rotate(180deg);
        }
      }
    }
  }
}
}

.data-all-box {
  width: 942px;
  min-height: 1000px;
  display: inline-block;
  border-radius: 2px;
  padding: 0 0 35px;
  position: relative;
  // overflow: hidden;

  .current-subject {
    padding: 0 45px;
    height: 57px;
    line-height: 57px;
    border-bottom: 2px solid #f5f7fa;
    position: relative;
    .add-res {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      background: #e64757;
      width: 85px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      cursor: pointer;
      color: #fff;
      border-radius: 14px;
      font-size: 14px;
    }
    &::before {
      content: "";
      margin-right: 16px;
      display: inline-block;
      width: 5px;
      height: 18px;
      background: #dd363a;
      border-radius: 0px 3px 3px 0px;
      vertical-align: -2px;
    }
    .lable1 {
      font-size: 18px;
      font-weight: bold;
      color: #2a2a2a;
    }
    .lable2 {
      font-size: 18px;
      color: #2a2a2a;
    }
  }
  .screen-all-box {
    padding: 15px 30px 0px 45px;
    .screen-lines {
      // display: flex;
      padding: 0 0 10px;
      // justify-content: space-between;
      height: auto;

      .understand {
        display: inline-block;
        font-size: 13px;
        padding: 5px 0 0 15px;
        color: #004ce2;
        text-decoration: underline;
      }
      &.overflow {
        height: 36px;
        overflow: hidden;
        &.zsd {
          height: 86px;
        }
      }
      .screen-label {
        display: inline-block;
        vertical-align: top;
        // font-weight: bold;
        // color: #11233F;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #11233f;
        // line-height: 13px;
        padding: 4px 0 0;
        width: 70px;
        text-align: right;
      }
      .screen-content {
        // flex: 1;
        display: inline-block;
        width: 690px;
        margin-right: 10px;
        .items {
          cursor: pointer;
          display: inline-block;
          font-weight: 400;
          color: #676f7f;
          font-size: 13px;
          padding: 5px 15px;
          &.active,
          &:hover {
            color: #ee373d;
            font-weight: bold;
          }
        }
      }
      .screen-operation {
        float: right;
        text-align: right;
        padding: 4px 0 0;
        cursor: pointer;
        .select-btn {
          display: inline-block;
          font-size: 12px;
          color: #474747;
          vertical-align: 2px;
          &:hover {
            color: #ee373d;
          }
          &::after {
            content: "";
            display: inline-block;
            width: 12px;
            height: 11px;
            background: url("../../../assets/image/szzyk/plus.png") no-repeat
              center;
            background-size: 100% auto;
            margin-left: 6px;
          }
        }
        .more-btn {
          display: inline-block;
          font-size: 12px;
          color: #474747;
          float: right;
          margin-left: 12px;
          &:hover {
            color: #ee373d;
          }
          &::after {
            content: "";
            display: inline-block;
            width: 7px;
            height: 11px;
            background: url("../../../assets/image/szzyk/more-icon.png")
              no-repeat center;
            background-size: 100% auto;
            margin-left: 6px;
          }
        }
      }
    }
  }
  .select-all-box {
    padding: 10px 45px 20px;
    .first-lines {
      .line-label {
        color: #11233f;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
      }
      .line-words {
        display: inline-block;
        .word-item {
          display: inline-block;
          font-size: 13px;
          color: #676f7f;
          margin: 0 20px;
          cursor: pointer;
          &.active,
          &:hover {
            color: #ee373d;
          }
        }
      }
      .line-button {
        float: right;
        display: inline-block;
        .btn {
          width: 48px;
          height: 26px;
          border-radius: 15px;
          text-align: center;
          font-size: 12px;
          background: #eff1f4;
          line-height: 26px;
          display: inline-block;
          margin-left: 10px;
          color: #dd363a;
          cursor: pointer;
          &:hover {
            background: linear-gradient(162deg, #d8292d 0%, #d43d29 100%);
            color: #fff;
          }
        }
      }
    }
    .select-groups {
      padding: 20px 0;
      .select-items {
        cursor: pointer;
        display: inline-block;
        font-weight: 400;
        color: #676f7f;
        font-size: 13px;
        padding: 5px 30px 5px 0;
        &.active,
        &:hover {
          color: #ee373d;
          font-weight: bold;
        }
      }
    }
  }
  .screen-current-box {
    padding: 15px 45px 10px;
    background: linear-gradient(93deg, #fffbf0 0%, #fefaf0 100%);
    display: flex;
    .left-label {
      font-size: 14px;
      font-weight: bold;
      color: #da5b2f;
      margin-right: 15px;
      padding-top: 6px;
    }
    .current-list {
      flex: 1;
      .current-opt {
        height: 30px;
        line-height: 30px;
        background: #ffffff;
        padding: 0 15px;
        border: 1px solid #eaeaea;
        box-shadow: 0px 0px 6px 0px rgba(130, 130, 130, 0.14);
        border-radius: 15px;
        color: #676f7f;
        font-size: 13px;
        display: inline-block;
        margin-right: 12px;
        margin-bottom: 10px;
        .current-text {
          display: inline-block;
          max-width: 600px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: top;
        }
        .clear-btn {
          cursor: pointer;
          display: inline-block;
          width: 8px;
          height: 8px;
          background: url("../../../assets/image/szzyk/close.png") no-repeat
            center;
          background-size: 8px auto;
          margin-left: 10px;
          vertical-align: 1px;
        }
      }
    }
  }
  .model-szzyk-box {
    padding: 15px 30px 14px 45px;
    .left-title-box {
      font-size: 18px;
      font-weight: bold;
      color: #cf0d04;
      display: inline-block;
      &::before {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url("../../../assets/image/home/label-star.png") no-repeat
          center;
        background-size: 100% auto;
        margin-right: 15px;
        vertical-align: -3px;
      }
    }
    .sort-middle-box {
      margin-left: 35px;
      color: #acafb6;
      font-size: 14px;
      font-weight: bold;
      line-height: 34px;
      height: 34px;
      display: inline-block;
      cursor: pointer;
      &::after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 10px;
        margin-left: 5px;
      }
      &::after {
        content: "";
        background: url("../../../assets/image/szzyk/sort.png") no-repeat center;
        background-size: 100% auto;
      }
      &.active {
        color: #f47874;
      }
      &.active::after {
        content: "";
        background: url("../../../assets/image/szzyk/sort-hover.png") no-repeat
          center;
        background-size: 100% auto;
      }
    }
    .right-search-box {
      float: right;
      width: 260px;
      height: 34px;
      background: #ffffff;
      border: 1px solid #d8d1d2;
      display: flex;
      align-items: center;
      .frame-input {
        flex: 1;
        input {
          font-size: 14px;
          margin-left: 15px;
          border: none;
          outline: none;
          height: 28px;
          line-height: 28px;
          &:focus-visible {
            border: none;
            outline: none;
          }
        }
      }
      .clear-input {
        width: 30px;
        height: 30px;
        background: url("../../../assets/image/common/clear.png") no-repeat
          center center;
        background-size: 15px auto;
        cursor: pointer;
      }
      .search-button {
        width: 60px;
        height: 34px;
        line-height: 34px;
        background: #bb0007;
        text-align: center;
        cursor: pointer;
        font-size: 13px;
        color: #f5f7fa;
      }
      .search-button.disabled {
        pointer-events: none;
      }
    }
  }
  .resource-list {
    width: 100%;
    padding: 0 30px 20px 45px;
    min-height: 400px;
    display: block;
    position: relative;
    .resource-opt {
      cursor: pointer;
      width: 100%;
      border-bottom: 1px solid #f3f3f3;
      padding: 18px 0 18px 0;
      position: relative;
      .res-title {
        font-size: 16px;
        color: #191919;
        font-weight: bold;
        width: 100%;
        margin-bottom: 8px;
        padding: 0 14px 0 9px;
        .title-span {
          max-width: 90%;
          display: inline-block;
          vertical-align: middle;
        }
        &.ov87 {
          .title-span {
            max-width: 87%;
          }
        }
        .badge {
          width: 65px;
          height: 20px;
          background-image: url("../../../assets/image/szzyk/school-badge.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          display: inline-block;
          vertical-align: middle;
          margin-left: 12px;
        }
        .resopen {
          font-size: 12px;
          color: #ffffff;
          background: #ee373d;
          padding: 2px 6px;
          border-radius: 6px;
        }
        &::before {
          content: "";
          display: inline-block;
          margin-right: 6px;
          width: 25px;
          height: 23px;
          vertical-align: -4px;
        }
        &.word::before {
          background: url("../../../assets/image/home/word.png") no-repeat
            center;
          background-size: 100% auto;
        }
        &.video::before {
          background: url("../../../assets/image/home/MP4.png") no-repeat center;
          background-size: 100% auto;
        }
      }
      .res-all-box {
        width: 100%;
        display: flex;
        .res-left-img {
          width: 122px;
          height: 69px;
          border-radius: 2px;
          background-size: 100% auto;
          background-position: center center;
          background-repeat: no-repeat;
          margin-right: 20px;
        }
        .res-right-con {
          width: 100%;
        }
        .brief {
          width: 100%;
          max-height: 40px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-size: 13px;
          color: #959494;
          line-height: 20px;
          margin-bottom: 7px;
          padding: 0 0 0 9px;
        }
        .advise-box {
          display: flex;
          margin-bottom: 5px;
          //   margin: 0 9px 5px 14px;
          padding: 0 14px 0 9px;
          .advise-item {
            color: #9b804d;
            font-size: 12px;
            display: flex;
            min-width: 200px;
            margin-right: 15px;
            align-items: center;
            .advise-item-span {
              padding: 6px 10px;
              line-height: 12px;
              margin: 0 5px;
              background: #fffaee;
            }
            .pointContent {
              font-size: 12px;
              color: #b75031;
              max-width: 180px;
              overflow: hidden;
              // text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .more {
            display: inline-block;
            position: relative;

            .more-text {
              display: inline-block;
              margin-left: 10px;
              width: 10px;
              height: 10px;
              background: url("../../../assets/image/szzyk/more.png");
              background-size: cover;
              position: relative;
              span {
                position: absolute;
                letter-spacing: -1px;
                top: 38%;
                left: 48%;
                transform: translate(-50%, -50%);
              }
            }
            .more-group {
              opacity: 0;
              padding: 8px 3px 3px;
              position: absolute;
              bottom: 25px;
              left: 50%;
              transform: translateX(-50%);
              background: #fff;
              transition: all 0.5s;
              // width: 300px;
              max-width: 400px;
              width: max-content;
              box-shadow: 0px 0px 8px 0px rgba(103, 106, 110, 0.3);
              border-radius: 4px;
              z-index: -1;
              &::before {
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                border-top: 8px solid #ffffff;
                border-right: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 8px solid transparent;
                position: absolute;
                left: 50%;
                bottom: -16px;
                transform: translate(-50%, 0);
              }
              .more-item {
                margin: 0 5px 5px;
                padding: 4px 8px;
                background: #f8f9fb;
                color: #666666;
                font-size: 12px;
                display: inline-block;
              }
            }
            &:hover .more-group {
              opacity: 1;
              z-index: 1;
            }
          }
        }
        .little-box {
          flex: 1;
          display: flex;
          justify-content: space-between;
          height: 24px;
          align-items: center;
          width: 100%;
        }
        .left-tags {
          flex: 1;
          display: flex;
          justify-content: space-between;
          padding: 0 0 0 9px;
          .tag-opt {
            color: #aaacb0;
            font-size: 12px;
            display: inline-block;
            min-width: 200px;
            // &.timer, &.author {
            //   min-width: 140px;
            // }
            &.timer::before {
              content: "";
              display: inline-block;
              margin-right: 10px;
              width: 10px;
              height: 10px;
              background: url("../../../assets/image/szzyk/timer.png") no-repeat
                center;
              background-size: 100% auto;
            }
            &.author::before {
              content: "";
              display: inline-block;
              margin-right: 10px;
              width: 10px;
              height: 10px;
              background: url("../../../assets/image/szzyk/resource.png")
                no-repeat center;
              background-size: 100% auto;
            }
            &.computed {
              font-size: 12px;
              text-align: end;
              .nums {
                display: inline-block;
                &::after {
                  content: "";
                  display: inline-block;
                  width: 1px;
                  height: 10px;
                  background: #e8e8e8;
                  border: 0px solid #151431;
                  margin: 0 10px;
                }
                &:last-child::after {
                  display: none;
                }
              }
            }
          }
        }
        .right-operat-box {
          display: none;
          width: 120px;
          text-align: right;
          .operat-item {
            width: 82px;
            text-align: center;
            height: 24px;
            line-height: 24px;
            background: #ffffff;
            border: 1px solid #dd363a;
            border-radius: 12px;
            color: #df4332;
            display: inline-block;
            cursor: pointer;
            &:hover {
              border: 1px solid transparent;
              background: linear-gradient(162deg, #dd363a 0%, #df4531 100%);
              color: #ffffff;
            }
            &.star {
              &::before {
                content: "";
                display: inline-block;
                width: 18px;
                height: 18px;
                margin-right: 4px;
                background: url("../../../assets/image/home/star.png") no-repeat
                  center;
                background-size: 100% auto;
                vertical-align: -3px;
              }
              &:hover::before {
                background: url("../../../assets/image/home/star-active.png")
                  no-repeat center;
                background-size: 100% auto;
              }
            }
            &.active.star {
              width: 112px;
              &::before {
                background: url("../../../assets/image/home/star-active.png")
                  no-repeat center;
                background-size: 100% auto;
              }
            }
          }
        }
      }
      &:hover {
        background: linear-gradient(0deg, #ffffff 0%, #f4f4f4 100%);
        border-radius: 2px;
        border-bottom: 1px solid #fff;
      }
      &:hover .right-operat-box {
        display: block;
      }
    }
  }
  .loading-data {
    width: 100%;
    height: 100%;
    .loading-mask {
      position: absolute;
      top: 250px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f3f3f3;
      opacity: 0.5;
    }
    .loading-text {
      position: absolute;
      top: 350px;
      color: #000;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.red-color {
  color: #df4332;
}
.loading-data {
  font-size: 13px;
  color: #a3a5aa;
  text-align: center;
  padding-top: 100px;
}
.empty {
  width: 14px;
  opacity: 0;
  display: inline-block;
}
</style>