<template>
  <div>
    <WhrjHeader />
    <div class="sdcm-container-box">
      <div class="content-box w1280">
        <div class="left-sidebar"></div>
        <div class="right-content">
          <template>
            <template v-if="listData&&listData.length>0">
              <template v-for="(item,index) in listData">
                <div class="item" :key="index" v-if="index<1" @click="goDetail(item,'dxjy')">
                  <p>{{item.title}}</p>
                  <span>{{item.issueTime}}</span>
                </div>
              </template>
              <template v-for="(item,index) in listData">
                <div class="item" :key="index" v-if="index>=1" @click="goDetail(item,'dxjy')">
                  <div class="left-date">
                    <div class="date-year-month">{{item.issueTime.substring(5,10)}}</div>
                    <div class="date-day">{{item.issueTime.substring(0,4)}}</div>
                  </div>
                  <div class="middle-cover" v-if="item.thumb_image" :style="`background-image:url(${item.thumb_image})`"></div>
                  <div class="right-desc">
                    <div class="title">{{item.title}}</div>
                    <div class="intro" v-html="item.description"></div>
                  </div>
                </div>

              </template>
              <div style="text-align: center; padding-top:20px">
                <a-pagination v-model="realyPage" :show-total="total => `共${allPages}页`" :total="total" @change="onPagesChange" />
              </div>
            </template>
            <div v-else class="no-content">
              <div>待提供内容</div>
            </div>
          </template>

        </div>
      </div>
    </div>
    <WhrjFooter :color="'#3E140B'" />
  </div>
</template>

<script>
import WhrjHeader from "../../components/WhrjHeader"
import WhrjFooter from "../../components/WhrjFooter"
import services from '../../assets/js/services'

export default {
  data () {
    return {
      school_code: localStorage.getItem("schoolCode"),
      listData: [],
      allPages: 0, //总页数
      page: 0,
      size: 10,
      total: 0,
      realyPage: 1,
    }
  },
  components: {
    WhrjHeader,
    WhrjFooter
  },
  mounted () {
    this.getList()
  },
  methods: {
    goDetail (item, flag) {
      // this.$router.push({ path: `/${this.school_code}/sdcmzyxy/detail/${item.id}`, query: { flag, url: item.url } })
      window.open(`/${this.school_code}/whrjgczyxy/detail/${item.id}?flag=${flag}&url=${item.url}`)

    },
    getList () {
      this.listData = []
      var time = Date.parse(new Date());

      services.getDxjyList({ page: this.page, time }).then(res => {
        this.listData = res.info
        this.total = res.pageNum * 10
        this.allPages = res.pageNum
      })
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    onPagesChange (val) {
      this.realyPage = val
      this.page = val - 1
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
* {
  transition: all 0.3s;
}
.w1280 {
  width: 1280px;
  margin: 0 auto;
}
.sdcm-container-box {
  background-image: url('../../assets/image/sdcmzyxy/zcwj-body.png');
  background-size: cover;
  padding: 22px 0 44px;

  .content-box {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 0 39px 0 43px;
    position: relative;
    min-height: 700px;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 203px;
      left: 0;
      background-size: cover;
      width: 637px;
      height: 563px;
      background-image: url('../../assets/image/sdcmzyxy/zcwj-bg.png');
      z-index: 1;
    }
  }
  .left-sidebar {
    width: 264px;
    height: 125px;
    background-image: url('../../assets/image/sdcmzyxy/slidebar/dxjy.png');
    background-size: cover;
  }
  .right-content {
    z-index: 10;
    flex: 1;
    padding: 22px 0 0 80px;
    .item {
      cursor: pointer;
      &:hover {
        .right-desc {
          .title {
            color: #931a14;
          }
        }
      }
      &:first-child {
        margin: 0;
        padding: 0;
        border: none;
        width: 853px;
        height: 93px;
        background: #f4f4f4;
        padding: 32px 25px 32px 27px;
        display: flex;
        justify-content: space-between;
        p {
          cursor: pointer;
          font-size: 22px;
          font-weight: bold;
          color: #840000;
          width: 670px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        span {
          font-size: 14px;
          color: #acacac;
          margin-top: 10px;
        }
      }

      padding: 28px 0;
      border-bottom: 1px solid #f4f4f4;
      display: flex;
      .left-date {
        margin-right: 30px;
        .date-year-month {
          width: 70px;
          height: 24px;
          background: #9b2020;
          line-height: 24px;
          text-align: center;
          font-size: 16px;
          color: #ffffff;
        }
        .date-day {
          width: 70px;
          height: 30px;
          background: #fff6f6;
          line-height: 30px;
          text-align: center;
          font-size: 16px;
          color: #840000;
        }
      }
      .middle-cover {
        background-size: cover !important;
        width: 200px;
        height: 113px;
        border-radius: 2px;
        background-position: center;
        margin-right: 14px;
        &::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: rgba(14, 14, 14, 8%);
        }
      }
      .right-desc {
        flex: 1;
        width: 0;
        display: flex;
        flex-direction: column;
        margin-top: -3px;
        .title {
          font-size: 18px;
          color: #262626;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 20px;
        }
        .intro {
          font-size: 14px;
          color: #acacac;
          ::v-deep p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
}
.no-content {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 500px;
  font-size: 14px;
  color: #a2a2a2;
  transition: all 0s;
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
  }
}
</style>