<template>
  <div>
    <WhrjHeader :active="'/'+school_code+'/whrjgczyxy/sztd'" />
    <div class="sdcm-container-box">
      <div class="content-box w1280">
        <div class="header-box"></div>
        <div class="body-box">
          <div class="main-content" v-if="detailData">
            <div class="left-picture" :style="`background-image:url(${detailData.thumb_image})`"></div>
            <div class="right-content">
              <div class="main-info">
                <div class="info">
                  <div class="title">{{detailData.name}}</div>
                  <div class="tag-group">
                    <div class="item" v-for="item,index in detailData.position" :key="index">{{item}}</div>
                  </div>
                </div>
                <router-link class="btn-return" :to="'/'+school_code+'/whrjgczyxy/sztd'" v-text="'< 返回师资团队'"></router-link>
              </div>
              <div class="split"></div>
              <div class="details">
                <div class="item" v-for="item,index in detailData.details" :key="index">{{item}}</div>
              </div>
            </div>
          </div>
          <div class="swiper-box">

            <div class="swiper-inner">
              <swiper ref="mySwiper" v-if="teachGroup" class="eol-swiper-box" :options="swiperOption">
                <swiper-slide v-for="(item,index) in teachGroup" :key="index">
                  <div class="eol-banner-item" :style='`background-image: url(${item.thumb_image});background-size: 100% 100%;`' @click="goDetail(item)"></div>
                  <div class="name">【{{ item.name}}】</div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
    <WhrjFooter :color="'#3e140b'" />
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import WhrjHeader from "../../components/WhrjHeader"
import WhrjFooter from "../../components/WhrjFooter"
import sdcmData from "./sdcmData"
export default {
  data () {
    return {
      teachGroup: sdcmData.teachGroup,
      detailData: {},
      swiperOption: {
        // loop: true,
        slidesPerView: 8,
        spaceBetween: 36,
        autoplay: {
          pauseOnMouseEnter: true,
        },
        speed: 400,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
      },
      school_code: localStorage.getItem("schoolCode"),
    }
  },
  components: {
    swiper,
    swiperSlide,
    WhrjHeader,
    WhrjFooter
  },
  mounted () {
    this.searchData()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  methods: {
    searchData () {
      let id = this.$route.params.id
      this.teachGroup.forEach(item => {
        if (item.id == id) {
          this.detailData = item
        }
      })
    },
    goDetail (item) {
      window.open(`/${this.school_code}/whrjgczyxy/sztd/detail/${item.id}`, "_self")
    }
  },
  computed: {
    mySwiper () {
      return this.$refs.mySwiper && this.$refs.mySwiper.swiper;
    },
  }

}
</script>

<style lang="less" scoped>
* {
  transition: all 0.3s;
}
.w1280 {
  width: 1280px;
  margin: 0 auto;
}
.sdcm-container-box {
  background-image: url('../../assets/image/sdcmzyxy/zcwj-body.png');
  background-size: cover;
  padding: 22px 0 0;
  .content-box {
    background-color: #fff;
    padding: 14px 40px 32px;
    .header-box {
      width: 259px;
      height: 51px;
      background-image: url('../../assets/image/sdcmzyxy/teach-group.png');
      background-size: cover;
      margin: 0 auto 20px auto;
    }
    .body-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .main-content {
        display: flex;
        position: relative;
        width: 100%;
        padding-bottom: 80px;
        &::before {
          content: '';
          width: 183px;
          height: 257px;
          background: #b44536;
          position: absolute;
          left: -8px;
          top: -8px;
          z-index: 1;
        }
        .left-picture {
          width: 216px;
          height: 288px;
          background-size: cover;
          z-index: 10;
        }
        .right-content {
          flex: 1;
          margin-left: 50px;
          .main-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .info {
              .title {
                font-size: 22px;
                font-weight: bold;
                color: #262626;
                &::after {
                  content: 'Famous teacher';
                  font-size: 18px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #f6f6f6;
                  margin-left: 18px;
                }
              }
              .tag-group {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 10px;
                .item {
                  padding: 0 8px;
                  height: 24px;
                  line-height: 24px;
                  background: #ffffff;
                  border: 1px solid #f4d088;
                  border-radius: 2px;
                  font-size: 14px;
                  color: #ff870f;
                  margin-right: 8px;
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
            .btn-return {
              cursor: pointer;
              width: 136px;
              height: 28px;
              text-align: center;
              line-height: 28px;
              background: linear-gradient(90deg, #fdeac6 0%, #fffaf1 100%);
              border-radius: 14px 0px 0px 14px;
              font-size: 14px;
              color: #9b2020;
            }
          }
          .split {
            width: 30px;
            height: 6px;
            background: #b44536;
            border-radius: 0px 3px 3px 0px;
            margin: 16px 0 21px;
          }
          .details {
            .item {
              font-size: 16px;
              color: #262626;
              line-height: 28px;
              margin-bottom: 35px;
              &:last-child {
                margin-bottom: 0;
              }
              &::before {
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                background: #ffffff;
                border: 2px solid #c5c5c5;
                margin-right: 7px;
                transform: translateY(-1px);
              }
            }
          }
        }
      }
      .swiper-box {
        position: relative;
        width: 1200px;
        height: 202px;
        margin: 0 auto;
        background: #931a14;
        padding: 21px 0 0;

        .swiper-inner {
          width: 1042px;
          overflow: hidden;
          margin: 0 auto;
          height: 166px;
          .eol-swiper-box {
            position: unset;
            .eol-banner-item {
              width: 99px;
              height: 133px;
            }
            .name {
              width: 99px;
              font-size: 14px;
              color: #ebdddd;
              text-align: center;
              margin-top: 12px;
            }
          }
        }
        ::v-deep.swiper-button-prev {
          left: 30px;
          width: 8px;
          height: 16px;
          background-size: cover;
          background-image: url('../../assets/image/sdcmzyxy/prev.png');
          &:hover {
            background-image: url('../../assets/image/sdcmzyxy/prev-hover.png');
          }
        }
        ::v-deep .swiper-button-next {
          right: 30px;
          width: 8px;
          height: 16px;
          background-size: cover;
          background-image: url('../../assets/image/sdcmzyxy/next.png');
          &:hover {
            background-image: url('../../assets/image/sdcmzyxy/next-hover.png');
          }
        }
      }
    }
  }
}
</style>