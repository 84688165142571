<template>
  <div class="jxshany-list">
    <div class="jxsy-tit"></div>
    <div class="jxshany-nav">
      <div
        class="nav-item"
        v-for="(item, ind) in navList"
        :key="ind"
        :class="{ active: navName == item.title }"
        @click="changeNav(item.title)"
      >
        <div class="title">{{ item.title}}</div>
        <div class="sub-text">
          <span v-for="(text, index) in item.subText" :key="index">{{
            text
          }}</span>
        </div>
      </div>
    </div>
    <div class="jxshany-con">
      <div class="jxshany-column" v-if="jxshanyList.length > 0">
        <div
          class="column-item"
          v-for="(item, ind) in jxshanyList"
          :key="ind"
          @click="toDetail(item)"
        >
          <div :style="{backgroundImage: item.cover? ` url(${item.cover})` : ''}"></div>
        </div>
      </div>
      <div v-if="pageNum > 1" class="pagination">
        <a-pagination
          v-model="page"
          :total="total"
          :page-size="16"
          @change="onPagesChange"
          size="small"
          :item-render="itemRender"
        />
      </div>

      <div v-if="jxshanyList.length <= 0 && !loading" class="noData-box">
        <img src="../../../assets/image/jsckzyxy/empty.png" alt="" />
        <div class="text">暂无相关内容</div>
      </div>
    </div>
    <div class="spin-con" v-if="loading">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import services from "../../../assets/js/services.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      navList: [
        {
          title: "匠于心 匠心至诚",
          subText: ["敢为人先", "匠心报国", "攻坚克难", "匠心独造"],
        },
        {
          title: "精于工 匠术至纯",
          subText: ["精益求精", "匠艺至极", "一丝不苟", "匠无止境"],
        },
        {
          title: "品于行 匠道至善",
          subText: ["抱诚守真", "矢志不渝", "乐业敬业", "坚守平凡"],
        },
        {
          title: "业于新 匠魂至勇",
          subText: ["追求卓越", "匠行无惧", "守正创新", "匠造无垠"],
        },
      ],
      navName: "继往开来 匠心闪耀",
      jxshanyList: [],
      page: 1,
      total: 0,
      pageNum: 0,
      loading: true,
    };
  },
 async created() {
    let res = await this.getAppsLoginUserInfo();
    if (res.code != "0000") {
      this.$message.info("请先登录！");
      this.$router.push({ path: `/${this.school_code}/gjjs/gjjsIndex` });
    }
    if (this.$route.query.ind > -1) {
      this.navName = this.navList[this.$route.query.ind].title;
    }
    this.getjxshanyList();
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin","getAppsLoginUserInfo"]),
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    async getjxshanyList() {
      try {
        this.loading = true;

        let res = await services.getAreaSzztkLists({
          page: this.page,
          length: 16,
          count: 0,
          school_code: this.school_code,
          special_id: this.specialId,
          kind: "1",
          column_name: this.navName,
          is_sub_category: 1
        });
        if (res.code == "0000" && res.data.items && res.data.items.length > 0) {
          this.jxshanyList = res.data.items;
          this.pageNum = res.data.page_num;
          this.total = res.data.total;
        } else {
          this.jxshanyList = [];
          this.pageNum = 0;
          this.total = 0;
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    changeNav(title) {
      this.navName = title;
      this.page = 1;
      this.pageNum = 0;
      this.getjxshanyList();
    },
    onPagesChange(val) {
      this.page = val
      this.getjxshanyList();
    },
    // changePage(ind) {
    //   if (ind == this.page || ind <= 0 || ind > this.pageNum) return;
    //   this.page = ind;
    //   this.getjxshanyList();
    // },
    toDetail(item) {
      if (item) {
        this.goAppsDetailsBeforeLogin().then(() => {
          this.$router.push({
            path: `/${this.school_code}/gjjs/detail`,
            query: {
              id: item.id,
              column_id: item.column_id,
              columnPath: "jxshany",
            },
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.jxshany-list {
  width: 100%;
  padding-top: 72px;
  padding-bottom: 40px;
  // min-width: 1920px;
  // height: 1096px;
  background-repeat: no-repeat;
  background-image: url("../../../assets/image/gjjs/jxshany/jxshany-bg.png");
  background-size: cover;
  // background-position-y: -76px;
  .jxsy-tit {
    width: 700px;
    height: 109px;
    background-image: url("../../../assets/image/gjjs/jxshany/jxshany-tit.png");
    background-size: cover;
    margin: 0 auto;
  }
  .jxshany-nav {
    width: 1244px;
    height: 82px;
    display: flex;
    margin: 40px auto 0;
    .nav-item {
      width: 300px;
      height: 100%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      justify-content: center;
      .title {
        text-align: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffe6ad;
        line-height: 18px;
      }
      .sub-text {
        text-align: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #cb4d2e;
        line-height: 12px;
        margin-top: 13px;
        display: none;
        span {
          margin: 0 5px;
        }
      }
      &:nth-child(1) {
        width: 322px;
        height: 100%;
        background-image: url("../../../assets/image/gjjs/jxshany/jxshany-nav1.png");
        background-size: cover;
        &.active {
          background-image: url("../../../assets/image/gjjs/jxshany/jxshany-nav1-hover.png");
          .title {
            color: #9c0000;
          }
          .sub-text {
            display: block;
          }
        }

        .sub-text {
          margin-left: 20px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #cb4d2e;
        }
      }
      &:nth-child(2) {
        width: 300px;
        height: 100%;
        background-image: url("../../../assets/image/gjjs/jxshany/jxshany-nav2.png");
        background-size: cover;
        &.active {
          background-image: url("../../../assets/image/gjjs/jxshany/jxshany-nav2-hover.png");
          .title {
            color: #9c0000;
          }
          .sub-text {
            display: block;
          }
        }
      }
      &:nth-child(3) {
        width: 300px;
        height: 100%;
        background-image: url("../../../assets/image/gjjs/jxshany/jxshany-nav3.png");
        background-size: cover;
        &.active {
          background-image: url("../../../assets/image/gjjs/jxshany/jxshany-nav3-hover.png");
          .title {
            color: #9c0000;
          }
          .sub-text {
            display: block;
          }
        }
      }
      &:nth-child(4) {
        width: 322px;
        height: 100%;
        background-image: url("../../../assets/image/gjjs/jxshany/jxshany-nav4.png");
        background-size: cover;
        &.active {
          background-image: url("../../../assets/image/gjjs/jxshany/jxshany-nav4-hover.png");
          .title {
            color: #9c0000;
          }
          .sub-text {
            display: block;
          }
        }
      }
    }
  }
  .jxshany-con {
    width: 1200px;
    height: 752px;
    margin: 0 auto;
    background-image: url("../../../assets/image/gjjs/jxshany/jxshany-con-bg.png");
    background-size: cover;
    background-position-y: -16px;
    padding: 36px 38px 0;
    .jxshany-column {
      width: 100%;
      height: 672px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .column-item {
        margin-left: 20px;
        width: 266px;
        height: 150px;
        background-color: #9c0000;
        margin-bottom: 18px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        overflow: hidden;
        cursor: pointer;
        div {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          transition: all 0.3s;
          background-image: url("../../../assets/image/gjjs/index/nav-index/default-bg.png");
        }
        &:hover {
          div {
            transform: scale(1.1);
          }
        }
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      ::v-deep.ant-pagination {
        .ant-pagination-prev ,.ant-pagination-next{
          margin: 0 15px;
            a {
            color: #cb4d2e;
          }

        }
        .ant-pagination-item {
          margin: 0 5px;
          border-radius: 0;

          a {
            color: #cb4d2e;
          }
          &.ant-pagination-item-active {
          background-color: #CB4E2E;
          border: 1px solid transparent;
            a {
              color: #fff;

            }
          }
        }
      }
    }
  }
  .spin-con {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>