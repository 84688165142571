<template>
  <div class="zyjnyc-content">
    <div class="header-box"></div>
    <div class="content-infobg">
      <div class="content-info">
        <div class="content-list">
          <div
            class="content-item"
            v-for="item in jnycList"
            :key="item.id"
            @click="goToClassDetail(item)"
          >
            <div class="item-img">
              <div
                :style="`background-image: url('${item.list_photo}');`"
              ></div>
            </div>
            <div class="item-name">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="Pagination-box">
          <APagination
            v-if="total > 12"
            v-model="page"
            :total="total"
            :page-size="pageSize"
            @change="onPaginationChange"
          ></APagination>
          <div v-else-if="jnycList.length <= 0" class="noData-box">
            <img src="../../assets/image/jsckzyxy/empty.png" alt="" />
            <div class="text">暂无相关内容</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from "ant-design-vue";
import services from "../../assets/js/services";
import { mapActions } from "vuex";
export default {
  name: "xxzyzyjnyc",
  components: {
    APagination: Pagination,
  },
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      category_id: 2,
      jnycList: [],
      total: 0,
      pageSize: 12,
      page: 1,
      listLOading: false
    };
  },
  created() {
    window.scrollTo(0,0)

    this.getkcszList();
  },

  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    async getkcszList() {
      try {
        let res = await services.getkcszList({
          page: this.page,
          size: this.pageSize,
          school_code: this.school_code,
          category_id: this.category_id,
        });
        console.log(res);
        if (res.code == "0000" && res.data.items.length > 0) {
          this.jnycList = res.data.items;
          this.total = res.data.total;
        }
      } catch (error) {}
    },
    onPaginationChange(current) {
      this.page = current;
      this.getkcszList();
    },
    goToClassDetail(item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(
          `${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.id}&category_name=${item.name}&cm_id=${item.id}`
        );
      });
    },
  },
};
</script>

<style lang="less" scoped>
.zyjnyc-content {
  .header-box {
    width: 100%;
    height: 300px;
    background-image: url("../../assets/image/xxzyk/zyjnyc-banner.png");
        background-size: cover;

    background-position: center;
  }
  .content-infobg {
    width: 100%;
    .content-info {
      width: 1200px;
      margin: 0 auto;
      padding-top: 28px;

      .content-list {
        display: flex;
        flex-wrap: wrap;
        .content-item {
          border: 1px solid #e4e3e3;
          width: 270px;
          background: #fff;
          margin-left: 40px;
          margin-bottom: 24px;
          cursor: pointer;
          &:nth-child(4n + 1) {
            margin-left: 0;
          }
          &:hover .item-img div {
            transform: scale(1.1);
          }
          .item-img {
            width: 100%;
            height: 152px;
            overflow: hidden;
            // background: #3c3c3c;
            div {
              width: 100%;
              height: 152px;
              background-size: cover;
              background-position: center;
              transition: all 0.5s;
            }
          }
          .item-name {
            margin: 14px 15px;
            height: 40px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            -webkit-line-clamp: 2; //显示几行
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #3a3a3a;
          }
        }
      }
      .Pagination-box {
        display: flex;
        margin-bottom: 28px;
        justify-content: center;
        .noData-box {
          width: 100%;
          text-align: center;
          height: 770px;
          padding-top: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 150px;
          img {
            width: 240px;
            height: auto;
            margin: 0 auto 30px;
          }
          .text {
            color: #76797e;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>