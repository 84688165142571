import services from '../assets/js/services'

export default {
  state: {
    szyskElementData: [],
    szyskDetails: {},
    relevantList: [], // 相关资源数据
    relevantListNums: 0, // 
    relevantTotal: 0,
    pageNum: 10,
    szysBgList: ["one", "two", "three", "four", "five"],
  },
  getters: {
  },
  actions: {
    // 获取思政资源库- 筛选 - 思政元素列表
    getSzyskElement (context, payload) {
      return services.getSzzykElement(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSzElementData', {
            'data': res.data && res.data.items ? res.data.items : []
          })
        }
        return res;
      })
    },
    getSzyskDetails (context, payload) {
      return services.getSzyskDetails(payload).then(res => {
        if (res.code == '0000') {
          context.commit('setSzElementDetails', {
            'data': res.data ? res.data : null
          })
        }
        return res;
      })
    },
    // 获取思政元素的相关思政资源库列表数据
    getRelevantData (context, payload) {
      return services.getSzzykListData(payload).then(res => {
        if (res.code == '0000') {
          if(payload.count){
            context.commit('setRelevantTotal', {
              'data': res.data ? res.data : null
            })
          } else {
            context.commit('setRelevantData', {
              'data': res.data && res.data.items ? res.data : null
            })
          }
          
        }
        return res;
      })
    },
  },
  mutations: {
    setSzElementData (state, payload) {
      if (payload.data && payload.data.length > 0) {
        payload.data.forEach(element => {
          element.classname = `${state.szysBgList[Math.floor(Math.random() * 5)]}`
        });
      }
      state.szyskElementData = payload.data;
    },
    setSzElementDetails (state, payload) {
      if (payload.data) {
        state.szyskDetails = payload.data;
      } else {
        state.szyskDetails = {};
      }
    },
    setRelevantData (state, payload) {
      if (payload.data) {
        state.relevantList = payload.data.items;
      } else {
        state.relevantList = [];
      }
    },
    setRelevantTotal (state, payload) {
      if (payload.data) {
        state.relevantListNums = payload.data.page_num;
        state.relevantTotal = payload.data.total ? payload.data.total : 0
      } else {
        state.relevantListNums = 0;
        state.relevantTotal = 0
      }
    }
  }
}