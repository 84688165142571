import Vue from 'vue'
import VueRouter from 'vue-router'

import utils from '../assets/js/utils.js';
import services from '../assets/js/services.js';
// import wxShareMethod from '../assets/js/weixin-share';
import store from '../store'
import VueCookies from 'vue-cookies';

// 引入组件
import Home from '../pages/home.vue'
import ResetPassword from '../pages/ResetPassword.vue'
import Search from "../pages/search/search.vue"
// szztk_bk 为旧版思政专题库页面
// import Szztk_bk from "../pages/szztk_bk/list.vue"
// import Szztk_xfrw_bk from "../pages/szztk_bk/xfrw.vue"
// import Szztk_ssjt_bk from "../pages/szztk_bk/ssjt.vue"
// import Szztk_xxys_bk from "../pages/szztk_bk/xxys.vue"
// import Szztk_Xfrw_sdkm_bk from "../pages/szztk_bk/detail_sdkm.vue"
// import Szztk_Xfrw_sdkm_list_bk from "../pages/szztk_bk/detail_list_sdkm.vue"
// import Szztk_ylzl_bk from "../pages/szztk_bk/ylzl.vue"
// import Szztk_yzzsjzj_bk from "../pages/szztk_bk/yzzszzj.vue"
import Szztk from "../pages/szztk"
import Szztk_mks from "../pages/szztk/mks.vue"
import Szztk_detailIframe from "../pages/szztk/detailIframe.vue"

import AreaRegion from "../pages/areaRes/index.vue"
import AreaList from "../pages/areaRes/list.vue"
import AreaDetail from "../pages/areaRes/areaDetail.vue"

import Szzyk from '../pages/szzyk/list.vue'
import Szzyk_details from "../pages/szzyk/details.vue"
import Szysk from "../pages/szysk/list.vue"

import Xxzyk from "../pages/xxzyk/xxzykIndex.vue"
// import Xxzyk from "../pages/xxzyk/classList.vue"     
import XxzykIndex from "../pages/xxzyk/index.vue"
import Xxzykjsfz from "../pages/xxzyk/zykjsfz.vue"
import Xxzykzjsj from "../pages/xxzyk/xxzyzjsj.vue"
import Xxzyzyjnyc from "../pages/xxzyk/xxzyzyjnyc.vue"
import Xxzyrwsy from "../pages/xxzyk/xxzyrwsy.vue"
import Xxzykcszzt from "../pages/xxzyk/xxzykcszzt.vue"
import xxzykszztyx from "../pages/xxzyk/xxzykszztyx.vue"
import Xxzykcszal from "../pages/szalzy/xxzykcszal.vue"
import Xxzykmyztyx from "../pages/xxzyk/myztyx.vue"

// 工匠精神
 
import gjjsIndex from "../pages/gjjs/index/index.vue"
import gjjs from "../pages/gjjs/gjjs.vue"
import gjjs_detail from "../pages/gjjs/detail.vue"
import xsgjList from "../pages/gjjs/xsgj/xsgjList.vue"
import jxlhList from "../pages/gjjs/jxlh/jxlhList.vue"
import jxlhDetal from "../pages/gjjs/jxlh/jxlhDetail.vue"
import jxshany from "../pages/gjjs/jxshany/jxshany.vue"
import gjjsSearch from "../pages/gjjs/search/search.vue"
import jxsy from "../pages/gjjs/jxsy/jxsy.vue"

// 思政课拓展资源
import szktz from "../pages/szktz/szkzt.vue"

import Szysk_details from "../pages/szysk/details.vue"
import Page404 from "../pages/page404"
import NoAuth from "../pages/noAuth.vue"
import Phone from "../pages/phone"
import Syzn from "../pages/syzn"
import Szsfkc from "../pages/szsfkc/list.vue"
import SzsfkcDetail from "../pages/szsfkc/detail.vue"


import Sdcmzyxy_index from "../pages/sdcmzyxy/index.vue"
import Sdcmzyxy_ptjs from "../pages/sdcmzyxy/ptjs.vue"
import Sdcmzyxy_zcwj from "../pages/sdcmzyxy/zcwj.vue"
import Sdcmzyxy_detail from "../pages/sdcmzyxy/detail.vue"
import Sdcmzyxy_sztd from "../pages/sdcmzyxy/sztd.vue"
import Sdcmzyxy_sztd_detail from "../pages/sdcmzyxy/mstd-detail.vue"
import Sdcmzyxy_sfkc from "../pages/sdcmzyxy/sfkc.vue"
import Sdcmzyxy_dxjy from "../pages/sdcmzyxy/dxjy.vue"
import Sdcmzyxy_jsdt from "../pages/sdcmzyxy/jsdt.vue"
import Sdcmzyxy_search from "../pages/sdcmzyxy/search.vue"

import Whrjgczyxy_index from "../pages/whrjgczyxy/index.vue"
import Whrjgczyxy_ptjs from "../pages/whrjgczyxy/ptjs.vue"
import Whrjgczyxy_zcwj from "../pages/whrjgczyxy/zcwj.vue"
import Whrjgczyxy_detail from "../pages/whrjgczyxy/detail.vue"
import Whrjgczyxy_sztd from "../pages/whrjgczyxy/sztd.vue"
import Whrjgczyxy_sztd_detail from "../pages/whrjgczyxy/sztd-detail.vue"
import Whrjgczyxy_mjjt from "../pages/whrjgczyxy/mjjt.vue"
import Whrjgczyxy_dxjy from "../pages/whrjgczyxy/dxjy.vue"
import Whrjgczyxy_dxal from "../pages/whrjgczyxy/dxal.vue"
import Whrjgczyxy_jsdt from "../pages/whrjgczyxy/jsdt.vue"
import Whrjgczyxy_sfkc from "../pages/whrjgczyxy/sfkc.vue"
import Whrjgczyxy_search from "../pages/whrjgczyxy/search.vue"
import Whrjgczyxy_syzn from "../pages/whrjgczyxy/syzn.vue"

Vue.use(VueRouter)

var staticRoutes = [
  {
    path: "/:schoolcode(\\d+)?/zy",
    name: "首页",
    component: Home,
  },
  // {
  //   path: "/:schoolcode^(?!100023)(?!100017)(\\d+)",
  //   name: "首页",
  //   component: Home,
  // },
  {
    path: "/:schoolcode(\\d+)?",
    name: "首页",
    component: Home,
  },
  
  {
    path: "/:schoolcode(\\d+)?/gjjs",
    name: "工匠精神",
    component: gjjs,
    redirect: "/:schoolcode(\\d+)?/gjjs/gjjsIndex",
    children: [
      {
        path: "gjjsIndex",
        name: "工匠精神首页",
        component: gjjsIndex,
      },
      {
        path: "detail",
        name: "详情",
        component: gjjs_detail
      },
      {
        path: "jdgj",
        name: "习说工匠解读工匠列表",
        component: xsgjList
      },
      {
        path: "jxlh",
        name: "匠心领行列表",
        component: jxlhList

      },
      {
        path: "jxlhDetail",
        name: "匠心领行详情",
        component: jxlhDetal
      },
      {
        path: "jxshany",
        name: "匠心闪耀列表",
        component: jxshany
      },
      {
        path: "gjjsSearch",
        name: "工匠精神搜索列表",
        component: gjjsSearch
      },
      {
        path: "jxsy",
        name: "匠心溯源列表",
        component: jxsy
      }
    ]
  },
  {
    path: '/:schoolcode(\\d+)?/zy/szztk',
    name: '思政专题库',
    component: Szztk,
  },
  // {
  //   path: '/zy/szztk/list',
  //   name: '思政专题库_53',
  //   component: Szztk_bk,
  // },
  // {
  //   path: '/zy/szztk/xfrw',
  //   name: '先锋人物',
  //   component: Szztk_xfrw_bk
  // },
  // {
  //   path: '/zy/szztk/xfrw/sdkm',
  //   name: '时代楷模',
  //   component: Szztk_Xfrw_sdkm_bk
  // },
  // {
  //   path: '/zy/szztk/xfrw/sdkm/details/:id',
  //   name: '时代楷模_list',
  //   component: Szztk_Xfrw_sdkm_list_bk
  // },
  // {
  //   path: '/zy/szztk/ssjt',
  //   name: '四史讲堂',
  //   component: Szztk_ssjt_bk
  // },
  // {
  //   path: '/zy/szztk/xxys',
  //   name: '学习有声',
  //   component: Szztk_xxys_bk
  // },
  // {
  //   path: '/:schoolcode(\\d+)?/zy/szztk/ylzl',
  //   name: '一路走来的习近平',
  //   component: Szztk_ylzl_bk
  // },
  // {
  //   path: '/:schoolcode(\\d+)?/zy/szztk/yzzsjzj',
  //   name: '沿着总书记的足迹',
  //   component: Szztk_yzzsjzj_bk
  // },
  {
    path: '/:schoolcode(\\d+)?/zy/szztk/mks',
    name: '不一样的马克思',
    component: Szztk_mks
  },
  {
    path: '/:schoolcode(\\d+)?/zy/szztk/detail/:id',
    name: '思政专题库详情页',
    component: Szztk_detailIframe
  },
  {
    path: '/:schoolcode(\\d+)?/zy/search',
    name: '检索词搜索',
    component: Search
  },
  {
    path: '/:schoolcode(\\d+)?/zy/szzyk',
    name: '思政资源库',
    component: Szzyk,
  },
  {
    path: '/:schoolcode(\\d+)?/zy/szzyk/details',
    name: '思政资源库详情',
    component: Szzyk_details
  },
  {
    path: '/:schoolcode(\\d+)?/zy/szsfkc',
    name: '思政示范课程',
    component: Szsfkc,
  },
  {
    path: '/:schoolcode(\\d+)?/zy/szsfkc/detail',
    name: '思政示范课程详情',
    component: SzsfkcDetail,
  },

  // SzsfkcDetail
  {
    path: "/:schoolcode(\\d+)?/zy/szysk",
    name: "思政元素体系",
    component: Szysk,
  },
  {
    path: "/:schoolcode(\\d+)?/zy/alzyk",
    name: "教学案例库",
    component: Xxzykcszal
  },
  {
    path: "/:schoolcode(\\d+)?/zy/xxzyk",
    name: "学习资源库",
    component: Xxzyk,
    redirect: "/:schoolcode(\\d+)?/zy/xxzyk/",
    children: [
      {
        path: "/",
        name: "学习资源库首页",
        component: XxzykIndex
      },
      {
        path: "jsfz",
        name: "教师发展",
        component: Xxzykjsfz
      },
      {
        path: "zjsj",
        name: "职教世界",
        component: Xxzykzjsj
      },
      {
        path: "zyjnyc",
        name: "专业技能养成",
        component: Xxzyzyjnyc
      },
      {
        path: "rwsy",
        name: "任务素养",
        component: Xxzyrwsy
      },
      {
        path: "kcszzt",
        name: "课程思政专题研修",
        component: Xxzykcszzt
      },
      {
        path: "szztyx",
        name: "思政专题研修",
        component: xxzykszztyx
      },
      {
        path: "myztyx",
        name: "美育专题研修",
        component: Xxzykmyztyx
      },


    ]
  },
  {
    path: "/:schoolcode(\\d+)?/zy/szktz",
    name:"思政课拓展",
    component: szktz
  },
  {
    path: "/:schoolcode(\\d+)?/zy/szysk/details",
    name: "思政元素库详情",
    component: Szysk_details,
  },
  {
    path: "/:schoolcode(\\d+)?/zy/syzn",
    name: "使用指南",
    component: Syzn,
  },
  {
    path: "/:schoolcode(\\d+)?/zy/region",
    name: "区域资源",
    component: AreaRegion,
  },
  {
    path: "/:schoolcode(\\d+)?/zy/region/list",
    name: "区域资源库",
    component: AreaList,
  },
  {
    path: "/:schoolcode(\\d+)?/zy/region/list/detail",
    name: "区域资源库",
    component: AreaDetail,
  },
  // 兼容h5提示页面路由
  {
    path: "/:schoolcode(\\d+)?/zy/message",
    name: "提示",
    redirect: '/:schoolcode(\\d+)?/zy',
  },

  {
    path: "/:schoolcode(\\d+)?/zy/page404",
    name: "404",
    component: Page404,
  },
  {
    path: "/:schoolcode(\\d+)?/zy/phonepage",
    name: "",
    component: Phone,
  },
  {
    path: "/:schoolcode(\\d+)?/zy/resetPassword",
    name: "重置密码",
    component: ResetPassword,
  },
  {
    path: "/:schoolcode(\\d+)?/zy/noauth",
    name: "无权限",
    component: NoAuth,
  },
  // 山东传媒职业学院定制页面路由
  {
    path: `/:schoolcode(${utils.sdcm_code})/sdcmzyxy`,
    name: "山东传媒职业学院",
    component: Sdcmzyxy_index,
  },
  {
    path: `/:schoolcode(${utils.sdcm_code})/sdcmzyxy/ptjs`,
    name: "山东传媒职业学院-平台介绍",
    component: Sdcmzyxy_ptjs,
  },
  {
    path: `/:schoolcode(${utils.sdcm_code})/sdcmzyxy/zcwj`,
    name: "山东传媒职业学院-政策文件",
    component: Sdcmzyxy_zcwj,
  },
  {
    path: `/:schoolcode(${utils.sdcm_code})/sdcmzyxy/detail/:id`,
    name: "山东传媒职业学院-详情",
    component: Sdcmzyxy_detail,
  },

  {
    path: `/:schoolcode(${utils.sdcm_code})/sdcmzyxy/sztd`,
    name: "山东传媒职业学院-师资团队",
    component: Sdcmzyxy_sztd,
  },
  {
    path: `/:schoolcode(${utils.sdcm_code})/sdcmzyxy/sztd/detail/:id`,
    name: "山东传媒职业学院-名师团队-详情",
    component: Sdcmzyxy_sztd_detail,
  },

  {
    path: `/:schoolcode(${utils.sdcm_code})/sdcmzyxy/sfkc`,
    name: "山东传媒职业学院-示范课程",
    component: Sdcmzyxy_sfkc,
  },
  {
    path: `/:schoolcode(${utils.sdcm_code})/sdcmzyxy/dxjy`,
    name: "山东传媒职业学院-典型经验",
    component: Sdcmzyxy_dxjy,
  },
  {
    path: `/:schoolcode(${utils.sdcm_code})/sdcmzyxy/jsdt`,
    name: "山东传媒职业学院-建设动态",
    component: Sdcmzyxy_jsdt,
  },
  {
    path: `/:schoolcode(${utils.sdcm_code})/sdcmzyxy/search`,
    name: "山东传媒职业学院-搜索",
    component: Sdcmzyxy_search,
  },

  // 武汉软件工程职业学院定制页面路由
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy`,
    name: "武汉软件工程职业学院",
    component: Whrjgczyxy_index,
  },
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/ptjs`,
    name: "武汉软件工程职业学院-平台介绍",
    component: Whrjgczyxy_ptjs,
  },
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/zcwj`,
    name: "武汉软件工程职业学院-政策文件",
    component: Whrjgczyxy_zcwj,
  },
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/detail/:id`,
    name: "武汉软件工程职业学院-详情",
    component: Whrjgczyxy_detail,
  },

  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/sztd`,
    name: "武汉软件工程职业学院-师资团队",
    component: Whrjgczyxy_sztd,
  },
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/sztd/detail/:id`,
    name: "武汉软件工程职业学院-名师团队-详情",
    component: Whrjgczyxy_sztd_detail,
  },

  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/mjjt`,
    name: "武汉软件工程职业学院-名家讲坛",
    component: Whrjgczyxy_mjjt,
  },
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/dxjy`,
    name: "武汉软件工程职业学院-典型经验",
    component: Whrjgczyxy_dxjy,
  },
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/dxal`,
    name: "武汉软件工程职业学院-典型案例",
    component: Whrjgczyxy_dxal,
  },
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/jsdt`,
    name: "武汉软件工程职业学院-建设动态",
    component: Whrjgczyxy_jsdt,
  },
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/sfkc`,
    name: "武汉软件工程职业学院-示范课程",
    component: Whrjgczyxy_sfkc,
  },
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/search`,
    name: "武汉软件工程职业学院-搜索",
    component: Whrjgczyxy_search,
  },
  {
    path: `/:schoolcode(${utils.whrj_code})/whrjgczyxy/syzn`,
    name: "武汉软件工程职业学院-使用指南",
    component: Whrjgczyxy_syzn,
  },
]
const newRoutes = [...staticRoutes];

let router = new VueRouter({
  mode: 'history',
  routes: newRoutes,
})

router.beforeEach((to, from, next) => {
  let code = to.params && to.params.schoolcode ? to.params.schoolcode : ''
  // 带有code 且 属于有权限访问的学校, 不符合跳转sz
  // console.log(code)
  if (code) {
    localStorage.setItem("schoolCode", code);
  } else {
    localStorage.setItem("schoolCode", '')
    window.open(services.SZ_SERVER, "_self")
  }
  // 武软100024访问院校主页、建设动态、典型案例、政策文件、示范课程、师资团队页面，跳转到zhsz.jiaoyu.cn相对应页面
  if (to.path == `/${code}/whrjgczyxy` || to.path == `/${code}/whrjgczyxy/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/2/index.html`, "_self")
  }
  if (to.path == `/${code}/whrjgczyxy/jsdt` || to.path == `/${code}/whrjgczyxy/jsdt/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/2/jsdt/index.html`, "_self")
  }
  if (to.path == `/${code}/whrjgczyxy/dxal` || to.path == `/${code}/whrjgczyxy/dxal/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/2/dxal/index.html`, "_self")
  }
  if (to.path == `/${code}/whrjgczyxy/zcwj` || to.path == `/${code}/whrjgczyxy/zcwj/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/2/zcwj/index.html`, "_self")
  }
  if (to.path == `/${code}/whrjgczyxy/sfkc` || to.path == `/${code}/whrjgczyxy/sfkc/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/2/sfkc/index.html`, "_self")
  }
  if (to.path == `/${code}/whrjgczyxy/sztd` || to.path == `/${code}/whrjgczyxy/sztd/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/2/sztd/index.html`, "_self")
  }
  // 山传100017访问院校主页、建设动态、典型案例、政策文件、示范课程、师资团队页面，跳转到zhsz.jiaoyu.cn相对应页面
  if (to.path == `/${code}/sdcmzyxy` || to.path == `/${code}/sdcmzyxy/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/5/index.html`, "_self")
  }
  if (to.path == `/${code}/sdcmzyxy/ptjs` || to.path == `/${code}/sdcmzyxy/ptjs/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/5/page/2023/07/ptjs.html`, "_self")
  }
  if (to.path == `/${code}/sdcmzyxy/jsdt` || to.path == `/${code}/sdcmzyxy/jsdt/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/5/jsdt/index.html`, "_self")
  }
  if (to.path == `/${code}/sdcmzyxy/dxjy` || to.path == `/${code}/sdcmzyxy/dxjy/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/5/dxjy/index.html`, "_self")
  }
  if (to.path == `/${code}/sdcmzyxy/zcwj` || to.path == `/${code}/sdcmzyxy/zcwj/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/5/zcwj/index.html`, "_self")
  }
  if (to.path == `/${code}/sdcmzyxy/sfkc` || to.path == `/${code}/sdcmzyxy/sfkc/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/5/sfkc/index.html`, "_self")
  }
  if (to.path == `/${code}/sdcmzyxy/sztd` || to.path == `/${code}/sdcmzyxy/sztd/`) {
    window.open(`${services.ZHSZ_SERVER}/${code}/5/shizituandui/index.html`, "_self")
  }  
  // 如未指定建设课程思政云平台的学校，开放了“思政资源库展示平台”时，访问kcsz.jiaoyu.cn/schoolcode即直接访问学校的思政资源库展示端（kcsz.jiaoyu.cn/schoolcode/zy）。
  if (code == utils.sdcm_code || code == utils.whrj_code) {
    if (to.path == `/${code}/zy` || to.path == `/${code}/zy/` || to.path == `/${code}` || to.path == `/${code}/`) {
      if (code == utils.whrj_code) {
        next(`/${code}/whrjgczyxy`)
      } else if (code == utils.sdcm_code) {
        next(`/${code}/sdcmzyxy`)
      }
    }
  } else {
    if (to.path == `/${code}` || to.path == `/${code}/`) {
      next(`/${code}/zy`)
    }
  }
  let isNeedLogin = utils.pathRouteName(to.path)
  if (isNeedLogin) {
    let appsToken = VueCookies.get("api_token");
    if (appsToken) {
      next()
    } else {
      // 3-10 游客未登录时 不去教务登录页面了 直接打开本网站登录弹层进行登录
      if (to.path.indexOf('/zy/szztk') > -1) {
        next(`/${code}/zy/szztk`)
      } else {
        next(`/${code}/zy`)
      }
      store.dispatch("goAppsDetailsBeforeLogin")
    }
  } else {
    next();
  }
})

export default router