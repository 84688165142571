import Vue from 'vue'
import App from './App.vue'
import router from '../router/router'
import store from '../store'
import { Modal, Menu, ConfigProvider, message, notification, Carousel, Dropdown, Pagination, Empty, Spin, Icon, Button  } from "ant-design-vue";
import VueLazyload from 'vue-lazyload'

Vue.use(Modal);
Vue.use(Menu);
Vue.use(Carousel);
Vue.use(Dropdown);
Vue.use(Pagination);
Vue.use(Empty);
Vue.use(Spin);
Vue.use(Icon);
Vue.use(ConfigProvider);
Vue.use(Button);
// Vue.use(progress);

Vue.use(VueLazyload, {
  loading: window.origin + '/loading-imgs.webp',//loading过渡效果
  try: 1, // 一次加载图片数量
})

Vue.config.productionTip = false

//注入全局属性$message
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
message.config({
  duration: 2,// 持续时间
  top: `45%`, // 到页面顶部距离
  maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
