<template>
  <div>
    <WhrjHeader />
    <div class="sdcm-container-box" >
      <div class="content-box w1280">
        <div class="header-box"></div>
        <div class="body-box">
          <template v-if="teachGrou && teachGroup.length > 0">
            <div class="item" v-for="item,index in teachGroup" :key="index" @click="goDetail(item)">
              <div class="main-content">
                <div class="left-cover" :style="`background-image:url(${item.thumb_image})`"></div>
                <div class="right-desc">
                  <div class="name">{{item.name}}</div>
                  <div class="tag-group">
                    <div class="tag-item" v-for="item,index in item.position" :key="index">
                      {{item}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="intro">
                <span v-for="item,index in item.details" :key="index">
                  {{item}}
                </span>
              </div>
            </div>
            <div class="sub-visibility"></div>
            <div class="sub-visibility"></div>
          </template>
          <div class="no-content" v-else>
            <div>待提供内容</div>
          </div>
        </div>
      </div>
    </div>
    
    <WhrjFooter :color="'#3e140b'" />
  </div>
</template>

<script>
import WhrjHeader from "../../components/WhrjHeader.vue"
import WhrjFooter from "../../components/WhrjFooter"
import sdcmData from "./sdcmData"
export default {
  data () {
    return {
      // teachGroup: sdcmData.teachGroup,
      teachGroup: [],
      school_code: localStorage.getItem("schoolCode"),

    }
  },
  components: {
    WhrjHeader,
    WhrjFooter
  },
  mounted () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  methods: {
    goDetail (item) {
      window.open(`/${this.school_code}/whrjgczyxy/sztd/detail/${item.id}`)
    }
  }

}
</script>

<style lang="less" scoped>
* {
  transition: all 0.3s;
}
.w1280 {
  width: 1280px;
  margin: 0 auto;
}
.sdcm-container-box {
  background-image: url('../../assets/image/sdcmzyxy/zcwj-body.png');
  background-size: cover;
  padding: 22px 0 34px;
  .content-box {
    min-height: 600px;
    background-color: #fff;
    padding: 14px 40px;
    .header-box {
      width: 259px;
      height: 51px;
      background-image: url('../../assets/image/sdcmzyxy/teach-group.png');
      background-size: cover;
      margin: 0 auto 20px auto;
    }
    .body-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 380px;
        height: 263px;
        background: #ffffff;
        border: 1px solid #e5e7eb;
        padding: 25px 30px 0;
        position: relative;
        margin-bottom: 30px;
        cursor: pointer;
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 11px;
          height: 12px;
          top: 3px;
          left: 3px;
          background-image: url('../../assets/image/sdcmzyxy/teach-group-angle.png');
          background-size: cover;
        }
        &::after {
          content: '';
          width: 100%;
          height: 4px;
          background: #b44536;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        .main-content {
          display: flex;
          padding-bottom: 17px;
          margin-bottom: 17px;
          border-bottom: 1px solid #eeebe8;
          &::before {
            content: 'Famous teacher';
            display: inline-block;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #f6f6f6;
            position: absolute;
            right: 27px;
            top: 20px;
          }
          .left-cover {
            width: 72px;
            height: 72px;
            border-radius: 50%;
            background-size: cover;
            margin-right: 18px;
            &::before {
              content: '';
              display: inline-block;
              width: 100%;
              height: 100%;
              background-color: rgba(14, 14, 14, 8%);
              border-radius: 50%;
            }
          }
          .right-desc {
            margin-top: 10px;
            flex: 1;
            .name {
              width: 54px;
              font-size: 18px;
              font-weight: bold;
              color: #262626;
              margin-bottom: 5px;
            }
            .tag-group {
              width: 228px;
              display: inline-block;
              color: #ff870f;
              height: 24px;
              overflow: hidden;
              // white-space: nowrap;
              // text-overflow: ellipsis;
            }
            .tag-item {
              display: inline-block;
              padding: 0 7px;
              height: 24px;
              line-height: 24px;
              background: #ffffff;
              border: 1px solid #f4d088;
              border-radius: 2px;
              font-size: 12px;
              margin-right: 8px;
              max-width: 228px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
      .intro {
        font-size: 13px;
        color: #666666;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }
    }
    .sub-visibility {
      flex: 0 0 380px;
      display: inline-block;
      overflow: visible;
    }
  }
}
.no-content {
  width: 400px;
  margin: 100px auto 0;
  img {
    width: 100%;
    height: auto;
  }
  div {
    padding-top: 13px;
    width: 100%;
    font-size: 16px;
    color: #969799;
    text-align: center;
  }
}
</style>