<template>
  <div class="phone-outer-box">
    <div class="top-imgs">
      <a class="jyzx" href="https://www.eol.cn" target="_blank"><img src="../assets/image/phone/zgjyzx.png" /></a>
      <a class="yxt" :href="services.YXT_GW" target="_blank"><img src="../assets/image/phone/yxt.png" /></a>
      <a class="szzyk" href="javascript:void(0);"><img src="../assets/image/phone/szzyk.png" /></a>
    </div>
    <div class="nav-box">
      <div class="items">
        <router-link to="/szzyk">课程思政素材库</router-link>
      </div>
      <div class="items">
        <router-link to="/szztk">思政专题库</router-link>
      </div>
      <div class="items">
        <router-link :to="`${services.YXT_GW}/school/class`">思政学习库</router-link>
      </div>
    </div>
    <div class="middle-box">
      <div class="middle-con">
        <img class="imgs" src="../assets/image/phone/phone-bg.png" />
        <p>为了便于资源更好的展现查看，</p>
        <p>请移步到电脑端查看。</p>
      </div>
    </div>
    <!-- <div class="support-box">
      <span>数据支持单位</span>
      <img class="rmkj-img" src="../assets/image/phone/rmkj.png" />
    </div> -->
  </div>
</template>

<script>
import services from "../assets/js/services"
export default {
  name: "phonepage",
  components: {},
  data () {
    return {
      services
    };
  },
  mounted () {
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.phone-outer-box {
  position: fixed;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .top-imgs {
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      img {
        width: 100%;
        height: auto;
      }
      &.jyzx {
        width: 120px;
      }
      &.yxt {
        flex: 1;
        margin: 0 20px;
      }
      &.szzyk {
        width: 90px;
        display: flex;
        align-items: center;
      }
    }
  }
  .nav-box {
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 0 15px;
    line-height: 40px;
    background: #d21010;
    color: #ffffff;
    font-size: 14px;
    .items {
      // flex: 1;
      text-align: center;
      a {
        color: #ffffff;
        font-size: 14px;
      }
    }
  }
  .middle-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    transform: translate(-50%, -50%);
    text-align: center;
    .imgs {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }
    p {
      color: #555555;
      font-size: 14px;
      line-height: 26px;
    }
  }
  .support-box {
    position: absolute;
    bottom: 50px;
    width: 100%;
    text-align: center;
    span {
      color: #d21010;
      font-size: 14px;
      letter-spacing: 2px;
    }
    .rmkj-img {
      vertical-align: middle;
      display: inline-block;
      margin-left: 13px;
      width: 90px;
      height: auto;
    }
  }
}
</style>