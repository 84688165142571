<template>
  <div class="gjjs-detail">
    <div class="header-ber" :class="columnpath"></div>
    <div class="detail-con">
      <div class="bread-crumbs">
        <div class="crumbs" @click="$router.push(`/${school_code}/gjjs`)">
          首页 
        </div>
        >
        <div class="crumbs" @click="$router.push(`/${school_code}/gjjs/${columnpath}`)">{{ columnNmae }} </div>
      </div>
      <div class="detail-tit">{{ title }}</div>
      <div
        id="jeol-article-box"
        @mouseup="handleMouseSelect($event)"
        @mouseout="mouseoutEvent"
        class="article"
      >
        <div class="detail-info" v-html="detailHtml"></div>
        <a
          href="javascript:void(0)"
          v-show="isShowBeike"
          @mouseenter="hoverBeike"
          @click="goOnlineBeike"
          class="element-beike-box"
          :style="`top: ${clientY}px; left:${clientX}px`"
          >在线备课</a
        >
      </div>

      <div class="page-change">
        <div class="page-next " @click="resourceChange(resourcePrev)" :class="{not: resourcePrev instanceof Array }" >
          上 一 条
        </div>
        <div class="page-prev" @click="resourceChange(resourceNext)" :class="{not: resourceNext instanceof Array }">
          下 一 条
        </div>
      </div>
    </div>
    <div class="disclaimer">
      <div class="title">免责声明</div>
      <div class="disclaimer-item">
        本平台仅提供空间服务，平台所有信息资源仅供学习、教学使用。凡以事与本平台相关的所有行为（包括但不限于访问浏览、利用、转载、下载资料)时，必须保留本平台注明的“资源来源”并标注“仅供教学使用”字样。不得故意或者过失提害本平台的各类合法权利与利益，不得利用本平台以任何方式直接或者间接的从事违反中国洗律、国际公约以及社会公德的行为，且应当恪守下述承诺:
      </div>
      <div class="disclaimer-item">
        <div>
          1、传输和利用资料信息符合中国法律、国际公约的规定、符合公序良俗；
        </div>
        <div>
          2、不将本平台以及与之相关的更新资料用作非法用途以及非正当用途；
        </div>
        <div>3、遵守与本平台相关的服务协议、规定、程序和惯例等。</div>
      </div>
      <div class="disclaimer-item">
        <div>
          平台所涉及的部分互联网信息，如无意侵犯了机构或个人的知识产权，请邮件告之，我方将立即删除。
        </div>
        <div>
          联系邮箱：【管老师 guanfm@eol.cn】 、【汪老师 wangyan@eol.cn】
        </div>
      </div>
    </div>
    <div class="spin-con" v-if="isLoading">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import services from "../../assets/js/services.js";
import utils from "../../assets/js/utils.js";
import { Empty, Modal } from 'ant-design-vue';
export default {
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      id: "",
      column_id: "",
      columnNmae: "",
      columnpath: "",
      title: "",
      detailHtml: "",
      resourceNext: null,
      resourcePrev: null,
      isShowBeike: false,
      isChrome: false,
      currentElem: null,
      selectionText: '',
      clientX: 0,
      clientY: 0,
      isLoading: false,
      // isLoading: false,

      textTag: ['<p', "<span", "<strong", "<em"],
      html: "",
      columnPathList: [
        {
          title: "习说工匠-解读工匠",
          path: "jdgj",
        },
        {
          title: "中华智慧-匠心溯源",
          path: "jxsy",
        },
        {
          title: "继往开来-匠心闪耀",
          path: "jxshany",
        },
        // {
        //   title: "匠苗培育-世赛青春",
        //   path: "ssqc",
        // },
      ],
    };
  },
  async created() {
    let res = await this.getAppsLoginUserInfo();
    this.isChrome = utils.bowerUserAgent();
    if (res.code != "0000") {
      this.$message.info("请先登录！");
      this.$router.push({ path: `/${this.school_code}/gjjs/gjjsIndex` });
    }
    this.id = this.$route.query.id;
    this.column_id = this.$route.query.column_id;
    this.columnpath = this.$route.query.columnPath;
    this.columnNmae = this.columnPathList.find(
      (item) => item.path == this.$route.query.columnPath
    ).title;
    
    this.getDetail();
  },
  methods: {
    ...mapActions(["getAppsLoginUserInfo",'getSzzyRecommendData', 'goAppsDetailsBeforeLogin', 'getBeikeSelectOffice', 'getBeikeSelectImage', 'getBeikeSelectVideo', 'saveResourceFavorite', 'allTopic']),
    async getDetail() {
      this.isLoading = true;
      try {
        let res = await services.getztgjjsDetail(
          {
            column_id: this.column_id,
            is_next_preview: 1,
          },
          this.id
        );
        if (res.code == "0000") {
          this.title = res.data.resource.title;
          this.detailHtml = res.data.resource.content;
          this.resourceNext = res.data.next || null,
          this.resourcePrev = res.data.preview || null,
           await this.addEventMethod();
           await this.addBeikeMethod();
        }
      } catch (error) {
      } finally {
        window.scrollTo(0, 0);
        this.isLoading = false;
      }
    },
    addEventMethod() {
      let that = this;
      that.$nextTick(() => {
        let videoElement = document.querySelectorAll("video"); // 获取页面上所有的video对象
        if (videoElement && videoElement.length > 0) {
          for (let i = 0; i < videoElement.length; i++) {
            let appid = videoElement[i].getAttribute("data-appid");
            let domid = videoElement[i].getAttribute("id");
            let fileid = videoElement[i].getAttribute("data-fileid");
            if (appid && domid && fileid) {
              that.initVideoFile(domid, fileid, appid);
            }
          }
        }
      });
    },
    initVideoFile(elemId, qclude_id, appId) {
      utils.loadScript(
        window.location.origin + "/vod/hls.min.0.12.4.js",
        () => {
          utils.loadScript(
            window.location.origin + "/vod/tcplayer.min.js",
            () => {
              if (typeof window.TCPlayer === "function") {
                window.TCPlayer(elemId, {
                  // player-container-id 为播放器容器ID，必须与html中一致
                  fileID: qclude_id, // 请传入需要播放的视频filID 必须
                  // fileID: "5285890807937512700",
                  controls: true,
                  muted: true,
                  // playbackRates: [0.5, 1, 1.25, 1.5, 2],
                  appID: appId, // 请传入点播账号的appID 必须

                  definition: 30,
                  autoplay: false,
                  plugins: {
                    // ContinuePlay: {
                    // 	// 开启续播功能
                    // 	auto: true, //[可选] 是否在视频播放后自动续播
                    // 	text: "上次播放至 ", //[可选] 提示文案
                    // 	btnText: "恢复播放", //[可选] 按钮文案
                    // },
                  },
                  // controlBar: {
                  //   playbackRateMenuButton: false
                  // }
                });
              }
            }
          );
        }
      );
    },
    resourceChange(data) {
      if (data) {
        if (data.length == 0) return;
        this.id = data.id;
        this.getDetail();
      }
    },
    // 监听鼠标按下
    handleMouseDown($event) {
      if ($event.target.tagName == "A") return;
      if (
        $event.target.tagName != "IMG" &&
        $event.target.tagName != "VIDEO" &&
        window.getSelection().toString() == ""
      )
        this.isShowBeike = false;
    },
    // 鼠标选取文本事件
    handleMouseSelect($event) {
      // 如果没有选取内容 return
      if ($event.target.tagName == "A") return;
      this.currentElem = $event.target;
      // if (!this.html) return this.isShowBeike = false
      if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText("");
      }
      let text = window.getSelection().toString();
      this.selectionText = text;
      if (
        this.currentElem.tagName == "IMG" ||
        this.currentElem.tagName == "VIDEO" ||
        this.currentElem.className == "vjs-poster"
      ) {
        var myElement = $event.target; // 获取子元素的引用
        var top =
          myElement.offsetTop + (this.currentElem.tagName != "IMG" ? 15 : 0); // 获取子元素相对于父元素顶部的位置
        var left =
          myElement.offsetLeft + (this.currentElem.tagName != "IMG" ? 55 : 0); // 获取子元素相对于父元素左侧的位置
        this.clientY = parseInt(top);
        this.clientX = parseInt(left + myElement.offsetWidth - 100);
      } else {
        if (!text) return (this.isShowBeike = false); // 如果没有选取任何内容，不显示“在线备课”
        this.clientX = parseInt($event.offsetX - 28);
        this.clientY = parseInt($event.target.offsetTop + $event.offsetY + 13);
      }
      this.isShowBeike = true;
    },
    // 鼠标进入富文本 在线备课按钮
    hoverBeike() {
      this.isShowBeike = true;
    },
    // 点击选取内容去 在线备课平台
    async goOnlineBeike() {
      let that = this;
      if (that.isChrome) {
        let type = ""; // 1图片 2视频 3组合
        if (that.currentElem) {
          try {
            let clipboardItems;
            await navigator.clipboard.writeText("");
            var clipboardData = await navigator.clipboard;

            if (!clipboardData) {

              return this.$message.error("不支持复制粘贴");
            } else {
              if (!document.execCommand("copy")) {
                return this.$message.error("不支持复制");
              }
              clipboardItems = await navigator.clipboard.read();
              for (let clipboardItem of clipboardItems) {
                let data = await clipboardItem.getType("text/html");
                that.html = await data.text();
                that.html = await that.html
                  .replace(/&quot;/g, "")
                  .replace(/'\'/g, "");
              }
              navigator.clipboard.writeText("");
              for (let i = 0; i < that.textTag.length; i++) {
                // 找到文字&&图片/视频 的组合
                if (
                  (that.html.indexOf(that.textTag[i]) > -1 &&
                    that.html.indexOf("<img") > -1) ||
                  that.html.indexOf("<video") > -1
                ) {
                  type = 3;
                  break;
                  // 未找到文字，找到图片了
                } else if (
                  that.html.indexOf(that.textTag[i]) == -1 &&
                  that.html.indexOf("<img") > -1
                ) {
                  type = 1;
                  break;
                  // 未找到文字，找到视频了
                } else if (
                  that.html.indexOf(that.textTag[i]) == -1 &&
                  that.html.indexOf("<video") > -1
                ) {
                  type = 2;
                  break;
                  // 纯文字
                } else {
                  type = 3;
                }
              }
            }
          } catch {
            that.isLoading = false;
            navigator.clipboard.writeText("");
          }
          that.isLoading = true;
          try {
            let copyText = window.getSelection().toString();
          
            if (
              (that.currentElem.tagName == "VIDEO" ||
                this.currentElem.className == "vjs-poster") &&
              type != 3 &&
              !copyText
            ) {
              let fileId =
                that.currentElem.getAttribute("data-id") ||
                that.currentElem.previousElementSibling.previousElementSibling.previousElementSibling.getAttribute(
                  "data-id"
                );

              if (fileId) {
                // 2022-8-12 思政2.0去掉从前台去备课功能 展示弹窗提示

                that.getBeikeSelectVideo({
                  id: fileId,
                  resource_id: that.id,
                });
                // that.clickBeikeSelect()
              }
            } else if (
              that.currentElem.tagName == "IMG" &&
              type != 3 &&
              !copyText
            ) {
              let srcTxt = that.currentElem.getAttribute("src");
             
              if (srcTxt) {
                that.getBeikeSelectImage({
                  url: srcTxt,
                  resource_id: that.id,
                });
                // that.clickBeikeSelect()
              }
            } else {
              if (that.html) {
                await that.getBeikeSelectOffice({
                  content: `${that.html}`,
                  // resource_id: that.id,
                });
              } else {
                this.$message.error("不支持复制粘贴");
                that.isLoading = false;
                return;
              }
            }
          } catch (err) {
            that.isLoading = false;
            navigator.clipboard.writeText("");
          }
          that.isLoading = false;
        }
      } else {
        that.messageModal();
      }

      // } else {
      //   that.messageModal()
      // }
    },
    // 鼠标再文章内容时 判断是否在img dom
    mouseoverEvent($event) {
      if ($event.target.tagName == "A") return;
      if (window.getSelection().toString()) {
        return;
      }
      this.currentElem = $event.target;
      this.isShowBeike = true;
      // 如果是非文本类型。需要将在线备课按钮定位到素材的右上角
      if (
        this.currentElem.tagName == "IMG" ||
        this.currentElem.tagName == "VIDEO" ||
        this.currentElem.className == "vjs-poster"
      ) {
        var myElement = $event.target; // 获取子元素的引用
        var top =
          myElement.offsetTop + (this.currentElem.tagName != "IMG" ? 15 : 0); // 获取子元素相对于父元素顶部的位置
        var left =
          myElement.offsetLeft + (this.currentElem.tagName != "IMG" ? 55 : 0); // 获取子元素相对于父元素左侧的位置
        this.clientY = parseInt(top);
        this.clientX = parseInt(left + myElement.offsetWidth - 100);
      } else {
        this.clientX = parseInt($event.offsetX - 28);
        this.clientY = parseInt($event.target.offsetTop + $event.offsetY + 13);
      }
    },
    // 鼠标离开文章内容时
    mouseoutEvent() {
      // 如果选中了文字，fixed 备课按钮
      if (window.getSelection().toString()) return;
      this.isShowBeike = false;
    },
     // chrome浏览器弹出提示
    messageModal () {
      Modal.info({
        okType: 'primary',
        okText: '确定',
        closable: true,
        content: (<div class="jioayu-chrome">
          资源在线备课使用Chrome 80 以上版本浏览器体验效果更佳，点击
          <a href="https://www.google.cn/chrome/index.html" target="_blank">下载浏览器</a>。
        </div>),
      })
    },
    // 添加在线备课的事件
    addBeikeMethod() {
      let that = this;
      that.$nextTick(() => {
        let articleEle = document.getElementById("jeol-article-box");
        let allEleArr = [],
          subImgArr = [],
          subVideoArr = [];
        subImgArr = articleEle.getElementsByTagName("img");
        subVideoArr = [
          ...articleEle.getElementsByTagName("video"),
          ...articleEle.getElementsByClassName("vjs-poster"),
        ];
        allEleArr = [...subImgArr, ...subVideoArr];
        if (allEleArr && allEleArr.length > 0) {
          for (let i = 0; i < allEleArr.length; i++) {
            if (allEleArr[i].addEventListener) {
              // 所有主流浏览器，除了 IE 8 及更早版本
              allEleArr[i].addEventListener("mouseenter", that.mouseoverEvent);
              allEleArr[i].addEventListener("mouseleave", that.mouseoutEvent);
            } else if (allEleArr[i].attachEvent) {
              // IE 8 及更早版本
              allEleArr[i].attachEvent("mouseenter", that.mouseoverEvent);
              allEleArr[i].attachEvent("mouseleave", that.mouseoutEvent);
            }
          }
        }
      });
    },
    // 在线备课弹出提示事件 - 新版本
    // clickBeikeSelect () {
    //   // Modal.info({
    //   //   title: '温馨提示',
    //   //   okType: 'primary',
    //   //   okText: '申请开通更多资源',
    //   //   closable: true,
    //   //   content: (<div class="jioayu-chrome">
    //   //     请拨打服务咨询电话或申请开通更多资源，获得在线备课权限<br />服务咨询： 宋老师  18701110987
    //   //   </div>),
    //   //   onOk () {
    //   //     window.open(`${services.YXT_GW}/need`)
    //   //   }
    //   // })
    //   this.isShowBeike = false
    //   this.dialogConfig.name = "appleRes"
    // },
    // 点击在线备课按钮事件 - 旧版本逻辑
    clickBeikeSelect() {
      let that = this;
      if (that.isChrome) {
        that.isLoading = true;
        let articleBox = document.getElementById("jeol-article-box");
        let subImg = articleBox.getElementsByClassName("img");
        let subVideo = articleBox.getElementsByClassName("video");
        if (
          that.detailInfo &&
          that.detailInfo.type != 2 &&
          subImg.length < 1 &&
          subVideo.length < 1
        ) {
          if (articleBox.innerText) {
            let newHTML = articleBox.innerHTML.replace(/在线备课/g, "");
            that
              .getBeikeSelectOffice({
                content: newHTML,
                // resource_id: that.id,
              })
              .then(() => {
                that.isLoading = false;

                //   if (res.code == '0000' && res.data.url) {
                //     window.open(res.data.url + `&refer=${window.location.href}`)
                //   }
              });
          }
        } else {
          that.isLoading = false;
          that.$message.error(
            "该资源不支持直接备课，请在资源详细内容中选择需要的部分选用备课"
          );
        }
      } else {
        that.messageModal();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.gjjs-detail {
  padding-bottom: 30px;
  .spin-con {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-ber {
    width: 100%;
    height: 400px;
    // background: #000;

    background-size: cover;
    background-position: center;
    &.jdgj {
      background-image: url("../../assets/image/gjjs/index/nav-xsgj/xsgj-banner.png");
    }
    &.jxsy {
      background-image: url("../../assets/image/gjjs/jxsy/jxsy-banner.png");
    }
    &.jxshany {
      background-image: url("../../assets/image/gjjs/jxshany/jxshany-banner.png");
    }
  }
  .detail-con {
    width: 1200px;
    padding-top: 22px;
    margin: 0 auto;
    .bread-crumbs {
      width: 352px;
      height: 40px;
      background: linear-gradient(90deg, #fff0f1 0%, #ffffff 100%);
      display: flex;
      align-items: center;
      padding-left: 18px;
      .crumbs {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #727272;
        cursor: pointer;
        &:hover {
          color: #bb0007;
          font-weight: bold;
        }
        &.active {
          color: #bb0007;
          font-weight: bold;
        }
        // &:nth-child(n + 2) {
        //   &::before {
        //     content: ">>";
        //     display: inline-block;
        //     margin: 0 10px;
        //   }
        // }
      }
    }
    .detail-tit {
      margin-top: 22px;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      text-align: center;
      margin-bottom: 10px;
    }
    .article {
      position: relative;
    }
    ::v-deep.detail-info {
      padding: 0 162px;
      p {
        text-indent: 2em;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #454545;
        line-height: 42px;
        margin-top: 30px;
        .tcplayer {
          width: 70%;
          margin: 0 auto;
          height: 400px;
        }
        img {
          max-width: 100%;
          display: block;
          margin: 0 auto;
        }
        strong {
          text-indent: 0;
        }
      }
    }
    .page-change {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .page-next,
      .page-prev {
        width: 560px;
        height: 50px;
        cursor: pointer;
        background: #f9f9f9;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 50px;
        &.not {
          opacity: 0;
          cursor: unset;

        }
      }
    }
  }
  .disclaimer {
    padding: 25px;
    margin: 42px auto;
    width: 1200px;
    height: 316px;
    background: #f9f9f9;
    .title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .disclaimer-item {
      margin-top: 10px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6e6d6d;
      line-height: 26px;
    }
  }
}
.element-beike-box {
  // position: fixed;
  position: absolute;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.15);
  z-index: 200;
  width: 100px;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  color: #333;
  text-align: center;
  cursor: pointer;
  display: block;
  &:hover {
    color: #121212;
  }
}
</style>
