<template>
  <div>
    <div class="course-all-box">
      <div class="header_box"></div>
    </div>
    <div class="content">
      <div
        class="ztyx-content"
        v-if="xxzyRouterList.some((item) => item.id == 60)"
      >
        <div class="content-title">
          <div class="title-class">
            <div class="title">
              <span style="color: #df283b">思政</span>专题研修
            </div>
            <div class="label-list">
              <div
                @click="getszztyxlist('')"
                :class="['label-item', { active: szlabelId == '' }]"
              >
                全部
              </div>
              <div
                :class="['label-item', { active: szlabelId == item.id }]"
                v-for="item in szztyxlabellist"
                :key="item.id"
                @click="getszztyxlist(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div
            class="more"
            @click="$router.push({ path: `/${school_code}/zy/xxzyk/szztyx` })"
          >
            更多
          </div>
        </div>
        <div class="content-info">
          <div
            class="info-item"
            v-for="(item, ind) in szztyxlist"
            :key="ind"
            @click="goToMaterialDetail(item)"
          >
            <div class="item-img">
              <div :style="`background-image: url('${item.photo}')`"></div>
            </div>
            <div class="item-name">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="ztyx-content"
        v-if="xxzyRouterList.some((item) => item.id == 6)"
      >
        <div class="content-title">
          <div class="title-class">
            <div class="title">
              <span style="color: #df283b">课程思政</span>专题研修
            </div>
            <div class="label-list">
              <div
                @click="getCourselist('')"
                :class="['label-item', { active: kcszlabelId == '' }]"
              >
                全部
              </div>
              <div
                :class="['label-item', { active: kcszlabelId == item.id }]"
                v-for="item in labelList"
                :key="item.id"
                @click="getCourselist(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div
            class="more"
            @click="$router.push({ path: `/${school_code}/zy/xxzyk/kcszzt` })"
          >
            更多
          </div>
        </div>
        <div class="content-info">
          <div
            class="info-item"
            v-for="(item, ind) in courselist"
            :key="ind"
            @click="goToMaterialDetail(item)"
          >
            <div class="item-img">
              <div :style="`background-image: url('${item.photo}')`"></div>
            </div>
            <div class="item-name">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="ztyx-content"
        v-if="xxzyRouterList.some((item) => item.id == 362)"
      >
        <div class="content-title">
          <div class="title-class">
            <div class="title">
              <span style="color: #df283b">美育</span>专题研修
            </div>
          </div>
          <div
            class="more"
            @click="$router.push({ path: `/${school_code}/zy/xxzyk/myztyx`})"
          >
            更多
          </div>
        </div>
        <div class="content-info">
          <div
            class="info-item"
            v-for="(item, ind) in myztyxList"
            :key="ind"
            @click="goToMaterialDetail(item)"
          >
            <div class="item-img">
              <div :style="`background-image: url('${item.photo}')`"></div>
            </div>
            <div class="item-name">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="zyjnyc-contentbg"
        v-if="xxzyRouterList.some((item) => item.id == 2)"
      >
        <div class="zyjnyc-content">
          <div class="content-title">
            <div class="title">
              <span style="color: #df283b">专业技能</span>养成
            </div>
            <div
              class="more"
              @click="$router.push({ path: `/${school_code}/zy/xxzyk/zyjnyc` })"
            >
              更多
            </div>
          </div>
          <div class="content-info">
            <div
              class="info-item"
              v-for="item in jnycList"
              :key="item.id"
              @click="goToClassDetail(item)"
            >
              <div class="item-img">
                <div
                  :style="`background-image: url('${item.list_photo}')`"
                ></div>
              </div>
              <div class="item-name">
                {{ item.name }}
              </div>
              <div class="item-label">专业技能养成</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="syts-contentbg"
        v-if="
          xxzyRouterList.some((item) => item.id == 55) ||
          xxzyRouterList.some((item) => item.id == 86)
        "
      >
        <div class="syts-content">
          <div
            class="content-title"
            v-if="xxzyRouterList.some((item) => item.id == 55)"
          >
            <div class="title">
              <span style="color: #df283b">素养</span>提升
            </div>
            <div
              class="more"
              @click="$router.push({ path: `/${school_code}/zy/xxzyk/rwsy` })"
            >
              更多
            </div>
          </div>
          <div
            class="syts-swipers"
            v-if="xxzyRouterList.some((item) => item.id == 55)"
          >
            <div class="syts-item">
              <xxzySwiper
                v-if="lssyList && lssyList.length > 0"
                :list="lssyList"
                :btnval="'lssy'"
              ></xxzySwiper>
              <div
                v-else
                class="img-not"
                style="width: 374px; height: 168px"
              ></div>
              <div class="syts-title">历&nbsp;史&nbsp;视&nbsp;野</div>
            </div>
            <div class="syts-item">
              <xxzySwiper
                :list="whpgList"
                :btnval="'whpg'"
                v-if="whpgList && whpgList.length > 0"
              ></xxzySwiper>
              <div
                v-else
                class="img-not"
                style="width: 374px; height: 168px"
              ></div>
              <div class="syts-title">文&nbsp;化&nbsp;品&nbsp;格</div>
            </div>
            <div class="syts-item">
              <xxzySwiper
                :list="yssmList"
                :btnval="'yssm'"
                v-if="yssmList && yssmList.length > 0"
              ></xxzySwiper>
              <div
                v-else
                class="img-not"
                style="width: 374px; height: 168px"
              ></div>
              <div class="syts-title">艺&nbsp;术&nbsp;审&nbsp;美</div>
            </div>
          </div>
          <div
            class="content-title"
            v-if="xxzyRouterList.some((item) => item.id == 86)"
          >
            <div class="title">
              <span style="color: #df283b">教师</span>发展
            </div>
            <div
              class="more"
              @click="$router.push({ path: `/${school_code}/zy/xxzyk/jsfz` })"
            >
              更多
            </div>
          </div>
          <div
            class="jsfz-content"
            v-if="xxzyRouterList.some((item) => item.id == 86)"
          >
            <div
              class="jsfz-item"
              v-for="(item, ind) in jsfzImgUrls"
              :key="ind"
              @click="$router.push({ path: `/${school_code}/zy/xxzyk/jsfz` })"
            >
              <div :style="`background-image: url(${item.img_path});`"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="zjsj-contentbg"
        v-if="xxzyRouterList.some((item) => item.id == 87)"
      >
        <div class="zjsj-content">
          <div class="content-title">
            <div class="title">
              <span style="color: #df283b">职教</span>世界
            </div>
            <div
              class="more"
              @click="$router.push({ path: `/${school_code}/zy/xxzyk/zjsj` })"
            >
              更多
            </div>
          </div>
          <div class="zjsj-info">
            <div
              class="zjsj-item"
              :style="`background-image: url('${require('../../assets/image/xxzyk/index/ind-zcjd.png')}');`"
              @click="$router.push({ path: `/${school_code}/zy/xxzyk/zjsj` })"
            ></div>
            <div
              class="zjsj-item"
              :style="`background-image: url('${require('../../assets/image/xxzyk/index/ind-zjzg.png')}');`"
              @click="$router.push({ path: `/${school_code}/zy/xxzyk/zjsj` })"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xxzySwiper from "../../components/xxzySwiper.vue";
import { Button, spin } from "ant-design-vue";
import axios from "axios";
import services from "../../assets/js/services";

import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "xxzykClass",
  components: {
    xxzySwiper,
  },
  data() {
    return {
      labelList: [],
      school_code: localStorage.getItem("schoolCode"),
      ztyxId: 6,
      courselist: [],
      szlabelId: "",
      kcszlabelId: "",
      jnycList: [],
      lssyList: [],
      whpgList: [],
      szztyxlist: [],
      szztyxlabellist: [],
      yssmList: [],
      myztyxList: [],
      jsfzImgUrls: [
        { img_path: require("../../assets/image/xxzyk/index/ind-sdsf.png") },
        { img_path: require("../../assets/image/xxzyk/index/ind-jxyr.png") },
        { img_path: require("../../assets/image/xxzyk/index/ind-zyjk.png") },
        { img_path: require("../../assets/image/xxzyk/index/ind-zyly.png") },
      ],
      imgUrl: "../../assets/image/jsckzyxy/empty.png",
    };
  },
  created() {
    this.$nextTick(async () => {
      if (!this.schoolMenu) {
        await this.getSchoolMenuStatus(this.school_code);
      }
      if (!this.schoolMenu || !this.schoolMenu.open_xxzyk) {
        this.$router.push(`/${this.school_code}/zy`);
      }
    });
    this.getszztyxlist("");
    this.getszztyxLabelLIst();
    this.getLabelLIst();
    this.getCourselist("");
    this.getkcszList();
    this.getlssyList();
    this.getwhpgList();
    this.getyssmList();
    this.getmyztyx()
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin", "getSchoolMenuStatus"]),
    ...mapMutations(["setDownloadIdList"]),
    videoCloseMadol() {
      // console.log(e);
      this.videoVisible = false;
      this.player.dispose();
      this.player = null;
    },
    // 获取思政专题研修列表
    async getszztyxlist(tag_id) {
      this.szlabelId = tag_id;
      try {
        let res = await services.getszztyxCourse({
          school_code: this.school_code,
          category_id: 60,
          page: 1,
          size: 8,
          tag_id,
          is_rand: 1,
        });
        if (res.code == "0000" && res.data.items.length > 0) {
          this.szztyxlist = res.data.items;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //获取思政专题研修标签
    async getszztyxLabelLIst() {
      try {
        let res = await services.getszztyxLabelLIst({
          school_code: this.school_code,
          category_id: 60,
        });

        if (res.code == "0000" && res.data.length > 0) {
          this.szztyxlabellist = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //专题研修
    async getLabelLIst() {
      try {
        let res = await services.getszztyxLabelLIst({
          school_code: this.school_code,
          category_id: 6,
        });

        if (res.code == "0000" && res.data.length > 0) {
          this.labelList = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取课程思政专题研修列表
    async getCourselist(tag_id) {
      this.kcszlabelId = tag_id;
      try {
        let res = await services.getszztyxCourse({
          school_code: this.school_code,
          category_id: 6,
          page: 1,
          size: 8,
          tag_id,
          is_rand: 1,
        });
        if (res.code == "0000" && res.data.items.length > 0) {
          this.courselist = res.data.items;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //美育专题研修
    async getmyztyx() {
      try {
        let res = await services.getszztyxCourse({
          page: 1,
          size: 8,
          school_code: this.school_code,
          category_id: 362,
          tag_id: "",
          is_rand: 1,
        });
        if (res.code == "0000" && res.data.items && res.data.items.length > 0) {
          this.myztyxList = res.data.items;
        }
      } catch (error) {}
    },
    //专业技能养成
    async getkcszList() {
      try {
        let res = await services.getkcszList({
          page: 1,
          size: 8,
          school_code: this.school_code,
          category_id: 2,
        });
        if (res.code == "0000" && res.data.items.length > 0) {
          this.jnycList = res.data.items;
        }
      } catch (error) {}
    },
    async getlssyList() {
      try {
        let res = await services.getkcszList({
          school_code: this.school_code,
          size: 100,
          page: 1,
          category_id: 57,
          // category_id: 2,
        });
        if (res.code == "0000" && res.data.items && res.data.items.length > 0) {
          this.lssyList = res.data.items;
        }
      } catch (error) {}
    },
    async getwhpgList() {
      try {
        let res = await services.getkcszList({
          school_code: this.school_code,
          size: 100,
          page: 1,
          category_id: 96,
          // category_id: 2,
        });
        if (res.code == "0000" && res.data.items && res.data.items.length > 0) {
          this.whpgList = res.data.items;
        }
      } catch (error) {}
    },
    async getyssmList() {
      try {
        let res = await services.getkcszList({
          school_code: this.school_code,
          size: 100,
          page: 1,
          category_id: 58,
          // category_id: 2,
        });

        if (res.code == "0000" && res.data.items && res.data.items.length > 0) {
          this.yssmList = res.data.items;
        }
      } catch (error) {}
    },
    goToMaterialDetail(item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        
        window.open(
          `${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.course_id}&cm_id=${item.id}`
        );
      });
    },
    goToClassDetail(item) {
      this.goAppsDetailsBeforeLogin().then(() => {
        window.open(
          `${services.XUEXI_JIAOYU}/school/${this.school_code}/Purchase/SubjectsRue/seeCourse?id=${item.id}&category_name=${item.name}&cm_id=${item.id}`
        );
      });
    },
  },
  computed: {
    ...mapState({
      downloadList: (state) => state.class.downloadIdList,
      xxzyRouterList: (state) => state.class.xxzyRouterList,
      schoolMenu: (state) => state.appsLogin.schoolMenu,
    }),
  },
};
</script>

<style lang="less" scoped>
.bg-box {
  background-color: #fff;
  width: 100%;
  .course-all-box {
    padding: 0 0 16px;
    background-color: #fff;
    .header_box {
      height: 140px;
      background: url("../../assets/image/xxzyk/head-bg.png") no-repeat 50%;
      width: 100%;
      background-size: cover;
      margin-bottom: 24px;
    }
  }
  .content {
    .ztyx-content {
      width: 1200px;
      margin: 0 auto;
      // background: indianred;
      .content-title {
        display: flex;
        justify-content: space-between;
        .title-class {
          display: flex;
          .title {
            line-height: 32px;
            color: #383838;
            font-size: 22px;
            font-weight: bold;
            margin-right: 14px;
          }
          .label-list {
            display: flex;
            .label-item {
              margin-left: 10px;
              cursor: pointer;
              padding: 0 20px;
              line-height: 30px;
              height: 32px;
              font-size: 14px;
              font-weight: 400;
              color: #383838;
              background: #ffffff;
              border: 1px solid #e4e3e3;
              border-radius: 16px;
              &.active {
                color: #fff;
                border: #e64757 1px solid;
                background: #e64757;
              }
            }
            .label-item:hover {
              color: #fff;
              border: #e64757 1px solid;
              background: #e64757;
            }
          }
        }
        .more {
          color: #e64757;
          line-height: 30px;
          cursor: pointer;
        }
      }
      .content-info {
        margin-top: 28px;
        display: flex;
        flex-wrap: wrap;
        .info-item {
          cursor: pointer;
          width: 270px;
          background: #ffffff;
          border: 1px solid #e4e3e3;
          margin-left: 40px;
          margin-bottom: 24px;
          &:nth-child(4n + 1) {
            margin-left: 0;
          }
          &:hover .item-img div {
            transform: scale(1.1);
          }
          .item-img {
            height: 152px;
            width: 100%;
            background: #e4e3e3;
            overflow: hidden;
            div {
              width: 100%;
              height: 100%;
              transition: all 0.5s;
              background-size: cover;
              background-position: center;
            }
          }
          .item-name {
            // height: 64px;
            margin: 12px 12px 12px 14px;
            // width: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #3a3a3a;
            //超出两行省略号
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            -webkit-line-clamp: 2; //显示几行
            line-height: 20px;
          }
        }
      }
    }
    .zyjnyc-contentbg {
      height: 670px;
      background-image: url("../../assets/image/xxzyk/index/jnyc-bg.png");
      background-size: cover;
      .zyjnyc-content {
        width: 1200px;
        margin: 0 auto;
        padding-top: 24px;
        .content-title {
          display: flex;
          justify-content: space-between;
          .title {
            line-height: 30px;
            color: #383838;
            font-size: 20px;
            font-weight: bold;
            margin-right: 14px;
          }
          .more {
            color: #e64757;
            line-height: 30px;
            cursor: pointer;
          }
        }
        .content-info {
          // margin-bottom: 12px;
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          .info-item {
            width: 270px;
            border: 1px solid #ececec;
            background: #fff;
            margin-left: 40px;
            cursor: pointer;
            margin-bottom: 24px;
            &:nth-child(4n + 1) {
              margin-left: 0;
            }
            &:hover .item-img div {
              transform: scale(1.1);
            }
            .item-img {
              width: 100%;
              height: 152px;
              // background: #be7f6e;
              overflow: hidden;
              div {
                width: 100%;
                height: 100%;
                transition: all 0.5s;
                background-size: cover;
                background-position: center;
              }
            }
            .item-name {
              margin: 12px 14px 14px 14px;
              font-size: 16px;
              font-weight: 400;
              color: #3a3a3a;
              line-height: 20px;
              height: 40px;
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              line-clamp: 2;
              -webkit-line-clamp: 2; //显示几行
            }
            .item-label {
              margin-left: 14px;
              margin-bottom: 16px;
              font-size: 14px;
              font-weight: 400;
              color: #b4b4b4;
            }
          }
        }
      }
    }
    .syts-contentbg {
      background-image: url("../../assets/image/xxzyk/index/syts-bg.png");
      // height: 650px;
      padding-bottom: 34px;
      background-size: cover;
      .syts-content {
        width: 1200px;
        padding-top: 28px;
        margin: 0 auto;
        .content-title {
          display: flex;
          justify-content: space-between;
          .title {
            line-height: 30px;
            color: #383838;
            font-size: 20px;
            font-weight: bold;
            margin-right: 14px;
          }
          .more {
            color: #e64757;
            line-height: 30px;
            cursor: pointer;
          }
        }
        .syts-swipers {
          margin-top: 22px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          .syts-item {
            width: 374px;
            height: 230px;
            // background: #383838;
            // margin-left: 39px;
            // &:first-child {
            //   margin-left: 0;
            // }
            .syts-title {
              width: 374px;
              height: 48px;
              margin-top: 8px;
              background-image: url("../../assets/image/xxzyk/index/syts-title.png");
              background-size: 100%;
              font-size: 18px;
              text-align: center;
              font-weight: bold;
              color: #ffffff;
              line-height: 48px;
              text-shadow: 0px 0px 16px rgba(147, 124, 97, 0.38);
            }
          }
        }
        .jsfz-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 24px;
          height: 212px;
          background-image: url("../../assets/image/xxzyk/index/jsfz-bg.png");
          background-size: 100% auto;
          .jsfz-item {
            width: 272px;
            cursor: pointer;
            height: 154px;
            border: 2px solid #a69b8b;
            overflow: hidden;
            &:hover div {
              transform: scale(1.1);
            }
            div {
              width: 100%;
              height: 100%;
              transition: all 0.5s;
              background-size: 100% auto;
              background-position: center;
            }
          }
        }
      }
    }
    .zjsj-contentbg {
      background-image: url("../../assets/image/xxzyk/index/zjsj-bg.png");
      background-size: 100% auto;
      .zjsj-content {
        width: 1200px;
        margin: auto;
        padding-top: 26px;
        padding-bottom: 38px;
        .content-title {
          display: flex;
          justify-content: space-between;
          .title {
            line-height: 30px;
            color: #383838;
            font-size: 20px;
            font-weight: bold;
            margin-right: 14px;
          }
          .more {
            color: #e64757;
            line-height: 30px;
            cursor: pointer;
          }
        }
        .zjsj-info {
          display: flex;
          justify-content: space-between;
          margin-top: 22px;
          .zjsj-item {
            cursor: pointer;
            width: 590px;
            height: 140px;
            background: #b4b4b4;
            background-size: 100% auto;
            background-position: center;
          }
        }
      }
    }
  }
}
.img-not {
  background-image: url("../../assets/image/jsckzyxy/empty.png");
  background-size: 100% 100%;
}
</style>