<template>
  <div>
    <WhrjHeader />
    <div class="sdcm-container-box">
      <div class="content-box w1280">
        <div class="left-sidebar">
          <div :class="['side-bar',position?'position':'']" ref="sideBar">
            <div class="header-box"></div>
            <div class="body-box">
              <div :class="['item',currentIndex==index?'active':'']" v-for="(item,index) in menuData" :key="index" @click="currentSideBar(index,item)">
                <div class="star"></div>
                <div class="text">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-content">
          <!-- <template v-for="(item,index) in listData">
            <div class="item" :key="index" v-if="index<1">
              <p @click="goDetail(item)">{{item.title}}</p>
              <span>{{item.issueTime}}</span>
            </div>
          </template> -->
          <div class="login-mask" v-if="(!appsUserInfo || !appsUserInfo.id) && currentIndex == 1 ">
            <div class="login-btn" @click="loginCloseMask">
              登陆后查看 >>
            </div>
          </div>
          <template v-else>
            <template v-if="listData && listData.length>0">
              <template v-for="(item,index) in listData">
                <div class="item" :key="index" @click="goDetail(item)">
                  <div class="left-date">
                    <div class="date-year-month">{{item.issueTime.substring(5,10)}}</div>
                    <div class="date-day">{{item.issueTime.substring(0,4)}}</div>
                  </div>
                  <!-- <div class="middle-cover" v-if="item.thumb_image" :style="`background-image:url(${item.thumb_image})`"></div> -->
                  <div class="right-desc">
                    <div class="title">{{item.title}}</div>
                    <div class="intro" v-html="item.description"></div>
                  </div>
                </div>
              </template>
            </template>
            <div v-else class="no-content">
              <div>待提供内容</div>
            </div>
            <div style="text-align: center; padding-top:20px" v-if="listData&&listData.length>0">
              <a-pagination v-model="realyPage" :show-total="total => `共${allPages}页`" :total="total" @change="onPagesChange" />
            </div>
          </template>
          

        </div>
      </div>
    </div>
    <WhrjFooter :color="'#3E140B'" />
  </div>
</template>

<script>
import WhrjHeader from "../../components/WhrjHeader.vue"
import WhrjFooter from "../../components/WhrjFooter"
import services from "../../assets/js/services"
import { mapActions, mapState } from "vuex"
// import sdcmData from "./sdcmData"
export default {
  data () {
    return {
      school_code: localStorage.getItem("schoolCode"),
      listData: [],
      paramsName: "wr_dxal/xwdxal",
      page: 0,
      allPages: 0,
      size: 10,
      total: 0,
      realyPage: 1,
      currentIndex: 0,
      position: false,
      menuData: [
        // { name: "典型案例", path: "wr_dxal", id: 14682 }, //校内典型案例需要登录
        { name: "校外典型案例", path: "wr_dxal/xwdxal", id: 14684 },
        { name: "校内典型案例", path: "wr_dxal/xndxal", id: 14683 }, //校内典型案例需要登录
      ],
    }
  },
  components: {
    WhrjHeader,
    WhrjFooter
  },
  computed: {
    ...mapState({
      appsUserInfo: state => state.appsLogin.appsUserInfo
    }),
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll)
    })
    this.handleScroll()
    if (this.$route.query.select && this.$route.query.paramsName) {
      let params = { path: this.$route.query.paramsName }
      this.currentSideBar(Number(this.$route.query.select), params)
    } else {
      this.getList()
    }
  },
  methods: {
    ...mapActions(['goAppsDetailsBeforeLogin', 'getAppsLoginUserInfo']),
    loginCloseMask () {
      this.goAppsDetailsBeforeLogin()
    },
    goDetail (item) {
      window.open(`/${this.school_code}/whrjgczyxy/detail/${item.id}?flag=dxal&url=${item.url}&index=${this.currentIndex}`)
    },
    getList () {
      this.listData = []
      var time = Date.parse(new Date());
      services.getWhrjDxalList({ name: this.paramsName, page: this.page, time }).then(res => {
        this.listData = res.info
        this.total = res.pageNum * 10
        this.allPages = res.pageNum
      })
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      this.position = false
    },
    onPagesChange (val) {
      this.realyPage = val
      this.page = val - 1
      this.getList()
    },
    currentSideBar (index, item) {
      this.currentIndex = index
      this.paramsName = item.path
      this.page = 0
      this.realyPage = 1
      this.getList()
    },

    handleScroll () {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset;
      if (scrollTop <= 1) {
        document.body.scrollTop = 1
        document.documentElement.scrollTop = 1
        window.pageYOffset = 1
      } else {
        if (scrollTop >= 218) {
          this.position = true
        } else {
          this.position = false
        }
      }
    },
  },
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll)
  },
}
</script>

<style lang="less" scoped>
* {
  transition: all 0.3s;
}
.w1280 {
  width: 1280px;
  margin: 0 auto;
}
.sdcm-container-box {
  background-image: url('../../assets/image/sdcmzyxy/zcwj-body.png');
  background-size: cover;
  padding: 22px 0 44px;

  .content-box {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 0 39px 0 43px;
    position: relative;
    min-height: 700px;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 203px;
      left: 0;
      background-size: cover;
      width: 637px;
      height: 563px;
      background-image: url('../../assets/image/sdcmzyxy/zcwj-bg.png');
      z-index: 1;
    }
  }
  .left-sidebar {
    width: 264px;
    height: 125px;
    background-size: cover;
    z-index: 10;
  }
  .side-bar {
    z-index: 10;
    &.position {
      position: fixed;
    }
    width: 264px;
    float: left;
    top: 0px;
    .header-box {
      height: 123px;
      background-image: url('../../assets/image/whrjgczyxy/dxal.png');
      background-size: cover;
    }
    .body-box {
      padding-left: 38px;
      .item {
        cursor: pointer;
        width: 226px;
        height: 60px;
        line-height: 60px;
        background-color: #fff;
        border-bottom: 1px solid #f3f3f3;
        padding: 0 14px;
        display: flex;
        align-items: center;
        .star {
          width: 18px;
          height: 17px;
          background-image: url('../../assets/image/sdcmzyxy/slidebar/star.png');
          background-size: cover;
          margin-right: 8px;
        }
        .text {
          font-size: 17px;
          color: #262626;
        }
        &.active {
          background: #c00800;
          border: none;
          .star {
            background-image: url('../../assets/image/sdcmzyxy/slidebar/star-active.png');
          }
          .text {
            color: #fff;
          }
        }
      }
    }
  }
  .right-content {
    z-index: 10;
    padding-top: 22px;
    flex: 1;
    padding-left: 70px;
    .item {
      padding: 28px 0;
      border-bottom: 1px solid #f4f4f4;
      display: flex;
      cursor: pointer;
      &:hover {
        .right-desc {
          .title {
            color: #931a14;
          }
        }
      }
      .left-date {
        margin-right: 32px;
        .date-year-month {
          width: 70px;
          height: 24px;
          background: #9b2020;
          line-height: 24px;
          text-align: center;
          font-size: 16px;
          color: #ffffff;
        }
        .date-day {
          width: 70px;
          height: 30px;
          background: #fff6f6;
          line-height: 30px;
          text-align: center;
          font-size: 16px;
          color: #840000;
        }
      }
      .middle-cover {
        background-size: cover !important;
        width: 200px;
        height: 113px;
        border-radius: 2px;
        background-position: center;
        margin-right: 14px;
        &::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: rgba(14, 14, 14, 8%);
        }
      }
      .right-desc {
        flex: 1;
        width: 0;
        display: flex;
        flex-direction: column;
        margin-top: -3px;
        .title {
          font-size: 18px;
          color: #262626;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 20px;
        }
        .intro {
          font-size: 14px;
          color: #acacac;
          max-height: 42px;
          ::v-deep p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
    .login-mask {
        position: absolute;
        width: 683px;
        height: 319px;
        z-index: 100;
        background-color: #fff;
        background-image: url('../../assets/image/sdcmzyxy/needLogin.png');
        background-size: cover;
        top: 106px;
        right: 134px;
        .login-btn {
          width: 158px;
          height: 38px;
          text-align: center;
          line-height: 38px;
          background: #ae0000;
          border-radius: 19px;
          cursor: pointer;
          font-size: 16px;
          color: #ffffff;
          margin: 36px auto 0;
          font-weight: 600;
        }
      }
  }
}

.no-content {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 500px;
  font-size: 14px;
  color: #a2a2a2;
  transition: all 0s;
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
  }
}
</style>