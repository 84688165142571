<template>
  <div class="nav-jdgj">
    <div class="resize-con">
      <div class="jdgj-tit"></div>
      <div class="jdgj-contet">
        <div class="left-content">
          <div class="video-content">
            <div class="video-box">
              <video
                :id="`v-${videoFileid}`"
                :data-fileid="videoFileid"
                :data-appid="videoAppid"
                playsinline
                webkit-playsinline
              ></video>
            </div>
            <div class="video-tit">{{ videoName }}</div>
          </div>
        </div>
        <div class="right-content">
          <div class="xl-title">系列解读</div>
          <div class="video-list" v-if="jdgjList.length > 0">
            <swiper :options="videoSwper">
              <swiperSlide v-for="(item, ind) in jdgjList" :key="ind">
                <div
                  class="jdgj-item"
                  v-for="(ele, index) in item"
                  :key="index"
                  :style="{
                    backgroundImage: ele.cover ? `url(${ele.cover})` : '',
                  }"
                  @click="toDetail(ele)"
                ></div>
              </swiperSlide>
            </swiper>
            <div class="swiper-pagination jdgj"></div>
            <div class="swiper-button-next jdgj"></div>
            <div class="swiper-button-prev jdgj"></div>
          </div>
        </div>
      </div>
      <div class="get-more" @click="toMoreList">
        <div class="more-icon"></div>
        <div class="more-text">查 看 更 多</div>
      </div>
    </div>
  </div>
</template>

<script>
import services from "../../../assets/js/services.js";
import utils from "../../../assets/js/utils";
import { mapActions } from "vuex";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      videoSwper: {
        direction: "vertical",
        // spaceBetween: 20,
        // slidesPerView: 3,
        navigation: {
          // 前进后退按钮 ,不同版本配置方式不同
          nextEl: `.swiper-button-next.jdgj`,
          prevEl: `.swiper-button-prev.jdgj`,
        },
        pagination: {
          el: `.swiper-pagination.jdgj`,
        },
      },
      jdgjList: [],
      videoAppid: "",
      videoFileid: "",
      videoName: "",
    };
  },
  created() {
    this.getjdgjList();
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    async getjdgjList() {
      // let that = this
      let res = await services.getAreaSzztkLists({
        page: 1,
        length: 9,
        count: 0,
        school_code: this.school_code,
        special_id: this.specialId,
        kind: "1",
        column_name: "解读工匠",
      });
      if (res.code == "0000" && res.data.items.length > 0) {
        // this.jdgjList = res.data.items;
        let num = Math.ceil(res.data.items.length / 3) - 1;
        for (let i = 0; i <= num; i++) {
          if (i == num) {
            this.jdgjList.push(res.data.items.slice(i * 3));
          } else {
            this.jdgjList.push(res.data.items.slice(i * 3, i * 3 + 3));
          }
        }
        let firstVideo = res.data.items.find(
          (item) => item.tencent_vod_app_id && item.tencent_vod_file_id
        );
        this.videoAppid = firstVideo.tencent_vod_app_id;
        this.videoFileid = firstVideo.tencent_vod_file_id;
        this.videoName = firstVideo.title;
        this.addEventMethod();
      }
    },
    addEventMethod() {
      let that = this;
      that.$nextTick(() => {
        let videoElement = document.querySelectorAll("video"); // 获取页面上所有的video对象
        if (videoElement && videoElement.length > 0) {
          for (let i = 0; i < videoElement.length; i++) {
            let appid = videoElement[i].getAttribute("data-appid");
            let domid = videoElement[i].getAttribute("id");
            let fileid = videoElement[i].getAttribute("data-fileid");
            if (appid && domid && fileid) {
              that.initVideoFile(domid, fileid, appid);
            }
          }
        }
      });
    },
    initVideoFile(elemId, qclude_id, appId) {
      utils.loadScript(
        window.location.origin + "/vod/hls.min.0.12.4.js",
        () => {
          utils.loadScript(
            window.location.origin + "/vod/tcplayer.min.js",
            () => {
              if (typeof window.TCPlayer === "function") {
                window.TCPlayer(elemId, {
                  // player-container-id 为播放器容器ID，必须与html中一致
                  fileID: qclude_id, // 请传入需要播放的视频filID 必须
                  // fileID: "5285890807937512700",
                  controls: true,
                  muted: true,
                  // playbackRates: [0.5, 1, 1.25, 1.5, 2],
                  appID: appId, // 请传入点播账号的appID 必须
                  // width: utils.isMobile() ? 355 : 720, //视频的显示宽度
                  // height: utils.isMobile() ? 300 : 403, //视频的显示高度
                  definition: 30,
                  autoplay: false,
                  plugins: {
                    // ContinuePlay: {
                    // 	// 开启续播功能
                    // 	auto: true, //[可选] 是否在视频播放后自动续播
                    // 	text: "上次播放至 ", //[可选] 提示文案
                    // 	btnText: "恢复播放", //[可选] 按钮文案
                    // },
                  },
                  // controlBar: {
                  //   playbackRateMenuButton: false
                  // }
                });
              }
            }
          );
        }
      );
    },
    toDetail(item) {
      if (item) {
        this.goAppsDetailsBeforeLogin().then(() => {
          let routeUrl = this.$router.resolve({
            path: `/${this.school_code}/gjjs/detail`,
            query: {
              id: item.id,
              column_id: item.column_id,
              columnPath: "jdgj",
            },
          });
          window.open(routeUrl.href, "_blank");
        });
      }
    },
    toMoreList() {
      this.goAppsDetailsBeforeLogin().then(() => {
        let routeUrl = this.$router.resolve({
          path: `/${this.school_code}/gjjs/jdgj?ind=1`,
        });
        window.open(routeUrl.href, "_blank");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.nav-jdgj {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 139px;
  background-image: url("../../../assets/image/gjjs/index/nav-index/nav-xsgj-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  .resize-con {
    position: absolute;
    left: 308px;
    top: 50%;
    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 468px;
      height: 548px;
      top: 43px;
      left: -246px;
      z-index: 0;
      background-image: url("../../../assets/image/gjjs/index/nav-xsgj/gjjs-sidber.png");
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
    }

    .jdgj-tit {
      width: 328px;
      height: 100px;
      background-image: url("../../../assets/image/gjjs/index/nav-jdgj/jdgj-title.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin: 0 auto;
      // margin-left: 698px;
    }
    .jdgj-contet {
      width: 1100px;
      height: 630px;
      margin-top: 43px;
      // margin-left: 308px;
      position: relative;
      z-index: 1;
      // margin-top: 53px;
      background: #ccc;
      box-shadow: 0px 11px 26px 4px rgba(168, 141, 147, 0.68);
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      .left-content {
        width: 766px;
        height: 100%;
        background: linear-gradient(0deg, #fccf94 0%, #ffefd8 100%);
        padding: 46px 20px;
        .video-content {
          width: 100%;
          height: 100%;
          background-image: url("../../../assets/image/gjjs/index/nav-jdgj/video-top-icon.png");
          background-size: 100% auto;
          background-repeat: no-repeat;
          padding-top: 24px;
          .video-box {
            width: 726px;
            height: 460px;
            background: #b81b1a;
            padding: 26px 0;
            .tcplayer {
              width: 100% !important;
              height: 100% !important;
            }
            video {
              width: 100%;
              height: 100%;
            }
          }
          .video-tit {
            width: 726px;
            height: 54px;
            background-image: url("../../../assets/image/gjjs/index/nav-jdgj/video-tit.png");
            background-size: cover;
            background-repeat: no-repeat;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffe4b6;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px 0px 12px 12px;
          }
        }
      }
      .right-content {
        background-color: #fff;
        flex: 1;
        height: 100%;
        padding: 9px 32px;
        .xl-title {
          height: 9.5%;
          width: 100%;
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #bb0612;
          display: flex;
          align-items: center;
        }
        .video-list {
          width: 100%;
          height: 90.5%;
          position: relative;
          .swiper-container {
            height: 100%;
            .swiper-wrapper {
              height: 100%;
              .swiper-slide {
                cursor: pointer;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .jdgj-item {
                  background-position: center;
                  background-image: url("../../../assets/image/gjjs/index/nav-index/default-bg.png");
                  background-size: cover;
                  background-repeat: no-repeat;
                  width: 100%;
                  height: 170px;
                }
              }
            }
          }
          .swiper-button-next {
            width: 20px;
            height: 20px;
            background-image: url("../../../assets/image/gjjs/index/nav-jdgj/jsgj-next.png");
            background-repeat: no-repeat;

            opacity: 1;
            background-size: 10px 6px;
            border: 1px solid #d0524b;
            top: -19px;
            // transform: translateY(-70%);
            &:hover {
              background-color: #d0524b;
              background-image: url("../../../assets/image/gjjs/index/nav-jdgj/jdgj-next-hover.png");
            }
            right: 0;
          }
          .swiper-button-prev {
            width: 20px;
            height: 20px;
            background-image: url("../../../assets/image/gjjs/index/nav-jdgj/jdgj-prev.png");
            background-size: 10px 6px;
            opacity: 1;
            border: 1px solid #d0524b;
            top: -19px;
            left: unset;
            transform: translateX(-300%);
            background-repeat: no-repeat;

            right: 25px;

            &:hover {
              background-color: #d0524b;
              background-image: url("../../../assets/image/gjjs/index/nav-jdgj/jsgj-prev-hover.png");
            }
          }
          ::v-deep.swiper-pagination {
            position: absolute;
            top: -34px;
            width: 65px;
            display: flex;
            justify-content: space-evenly;
            right: 20px;
            .swiper-pagination-bullet {
              background-color: #D9D9D9;
              opacity: 1;
              &.swiper-pagination-bullet-active {
                background-color: #CB0F16;
                width: 7px !important;
              }
            }

          }
        }
      }
    }
    .get-more {
      display: flex;
      justify-content: center;
      margin-top: 22px;
      cursor: pointer;
      .more-icon {
         width: 42px;
        height: 24px;
        background-image: url("../../../assets/image/gjjs/index/nav-index/nav-more.png");
        background-size: cover;
        margin-right: 14px;
      }
      .more-text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ab030c;
      }
    }
  }
}


@media screen and (max-width: 1900px) and (min-width: 1700px) {
  .resize-con {
    transform: scale(0.9) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-height: 600px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .resize-con {
    transform: scale(0.84) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1550px) {
  .resize-con {
    transform: scale(0.8) translateY(-50%);
    transform-origin: 0 0;
  }
}

@media screen and (max-width: 1550px) {
  .resize-con {
    transform: scale(0.74) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1500px) {
  .resize-con {
    transform: scale(0.7) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1400px) {
  .resize-con {
    transform: scale(0.6) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1300px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (min-width: 1900px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
@media screen and (min-height: 1080px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
</style>