<template>
  <div class="thoughtParty">
    <div class="body">
      <SzztkHeader />

      <div class="body_box">
        <div class="header_banner">

        </div>
        <div class="main_box">
          <div class="mkssdd outer-box">
            <div class="content-box">
              <div class="header"></div>
              <div class="list-body">
                <div class="left-box">
                  <div class="item" @click="toSzztkDetail(list1[0].path)">
                    <div class="thumb" :style="`background:url(${list1[0].thumb})`"></div>
                    <div class="description">
                      <div class="title">{{list1[0].title}}</div>
                      <div class="desc">{{list1[0].desc}}</div>
                    </div>
                  </div>
                </div>
                <div class="right-box">
                  <div class="item" v-for="item,index in list1.slice(1,999)" :key="index" @click="toSzztkDetail(item.path)">
                    <div class="thumb" :style="`background:url(${item.thumb})`"></div>
                    <div class="bottom-text">
                      <div class="title">{{item.title}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="jlh outer-box">
            <div class="content-box">
              <div class="header"></div>
              <div class="body-box">
                <div class="notes">
                  <div :class="['item',item.type=='video'?'video':'audio']" v-for="item,index in list2.left" :key="index" @click="toSzztkDetail(item.path)">
                    {{item.title}}
                  </div>

                </div>
                <div class="notes">
                  <div :class="['item',item.type=='video'?'video':'audio']" v-for="item,index in list2.right" :key="index" @click="toSzztkDetail(item.path)">
                    {{item.title}}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="mkskp outer-box">
            <div class="content-box">
              <div class="header"></div>
              <div class="body-box">
                <div class="top-imgs">
                  <div class="item" :style="`background:url(${item.thumb})`" v-for="item,index in list3.slice(0,4)" :key="index" @click="toSzztkDetail(item.path)">
                  </div>
                </div>
                <div class="bottom-list">
                  <div class="item" v-for="item,index in list3.slice(4,999)" :key="index" @click="toSzztkDetail(item.path)">
                    {{item.title}}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import SzztkHeader from "../../components/szztkHeader.vue"
export default {
  name: "mks",
  components: {
    SzztkHeader,
  },

  data () {
    return {
      list1: [
        {
          thumb: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661619600&t=168c479af7dfac2a306437039f33ea95',
          title: "《马克思是对的》第一集：你好 马克思", desc: "这是一档有理论深度，有实践温度大型通俗理论对话节目，重温马克思的感人故事，汲取马克思主义的真理力量，探究马克思主义的当代价值和",
          path: "http://www.baidu.com"
        },


        {
          thumb: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661619600&t=168c479af7dfac2a306437039f33ea95',
          title: "《马克思是对的》第二集：洞悉世 界的眼睛", desc: "这是一档有理论深度，有实践温度大型通俗理论对话节目，重温马克思的感人故事，汲取马克思主义的真理力量，探究马克思主义的当代价值和",
          path: "http://www.baidu.com"
        }, {
          thumb: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661619600&t=168c479af7dfac2a306437039f33ea95',
          title: "《马克思是对的》第三集：不朽的 《资本论》", desc: "这是一档有理论深度，有实践温度大型通俗理论对话节目，重温马克思的感人故事，汲取马克思主义的真理力量，探究马克思主义的当代价值和",
          path: "http://www.baidu.com"
        }, {
          thumb: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661619600&t=168c479af7dfac2a306437039f33ea95',
          title: "《马克思是对的》第四集：解放全 人类的胸怀", desc: "这是一档有理论深度，有实践温度大型通俗理论对话节目，重温马克思的感人故事，汲取马克思主义的真理力量，探究马克思主义的当代价值和",
          path: "http://www.baidu.com"
        }, {
          thumb: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661619600&t=168c479af7dfac2a306437039f33ea95',
          title: "《马克思是对的》第五集：千年思 想家", desc: "这是一档有理论深度，有实践温度大型通俗理论对话节目，重温马克思的感人故事，汲取马克思主义的真理力量，探究马克思主义的当代价值和",
          path: "http://www.baidu.com"
        },
      ],
      list2: {
        left: [
          { title: "自古英雄出少年——1835年，人生志向", path: "http://www.baidu.com", type: 'video...', },
          { title: "哲学博士炼成记——1841年，博士论文", path: "http://www.baidu.com", type: 'video...', },
          { title: "穿越时空的手稿——1844年，哲学革命", path: "http://www.baidu.com", type: 'audio' },
          { title: "撸起袖子加油干——1845年，新世界观", path: "http://www.baidu.com", type: 'audio' },
          { title: "旷世宣言惊风雨——1848年，不朽宣言", path: "http://www.baidu.com", type: 'audio' },
          { title: "指点江山论中国——1853年，关注中国", path: "http://www.baidu.com", type: 'video...', },
          { title: "忠言逆耳利于行——1857年，批判事业", path: "http://www.baidu.com", type: 'video...', },
        ],
        right: [
          { title: "唯物史观讲了啥——1859年，唯物史观", path: "http://www.baidu.com", type: 'video...', },
          { title: "导师责任重于山——1862年，革命导师", path: "http://www.baidu.com", type: 'audio' },
          { title: "谁与我生死与共——1866年，伟大友谊", path: "http://www.baidu.com", type: 'audio' },
          { title: "史上最强判决书——1867年，鸿篇巨著", path: "http://www.baidu.com", type: 'video...', },
          { title: "国际歌由此诞生——1871年，巴黎公社", path: "http://www.baidu.com", type: 'audio' },
          { title: "活化石也有春天——1877年，转向东方", path: "http://www.baidu.com", type: 'audio' },
          { title: "他永远地睡着了——1883年，与世长辞", path: "http://www.baidu.com", type: 'video...', },
        ]
      },
      list3: [
        { thumb: "https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661619600&t=168c479af7dfac2a306437039f33ea95", title: "开卷有理 主创访谈", path: "http://www.baidu.com" },
        { thumb: "https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661619600&t=168c479af7dfac2a306437039f33ea95", title: "再见吧! 黑格尔", path: "http://www.baidu.com" },
        { thumb: "https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661619600&t=168c479af7dfac2a306437039f33ea95", title: "飞扬吧! 青春", path: "http://www.baidu.com" },
        { thumb: "https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1661619600&t=168c479af7dfac2a306437039f33ea95", title: "马克思的新朋友", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：西式民主的五种病", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：国际歌的由来", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：一艘升火待发的军舰随时开往知识的海洋", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：西式民主的五种病", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：国际歌的由来", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：一艘升火待发的军舰随时开往知识的海洋", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：西式民主的五种病", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：国际歌的由来", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：一艘升火待发的军舰随时开往知识的海洋", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：西式民主的五种病", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：国际歌的由来", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：一艘升火待发的军舰随时开往知识的海洋", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：一艘升火待发的军舰随时开往知识的海洋", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：西式民主的五种病", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：国际歌的由来", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：一艘升火待发的军舰随时开往知识的海洋", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：西式民主的五种病", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：国际歌的由来", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：一艘升火待发的军舰随时开往知识的海洋", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：西式民主的五种病", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：国际歌的由来", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：一艘升火待发的军舰随时开往知识的海洋", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：西式民主的五种病", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：国际歌的由来", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：一艘升火待发的军舰随时开往知识的海洋", path: "http://www.baidu.com" },
        { title: "马克思，靠谱：一艘升火待发的军舰随时开往知识的海洋", path: "http://www.baidu.com" },



      ]


    };
  },
  methods: {

    ...mapActions(['goAnyDetailsBeforeLogin',]),
    // 判断是否登录,进行跳转
    toSzztkDetail (path) {
      let that = this;
      that.goAnyDetailsBeforeLogin().then(() => {
        window.open(path)
      })
    },
  }


};
</script>

<style lang="less" scoped>
.thoughtParty {
  .body {
    .body_box {
      background-color: #fff;
      .header_banner {
        width: 100%;
        height: 280px;
        background: url('../../assets/image/new-szztk/mks/banner.jpg') no-repeat
          50%;
        background-size: auto 100%;
        background-repeat: no-repeat !important;
      }
      .main_box {
        .mkssdd.outer-box {
          padding: 50px 0 36px;
          .header {
            background: url('../../assets/image/new-szztk/mks/mks-title1.jpg');
          }
          .list-body {
            display: flex;
            justify-content: space-between;
            .item {
              cursor: pointer;
              // &:hover {
              //   .title {
              //     color: #cc2a27 !important;
              //   }
              // }
            }
            .left-box {
              margin-right: 46px;
              cursor: pointer;
              .thumb {
                width: 428px;
                height: 241px;
                border-radius: 4px 4px 0px 0px;
              }
              .description {
                width: 428px;
                height: 148px;
                background: #fcfbf6;
                padding: 22px 15px 0;

                .desc {
                  font-size: 14px;
                  color: #888476;
                  line-height: 20px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 4;
                }
                .title {
                  max-width: 100%;
                  font-size: 14px;
                  font-weight: bold;
                  color: #000000;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  margin-bottom: 10px;
                }
              }
            }
            .right-box {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              .item:nth-child(2) {
                margin-bottom: 9px;
              }

              .thumb {
                width: 240px;
                height: 135px;
                background: #f2bf3e;
                border-radius: 4px 4px 0px 0px;
              }
              .bottom-text {
                width: 240px;
                height: 55px;
                background: #faf9f9;
                padding: 7px 12px 0;

                .title {
                  font-size: 14px;
                  font-weight: bold;
                  color: #000000;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                }
              }
            }
          }
        }
        .jlh.outer-box {
          background: #f4f2ec;
          padding: 28px 0 25px;
          .header {
            background: url('../../assets/image/new-szztk/mks/mks-title2.jpg');
          }
          .body-box {
            display: flex;
            justify-content: space-between;
            .notes {
              width: 480px;
              border-radius: 0px 0px 4px 4px;
              background: url('../../assets/image/new-szztk/mks/note-bg.png');
              background-size: cover;
              background-repeat: no-repeat;
              padding: 36px 27px 23px;
              .item {
                border-bottom: 2px dashed #efece5;
                padding: 0 0 0 33px;
                height: 50px;
                line-height: 50px;
                position: relative;
                font-size: 14px;
                font-weight: bold;
                color: #333333;
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                &:last-child {
                  border: none;
                  margin-bottom: 5px;
                }
                &.audio::before {
                  content: '';
                  display: inline-block;
                  width: 18px;
                  height: 16px;
                  background: url('../../assets/image/new-szztk/mks/icon-audio.png');
                  background-size: cover !important;
                  background-repeat: no-repeat !important;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
                &.video::before {
                  content: '';
                  display: inline-block;
                  width: 18px;
                  height: 16px;
                  background: url('../../assets/image/new-szztk/mks/icon-video.png');
                  background-size: cover !important;
                  background-repeat: no-repeat !important;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
        .mkskp.outer-box {
          background: url('../../assets/image/new-szztk/mks/bottom-background.png')
            bottom no-repeat;
          background-size: 100% 464px;
          padding: 35px 0 37px;
          .header {
            background: url('../../assets/image/new-szztk/mks/mks-title3.jpg');
          }
          .body-box {
            .top-imgs {
              display: flex;
              justify-content: space-between;
              margin-bottom: 12px;
              .item {
                width: 230px;
                height: 129px;
                border-radius: 4px;
                background-size: cover !important;
                background-repeat: no-repeat !important;
                cursor: pointer;
              }
            }
            .bottom-list {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              font-size: 14px;
              font-weight: bold;
              color: #333333;
              .item {
                width: 484px;
                height: 45px;
                line-height: 45px;
                border-bottom: 1px solid #f1f1f1;
                padding-left: 30px;
                position: relative;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                &::before {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  left: 8px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 12px;
                  height: 12px;
                  background: url('../../assets/image/new-szztk/mks/icon-video.png');
                  background-size: cover !important;
                  background-repeat: no-repeat !important;
                }
              }
            }
          }
        }
        .content-box {
          width: 1000px;
          margin: 0 auto;
        }
      }
      .header {
        width: 100%;
        height: 42px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        margin-bottom: 20px;
      }
    }
  }
}
.thumb {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
</style>
