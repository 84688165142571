<template>
  <div class="dialog-box">
    <div class="dialog-mask"></div>
    <div class="dialog-container">
      <div class="dialog-header">
        <div class="title">温馨提示</div>
        <div class="close" @click="close"></div>
      </div>
      <div class="dialog-body">
        <div class="header-tip">
          如需得获得更多资源使用权限，可拨打服务电话或<a style="color: #3d82ff" href="http://www.66.dev.eol.com.cn/need" target="_blank">在线申请开通</a>。
        </div>
        <div class="body-content">
          <div class="service-title">
            <div class="left-line"></div>
            <span>服务咨询</span>
            <div class="right-line"></div>
          </div>
          <div class="service-qrCode">
          </div>
          <div class="service-contact">
            <p class="teach">王老师 <span>13161510966</span></p>
            <p>或扫描二维码添加微信</p>
          </div>
        </div>
      </div>
      <div class="dialog-split"></div>
      <a :href="apply_url+'/need'" class="dialog-handle" target="_blank">
        申请开通更多资源
      </a>
    </div>
  </div>
</template>
<script>
import services from "../assets/js/services"
export default {
  name: "appleRes",
  data () {
    return {
      visible: false,
      dialogFlag: true,
      apply_url: ''
    };
  },
  mounted () {
    this.apply_url = services.YXT_GW
  },
  methods: {
    close () {
      this.$parent.dialogConfig.name = ''
    },

  },


};
</script>
<style  lang="less">
.dialog-box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100vw;
  height: 100vh;
  .dialog-mask {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
  }
  .dialog-container {
    width: 500px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 21px;
    .dialog-header {
      box-sizing: border-box;
      text-align: center;
      width: 100%;
      height: 60px;
      opacity: 0.8;
      border-radius: 4px 4px 0px 0px;
      line-height: 60px;
      padding: 0 30px;
      position: relative;
      background: url('../assets/image/new-szztk/index/dialog-header.jpg');
      background-size: cover !important;
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #000;
      }
      .close {
        float: right;
        position: absolute;
        right: 29px;
        top: 50%;
        transform: translateY(-50%);
        background: url('../assets/image/new-szztk/index/dialog-close.png');
        background-size: cover;
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }
    .dialog-body {
      padding: 0 30px;
      .header-tip {
        font-size: 14px;
        color: #414141;
        line-height: 18px;
        padding: 20px 0 10px;
        text-align: center;
      }
      .service-title {
        display: flex;
        align-items: center;
        .left-line {
          flex: 1;
          height: 1px;
          background: #e2e5e7;
          position: relative;
          &::after {
            content: '';
            display: inline-block;
            width: 9px;
            height: 9px;
            background: #c2bdbd;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotateZ(45deg);
          }
        }
        span {
          font-size: 14px;
          font-weight: bold;
          color: #df3b26;
          padding: 0 16px;
        }
        .right-line {
          flex: 1;
          height: 1px;
          background: #e2e5e7;
          position: relative;
          &::after {
            content: '';
            display: inline-block;
            width: 9px;
            height: 9px;
            background: #c2bdbd;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotateZ(45deg);
          }
        }
      }
      .service-qrCode {
        width: 125px;
        height: 125px;
        background: url('../assets/image/new-szztk/index/qrcode.jpg');
        margin: 19px auto 13px;
        background-size: cover;
      }
      .service-contact {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: #000;
        line-height: 18px;
        text-align: center;
        .teach {
          font-size: 20px;
          margin-bottom: 18px;
          color: #414141;
        }
        p {
          color: #4d4d4d;
        }
      }
    }
    .dialog-split {
      margin: 20px 28px 10px;
      flex: 1;
      border: 1px solid #f1f1f1;
    }
    .dialog-handle {
      display: block;
      margin: 0 auto;
      text-align: center;
      background: linear-gradient(-90deg, #e75249 0%, #dd3f2a 100%);
      opacity: 0.89;
      border-radius: 13px;
      line-height: 27px;
      font-size: 14px;
      color: #ffffff;
      width: 161px;
      height: 27px;
      cursor: pointer;
    }
  }
}
</style>
