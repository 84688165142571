<template>
  <div class="iframe-outer">
    <Header />
    <Navbar />
    <div v-if="schoolMenu && schoolMenu.hasOwnProperty('special') && schoolMenu.special" style="height: 935px;">
      <iframe allowfullscreen id="areaIframe" frameborder="0" marginheight="0" class="detail-con" :src="schoolMenu.special.url"></iframe>
    </div>
    <!-- <div v-else class="noData-box">
      <img src="../../assets/image/jsckzyxy/empty.png" alt="">
      <div class="text">暂无相关内容</div>
    </div> -->
    <Loading :showLoading="loading" />

  </div>

</template>

<script>
import Header from "../../components/Header.vue"
import Navbar from "../../components/Navbar.vue"
import services from "../../assets/js/services"
import Loading from "../../components/loading.vue";
import { mapActions, mapState } from "vuex"
export default {
  name: "areaIframe",
  components: {
    Header,
    Navbar,
    Loading
  },
  data () {
    return {
      services,
      loading: false,
      school_code: localStorage.getItem("schoolCode"),
    }
  },
  computed: {
    ...mapState({
      // areaRegionInfo: (state) => state.szztk.areaRegionInfo,
      schoolMenu: (state) => state.appsLogin.schoolMenu,
    })
  },
  mounted () {
    if (this.schoolMenu && this.schoolMenu.hasOwnProperty('special') && this.schoolMenu.special.id) {

    } else {
      this.getSchoolMenuStatus(this.school_code)
    }
    let that = this
    this.$nextTick(() => {
      // // 需要在iframe加载完成后调用该方法，或者在$nextTick中进行方法调用，看实际情况
      // setTimeout(function() {
      //   that.setIframeHeight(document.getElementById('areaIframe'))
      // }, 1000)
      window.addEventListener("message", receiveMessage, false);
      function receiveMessage (event) {
        if (event.data == 'needLogin') {
          that.goAppsDetailsBeforeLogin()
        } else if (event.data == 'noAuth') {
          that.$router.push({
            path: `/${that.school_code}/zy/noauth`
          })
        }
      }
    })
  },
  methods: {
    ...mapActions(['goAppsDetailsBeforeLogin', 'getSchoolMenuStatus']),
    // setIframeHeight(iframe) {
    //   let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow
    //   console.log(iframeWin.document.documentElement.scrollHeight)
    //   iframe.height = iframeWin.document.body.scrollHeight || iframeWin.document.documentElement.scrollHeight;
    // }
  },

}
</script>

<style scoped>
.iframe-outer {
  width: 100vw;
  padding: 0;
  margin: 0;
}
.detail-con {
  width: 100vw;
  height: 935px;
}

.noData-box {
  width: 100%;
  height: calc(100vh - 232px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.noData-box img {
  width: 240px;
  height: auto;
  margin: 0 auto 30px;
}
.noData-box .text {
  color: #76797e;
  font-size: 14px;
}
</style>