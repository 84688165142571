<template>
  <div class="bg-box">
    <Header />
    <Navbar />
    <div class="use-box">
      <div class="use-header">
        <div class="icon"></div>
        <div class="text">用户使用手册</div>
      </div>
      <div class="use-body">
        <iframe :src="iframeUrl" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Navbar from "../../components/Navbar.vue";

import { mapActions, mapState, mapMutations } from "vuex"

export default {
  name: "syzn",
  components: {
    Header,
    Navbar,
  },
  data () {
    return {
      iframeUrl: "",
      needLogin: false,
      school_code: localStorage.getItem("schoolCode"),

    };
  },
  mounted () {
    this.iframeUrl = window.origin + '/kcsz.pdf'
    this.judgeLogin()


  },
  methods: {
    ...mapActions(['goAppsDetailsBeforeLogin', 'getAppsLoginUserInfo']),
    ...mapMutations(['setAppsLoginVisible']),

    async judgeLogin () {
      await this.getAppsLoginUserInfo();
      this.needLogin = this.appsUserInfo.id ? false : true
      if (this.needLogin) {
        this.$router.push(`/${this.school_code}/zy/szzyk?needLogin=1`)
      }
    },
  },

  computed: {
    ...mapState({
      loginVisible: state => state.appsLogin.loginVisible,
      appsUserInfo: state => state.appsLogin.appsUserInfo
    }),
  },
};
</script>

<style lang="less" scoped>
.bg-box {
  padding-bottom: 65px;
  .use-box {
    width: 1000px;
    margin: 0 auto;
    .use-header {
      padding: 35px 0;
      display: flex;
      align-items: center;
      .icon {
        width: 24px;
        height: 24px;
        background-image: url('../../assets/image/common/use-book.png');
        background-size: cover;
        margin-right: 20px;
      }
      .text {
        font-size: 26px;
        font-weight: bold;
        color: #515151;
      }
    }
    .use-body {
      min-height: 860px;
      margin: 0 auto;
      background: #f4f7f9;
      border: 1px solid #e1e1e1;
      text-align: center;
      iframe {
        width: 100%;
        height: 860px;
      }
    }
  }
}
</style>