<template>
  <div class="nav-jxzx">
    <div class="resize-con">
      <div class="jxsy-tit"></div>
      <div class="jxzx-content">
        <div v-for="(item, ind) in jszxLIst" :key="ind" class="jxzx-item">
          <div
            class="jxzx-item-column"
            :class="{ hide: !(index == changeSlide) }"
            v-for="(ele, index) in item"
            :key="index"
            @click="toDetail(ele)"
          >
            <div
              :style="{ backgroundImage: ele.cover ? `url(${ele.cover})` : '' }"
            ></div>
          </div>
        </div>
        <div class="jxzx-item jxzx"></div>
        <div
          class="jxzx-next"
          v-if="total > 1"
          @click="changeSlide = changeSlide == 0 ? 1 : 0"
        ></div>
        <div
          class="jxzx-prev"
          v-if="total > 1"
          @click="changeSlide = changeSlide == 0 ? 1 : 0"
        ></div>
        <div class="jxzx-pagination" v-if="total > 1">
          <div
            class="pagination-bullet"
            :class="{ active: i == changeSlide + 1 }"
            v-for="i in 2"
            :key="i"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import services from "../../../assets/js/services.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      school_code: localStorage.getItem("schoolCode"),
      specialId: "658162fe92446f6204763592",
      jszxLIst: [],
      changeSlide: 0,
    };
  },
  created() {
    this.getjxzxList();
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin"]),
    async getjxzxList() {
      let res = await services.getAreaSzztkLists({
        page: 1,
        length: 12,
        count: 0,
        school_code: this.school_code,
        special_id: this.specialId,
        kind: "1",
        column_name: "乘风破浪 匠心争先",
        total: 0
      });
      if (res.code == "0000" && res.data.items.length > 0) {
        this.total = res.data.total
         let num = Math.ceil(res.data.items.length / 2) - 1;
        for (let i = 0; i <= num; i++) {
          if (i == num) {
            this.jszxLIst.push(res.data.items.slice(i * 2));
          } else {
            this.jszxLIst.push(res.data.items.slice(i * 2, i * 2 + 2));
          }
        }
  
      }
    },
    toDetail(item) {
      if (item) {
        this.goAppsDetailsBeforeLogin().then(() => {
          if (item.is_open_url == 2) {
            window.open(`${item.source_url}`, "_blank");
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-jxzx {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 139px;
  background-image: url("../../../assets/image/gjjs/index/nav-index/nav-xsgj-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  .resize-con {
    position: absolute;
    left: 308px;
    top: 50%;
    &::after {
      position: absolute;
      content: "";
      display: block;
     width: 468px;
      height: 548px;
      top: 54px;
      left: -246px;
      z-index: 0;
      background-image: url("../../../assets/image/gjjs/index/nav-xsgj/gjjs-sidber.png");
      background-repeat: no-repeat;

      background-size: cover;
      z-index: 0;
    }
    .jxsy-tit {
      width: 700px;
      height: 109px;
      background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzs-tit.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin: 0 auto;
      // margin-left: 23.5%;
    }
    .jxzx-content {
      position: relative;
      z-index: 1;
      box-shadow: 0px 11px 26px 4px rgba(168, 141, 147, 0.68);
      width: 1100px;
      height: 630px;
      // margin-left: 16%;
      background: #fcf4e9;
      box-shadow: 0px 11px 26px 4px rgba(168, 141, 147, 0.68);
      border-radius: 6px;
      background-repeat: no-repeat;
      margin-top: 43px;
      display: flex;
      justify-content: space-evenly;
      background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzx-bg.png");
      background-size: 1081px 599px;
      background-position: center;

      padding: 46px 35px 70px;
      display: grid;
      gap: 10px 10px;
      grid-template-rows: repeat(6, 1fr);
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "a b c"
        "a b c"
        "a d c"
        "e d g"
        "e f g"
        "e f g";
      .jxzx-item {
        position: relative;
        &:nth-child(1) {
          grid-area: a;
        }
        &:nth-child(2) {
          grid-area: b;
        }
        &:nth-child(3) {
          grid-area: c;
        }
        &:nth-child(4) {
          grid-area: e;
        }
        &:nth-child(5) {
          grid-area: f;
        }
        &:nth-child(6) {
          grid-area: g;
        }
        &.jxzx {
          grid-area: d;
          background-image: url("../../../assets/image/gjjs/index/nav-jxzx/center-img.png");
          background-size: cover;
        }
        .jxzx-item-column {
          width: 100%;
          height: 100%;
          cursor: pointer;
          // background-color: #fcf4e9;
          transition: all 0.3s;

          background-size: cover;
          position: absolute;
          opacity: 1;
          z-index: 20;
          overflow: hidden;
          &:hover {
            div {
              transform: scale(1.1);
              transition: all 0.3s;
            }
          }
          div {
            transition: 0.3s all;
            width: 100%;
            height: 100%;
            background-image: url("../../../assets/image/gjjs/index/nav-index/default-bg.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          &.hide {
            transform: scale(0.5);
            opacity: 0;
            z-index: -1;
          }
        }
      }
      .jxzx-next {
        position: absolute;
        width: 48px;
        height: 48px;
        background-color: rgba(252, 244, 233, 0.78);
        border: 1px solid #b11017;
        border-radius: 50%;
        background-size: 31px 26px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        right: 0;
        top: 39.6%;
        background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzx-next.png");
        background-repeat: no-repeat;

        z-index: 99;
        &:hover {
          background-color: rgba(252, 244, 233, 1);
        }
      }
      .jxzx-prev {
        position: absolute;
        width: 48px;
        height: 48px;
        background-color: rgba(252, 244, 233, 0.78);
        border: 1px solid #b11017;
        border-radius: 50%;
        background-size: 31px 26px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        background-image: url("../../../assets/image/gjjs/index/nav-jxzx/jxzx-prev.png");
        z-index: 99;
        &:hover {
          background-color: rgba(252, 244, 233, 1);
        }
        left: 0;
        top: 39.6%;
      }
      .jxzx-pagination {
        display: flex;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 44px;
        .pagination-bullet {
          width: 6px;
          height: 6px;
          background-color: rgba(251, 237, 210, 0.6);
          border-radius: 50%;
          margin: 0 5px;
          &.active {
            width: 26px;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1900px) and (min-width: 1700px) {
  .resize-con {
    transform: scale(0.9) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-height: 600px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .resize-con {
    transform: scale(0.84) translateY(-50%);
    transform-origin: 0 0;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1550px) {
  .resize-con {
    transform: scale(0.8) translateY(-50%);
    transform-origin: 0 0;
  }
}

@media screen and (max-width: 1550px) {
  .resize-con {
    transform: scale(0.74) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1500px) {
  .resize-con {
    transform: scale(0.7) translateY(-50%);
    transform-origin: 0 0;
    // left: 50%;
  }
}
@media screen and (max-width: 1400px) {
  .resize-con {
    transform: scale(0.6) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (max-width: 1300px) {
  .resize-con {
    transform: scale(0.55) translateX(-50%) translateY(-50%) !important;
    transform-origin: 0 0;
    left: 50% !important;
  }
}
@media screen and (min-width: 1900px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
@media screen and (min-height: 1080px) {
  .resize-con {
    transform:  translateY(-50%) !important;
  }
}
</style>