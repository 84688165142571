<template>
  <!-- 登录弹层 -->
  <div v-if="loginVisible" class="login-outer-box">
    <div class="login-mask" @click="loginModal(false)"></div>
    <div class="login-inner-box">
      <div class="line-top">
        <img class="close" @click="loginModal(false)" src="../assets/image/common/login-close.png" />
      </div>
      <div class="login-ways-box">
        <div :class="formType == 1 ? 'ways-option active' : 'ways-option'" @click="changeFormWays(1)">账号登录</div>
        <div class="split-line"></div>
        <div :class="formType == 2 ? 'ways-option active' : 'ways-option'" @click="changeFormWays(2)">验证码登录</div>
      </div>
      <!-- 账号登录表单 -->
      <div v-show="formType == 1">
        <a-form :form="accountForm" class="login-form" :destroyOnClose="true" @submit="handleAccountSubmit">
          <a-form-item class="login-form-item">
            <a-input class="form-input" v-decorator="[
                  'username',
                  { rules: [{ validator: validateCheckUsername}] },
                ]" placeholder="请输入账号"></a-input>
          </a-form-item>
          <a-form-item class="login-form-item">
            <a-input type="password" class="form-input" v-decorator="['password',{ rules: [{ validator: validateCheckPassword}] },]" placeholder="请输入密码"></a-input>
          </a-form-item>
          <a-form-item v-if="schoolInfo && schoolInfo.is_captcha_check" class="login-form-item">
            <a-input class="form-input input-code" v-decorator="['captcha', {
                  rules: [{ required: true, message: '请输入验证码' }],
                }]" placeholder="请输入验证码">
            </a-input>
            <div class="img-code" @click="methodImageCaptcha()">
              <img v-if="imageCode" :src="imageCode.img" alt />
            </div>
          </a-form-item>
          <a-button type="primary" html-type="submit" class="login-form-button">登录</a-button>
        </a-form>
      </div>
      <!-- 验证码登录表单 -->
      <div v-show="formType == 2">
        <a-form :form="mobileForm" class="login-form" :destroyOnClose="true" @submit="handleMobileSubmit">
          <a-form-item class="login-form-item">
            <a-input class="form-input" v-decorator="['mobile',{ rules: [{ validator: validateCheckPhone}] },]" placeholder="请输入手机号"></a-input>
          </a-form-item>
          <a-form-item class="login-form-item">
            <a-input type="password" class="form-input input-code" v-decorator="['captcha',{ rules: [
              { required: true, message: '请输入验证码'}]  
              }]" placeholder="请输入验证码"></a-input>
            <div class="img-code" @click="methodImageCaptcha()">
              <img v-if="imageCode" :src="imageCode.img" alt />
            </div>
          </a-form-item>
          <a-form-item class="login-form-item">
            <a-input class="form-input input-code" v-decorator="['code', {
                  rules: [{ required: true, message: '请输入短信验证码' }],
                }]" placeholder="请输入短信验证码">
            </a-input>
            <div :class="loginSmsClass" @click="clickloginSms()">{{ loginSmsText }}</div>
          </a-form-item>
          <a-button type="primary" html-type="submit" class="login-form-button">登录</a-button>
        </a-form>
      </div>

      <div class="bottom-ways-box">
        <!-- <div class="wechat-box">
          <span>快速登陆</span>
          <img class="weixin-icon" src="../assets/image/common/weixin-login.png" />
        </div> -->
        <!-- <router-link class="link-right try" to="/register">个人注册</router-link> -->
        <router-link class="link-right try" to="/resetPassword">忘记密码</router-link>
        <!-- <a class="link-right" :href='`${services.YXT_GW}/need`' target="_blank">学校免费试用</a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Input, Button } from "ant-design-vue";
import { mapState, mapActions, mapMutations } from "vuex"
import services from "../assets/js/services"
export default {
  name: 'Login',
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AButton: Button,
  },
  data () {
    return {
      services,
      formType: 1, // 1账号登录 2验证码登录
      loginSmsNum: 60,
      loginSmsText: "获取验证码",
      loginSmsClass: "jeol-getCode",
      school_code: '',
    };
  },
  computed: {
    ...mapState({
      loginVisible: state => state.appsLogin.loginVisible,
      imageCode: state => state.appsLogin.imageCode,
      schoolInfo: state => state.appsLogin.schoolInfo
    }),
  },
  beforeCreate () {
    this.accountForm = this.$form.createForm(this);
    this.mobileForm = this.$form.createForm(this);
  },
  mounted () {
    this.judgeShowCaptcha()
  },
  methods: {
    ...mapMutations(['setAppsLoginVisible']),
    ...mapActions(["getAppsImageCaptcha", 'getLoginSchoolInfo', 'getAppsSmsCaptcha', 'requestAppsLoginApps', 'getAppsLoginUserInfo']),
    // 判断是否显示验证码
    judgeShowCaptcha(){
      let that = this
      that.school_code = localStorage.getItem("schoolCode")
      
      if(that.schoolInfo && that.schoolInfo.school_name){
        if (!that.schoolInfo.is_school_exist) {
          window.open(services.SZ_SERVER, "_self")
        }
      } else {
        that.getLoginSchoolInfo({ school_code: that.school_code, label: 'kcsz' }).then(res => {
          if (res.code == '0000') {
            if (!res.data.is_school_exist) {
              window.open(services.SZ_SERVER, "_self")
            }
          } else {
            that.$errorMsg(res.message ? res.message : "错误")
          }
        })
      }

    },
    loginModal (val) {
      this.setAppsLoginVisible(val);
      if (val) {
        this.methodImageCaptcha();
      } else {
        this.accountForm.resetFields()
        this.mobileForm.resetFields()
      }
    },
    // 用户名验证
    validateCheckUsername (rule, value, callback) {
      if (value) {
        if (/^[A-Za-z0-9]{6,16}$/.test(value)) {
          callback();
        } else {
          callback("请输入[6-16]位字母或数字组成的账号");
        }
      } else {
        callback("请输入账号");
      }
    },
    // 密码验证
    validateCheckPassword (rule, value, callback) {
      if (value) {
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,16}$/.test(value)) {
          callback();
        } else {
          callback("密码为[8-16]位字母大小写及数字组合");
        }
      } else {
        callback('请输入密码');
      }
    },
    // 手机号验证
    validateCheckPhone (rule, value, callback) {
      if (value) {
        if (/^1[3-9][0-9]{9}$/.test(value)) {
          callback();
        } else {
          callback("请输入正确的手机号");
        }
      } else {
        callback('请输入手机号');
      }
    },
    // 切换账号登录 和 验证码登录点击事件
    changeFormWays (type) {
      this.formType = type
      this.accountForm.resetFields()
      this.mobileForm.resetFields()
    },
    // 获取图片验证码
    methodImageCaptcha () {
      let that = this;
      that.getAppsImageCaptcha()
    },
    // 提交账号登录表单
    handleAccountSubmit (e) {
      let that = this;
      e.preventDefault();
      this.accountForm.validateFields(["username", "password", "captcha"], (err, values) => {
        if (!err) {
          let param = { ...values }
          if (that.schoolInfo && that.schoolInfo.is_captcha_check) {
            param.key = that.imageCode.key
          }
          that.methodlogin(param);
        }
      })
    },
    // 提交验证码登录表单
    handleMobileSubmit (e) {
      let that = this;
      e.preventDefault();
      this.mobileForm.validateFields(["mobile", "captcha", "code"], (err, values) => {
        if (!err) {
          console.log(values)
          that.methodlogin(values);
        }
      })
    },
    // 请求登录方法
    async methodlogin (param) {
      let that = this;
      let { setFields } = that.formType == 1 ? this.accountForm : this.mobileForm
      that.requestAppsLoginApps({ ...param, school_code: that.school_code, }).then((res) => {
        if (res.code == '0000') {
          // 获取用户信息
          that.getAppsLoginUserInfo();
          that.loginModal(false)
        } else {
          that.methodImageCaptcha()
          if (res.errors) {
            let errors = res.errors
            for (let k in errors) {
              setFields({
                [k]: { errors: [new Error(errors[k])] }
              })
            }
          } else {
            that.$message.error(res.message ? res.message : "登录失败");
          }
        }
      });
    },
    // 点击获取短信验证码事件
    clickloginSms () {
      let that = this;
      if (that.loginSmsClass === "jeol-getCode") {
        that.mobileForm.validateFields(["mobile", "captcha"], (err, values) => {
          if (!err) {
            values.key = that.imageCode.key;
            that.getAppsSmsCaptcha(values).then((res) => {
              if (res.code === "0000") {
                that.smsCountDown()
                that.$message.success(res.message ? res.message : "短信验证码发送成功");
              } else {
                that.methodImageCaptcha()
                if (res.errors) {
                  let errors = res.errors
                  for (let k in errors) {
                    that.mobileForm.setFields({
                      [k]: { errors: [new Error(errors[k])] }
                    })
                  }
                } else {
                  that.$message.error(res.message ? res.message : "短信验证码发送失败");
                }
              }
            });
          }
        })

      }
    },
    // 验证码倒计时
    smsCountDown () {
      let that = this
      that.loginSmsText = this.loginSmsNum + "s后获取";
      that.loginSmsClass = "jeol-getCode disabled";
      that.loginSmsNum--;
      // 倒计时
      that.interval1 = setInterval(() => {
        if (that.loginSmsNum > 0) {
          that.loginSmsText = that.loginSmsNum + "s后获取";
          that.loginSmsNum--;
        } else {
          that.loginSmsText = "获取验证码";
          that.loginSmsNum = 60;
          that.loginSmsClass = "jeol-getCode";
          clearInterval(that.interval1);
        }
      }, 1000);
    }
  }
}
</script>

<style lang="less" scoped>
.login-outer-box {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  .login-mask {
    background: #000000;
    opacity: 0.52;
    width: 100%;
    height: 100%;
    z-index: 1001;
  }
  .login-inner-box {
    width: 384px;
    background-color: #ffffff;
    border-radius: 16px;
    z-index: 1002;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    padding: 30px 34px;
    transition: width 0.3s;
    .student-box {
      font-size: 13px;
      color: #df4531;
      margin-bottom: 10px;
    }
    .login-ways-box {
      width: 100%;
      text-align: center;
      margin: 20px 0 30px;
    }
    .login-ways-box .ways-option {
      font-size: 16px;
      font-weight: 400;
      color: #7a7a7a;
      padding: 0 0 10px;
      display: inline-block;
      cursor: pointer;
    }
    .login-ways-box .ways-option.active {
      color: #101010;
      font-weight: 800;
      position: relative;
      border-bottom: 2px solid #df4531;
    }
    .login-ways-box .split-line {
      width: 1px;
      height: 22px;
      display: inline-block;
      background-color: #dadada;
      margin: 0 40px 0;
      vertical-align: top;
    }
    .line-top {
      position: absolute;
      text-align: center;
      display: inline-block;
      top: 10px;
      right: 20px;
      .close {
        position: absolute;
        top: 12px;
        right: 0;
        cursor: pointer;
      }
    }
    .login-form-button {
      width: 316px;
      height: 40px;
      line-height: 40px;
      background: #df4531;
      border-radius: 21px;
      color: #fff;
      font-size: 19px;
      border: none;
      box-shadow: none;
      margin: 10px 0 20px;
    }
    .bottom-ways-box {
      width: 100%;
      .wechat-box {
        display: inline-block;
        span {
          font-size: 13px;
          color: #a4a4a4;
        }
        .weixin-icon {
          width: 40px;
          height: auto;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
      .link-right {
        float: right;
        display: inline-block;
        font-size: 16px;
        color: #df4531;
        line-height: 40px;
        &.try {
          margin-left: 15px;
        }
      }
    }
  }
}
.login-form-item {
  margin-bottom: 22px;
  .form-input {
    color: #191919;
    font-size: 14px;
    border: none;
    box-shadow: none;
    height: 40px;
    background: #f8f5f5;
    border-radius: 6px;
  }
  .form-input.input-code {
    width: 200px;
  }
  .img-code {
    width: 86px;
    height: 40px;
    float: right;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: inline-block;
      width: 100%;
      height: auto;
    }
  }
  .jeol-getCode {
    width: 96px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    float: right;
    cursor: pointer;
    color: #ee373d;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-shadow: 0px 0px 8px 0px rgb(130 130 130 / 20%);
    margin-top: 2px;
  }
  .jeol-getCode.disabled {
    font-size: 14px;
    color: #b8b8b8;
    background: #f6f7f9;
    border-color: transparent;
    cursor: text;
  }
}
/deep/ .login-form .has-error .ant-form-explain,
/deep/ .login-form .has-error .ant-form-split,
/deep/ .login-form .has-success .ant-form-explain,
/deep/ .login-form .has-success .ant-form-split {
  position: absolute !important;
  left: 10px;
}
</style>