<template>
  <div ref="container" class="containers">
    <WhrjHeader @refresh="watchRefresh" :keywords="keyword.name" :currentType='{name: "资讯", catid: "2"}' />
    <div class="sdcm-container-box">
      <div class="search-content">
        本次检索为您找到{{keyword.count}}篇有关“ <span class="keyword">{{keyword.name}}</span> ”的内容
      </div>
      <div class="sdcm-content-box w1280">
        <div :class="['side-bar',position?'position':'']" ref="sideBar">
          <div class="header-box"></div>
          <div class="body-box">
            <div :class="['item',currentIndex==index?'active':'']" v-for="(item,index) in searchMenu" :key="index" @click="currentSideBar(index,item)">
              <div class="star"></div>
              <div class="text">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="right-container">
          <template v-if="listData && listData.length>0">
            <div v-if="catid == '14672'" class="outer-body">
              <div class="login-mask" v-if="needLogin">
                <div class="login-btn" @click="loginCloseMask">
                  登陆后查看 >>
                </div>
              </div>
              <div class="sfkc-box" v-else>
                <div class="item" v-for="item,index in listData" :key="index" @click="goCourse(item)">
                  <div class="thumb-image" :style="`background-image:url(${item.thumb_image})`" v-if="item.thumb_image"></div>
                  <div class="right-content">
                    <div class="title">
                      <div class="text" v-html="item.title"></div>
                      <div class="label" v-if="item.ovtitle&&item.ovtitle.length>0">{{item.ovtitle}}</div>
                    </div>
                    <div class="sub-title" v-if="item.author||item.name" v-html="item.author || item.name"></div>
                    <div class="desc" v-html="item.description"></div>
                  </div>
                </div>

                <div style="text-align: center; padding:30px 0" v-if="listData && listData.length>0">
                  <a-pagination v-model="realyPage" :show-total="total => `共${allPages}页`" :total="total" @change="onPagesChange" />
                </div>
              </div>

            </div>
            <template v-else>
              <template v-for="(item,index) in listData">
                <div class="item" :key="index" @click="goCourse(item)">
                  <div class="left-date">
                    <div class="date-year-month" v-if="item.issueTimeSec">{{item.issueTimeSec.substring(5,10)}}</div>
                    <div class="date-day" v-if="item.issueTimeSec">{{item.issueTimeSec.substring(0,4)}}</div>
                  </div>
                  <div class="right-desc">
                    <div class="title" v-html="item.title"></div>
                    <div class="intro" v-html="item.summary"></div>
                  </div>
                </div>
              </template>

              <div style="text-align: center; padding:30px 0" v-if="listData && listData.length>0">
                <a-pagination v-model="realyPage" :show-total="total => `共${allPages}页`" :total="total" @change="onPagesChange" />
              </div>
            </template>

          </template>
          <div v-else class="no-content">
            <img src="../../assets/image/sdcmzyxy/noContent.png">
            <div>抱歉没有找到搜索内容...</div>
          </div>

        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <WhrjFooter :color="'#3E140B'" />

  </div>
</template>

<script>
import WhrjHeader from "../../components/WhrjHeader.vue"
import WhrjFooter from "../../components/WhrjFooter"
import { mapActions, mapState } from "vuex"
import services from "../../assets/js/services"
export default {
  data () {
    return {
      school_code: localStorage.getItem("schoolCode"),
      currentIndex: 0,
      position: false,
      searchMenu: [
        { name: "全部", catid: '14643,14652,14653,14681,14683,14684,14671,14672,14686', loginCateid: '14643,14652,14653,14681,14684,14671' },
        { name: "建设动态", catid: '14643' },
        // { name: "典型经验", catid: '14619' },
        { name: "政策文件", catid: '14652,14653,14681' },
        { name: "典型案例", catid: '14683,14684', loginCateid:  '14684'},
        { name: "示范课程", catid: '14671,14672,14686', loginCateid:  '14671' },
      ],
      keyword: {
        name: "",
        count: "0",
      },
      listData: [],
      page: 0,
      allPages: 1,
      size: 10,
      total: 10,
      realyPage: 1,
      needLogin: true,
      catid: '14643,14652,14653,14681,14683,14684,14671,14672,14686', // 栏目ID 
      // acceptInfo: {}
    }
  },
  components: {
    WhrjHeader,
    WhrjFooter,
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll)
    })
    this.handleScroll()
    this.initData()
  },
  methods: {
    ...mapActions(['goAppsDetailsBeforeLogin', 'getAppsLoginUserInfo']),
    currentSideBar (index, item, refresh) {
      this.currentIndex = index
      if(this.currentIndex == 0 || this.currentIndex == 3 || this.currentIndex == 4){
        // 没登录
        if(this.needLogin){ 
          this.catid = item.loginCateid
        } else {
          this.catid = item.catid
        }
      } else {
        this.catid = item.catid
      }
      this.page = 0
      this.realyPage = 1
      if (!refresh) {
        this.getList(this.catid, index)
      }
      // this.acceptInfo = null
    },
    handleScroll () {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset;
      if (scrollTop <= 1) {
        document.body.scrollTop = 1
        document.documentElement.scrollTop = 1
        window.pageYOffset = 1
      } else {
        if (scrollTop >= 218) {
          this.position = true
        } else {
          this.position = false
        }
      }
    },
    goCourse (item) {
      // 判断是否登录,进行跳转
      // if (this.currentIndex == 0) {
      //   if (item.url == 'kcsz_sfkc') {
      //     // window.open(`/${this.school_code}/sdcmzyxy/sfkc`)
      //     return
      //   } else {
      //     window.open(item.url)
      //   }
      // } else {
      //   this.goAppsDetailsBeforeLogin().then(() => {
      //     window.open(item.url)
      //   })
      // }
      if(item.catid == '14672' || item.catid == '14686' || item.catid == '14683'){
        this.goAppsDetailsBeforeLogin().then(() => {
          if(this.catid == '14672'){
            window.open(item.url)
          } else if(item.catid == '14686'){
            window.open(`/${this.school_code}/whrjgczyxy/detail/${item.id}?flag=sfkc&url=${item.url}&index=${1}`)
          }
        })
      } else {
        let flag = ''
        if(item.catid == '14671'){
          window.open(`/${this.school_code}/whrjgczyxy/detail/${item.id}?flag=sfkc&url=${item.url}&index=${0}`)
        } else if(item.catid == '14643'){
          flag = 'jsdt'
        } else if(item.catid == '14652' || item.catid == '14653' || item.catid == '14681') {
          flag = 'zcwj'
          window.open(`/${this.school_code}/whrjgczyxy/detail/${item.id}?flag=${flag}&url=${item.url}&index=${item.catid == '14652' ? 1 : item.catid == '14653' ? 2 : item.catid == '14681' ? 3 : 0 }`)
        } else if(item.catid == '14684') {
          flag = 'dxal'
          window.open(`/${this.school_code}/whrjgczyxy/detail/${item.id}?flag=${flag}&url=${item.url}&index=${0}`)
        }
      }

    },
    onPagesChange (val) {
      this.realyPage = val
      this.page = val - 1
      this.getList()
    },
    getList () {
      console.log("88",this.catid)
      // catid 文章id | prepage 每页条数 | condid:2 按标题、内容检索 | search 关键字 
      services.searchData({ condid: "2", catid: this.catid, page: this.realyPage, prepage: this.size, search: this.keyword.name, sorttype: 'desc', sort: 'issueTime' }).then(res => {
        let data = res.data
        let keyword = this.keyword.name // 关键词
        data.forEach(item => {
          // 过滤标题
          if (item.title) {
            var reg = new RegExp(keyword.replace(/[-\[\]\/\{\}\ (\) \*\+\?\.\\\^\$\|]/g, "\\$&"), "igm");
            let result = item.title.replace(reg, (txt) => {
              return '<span style="color:#c00a03;">' + txt + '</span>';
            })
            this.$set(item, "title", result)
          }
          // 过滤摘要
          if (item.summary) {
            var reg = new RegExp(keyword.replace(/[-\[\]\/\{\}\ (\) \*\+\?\.\\\^\$\|]/g, "\\$&"), "igm");
            let result = item.summary.replace(reg, (txt) => {
              return '<span style="color:#c00a03;">' + txt + '</span>';
            })
            this.$set(item, "summary", result)
          }
          document.body.scrollTop = 1
          document.documentElement.scrollTop = 1
          window.pageYOffset = 1
          this.position = false
        })
        this.listData = res.data
        this.total = res.total
        this.allPages = res.last_page
        this.keyword.count = res.total
      })
      // if (this.acceptInfo && this.acceptInfo.item) {
      //   this.currentSideBar(this.acceptInfo.index, this.acceptInfo.item, true)
      // }

    },
    goDetail (item) {
      window.open(`/${this.school_code}/whrjgczyxy/detail/${item.id}?flag=zcwj&url=${item.url}`)
    },
    initData () {
      let query = this.$route.query
      this.keyword.name = query.keyword
      // if (query.currentData.index && query.currentData.item.catid) {
      //   this.currentIndex = query.currentData.index
      //   this.catid = query.currentData.item.catid
      // }
      this.getList()
    },
    loginCloseMask () {
      this.goAppsDetailsBeforeLogin()
    },
    watchRefresh: function (val) {
      // this.acceptInfo = val
      this.catid = val.item.catid
      this.page = 0
      this.realyPage = 1
      this.getList()
    }
  },

  computed: {
    ...mapState({
      appsUserInfo: state => state.appsLogin.appsUserInfo
    }),
  },
  watch: {
    appsUserInfo: {
      // 数据变化时执行的逻辑代码
      handler(newName, oldName) {
        if(newName && newName.id){
          this.needLogin = false
          this.catid = this.searchMenu[this.currentIndex].catid
        } else {
          this.needLogin = true
          if(this.currentIndex == 0 || this.currentIndex == 3 || this.currentIndex == 4){
            this.catid = this.searchMenu[this.currentIndex].loginCateid
          } else {
            this.catid =  this.searchMenu[this.currentIndex].catid
          }
        }
        this.page = 0
        this.realyPage = 1
        this.getList()
      },
      // 开启深度监听
      deep: true
    },
    $route () {
      this.initData()
    },
  },
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll)
  },
}
</script>

<style lang="less" scoped>
* {
  transition: all 0.3s;
}
.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
  overflow: hidden;
}

.clearfix {
  zoom: 1;
}

.w1280 {
  width: 1280px;
  margin: 0 auto;
}
.containers {
  margin-top: 1px;
}
.sdcm-container-box {
  background-image: url('../../assets/image/sdcmzyxy/zcwj-body.png');
  background-size: cover;
  .search-content {
    padding: 15px 0 12px;
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    color: #be2700;
    .keyword {
      color: #252525;
      font-weight: bold;
    }
  }
  .sdcm-content-box {
    background-color: #fff;
    padding: 38px 40px 0 80px;
    min-height: calc(100vh - 330px);
    .side-bar {
      &.position {
        position: fixed;
      }
      width: 226px;
      float: left;
      top: 0px;
      .header-box {
        height: 60px;
        background-image: url('../../assets/image/sdcmzyxy/slidebar/search-header.png');
        background-size: cover;
      }
      .body-box {
        padding-top: 1px;
        .item {
          cursor: pointer;
          width: 100%;
          height: 60px;
          line-height: 60px;
          background-color: #fff;
          border-bottom: 1px solid #f3f3f3;
          padding: 0 14px;
          display: flex;
          align-items: center;
          .star {
            width: 18px;
            height: 17px;
            background-image: url('../../assets/image/sdcmzyxy/slidebar/star.png');
            background-size: cover;
            margin-right: 8px;
          }
          .text {
            font-size: 17px;
            color: #262626;
          }
          &.active {
            background: #c00800;
            border: none;
            .star {
              background-image: url('../../assets/image/sdcmzyxy/slidebar/star-active.png');
            }
            .text {
              color: #fff;
            }
          }
        }
      }
    }
    .right-container {
      float: right;
      width: 840px;
      min-height: 600px;
      position: relative;

      .item {
        padding: 28px 0;
        border-bottom: 1px solid #f4f4f4;
        display: flex;
        cursor: pointer;
        &:first-child {
          padding-top: 0;
        }
        &:hover {
          .title {
            color: #931a14 !important;
          }
        }
        .left-date {
          margin-right: 32px;
          .date-year-month {
            width: 70px;
            height: 24px;
            background: #9b2020;
            line-height: 24px;
            text-align: center;
            font-size: 16px;
            color: #ffffff;
          }
          .date-day {
            width: 70px;
            height: 30px;
            background: #fff6f6;
            line-height: 30px;
            text-align: center;
            font-size: 16px;
            color: #840000;
          }
        }
        .middle-cover {
          background-size: cover !important;
          width: 200px;
          height: 113px;
          border-radius: 2px;
          background-position: center;
          margin-right: 14px;
          &::before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: rgba(14, 14, 14, 8%);
          }
        }
        .right-desc {
          flex: 1;
          width: 0;
          display: flex;
          flex-direction: column;
          margin-top: -3px;
          .title {
            font-size: 18px;
            color: #262626;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin-bottom: 20px;
          }
          .intro {
            font-size: 14px;
            color: #acacac;
            ::v-deep p {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
      }

      .login-mask {
        position: absolute;
        width: 683px;
        height: 319px;
        z-index: 100;
        background-color: #fff;
        background-image: url('../../assets/image/sdcmzyxy/needLogin.png');
        background-size: cover;
        top: 106px;
        right: 134px;
        .login-btn {
          width: 158px;
          height: 38px;
          text-align: center;
          line-height: 38px;
          background: #ae0000;
          border-radius: 19px;
          cursor: pointer;
          font-size: 16px;
          color: #ffffff;
          margin: 36px auto 0;
          font-weight: 600;
        }
      }
    }
  }
}

::v-deep .login-outer-box .login-inner-box {
  top: 51%;
  left: 50%;
}
.no-content {
  width: 400px;
  margin: 100px auto 0;
  img {
    width: 100%;
    height: auto;
  }
  div {
    padding-top: 13px;
    width: 100%;
    font-size: 16px;
    color: #969799;
    text-align: center;
  }
}

.sfkc-box {
  width: 100%;
  overflow: hidden;
  .item {
    padding: 28px 0;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    transition: color 0s;
    &:first-child {
      cursor: pointer;
    }
    &:hover {
      .title .text {
        color: #840000 !important;
      }
    }
    &:first-child {
      padding-top: 25px;
    }
    .thumb-image {
      width: 200px;
      height: 113px;
      border-radius: 2px;
      background-size: cover;
      background-position: center;
    }
    .right-content {
      flex: 1;
      padding: 3px 0 0 15px;
      .title {
        padding-bottom: 17px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .text {
          font-size: 18px;
          color: #262626;
          margin-right: 15px;
          max-width: 80%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .label {
          padding: 0 10px;
          height: 24px;
          background: linear-gradient(
            -55deg,
            #f8e7c6 0%,
            #f8e5be 53%,
            #fff0cf 99%
          );
          border-radius: 2px;
          vertical-align: middle;
          font-size: 14px;
          color: #840000;
          line-height: 24px;
          text-align: center;
          font-weight: bold;
        }
      }
      .sub-title {
        font-size: 12px;
        color: #840000;
        padding-bottom: 5px;
        &::before {
          content: '';
          display: inline-block;
          background-image: url('../../assets/image/sdcmzyxy/sfkc-sub-label.png');
          width: 6px;
          height: 13px;
          background-size: cover;
          vertical-align: middle;
          margin-right: 8px;
        }
      }
      .desc {
        font-size: 13px;
        color: #acacac;
        line-height: 22px;
      }
    }
  }
}
</style>