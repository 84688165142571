var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-outer"},[_c('div',{staticClass:"container navbar-inner"},[(_vm.school_code == _vm.utils.sdcm_code || _vm.school_code == _vm.utils.whrj_code)?_c('div',{class:_vm.currentPath == ("/" + _vm.school_code + "/") ? 'nav-item active' : 'nav-item'},[_c('router-link',{attrs:{"to":("/" + _vm.school_code)}},[_vm._v("平台首页")])],1):_c('div',{class:_vm.currentPath == ("/" + _vm.school_code + "/zy") ? 'nav-item active' : 'nav-item'},[_c('router-link',{attrs:{"to":("/" + _vm.school_code + "/zy")}},[_vm._v("首页")])],1),_vm._l((_vm.school_code == _vm.utils.sdcm_code
        ? _vm.sdcmRouteArr
        : _vm.routeArr),function(item,index){return [_c('div',{key:index,class:_vm.currentPath.indexOf(item.path) != -1
            ? 'nav-item active'
            : 'nav-item'},[(item.type == 'path')?[_c('div',{staticClass:"link-nav",on:{"click":function($event){return _vm.clickRouter(item)}}},[_vm._v(" "+_vm._s(item.name)+" "),(item.path.indexOf('/xxzyk') > -1)?_c('div',{staticClass:"xxzy-list"},[_c('div',{staticClass:"xxzy-box"},_vm._l((_vm.xxzyRouterList),function(elem){return _c('div',{key:elem.path,class:_vm.$route.path.indexOf(elem.path) != -1
                      ? 'xxzy-item active'
                      : 'xxzy-item',on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                      path: ("/" + _vm.school_code + "/zy/xxzyk/" + (elem.path)),
                    })}}},[_vm._v(" "+_vm._s(elem.name)+" ")])}),0)]):_vm._e()])]:(item.type == 'click')?[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.clickFunc(item)}}},[_vm._v(_vm._s(item.name))])]:[_c('a',{attrs:{"href":item.path,"target":"_blank"}},[_vm._v(_vm._s(item.name))])]],2)]}),(_vm.school_code == _vm.yzSchoolCode)?[_c('a',{staticClass:"nav-item",attrs:{"href":"https://zhsz.jiaoyu.cn/100047/10/index.html","target":"_blank"}},[_vm._v("教学研究中心")])]:_vm._e()],2),_c(_vm.dialogConfig.name,{tag:"component"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }