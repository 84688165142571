<template>
  <div class="navbar-outer">
    <div class="container navbar-inner">
      <!-- <div v-show="currentPath == `/${school_code}/`" class="nav-category">资源类目</div> -->
      <div v-if="school_code == utils.sdcm_code || school_code == utils.whrj_code" :class="
          currentPath == `/${school_code}/` ? 'nav-item active' : 'nav-item'
        ">
        <router-link :to="`/${school_code}`">平台首页</router-link>
      </div>
      <div v-else :class="
          currentPath == `/${school_code}/zy` ? 'nav-item active' : 'nav-item'
        ">
        <router-link :to="`/${school_code}/zy`">首页</router-link>
      </div>
      <template v-for="(item, index) in school_code == utils.sdcm_code
          ? sdcmRouteArr
          : routeArr">
        <div :class="
            currentPath.indexOf(item.path) != -1
              ? 'nav-item active'
              : 'nav-item'
          " :key="index">
          <template v-if="item.type == 'path'">
            <!-- <router-link :to="`/${school_code}${item.path}`">{{item.name}}</router-link> -->
            <div class="link-nav" @click="clickRouter(item)">
              {{ item.name }}
              <div class="xxzy-list" v-if="item.path.indexOf('/xxzyk') > -1">
                <div class="xxzy-box">
                  <div v-for="elem in xxzyRouterList" :key="elem.path" @click.stop="
                      $router.push({
                        path: `/${school_code}/zy/xxzyk/${elem.path}`,
                      })
                    " :class="
                      $route.path.indexOf(elem.path) != -1
                        ? 'xxzy-item active'
                        : 'xxzy-item'
                    ">
                    {{ elem.name }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="item.type == 'click'">
            <a href="javascript:void(0)" @click="clickFunc(item)">{{
              item.name
            }}</a>
          </template>
          <template v-else>
            <a :href="item.path" target="_blank">{{ item.name }}</a>
          </template>

        </div>
      </template>
      <template v-if="school_code == yzSchoolCode">
        <a class="nav-item" href="https://zhsz.jiaoyu.cn/100047/10/index.html" target="_blank">教学研究中心</a>
      </template>
    </div>
    <component :is="dialogConfig.name"></component>
  </div>
</template>

<script>
import services from "../assets/js/services";
import utils from "../assets/js/utils";
// import { Modal } from 'ant-design-vue';
import appleRes from "../components/appleRes.vue";
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Navbar",
  components: {
    appleRes,
  },
  data () {
    return {
      school_code: localStorage.getItem("schoolCode"),
      isMobile: utils.isMobile(),
      utils,
      services,
      currentPath: "",
      routeArr: [
        {
          name: "思政元素体系",
          path: "/zy/szysk",
          type: "path", // path为本站点路由 link 为跳转链接
        },
        {
          name: "课程思政元素库",
          path: "/zy/szzyk",
          type: "path",
        },
        // {
        //   name: "思政专题库",
        //   path: "/zy/szztk",
        //   type: "path",
        // },
        {
          name: "在线协同备课",
          type: "click",
        },
        {
          name: "使用帮助",
          type: "click",
          path: "/zy/syzn",
        },
      ],
      sdcmRouteArr: [
        {
          name: "思政元素体系",
          path: "/zy/szysk",
          type: "path", // path为本站点路由 link 为跳转链接
        },
        {
          name: "热点资源",
          path: "/zy/szzyk",
          type: "path",
        },
        // {
        //   name: "专题资源",
        //   path: "/zy/szztk",
        //   type: "path",
        // },
        // {
        //   name: "培训资源",
        //   path: "/zy/xxzyk",
        //   type: "path",
        // },
        {
          name: "在线协同备课",
          type: "click",
        },
        {
          name: "使用帮助",
          type: "click",
          path: "/zy/syzn",
        },
      ],
      dialogConfig: {
        name: "",
      },
      yzSchoolCode: 100047
    };
  },
  computed: {
    ...mapState({
      schoolMenu: (state) => state.appsLogin.schoolMenu,
      xxzyRouterList: (state) => state.class.xxzyRouterList,
    }),
  },
  async mounted () {
    this.currentPath = this.$route.path;
    this.$nextTick(async () => {
      await this.getSchoolMenuData();
      // if (
      //   this.school_code == utils.whrj_code ||
      //   this.school_code == utils.jyzx_code ||
      //   this.school_code == utils.zjjyzx_code ||
      //   this.school_code == utils.fjjyzx_code ||
      //   this.school_code == utils.tsgy_code ||
      //   this.school_code == 100064 ||
      //   this.school_code == 100002 ||
      //   this.school_code == 100016
      // ) {
      // 开通后显示学习资源库
      if (this.schoolMenu) {
        if (this.schoolMenu.open_xxzyk) {
          if (this.school_code == utils.sdcm_code) {
            this.sdcmRouteArr.push({
              name: "培训资源",
              path: "/zy/xxzyk",
              type: "path",
            });
          } else {
            this.routeArr.push({
              name: "教师研修库",
              path: "/zy/xxzyk",
              type: "path",
            });
          }
        }
        // 开通后显示思政专题库
        if (this.schoolMenu.open_szztk) {
          if (this.school_code == utils.sdcm_code) {
            this.sdcmRouteArr.push({
              name: "专题资源",
              path: "/zy/szztk",
              type: "path",
            });
          } else {
            this.routeArr.push({
              name: "思政专题库",
              path: "/zy/szztk",
              type: "path",
            });
          }
        }
        if (this.schoolMenu.open_cases) {
          if (this.school_code == utils.sdcm_code) {
            this.sdcmRouteArr.push({
              name: "案例资源库",
              path: "/zy/alzyk",
              type: "path",
            });
          } else {
            this.routeArr.push({
              name: "案例资源库",
              path: "/zy/alzyk",
              type: "path",
            });
          }
        }
        // 学校是否展示区域资源菜单
        if (
          this.schoolMenu &&
          this.schoolMenu.hasOwnProperty("special") &&
          this.schoolMenu.special
        ) {
          var path = {
            name: this.schoolMenu.special.name,
            path: "/zy/region",
            type: "path",
          };
          if (
            this.school_code == utils.sdcm_code ||
            this.school_code == utils.whrj_code
          ) {
            this.sdcmRouteArr.push(path);
          } else {
            this.routeArr.push(path);
          }
        }
        // 学校是示范课程菜单
        if (
          this.schoolMenu &&
          this.schoolMenu.hasOwnProperty("course") &&
          this.schoolMenu.course
        ) {
          var path = {
            name: this.schoolMenu.course.name, // this.schoolMenu.course.name
            type: "path",
            path: "/zy/szsfkc",
          };
          if (
            this.school_code == utils.sdcm_code ||
            this.school_code == utils.whrj_code
          ) {
            this.sdcmRouteArr.push(path);
          } else {
            this.routeArr.push(path);
          }
        }
        // 学校是否开通区域文化库列表
        if (
          this.schoolMenu &&
          this.schoolMenu.hasOwnProperty("open_qyzyk") &&
          this.schoolMenu.open_qyzyk
        ) {
          let path = {
            type: "path",
            name: "区域文化库",
            path: "/zy/region/list",
          };
          this.routeArr.push(path);
          this.sdcmRouteArr.push(path);
        }
      }
      // }

      // 菜单顺序根据sort值排序
      let menu_type = "routeArr";
      if (
        this.school_code != utils.sdcm_code &&
        this.school_code != utils.whrj_code
      ) {
        menu_type = "routeArr";
      } else {
        menu_type = "sdcmRouteArr";
      }
      this[menu_type].forEach((item) => {
        if (item.path) {
          if (item.path.indexOf("szztk") > -1) {
            // 思政专题库
            this.$set(item, "sort", 5);
          } else if (item.path.indexOf("/szysk") > -1) {
            // 思政元素体系
            this.$set(item, "sort", 1);
          } else if (item.path.indexOf("szzyk") > -1) {
            // 课程思政元素库
            this.$set(item, "sort", 2);
          } else if (item.path.indexOf("alzyk") > -1) {
            // 案例资源库
            this.$set(item, "sort", 3);
          } else if (item.path == "/zy/region") {
            // 开通的区域资源
            this.$set(item, "sort", 6);
          } else if (item.path.indexOf("region/list") > -1) {
            // 区域文化库列表
            this.$set(item, "sort", 7);
          } else if (item.path.indexOf("xxzyk") > -1) {
            // 学习资源库
            this.$set(item, "sort", 8);
          } else if (item.path.indexOf("szsfkc") > -1) {
            // 示范课程
            this.$set(item, "sort", 4);
          } else if (item.path.indexOf("syzn") > -1) {
            // 使用指南
            this.$set(item, "sort", 10);
          }
        } else {
          if (item.name == "在线协同备课") {
            this.$set(item, "sort", 9);
          }
        }
      });
      this[menu_type].sort((a, b) => a.sort - b.sort);
    });
  },
  created () {
    this.getOpenClass();
  },
  methods: {
    ...mapActions(["goAppsDetailsBeforeLogin", "getSchoolMenuStatus"]),
    ...mapMutations(["setxxzyRouterList"]),
    // 获取学校菜单展示权限 - 区域资源、示范课程
    async getSchoolMenuData () {
      let that = this;
      if (!that.schoolMenu) {
        await that.getSchoolMenuStatus(that.school_code);
      }

      // this.getAreaRegionInfo({ school_code: this.school_code }).then(res => {
      //   if (res.code == '0000') {
      //     if (that.school_code == utils.sdcm_code || that.school_code == utils.whrj_code) {
      //       that.sdcmRouteArr.splice(2, 0, {
      //         name: res.data.name,
      //         path: "/zy/region",
      //         type: "path",
      //       });

      //     } else {
      //       that.routeArr.splice(3, 0, {
      //         name: res.data.name,
      //         path: "/zy/region",
      //         type: "path",
      //       });
      //     }
      //   }
      // })
    },
    // 在线备课弹出提示事件
    clickFunc (item) {
      let that = this;
      this.goAppsDetailsBeforeLogin().then(() => {
        if (item.name == "在线协同备课") {
          window.open(
            `${services.BEIKE_SERVER}/${that.school_code}/lesson/resource`
          );
        } else if (item.name.indexOf("齐鲁") > -1) {
          window.open(
            `${services.BEIKE_SERVER}/${that.school_code}/sz/special/region`
          );
        } else if (item.name == "使用帮助") {
          this.$router.push(`/${this.school_code}/zy/syzn`);
        }
      });
    },
    clickRouter (item) {
      if (
        item.path.indexOf("/zy/region") > -1 &&
        item.path.indexOf("/zy/region/list") < 0
      ) {
        let routeUrl = this.$router.resolve({
          path: `/${this.school_code}/zy/region`,
        });
        window.open(routeUrl.href, "_self");
      } else {
        this.$router.push({ path: `/${this.school_code}${item.path}` });
      }
    },
    beikeMessageModal () {
      this.dialogConfig.name = "appleRes";
    },
    async getOpenClass () {
      let res = await this.services.getOpenClass({
        school_code: this.school_code,
        course_category_id: "60,2,6,55,86,87,362",
      });
      if (res.code == "0000") {
        this.setxxzyRouterList([
          ...res.data.material_category_id,
          ...res.data.course_category_id,
        ]);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.navbar-outer {
    width: 100%;
    height: 42px;
    background: linear-gradient(162deg, #dd363a 0%, #df4531 100%);
    .navbar-inner {
        display: flex;
        justify-content: space-between;
        .nav-item {
            color: #ffffff;
            position: relative;
            line-height: 42px;
            font-size: 16px;
            text-align: center;
            opacity: 0.8;
            a,
            .link-nav {
                color: #ffffff;
                display: inline-block;
                text-decoration: none;
                cursor: pointer;
                .xxzy-list {
                    z-index: 99;
                    position: absolute;
                    display: none;
                    top: 43px;
                    left: 50%;
                    transform: translate(-50%, 10px);
                    background: #fff;
                    width: 630px;
                    border-bottom: 1px solid #ea6a59;
                    height: 95px;
                    background-size: 100%;
                    .xxzy-box {
                        margin: 14px 40px 0;
                        display: flex;
                        flex-wrap: wrap;
                        .xxzy-item {
                            width: 23%;
                            margin-top: 6px;
                            height: 24px;
                            font-size: 13px;
                            line-height: 24px;
                            font-weight: 400;
                            color: #383838;
                            background: #f8f9f9;
                            margin-left: 11px;
                            &:hover {
                                background: #fff8f3;
                                color: #dd3639;
                            }
                            &.active {
                                background: #fff8f3;
                                color: #dd3639;
                            }
                        }
                    }
                    // background: #fff;
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -14px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-top: 7px solid transparent;
                        border-left: 7px solid transparent;
                        border-bottom: 7px solid #fff;
                        border-right: 7px solid transparent;
                    }
                    &::after {
                        content: '';
                        display: block;
                        top: -12px;
                        width: 100%;
                        height: 14px;
                        position: absolute;
                        background: transparent;
                    }
                }
            }

            &.active,
            &:hover {
                opacity: 1;
                font-weight: bold;
            }
            &:hover .link-nav .xxzy-list {
                display: block;
            }
            &.active:after,
            &:hover:after {
                content: '';
                width: 24px;
                height: 3px;
                background: #ffffff;
                border-radius: 2px;
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
        .nav-category {
            text-align: center;
            width: 245px;
            font-size: 16px;
            font-weight: bold;
            color: #da3033;
            background-color: #ffffff;
            opacity: 1;
            line-height: 42px;
            transition: all 0.3s;
        }
    }
}
.jioayu-chrome {
    font-size: 14px;
    color: #333;
    display: inline;
    line-height: 30px;
    a,
    a:hover {
        color: #df4531;
    }
    a:hover {
        text-decoration: underline;
    }
}
</style>