<template>
  <div class="header-box" @contextmenu.prevent.capture>
    <!-- <div class="head-top">
      <div class="container clearfix">
        <div class="jioayu-online">中国教育在线旗下网站</div>
        <div v-show="!isChrome && !isMobile" class="jioayu-chrome">
          本站使用 Chrome 60 以上版本浏览器体验效果更佳，建议更换浏览器使用，
          <a href="https://www.google.cn/chrome/index.html" target="_blank">下载浏览器</a>。
        </div>
        
      </div>
    </div> -->
    <div class="head-two">
      <div class="container flex-center">
        <div class="left-imgs">
          <img v-if="schoolInfo" class="schoollogo" :src="schoolInfo.school_logo" />
          <!-- <a href="https://www.eol.cn" target="_blank"><img src="../assets/image/common/zgjyzx.png" /></a> -->
          <span class="split-line"></span>
          <!-- <a :href="services.YXT_GW" target="_blank"><img src="../assets/image/common/yxt-top.png" /></a> -->
            <img v-if="school_code == utils.gdkm_code" class="chayi-img" src="../assets/image/gdkm/chayi.png"  @click="$router.push(`/${school_code}/zy`)"/>
          <img v-else class="szzyk-img" src="../assets/image/common/szzyk.png"  @click="$router.push(`/${school_code}/zy`)"/>
        </div>
        <div class="search-middle-box">
          <div class="outer-frame">
            <div class="frame-input">
              <input class="text" placeholder="请输入检索词" :value="keyWord" @keyup.enter="searchMethod" @change="changeInputVal" />
            </div>
            <div v-show="keyWord && keyWord.length > 0" class="clear-input" @click="clearInputVal"></div>
            <div class="search-button" @click="searchMethod">
              <img src="../assets/image/common/search-icon.png" />
            </div>
          </div>
          <!-- <div class="search-list">
            <template v-for="(item, i) in homeSearchList">
              <div class="search-item" @click="toSzyskDetail(item)" :key="i">{{item.name}}</div>
            </template>
          </div> -->
        </div>
        <div class="login-box">
          <div v-if="appsUserInfo && appsUserInfo.name" class="login-dropdown-box">
            <div class="login-after">{{appsUserInfo.name}}</div>
            <div class="login-sub-box">
              <template v-if="appsUserInfo.user_type== 2 || (appsUserInfo.user_type == 1 && appsUserInfo.role_ids)">
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/sz/school/resource`" target="_blank">校内共建资源库</a>
                </div>
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/lesson/resource`" target="_blank">备课资源库</a>
                </div>
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/lesson/works`" target="_blank">协同备课</a>
                </div>
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/sz/favorite`" target="_blank">资源收藏</a>
                </div>
              </template>
              <template v-if="appsUserInfo.user_type == 2 || (appsUserInfo.user_type == 1 && appsUserInfo.role_ids == 2)">
                <div class="hover-item">
                  <a :href="`${services.BEIKE_SERVER}/${school_code}/manage/user`" target="_blank">用户管理</a>
                </div>
              </template>
              <div class="login-lines"></div>
              <div class="hover-item">
                <a :href="`${services.APPS_WEB}/${school_code}/worker`" target="_blank">信息服务中心</a>
              </div>
              <div class="hover-item">
                <a :href="`${services.APPS_WEB}/${school_code}/userInfo`" target="_blank">个人信息设置</a>
              </div>
              <div v-if="!isMobile" class="hover-item">
                <a :href="`${services.APPS_WEB}/${school_code}/editPassword`" target="_blank">修改密码</a>
              </div>
              <div class="hover-item" @click="logoutEvent">
                <a href="javascript:;">退出</a>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="login" @click="loginModal(true)">登 录</div>
            <!-- <router-link class="register" to="/register">个人注册</router-link> -->
            <!-- <a class="free-trial" :href='services.YXT_GW + "/need"' target="_blank">学校免费试用</a> -->
          </template>
        </div>
        <!-- <div class="renminkeji-box">
          <img src="../assets/image/common/rmkj.png" />
          <div class="rmkj-text">数据支持 / 战略合作伙伴</div>
        </div> -->
      </div>
    </div>

    <!-- 登录弹层 -->
    <Login ref="loginchild"></Login>
  </div>
</template>

<script>
import services from "../assets/js/services"
import utils from "../assets/js/utils"
import { mapState, mapActions, mapMutations } from "vuex"
// import VueCookies from 'vue-cookies';
import homeData from "../assets/js/homeData"
import Login from "./Login.vue"

export default {
  name: 'Header',
  components: {
    Login,
  },
  data () {
    return {
      utils,
      services,
      isChrome: true,
      loginSignArr: [],
      homeSearchList: homeData.homeSearchList,
      isMobile: utils.isMobile(),
      school_code: localStorage.getItem("schoolCode"),
    };
  },
  computed: {
    ...mapState({
      appsUserInfo: state => state.appsLogin.appsUserInfo,
      loginVisible: state => state.appsLogin.loginVisible,
      keyWord: state => state.search.keyWord,
      loading: state => state.search.loading,
      schoolInfo: state => state.appsLogin.schoolInfo
    }),
  },
  created () {
    // this.getLoginSchoolInfo({ school_code: this.school_code, label: 'kcsz' }).then(res => {
    //   if(res.code == '0000'){
    //     utils.setTitle(this.schoolInfo.school_name)
    //   }
    // })
  },
  async mounted () {
    // 为给运维扫描添加 
    if (this.$route.query.needLogin) {
      this.loginModal(true)
    }
    this.isChrome = utils.bowerUserAgent();
    this.getAppsLoginUserInfo();

  },
  methods: {
    ...mapMutations(['setSearchKeyWord', 'setAppsLoginVisible', 'setSearchSzzykData', 'setSearchElementData', 'resetSzzykTotal']),
    ...mapActions(['getAppsImageCaptcha', 'getLoginSchoolInfo', 'getAppsLoginUserInfo', 'requestAppsLogout', 'goAppsDetailsBeforeLogin', 'schoolLoginRedirect']),
    changeInputVal (e) {
      this.setSearchKeyWord({ data: e.target.value })
    },
    clearInputVal () {
      this.setSearchKeyWord({ data: '' })
      this.setSearchSzzykData({ data: [] })
      this.setSearchElementData({ data: [] })
      this.resetSzzykTotal()
    },
    searchMethod () {
      if (this.keyWord && this.keyWord.length > 0) {
        this.$emit("currentState", false)  // 再次搜索时让重新请求回来的总数显示
        this.$router.push({
          path: `/${this.school_code}/zy/search`,
          query: {
            keyWord: this.keyWord
          }
        });
      } else {
        this.$message.error("检索词不能为空")
      }
    },
    loginModal (val) {
      let that = this
      // 如果需要统一登录,跳转
      let param = { format: 'json', school_code: that.school_code, from: window.location.href }
      that.schoolLoginRedirect(param).then(res => {
        if (res.code == '0000' && res.data && !(res.data.redirect.indexOf(services.APPS_WEB) > -1)) {
          return window.open(res.data.redirect, "_self")
          // return window.open(`${services.APPS_WEB}/${this.school_code}/login?from=${window.location.href}`, "_self")
        } else {
          if (val) {
            that.getAppsImageCaptcha()
          }
          that.setAppsLoginVisible(val);
        }
      })
    },
    // 退出登录
    async logoutEvent () {
      let that = this;
      await that.requestAppsLogout().then(() => {
        if (utils.whiteList.some( item =>  `/${this.school_code}${item}` == `${this.$route.path}`)) {
          that.$router.push({ path: `/${that.school_code}/` })
          that.loginModal(true);
        }
      })
    },
    toSzyskDetail (item) {
      let that = this;
      that.goAppsDetailsBeforeLogin().then(() => {
        let routeUrl = that.$router.resolve({
          path: `/${that.school_code}/zy/szysk/details`,
          query: {
            id: item.id,
            pid: item.pid,
            className: item.classname
          }
        });
        window.open(routeUrl.href, '_blank');
      });
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  height: 100%;
}
.header-box {
  width: 100%;
  .head-top {
    width: 100%;
    height: 30px;
    background: #f5f5f5;
    .jioayu-online {
      color: #333333;
      font-size: 12px;
      line-height: 30px;
      float: left;
    }
    .jioayu-chrome {
      font-size: 11px;
      color: #df4531;
      margin-left: 20px;
      display: inline-block;
      line-height: 30px;
      a,
      a:hover {
        color: #df4531;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    
  }
}
.head-two {
  padding: 8px 0 6px;
  width: 100%;
  height: 100px;
  background-color: #fff;
  .flex-center {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .left-imgs {
      display: flex;
      align-items: center;
      .schoollogo {
        max-height: 50px;
        width: auto;
      }
      .split-line {
        width: 1px;
        height: 20px;
        background: #e6e3e3;
        margin: 0 20px;
        display: inline-block;
      }
      .szzyk-img {
        margin-left: 4px;
        cursor: pointer;
      }
      .chayi-img {
      cursor: pointer;
        margin-left: 4px;
        height: 36px;
      }
    }

    .search-middle-box {
      margin: 0 140px 0 auto;
      .outer-frame {
        width: 310px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #f8818d;
        border-radius: 16px;
        display: flex;
        align-items: center;
        .frame-input {
          flex: 1;
          input {
            font-size: 14px;
            margin: 0 10px;
            border: none;
            outline: none;
            height: 28px;
            line-height: 28px;
            display: inline-block;
            width: 90%;
            &:focus-visible {
              border: none;
              outline: none;
            }
          }
        }
        .clear-input {
          width: 30px;
          height: 30px;
          background: url('../assets/image/common/clear.png') no-repeat center
            center;
          background-size: 15px auto;
          cursor: pointer;
        }
        .search-button {
          width: 45px;
          height: 34px;
          background: #e64757;
          border-radius: 16px;
          text-align: center;
          cursor: pointer;
          img {
            margin: 7px auto;
            display: inline-block;
          }
        }
      }
      .search-list {
        font-size: 12px;
        color: #887a79;
        line-height: 32px;
        padding-left: 12px;
        .search-item {
          display: inline-block;
          margin-right: 14px;
          cursor: pointer;
          &:hover {
            color: #df4531;
          }
        }
      }
    }
    .renminkeji-box {
      text-align: center;
      .rmkj-text {
        color: #4b4b4b;
        font-size: 12px;
        margin-top: 5px;
      }
    }
    .login-box {
      float: right;
      text-align: right;
      line-height: 30px;
      .free-trial {
        color: #e54656;
        font-size: 12px;
        line-height: 30px;
        display: inline-block;
      }
      .login {
        color: #e54656;
        font-size: 14px;
        display: inline-block;
        cursor: pointer;
        // &::after {
        //   content: '';
        //   width: 2px;
        //   height: 9px;
        //   background: #eeeff0;
        //   display: inline-block;
        //   margin: 0 12px;
        // }
        &::before {
          content: '';
          display: inline-block;
          width: 19px;
          height: 17px;
          background-size: cover;
          vertical-align: -3.5px;
          background-image: url('../assets/image/common/login.png');
          margin-right: 8px;
        }
      }
      .register {
        color: #e54656;
        font-size: 12px;
        display: inline-block;
        cursor: pointer;
        &::after {
          content: '';
          width: 2px;
          height: 9px;
          background: #eeeff0;
          display: inline-block;
          margin: 0 12px;
        }
      }
      .login-dropdown-box {
        height: 30px;
        position: relative;
        display: inline-block;
        .login-after {
          color: #121212;
          font-size: 14px;
          display: inline-block;
          line-height: 30px;
          &::after {
            display: inline-block;
            content: '';
            width: 7px;
            height: 5px;
            margin-left: 5px;
            background-image: url('../assets/image/common/up-icon.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
            vertical-align: 3px;
            transition: background-image 0.3s;
          }
          &::before {
          content: '';
          display: inline-block;
          width: 19px;
          height: 17px;
          background-size: cover;
          vertical-align: -2.5px;
          background-image: url('../assets/image/common/login.png');
          margin-right: 8px;
        }
        }
        .login-sub-box {
          display: none;
          position: absolute;
          padding: 10px 20px;
          box-sizing: border-box;
          top: 30px;
          left: -35px;
          width: 140px;
          text-align: left;
          background-color: transparent;
          box-shadow: 0px 1px 6px 0px rgba(130, 130, 130, 0.2);
          border-radius: 2px;
          transition: background-color 0.3s;
          z-index: 100;
          .hover-item {
            display: block;
            line-height: 26px;
            &:hover {
              background-color: #fff;
            }
            a {
              color: #393939;
              font-size: 12px;
              display: block;
              &:hover {
                color: #e64757;
                background-color: #fff;
              }
            }
          }
          .login-lines {
            width: 100%;
            height: 1px;
            background: #f3f4f5;
            margin: 5px 0;
          }
        }
        &:hover .login-after::after {
          background-image: url('../assets/image/common/down-icon.png');
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-position: center;
        }
        &:hover .login-sub-box {
          display: block;
          background-color: #ffffff;
        }
      }
    }
  }
}
</style>
